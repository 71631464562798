import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import ModalClonesConfirmation from '@/components/ModalClonesConfirmation'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'

class ModalSurveyConfirmation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showByProps: this.props.show,
            showByRef: false,
            showError: { error: false, message: '' },
            showConfirmation: false,
            display: 'block'
        }
        this.onCancel = this.onCancel.bind(this)
        this.onConfirm = this.onConfirm.bind(this)
        this.handleShow = this.handleShow.bind(this)
        this.openConfirmationModal = this.openConfirmationModal.bind(this)
        this.handleCancelConfirmation = this.handleCancelConfirmation.bind(this)
        this.onConfirmClone = this.onConfirmClone.bind(this)
    }

    openConfirmationModal() {
          this.setState(state => ({
            ...state,
            showConfirmation: true,
            display: 'none'
          }))
      }

      handleCancelConfirmation() {
        this.setState({
            ...this.state,
          showConfirmation: false,
          display: 'block'
        })
      }

      onConfirmClone(){
          this.props.onConfirm()
          this.setState(state => ({
            ...state,
            showConfirmation: false,
            display:'block',
            showByProps: false,
            showByRef: false,
          }))
      }

    static getDerivedStateFromProps(props, state) {
        if (props.show !== state.showByProps) {
            return { showByProps: props.show }
        }
        return {}
    }

    handleShow() {
        this.setState({
            ...this.state,
            showByRef: true,
        })
    }

    onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel()
        }
        this.setState({
            ...this.state,
            showByProps: false,
            showByRef: false,
        })
    }

    onConfirm() {
        this.props.onConfirm()

        this.setState({
            ...this.state,
            showByProps: false,
            showByRef: false,
        })
    }


    render() {
        return (
            <React.Fragment>
                <Modal
                    className="__modal __modal_confirmation"
                    show={this.state.showByProps || this.state.showByRef}
                    onHide={this.onCancel}
                    keyboard={false}
                    backdrop="static">
                    <Modal.Header closeButton className="__modal_header __border_bottom">
                        <h3 className="__modal_title">Atención</h3>
                    </Modal.Header>

                    <Modal.Body className="__modal_body">
                        {this.props.body}
                        {this.props.showError.error ? <div className="_modal_confirmation_error">{this.props.showError.message}</div> : ''}
                    </Modal.Body>

                    <Modal.Footer className="__modal_footer __border_top">
                        <ButtonGroup className="pull-right">
                            <Button
                                id="CancelButton"
                                theme="secondary"
                                onClick={this.onCancel}
                                text={this.props.cancelTitle}
                            />
                            <Button
                                id="ConfirmButton"
                                onClick={(this.props.base && this.props.count > 0) ? this.openConfirmationModal : this.onConfirm}
                                text={this.props.confirmTitle}
                            />
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>
                <ModalClonesConfirmation
                    show={this.state.showConfirmation}
                    confirmTitle="Aceptar"
                    cancelTitle="Cancelar"
                    onConfirm={() => { this.onConfirmClone() }}
                    onCancel={this.handleCancelConfirmation}
                    body={`${this.props.lang.modalBodyWarning} ${ this.props.count} ${this.props.lang.modalBodyCurses}. ${this.props.lang.modalBodyWarningConfirmation}`}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.langStore.modalSurvey
    }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(ModalSurveyConfirmation)
