import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'react-bootstrap'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { X } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { animationAsset } from '@/assets/img/config'
import './onboardingMenuPopover.scss'
import { ONBOARDING_TYPE } from '@/constants/onboarding'

const OnboardingMenuPopover = ({
    reference,
    subComponents,
    update,
    lang,
    className
}) => { 
    const [show,setShow] = useState(true)
    const confirm = () => {
        update(ONBOARDING_TYPE.COMPLETE)
        setShow(false)
    }
    const placement = 'right'
    return (
        <>
            <div className="menuBackCinema" />
            <div className="menuOverCinema disabledPointer" />
            <Popover 
                id="onboardingSidePopover"
                placement={placement}
                bsClass="popover"
                arrowOffsetTop="50%"
                positionLeft="320.8"
                positionTop="75"
                className={["fade in", className]}
            >
                <div className="onboardingPopoverHeader">
                    <Button 
                        id="closeIconRemote"
                        className="close_icon" 
                        theme="no-theme"
                        icon={<X />}
                        onClick={()=>(confirm())}
                    />
                </div>
                <div className="onboardingPopoverBody">
                    <img
                        className="onboardingSideGif"
                        src={animationAsset('onboarding-progress.gif')}
                        alt="progress"
                    />
                    <p className="paragraph">{lang.onboardingSideMessage}</p>
                </div>
                <div className="onboardingPopoverFooter">
                    <div className="_btn_group popoverConfirm">
                        <Button
                            id="CancelButton"
                            block
                            visible
                            theme='secondary'
                            onClick={()=>(confirm())}
                            text={lang.confirmMessage}
                        />
                    </div>
                </div>
            </Popover>
        </> )
}

OnboardingMenuPopover.propTypes = {
    lang: PropTypes.any.isRequired,
    update: PropTypes.func.isRequired,
    className: PropTypes.string,
}

OnboardingMenuPopover.defaultProps = {
    className: null
}

export default OnboardingMenuPopover