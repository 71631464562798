import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actions as modalActions } from '@/redux/reducers/modalReducer'
import { logoutUser } from '@/redux/reducers/_deprecated/authReducer'
import { clearAll } from '@/redux/reducers/idleTimeReducer'
import { IDLE_TIME_OUT } from '@/config/env'

import Modal from '@digitalhouse-tech/react-lib-ui-explorer/lib/Modal'
import { Clock } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { IDLE_TIMER_MODAL } from '@/constants'

import './styles.scss'

const IdleTimeModal = () => {
    const dispatch = useDispatch()
    const modalState = useSelector(({ modal }) => modal)

    const {
        modalIdle: { modalTitle, modalText, cancelButton, confirmButton },
    } = useSelector(({ langStore }) => langStore.idle)

    const handleLogout = () => {
        dispatch(clearAll())
        dispatch(logoutUser())
    }

    const handleContinue = () => {
        dispatch(clearAll())
        dispatch(modalActions.close())
    }

    const timeoutMinutes = Math.floor(IDLE_TIME_OUT / 60000)

    const formatedModalText = modalText.replace('{{TIME}}', timeoutMinutes)

    return (
        <Modal
            id={modalState.id}
            showModal={IDLE_TIMER_MODAL === modalState.id}
            modalTitle={
                <span className='idle-modal__title'>
                    <Clock className='idle-modal__title--icon' size={22} />
                    {modalTitle}
                </span>
            }
            onMainAction={handleContinue}
            onSecondaryAction={handleLogout}
            onCloseAction={handleContinue}
            mainTextAction={confirmButton}
            secondaryTextAction={cancelButton}
            closeIconSize={22}
        >
            <div className='idle-modal__content'>{formatedModalText}</div>
        </Modal>
    )
}

export default IdleTimeModal
