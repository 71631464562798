'use-strict'

import React, { useState } from 'react'

import RoleFormHeader from './RoleFormHeader'
import RoleFormBody from './RoleFormBody'
import RoleFormFooter from './RoleFormFooter'

import CardLoader from '@/components/CardLoader'
import { scrollTo } from '@/helpers/utils'

import '../Roles.scss'

export default ({
    lang,
    title,
    role,
    loading,
    handlers,
    routing,
    isCreate,
    allPermissions,
    defaultPermissionsRole,
}) => {


    const state = { state: null, message: '' }
    const [name, setName] = useState(state)
    const [description, setDescription] = useState(state)
    const [permissions, setPermissions] = useState(state)

    const onSave = () => {
        let hasError = false
        const errorState = { ...state, state: 'error' }
        const noErrorState = { ...state, state: null }

        if (role.name == null || role.name == '') {
            hasError = true
            const error = { ...errorState, message: lang.validations.thisIsAMandatoryField }
            setName(error)
        } else {
            setName(noErrorState)
        }
        if (role.description == null || !role.description.length) {
            hasError = true
            const error = { ...errorState, message: lang.validations.thisIsAMandatoryField }
            setDescription(error)
        } else {
            setDescription(noErrorState)
        }
        if (defaultPermissionsRole.length <= 0) {
            hasError = true
            const error = { ...errorState, message: lang.validations.atLeastOnePermission }
            setPermissions(error)
        } else {
            setPermissions(noErrorState)
        }

        if (hasError) {
            scrollTo(window, 0)
            return
        }

        handlers.onSave()
    }


    return (
        <div className="container">
            <RoleFormHeader
                lang={lang}
                title={title}
            />
            <div className="_roles_container">
                <CardLoader show={loading.loadSingleRole} />
                <RoleFormBody
                    lang={lang}
                    validations={{
                        name,
                        description,
                        permissions
                    }}
                    data={role}
                    handlers={handlers}
                    isCreate={isCreate}
                    allPermissions={allPermissions}
                    defaultPermissionsRole={defaultPermissionsRole}
                />
                <RoleFormFooter
                    lang={lang}
                    goBack={routing.goToRolesList}
                    onSave={onSave}
                    loading={loading}
                />
            </div>
        </div>
    )
}
