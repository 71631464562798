import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import useCourseInfo from './shared/hooks/useCourseInfo'
import DashboardHeader from './shared/components/DashboardHeader/DashboardHeader'
import EmptyState from './shared/components/EmptyState'
import { atomAsset } from '@/assets/img/config'

const Dashboard = ({ match }) => {
    const courseId = match.params.courseId
    const emptySelection = useSelector(({ langStore }) => langStore.dashboard.emptySelection)
    const { name, alias, is_massive } = useCourseInfo(courseId)

    return (
        <div className='container dashboard-wrapper'>
            <DashboardHeader courseName={name} courseAlias={alias} courseMassive={is_massive} />
            <EmptyState
                text={emptySelection}
                id='dashboardIndexEpmtyState'
                imgUrl={atomAsset('grayscale-chart.svg')}
            />
        </div>
    )
}

Dashboard.propTypes = {
    match: PropTypes.object,
}

Dashboard.defaultProps = {
    match: {},
}

export default Dashboard
