import configureStore from '@/app/store'
import { STUDENT_GUARD, ADMIN_GUARD } from '@/constants/guards'
import { CLASSROOM_LOCATION } from '@/constants/locations'
import * as context from '@/redux/reducers/contextReducer'

const guards = [
    {
        name: STUDENT_GUARD,
        defaultPath: '/',
    },
    {
        name: ADMIN_GUARD,
        defaultPath: '/admin',
    },
]

export const setGuard = (guard) => configureStore.store.dispatch(context.setGuard(guard))
// export const getGuard = () => configureStore.store.getState().context.guard
export const getGuard = () => {
    const isAdminUrl = /^\/admin(\/)?/.test(location?.pathname ?? '');
    return isAdminUrl ? ADMIN_GUARD : STUDENT_GUARD;
}

export const setLocation = (location) =>
    configureStore.store.dispatch(context.setLocation(location))
export const getLocation = () => configureStore.store.getState().context.location

export const isClassroomLocation = () => getLocation() === CLASSROOM_LOCATION

export default guards
