import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import useHistory from '@/hooks/useHistory'
import { getOwnersSelector } from '@/redux/selectors/owner'
import { getOwners, removeOwner, toggleOwnerVisibility } from '@/redux/thunks/ownerThunks'
import OwnerList from './OwnerList'
import {
    OWNER_ENROLMENT_SEARCH,
    DELETE_OWNER,
    EDIT_OWNER_PERMISSIONS,
} from '../../../../../constants/coursePermissions'
import { loadCourse } from '@/redux/thunks/courseThunks'
import { loadCoursePermissions, clearCoursePermissionsAction } from '@/redux/reducers/_deprecated/authReducer'
import { clearAllOwnersAction } from '@/redux/reducers/ownerReducer'
import { clearCourse } from '@/redux/reducers/courseReducer'

const OwnerListContainer = ({ match }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const owners = useSelector(getOwnersSelector)
    const courseId = match.params.courseId
    const { name } = useSelector(({ course }) => course.course)
    const lang = useSelector(({ langStore }) => langStore.ownerList)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const loadResources = async () => {
            setLoading(true)
            await dispatch(getOwners(courseId))
            await dispatch(loadCourse(courseId))
            await dispatch(loadCoursePermissions(courseId))
            setLoading(false)
        } 

        loadResources()

        return () => {
            dispatch(clearAllOwnersAction())
            dispatch(clearCourse())
            dispatch(clearCoursePermissionsAction())
        }
    }, [dispatch, courseId])

    const handleGoBack = useCallback(() => history.goBack(), [history])

    const onClickDeleteOwner = useCallback(
        ownerId => {
            dispatch(removeOwner(courseId, ownerId))
        },
        [dispatch, courseId],
    )

    const onClickToggleVisibility = useCallback(
        userId => {
            dispatch(toggleOwnerVisibility(userId, courseId))
        },
        [dispatch, courseId],
    )

    return (
        <OwnerList
            courseId={courseId}
            owners={owners}
            lang={lang}
            courseName={name}
            loading={loading}
            onClickDeleteOwner={onClickDeleteOwner}
            showEnrolmentButton={OWNER_ENROLMENT_SEARCH()}
            showDeleteButton={DELETE_OWNER()}
            showEditPermissionsButton={EDIT_OWNER_PERMISSIONS()}
            showVisibilityButton={EDIT_OWNER_PERMISSIONS()}
            handleGoBack={handleGoBack}
            onClickToggleVisibility={onClickToggleVisibility}
        />
    )
}

OwnerListContainer.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            courseId: PropTypes.string,
        }),
    }).isRequired,
}

export default OwnerListContainer
