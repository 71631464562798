import React, { useState, useEffect } from 'react'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'

const FilenamePopup = ({ fileName, onCancel, onSave }) => {
    const [name, ext] = fileName.split('.')
    const [nameError, setNameError] = useState(false)
    const [stateName, setStateName] = useState('')
    useEffect(() => {
        setStateName(name)
    }, [name])
    return (
        <div className='filename-popup'>
            <div className='filename-popup__modal'>
                <form onSubmit={() => {
                    if (stateName.length <= 1) {
                        setNameError(true)
                        return
                    }
                    onSave(fileName, `${stateName}.${ext}`)
                    onCancel()
                }}>
                    <div className='filename-popup__modal-header'>Change file name</div>
                    <div className='filename-popup__modal-body'>
                        <div className='filename-popup__modal-input'>
                            <input
                                type='text'
                                value={stateName}
                                onChange={(e) => setStateName(e.target.value)}
                            />
                            {nameError && (
                                <span className='filename-popup__modal-input-error'>
                                    The length of the name must be greater than 1
                                </span>
                            )}
                        </div>
                        <span className='filename-popup__modal-extension'>.{ext}</span>
                    </div>
                    <div className='filename-popup__modal-footer'>
                        <Button
                            type='button'
                            text='Cancel'
                            onClick={onCancel}
                            theme='secondary'
                        />
                        <Button
                            type='submit'
                            theme='primary'
                            text='Save'
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default FilenamePopup
