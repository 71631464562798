import React from 'react'
import PropTypes from 'prop-types'
import { X, ChevronsRight } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import ReadOnly from '../Sections/File/ReadOnly'

const Header = ({
    showSidebar,
    openFiles,
    activeFile,
    setActiveFile,
    closeFile,
    readOnlySections,
    setShowSidebar,
}) => {
    return (
        <div
            className='header'
            style={{
                paddingLeft: showSidebar ? '230px' : '40px',
                width: '100%',
            }}
        >
            <ul>
                {!showSidebar && (
                    <li className='showSidebar' onClick={() => setShowSidebar(true)}>
                        <ChevronsRight />
                    </li>
                )}
                {openFiles.map((file, i) => {
                    return (
                        <li
                            key={`sidebar${file.name}${i}`}
                            active={`${activeFile?._id === file._id}`}
                        >
                            <span
                                onClick={() =>
                                    setActiveFile(file)
                                }
                            >
                                <ReadOnly
                                    readOnlySections={readOnlySections}
                                    sectionName={file.section}
                                />
                                {file.name}
                            </span>
                            <span
                                onClick={(e) => {
                                    e.stopPropagation()
                                    closeFile(file)
                                }}
                            >
                                <X size={14} />
                            </span>
                        </li>
                    )
                })}
            </ul>
            <div className='workingArea'>
                {openFiles.length
                    ? `path: ${activeFile?.section} > ${activeFile?.name}`
                    : '(0) open files'}
            </div>
        </div>
    )
}

Header.propTypes = {
    showSidebar: PropTypes.bool,
    openFiles: PropTypes.array,
    readOnlySections: PropTypes.array,
    activeFile: PropTypes.object,
    setActiveFile: PropTypes.func,
    closeFile: PropTypes.func,
    setShowSidebar: PropTypes.func,
}

Header.defaultProps = {
    showSidebar: true,
    openFiles: [],
    readOnlySections: [],
    activeFile: {},
    setActiveFile: () => {},
    closeFile: () => {},
    setShowSidebar: () => {},
}

export default Header
