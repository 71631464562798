'use-strict'

import React from 'react'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'

import ExerciseDataForm from './ExerciseDataForm'
import ExerciseTabsForm from '@/components/ExerciseTabs/ExerciseTabsForm'
import CustomTabs from '@/components/CustomTabs'
import Playground from '@/components/Playground'
import { history } from '@/app/store'
import CardLoader from '@/components/CardLoader'
import ModalConfirmation from '@/components/ModalConfirmation'
import { AlertCircle } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { stripHtml } from '@/helpers/utils'
import './ExerciseManagerForm.scss'
import exerciseTypes from '@/helpers/exercises/exerciseTypes'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import { BookOpen } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import IframeExercise from './IframeExercise'

const ExerciseManagerForm = ({
    hasProgress = false,
    title = '',
    exerciseType = '',
    handlers = {
        setField: () => {},
        save: () => {},
    },
    data,
    solvedValidations = {},
    lang = {},
    allRuleTypes = [],
    allTags = [],
    loading = {},
    playground = {
        sections: [],
    },
    availableFiles,
    location = {},
}) => {
    const state = { state: null, message: lang.thisIsAMandatoryField }
    const [name, setName] = React.useState(state)
    const [statement, setStatement] = React.useState(state)
    const [hints, setHints] = React.useState(state)
    const [moral, setMoral] = React.useState(state)
    const [hasProgressShow, setHasProgressShow] = React.useState(false)
    const [urlReference, setUrlReference] = React.useState('')

    const handleChangeFileName = (changedFiles) => {
        handlers.setField('files', [
            ...data.files.filter((f) => !changedFiles.find((mf) => mf._id === f._id)),
            ...changedFiles,
        ])
    }
    const handleChangeEditor = (file, content) => {
        const matchFile = data.files.find((f) => f._id === file._id)
        handlers.setField('files', [
            ...data.files.filter((f) => f._id !== matchFile._id),
            { ...matchFile, content },
        ])
    }
    const handleUrlReference = (url) => {
        handlers.setField('urlReference', url)
        setUrlReference(url)
    }

    const handleChangeDefaultFile = (file) => {
        const matchFile = data.files.find((f) => f._id === file._id)
        handlers.setField('files', [
            ...data.files.filter((f) => f._id !== matchFile._id),
            { ...matchFile, defaultOpen: !matchFile.defaultOpen },
        ])
    }
    const onSave = () => {
        let hasError = false
        const errorState = { ...state, state: 'error' }
        const noErrorState = { ...state, state: null }
        if (data.name === '') {
            hasError = true
            setName(errorState)
        } else {
            setName(noErrorState)
        }
        if (stripHtml(data.statement).trim() === '') {
            hasError = true
            setStatement(errorState)
        } else {
            setStatement(noErrorState)
        }
        if (data.moral === '') {
            hasError = true
            setMoral(errorState)
        } else {
            setMoral(noErrorState)
        }
        if (!data.hints.length) {
            hasError = true
            setHints(errorState)
        } else {
            setHints(noErrorState)
        }
        if (hasError) {
            return
        }
        handlers.save()
    }
    const isUsedTooltip = () => {
        if (hasProgress) {
            return (
                <span className='span__usedTooltip'>
                    <Tooltip tooltipId='isUsed' tooltipContent={lang.isUsedAlert.tooltip}>
                        <AlertCircle />
                    </Tooltip>
                </span>
            )
        }
        return null
    }
    const logsOrThrows = () => {
        const logs = []
        const throws = []
        solvedValidations.output.forEach((elem) => {
            if (elem.type === 'log') {
                elem.logs.forEach((log) => logs.push(log))
            }
            if (elem.type === 'throw') {
                throws.push(elem.msg)
            }
        })
        return [logs, throws]
    }

    return (
        <>
            <div className='container'>
                <div className='__main_header'>
                    <h1 className='__main_title excercise-title'>
                        {isUsedTooltip()}
                        {title}
                    </h1>
                    <Button
                        className='pull-right'
                        icon={<BookOpen size={20} />}
                        id='exerciseWiki'
                        onClick={() => window.open('https://pg-wiki.digitalhouse.com', '_blank')}
                        text={lang.goToWiki}
                        theme='tertiary'
                    />
                </div>
                <div className='exercise_container'>
                    <CardLoader show={loading.loadSingleExercise} />
                    <CustomTabs
                        activeKey='general_data'
                        tabs={[
                            {
                                key: 'general_data',
                                title: lang.generalData,
                                component: (
                                    <>
                                        <ExerciseDataForm
                                            data={data}
                                            lang={lang}
                                            allTags={allTags}
                                            validations={{
                                                name,
                                                statement,
                                            }}
                                            micInitial={data.allowMicrophone}
                                            camInitial={data.allowCamera}
                                            location={location}
                                            handlers={handlers}
                                        />
                                        <ExerciseTabsForm
                                            data={data}
                                            lang={lang}
                                            allRuleTypes={allRuleTypes}
                                            validations={{
                                                hints,
                                                moral,
                                            }}
                                            handlers={handlers}
                                            availableFiles={availableFiles}
                                            showRulesTab={allRuleTypes.length > 0}
                                        />
                                    </>
                                ),
                            },
                            {
                                key: 'config',
                                title: lang.exerciseConfig,
                                component: (
                                    <>
                                        {exerciseType === 'IFRAME' ? (
                                            <IframeExercise 
                                                urlReference={urlReference}
                                                defaultUrlReference={data?.urlReference}
                                                setUrlReference={handleUrlReference}
                                            />
                                        ) : (
                                            <Playground
                                                {...playground}
                                                files={data.files}
                                                executing={loading.executePreview}
                                                content={data.compiled}
                                                exercise={data}
                                                solvedValidations={solvedValidations}
                                                lang={lang.playground}
                                                handleChange={handleChangeEditor}
                                                handleChangeDefaultFile={handleChangeDefaultFile}
                                                handleChangeFileName={handleChangeFileName}
                                                onValidate={() => handlers.preview(exerciseType)}
                                                logs={logsOrThrows()[0]}
                                                throws={logsOrThrows()[1]}
                                                isAdministration
                                                allowFilesNameChange={
                                                    !hasProgress &&
                                                    exerciseType === exerciseTypes.JAVA_JDK11
                                                }
                                            />
                                        )}
                                    </>
                                ),
                            },
                        ]}
                    />
                    <div className='clearfix'></div>
                    <div className='__main_footer'>
                        <ButtonGroup className='pull-right'>
                            <Button
                                id='GoBackButton'
                                theme='secondary'
                                onClick={() => history.push('/admin/exercises')}
                                text={lang.return}
                                style={{ marginRight: '20px' }}
                            />
                            <Button
                                id='SaveExcerciseButton'
                                theme='primary'
                                loading={loading.saveExercise}
                                onClick={() => (hasProgress ? setHasProgressShow(true) : onSave())}
                                text={lang.saveExersise}
                            />
                        </ButtonGroup>
                    </div>
                </div>
            </div>
            <ModalConfirmation
                show={hasProgressShow}
                confirmTitle={lang.isUsedAlert.confirmTitle}
                cancelTitle={lang.isUsedAlert.cancelTitle}
                body={<div dangerouslySetInnerHTML={{ __html: lang.isUsedAlert.body }} />}
                onConfirm={onSave}
                onCancel={() => setHasProgressShow(false)}
            />
        </>
    )
}

export default ExerciseManagerForm
