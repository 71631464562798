import { ADMIN_GUARD } from '@/constants/guards'

import BackofficeLayout from '@/components/Layout/BackofficeLayout'
import BackofficeLandingPage from '@/components/BackofficeLandingPage'

import CourseList from '@/modules/Backoffice/Courses/List'
import UploadCsv from '@/modules/Backoffice/Users/UploadCsv'
import Course from '@/modules/Backoffice/Courses/Course'
import { InstituteList, InstituteDetail, InstituteForm } from '@/modules/Backoffice/Institutes'
import UserList from '@/modules/Backoffice/Users/List'
import UserForm from '@/modules/Backoffice/Users/Form'
import UserDetail from '@/modules/Backoffice/Users/Detail'
import Surveys from '@/modules/Backoffice/Courses/Surveys'
import QuizList from '@/modules/Backoffice/Quizzes/List'
import QuizCreate from '@/modules/Backoffice/Quizzes/Create'
import QuizEdit from '@/modules/Backoffice/Quizzes/Edit'
import QuizCopy from '@/modules/Backoffice/Quizzes/Copy'
import { TabsList, TabsEdit } from '@/modules/Backoffice/Courses/CourseTabs'
import SendEmails from '@/modules/Backoffice/Courses/Emails'
import FileManager from '@/modules/Backoffice/Courses/Files'
import CourseReports from '@/modules/Backoffice/Courses/CourseReports'
import { PreworkReport } from '@/modules/Backoffice/Courses/CourseReports/Prework'
import { PreworkUserReport } from '@/modules/Backoffice/Courses/CourseReports/Prework'
import ExerciseManagerForm from '@/modules/Backoffice/Exercises/Form'
import RoleList from '@/modules/Backoffice/Roles/List'
import RoleForm from '@/modules/Backoffice/Roles/Form'
import StudentList from '@/modules/Backoffice/Courses/Students'
import * as permissions from '@digitalhouse-tech/ts-lib-store/lib/session-storage/permission'
import * as coursePermissions from '@/constants/coursePermissions'
import OwnerAssign from '@/modules/Backoffice/Courses/Owners/Assign'
import OwnerEdit from '@/modules/Backoffice/Courses/Owners/Edit'
import OwnerList from '@/modules/Backoffice/Courses/Owners/List'
import CourseTools from '@/modules/Backoffice/Courses/CourseTools'
import Reports from '@/modules/Backoffice/Courses/Dashboard/reports'
import Dashboard from '@/modules/Backoffice/Courses/Dashboard'
import Quizzes from '@/modules/Backoffice/Courses/Dashboard/Quizzes'
import GradesList from '@/modules/Backoffice/Courses/Dashboard/Grades/List'
import EvaluableActivityList from '@/modules/Backoffice/Courses/Dashboard/EvaluableActivity/List'
import ReviewActivity from '@/modules/Backoffice/Courses/Dashboard/EvaluableActivity/Review'
import ExerciseList from '@/modules/Backoffice/Exercises/List'
import ClassProgress from '@/modules/Backoffice/Courses/Dashboard/ClassProgress'

import { checkPermissionsGroup } from '@digitalhouse-tech/ts-lib-store/lib/session-storage/permission'
import { NotionRefresh } from '@/modules/Backoffice/Notion'

const routes = [
    {
        path: '/',
        key: 'Backoffice - Landing',
        exact: true,
        permission: permissions.BACKOFFICE_PERMISSION,
        component: BackofficeLandingPage,
        secured: true,
    },
    {
        path: '/courses',
        key: 'Backoffice - Courses',
        exact: true,
        permission: permissions.NAVIGATE_COURSE,
        component: CourseList,
        secured: true,
    },
    {
        path: '/courses/:courseId',
        key: 'Backoffice - Course Index',
        exact: true,
        permission: () =>
            checkPermissionsGroup.oneOf([coursePermissions.VIEW_COURSE, permissions.VIEW_USER]),
        component: Course,
        secured: true,
    },
    {
        path: '/courses/:courseId/enrolments',
        key: 'Backoffice - Course Students',
        exact: true,
        permission: coursePermissions.VIEW_STUDENT,
        component: StudentList,
        secured: true,
    },
    {
        path: '/courses/:courseId/dashboard',
        key: 'Backoffice - Dashboard',
        exact: true,
        permission: coursePermissions.VIEW_COURSE_PROGRESS_REPORT,
        component: Dashboard,
        secured: true,
    },
    {
        path: '/courses/:courseId/dashboard/general',
        key: 'Backoffice - Course Reports V1',
        exact: true,
        permission: coursePermissions.VIEW_COURSE_PROGRESS_REPORT,
        component: Reports,
        secured: true,
    },
    {
        path: '/courses/:courseId/dashboard/grades',
        key: 'Backoffice - Course Reports V1',
        exact: true,
        permission: coursePermissions.VIEW_COURSE_PROGRESS_REPORT,
        component: GradesList,
        secured: true,
    },
    {
        path: '/courses/:courseId/dashboard/quizzes',
        key: 'Backoffice - Quiz Reports',
        exact: true,
        permission: coursePermissions.VIEW_COURSE_PROGRESS_REPORT,
        component: Quizzes,
        secured: true,
    },
    {
        path: '/courses/:courseId/dashboard/evaluable-activity',
        key: 'Backoffice - Evaluable Activity',
        exact: true,
        permission: coursePermissions.VIEW_COURSE_PROGRESS_REPORT,
        component: EvaluableActivityList,
        secured: true,
    },
    {
        path: '/courses/:courseId/dashboard/evaluable-activity/blocks/:blockId/users/:userId/review',
        key: 'Backoffice - Review Activity',
        exact: true,
        permission: coursePermissions.VIEW_COURSE_PROGRESS_REPORT,
        component: ReviewActivity,
        secured: true,
    },
    {
        path: '/courses/:courseId/dashboard/class-progress',
        key: 'Backoffice - Class Progress Report',
        exact: true,
        permission: coursePermissions.VIEW_COURSE_PROGRESS_REPORT,
        component: ClassProgress,
        secured: true,
    },
    {
        path: '/users/csv',
        key: 'Backoffice - Enrolment User Csv',
        exact: true,
        permission: () =>
            checkPermissionsGroup.oneOf([
                coursePermissions.STUDENT_ENROLMENT_CSV,
                permissions.CREATE_USER,
            ]),
        component: UploadCsv,
        secured: true,
    },
    {
        path: '/notion',
        key: 'Backoffice - Notion',
        exact: true,
        permission: permissions.BACKOFFICE_PERMISSION,
        component: NotionRefresh,
        secured: true,
    },
    {
        path: '/institutes',
        key: 'Backoffice - Institutes',
        exact: true,
        permission: permissions.NAVIGATE_INSTITUTE,
        component: InstituteList,
        secured: true,
    },
    {
        path: '/institutes/:instituteId/view',
        key: 'Backoffice - Institutes',
        exact: true,
        permission: permissions.VIEW_INSTITUTE,
        component: InstituteDetail,
        secured: true,
    },
    {
        path: '/institutes/create',
        key: 'Backoffice - Institutes create',
        exact: true,
        permission: permissions.CREATE_INSTITUTE,
        component: InstituteForm,
        secured: true,
    },
    {
        path: '/institutes/:id/edit',
        key: 'Backoffice - Institutes edit',
        exact: true,
        permission: permissions.EDIT_INSTITUTE,
        component: InstituteForm,
        secured: true,
    },
    {
        path: '/users',
        key: 'Backoffice - Users',
        exact: true,
        permission: permissions.NAVIGATE_USER,
        component: UserList,
        secured: true,
    },
    {
        path: '/users/create',
        key: 'Backoffice - Users create',
        exact: true,
        permission: permissions.CREATE_USER,
        component: UserForm,
        secured: true,
    },
    {
        path: '/users/:id/edit/',
        key: 'Backoffice - Users edit',
        exact: true,
        permission: permissions.EDIT_USER,
        component: UserForm,
        secured: true,
    },
    {
        path: '/users/:userId/detail',
        key: 'Backoffice - User Detail',
        exact: true,
        permission: permissions.VIEW_USER,
        component: UserDetail,
        secured: true,
    },
    {
        path: '/courses/:courseId/owners',
        key: 'Backoffice - Owners',
        exact: true,
        permission: coursePermissions.VIEW_OWNERS,
        component: OwnerList,
        secured: true,
    },
    {
        path: '/courses/:courseId/owners/add',
        key: 'Backoffice - Assign Owners',
        exact: true,
        permission: coursePermissions.OWNER_ENROLMENT_SEARCH,
        component: OwnerAssign,
        secured: true,
    },
    {
        path: '/courses/:courseId/owners/:userId/edit',
        key: 'Backoffice - Edit Owners',
        exact: true,
        permission: coursePermissions.EDIT_OWNER_PERMISSIONS,
        component: OwnerEdit,
        secured: true,
    },
    {
        path: '/courses/:id/surveys',
        key: 'Backoffice - Course Surveys',
        exact: true,
        permission: coursePermissions.NAVIGATE_SURVEY,
        component: Surveys,
        secured: true,
    },
    {
        path: '/quizzes',
        key: 'Backoffice - Quizzes',
        exact: true,
        permission: permissions.NAVIGATE_QUIZ,
        component: QuizList,
        secured: true,
        layout: BackofficeLayout,
    },
    {
        path: '/quizzes/create',
        key: 'Backoffice - Quizzes create',
        exact: true,
        permission: permissions.CREATE_QUIZ,
        component: QuizCreate,
        secured: true,
    },
    {
        path: '/quizzes/:quizId/edit',
        key: 'Backoffice - Quizzes edit',
        exact: true,
        permission: permissions.EDIT_QUIZ,
        component: QuizEdit,
        secured: true,
    },
    {
        path: '/quizzes/:quizId/copy',
        key: 'Backoffice - Quizzes copy',
        exact: true,
        permission: permissions.CREATE_QUIZ,
        component: QuizCopy,
        secured: true,
    },
    {
        path: '/courses/:courseId/tabs',
        key: 'Backoffice - Tabs',
        exact: true,
        permission: coursePermissions.VIEW_TABS,
        component: TabsList,
        secured: true,
    },
    {
        path: '/courses/:courseId/tabs/:tabId',
        key: 'Backoffice - Tab edit',
        exact: true,
        permission: coursePermissions.EDIT_TABS,
        component: TabsEdit,
        secured: true,
    },
    {
        path: '/courses/:courseId/emails',
        key: 'Backoffice - Course Send Email',
        exact: true,
        permission: coursePermissions.SEND_EMAIL,
        component: SendEmails,
        secured: true,
    },
    {
        path: '/courses/:id/files',
        key: 'Backoffice - Course Files',
        exact: true,
        permission: coursePermissions.VIEW_FILE,
        component: FileManager,
        secured: true,
    },
    {
        path: '/course/:courseId/reports',
        key: 'Backoffice - Course Reports',
        exact: true,
        permission: coursePermissions.VIEW_STUDENT_PROGRESS_REPORT,
        component: CourseReports,
        secured: true,
    },
    {
        path: '/courses/:courseId/preworks',
        key: 'Backoffice - Course Prework Reports',
        exact: true,
        permission: coursePermissions.VIEW_STUDENT_PROGRESS_REPORT,
        component: PreworkReport,
        secured: true,
    },
    {
        path: '/courses/:courseId/preworks/users/:userId',
        key: 'Backoffice - Course Prework Reports per User',
        exact: true,
        permission: coursePermissions.VIEW_STUDENT_PROGRESS_REPORT,
        component: PreworkUserReport,
        secured: true,
    },
    {
        path: '/exercises',
        key: 'Backoffice - Exercises',
        exact: true,
        permission: permissions.NAVIGATE_EXERCISE,
        component: ExerciseList,
        secured: true,
    },
    {
        path: '/exercises/create',
        key: 'Backoffice - Exercises create',
        exact: true,
        permission: permissions.CREATE_EXERCISE,
        component: ExerciseManagerForm,
        secured: true,
    },
    {
        path: '/exercises/:id/edit',
        key: 'Backoffice - Exercises edit',
        exact: true,
        permission: permissions.EDIT_EXERCISE,
        component: ExerciseManagerForm,
        secured: true,
    },
    {
        path: '/roles',
        key: 'Backoffice - Roles',
        exact: true,
        permission: permissions.NAVIGATE_ROLES,
        component: RoleList,
        secured: true,
    },
    {
        path: '/roles/create',
        key: 'Backoffice - Roles create',
        exact: true,
        permission: permissions.CREATE_ROLE,
        component: RoleForm,
        secured: true,
    },
    {
        path: '/roles/:id/edit',
        key: 'Backoffice - Roles edit',
        exact: true,
        permission: permissions.EDIT_ROLES,
        component: RoleForm,
        secured: true,
    },
    {
        path: '/course/:courseId/tools',
        key: 'Backoffice - Tools manager',
        exact: true,
        permission: coursePermissions.VIEW_TOOLS,
        component: CourseTools,
        secured: true,
    },
]

export default routes.map((route) => ({
    ...route,
    path: `/admin${route.path}`,
    layout: BackofficeLayout,
    guard: ADMIN_GUARD,
}))
