import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import sectionDropdowns from './dropdowns/sectionDropdowns'
import SectionTitle from '@digitalhouse-tech/react-lib-ui-explorer/lib/SectionTitle'
import InputCustomComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputCustomComboBox'
import DropdownItem from '@digitalhouse-tech/react-lib-ui-explorer/lib/DropdownItem'
import { history } from '@/app/store'
import './DashboardHeader.scss'
import {
    EVALUABLE_ACTIVITY_SECTION,
    INITIAL_SECTION,
    QUIZZES_SECTION,
    GENERAL_PROGRESS_SECTION,
    CLASS_PROGRESS_SECTION,
    DASHBOARD_ROUTE,
    GRADES_SECTION,
} from '../../constants/dashboard'

const DashboardHeader = ({ section, courseName, courseAlias, courseMassive }) => {
    const lang = useSelector(({ langStore }) => langStore.dashboard)
    const { assessment_strategy_id: strategyId } = useSelector((state) => state.course.course)

    const sectionItems = sectionDropdowns(lang, strategyId)
    const onSelect = (item) => {
        const isDashboardIndex = window.location.pathname.split('/').pop() === DASHBOARD_ROUTE
        const sectionRoute = `${isDashboardIndex ? `${DASHBOARD_ROUTE}/` : ``}${item.props.value}`
        history.push(sectionRoute)
    }

    const createDropdownItem = (item) => (
        <DropdownItem
            id={item.id}
            text={item.text}
            theme={item.theme}
            value={item.value}
            icon={item.icon}
            strong
            disabled={item.disabled}
        />
    )

    const getDropdownItems = () => {
        return sectionItems
            .filter(
                ({ key, massiveCourseEnabled }) =>
                    key !== section &&
                    key !== INITIAL_SECTION &&
                    (!courseMassive || massiveCourseEnabled === courseMassive)
            )
            .map((item) => createDropdownItem(item))
    }

    return (
        <div className='dashboard-header'>
            <div className='dashboard-header__titles'>
                <SectionTitle id='dashboardTitle' text={lang.reportsAndResults} />
                <div className='dashboard-header__titles__course'>
                    {courseAlias && (
                        <span className='dashboard-header__titles__course__alias'>
                            {courseAlias}
                        </span>
                    )}
                    {courseName && (
                        <span className='dashboard-header__titles__course__name'>{courseName}</span>
                    )}
                </div>
            </div>
            <div className='dashboard-header__select'>
                <InputCustomComboBox
                    id='sectionSelect'
                    selected={createDropdownItem(sectionItems.find(({ key }) => key === section))}
                    onSelect={(item) => onSelect(item)}
                    dropdownItems={getDropdownItems()}
                />
            </div>
        </div>
    )
}

DashboardHeader.propTypes = {
    section: PropTypes.oneOf([
        QUIZZES_SECTION,
        GENERAL_PROGRESS_SECTION,
        INITIAL_SECTION,
        CLASS_PROGRESS_SECTION,
        EVALUABLE_ACTIVITY_SECTION,
        GRADES_SECTION,
    ]),
    courseName: PropTypes.string,
    courseAlias: PropTypes.string,
    courseMassive: PropTypes.bool,
}

DashboardHeader.defaultProps = {
    section: INITIAL_SECTION,
    courseName: '',
    courseAlias: '',
    courseMassive: false,
}

export default DashboardHeader
