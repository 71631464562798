import { ASSETS_URL } from '@/config/env'

// CLOUD MAIN FOLDERS
const LOGOS = 'logos'
const ICONS = 'icons'
const BACKGROUNDS = 'backgrounds'
const EMPTY_STATES = 'empty-states'
const ATOMS = 'atoms'
const ANIMATION = 'animation'
const REPORTS = 'reports'

export const logoAsset = (asset) => `${ASSETS_URL}/${LOGOS}/${asset}`
export const iconAsset = (asset) => `${ASSETS_URL}/${ICONS}/${asset}`
export const backgroundAsset = (asset) => `${ASSETS_URL}/${BACKGROUNDS}/${asset}`
export const emptyStateAsset = (asset) => `${ASSETS_URL}/${EMPTY_STATES}/${asset}`
export const atomAsset = (asset) => `${ASSETS_URL}/${ATOMS}/${asset}`
export const animationAsset = (asset) => `${ASSETS_URL}/${ANIMATION}/${asset}`
export const reportAsset = (asset) => `${ASSETS_URL}/${REPORTS}/${asset}`
