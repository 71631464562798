import React from 'react'
import TableGeneralGrade from '../TableGeneralGrade/TableGeneralGrade'
import TableProgress from '../TableProgress/TableProgress'
import TablePresence from '../TablePresence/TablePresence'

export default (assessmentsTypes, lang) => {
    const { headers } = lang
    const tempHeaders = assessmentsTypes.map((type) => ({
        label: type.name,
        key: type.id,
    }))
    return [
        // {
        //     label: headers.presenteeism,
        //     key: '',
        //     component: () => <TablePresence presence={99}/>
        // },
        {
            label: headers.progress,
            key: '',
            component: (row) => <TableProgress progress={row.progress} />,
        },
        ...tempHeaders,
        {
            label: headers.courseNote,
            key: 'subjectGrade',
            component: (row) => (
                <TableGeneralGrade
                    subjectGrade={row.subjectGrade}
                    recover={row.allowRecoverySubject}
                />
            ),
        },
    ]
}