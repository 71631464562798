import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'

const GenericModal = ({
    cancelTitle,
    children,
    className,
    confirmTitle,
    onCancel,
    onConfirm,
    show,
    titleModal,
}) => {
    return (
        <Modal
            backdrop="static"
            bsSize="large"
            className={`__modal ${className}`}
            keyboard={false}
            onHide={onCancel}
            show={show}
        >
            <Modal.Header className="__modal_header __border_bottom" closeButton>
                <h3 className="__modal_title">{titleModal}</h3>
            </Modal.Header>
            <Modal.Body className="__modal_body">
                {children}
            </Modal.Body>
            <Modal.Footer className="__modal_footer __border_top">
                <ButtonGroup className='pull-right'>
                    <Button
                        id="CancelButton"
                        onClick={onCancel}
                        text={cancelTitle}
                        theme='tertiary'
                    />

                    <Button
                        id="ConfirmButton"
                        onClick={onConfirm}
                        text={confirmTitle}
                        theme='primary'
                    />
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    )
}

GenericModal.propTypes = {
    cancelTitle: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    className: PropTypes.string,
    confirmTitle: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    show: PropTypes.bool,
    titleModal: PropTypes.string,
}

GenericModal.defaultProps = {
    cancelTitle: '',
    children: [],
    className: '',
    confirmTitle: '',
    onCancel: () => { },
    onConfirm: () => { },
    show: false,
    titleModal: '',
}

export default GenericModal