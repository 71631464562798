import { useSelector } from 'react-redux'
import { getContentMap } from '@/redux/selectors/students/classroom'
import { NO_MORE_CONTENT, UNIT, LESSON, TOPIC } from '@/constants/student'

export default (topicId, resolver) => {
    const { Lessons, ...unit } = useSelector(getContentMap)

    const resolve = {
        next: idx => idx + 1,
        prev: idx => idx - 1,
    }[resolver]

    const spike = {
        next: items => items[0],
        prev: items => items[items.length - 1],
    }[resolver]

    if (!Lessons) return {}

    const lessonIdx = Lessons.findIndex(lesson => lesson.Topics.find(topic => topic.id == topicId))

    const currentLesson = Lessons[lessonIdx]

    if (!currentLesson) return {}

    const topicIdx = currentLesson.Topics.findIndex(topic => topic.id == topicId)

    const topic = currentLesson.Topics.find((_, i) => i === resolve(topicIdx))

    if (topic) {
        return {
            type: TOPIC,
            unit_id: unit.id,
            lesson_id: currentLesson.id,
            topic_id: topic.id,
        }
    }

    const lesson = Lessons.find((_, i) => i === resolve(lessonIdx))

    if (lesson) {
        const firstTopic = spike(lesson.Topics)
        return {
            type: LESSON,
            unit_id: unit.id,
            lesson_id: lesson.id,
            topic_id: firstTopic.id,
        }
    }

    const direction = `${resolver}Unit`

    if (unit[direction]) {
        return {
            type: UNIT,
            ...unit[direction],
        }
    }

    return {
        type: NO_MORE_CONTENT,
    }
}
