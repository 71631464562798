import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import GuardRedirect from '../Layout/GuardRedirect'
import { emptyStateAsset } from '@/assets/img/config'
import './NotFoundPage.scss'

const NotFoundPage = () => {
    const imageUrl = emptyStateAsset('404.png')

    const [component, setComponent] = useState()

    const NOT_FOUND = 'notfound'
    const GUARD = 'guard'

    const { title, subtitle, goToHome } = useSelector(
        ({ langStore }) => langStore.notFoundPage
    )

    const map = {
        [NOT_FOUND]: () => (
            <div className='__not_found'>
                <div className='__not_found__content'>
                    <img className='__not_found__content--image' src={imageUrl} alt='Not found' />
                    <div className='__not_found__content--title'>{title}</div>
                    <div className='__not_found__content--subtitle'>{subtitle}</div>
                    <div className='__not_found__content--buttons'>
                        <Button
                            id='GoToMenuButton'
                            theme='secondary'
                            onClick={() => setComponent(GUARD)}
                            text={goToHome}
                        />
                    </div>
                </div>
            </div>
        ),
        [GUARD]: () => <GuardRedirect />,
    }

    useEffect(() => {
        setComponent(NOT_FOUND)
    }, [])

    return map[component] ? map[component]() : null
}

export default NotFoundPage
