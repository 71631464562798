//  MAMUSCHKA's KEYS
export const UNIT_KEY = 'unit'
export const LESSON_KEY = 'lesson'
export const TOPIC_KEY = 'topic'
export const BLOCK_KEY = 'block'

//  BLOCK TYPE
export const QUIZ_BLOCK = 'quiz-block'
export const EVALUABLE_BLOCK = 'evaluable-block'

//  HEADER SECTIONS
export const INITIAL_SECTION = 'INITIAL'
export const GENERAL_PROGRESS_SECTION = 'GENERAL_PROGRESS'
export const EVALUABLE_ACTIVITY_SECTION = 'EVALUABLE_ACTIVITY'
export const QUIZZES_SECTION = 'QUIZZES'
export const CLASS_PROGRESS_SECTION = 'CLASS_PROGRESS'
export const GRADES_SECTION = 'GRADES'

//  DASHBOARD ROUTES
export const DASHBOARD_ROUTE = 'dashboard'
export const GENERAL_ROUTE = 'general'
export const EVALUABLE_ACTIVITY_ROUTE = 'evaluable-activity'
export const QUIZZES_ROUTE = 'quizzes'
export const CLASS_PROGRESS_ROUTE = 'class-progress'
export const GRADES_ROUTE = 'grades'

//  SELECTS DROPDOWN
export const MODULE = 'MODULE'
export const LESSON = 'LESSON'
export const TOPIC = 'TOPIC'
export const BLOCK = 'BLOCK'

//  EMPTY STATE CASES
export const EMPTY_SELECTION = 'EMPTY_SELECTION'
export const EMPTY_PROGRESS = 'EMPTY_PROGRESS'

// EVALUABLE ACTIVITY
export const PENDING_RETRY = 'PENDING_RETRY'
export const PENDING_FEEDBACK = 'PENDING_FEEDBACK'
export const APPROVED = 'APPROVED'
export const FAILED = 'FAILED'
export const INTERNAL = 'INTERNAL'
export const EXTERNAL = 'EXTERNAL'
export const REQUEST_REDO = 'REQUEST_REDO'
export const EVALUATE = 'EVALUATE'

// EVALUABLE ACTIVITY STATUS
export const INFO_STATUS = 'info'
export const DISABLED_STATUS = 'disabled'
export const WARNING_STATUS = 'warning'
export const SUCCESS_STATUS = 'success'
export const DANGER_STATUS = 'danger'
export const CLOCK_ICON = 'Clock'
export const REDO_ICON = 'RotateCw'
export const USER_ICON = 'Users'
export const EXCLAMATION_ICON = 'AlertCircle'
export const CIRCLE_CROSS_ICON = 'XCircle'
export const CIRCLE_CHECK_ICON = 'CheckCircle'
export const FORMAT_TIME = 'DD/MM/YYYY HH:mm'
export const FORMAT_TIME_MONTH_NAME = 'DD MMMM HH:mm'
export const FORMAT_TIME_DAY_MONTH = 'DD/MM HH:mm'
export const HOUR_SUFIX = 'h'

// EVALUABLE ACTIVITY CONFIG && VALIDATIONS
export const MAX_REVIEW_CHARS = 300
export const MAX_REVIEW_CHARS_FEEDBACK = 5000
export const MAX_REJECT_REASONS_TAGS = 3
export const ALLOWED_KEY_CODES = [8, 46, 37, 38, 39, 40]
export const SUMMER_NOTE_CLASSNAME = 'note-editable'
export const FILES_LIMI = 4
export const FILES_UNIT = 'Mb'
export const FILES_TYPES = 'jpg, png, gif, pdf, xcl, ppt, zip'

// EVALUABLE ACTIVITY DEFAULT OBJECTS
export const FEEDBACK = {
    files: [],
    rejectReasons: [],
    reporterId: '',
    feedback: '',
    name: '',
    surname: '',
}

export const HTML_REVIEW_PROPS = { error: false, chars: 0 }

export const DEFAULT_SELECTED = { index: null }

export const MAX_NUMBER_DAYS_REPORT = 92
