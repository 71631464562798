import React, { createRef, useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUser } from '../../../redux/selectors/auth'
import { PasswordModal } from '@/modules/Auth/Login/PasswordModal'
import useHistory from '@/hooks/useHistory'
import { getCourseType } from '@//redux/selectors/course'
import { getGuard, getLocation, isClassroomLocation } from '@/routes/guards'
import { loadLastUserProgress } from '@/redux/thunks/courseThunks'
import { actions as navigationActions } from '@/redux/reducers/navigationReducer'
import { loadCourseNavigation } from '@/redux/thunks/navigationThunks'
import { actions as mamushkaActions } from '@//redux/reducers/mamushkaReducer'
import { getTenantSession } from '@/helpers/tenants/helpers'
import Menu from '@digitalhouse-tech/react-lib-ui-explorer/lib/Menu'
import Navbar from '@/components/Navbar/Navbar'
import { useTabLocation } from './hooks/useTabLocation'
import { ONBOARDING_TYPE } from '@/constants/onboarding'
import Onboarding from '@/components/Onboarding'
import { matchPath } from 'react-router'
import { useLocation } from 'react-router-dom'
import './AppHeader.scss'

const AppHeader = () => {
    const { pathname } = useLocation()
    const match = matchPath(pathname, {
        path: '/course/:courseId',
        strict: true,
    })

    const courseId = match?.params.courseId
    const { courseId: storedCourseId } = useSelector(({ mamushka }) => mamushka)
    const { passwordModal, mainHeader } = useSelector(({ langStore }) => langStore)
    const currentGuard = getGuard()
    const currentLocation = getLocation()
    const { name, surname, id, avatar, email } = useSelector(getUser)
    const { isMobile } = useSelector(({ authReducer }) => authReducer)
    const { lightLogoUrl, logoUrl } = getTenantSession()
    const passwordModalRef = createRef()
    const history = useHistory()
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)

    const setShowPreloader = useCallback(
        (boolean) => {
            dispatch(navigationActions.setShowPreloader(boolean))
        },
        [dispatch]
    )

    const courseType = useSelector(getCourseType)

    const [locationTabs, defaultTabs] = useTabLocation(
        currentGuard,
        currentLocation,
        courseId,
        passwordModalRef
    )

    useEffect(() => {
        const loadLastUserProgressHandler = async () => {
            const { unit_id, lesson_id, topic_id } = await dispatch(loadLastUserProgress(courseId))
            if (!unit_id || !lesson_id || !topic_id) return location.href = '/';

            dispatch(
                mamushkaActions.setMamushkaValues({
                    courseId,
                    unitId: unit_id,
                    lessonId: lesson_id,
                    topicId: topic_id,
                })
            )
        }

        const loadCourseNavigationHandler = async () => {
            setShowPreloader(true)
            await dispatch(loadCourseNavigation(courseId))
            setShowPreloader(false)
        }

        if (courseId && isClassroomLocation()) {
            loadLastUserProgressHandler()

            if (courseId !== storedCourseId) {
                loadCourseNavigationHandler()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseId, currentLocation])

    const getOnboardingWrapper = (menuItem, reference) => {
        return (
            <Onboarding
                key='remote-classroom'
                reference={reference}
                step={ONBOARDING_TYPE.NAV_POPOVER}
            >
                {menuItem}
            </Onboarding>
        )
    }
    const getLocationTabs = () => {
        if (pathname === '/payment-method') {
            return [
                {
                    dataMessage: 'Payment Methods',
                    id: 'paymentMethodTab',
                    isSelected: true,
                    key: 'payment-method-tab',
                    text: 'Método de pago',
                },
            ]
        }
        return locationTabs?.length ? locationTabs : defaultTabs
    }
    const navButtons = currentGuard === 'admin' ? [] : [{
        id: 'myCourses',
        text: 'Mis Cursos',
        onClick: () => location.href = '/',
        isActive: pathname === '/',
    }]
    
    return (
        <>
            <Navbar
                navButtons={navButtons}
                profileButtons={defaultTabs}
                profile={{ name, surname, email, avatar}}
                logo={lightLogoUrl ?? logoUrl}
                pageTitle={pathname === '/' ? 'Playground' : mainHeader?.accessibility.backToHome}
                isMobile={isMobile}
            />
            {(locationTabs?.length || isMobile || pathname === '/payment-method') && (
                <div className='menu-secondary'>
                    <div className="menu-secondary__wrapper">
                        <Menu
                            style={{ boxShadow: 'none' }}
                            testId='menuBanner'
                            isMobile={isMobile}
                            username={name}
                            logoUrl={logoUrl}
                            show={show}
                            insideModule={locationTabs?.length}
                            onClickClose={() => setShow(false)}
                            items={getLocationTabs()}
                            userItems={defaultTabs}
                            getOnboardingComponent={getOnboardingWrapper}
                        />
                    </div>
                </div>
            )}
            <PasswordModal userId={id} ref={passwordModalRef} lang={passwordModal} />
        </>
    )
}

export default AppHeader
