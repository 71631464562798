'use-strict'

import React from 'react'
import { calculatePaginationRange } from '@/helpers/pagination'
import Paginator from '@digitalhouse-tech/react-lib-ui-explorer/lib/Paginator'
import LoadingOrContent from '@digitalhouse-tech/react-lib-ui-explorer/lib/LoadingOrContent'
import RoleCard from './RoleCard'

export default ({
    lang = {},
    loading = false,
    currentPage = 1,
    pageChangeHandler,
    paginated = {
        totalRows: 0,
        rows: [],
        pagesQuantity: 0,
    },
    handlers,
    routing,
    showEditRole,
    showDeleteRole,
}) => (
    <div className='scrollable-wrapper'>
        <LoadingOrContent loading={loading} size='medium'>
            {paginated && paginated.totalRows ? (
                <>
                    {paginated.rows.map((row, i) => (
                        <RoleCard
                            key={`RoleCardNumber${i}`}
                            name={row.description}
                            id={row.id}
                            isDefault={row.is_default}
                            description={row.description}
                            handleEdit={routing.goToEditRole}
                            handleDelete={handlers.deleteRole}
                            lang={lang}
                            showEditRole={showEditRole}
                            showDeleteRole={showDeleteRole}
                        />
                    ))}
                </>
            ) : (
                <>
                    <div className='__search_msg_result'>
                        <p>{lang.findRoles}</p>
                    </div>
                </>
            )}
        </LoadingOrContent>
        {paginated && paginated.totalRows ? (
            <div className='__pagination'>
                <Paginator
                    currentPage={currentPage}
                    disabled={loading}
                    onPageChange={(page) => pageChangeHandler(page)}
                    range={calculatePaginationRange(paginated.pagesQuantity)}
                    totalPages={paginated.pagesQuantity}
                />
            </div>
        ) : (
            <></>
        )}
    </div>
)
