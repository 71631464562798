import initialState from './langStates'

const setLang = 'SET_LANG'

const actions = {
    [setLang]: lang => ({
        type: setLang,
        payload: lang
    })
}

const reducers = {
    [setLang]: (state, payload) => ({ ...state, ...payload })
}

export default  (state = initialState, action) => reducers[action.type] ? reducers[action.type](state, action.payload) : state
export {actions}