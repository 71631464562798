import React from 'react'
import {
    GENERAL_PROGRESS_SECTION,
    EVALUABLE_ACTIVITY_SECTION,
    QUIZZES_SECTION,
    INITIAL_SECTION,
    CLASS_PROGRESS_SECTION,
    DASHBOARD_ROUTE,
    GENERAL_ROUTE,
    EVALUABLE_ACTIVITY_ROUTE,
    QUIZZES_ROUTE,
    CLASS_PROGRESS_ROUTE,
    GRADES_SECTION,
    GRADES_ROUTE,
} from '../../../constants/dashboard'

import {
    Clipboard,
    Play,
    PieChart,
    TrendingUp,
    Award,
} from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'

export default (lang, strategyId) => {
    return [
        {
            key: INITIAL_SECTION,
            id: 'initialReportDropdown',
            text: lang.showDataFrom,
            theme: 'primary',
            value: DASHBOARD_ROUTE,
            massiveCourseEnabled: true,
            disabled: false,
        },
        {
            key: GENERAL_PROGRESS_SECTION,
            id: 'generalReportDropdown',
            text: lang.generalProgressReport,
            theme: 'primary',
            value: GENERAL_ROUTE,
            icon: <PieChart alt='progress-icon' size={20} />,
            massiveCourseEnabled: false,
            disabled: false,
        },
        {
            key: QUIZZES_SECTION,
            id: 'quizzesReportDropdown',
            text: lang.quizzes,
            theme: 'primary',
            value: QUIZZES_ROUTE,
            icon: <Clipboard alt='quiz-icon' size={20} />,
            massiveCourseEnabled: false,
            disabled: false,
        },
        {
            key: EVALUABLE_ACTIVITY_SECTION,
            id: 'evaluableReportDropdown',
            text: lang.evaluableActivity,
            theme: 'primary',
            value: EVALUABLE_ACTIVITY_ROUTE,
            icon: <Play alt='evaluable-icon' size={20} />,
            massiveCourseEnabled: false,
            disabled: false,
        },
        {
            key: CLASS_PROGRESS_SECTION,
            id: 'classReportDropdown',
            text: lang.classReport,
            theme: 'primary',
            value: CLASS_PROGRESS_ROUTE,
            icon: <TrendingUp alt='class-progress-icon' size={20} />,
            massiveCourseEnabled: true,
            disabled: false,
        },
        {
            key: GRADES_SECTION,
            id: 'gradesDropdown',
            text: lang.notebook,
            theme: 'primary',
            value: GRADES_ROUTE,
            icon: <Award alt='grades-icon' size={20} />,
            massiveCourseEnabled: false,
            disabled: !strategyId,
        },
    ]
}
