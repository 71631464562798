import React from 'react'
import PropTypes from 'prop-types'
import './CourseInfo.scss'
import moment from 'moment'
import UnitCarousel from './UnitCarousel'
import DigitalTools from './DigitalTools'
import BreadCrumb from '@/components/Breadcrumb'
import CourseTeachers from './CourseTeachers'
import CustomCargando from '@/components/CustomCargando'
import { timezoneConfig } from '@/helpers/dates/timezoneConfig'
const [tz, locale] = timezoneConfig()
moment.locale(locale)

const CourseInfo = ({
    initial_date,
    finish_date,
    alias,
    description,
    teachers,
    units,
    name,
    isMobile,
    tools,
    lang,
    loading
}) => {
    const {tools:langTools, teachersTeam:langTeachers} = lang
    const courseAlias = alias || name

    const defaultDateFormat = "DD/MM/YYYY"
    const dayMonthDateFormat = "DD/MM"
    const checkDatesExist = () => {
        return !!(initial_date && finish_date)
    }

    const getCourseFormatDates = (format) => {
        const startDate = moment(new Date(initial_date)).tz(tz).format(format)
        const endDate = moment(new Date(finish_date)).tz(tz).format(format)
        return {start: startDate, end: endDate}
    }

    const bothDatesExists = () => (initial_date && finish_date)

    const getStartEndDates = (format) => {
        const { start, end } = getCourseFormatDates(format)
        return (
            <>
                {initial_date && `${lang.startDate} ${start}`}                 
                {bothDatesExists() && <span className="dateSeparator">|</span>}
                {bothDatesExists() && `${lang.finishDate} ${end}`}
            </>
        )
    }

    const crumbSetup =  [
        {
            name: lang.courseInformation,
            url: '#'
        }
    ]

    return (
        <div>
            <div className="container info">

                <BreadCrumb crumbs={crumbSetup} courseAlias={courseAlias} />

                <div className="courseData">
                    <h2 className="comissionTitle">{alias || name}</h2>
                    {description && <p className="courseDescription">{description}</p>} 
                </div>

                <h3 className="subtitle">{lang.courseRoadMap}</h3>
                {loading ? (
                    <CustomCargando />
                ) :
                    <>
                        <p className="dates">{getStartEndDates(defaultDateFormat)}</p>
                        <UnitCarousel
                            dates={checkDatesExist() ? getCourseFormatDates(dayMonthDateFormat) : undefined}
                            unitNames={units}
                        />
                        {tools && <DigitalTools 
                            tools={tools}
                            lang={langTools}
                        />}  
                        <CourseTeachers
                            teachers={teachers}
                            isMobile={isMobile}
                            lang={langTeachers}
                        />
                    </>}

            </div>
        </div>
    )
}
CourseInfo.defaultProps = {
    initial_date: null,
    finish_date: null,
    alias: null,
    description: null,
    teachers: [],
    units: [],
    tools: null,
    name: "",
}

CourseInfo.propTypes = {
    initial_date: PropTypes.string,
    finish_date: PropTypes.string,
    alias: PropTypes.string,
    description: PropTypes.string,
    teachers: PropTypes.array,
    units: PropTypes.array,
    name: PropTypes.string,
    isMobile: PropTypes.bool.isRequired,
    tools: PropTypes.array,
    lang: PropTypes.any.isRequired,
    loading: PropTypes.bool.isRequired
}
export default CourseInfo
