import * as blockIssuesOptions from '../api/blockIssueOptionsApi'
import httpClient from '../httpClient'
import { getTenantLang } from '@/helpers/tenants/helpers'

const api = () => httpClient(blockIssuesOptions.base)

export const loadBlockIssuesOptions = () =>
    api().get(null, {
        params: { lang: getTenantLang() },
    })
