import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import { MODULE, LESSON, TOPIC, BLOCK } from '../../constants/dashboard'
import './DashboardDropdownsBar.scss'

const DashboardDropdownsBar = ({
    modules,
    selectedModule,
    lessons,
    selectedLesson,
    topics,
    selectedTopic,
    blocks,
    selectedBlock,
    onChange,
}) => {
    const {
        unitPlaceholder,
        lessonPlaceholder,
        topicPlaceholder,
        blockPlaceholder,
        quizReportMessage,
    } = useSelector(({ langStore }) => langStore.dashboard)

    return (
        <div className='dropdowns-row-container'>
            <div className='dropdowns-row-container--hola'>
                <MessageBox
                    theme='info'
                    className='dropdowns-row-container--alert'
                    title={quizReportMessage}
                    fullWidth
                    centeredText
                />
            </div>
            <div className='dropdowns-row-container__inputs'>
                <div className='dropdowns-row-container__inputs--item'>
                    <InputComboBox
                        disabled={!modules.length}
                        dropdownItems={modules}
                        id='evaluableActivityUnitDropdown'
                        onChange={(selection, index) => onChange(selection, index, MODULE)}
                        placeholder={unitPlaceholder}
                        value={selectedModule}
                    />
                </div>
                <div className='dropdowns-row-container__inputs--item'>
                    <InputComboBox
                        disabled={!lessons.length}
                        dropdownItems={lessons}
                        id='evaluableActivityLessonDropdown'
                        onChange={(selection, index) => onChange(selection, index, LESSON)}
                        placeholder={lessonPlaceholder}
                        value={selectedLesson}
                    />
                </div>
                <div className='dropdowns-row-container__inputs--item'>
                    <InputComboBox
                        disabled={!topics.length}
                        dropdownItems={topics}
                        id='evaluableActivityTopicDropdown'
                        onChange={(selection, index) => onChange(selection, index, TOPIC)}
                        placeholder={topicPlaceholder}
                        value={selectedTopic}
                    />
                </div>
                <div className='dropdowns-row-container__inputs--item'>
                    <InputComboBox
                        disabled={!blocks.length}
                        dropdownItems={blocks}
                        id='evaluableActivityBlockDropdown'
                        onChange={(selection, index) => onChange(selection, index, BLOCK)}
                        placeholder={blockPlaceholder}
                        value={selectedBlock}
                    />
                </div>
            </div>
        </div>
    )
}

DashboardDropdownsBar.propTypes = {
    modules: PropTypes.array,
    selectedModule: PropTypes.string,
    lessons: PropTypes.array,
    selectedLesson: PropTypes.string,
    topics: PropTypes.array,
    selectedTopic: PropTypes.string,
    blocks: PropTypes.array,
    selectedBlock: PropTypes.string,
    onChange: PropTypes.func,
}

DashboardDropdownsBar.defaultProps = {
    modules: [],
    selectedModule: '',
    lessons: [],
    selectedLesson: '',
    topics: [],
    selectedTopic: '',
    blocks: [],
    selectedBlock: '',
    onChange: () => {},
}

export default DashboardDropdownsBar
