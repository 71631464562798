import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import LoadingOrContent from '@digitalhouse-tech/react-lib-ui-explorer/lib/LoadingOrContent'
import { calculatePaginationRange } from '@/helpers/pagination'
import Paginator from '@digitalhouse-tech/react-lib-ui-explorer/lib/Paginator'
import './OwnerAssign.scss'

const OwnerAssignUsersTable = ({
    users,
    pagination,
    changeSearchUserPage,
    usersToAssign,
    preAssignUser,
    loadingUserSearch,
    activeSearch,
    lang,
}) => {
    const { pagesQuantity, nextPage } = pagination
    const currentPage = nextPage && nextPage <= pagesQuantity ? nextPage - 1 : pagesQuantity
    return (
        <>
            <LoadingOrContent loading={loadingUserSearch} size='small'>
                {users && users.length ? (
                    <div className='assign_users'>
                        <div
                            className={cn(
                                {
                                    _no_show_element: users.length === 0,
                                },
                                'assign_users_table'
                            )}
                        >
                            <table className='assign_users_list table-responsive pdg-table'>
                                <tbody>
                                    {users.map((user) => {
                                        if (
                                            usersToAssign.findIndex(
                                                (item) => item.id == user.id
                                            ) === -1
                                        ) {
                                            //no mostrar los que estan en el paso 2
                                            return (
                                                <tr key={`row_user_${user.id}`}>
                                                    <td className='col-md-3'>{user.name}</td>
                                                    <td className='col-md-3'>{user.surname}</td>
                                                    <td className='col-md-4'>{user.email}</td>
                                                    <td className='col-md-2'>
                                                        <Button
                                                            className='pre_assign_user_button'
                                                            id={`assign_user_${user.id}`}
                                                            theme='no-theme'
                                                            text={lang.userSelection}
                                                            onClick={() => preAssignUser(user.id)}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <div
                        className={cn(
                            {
                                show_empty_msg: users.length === 0 && activeSearch,
                            },
                            'assign_empty_msg'
                        )}
                    >
                        {lang.emptyResults}
                    </div>
                )}
            </LoadingOrContent>
            {users && users.length ? (
                <div className='__pagination'>
                    <Paginator
                        currentPage={currentPage}
                        disabled={loadingUserSearch}
                        onPageChange={(page) => changeSearchUserPage(page)}
                        range={calculatePaginationRange(pagesQuantity)}
                        totalPages={pagesQuantity}
                    />
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

OwnerAssignUsersTable.propTypes = {
    users: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    changeSearchUserPage: PropTypes.func.isRequired,
    usersToAssign: PropTypes.array.isRequired,
    preAssignUser: PropTypes.func.isRequired,
    loadingUserSearch: PropTypes.bool.isRequired,
    activeSearch: PropTypes.bool.isRequired,
    lang: PropTypes.object.isRequired,
}

export default OwnerAssignUsersTable
