import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEnvelope,
    faUserCircle,
    faDotCircle,
    faCircle,
    faAngleDown,
    faDownload,
    faCheck,
    faPen,
    faSearch,
    faTimes,
    faArrowRight,
    faCode,
    faSpinner,
    faChevronLeft,
    faChevronRight,
    faSyncAlt,
    faArrowCircleRight,
    faArrowCircleLeft,
    faPlus,
    faPlusCircle,
    faLock,
    faExclamationCircle,
    faHome,
    faAngleLeft,
    faAngleUp,
    faTrash,
    faExclamation,
    faChevronDown,
    faClock,
    faEye,
    faEyeSlash,
    faCloudUploadAlt,
    faSortNumericUp,
    faArrowUp,
    faArrowDown,
    faQuestionCircle,
    faArrowLeft,
    faDesktop,
    faUsers,
    faAngleRight,
    faFileAlt,
    faQuestion,
    faChartLine,
    faAdjust,
    faComment,
    faCheckCircle,
    faTimesCircle,
    faBell,
    faCircleNotch,
    faRedo,
    faLightbulb,
    faTerminal,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faAngleDoubleDown,
    faAngleDoubleUp,
    faCopy,
    faCodeBranch,
    faInfoCircle,
    faChevronUp,
    faPaperPlane,
    faBug,
    faChevronCircleLeft,
    faChevronCircleRight,
    faListUl,
    faClone,
    faExpand,
    faExpandArrowsAlt,
    faBars,
    faKey,
    faUpload,
    faVideo,
    faLaptopHouse,
    faBullhorn,
    faFileDownload,
    faSortDown,
    faSortUp,
    faFilter,
    faBook
} from '@fortawesome/free-solid-svg-icons'

import {
    faBellSlash,
    faFolderOpen,
    faComment as faCommentRegular,
    faCircle as faCircleBorder
} from '@fortawesome/free-regular-svg-icons'

export const BookIcon = props => {
    return <FontAwesomeIcon {...props} icon={faBook} />
}

export const BullhornIcon = props => {
    return <FontAwesomeIcon {...props} icon={faBullhorn} />
}

export const LaptopHouseIcon = props => {
    return <FontAwesomeIcon {...props} icon={faLaptopHouse} />
}

export const CommentRegularIcon = props => {
    return <FontAwesomeIcon {...props} icon={faCommentRegular} />
}

export const FolderOpenIcon = props => {
    return <FontAwesomeIcon {...props} icon={faFolderOpen} />
}
export const VideoIcon = props => {
    return <FontAwesomeIcon {...props} icon={faVideo} />
}

export const BellSlashIcon = props => {
    return <FontAwesomeIcon {...props} icon={faBellSlash} />
}

export const DropdownIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faAngleDown} />)
}

export const DownloadIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faDownload} />)
}

export const CheckIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faCheck} />)
}

export const EditIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faPen} />)
}

export const DeleteIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faTrash} />)
}

export const SearchIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faSearch} />)
}

export const CloseIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faTimes} />)
}

export const ArrowRightIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faArrowRight} />)
}

export const CodeIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faCode} />)
}

export const LoadingIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faSpinner} />)
}

export const ChevronLeftIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faChevronLeft} />)
}

export const ChevronRightIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faChevronRight} />)
}

export const ReloadIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faSyncAlt} />)
}

export const ArrowCircleRightIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faArrowCircleRight} />)
}

export const ArrowCircleLeftIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faArrowCircleLeft} />)
}

export const PlusIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faPlus} />)
}

export const PlusCircleIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faPlusCircle} />)
}

export const LockIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faLock} />)
}

export const ExclamationCircleIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faExclamationCircle} />)
}

export const AngleUpIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faAngleUp} />)
}

export const AngleDownIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faAngleDown} />)
}

export const SpinnerIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faSpinner} />)
}

export const CircleSpinnerIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faCircleNotch} />)
}

export const AddIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faPlus} />)
}

export const AngleLeftIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faAngleLeft} />)
}

export const AngleRightIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faAngleRight} />)
}

export const HelpIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faQuestionCircle} />)
}
export const ChevronDownIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faChevronDown} />)
}

export const ClockIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faClock} />)
}

export const ExclamationIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faExclamation} />)
}

export const GenericIcon = (icon, props) => {
    return (<FontAwesomeIcon {...props} icon={icon} />)
}

export const HomeIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faHome} />)
}

export const AngleUp = (props) => {
    return (<FontAwesomeIcon {...props} icon={faAngleUp} />)
}

export const AngleDown = (props) => {
    return (<FontAwesomeIcon {...props} icon={faAngleDown} />)
}

export const VisibleIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faEye} />)
}

export const NotVisibleIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faEyeSlash} />)
}

export const UploadIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faCloudUploadAlt} />)
}

export const SortIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faSortNumericUp} />)
}

export const ArrowUpIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faArrowUp} />)
}

export const ArrowDownIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faArrowDown} />)
}
export const InnerCircleIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faDotCircle} />)
}

export const CircleIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faCircle} />)
}

export const CircleBorderIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faCircleBorder} />)
}

export const ProfileIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faUserCircle} />)
}
export const EnvelopeIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faEnvelope} />)
}

export const DesktopIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faDesktop} />)
}
export const UsersGroupIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faUsers} />)
}

export const FeedbackIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faComment} />)
}

export const RoundCheckIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faCheckCircle} />)
}

export const RoundCloseIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faTimesCircle} />)
}

export const QuestionIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faQuestion} />)
}

export const ChartIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faChartLine} />)
}

export const ReportIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faFileAlt} />)
}

export const PartialIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faAdjust} />)
}

export const BellIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faBell} />)
}

export const RedoIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faRedo} />)
}

export const LightbulbIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faLightbulb} />)
}

export const ArrowLeftIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faArrowLeft} />)
}

export const TerminalIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faTerminal} />)
}

export const AngleDoubleRightIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faAngleDoubleRight} />)
}

export const AngleDoubleLeftIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faAngleDoubleLeft} />)
}

export const AngleDoubleDownIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faAngleDoubleDown} />)
}

export const AngleDoubleUpIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faAngleDoubleUp} />)
}

export const CopyIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faCopy} />)
}

export const CodeBranchIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faCodeBranch} />)
}

export const InfoCircleIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faInfoCircle} />)
}

export const ChevronUpIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faChevronUp} />)
}

export const PaperPlaneIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faPaperPlane} />)
}

export const BugIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faBug} />)
}

export const ChevronCircleLeft = props => <FontAwesomeIcon {...props} icon={faChevronCircleLeft} />

export const ChevronCircleRight = props => <FontAwesomeIcon {...props} icon={faChevronCircleRight} />
export const StepsIcon = (props) => {
    return ((<FontAwesomeIcon {...props} icon={faListUl} />))
}

export const CloneIcon = (props) => {
    return ((<FontAwesomeIcon {...props} icon={faClone} />))
}

export const ExpandIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faExpand} />)
}

export const ExpandArrowIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faExpandArrowsAlt} />)
}

export const BarsMenu = (props) => {
    return (<FontAwesomeIcon {...props} icon={faBars} />)
}

export const FileDownloadIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faFileDownload} />)
}

export const ArrowSortDownIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faSortDown} />)
}

export const ArrowSortUpIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faSortUp} />)
}

export const FilterIcon = (props) => {
    return (<FontAwesomeIcon {...props} icon={faFilter} />)
}

export const KeyIcon = (props) => <FontAwesomeIcon {...props} icon={faKey} />

export const UploadFileIcon = (props) => <FontAwesomeIcon {...props} icon={faUpload} />
