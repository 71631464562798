import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { history } from '@/app/store'
import VideoHero from '@digitalhouse-tech/react-lib-ui-explorer/lib/VideoHero'
import { useSelector, useDispatch } from 'react-redux'
import { Smile, Frown } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { sendNewPasswordAndToken } from '@/redux/thunks/recoverPasswordThunks'
import RecoverMessageCard from '@/modules/Auth/RecoverPasswordManager/RecoverMessageCard'
import useVideoHero from '@/modules/Auth/shared/hooks/useVideoHero'
import * as card from '@/modules/Auth/RecoverPasswordManager/helpers/constants'
import CredentialsManager from '../CredentialsManager'

const PasswordManager = ({ token, firstPassword, invalidToken }) => {
    const [cardStatus, setCardStatus] = useState(
        invalidToken ? card.INVALID_TOKEN : card.PASSWORD_FORM
    )
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')

    const dispatch = useDispatch()
    const lang = useSelector(({ langStore }) => langStore.addNewPassword)
    const onBack = () => location.href = '/'
    const { videoSrc, posterSrc, autoPlay, notSupportedMsg } = useVideoHero()
    const onSubmit = async () => {
        const [error] = await dispatch(sendNewPasswordAndToken(password, token))
        if (!error) {
            setCardStatus(card.PASSWORD_SUCCESS)
        } else {
            setCardStatus(card.INVALID_TOKEN)
        }
    }

    const components = {
        [card.PASSWORD_FORM]: (
            <CredentialsManager
                onSubmit={onSubmit}
                onBack={onBack}
                type={card.PASSWORD_FORM}
                lang={{ ...lang.form, returnButton: lang.returnButton }}
                onChangeInput={(e) => setPassword(e.currentTarget.value)}
                onChangeInputConfirm={(e) => setPasswordConfirm(e.currentTarget.value)}
                inputValue={password}
                inputConfirmValue={passwordConfirm}
                hideGoBack={firstPassword}
            />
        ),
        [card.PASSWORD_SUCCESS]: (
            <RecoverMessageCard
                text={lang.messages.passwordSuccess.text}
                lang={lang.messages.passwordSuccess}
                buttonContinue={lang.messages.passwordSuccess.continue}
                icon={<Smile />}
                redirectToLogin={() => location.href = '/'}
                hasContinueButton
            />
        ),
        [card.INVALID_TOKEN]: (
            <RecoverMessageCard
                text={lang.messages.tokenError.text}
                lang={{ ...lang.messages.tokenError, returnButton: lang.returnButton }}
                buttonContinue={lang.messages.passwordSuccess.continue}
                icon={<Frown />}
                redirectToLogin={() => location.href = '/'}
                hasReturnButton
            />
        ),
    }

    return (
        <>
            <VideoHero
                videoSrc={videoSrc}
                posterSrc={posterSrc}
                autoPlay={autoPlay}
                notSupportedMsg={notSupportedMsg}
            />
            {components[cardStatus]}
        </>
    )
}

PasswordManager.propTypes = {
    token: PropTypes.string,
    invalidToken: PropTypes.bool,
}

PasswordManager.defaultProps = {
    token: '',
    invalidToken: false,
}

export default PasswordManager
