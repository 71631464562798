import React from 'react'
import PropTypes from 'prop-types'

import './styles/ToolPanel.scss'

const ToolPanel = ({ tool }) => {
    return (
        <div className="__card toolPanel">
            <p className="toolPanel__name">{tool.name}</p>
            {tool.actions}
        </div>
    )
}

ToolPanel.propTypes = {
    tool: PropTypes.shape({
        actions: PropTypes.object.isRequired,
        description: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        tool_url: PropTypes.string.isRequired,
        tutorial_url: PropTypes.string,
    }).isRequired,
}

export default ToolPanel
