export const parseQueryStringToFilters = (queryString) => {
  const result = {};

  const urlSearchParams = new URLSearchParams(queryString);

  result.module = urlSearchParams.get('module') ?? '';
  result.lesson = urlSearchParams.get('lesson') ?? '';
  result.topic = urlSearchParams.get('topic') ?? '';
  result.block = urlSearchParams.get('block') ?? '';
  
  return result;
};