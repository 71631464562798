import React from 'react'
import PropTypes from 'prop-types'
import './ProgressBar.scss'

const ProgressBar = ({
    level
}) =>{
    const completed = () =>{
        return {
            width: `${level}%`,
        }
    }

    return (
        <div className="bar">
            <div className="bar __completed" style={completed()} />            
        </div>
    )
}

ProgressBar.propTypes = {
    level: PropTypes.number.isRequired,
}

export default ProgressBar
