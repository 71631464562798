import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { ChevronRight } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import useHistory from '@/hooks/useHistory'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'

import './Breadcrumb.scss'

const Breadcrumb = ({ crumbs, className, courseAlias }) => {
    const history = useHistory()
    const lang = useSelector(({ langStore }) => langStore.breadcrumb)
    const start = () => (
        <Button
            id='breadStart'
            key='breadStart'
            onClick={() => handleRedirect('/')}
            className='link'
            data-message={lang.accesibility.start}
            text={lang.start}
            theme='clear'
        />
    )

    const courseAliasBreadcrumb = (courseAlias) => (
        <>
            {separator()}
            <span key='courseAliasCrumb' className='ultimo'>
                {courseAlias}
            </span>
        </>
    )

    const separator = () => <ChevronRight key="breadSep" className="icon separator" />

    const handleRedirect = url => location.href = url

    const processBreadCrumb = () => {
        return crumbs.map((crumb, index) => {
            const key = `${crumb.name}${index}`

            if (index + 1 !== crumbs.length) {
                return [
                    <Button
                        id={key}
                        key={key}
                        onClick={() => handleRedirect(crumb.url)}
                        className="link"
                        theme="clear"
                        text={crumb.name}
                    />,
                    separator(),
                ]
            }
            return (
                <span key={key} className="ultimo">
                    {crumb.name}
                </span>
            )
        })
    }

    return (
        <>
            <div className={`crumbs ${className}`}>
                {start()}
                {courseAlias && courseAliasBreadcrumb(courseAlias)}
                {crumbs && crumbs.length > 0 && separator()}
                {crumbs && processBreadCrumb()}
            </div>
        </>
    )
}

Breadcrumb.propTypes = {
    crumbs: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string,
        }),
    ).isRequired,
    className: PropTypes.string,
    courseAlias: PropTypes.string,
}

Breadcrumb.defaultProps = {
    className: '',
}

export default Breadcrumb
