'use-strict'

import _ from 'lodash'
import actions from './actions'
import service from './service'
import routing from './routing'
import configureStore from '@/app/store'
import showNewMessage from '../globalAlertsReducer/alertsControllers'

const { dispatch, getState } = configureStore.store

const controller = {
    loadPaginatedRoles: async data => {
        dispatch(actions.setIsLoading(true, 'loadPaginatedRoles'))
        try {
            const paginatedRoles = await service.loadPaginatedRoles(data, getState().roleManagerStore.paginationConfig)

            dispatch(actions.setPaginatedRoles(paginatedRoles))
        } catch (e) { }
        dispatch(actions.setIsLoading(false, 'loadPaginatedRoles'))
    },
    loadAllPermissions: async () => {
        try {
            const permissions = await service.loadAllPermissions()

            dispatch(actions.setPaginatedPermissions(permissions))
        } catch (e) { }
    },
    loadRole: async roleId => {
        dispatch(actions.setIsLoading(true, 'loadSingleRole'))
        try {
            const singleRole = await service.loadRole(roleId)
            dispatch(actions.setActiveRole(singleRole))
            dispatch(actions.setRolePermissions(singleRole.Permissions.map(p => p.id)))
        } catch (e) { }
        dispatch(actions.setIsLoading(false, 'loadSingleRole'))
    },
    updateRolePermissions: (isChecked, permissionId) => {
        try {
            const state = getState()
            let defaultPermissions = [...state.roleManagerStore.defaultPermissionsRole]

            if (isChecked) {
                defaultPermissions.push(permissionId)
            } else {
                defaultPermissions = defaultPermissions.filter(item => item !== permissionId)
            }

            dispatch(actions.setRolePermissions(defaultPermissions))
        } catch (e) { }
    },
    saveNewRole: async () => {
        dispatch(actions.setIsLoading(true, 'saveRole'))
        const state = getState().roleManagerStore
        const { activeRole, defaultPermissionsRole } = state
        try {
            const roleData = { ...activeRole, Permission: [...defaultPermissionsRole] }
            await service.saveNewrole(roleData)
            routing.goToRolesList()
        } catch (e) { }
        dispatch(actions.setIsLoading(false, 'saveRole'))
    },
    updateOneRole: async () => {
        dispatch(actions.setIsLoading(true, 'saveRole'))
        const state = getState().roleManagerStore
        const { activeRole, defaultPermissionsRole } = state
        try {

            const roleData = { ...activeRole, Permission: [...defaultPermissionsRole] }

            await service.updateOneRole(roleData.id, roleData)
            routing.goToRolesList()
        } catch (e) { }
        dispatch(actions.setIsLoading(false, 'saveRole'))
    },
    deleteRole: async roleId => {
        try {
            await service.deleteRole(roleId)
            dispatch(actions.clearAllRoles())
        } catch (e) {
            const state = getState()
            
            showNewMessage({
                type: 'danger',
                message: state.langStore.roles.list.conflictDeleteRole,
                isTemporal: true
            })
        }
    },
    setActiveRole: (key, value) => {
        const state = getState().roleManagerStore
        const { activeRole } = state
        const modified = { ...activeRole, [key]: value }
        dispatch(actions.setActiveRole(modified))
    }
}

export default controller