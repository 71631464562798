import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Eye, EyeOff, Edit2, Trash2, Plus } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { saveNewBlock, getCoursesToModify } from '@/redux/reducers/_deprecated/courseReducer/index'

import { DELETE_BLOCK_WARNING_MODAL, VISIBILITY_MODAL } from '@/constants/modals'
import { actions as mamushkaActions } from '@/redux/reducers/mamushkaReducer'
import { actions as modalActions } from '@/redux/reducers/modalReducer'
import { actions as blockActions } from '@/redux/reducers/blockReducer'
import * as courseThunks from '@/redux/thunks/courseThunks'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'
import { hasAnyProgress } from '@/redux/thunks/blockThunks'
import { getBlockType } from '@/helpers/blocks'
import { BLOCK_TYPES } from '@/constants/blocks'
import { EDIT_BLOCK_CONTENT, EDIT_BLOCK_VISIBILITY, DELETE_BLOCK } from '@/constants/coursePermissions'
import { showTopicsAndBlocksActions, hideChangeVisibility } from '@/helpers/granters/hideMamushkaElements'
import BlockIcon from '@/components/BlockIcon'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'

import './styles/Block.scss'
import '../../../../styles/Card.scss'
import langStates from '@/redux/reducers/_deprecated/langReducer/langStates'

export class Block extends React.Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            removingBlockId: 0
        }
        this.getExercise = this.getExercise.bind(this)
        this.onClickEditBlock = this.onClickEditBlock.bind(this)
        this.onClickRemoveButton = this.onClickRemoveButton.bind(this)
        this.setMamushkaValues = this.setMamushkaValues.bind(this)
        this.openModalVisibility = this.openModalVisibility.bind(this)
    }

    async onClickEditBlock() {
        const { block, openModal, hasAnyProgress, getCoursesToModify, courseId } = this.props
        const blockType = getBlockType(block)

        const isNotion = BLOCK_TYPES.NOTION_BLOCK.regex.test(block.TextBlock?.text)
        const modal = isNotion? BLOCK_TYPES.NOTION_BLOCK.modal : blockType.modal

        await this.setMamushkaValues()
        await hasAnyProgress()
        if (this.props.course.base){
            getCoursesToModify(block.id, "block", courseId)
        }
        return openModal(modal, block)
    }

    renderPanelTitle() {
        const { block } = this.props
        if (block.ExerciseBlock) {
            const title = (
                <h3 className="__card_secondary_title">
                    {this.props.block.extra
                        ? (
                            <React.Fragment>
                                <Tooltip
                                    tooltipId="isExtra"
                                    tooltipContent={this.props.lang.extraBlock}
                                >
                                    <Plus className="__prev_icon" />
                                </Tooltip>
                                {this.props.block.name}
                            </React.Fragment>
                        )
                        : this.props.block.name
                    }
                </h3>
            )
            return this.props.dragDisableBlocks ? <Panel.Toggle>{title}</Panel.Toggle> : title
        }
        return <h3 className="__card_secondary_title" style={{ cursor: "default" }}>{block.name}</h3>
    }

    renderSubTitle() {
        const { block, lang } = this.props
        const isNotion = BLOCK_TYPES.NOTION_BLOCK.regex.test(block.TextBlock?.text)
        const blockType = getBlockType(block)
        const blockName = isNotion ? BLOCK_TYPES.NOTION_BLOCK.name : blockType?.name
        let message = '';

        if (!blockType) return null;

        switch (blockName) {
            case (BLOCK_TYPES.TEXT_BLOCK.name):
                message = lang.textBlock
                break
            case (BLOCK_TYPES.NOTION_BLOCK.name):
                message = lang.notionBlock
                break
            case (BLOCK_TYPES.EXERCISE_BLOCK.name):
                message = lang.exerciseBlock
                break
            case (BLOCK_TYPES.QUIZ_BLOCK.name):
                message = lang.quizBlock
                break
            case (BLOCK_TYPES.VIDEO_BLOCK.name):
                message = lang.videoBlock
                break
            case (BLOCK_TYPES.SLIDE_BLOCK.name):
                message = lang.slideBlock
                break
            case (BLOCK_TYPES.PDF_BLOCK.name):
                message = lang.pdfBlock
                break
            case (BLOCK_TYPES.FILE_BLOCK.name):
                message = lang.fileBlock
                break
            case (BLOCK_TYPES.TEXT_HTML_BLOCK.name):
                message = lang.textHtmlBlock
                break
            case (BLOCK_TYPES.EVALUABLE_BLOCK.name):
                message = lang.evaluableActivity
                break
        }

        return (
            <p className="typeGreen subTitleMarginLeft">
                {lang.blockType}<BlockIcon type={blockName} className="card-block-icon" />{message}
            </p>
        )
    }

    async onClickRemoveButton() {
        this.setMamushkaValues()

        this.setState({
            ...this.state,
            removingBlockId: this.props.id
        })

        if (this.props.course.base) {
            await this.props.howManyItemsCanBeRemovedFromChilds('block', this.props.block.id)
        }

        this.setState({
            ...this.state,
            removingBlockId: 0
        })

        this.props.openModal(DELETE_BLOCK_WARNING_MODAL)
    }

    getExercise() {
        const { block } = this.props
        return [block]
    }


    setMamushkaValues() {
        const { courseId, unitId, lessonId, topicId, block, setMamushkaValues } = this.props
        const mamushka = {
            courseId: courseId,
            unitId: unitId,
            lessonId: lessonId,
            topicId: topicId,
            blockId: block.id,
        }
        return setMamushkaValues(mamushka)
    }

    openModalVisibility() {
        const { openModal, courseId, unitId, lessonId, topicId, block, lang } = this.props
        const data = {
            courseId,
            unitId,
            lessonId,
            topicId,
            blockId: block.id,
            visibilityRoute: 'blocks',
            visibility: !block.visibility,
            modalText: lang.modalText
        }
        openModal(VISIBILITY_MODAL, data)
    }

    render() {
        return (
            <>
                <Panel className={`__card ${this.props.block.isRemoving ? 'removing' : ''}`}>
                    <Panel.Heading>
                        <Row>
                            <Col lg="7">
                                {this.renderPanelTitle()}
                                {this.renderSubTitle()}
                                {this.props.block.isRemoving ? <small className="removing-title">{this.props.lang.deletedBlock}</small> : null}
                            </Col>
                            <Col lg="5" className="__card_button_box __center_buttons_course">
                                <ButtonGroup>
                                    { showTopicsAndBlocksActions(this.props.course) &&
                                        <ButtonRound
                                            id="DeleteBlockButton"
                                            visible={DELETE_BLOCK()}
                                            theme='danger'
                                            loading={!!(this.props.courseLoading.howManyItemsCanBeRemovedFromChilds && this.state.removingBlockId)}
                                            icon={<Trash2 />}
                                            tooltipId='deleteBlockButton'
                                            tooltipContent={this.props.lang.deleteBlock}
                                            onClick={this.onClickRemoveButton}
                                            disabled={!(this.props.dragDisableBlocks && this.props.dragGeneralDisable)}
                                        />
                                    }
                                    { !hideChangeVisibility(this.props.course) &&
                                        <ButtonRound
                                            id="VisibilityBlockButton"
                                            tooltipId='visibilityBlockButton'
                                            tooltipContent={this.props.lang.changeVisibility}
                                            disabled={!(this.props.dragDisableBlocks && this.props.dragGeneralDisable) || !EDIT_BLOCK_VISIBILITY()}
                                            onClick={this.openModalVisibility}
                                            icon={this.props.block.visibility ? <Eye /> : <EyeOff />}
                                            theme='info'
                                        />
                                    }
                                    <ButtonRound
                                        id="EditBlockButton"
                                        visible={EDIT_BLOCK_CONTENT()}
                                        icon={<Edit2 />}
                                        disabled={!(this.props.dragDisableBlocks && this.props.dragGeneralDisable)}
                                        leftIcon
                                        theme='primary_dark'
                                        tooltipId='EditBlockButton'
                                        tooltipContent={this.props.lang.editBlock}
                                        onClick={this.onClickEditBlock}
                                    />
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <>
                            <h4 className="__exercise_title">{this.props.lang.exerciseTitle}</h4>
                            <ul className="__list_exercises">
                                {this.getExercise().map((exercise, index) => {
                                    return <li key={index}>{exercise.ExerciseBlock ? exercise.ExerciseBlock.name : this.props.lang.addExercise}</li>
                                })}
                            </ul>
                        </>
                    </Panel.Body>
                </Panel>
            </>
        )
    }
}

const mapDispatch = {
    saveNewBlock,
    getCoursesToModify,
    setMamushkaValues: mamushkaActions.setMamushkaValues,
    openModal: modalActions.open,
    setSingleBlock: blockActions.setSingleBlock,
    howManyItemsCanBeRemovedFromChilds: courseThunks.howManyItemsCanBeRemovedFromChilds,
    hasAnyProgress,
}

const mapStateToProps = (state) => {
    return {
        course: state.courseReducer.activeCourse,
        dragDisableBlocks: state.courseReducer.dragDisableBlocks,
        dragGeneralDisable: state.courseReducer.dragGeneralDisable,
        isMobile: state.authReducer.isMobile,
        lang: {
            ...langStates.blockLang,
            ...state.langStore.blockLang,
            ...state.langStore.courseIndexButtons,
            evaluableActivity: state.langStore.topic.evaluableActivity,
        },
        courseLoading: state.loading.course
    }
}

export default withRouter(connect(mapStateToProps, mapDispatch)(Block))