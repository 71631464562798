import React from 'react'
import { isUndefined } from '@/helpers/utils'

export default class Tabs extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            activeKey: props.activeKey
        }
    }
    componentDidMount() {
        if (this.props.setKey)
            this.props.setKey(this.props.activeKey)
    }

    render() {
        return (
            <React.Fragment>
                <ul className="nav nav-tabs">
                    {this.props.tabs && this.props.tabs.map((tab, key) => {
                        if (isUndefined(tab.show) || tab.show) {
                            return (
                                <li
                                    onClick={() => {
                                        if (this.props.setKey) {
                                            this.props.setKey(tab.key)
                                        }
                                        this.setState({ ...this.state, activeKey: tab.key })
                                    }}
                                    key={'nav' + key}
                                    className={
                                        (this.state.activeKey === tab.key)
                                            ? "active"
                                            : ""
                                    }
                                >
                                    <a href={'#' + tab.key} onClick={e => e.preventDefault()}>{tab.title}</a>
                                </li>
                            )
                        }
                        return null
                    })}
                    {/* {this.props.extraNavItems && this.props.extraNavItems.map((item, i) => (
                        // <li key={'extranav' + key} onClick={item.action} className={item.classes}>
                        //     <a href={'#' + key} onClick={e => e.preventDefault()}>{item.title}</a>
                        // </li>
                        <item.component
                            key={i}
                            children={this.props.children}
                            ddStyle={this.props.ddStyle}
                        />
                    ))} */}
                    { this.props.extraNavItems ? this.props.extraNavItems : null }
                </ul>
                {this.props.tabs && _.find(this.props.tabs, { key: this.state.activeKey }).component}
            </React.Fragment>
        )
    }
}

Tabs.defaultProps = {
    setKey: () => { }
}
