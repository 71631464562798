'use-strict'

import React from 'react'
import CustomTabs from '../CustomTabs'
import ExerciseRules from './ExerciseRules'
import ExerciseHints from './ExerciseHints'
import ExerciseMoral from './ExerciseMoral'
import './ExerciseTabsForm.scss'

const ExerciseTabsForm = ({
    data = {
        rules: [],
        hints: [],
        moral: ''
    },
    validations = {
        hints: {},
        moral: {}
    },
    handlers = {
        setField: () => { }
    },
    lang = {},
    allRuleTypes = [],
    availableFiles
}) => {

    const title = key => {
        return validations[key].state
            ? <span className="font-color--danger">{lang[key]} *</span>
            : `${lang[key]} *`
    }

    const getTabs = () => {
        const tabs = []
        if (allRuleTypes.length) {
            tabs.push({
                key: 'rules',
                title: lang.rules,
                component: (
                    <ExerciseRules
                        rules={data.rules}
                        lang={lang.exerciseRules}
                        allRuleTypes={allRuleTypes}
                        handlers={handlers}
                        availableFiles={availableFiles}
                    />
                )
            })
        }
        tabs.push(
            {
                key: 'hints',
                title: title('hints'),
                component: (
                    <ExerciseHints
                        lang={lang}
                        hints={data.hints}
                        validations={validations}
                        handlers={handlers}
                    />
                )
            })
        tabs.push({
            key: 'moral',
            title: title('moral'),
            component: (
                <ExerciseMoral
                    validations={validations}
                    handlers={handlers}
                    data={data}
                />
            )
        })
        return tabs
    }
    
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <CustomTabs
                        activeKey="hints"
                        tabs={getTabs()
                        }
                    />
                </div>
            </div>
        </>
    )
}

export default ExerciseTabsForm