import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './TableProgress.scss'
import { MINIMUM_TO_APPROVE_PROGRESS } from '@/constants/grades'

const TableProgress = ({ progress }) => {
    const classes = useCallback(() => {
        return cn('table-progress', {
            'table-progress--success': progress >= MINIMUM_TO_APPROVE_PROGRESS,
        })
    }, [progress])

    return !progress ? '-' : <div className={classes()}>{progress}%</div>
}

TableProgress.propTypes = {
    progress: PropTypes.number,
}

TableProgress.defaultProps = {
    progress: 0,
}

export default TableProgress
