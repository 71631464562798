import { isEmpty } from 'lodash/lang'

import { actions as modalActions } from '../reducers/modalReducer'
import { logoutUser } from '../reducers/_deprecated/authReducer'

import { onIdle, onExpire } from '../reducers/idleTimeReducer'

import { IDLE_TIMER_MODAL } from '@/constants/modals'
import { EXPIRE_SESSION_TIMEOUT } from '@/config/env'

import { getIsAuthenticated } from '../selectors/auth'

export const setIdle = () => (dispatch, getState) => {
    dispatch(onIdle())
    const {
        modal: { id: modalId },
    } = getState()

    const isAuthenticated = getIsAuthenticated(getState())

    const haveOpenModal = !isEmpty(modalId)

    if (haveOpenModal) {
        dispatch(modalActions.close())
    }

    dispatch(modalActions.open(IDLE_TIMER_MODAL))

    if (isAuthenticated) {
        setTimeout(() => dispatch(setExpired()), EXPIRE_SESSION_TIMEOUT)
    }
}

export const setExpired = () => (dispatch, getState) => {
    const {
        idleTime: { isIdle },
        modal: { id: modalId },
    } = getState()
    if (!isIdle) {
        return
    }

    const haveOpenModal = !isEmpty(modalId)

    if (haveOpenModal) {
        dispatch(modalActions.close())
    }

    dispatch(onExpire())
    dispatch(logoutUser())
}
