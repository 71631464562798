import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import FileManager from './FileManager'
import controllers from './fileManagerControllers'
import { loadCourse } from '@/redux/thunks/courseThunks'
import { clearCourse } from '@/redux/reducers/courseReducer'
import { loadCoursePermissions } from '@/redux/reducers/_deprecated/authReducer'
import showNewMessage from '@/redux/reducers/_deprecated/globalAlertsReducer/alertsControllers'
import { history } from '@/app/store'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import httpHandler, { codes } from '@/helpers/error-handling/httpHandler'
import * as env from '@/config/env'
import { DELETE_FILE, EDIT_FILE, DOWNLOAD_FILE, UPLOAD_FILE } from '@/constants/coursePermissions'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

const FileManagerContainer = ({
    match,
    fileList,
    loadCourse,
    clearCourse,
    course,
    loading,
    lang,
    loadCoursePermissions
}) => {
    useEffect(() => {
        loadCoursePermissions(match.params.id)
    }, [])

    useEffect(() => {
        return () => clearCourse()
    }, [])

    useEffect(() => {
        if (!course.id || match.params.id !== course.id) {
            loadCourse(match.params.id)
        }
        (async () => {
            try {
                await controllers.getFileList(match.params.id)
                showNewMessage({
                    type: 'success',
                    message: lang.alerts.successes.list,
                    isTemporal: true,
                })
            } catch (e) {
                showNewMessage({
                    type: 'danger',
                    message: lang.alerts.errors.list,
                    isTemporal: true,
                })
            }
        })()
    }, [])

    const uploadFileHandler = async (file, newFileName) => {
        try {
            await controllers.uploadFile(match.params.id, file, newFileName)
            showNewMessage({
                type: 'success',
                message: lang.alerts.successes.upload,
                isTemporal: true,
            })
        } catch (e) {
            showNewMessage({
                type: 'danger',
                message: lang.alerts.errors.upload,
                isTemporal: true,
            })
        }
    }

    const downloadFileHandler = async (path, filename) => {
        await controllers.downloadFile(path, filename)
    }

    const editFileHandler = async (fileId, fileName) => {
        try {
            await controllers.editFile(match.params.id, { id: fileId, name: fileName })
            showNewMessage({
                type: 'success',
                message: lang.alerts.successes.edit,
                isTemporal: true,
            })
        } catch (e) {
            showNewMessage({
                type: 'danger',
                message: lang.alerts.errors.edit,
                isTemporal: true,
            })
        }
    }

    const deleteFileHandler = async fileId => {
        try {
            await controllers.deleteFile(match.params.id, fileId)
            showNewMessage({
                type: 'success',
                message: lang.alerts.successes.delete,
                isTemporal: true,
            })
        } catch (e) {
            httpHandler(e)({
                [codes.CONFLICT]: data => {
                    showNewMessage({
                        type: 'danger',
                        message: lang.alerts.errors.deleteFile,
                        isTemporal: true,
                    })
                }
            })
        }
    }

    const onErrorHandler = (fileName, typeError) => {
        if (typeError === 'size') {
            showNewMessage({
                type: 'danger',
                message: lang.alerts.errors.size.replace('{n}',env.FILES_SIZE_LIMIT_MB),
                isTemporal: true,
            })
            return
        }
        if (typeError === 'name') {
            showNewMessage({
                type: 'danger',
                message: lang.alerts.errors.file,
                isTemporal: true,
            })
            return
        }
    }

    const handleGoBack = () => {
        history.goBack()
    }

    const getComponent = loading => {
        return (
            <div className="container">
                <div className="__main_header">
                    <Row>
                        <Col lg="12">
                            <h1 className="__main_title">
                                {lang.title} <small>{course.name}</small>
                            </h1>
                        </Col>
                    </Row>
                </div>
                <FileManager
                    onUploadFile={uploadFileHandler}
                    onDownloadFile={downloadFileHandler}
                    onEditFile={editFileHandler}
                    onDeleteFile={deleteFileHandler}
                    onError={onErrorHandler}
                    fileList={fileList}
                    lang={lang}
                    loading={loading}
                    showDeleteButton={DELETE_FILE()}
                    showEditButton={EDIT_FILE()}
                    showDownloadButton={DOWNLOAD_FILE()}
                    showUploadButton={UPLOAD_FILE()}
                />
                <Row>
                    <Col lg="12">
                        <div className="__main_footer">
                            <div className="_btn_group pull-right _margin_bottom_28">
                                <Button
                                    id="GoBackButton"
                                    theme="secondary"
                                    onClick={handleGoBack}
                                    text={lang.goBackButton}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    return <div>{getComponent(loading)}</div>
}

FileManagerContainer.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    fileServices: PropTypes.shape({
        downloadFile: PropTypes.func,
        uploadFile: PropTypes.func,
        editFile: PropTypes.func,
        deleteFile: PropTypes.func,
    }),
    loading: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
    fileList: state.fileManagerStore.fileList,
    course: state.course.course,
    loading: state.fileManagerStore.loading,
    lang: state.langStore.fileManager,
})

const mapDispatch = (dispatch, functions = {
    loadCourse,
    clearCourse,
    loadCoursePermissions
}) => ({
    loadCourse: (courseId) => dispatch(functions.loadCourse(courseId)),
    clearCourse: () => dispatch(functions.clearCourse()),
    loadCoursePermissions: courseId => dispatch(functions.loadCoursePermissions(courseId)),
})

export default withRouter(connect(mapStateToProps, mapDispatch)(FileManagerContainer))
export { FileManagerContainer }
