import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import { RefreshCw, Download } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'

const CourseReportActions = ({
    refreshCourse,
    loading,
    lang,
    showActionButtons,
    onClickDownloadReport,
    loadingDownloadProgressReport,
}) => {
    return (
        <>
            {showActionButtons && (
                <ButtonGroup className='_report_actions'>
                    <ButtonRound
                        id='sync_course_report'
                        theme='primary_dark'
                        onClick={refreshCourse}
                        icon={
                            <RefreshCw
                                className={cn({
                                    '_report_refresh_loading': loading,
                                }, '_report_refresh')}
                            />
                        }
                        tooltipId='sync_button_tooltip'
                        tooltipContent={lang.updateCourse}
                    />
                    <ButtonRound
                        id="download_course_report"
                        theme='primary_dark'
                        onClick={onClickDownloadReport}
                        icon={<Download />}
                        tooltipId="download_report_button_tooltip"
                        tooltipContent={lang.downloadReport}
                        loading={loadingDownloadProgressReport}
                    />
                </ButtonGroup>
            )}
        </>
    )
}

CourseReportActions.propTypes = {
    refreshCourse: PropTypes.func.isRequired,
    onClickDownloadReport: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    lang: PropTypes.object.isRequired,
    showActionButtons: PropTypes.bool,
}

CourseReportActions.defaultProps = {
    showActionButtons: false,
    loading: false,
}

export default CourseReportActions
