import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Plus, Trash2 } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'

export class UserTable extends React.Component {

    getHeaders() {
        let headers = this.props.headers,
            i,
            returnHeaders = [],
            editAction = this.props.editAction,
            deleteAction = this.props.deleteAction

        let x = 1
        for (i in headers) {
            let className = 'col-md-4'
            // if(x === 1) {
            //   className = 'col-md-2'
            // }
            returnHeaders.push(
                <th key={i} className={className}>
                    {headers[i]}
                </th>
            )
            x++
        }
        if (editAction || deleteAction) {
            returnHeaders.push(<th key={"acciones"} className="col-md-2 text-center">{this.props.lang.actions}</th>)
        }
        return returnHeaders
    }

    getTableRows() {
        const { data, editAction, deleteAction, permissionsAction, lang } = this.props
        let showDeleteButton = this.props.showDeleteButton
        var tableRow = data.map(function (rowObject, index) {
            let i
            var returnValue = [];

            for (i in rowObject) {
                if (i != 'id' && i != 'edit_table_id' && i != 'disabled')
                    returnValue.push(
                        <td key={i}>
                            {rowObject[i]}
                        </td>)
            }

            if (editAction || deleteAction || permissionsAction) {
                let editRender, deleteRender, permissionsRender

                if (editAction) {
                    editRender =
                        rowObject.disabled ?
                        <div className="__assigned_user">Matriculado</div>
                        : <Button
                            id="EditButton"
                            onClick={() => editAction(rowObject)}
                            icon={<Plus />}
                            theme="tertiary"
                            className="__assign_user"
                        />
                }

                if (deleteAction && showDeleteButton) {
                    deleteRender = (
                        <ButtonRound
                            id="DeleteButton"
                            theme='danger'
                            onClick={() => deleteAction(rowObject.id)}
                            icon={<Trash2 />}
                        />
                    )
                }

                if (permissionsAction) {
                    permissionsRender = (
                        <ButtonRound
                            id="PermissionsButton"
                            theme='primary_dark'
                            onClick={() => permissionsAction(rowObject.id)}
                            icon={<Plus />}
                        />
                    )
                }

                returnValue.push(
                    <td className="col-md-4 text-center" key="acciones">
                        <div>
                            {permissionsRender}
                            {editRender}
                            {deleteRender}
                        </div>
                    </td>
                )
            }

            return (
                <tr key={index}>
                    {returnValue}
                </tr>
            )
        })
        return tableRow
    }



    render() {
        if (this.props.data.length) {
            return (
                <div>
                    <table className="table __table">
                        <thead>
                            <tr>
                                {this.getHeaders()}
                            </tr>
                        </thead>
                        <tbody>
                            {this.getTableRows()}
                        </tbody>
                    </table>
                </div>
            )
        }
        else {
            return <div className="__search_msg_result"><p>{this.props.lang.noResultsFound}</p></div>
        }
    }
}


const mapDispatch = (dispatch) => ({
})

const mapStateToProps = (state, ownProps) => {
    return {
        lang: state.langStore.userTable
    }
}

export default withRouter(connect(mapStateToProps, mapDispatch)(UserTable))
