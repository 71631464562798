import React from 'react'
import PropTypes from 'prop-types'
import StatusInfo from '@digitalhouse-tech/react-lib-ui-explorer/lib/StatusInfo'
import StudentSearcher from '@digitalhouse-tech/react-lib-ui-explorer/lib/StudentSearcher'
import CallToAction from '@digitalhouse-tech/react-lib-ui-explorer/lib/CallToAction'
import { RotateCw } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { PENDING_FEEDBACK, USER_ICON } from '../../../shared/constants/dashboard'
import { getStatusInfo } from '../../../shared/utils/reviewHelpers'
import './PanelHeader.scss'

const PanelHeader = ({
    blockName,
    dropdownList,
    lang,
    onRequestRedo,
    selected,
    studentStatus,
    searchValue,
    onChangeSearch,
    canEdit
}) => {
    const statusInfo = getStatusInfo(studentStatus, lang)
    const noop = () => {}

    return (
        <div className='activity-panel-header'>
            <div className='activity-panel-header__info'>
                <h3 className='activity-panel-header__info--title'>{blockName}</h3>
                <div className='activity-panel-header__info--status'>
                    <StatusInfo
                        text={statusInfo.text}
                        icon={statusInfo.icon}
                        theme={statusInfo.theme}
                    />
                    {studentStatus === PENDING_FEEDBACK && (
                        <CallToAction
                            text={lang.requestRedo}
                            icon={<RotateCw size={18} />}
                            onClick={canEdit ? onRequestRedo : noop}
                            id='requestActivityRedo'
                            disabled={!canEdit}
                        />
                    )}
                </div>
            </div>
            <div className='activity-panel-header__dropdown'>
                <p className='activity-panel-header__dropdown--text'>{lang.deliveredBy}</p>
                <StudentSearcher
                    users={dropdownList}
                    onChangeSearch={(value) => onChangeSearch(value)}
                    searchValue={searchValue}
                    notFoundResult={
                        <span className='activity-panel-header__dropdown__no-results'>
                            {lang.noResultsFound}
                        </span>
                    }
                    selected={selected}
                    searchPlaceholder={lang.searchStudents}
                    searchIcon={USER_ICON}
                />
            </div>
        </div>
    )
}

PanelHeader.propTypes = {
    blockName: PropTypes.string,
    dropdownList: PropTypes.array,
    lang: PropTypes.object,
    onRequestRedo: PropTypes.func,
    selected: PropTypes.object,
    studentStatus: PropTypes.string,
    searchValue: PropTypes.string,
    onChangeSearch: PropTypes.func,
    canEdit: PropTypes.bool,
}

PanelHeader.defaultProps = {
    blockName: '',
    dropdownList: [],
    lang: {},
    onRequestRedo: () => {},
    selected: {},
    studentStatus: '',
    searchValue: '',
    onChangeSearch: () => {},
    canEdit: false,
}

export default PanelHeader
