import React from 'react'
import PropTypes from 'prop-types'
import './QuestionHeader.scss'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'

const QuestionHeader = ({ order, statement, lang, isSurvey, affectsProgress, hasQuizComplete }) => (
    <div>
        {!isSurvey && !affectsProgress && !hasQuizComplete &&
            <MessageBox
                title={lang.textFeedbackInfo}
                theme={'info'}
                fullWidth={true}
            />
        }
        {isSurvey &&
            <MessageBox title={lang.surveyInfoMessage} theme={'info'} fullWidth={true} />
        }
    <div className='question-header'>
        <div className='question-header__order'>{order}</div>

        <div
            className='question-header__title'
            dangerouslySetInnerHTML={{ __html: statement }}
        ></div>
    </div>
    </div>
)

QuestionHeader.propTypes = {
    order: PropTypes.number.isRequired,
    statement: PropTypes.string.isRequired,
    isSurvey: PropTypes.bool,
    affectsProgress: PropTypes.bool,
    lang: PropTypes.object
}

export default QuestionHeader
