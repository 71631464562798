'use-strict'

export default {
    ExerciseManagerFormContainer: lang => {
        return {
            ...lang.exerciseManager,
            exerciseRules: lang.exerciseRules,
            playground: lang.playground,
            hintModal: lang.hintModal,
            hintsTable: lang.hintsTable
        }
    }
}