import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import AddNewPasswordForm from '@/components/AddNewPasswordForm'
import ConfirmMessageMobile from '@/components/ConfirmMessageMobile'
import { updateUser } from '@/redux/reducers/_deprecated/authReducer'
import { history } from '@/app/store'
import AnimateComponent from '@/components/AnimateComponent'
import CustomCargando from '@/components/CustomCargando'
import { RoundCheckIcon } from '@/components/CustomIcons'
import './ChangePassword.scss'

const ChangePasswordContainer = ({
    lang: {
        addNewPasswordForm: {
            cancelAddNewPassword: cancelText,
            error: errorFormLang,
            errorConfirmEmpty: errorConfirmEmptyFormLang,
            errorEmpty: errorEmptyFormLang,
            goBack: goBackFormLang,
            inputConfirm: inputConfirmFormLang,
            inputConfirmPlaceholder,
            inputPassword: inputPasswordFormLang,
            inputPasswordPlaceholder,
            invalidPassword: invalidPasswordFormLang,
            sendNewPassword: sendNewPasswordFormLang,
        },
        changePasswordMessage: {
            again: againLang,
            exit: exitLang,
            goBack: goBackLang,
            message: messageLang,
            title: titleLang,
        }
    },
    user
}) => {

    const [currentComponent, setCurrentComponent] = useState(currentComponent)

    useEffect(() => {
        setCurrentComponent(getAddNewPasswordForm())
    }, [])

    const getAddNewPasswordForm = () => (
        <AddNewPasswordForm
            lang={{
                cancelAddNewPassword: cancelText,
                error: errorFormLang,
                errorConfirmEmpty: errorConfirmEmptyFormLang,
                errorEmpty: errorEmptyFormLang,
                goBack: goBackFormLang,
                inputConfirm: inputConfirmFormLang,
                inputConfirmPlaceholder,
                inputPassword: inputPasswordFormLang,
                inputPasswordPlaceholder,
                invalidPassword: invalidPasswordFormLang,
                sendNewPassword: sendNewPasswordFormLang,
            }}
            onGoBack={onGoBackHandler}
            onSubmit={onSubmitHandler}
        />
    )

    // Todo: Hay que refactoriar esto
    const onSubmitHandler = async newPassword => {

        setCurrentComponent(
            <CustomCargando />
        )

        await updateUser(user.id, newPassword, newPassword, null)
        
        setCurrentComponent(
            <ConfirmMessageMobile
                onGoBack={onGoBackHandler}
                onFisrt={onGoBackHandler}
                onSecond={changePasswordAgainHandler}
                onThird={() => { }}
                customIcon={<RoundCheckIcon style={{ color: "var(--success-light)" }} />}
                lang={{
                    goBack: goBackLang,
                    title: titleLang,
                    message: messageLang,
                    firstOption: exitLang,
                    secondOption: againLang,
                    thirdOption: ''
                }}
            />
        )

    }

    const changePasswordAgainHandler = () => {
        setCurrentComponent(getAddNewPasswordForm())
    }

    const onGoBackHandler = () => {
        history.goBack()
    }

    return (
        <div className="viewContainer changePasswordContainer">
            <AnimateComponent
                componentToShow={currentComponent}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    lang: {
        addNewPasswordForm: { ...state.langStore.addNewPassword.form, goBack: state.langStore.mainNav.changePassword },
        changePasswordMessage: { ...state.langStore.changePasswordMessage }
    },
    user: state.authReducer.user,
})

export default connect(mapStateToProps)(ChangePasswordContainer)