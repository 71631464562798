import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import LoadingOrContent from '@/components/LoadingOrContent'
import DashboardDropdownsBar from '../shared/components/DashboardDropdownsBar'
import DashboardHeader from '../shared/components/DashboardHeader'

import useCourseInfo from '../shared/hooks/useCourseInfo'
import useCourseSelects from '../shared/hooks/useCourseSelects'
import useQuizReport from './hooks/useQuizReport'
import ReportDownload from './ReportDownload'

import { QUIZZES_SECTION, QUIZ_BLOCK } from '../shared/constants/dashboard'

import './Quizzes.scss'

const Quizzes = ({ match }) => {
    const courseId = match.params.courseId
    const lang = useSelector(({ langStore }) => langStore.dashboard)
    const { dropdowns: loadingDropdowns } = useSelector(({ loading }) => loading.dashboard)

    const { name: courseName, alias: courseAlias, is_massive } = useCourseInfo(courseId)

    const {
        modules,
        selectedModule,
        lessons,
        selectedLesson,
        topics,
        selectedTopic,
        blocks,
        selectedBlock,
        onSelectChange,
    } = useCourseSelects(courseId, QUIZ_BLOCK)
    const { downloadQuizReport, downloadIsLoading } = useQuizReport(
        courseId,
        selectedBlock,
        is_massive
    )

    return (
        <div className='quiz-report-container'>
            <DashboardHeader
                section={QUIZZES_SECTION}
                courseName={courseName}
                courseAlias={courseAlias}
            />
            <LoadingOrContent loading={loadingDropdowns}>
                <DashboardDropdownsBar
                    modules={modules.map(({ name }) => name)}
                    selectedModule={selectedModule.name}
                    lessons={lessons.map(({ name }) => name)}
                    selectedLesson={selectedLesson.name}
                    topics={topics.map(({ name }) => name)}
                    selectedTopic={selectedTopic.name}
                    blocks={blocks.map(({ name }) => name)}
                    selectedBlock={selectedBlock.name}
                    onChange={(selection, index, key) => onSelectChange(selection, index, key)}
                />

                <ReportDownload
                    disabled={downloadIsLoading || !selectedBlock.id}
                    onClick={downloadQuizReport}
                    lang={lang}
                />
            </LoadingOrContent>
        </div>
    )
}

Quizzes.propTypes = {
    match: PropTypes.object,
}

Quizzes.defaultProps = {
    match: {},
}

export default Quizzes
