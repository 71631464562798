import React from 'react'
import PropTypes from 'prop-types'
import { Calendar } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import './CourseSchedule.scss'
import VerticalSeparator from '../common/Separator/VerticalSeparator'

const CourseSchedule = ({ lang, schedules, startingDate, nextLesson }) => {

    return (
        <>
            <div className='course-schedule'>
                <div className='course-schedule-title'>
                    <span>{lang?.courseInfo}</span>
                </div>
                <div className='course-schedule-main'>
                    <div className='course-schedule-main-item'>
                        <p>{lang?.startingDate} </p>
                        <div>
                            <div className='course-schedule-main-item-icon'>
                                <Calendar />
                            </div>
                            <p>{startingDate}</p>
                        </div>
                    </div>
                    <VerticalSeparator />
                    <div className='course-schedule-main-item'>
                        <p>{lang.daysAndHours} </p>
                        <div>
                            <div className='course-schedule-main-item-icon'>
                                <Calendar />
                            </div>
                            <p>{schedules}</p>
                        </div>
                    </div>
                    {nextLesson && (
                        <>
                            <VerticalSeparator />
                            <div className='course-schedule-main-item'>
                                <p>{lang.nextClass} </p>
                                <div>
                                    <div className='course-schedule-main-item-icon'>
                                        <Calendar />
                                    </div>

                                    <p>{nextLesson}</p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

CourseSchedule.propTypes = {
    langSchedule: PropTypes.object,
    nextLesson: PropTypes.string,
    startingDate: PropTypes.string,
}

export default CourseSchedule
