import configureStore from '@/app/store'
import actions from './alertsActions'
import { ADD_MESSAGE, DELETE_FIRST_MESSAGE, DELETE_MESSAGE } from './alertsConstants'

const getStore = () => configureStore.store
const getGlobalAlertsStoreState = () => getStore().getState().globalAlertsStore
let inIntervalState = false

const initInterval = () => {
    inIntervalState = true
    setTimeout(() => {
        const isNotTemporalFinded = getGlobalAlertsStoreState().find(item => item.isTemporal)
        if (isNotTemporalFinded) {
            getStore().dispatch(actions[DELETE_FIRST_MESSAGE]()); initInterval()
        } else {
            inIntervalState = false
        }
    }, 4000)
}

const closeMessage= id => {
    getStore().dispatch(actions[DELETE_MESSAGE](id))
}

const showNewMessage = ({ type, message, isTemporal }) => {
    getStore().dispatch(actions[ADD_MESSAGE]({ type, message, isTemporal }))
    if (!inIntervalState) initInterval()
}

export default showNewMessage
export { closeMessage }