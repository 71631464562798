import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import 'react-datepicker/dist/react-datepicker.css'
import { BLOCK_TYPES } from '@/constants/blocks'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import BlockModal from './BlockModal'
import { hasAnyProgressSelector, createModalDataSelector } from '@/redux/selectors'
import { getSelectOptions } from '@/helpers/arrays'
import { EMPTY_FIELD } from './constants/errorTypes'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import { validateSlug } from '@/helpers/strings/validateSlug'
import { refreshNotionPage } from '@/redux/http/services/notionService'

const DEFAULT_TEXT_BLOCK = {
    TextBlock: {
        text: '',
        extra: false,
    },
}

const textBlockSelector = createModalDataSelector(DEFAULT_TEXT_BLOCK)

const langSelector = ({ langStore: { modalNotionBlock, modalExerciseBlock } }) => ({
    ...modalNotionBlock,
    ...modalExerciseBlock,
})

const getPageId = (text) => {
    const notionPageIdMatch = text?.match(BLOCK_TYPES.NOTION_BLOCK.regex)
    return notionPageIdMatch ? notionPageIdMatch[1] : null
}

const ModalNotionBlock = () => {
    const lang = useSelector(langSelector)
    const hasAnyProgress = useSelector(hasAnyProgressSelector)
    const data = useSelector(textBlockSelector)
    const pageId = getPageId(data.TextBlock.text)
    const [text, setText] = useState(pageId ?? '')
    const [textError, setTextError] = useState(null)
    const [extra, setExtra] = useState(data.extra)

    const values = { text }

    const validateInputValues = useCallback(() => {
        const error = validateSlug(text, lang.requiredTextMessage)
        setTextError(error)
        return error ? [EMPTY_FIELD] : []
    }, [lang, text])

    const transformOnSubmit = useCallback((data) => {
        data.text = BLOCK_TYPES.NOTION_BLOCK.stringKey.replace('PAGE_ID', data.text)

        if (pageId) clearCache()

        return data;
    }, [pageId])

    const clearCache = useCallback(() => {
        try {
            refreshNotionPage(pageId)

        } catch(e) {
            console.error(e)
        }
    }, [pageId])

    const blockTitle = data.id ? lang.modalNotionTitleEdit : lang.modalNotionTitleCreate
    const selectOptions = getSelectOptions(lang.yes, lang.no)
    const getValue = (option) => (option ? selectOptions[0] : selectOptions[1])
    const id = BLOCK_TYPES.NOTION_BLOCK.modal

    return (
        <BlockModal
            validateInputValues={validateInputValues}
            transformOnSubmit={transformOnSubmit}
            block={data}
            values={values}
            lang={lang}
            blockTitle={blockTitle}
            blockType={BLOCK_TYPES.NOTION_BLOCK}
            id={id}
            blockId={data.id}
            visibility={data.visibility}
            show_name={data.show_name}
            name={data.name}
            extra={extra}
        >
            <Row>
                <Col lg="12" md="12">
                    <Label text={lang.isExtraLabel} />
                    <InputComboBox
                        disabled={hasAnyProgress}
                        inputName='extra'
                        placeholder={lang.select}
                        id='extra'
                        dropdownItems={selectOptions.map(({ label }) => label)}
                        onChange={label => setExtra(label === selectOptions[0].label)}
                        value={getValue(extra).label}
                    />
                </Col>
                <Col lg="12" md="12">
                    <Label text={'ID de Página de Notion'} />
                    <InputText
                        key='blockNotion'
                        id='blockNotion'
                        value={text}
                        placeholder='e.g. Pagina-recursos-fa6c42f285904153818056eb90d169c1'
                        onChange={(e) => setText(e.target.value)}
                        error={textError}
                    />
                </Col>
            </Row>
        </BlockModal>
    )
}

export default ModalNotionBlock
