import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useIdleTimer from '@/hooks/useIdleTimer'
import { setIdle } from '@/redux/thunks/idleThunks'
import { IDLE_TIME_OUT, KEEP_SESSION_TIME } from '@/config/env'
import { getIsAuthenticated } from '@/redux/selectors/auth'

const IdleTimer = () => {
    const dispatch = useDispatch()
    const { isIdle, keepLogged } = useSelector(({ idleTime }) => idleTime)
    const IsAuthenticated = useSelector(getIsAuthenticated)

    const handleOnIdle = () => {
        if (IsAuthenticated && !isIdle) {
            dispatch(setIdle())
        }
    }

    useIdleTimer({
        timeout: keepLogged ? KEEP_SESSION_TIME : IDLE_TIME_OUT,
        onIdle: handleOnIdle,
        debounce: 500,
    })

    return <></>
}

export default IdleTimer
