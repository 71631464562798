import React from 'react'
import './ExerciseReport.scss'
import moment from 'moment'

moment.locale(getTenantLang())

import { iconAsset } from '@/assets/img/config'
import { getTenantLang } from '@/helpers/tenants/helpers'
import './shared.scss'


const ExerciseReport = ({
    studentsData,
    lang
}) => {

    const tableHeaders = [
        lang.name,
        lang.lastName,
        lang.state,
        lang.attempts,
        lang.shippingDate,
    ]

    const getHeaders = () => {
        let headers = tableHeaders,
            i,
            returnHeaders = [],
            x = 0

        for (i in headers) {
            let className = x > 1 ? 'col-md-2 text-center' : 'col-md-3'

            returnHeaders.push(
                <th
                    key={headers[i]}
                    className={className}
                >
                    {headers[i]}
                </th>
            )
            x++
        }
        return returnHeaders
    }

    const getRows = () => {
        let tableRows = studentsData.map((student, index) => {
            let returnValue = []
            let progress = null
            if (student.User.BlockProgresses[0] && student.User.BlockProgresses[0].ProgressExercise) {
                if (typeof (student.User.BlockProgresses[0].ProgressExercise.last_sending) !== 'object') {
                    student.User.BlockProgresses[0].ProgressExercise.last_sending = JSON.parse(student.User.BlockProgresses[0].ProgressExercise.last_sending)
                }
                progress = student.User.BlockProgresses[0]
            }
            returnValue.push(
                <React.Fragment key={`table-${index}`}>
                    <td key={'name-' + index} className="col-md-3">
                        {`${student.User.name}`}
                    </td>

                    <td key={'surname-' + index} className="col-md-3">
                        {`${student.User.surname}`}
                    </td>

                    <td key={'completed-' + index} className="col-md-2 text-center">
                        {getIcon(progress ? progress.completed : null)}
                    </td>

                    <td key={'sendings-' + index} className="col-md-2 text-center __card_text">
                        {progress ? progress.ProgressExercise.sendings_quantity : '0'}
                    </td>

                    <td key={'sending_date-' + index} className="col-md-2 text-center __card_text">
                        {progress ? moment(progress.ProgressExercise.last_sending.sending_date, 'L', 'es').utc().format('L') : '-'}
                    </td>

                </React.Fragment>
            )

            return (<tr key={student.user_id}>
                {returnValue}
            </tr>)
        })

        return tableRows
    }


    const getIcon = (completed) => {
        let icon = null
        let dot = (
            <div key={`${Math.random()}-dot-div`}>
                <span className='__dot __bigger' key={`${Math.random()}-dot-span`}></span>
            </div>
        )
        if (completed !== null && completed !== undefined) {
            icon = (
                <div className="headerIconBox" key={`${Math.random()}-header-icon-box`}>
                    <img
                        key={`${Math.random()}-header-img`}
                        src={completed ? iconAsset('check.svg') : iconAsset('cross-circle.svg')}
                        className={'mR exerciseHeaderIcon ' + (completed ? '__successBG' : '')}
                    />
                </div>
            )
        }
        return (
            <div key={`icon-key-${Math.random()}`} className="__flex_item">{icon ? icon : dot}</div>
        )
    }


    if (studentsData) {
        return (
            <div>
                <table className="table __table">
                    <thead>
                        <tr>
                            {getHeaders()}
                        </tr>
                    </thead>
                    <tbody>
                        {getRows()}
                    </tbody>
                </table>
            </div>
        )
    }
    else {
        return <div className="__search_msg_result"><p>{lang.noDataToDisplay}</p></div>
    }
}


export default ExerciseReport