import React from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { history } from '@/app/store.js'

import { handleTabContentChange, saveTabContent, getOneTab, clearActiveCourseTabAction } from '@/redux/reducers/_deprecated/courseReducer'
import TabEdit from './TabEdit'
import CustomCargando from '@/components/CustomCargando'


export class EditTabsContainer extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            isLoaded: false,
        }

        this.handleContentChange = this.handleContentChange.bind(this)
        this.handleSaveTabContent = this.handleSaveTabContent.bind(this)
        this.goToTabsList = this.goToTabsList.bind(this)
    }

    componentDidMount() {
        this.props.clearActiveCourseTabAction()
        let tab_id = this.props.match.params.tabId
        let courseId = this.props.match.params.courseId
        this.props.getOneTab(tab_id, courseId)
        this.setState({ isLoaded: true })
    }

    handleContentChange(content) {
        this.props.handleTabContentChange(content)
    }

    handleSaveTabContent(data, courseId) {
        this.props.saveTabContent(data, courseId)
    }

    goToTabsList() {
        let courseId = this.props.match.params.courseId
        history.push("/admin/courses/" + courseId + "/tabs")
    }


    render() {
        if (this.state.isLoaded) {
            return (
                <React.Fragment>
                    <TabEdit
                        courseId={this.props.match.params.courseId}
                        tabId={this.props.match.params.tabId}
                        tabData={this.props.courseTab}
                        contentChange={this.handleContentChange}
                        submitTabContent={this.handleSaveTabContent}
                        goToTabsList={() => {
                            this.goToTabsList()
                        }}
                    />
                </React.Fragment>
            )
        } else {
            return (
                <CustomCargando />
            )
        }
    }
}

const mapDispatch = (dispatch) => ({
    handleTabContentChange: (contentData) => {
        dispatch(handleTabContentChange(contentData))
    },
    saveTabContent: (data, courseId) => {
        dispatch(saveTabContent(data, courseId))
    },
    clearActiveCourseTabAction: () => {
        dispatch(clearActiveCourseTabAction())
    },
    getOneTab: (tab_id, courseId) => {
        dispatch(getOneTab(tab_id, courseId))
    }
})

const mapStateToProps = (state, ownProps) => {
    return {
        courseTab: state.courseReducer.courseTab
    }
}

export default withRouter(connect(mapStateToProps, mapDispatch)(EditTabsContainer))