import React, { useState } from 'react'
import { history } from '@/app/store'
import VideoHero from '@digitalhouse-tech/react-lib-ui-explorer/lib/VideoHero'
import { recoverPasswordByEmail } from '@/redux/thunks/recoverPasswordThunks'
import { useSelector, useDispatch } from 'react-redux'
import { Mail } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import RecoverMessageCard from '@/modules/Auth/RecoverPasswordManager/RecoverMessageCard'
import useVideoHero from '@/modules/Auth/shared/hooks/useVideoHero'
import * as card from '@/modules/Auth/RecoverPasswordManager/helpers/constants'
import CredentialsManager from '../CredentialsManager'

const EmailManager = () => {
    const [cardStatus, setCardStatus] = useState(card.EMAIL_FORM)
    const [email, setEmail] = useState('')
    const [emailConfirm, setEmailConfirm] = useState('')

    const dispatch = useDispatch()
    const lang = useSelector(({ langStore }) => langStore.recoverPassword)

    const onSubmit = async () => {
        const [error, isValid] = await dispatch(recoverPasswordByEmail(email))

        if (error) {
            setCardStatus(card.ERROR_SERVER)
        } else if (!isValid) {
            setCardStatus(card.INVALID_EMAIL)
        } else {
            setCardStatus(card.EMAIL_SENT)
        }
    }

    const onBack = () => location.href = '/'
    const { videoSrc, posterSrc, autoPlay, notSupportedMsg } = useVideoHero()
    const firstParagraph = lang.sentEmailMessage.text.replace('${email}', '|')

    const components = {
        [card.EMAIL_FORM]: (
            <CredentialsManager
                type={card.EMAIL_FORM}
                onSubmit={onSubmit}
                onBack={onBack}
                lang={{ ...lang.emailForm, returnButton: lang.returnButton }}
                onChangeInput={(e) => setEmail(e.currentTarget.value)}
                onChangeInputConfirm={(e) => setEmailConfirm(e.currentTarget.value)}
                inputValue={email}
                inputConfirmValue={emailConfirm}
            />
        ),
        [card.EMAIL_SENT]: (
            <RecoverMessageCard
                text={firstParagraph}
                email={email}
                lang={{ ...lang.sentEmailMessage, returnButton: lang.returnButton }}
                hasReturnButton
                icon={<Mail />}
                redirectToLogin={() => location.href = '/'}
            />
        ),
        [card.INVALID_EMAIL]: (
            <CredentialsManager
                type={card.EMAIL_FORM}
                onSubmit={onSubmit}
                onBack={onBack}
                lang={{
                    ...lang.emailForm,
                    returnButton: lang.returnButton,
                }}
                onChangeInput={(e) => setEmail(e.currentTarget.value)}
                onChangeInputConfirm={(e) => setEmailConfirm(e.currentTarget.value)}
                inputValue={email}
                inputConfirmValue={emailConfirm}
                notValidatedEmail
            />
        ),
        [card.ERROR_SERVER]: (
            <CredentialsManager
                type={card.EMAIL_FORM}
                onSubmit={onSubmit}
                onBack={onBack}
                lang={{
                    ...lang.emailForm,
                    returnButton: lang.returnButton,
                }}
                onChangeInput={(e) => setEmail(e.currentTarget.value)}
                onChangeInputConfirm={(e) => setEmailConfirm(e.currentTarget.value)}
                inputValue={email}
                inputConfirmValue={emailConfirm}
                emailNotFound
            />
        ),
    }

    return (
        <>
            <VideoHero
                videoSrc={videoSrc}
                posterSrc={posterSrc}
                autoPlay={autoPlay}
                notSupportedMsg={notSupportedMsg}
            />
            {components[cardStatus]}
        </>
    )
}

export default EmailManager
