'use-strict'

import React, { useEffect } from 'react'
import { getTenantLang } from '@/helpers/tenants/helpers'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import SummerNote from '@/components/Wysiwyg'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import InputTag from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputTag'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import ToggleButton from '@digitalhouse-tech/react-lib-ui-explorer/lib/ToggleButton'

const ExerciseDataForm = ({
    data = {
        name: '',
        statement: '',
        tags: []
    },
    handlers = {
        setField: () => {}
    },
    validations = {
        name: {},
        statement: {}
    },
    lang = {},
    allTags = [],
    micInitial,
    camInitial,
    location = {},
}) => {
    const isIframe = location.search.match(/exerciseType=IFRAME/gm);

    const [allowCamera, setAllowCamera] = React.useState(camInitial);
    const [allowMicrophone, setAllowMicrophone] = React.useState(micInitial);
    
    const infiniteOptions = () => ([
        { value: true, label: lang.yes },
        { value: false, label: lang.no }
    ])
    const tags = () => allTags.map(tag => ({ value: tag.name, label: tag.name }))
    const activeTags = () => data.tags.map(tag => ({
        value: tag,
        label: tag
    }))
    const activeInfinite = () => {
        return infiniteOptions().find(opt => opt.value === !!data.infinite)
    }

    const handleChangeCamera = (value) => {
        handlers.setField('allowCamera', value)
        setAllowCamera(value)
    }
    const handleChangeMicrophone = (value) => {
        handlers.setField('allowMicrophone', value)
        setAllowMicrophone(value)
    }

    useEffect(() => {
        setAllowCamera(camInitial)
        setAllowMicrophone(micInitial)
    }, [data])

    return (
        <>
            <div className="row">
                <br />
                <div className="col-md-8">
                    <Label text={`${lang.name} *`} />
                    <InputText
                        id="name"
                        value={data.name}
                        onChange={e => handlers.setField('name', e.target.value)}
                        autoFocus
                        error={validations.name.state}
                        errorMsg={validations.name.message}
                    />
                </div>
                <div className="col-md-4">
                    <Label text={lang.alwaysSaveTheLastAnswer} />
                    <InputComboBox
                        inputName="infinite"
                        placeholder={lang.choose}
                        id="infinite"
                        dropdownItems={infiniteOptions().map(option => option.label)}
                        onChange={elem => handlers.setField('infinite', !!(elem === lang.yes))}
                        value={activeInfinite().label}
                    />
                </div>
                <div className="col-md-12" style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <Label text={lang.tags} />
                    <InputTag
                        name="tags"
                        id="tags"
                        key="tags"
                        dropdownItems={tags().map(tag => tag.label)}
                        onChange={items => {
                            handlers.setField('tags', items)
                        }}
                        selectedTags={activeTags().map(tag => tag.label)}
                        createNewTag
                        langCountry={getTenantLang()}
                    />
                </div>
                <div className="col-md-12">
                    <Label text={`${lang.statement} *`} />
                    <div className={validations.statement.state ? '__wysiwyg--error' : null}>
                        <SummerNote
                            onChange={content => handlers.setField('statement', content)}
                            value={data.statement}
                        />
                    </div>
                    {validations.statement.state &&
                        <MessageBox
                            theme='danger'
                            text={validations.statement.message}
                            noFill
                        />
                    }
                </div>
                <div className="col-md-12" style={{ marginBottom: '20px', marginTop: '20px', display: isIframe ? 'block' : 'none' }}>
                    <Label text={lang.allowMicrophone} />
                    <ToggleButton
                        onToggle={() => handleChangeMicrophone(!allowMicrophone)}
                        toggled={allowMicrophone}
                        inputName='microphone'
                        key='microphone'
                    />
                </div>
                <div className="col-md-12" style={{ marginBottom: '20px', marginTop: '20px', display: isIframe ? 'block' : 'none' }}>
                    <Label text={lang.allowCamera} />
                    <ToggleButton
                        onToggle={() => handleChangeCamera(!allowCamera)}
                        toggled={allowCamera}
                        inputName='camera'
                        key='camera'
                    />
                </div>
            </div>
        </>
    )
}

export default ExerciseDataForm