import React from 'react'
import PropTypes from 'prop-types'
import { Key, Trash2 } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'

const StudentListActions = ({
    enableChangePassword,
    enableDeleteStudents,
    handleChangePassword,
    openModalDelete
}) => {

    return (
        <>
            <ButtonGroup>
                {enableChangePassword &&
                    <ButtonRound
                        onClick={() => handleChangePassword()}
                        icon={<Key />}
                        id='changePasswordStudent'
                        theme='info'
                    />
                }

                {enableDeleteStudents &&
                    <ButtonRound
                        icon={<Trash2 />}
                        onClick={() => openModalDelete()}
                        id='deleteStudent'
                        theme='danger'
                    />
                }
            </ButtonGroup>

        </>
    )
}

StudentListActions.propTypes = {
    handleChangePassword: PropTypes.func.isRequired,
    openModalDelete: PropTypes.func.isRequired,
    enableChangePassword: PropTypes.bool.isRequired,
    enableDeleteStudents: PropTypes.bool.isRequired,
}

export default StudentListActions
