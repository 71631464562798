import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { BLOCK_TYPES } from '@/constants/blocks'
import { validateEmptyString } from '@/helpers/strings'
import { getSelectOptions } from '@/helpers/arrays'
import BlockModal from './BlockModal'
import { hasAnyProgressSelector, createModalDataSelector } from '@/redux/selectors'
import HTMLEditor from '@/components/HTMLEditor'
import { EMPTY_FIELD } from './constants/errorTypes'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

const DEFAULT_TEXT_HTML_BLOCK = {
    TextHtmlBlock: {
        html: '',
        extra: false
    },
}

const textHtmlBlockSelector = createModalDataSelector(DEFAULT_TEXT_HTML_BLOCK)

const langSelector = ({ langStore: { modalTextHtmlBlock, modalExerciseBlock } }) => ({
    ...modalTextHtmlBlock,
    ...modalExerciseBlock,
})

const ModalTextHtmlBlock = () => {
    const lang = useSelector(langSelector)
    const hasAnyProgress = useSelector(hasAnyProgressSelector)
    const data = useSelector(textHtmlBlockSelector)
    const [html, setHtml] = useState(data.TextHtmlBlock.html)
    const [htmlError, setHtmlError] = useState(null)
    const [extra, setExtra] = useState(data.extra)

    const values = { html }

    const checkStyles = useCallback((html = '', errorMessage = '') => {
        //const inline = /style=(["']).*(["'])/.test(html)
        const tag = html.search(/<style.*>/)

        return (tag !== -1) ? errorMessage : null
    }, [])

    const validateInputValues = useCallback(() => {
        const error = validateEmptyString(html, lang.requiredHtmlMessage)
        const stylesError = checkStyles(html, lang.stylesError)
        setHtmlError(error || stylesError)
        return (error || stylesError) ? [EMPTY_FIELD] : []
    }, [lang, html, checkStyles])

    const blockTitle = data.id ? lang.modalHtmlTitleEdit : lang.modalHtmlTitleCreate
    const selectOptions = getSelectOptions(lang.yes, lang.no)
    const getValue = (option) => (option ? selectOptions[0] : selectOptions[1])
    const id = BLOCK_TYPES.TEXT_HTML_BLOCK.modal

    return (
        <BlockModal
            validateInputValues={validateInputValues}
            block={data}
            values={values}
            lang={lang}
            blockTitle={blockTitle}
            blockType={BLOCK_TYPES.TEXT_HTML_BLOCK}
            id={id}
            blockId={data.id}
            visibility={data.visibility}
            show_name={data.show_name}
            name={data.name}
            extra={extra}
        >
            <Row>
                <Col lg="12" md="12">
                    <Label text={lang.isExtraLabel} />
                    <InputComboBox
                        disabled={hasAnyProgress}
                        inputName='extra'
                        placeholder={lang.select}
                        id='extra'
                        dropdownItems={selectOptions.map(({ label }) => label)}
                        onChange={label => setExtra(label === selectOptions[0].label)}
                        value={getValue(extra).label}
                    />
                </Col>
                <Col md="12">
                    <Label text={lang.htmlLabel} />
                </Col>
                <Col lg="12" md="12">
                    <HTMLEditor
                        html={html}
                        handleChange={setHtml}
                    />
                </Col>
            </Row>
        </BlockModal>
    )
}

export default ModalTextHtmlBlock
