'use-strict'

import React from 'react'
import ReactSummernote from 'react-summernote'
import 'react-summernote/dist/react-summernote.css' // import styles
import 'react-summernote/lang/summernote-es-ES' // you can import any other locale
import 'react-summernote/lang/summernote-pt-BR'
import { getTenantLang } from '@/helpers/tenants/helpers'

export default (props) => {

    const handleDisabled = () => {
        if (props.disabled == true || props.disabled == false) {
            return props.disabled
        } else {
            return false
        }
    }

  return (
    <ReactSummernote
      value={props.value}
      children={<div dangerouslySetInnerHTML={{ __html: props.value }}></div>}
      disabled={handleDisabled()}
      options={{
        lang: getTenantLang(),
        height: 200,
        dialogsInBody: false,
        dialogsFade: true,
        fontNames: ['Poppins', 'SourceSansPro'],
        fontNamesIgnoreCheck: ['Helvetica', 'sans-serif', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New'],
        toolbar: [
          ['placeholder', ['acacaca']],
          ['style', ['style']],
          ['font', ['bold', 'underline', 'clear']],
          ['fontsize', ['fontsize']],
          ['fontname', ['fontname']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['table', ['table']],
          ['insert', ['link', 'picture']],
          ['view', ['fullscreen', 'codeview']]
        ]
      }}
      onChange={props.onChange}
      className="__wysiwyg"
    />
  )
}
