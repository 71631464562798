import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './Presence.scss'
import { MINIMUM_TO_APPROVE_PRESENCE } from '@/constants/grades'
import { CheckCircle, AlertTriangle } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'

const Presence = ({ presence }) => {
    const isSuccess = presence >= MINIMUM_TO_APPROVE_PRESENCE
    const data = useCallback(() => {
        return [
            {
                label: 'Ausente',
                value: 4,
            },
            {
                label: 'Tarde',
                value: 1,
            },
            {
                label: 'Presente',
                value: 23,
            },
        ]
    }, [])

    const classes = useCallback(() => {
        return cn('presence__value', {
            'presence__value--success': isSuccess,
        })
    }, [presence])

    const Icon = isSuccess ? CheckCircle : AlertTriangle

    return (
        <div className='presence'>
            {data().map((item, i) => (
                <div key={i} className='presence__item'>
                    <p className='presence__item-label'>{item.label}</p>
                    <span className='presence__item-value'>{item.value}</span>
                </div>
            ))}
            <div className='presence__divider' />
            <Tooltip
                tooltipContent={
                    'Este es tu detalle de presentismo. Para aprobar, necesitas superar el 90%.'
                }
                tooltipPlacement='bottom'
            >
                <div className={classes()}>
                    {<Icon className='presence__icon' size={14} />}
                    <span>{presence}%</span>
                </div>
            </Tooltip>
        </div>
    )
}

Presence.propTypes = {
    presence: PropTypes.number,
}

Presence.defaultProps = {
    presence: 0,
}

export default Presence
