import * as bases from '@/config/endpoints'
import api from '@/config/endpoints'
import { getTenantSession } from '@/helpers/tenants/helpers'
import { getConfig } from '@/helpers/utils'
import httpClient from '../httpClient'
import { getTenantLang } from '@/helpers/tenants/helpers'

export const recoverPassword = (email) => {
    const redirectUrl = `${getTenantSession().host}/renew-password?token=`
    return httpClient().post(`${bases.base}users/recover?redirectUrl=${redirectUrl}&lang=${getTenantLang()}`, { email })
}

export const sendToken = (token) => {
    return httpClient().get(`${api.userValidateToken}?token=${token}`, getConfig())
}

export const sendNewPasswordAndToken = (password, token) => {
    return httpClient().put(
        `${api.updatePassword}`,
        {
            newPassword: password,
            token,
        },
        getConfig()
    )
}
