import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import useClassroomRedirect from '../../../../useClassroomRedirect'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { handleToggleMobileMenu } from '@/redux/reducers/student/classroom'
import { getTopicProgressByTopicId } from '@/redux/selectors/students/progress'
import ProgressIcon from './ProgressIcon'
import './Topic.scss'

const Topic = memo(({ topic: { Blocks, ...topic }, lessonId: lesson_id }) => {
    const dispatch = useDispatch()
    const { classroomRedirect, topic_id: current_topic_id } = useClassroomRedirect()
    const topicProgress = useSelector(getTopicProgressByTopicId(topic.id, lesson_id))

    const handleTopicRedirect = () => {
        classroomRedirect({ lesson_id, topic_id: topic.id })
        dispatch(handleToggleMobileMenu())
    }

    const hasExtra = useMemo(() => {
        return Blocks.some((block) => block.extra)
    }, [Blocks])

    return (
        <li className='topic-progress-container'>
            <Button
                id={`handleTopicRedirectButton-${topic.id}`}
                theme='clear'
                className='topic-progress-container__item'
                onClick={handleTopicRedirect}
                text={
                    <>
                        <ProgressIcon
                            completed={topicProgress}
                            isActive={current_topic_id == topic.id}
                            hasExtra={hasExtra}
                        />
                        <span
                            className={cn('topic-progress-container__title', {
                                'topic-progress-container__title--active':
                                    current_topic_id == topic.id,
                            })}
                        >
                            {topic.name}
                        </span>
                    </>
                }
            />
        </li>
    )
})

Topic.propTypes = {
    topic: PropTypes.object.isRequired,
    completed: PropTypes.bool,
    lessonId: PropTypes.string,
}

Topic.defaultProps = {
    completed: null,
    lessonId: null,
}

export default Topic
