import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import LoadingOrContent from '@/components/LoadingOrContent'
import Skeleton, { SkeletonGroup } from '@/components/Skeleton'

import { ChevronLeft, ChevronRight } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import useTopicNav from './useTopicNav'
import './TopicNav.scss'

const TopicNav = memo(({ isMobile, loading, hasActiveQuiz, showTopicName }) => {
    const {
        handleForward,
        handleGoBack,
        hasNext,
        hasPrev,
        topicName,
        previousLang,
        nextLang,
        allowFinishCourse,
        enableFinishCourseButton,
        handleGotToCertificate,
        mandatoryContentLang,
        finishCourseLang
    } = useTopicNav({
        isMobile,
    })

    if ((!hasPrev && !hasNext) || (hasActiveQuiz && isMobile)) {
        return null
    }

    return (
        <LoadingOrContent
            loading={loading}
            loadingComponent={
                <SkeletonGroup containerStyles="topic-nav-container--skeleton">
                    <Skeleton width="140px" circle={isMobile} left />
                    {isMobile && <Skeleton width="95%" />}
                    <Skeleton width="140px" circle={isMobile} right />
                </SkeletonGroup>
            }
        >
            <div className="topic-nav-container">
                <Button
                    hidden={!hasPrev && !isMobile}
                    id="PreviosNavigationButton"
                    className="pull-left common-text"
                    onClick={handleGoBack}
                    icon={<ChevronLeft className="topic-nav-container__icon" />}
                    leftIcon
                    text={!isMobile && previousLang?.buttonText}
                    theme="clear"
                    aria-label={previousLang?.ariaLabel}
                />

                {(showTopicName || isMobile) && (
                    <h1 className='topic-nav-container__title'>{topicName}</h1>
                )}

                {isMobile &&
                    !hasNext &&
                    !allowFinishCourse && (<div className='topic-nav-container__filler' />)}

                <Button
                    visible={hasNext}
                    id="NextNavigationButton"
                    className="pull-right"
                    onClick={handleForward}
                    text={!isMobile && nextLang?.buttonText}
                    icon={<ChevronRight className="topic-nav-container__icon" />}
                    theme="clear"
                    aria-label={nextLang?.ariaLabel}
                />

                <Button
                    visible={allowFinishCourse && !hasNext}
                    id='FinishNavigationButton'
                    onClick={handleGotToCertificate}
                    text={finishCourseLang?.buttonText}
                    tooltipId='student_finish_course'
                    tooltipContent={enableFinishCourseButton ? '' : mandatoryContentLang}
                    icon={<ChevronRight className="topic-nav-container__icon" />}
                    className={cn({
                        'topic-nav-container--disabled': !enableFinishCourseButton,
                        'topic-nav-container--hidden': hasNext,
                        'topic-nav-container--pulse': enableFinishCourseButton
                    }, 'topic-nav-container--active')}
                    theme='clear'
                    aria-label={finishCourseLang?.ariaLabel}
                />
            </div>
        </LoadingOrContent>
    )
})

TopicNav.propTypes = {
    isMobile: PropTypes.bool,
    loading: PropTypes.bool,
    hasActiveQuiz: PropTypes.bool,
    showTopicName: PropTypes.bool,
}

TopicNav.defaultProps = {
    isMobile: false,
    loading: false,
    hasActiveQuiz: false,
    showTopicName: false,
}

export default TopicNav
