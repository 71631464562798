import React from 'react'
import PropTypes from 'prop-types'
import './RotatePhoneMessage.scss'

const RotatePhoneMessage = ({ message, messageIcon, className }) => {
    return (
        <div className={`RotatePhoneMessage ${className}`}>
            <div className="messageIcon_box">{messageIcon}</div>
            <p>{message}</p>
        </div>
    )
}

RotatePhoneMessage.propTypes = {
    message: PropTypes.string.isRequired,
    messageIcon: PropTypes.node.isRequired,
    className: PropTypes.string,
}

RotatePhoneMessage.defaultProps = {
    className: '',
}

export default RotatePhoneMessage
