import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import EmailManager from './CredentialsManager/EmailManager'
import PasswordManager from './CredentialsManager/PasswordManager'
import { PASSWORD_FORM, EMAIL_FORM, INVALID_TOKEN } from './helpers/constants'
import { getTenantSession } from '@/helpers/tenants/helpers'
import { sendToken } from '@/redux/thunks/recoverPasswordThunks'

const RecoverPasswordManager = (props) => {
    const dispatch = useDispatch()
    const tenantId = getTenantSession()._id

    const [currentStatus, setCurrentStatus] = useState('')
    const [currentToken, setCurrentToken] = useState('')
    const [firstPassword, setFirstPassword] = useState(false)

    const sendCurrentToken = async (token) => {
        const [error, response] = await dispatch(sendToken(token))
        error ? setCurrentStatus(INVALID_TOKEN) : setCurrentStatus(PASSWORD_FORM)
        setCurrentToken(token)
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        setFirstPassword(searchParams.has('first_set'));

        if (tenantId) {
            const token = searchParams.get('token')
            if(!token) {
                setCurrentStatus(EMAIL_FORM)
            } else {
                sendCurrentToken(token)
            }
        }
    }, [tenantId])

    const getFormComponent = () => {
        const types = {
            [EMAIL_FORM]: <EmailManager />,
            [PASSWORD_FORM]: <PasswordManager token={currentToken} firstPassword={firstPassword}  />,
            [INVALID_TOKEN]: <PasswordManager token={currentToken} invalidToken />,
        }
        return types[currentStatus]
    }
    return <> {getFormComponent()} </>
}

export default RecoverPasswordManager