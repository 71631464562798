import * as deploymentAlertService from '../http/services/configService'
import alertTypes from '@/components/Alerts/alertTypes'
import { INCOMING_DEPLOY_INTERVAL } from '@/config/env'
import { turnOnDeploymentIntervalAction } from '../reducers/deployment'

const isDeploymentComingSoon = (status, time) => status && time
export const incomingDeployment = () => async (dispatch, getState) => {
    const { turnOnDeploymentInterval } = getState().deployment

    const deploymentAlert = async () => {
        const res = await deploymentAlertService.getDeploymentAlert()
        const { status, time } = res[1]
        if (isDeploymentComingSoon(status, time)) {
            const response = {
                code: 501,
                errors: status,
                message: time,
            }
            const alerta = alertTypes(response)
            dispatch(alerta)
        }
    }

    getState().authReducer.isAuthenticated && deploymentAlert()

    if (!turnOnDeploymentInterval) {        
        const idInterval = setInterval(async () => {
            const { closeClicked } = getState().deployment
            if (closeClicked) {
                clearInterval(idInterval)
            } else {
                getState().authReducer.isAuthenticated && deploymentAlert()
            }
        }, INCOMING_DEPLOY_INTERVAL)
        dispatch(turnOnDeploymentIntervalAction())
    }
}
