import { useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { isEmpty, isNull } from 'lodash/lang'
import { getBlockProgress } from '@/redux/selectors/students/progress'
import { htmlentities } from '@/helpers/utils'
import { actions as modalActions } from '@/redux/reducers/modalReducer'
import { CONFIRMATION_MODAL } from '../../../../../../constants'
import { EMPTY_LIST, EMPTY_OBJECT, EMPTY_STRING } from '@/constants/utils'
import {
    JAVASCRIPT,
    JAVA_JDK11,
    BROWSER,
    NODEJS_TERMINAL,
    JAVA_TERMINAL,
    SRC,
    LOG,
    THROW,
    HTML_LANGUAGE,
    CSS_LANGUAGE,
    JS_LANGUAGE,
    JAVA_LANGUAGE,
    HTML_EXTENSION,
    CSS_EXTENSION,
    JS_EXTENSION,
    JAVA_EXTENSION,
    SOLUTION,
} from '../../../../../../constants/exerciseBlock'
import {
    updateProgressExercise,
    setProgressExercise,
} from '../../../../../../redux/reducers/student/classroom'

const LANGUAGES = {
    [HTML_EXTENSION]: HTML_LANGUAGE,
    [CSS_EXTENSION]: CSS_LANGUAGE,
    [JS_EXTENSION]: JS_LANGUAGE,
    [JAVA_EXTENSION]: JAVA_LANGUAGE,
}

const useExerciseBlock = ({
    block,
    statement,
    hints,
    moral,
    exerciseType,
    progressReport,
    files: initialFiles,
}) => {
    const dispatch = useDispatch()

    // @todo make selectors
    const { progressLoading, playgroundLang, courseFinishDate, progressExercises } = useSelector(
        ({
            langStore: {
                playground,
                generalStudentWarnings,
                topicBlock: { exerciseBlock },
            },
            loading: { classroomProgress = {} },
            student: { classroom: { content_map, progress: { progressExercises } } }
        }) => ({
            playgroundLang: { ...playground, ...generalStudentWarnings, exerciseBlock },
            progressLoading: classroomProgress,
            courseFinishDate: content_map.Course && content_map.Course.finish_date,
            progressExercises,
        }),
    )

    const blockProgress = useSelector(getBlockProgress(block.id))
    const { content_map: { User } = {}} = useSelector(({ student: { classroom } = {} }) => classroom)
    const hasViewSolutionPermission = User?.isOwner

    useEffect(() => {
        if (!isEmpty(blockProgress?.ProgressExercise?.last_sending)) {
            const lastSending = JSON.parse(blockProgress.ProgressExercise.last_sending)
            const matchFiles = lastSending.files.map(file => {
                const defaultConfigFile = initialFiles.find(f => f.name == file.name && f.section == file.section)
                return { ...file, _id: defaultConfigFile._id }
            })
            dispatch(setProgressExercise({ block_id: block.id, files: matchFiles }))
            return
        }
        dispatch(setProgressExercise({ block_id: block.id, files: initialFiles }))
    }, [blockProgress?.ProgressExercise])

    const validateLoading = useMemo(() => {
        if (isNull(progressLoading)) {
            return false
        }

        return progressLoading[block.id]
    }, [progressLoading, block.id])

    const availableSections = [...(hasViewSolutionPermission ? [SRC, SOLUTION] : [SRC])]

    const playgroundConfig = useMemo(
        () => ({
            sections: availableSections,
            show: ({
                [JAVASCRIPT]: NODEJS_TERMINAL,
                [JAVA_JDK11]: JAVA_TERMINAL,
            })[exerciseType] || BROWSER,
        }),
        [exerciseType],
    )

    const isReadOnly = useMemo(() => {
        return moment().isAfter(moment(courseFinishDate))
    }, [courseFinishDate])

    const exercise = useMemo(
        () => ({
            name: block.name,
            statement: statement,
            hints: hints,
            moral: moral,
            show_name: block.show_name,
        }),
        [block, statement, hints, moral],
    )

    const solvedValidations = useMemo(() => {
        const {
            Runner: {
                hasError = false,
                output = EMPTY_LIST,
                joined = EMPTY_STRING,
                validations = EMPTY_LIST,
                ...restRunner
            } = EMPTY_OBJECT,
        } = blockProgress.ProgressExercise || EMPTY_OBJECT

        const logs = output.filter(({ type }) => type === LOG).map(l => l.logs)
        const throws = output.filter(({ type }) => type === THROW).map(t => t.msg)

        return {
            joined: htmlentities.decode(joined),
            logs,
            throws,
            hasError,
            validations,
            ...restRunner,
        }
    }, [blockProgress])

    const progressExeciseFiles = useMemo(
        () => progressExercises.find(({ block_id }) => block_id === block.id)?.files || EMPTY_LIST,
        [progressExercises]
    )

    const solutionExerciseFiles =useMemo(
        () => hasViewSolutionPermission ? initialFiles.filter(file => file.section === SOLUTION) : EMPTY_LIST,
        [initialFiles, hasViewSolutionPermission]
    )

    const files = [...progressExeciseFiles, ...solutionExerciseFiles]

    const handleChange = (file, code) => {
        const fileExetension = file.name.split('.')[1]
        dispatch(
            updateProgressExercise({
                _id: file._id,
                block_id: block.id,
                code,
                section: file.section,
                name: file.name,
                language: LANGUAGES[fileExetension],
                divergent: true,
            })
        )
    }

    const handleOnValidate = () => {
        progressReport({
            progress: files.filter(({ section }) => section === SRC),
        })
    }

    const openResetCodeModal = () => {
        dispatch(
            modalActions.open(CONFIRMATION_MODAL, {
                message: playgroundLang.exerciseBlock.ressetCodeMessage,
                blockType: 'exercise',
                buttonConfirmText: playgroundLang.exerciseBlock.reset,
                buttonCancelText: playgroundLang.exerciseBlock.cancel,
                handleConfirm: handleModalConfirm,
            }),
        )
    }

    const handleModalConfirm = () => {
        dispatch(setProgressExercise({ block_id: block.id, files: initialFiles, forceUpdate: true }))
        dispatch(modalActions.close())
    }

    return {
        files,
        playgroundConfig,
        validateLoading,
        playgroundLang,
        isReadOnly,
        progress: blockProgress,
        logs: solvedValidations.logs,
        throws: solvedValidations.throws,
        exercise,
        solvedValidations,
        handleChange,
        handleOnValidate,
        openResetCodeModal,
        handleModalConfirm,
    }
}

export default useExerciseBlock
