import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import ModalClonesConfirmation from '@/components/ModalClonesConfirmation'
import { validateEmptyString } from '@/helpers/strings'
import { BLOCK_SCHEMA_NAMES, BLOCK_NAMES, BLOCK_MODAL_NAMES } from '@/constants/blocks'
import { actions as modalActions } from '@/redux/reducers/modalReducer'
import { actions as blockActions } from '@/redux/reducers/blockReducer'
import { saveNewBlock, updateOneBlock } from '@/redux/thunks/blockThunks'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import { CREATE_BLOCKS, EDIT_BLOCK_VISIBILITY } from '@/constants/coursePermissions'
import BlockIcon from '@/components/BlockIcon'
import ToggleButton from '@digitalhouse-tech/react-lib-ui-explorer/lib/ToggleButton'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import ModalPanel from '@/components/ModalPanel'
import { hasAnyProgressSelector } from '@/redux/selectors'
import { useModalError } from './hooks/useModalError'
import { EMPTY_FIELD } from './constants/errorTypes'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
const loadingSelector = ({ loading: { block } }) => block.createBlock || block.updateBlock
import { showTopicsAndBlocksActions } from '@/helpers/granters/hideMamushkaElements'

export const BlockModal = ({
    validateInputValues,
    transformOnSubmit,
    lang,
    children,
    blockTitle,
    onCancel,
    id,
    blockType,
    values,
    blockId,
    visibility,
    show_name,
    description,
    name,
    extra,
    extraValues,
}) => {
    const dispatch = useDispatch()
    const modal = useSelector((state) => state.modal)
    const course = useSelector(({ courseReducer }) => courseReducer)
    const loading = useSelector(loadingSelector)
    const hasAnyProgress = useSelector(hasAnyProgressSelector)
    const { parent_id } = useSelector(({ courseReducer }) => courseReducer.activeCourse)
    const commonBlockLang = useSelector(({ langStore }) => langStore.commonBlock)

    const { errorMessage, errorCourse, activeCourse, coursesToModify } = course

    const [showConfirmation, setShowConfirmation] = useState(false)
    const [isConfirmed, setIsConfirmed] = useState(false)
    const [nameError, setNameError] = useState(null)

    const [blockName, setBlockName] = useState(name)
    const [blockVisibility, setBlockVisibility] = useState(visibility)
    const [showName, setShowName] = useState(show_name)

    const [setErrorList, errorsToString] = useModalError()
    const showVisibility = useCallback(() => {
        if (blockId) {
            return EDIT_BLOCK_VISIBILITY()
        }
        return CREATE_BLOCKS()
    }, [blockId])

    const clearHasAnyProgress = useCallback(
        () => dispatch(blockActions.setBlockKeyValue('hasAnyProgress', false)),
        [dispatch]
    )
    const validateErrors = useCallback(() => {
        const validateBlockName = validateEmptyString(blockName, EMPTY_FIELD)
        const errors = [validateBlockName, ...validateInputValues(values)]
        setErrorList(errors)
        setNameError(validateBlockName)
        return validateInputValues(values).length || validateBlockName
    }, [values, commonBlockLang.requiredInputMessage, blockName, validateInputValues])

    const showConfirmationModal = () => {
        const hasErrors = validateErrors()
        if (!hasErrors) {
            setShowConfirmation(true)
        }
    }

    const onCancelConfirmation = () => {
        setShowConfirmation(false)
    }

    const cancel = useCallback(() => {
        dispatch(modalActions.close())
        clearHasAnyProgress()
        onCancel()
    }, [dispatch, onCancel, clearHasAnyProgress])

    const submit = useCallback(async () => {
        if (!isConfirmed && coursesToModify.length) return
        const validationError = validateErrors()
        if (validationError) return

        const dataValues = transformOnSubmit ? transformOnSubmit(values) : values
        const data = {
            [blockType.payloadName]: dataValues,
            show_name: showName,
            visibility: blockVisibility,
            name: blockName,
            description,
            extra,
            ...extraValues,
        }
        let err
        if (!blockId) {
            ;[err] = await dispatch(saveNewBlock(data, blockType.schemaName))
        } else {
            ;[err] = await dispatch(updateOneBlock(data, blockType.schemaName))
        }
        if (!err) {
            clearHasAnyProgress()
            dispatch(modalActions.close())
        }
    }, [
        values,
        description,
        showName,
        validateErrors,
        blockName,
        blockType,
        blockVisibility,
        blockId,
        dispatch,
        extra,
        coursesToModify,
        isConfirmed,
        extraValues,
        clearHasAnyProgress,
    ])
    useEffect(() => {
        if (isConfirmed) submit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConfirmed])

    const onSave =
        activeCourse.base && coursesToModify.length && !isConfirmed ? showConfirmationModal : submit

    if (modal.id !== blockType.modal) return null

    const getWarnMessages = () => {
        if(blockId) {
            if(parent_id && hasAnyProgress && !!lang.hasProgress) {
                return `${commonBlockLang.warnBaseChange} ${lang.hasProgress}`
            }
    
            if(hasAnyProgress && !!lang.hasProgress) {
                return lang.hasProgress
            }
            
            if(parent_id && !activeCourse.base) {
                return commonBlockLang.warnBaseChange
            }
        }
    }

    return (
        <>
            <Modal
                id={`${id}-modal`}
                className='__modal'
                bsSize='large'
                show={!showConfirmation}
                onHide={cancel}
                keyboard={false}
                backdrop='static'
            >
                <Modal.Header
                    id={`${id}-modal-header`}
                    className='__modal_header __border_bottom'
                    closeButton
                >
                    <h3 className='__modal_title'>
                        <BlockIcon type={blockType.name} className='block-icon' />
                        {blockTitle}
                    </h3>
                </Modal.Header>

                <Modal.Body id={`${id}-modal-body`} className='__modal_body'>
                    {(!!getWarnMessages()) && (
                            <Row>
                                <Col md="12" lg="12">
                                    <ModalPanel theme='no-theme'>
                                        <MessageBox
                                            className='message-box'
                                            theme='warning'
                                            text={`${getWarnMessages()}`}
                                            fullWidth
                                        />
                                    </ModalPanel>
                                </Col>
                            </Row>
                        )}
                    {(!!errorsToString().length) && (
                            <Row>
                                <Col md="12" lg="12">
                                    <ModalPanel theme='no-theme'>
                                        <MessageBox
                                            className='message-box'
                                            theme='danger'
                                            text={`${errorsToString()}`}
                                            fullWidth
                                        />
                                    </ModalPanel>
                                </Col>
                            </Row>
                        )}
                    {errorCourse && (
                        <MessageBox
                            className='_margin_bottom_20'
                            fullWidth
                            key={`${id}-modal-error`}
                            text={errorMessage}
                            theme='danger'
                        />
                    )}
                    <Row>
                        <Col md="12">
                            <Label theme='classic' text={commonBlockLang.blockNameLabel} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <ModalPanel theme='no-theme'>
                                <div className='modal-panel__input full-width'>
                                    <InputText
                                        id='blockName'
                                        key='blockName'
                                        onChange={(e) => setBlockName(e.target.value)}
                                        value={blockName}
                                        disabled={!showTopicsAndBlocksActions(activeCourse)}
                                        type='text'
                                        error={!!nameError}
                                    />
                                </div>

                                <div className='modal-panel__input'>
                                    <ToggleButton
                                        onToggle={() => setShowName(!showName)}
                                        toggled={showName}
                                        inputName='showName'
                                        key='showName'
                                    />
                                </div>

                                <div className='modal-panel__label'>
                                    <Label indent text={commonBlockLang.showBlockNameLabel} />
                                </div>
                                {showVisibility() && (
                                    <>
                                        <div className='modal-panel__input'>
                                            <ToggleButton
                                                onToggle={() =>
                                                    setBlockVisibility(!blockVisibility)
                                                }
                                                toggled={blockVisibility}
                                                inputName='visibility'
                                                key='visibility'
                                            />
                                        </div>
                                        <div className='modal-panel__label'>
                                            <Label indent text={commonBlockLang.isVisibleLabel} />
                                        </div>
                                    </>
                                )}
                            </ModalPanel>
                        </Col>
                    </Row>

                    {children}
                </Modal.Body>

                <Modal.Footer className='__modal_footer __border_top'>
                    <ButtonGroup>
                        <Button
                            id='BlockModalCancelButton'
                            theme='secondary'
                            onClick={cancel}
                            text={commonBlockLang.cancelButton}
                            disabled={loading}
                        />
                        <Button
                            id='BlockModalSubmitButton'
                            loading={loading}
                            onClick={onSave}
                            disabled={loading}
                            text={commonBlockLang.saveButton}
                            theme='primary'
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>

            <ModalClonesConfirmation
                show={showConfirmation}
                confirmTitle={commonBlockLang.confirmTitle}
                cancelTitle={commonBlockLang.cancelTitle}
                onConfirm={() => setIsConfirmed(true)}
                onCancel={onCancelConfirmation}
                body={coursesToModify}
            />
        </>
    )
}

BlockModal.propTypes = {
    id: PropTypes.string.isRequired,
    lang: PropTypes.any.isRequired,
    blockId: PropTypes.string,
    name: PropTypes.string,
    visibility: PropTypes.bool,
    show_name: PropTypes.bool,
    values: PropTypes.object,
    validateInputValues: PropTypes.func.isRequired,
    blockTitle: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    blockType: PropTypes.shape({
        schemaName: PropTypes.oneOf(BLOCK_SCHEMA_NAMES).isRequired,
        name: PropTypes.oneOf(BLOCK_NAMES).isRequired,
        modal: PropTypes.oneOf(BLOCK_MODAL_NAMES).isRequired,
        payloadName: PropTypes.string.isRequired,
    }).isRequired,
    onCancel: PropTypes.func,
    hasAnyProgress: PropTypes.bool,
    description: PropTypes.string,
    extra: PropTypes.bool,
    extraValues: PropTypes.object,
}

BlockModal.defaultProps = {
    onCancel: () => {},
    name: '',
    visibility: true,
    show_name: true,
    blockId: '',
    hasAnyProgress: false,
    extra: false,
    description: '',
    values: {},
    extraValues: {},
}

export default BlockModal
