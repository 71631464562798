import React from 'react'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import HintModal from './HintModal'
import HintTable from './HintTable'

const ExerciseHints = ({
    lang = {},
    hints = [],
    handlers = {
        setField: () => { }
    }
}) => {
    const hintModalRef = React.createRef()
    const addNewHint = () => hintModalRef.current.showModal()
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="_btn_group pull-right">
                        <Button
                            id="CreateNewHintsButton"
                            theme='tertiary'
                            onClick={addNewHint}
                            text={lang.createNewHints}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <HintTable
                        hints={hints}
                        lang={lang.hintsTable}
                        actions={{
                            onEdit: key => {
                                hintModalRef.current.showModal(hints[key], key)
                            },
                            onDelete: key => {
                                const allHints = [...hints]
                                allHints.splice(key, 1)
                                handlers.setField('hints', allHints)
                            }
                        }}
                    />
                </div>
            </div>
            <HintModal
                lang={lang.hintModal}
                ref={hintModalRef}
                onSave={hints => handlers.setField('hints', hints)}
                hints={hints}
            />
        </>
    )
}

export default ExerciseHints