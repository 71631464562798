'use-strict'

export default {
    SET_PAGINATED_ROLES: 'SET_PAGINATED_ROLES',
    SET_PAGINATED_PERMISSIONS: 'SET_PAGINATED_PERMISSIONS',
    SET_IS_LOADING: 'SET_IS_LOADING',
    CLEAR_ACTIVE_ROLE: 'CLEAR_ACTIVE_ROLE',
    CLEAR_ALL_ROLES: 'CLEAR_ALL_ROLES',
    SET_ROLE_PERMISSIONS: 'SET_ROLE_PERMISSIONS',
    SET_ACTIVE_ROLE: 'SET_ACTIVE_ROLE',
    CLEAR_ROLE_PERMISSIONS: 'CLEAR_ROLE_PERMISSIONS',
    SET_ROLE_CURRENT_PAGE: 'SET_ROLE_CURRENT_PAGE',
    CLEAR_PAGINATED_ROLES: 'CLEAR_PAGINATED_ROLES',
    SET_ACTIVE_ROLE: 'SET_ACTIVE_ROLE',
}