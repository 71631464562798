import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import StudentList from './StudentList'
import useHistory from '@/hooks/useHistory'
import * as managerThunks from '@/redux/thunks/managerThunks'
import * as enrolmentThunks from '@/redux/thunks/enrolmentThunks'
import * as enrolmentService from '@/redux/http/services/enrolmentService'
import { showModalPasswordAction } from '@/redux/reducers/_deprecated/modalsReducer'
import { showModalSearchAction } from '@/redux/reducers/_deprecated/userReducer'
import { loadCoursePermissions } from '@/redux/reducers/_deprecated/authReducer'
import {
    STUDENT_ENROLMENT_SEARCH,
    DOWNLOAD_STUDENT_LAST_LOGIN,
    CHANGE_PASSWORD_STUDENT,
    DELETE_STUDENT,
    STUDENT_ENROLMENT_CSV
} from '../../../../constants/coursePermissions'
import { enableStudents } from '@/helpers/granters/courseTypeRules'
import { tenantHasNativeAuthStrategy } from '@/helpers/tenants/helpers'

const StudentListContainer = ({
    match,
    lang,
    users,
    courses,
    loading,
    managerDataLoader,
    showModalPassword,
    deleteStudentCourse,
    showModalAssign,
    activePage
}) => {
    const history = useHistory()
    const {
        location: { search },
    } = history
    const courseName = new URLSearchParams(search).get('course')
    const courseId = match.params.courseId

    
    useEffect(() => {
        if (!courseName) history.push('/admin/courses')
        loadPermissions(courseId)
    }, [])

    const showToolbar = () => {
        const course = courses.rows.find(
            course => course.id == courseId
        )
        return enableStudents(course.CourseType)
    }

    const deleteStudent = useCallback(
        async (userId) => {
            await deleteStudentCourse(userId, courseId)
            await managerDataLoader()
        }, [courseId, deleteStudentCourse]
    )

    return (
        <StudentList
            lang={lang}
            courseName={courseName}
            users={users.rows}
            totalUsers={users.totalRows}
            enableAssignStudentsFromSearch={STUDENT_ENROLMENT_SEARCH()}
            enableAssignStudentsFromCSV={STUDENT_ENROLMENT_CSV()}
            enableDownloadLastLoginReport={DOWNLOAD_STUDENT_LAST_LOGIN()}
            enableChangePassword={CHANGE_PASSWORD_STUDENT() && tenantHasNativeAuthStrategy()}
            enableDeleteStudents={DELETE_STUDENT()}
            showToolbar={showToolbar()}
            loadingTable={loading.loadStudents}
            managerDataLoader={managerDataLoader}
            searcherFields={{
                search: {
                    ['!name']: '',
                    ['!surname']: '',
                    ['!email']: '',
                },
                order: 'surname:asc',
                course_id: courseId,
                active: activePage
            }}
            downloadLoginReport={downloadLoginReport}
            showModalPassword={showModalPassword}
            showModalAssign={showModalAssign}
            deleteStudentCourse={deleteStudent}
        />
    )
}

StudentListContainer.propTypes = {
    lang: PropTypes.shape().isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            courseId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    users: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            surname: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    courses: PropTypes.shape({
        rows: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
    loading: PropTypes.shape({
        loadStudents: PropTypes.bool.isRequired,
    }).isRequired,
    managerDataLoader: PropTypes.func.isRequired,
    showModalPassword: PropTypes.func.isRequired,
    deleteStudentCourse: PropTypes.func.isRequired,
    showModalAssign: PropTypes.func.isRequired,
    activePage: PropTypes.number.isRequired,
}

const managerDataLoaderThunk = managerThunks.managerDataLoader({
    loadingName: 'enrolment.loadStudents',
    serviceHandler: enrolmentService.loadStudents,
})

const downloadLoginReport = (courseId, courseName) => {
    enrolmentService.loginReport(courseId, courseName)
}

const loadPermissions = courseId => {
    loadCoursePermissions(courseId)
}

const mapDispatch = {
    managerDataLoader: managerDataLoaderThunk,
    showModalPassword : showModalPasswordAction,
    deleteStudentCourse : enrolmentThunks.removeStudentFromCourse,
    showModalAssign: showModalSearchAction,
    downloadLoginReport,
    loadPermissions
}

const mapState = state => ({
    lang: {
        ...state.langStore.studentList,
        ...state.langStore.studentTable,
        ...state.langStore.generalSearcher,
    },
    users: state.manager.pagination,
    courses: state.courseReducer.allCourses,
    loading: state.loading.enrolment,
    activePage: state.manager.pagination.currentPage
})

export default connect(mapState, mapDispatch)(StudentListContainer)