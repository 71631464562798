import { setGradesListPublishModal } from '@/redux/reducers/dashboardReducer/index'
import { publishGradebook } from '@/redux/thunks/dashboardThunks'
import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const usePublishModal = (courseId) => {
    const dispatch = useDispatch()
    const lang = useSelector(({ langStore }) => langStore.notebook)
    const {
        gradesList: gradesListState,
        gradesListPublishModal: { show },
    } = useSelector(({ dashboardReducer }) => dashboardReducer)
    const { assessmentsAllowToPublish } = gradesListState

    const loading = useSelector(({ loading }) => loading.dashboard.publishGradebook)

    const closeModal = () => dispatch(setGradesListPublishModal(false))

    const handlePublishGrades = useCallback(() => {
        dispatch(publishGradebook(courseId))
    }, [])

    return {
        lang,
        assessmentsAllowToPublish,
        showModal: show,
        closeModal,
        handlePublishGrades,
        loading,
    }
}

export default usePublishModal
