import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { downloadQuizReportData } from '@/redux/thunks/dashboardThunks'

import disabledSectionMassiveCourse from '../../shared/utils/disabledSectionMassiveCourse'


const useQuizReport = (courseId, selectedBlock, is_massive) => {
    const dispatch = useDispatch()
    const downloadIsLoading = useSelector(({ loading }) => loading.dashboard.quizDownloadReport)

    useEffect(() => {
        if (is_massive) disabledSectionMassiveCourse(is_massive)
    }, [is_massive])

    const downloadQuizReport = async () => {
        const response = await dispatch(downloadQuizReportData(courseId, selectedBlock?.id))
        return response
    }

    return {
        downloadQuizReport,
        downloadIsLoading,
    }
}

export default useQuizReport
