'use-strict'

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { history } from '@/app/store'
import ExerciseManagerForm from './ExerciseManagerForm'
import controller from '@/redux/reducers/_deprecated/exerciseManagerReducer/controller'
import resolveLangs from '../List/ExerciseCloneModal/helpers/resolveLangs'
import exerciseTypes from '@/helpers/exercises/exerciseTypes'

const ExerciseManagerFormContainer = ({
    match = {},
    location = {},
    lang = {},
    allExerciseTypes = [],
    allTags = [],
    workingExercise = {},
    solvedValidations = {},
    loading = {},
    hasProgress = false,
}) => {
    React.useEffect(() => {
        controller.loadAllTags()
        controller.loadAllExerciseTypes()
        const isCreate = /create/.test(match.url)
        if (isCreate) {
            const typeName = checkTypeName()
            controller.setWorkingExercise('exerciseType', typeName)
            controller.setFilesInWorkingExercise(typeName, [])
        } else {
            controller.loadSingleExercise(match.params.id)
            controller.exerciseHasProgress(match.params.id)
        }
        return () => {
            controller.clearWorkingExercise()
            controller.clearSolvedValidations()
            controller.clearIfExerciseHasProgress()
        }
    }, [])

    const backToList = () => history.push('/admin/exercises')

    const checkTypeName = () => {
        const match = location.search.match(/exerciseType\=.*/gm)
        if (!match) backToList()
        const [, typeName] = match[0].split('=')
        return typeName
    }

    const playgroundConfig = () => {
        const type = checkTypeName()
        const configs = {
            [exerciseTypes.HTML_CSS]: {
                sections: ['SRC', 'HIDDENCODE', 'SOLUTION'],
                show: 'Browser',
            },
            [exerciseTypes.JAVASCRIPT]: {
                sections: [
                    'SRC',
                    'HIDDENCODE',
                    'MOCKS',
                    'TESTS',
                    'SOLUTION',
                ],
                show: 'NodejsTerminal',
            },
            [exerciseTypes.DOM]: {
                sections: [
                    'SRC',
                    'HIDDENCODE',
                    'MOCKS',
                    'TESTS',
                    'SOLUTION',
                ],
                show: 'Browser',
            },
            [exerciseTypes.JAVA_JDK11]: {
                sections: [
                    'SRC',
                    'HIDDENCODE',
                    'TESTS',
                    'SOLUTION',
                ],
                show: 'JavaTerminal',
            },
            [exerciseTypes.BLOCKLY]: {
                sections: [],
                show: ''
            }
        }
        return configs[type]
    }

    const availableFiles = () => {
        const sourceFiles = workingExercise.files.filter((file) => file.section === 'SRC')
        const htmlFiles = sourceFiles.filter((file) => {
            const [, ext] = file.name.split('.')
            return ext === 'html'
        })
        return htmlFiles.map((file) => file.name)
    }

    const getComponent = () => {
        const isCreate = /create/.test(match.url)
        const saveHandler = isCreate ? controller.createExercise : controller.updateExercise
        const resolvedLang = resolveLangs.ExerciseManagerFormContainer(lang)
        const exerciseTypeName = checkTypeName()
        const exerciseType = allExerciseTypes.find((type) => type.name === exerciseTypeName)
        if (!exerciseType) return null
        return (
            <ExerciseManagerForm
                title={isCreate ? resolvedLang.createExercise : resolvedLang.editExercise}
                exerciseType={exerciseTypeName}
                lang={resolvedLang}
                allRuleTypes={exerciseType.ruleTypes}
                data={workingExercise}
                allTags={allTags}
                solvedValidations={solvedValidations}
                loading={loading}
                hasProgress={hasProgress}
                handlers={{
                    setField: controller.setWorkingExercise,
                    preview: controller.executePreview,
                    save: saveHandler,
                }}
                playground={playgroundConfig()}
                availableFiles={availableFiles()}
            />
        )
    }

    return <>{getComponent()}</>
}

const mapStateToProps = (state) => ({
    lang: state.langStore,
    allExerciseTypes: state.exerciseManagerStore.allExerciseTypes,
    workingExercise: state.exerciseManagerStore.workingExercise,
    solvedValidations: state.exerciseManagerStore.solvedValidations,
    loading: state.exerciseManagerStore.loading,
    allTags: state.exerciseManagerStore.allTags,
    hasProgress: state.exerciseManagerStore.hasProgress,
})

export default withRouter(connect(mapStateToProps, null)(ExerciseManagerFormContainer))
