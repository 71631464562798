import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import './RoadmapCarousel.scss'
import cn from 'classnames'
import { slider } from './RoadmapCarouselSetup'
import CustomArrow from '@/components/CustomArrow'
import RoadItem from './RoadItem'
import RoadSeparator from './RoadSeparator'
import { 
    TOP, 
    BOTTOM, 
    COMPLETED,
    MIN_INDEX_TO_SCROLL,
    INDEX_NOT_FOUND,
} from './roadMapConstants'
import { breakpointsTable } from './RoadmapBreakpoints'

const RoadmapCarousel = ({
    isMobile,
    userProgress,
    lastOnSiteLesson,
    getMonthAndDay,
    lang,
    finishDate,
    units,
}) => {

    if(units.length === 0) return null

    const sliderRef = useRef(null)
    const [breakpoint, setBreakpoint] = useState(null)
    const { units:unitsProgress, firstTime } = userProgress

    useEffect(() => {
        setBreakpoint(sliderRef.current.state.breakpoint)
        calculateSlidesToScroll()

    }, [sliderRef, breakpoint, calculateSlidesToScroll, lastOnSiteLesson])

    const calculateSlidesToScroll = useCallback(() => {
        const { index } = getLastCompletedLesson
        if(breakpoint && index >= MIN_INDEX_TO_SCROLL){
            const { initialScroll, toCenter } = breakpointsTable.find( 
                ({width}) => width === breakpoint)
            const scrollRange = index + 1 - initialScroll + toCenter
            scrollRange > 0 && sliderRef.current.slickGoTo(scrollRange)
        }
    }, [breakpoint, getLastCompletedLesson])
        
    const getLastCompletedLesson = useMemo(() => {
        const reverseUnits = unitsProgress.slice().reverse()
        const index = reverseUnits.findIndex( 
            ({ completedLessons }) => completedLessons > 0)
        
        const reversedIndex = unitsProgress.length-index-1
        
        return { 
            unit: unitsProgress[reversedIndex], 
            index: index !== INDEX_NOT_FOUND ? reversedIndex : INDEX_NOT_FOUND
        }
    }, [unitsProgress])

    const setup = {
        ...slider,
        prevArrow: <CustomArrow right={false} />,
        nextArrow: <CustomArrow right />,    
    }

    const didCourseFinish = () => {
        const lastLesson = moment(finishDate, "YYYY-MM-DD[T]HH:mm:ss")
        return moment().isAfter(lastLesson)
    }

    const didStudentFinish = () => {
        return !unitsProgress.find( unit => unit.percent < COMPLETED)
    }

    const processPopovers = (currentUnitId) => {
        const lastOnSiteUnit = lastOnSiteLesson && lastOnSiteLesson.Unit
        const lastCompletedLesson = getLastCompletedLesson.unit
        
        const processTop = !firstTime && lastCompletedLesson 
        && lastCompletedLesson.id === currentUnitId && TOP

        const processBottom = !firstTime && !didCourseFinish() && lastOnSiteUnit
        && currentUnitId === lastOnSiteUnit.id && BOTTOM

        return [processTop, processBottom]
    }

    const getEmptyProgressMock = () => ({
        completedLessons: 0,
        lessonsQty: 0,
        percent: 0,
    })

    const getUnitProgress = (unitId) => {
        return unitsProgress.find( ( {id} ) => 
            id === unitId) || getEmptyProgressMock()
    }

    const activateFirstUnitPadding = () => {
        const firstUnit = processPopovers(units[0].id)
        return  firstUnit[0] || firstUnit[1]
    }

    const roadmapSlider = cn({
        'paddingLeft': activateFirstUnitPadding(),
    }, 'roadmapSlider')

    const getCircularProgress = (unit) => {
        const { id } = unit
        const { completedLessons, lessonsQty } = getUnitProgress(id)
        return !lessonsQty ? 0 : 100 * completedLessons / getVisibleLessons(unit)
    }

    const getVisibleLessons = (unit) => {
        return unit.Lessons.filter( ({ visibility }) => visibility).length
    }

    return (
        <>
            <Slider {...setup} ref={sliderRef} className={roadmapSlider}>
                { units.map( (unit,index) => {
                    const { id } = unit
                    const keys = [`iu${id}`,`su${id}`,`du${id}`]
                    const separator = getCircularProgress(unit) === COMPLETED
                    return (
                        <div className="roadBox" key={keys[2]}>
                            <RoadItem 
                                moduleNumber={index+1} 
                                lessonsLength={getVisibleLessons(unit)} 
                                percent={getCircularProgress(unit)}
                                popovers={processPopovers(id, index)}
                                isMobile={isMobile}
                                lang={lang}
                                key={keys[0]}
                                didCourseFinish={didCourseFinish}
                                didStudentFinish={didStudentFinish}
                            />
                            {index !== units.length-1 && 
                                <RoadSeparator 
                                    incomplete={!separator}
                                    isMobile={isMobile} 
                                    key={keys[1]}
                                /> }
                        </div>
                    )
                })}
            </Slider>
            {!firstTime &&
            <div className='references'>
                <div className="reference">
                    <div className="circle active" />
                    <p className="referenceText">{lang.completedLessons}</p>
                </div>
                {!didCourseFinish() && lastOnSiteLesson && (
                    <div className="reference">
                        <div className="circle " />
                        <p className="referenceText">
                            {`${lang.lastLiveLesson} 
                        ${getMonthAndDay(lastOnSiteLesson.release_date)}`}
                        </p>
                    </div>)}
            </div>}
        </>
    )
}

RoadmapCarousel.defaultProps = {
    finishDate: null,
    lastOnSiteLesson: null,
}

RoadmapCarousel.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    lang: PropTypes.any.isRequired,
    units: PropTypes.array.isRequired,
    userProgress: PropTypes.shape({
        unit_id: PropTypes.string,
        units: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                length: PropTypes.number,
                map: PropTypes.func,
                find: PropTypes.func,
            }),
        ).isRequired,
        lessons: PropTypes.arrayOf(
            PropTypes.shape({
                length: PropTypes.number,
                map: PropTypes.func,
            }),
        ).isRequired,
        firstTime: PropTypes.bool, 
    }).isRequired, 
    lastOnSiteLesson: PropTypes.shape({
        id: PropTypes.string,
        release_date: PropTypes.string,
        Unit: PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.number, PropTypes.string
            ]),
        }),
    }),
    getMonthAndDay: PropTypes.func.isRequired,
    visibleUnits: PropTypes.arrayOf(
        PropTypes.number,
    ).isRequired,
    finishDate: PropTypes.string,
}

export default RoadmapCarousel
