import { useSelector } from "react-redux"

export const usePaymentMethods = () => {
    const paymentMethods = useSelector(({ authReducer }) => authReducer.user.paymentMethods)
    const getCardBrand = (type) => {
        switch(type) {
            case 'VD':
            case 'VI': return 'Visa'
            case 'MD':
            case 'MC': return 'MasterCard'
            case 'NJ': return 'Tarjeta Naranja' 
            case 'AE': return 'American Express'
            case 'CM': return 'Tarjeta CMR'
            case 'NT': return 'Nativa'
            case 'MS': return 'Maestro'
            case 'DC': return 'Diners'
            case 'DI': return 'Discover'
            case 'CZ': return 'Codenza'
            case 'CL': return 'Cabal'
            default: return ''
        }
    }
    const getCardType = (type) => {
        switch(type) {
            case 'VI':
            case 'MC':
            case 'NJ':
            case 'AE':
            case 'DC':
            case 'DI':
            case 'CZ':
            case 'CL':
            case 'AE': return {en: 'credit', es: 'crédito'}
            case 'VD':
            case 'CM': 
            case 'NT':
            case 'MD':
            case 'MS': return {en: 'debit', es: 'débito'}
            default: return ''
        }
    }

    const getLogoUrl = (brand) => {
        switch(brand) {
            case 'VI':
                return 'https://static.dlocal.com/images/providers/Visa_logo.png'
            case 'MC':
                return 'https://pay.dlocal.com/views/2.0/images/payments/MC.png'
            case 'MS':
                return 'https://pay.dlocal.com/views/2.0/images/payments/MS.png'
            case 'MD':
                return 'https://pay.dlocal.com/views/2.0/images/payments/MD.png'    
            case 'AE':
                return 'https://pay.dlocal.com/views/2.0/images/payments/AE.png'
            case 'NJ':
                return 'https://pay.dlocal.com/views/2.0/images/payments/NJ.png'
            case 'NT':
                return 'https://pay.dlocal.com/views/2.0/images/payments/NT.png'
            case 'VD':
                return 'https://pay.dlocal.com/views/2.0/images/payments/VD.png'
            case 'CM': 
                return 'https://pay.dlocal.com/views/2.0/images/payments/CM.png'
            case 'CL':
                return 'https://pay.dlocal.com/views/2.0/images/payments/CL.png'
            case 'AG':
                return 'https://pay.dlocal.com/views/2.0/images/payments/AG.png'
            case 'LD':
                return 'https://pay.dlocal.com/views/2.0/images/payments/LD.png'
            case 'MP':
                return 'https://pay.dlocal.com/views/2.0/images/payments/MP.png'
            case 'DC':
                return 'https://pay.dlocal.com/views/2.0/images/payments/DC.png'
            case 'CZ':
                return 'https://pay.dlocal.com/views/2.0/images/payments/CZ.png'
            default:
                return ''
        }
    }

    return paymentMethods ? [
        ...paymentMethods.map(pm => ({
            id: pm.id,
            expirationDate: `${pm.expiration_month}/${pm.expiration_year}`,
            logoUrl: getLogoUrl(pm.brand),
            last4: pm?.last4 || '1234',
            brand: getCardBrand(pm.brand),
            type: getCardType(pm.brand),
            clusterName: pm.clusterName ?? '',
            method: pm.method,
            country: pm.country ?? 'AR',
            productId: pm.productId
        }))
    ] : []
}