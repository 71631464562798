const STUDENT_ROLE = 'STUDENT_ROLE'

export const filterVisibleTopicsByUnits = (units) => {
    const unitsWithFilteredTopics = units.map(u => 
        ({...u, 
            Lessons: u.Lessons.map(l => 
                ({...l, Topics: l.Topics.filter(t => 
                    !t.TopicRoles?.length)})
            )})
            )
    return unitsWithFilteredTopics
}

export const filterVisibleTopicsByLessons = (lessons) => {
    const lessonsWithVisibleTopics = lessons?.map(l => 
                ({...l, Topics: l.Topics.filter(t => 
                    !t.TopicRoles?.length)})
            )
    return lessonsWithVisibleTopics
}

export const hasOnlyStudentRole = (payload) => payload?.userRoles?.length === 1 && payload?.userRoles[0].name === STUDENT_ROLE
