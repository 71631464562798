import React from 'react'
import PropTypes from 'prop-types'

import './NoConnectionEmptyState.scss'
import { emptyStateAsset } from '@/assets/img/config'

const NoConnectionEmptyState = ({ blockName, lang, showName }) => {
    return (
        <>
            {showName && (
                <div className='no-connection__block-title pg-block-title'>
                    <h1>{blockName}</h1>
                </div>
            )}
            <div className='no-connection-empty-state'>
                <p className='no-connection-empty-state__title'>{lang.title}</p>
                <img
                    className='no-connection-empty-state__img'
                    src={emptyStateAsset('connection-failure.svg')}
                    alt='Connection Failure'
                />
                <p className='no-connection-empty-state__subtitle'>{lang.firstSubtitle}</p>
                <p className='no-connection-empty-state__subtitle'>{lang.secondSubtitle}</p>
            </div>
        </>
    )
}

NoConnectionEmptyState.propTypes = {
    blockName: PropTypes.string.isRequired,
    lang: PropTypes.object.isRequired,
    showName: PropTypes.bool,
}

NoConnectionEmptyState.defaultProps = {
    showName: true,
}

export default NoConnectionEmptyState
