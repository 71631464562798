import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { X } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import { emptyStateAsset } from '@/assets/img/config'
import './OwnerAssign.scss'

const OwnerAssignedUsersTable = ({
    getRoles,
    removePreAssignUser,
    selectRole,
    usersToAssign,
    isCompleteDataToAssign,
    assignUsers,
    lang,
}) => {
    return (
        <>
            <div className="assign_card">
                <div className="assign_step">{lang.secondStep}</div>
                <p className="assign_card_title">{lang.roleAssign}</p>
                <p className="assign_card_subtitle">{lang.roleAssignSubtitle}</p>

                <div
                    className={cn(
                        {
                            _no_show_element: usersToAssign.length === 0,
                        },
                        'assign_users',
                    )}
                >
                    <div className="assigned_users_table">
                        <table responsive className="assign_users_list table-responsive pdg-table">
                            <tbody>
                                {usersToAssign.map(user => {
                                    return (
                                        <tr key={`row_assign_${user.id}`}>
                                            <td>{user.name}</td>
                                            <td>{user.surname}</td>
                                            <td>{user.email}</td>
                                            <td className="select_role_td">
                                                <div className='search_by_role'>
                                                    <InputComboBox
                                                        inputName="searchByRole"
                                                        placeholder="Seleccionar rol"
                                                        id={`searchByRole_${user.id}`}
                                                        dropdownItems={getRoles().map(({ label }) => label)}
                                                        onChange={(selectedOption, index) => {
                                                            const selected = getRoles().find((rol, i) => rol.label === selectedOption && index === i)
                                                            selectRole(selected, user.id)
                                                        }}
                                                        value={user?.role?.description}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <Button
                                                    id={`remove_user_${user.id}`}
                                                    className="remove_preAssign_user"
                                                    theme='clear'
                                                    text=""
                                                    onClick={() => removePreAssignUser(user.id)}
                                                    icon={<X />}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    className={cn(
                        {
                            _no_show_element: usersToAssign.length > 0,
                        },
                        'empty_assign',
                    )}
                >
                    <p className="empty_assign__text">{lang.notSelectedUsers}</p>
                    <img
                        className="empty_assign__image"
                        src={emptyStateAsset('default-no-results.svg')}
                        alt={lang.notSelectedUsers}
                    />
                </div>
            </div>
            <div className="_btn_group pull-right">
                <Button
                    id="assign_button"
                    text={lang.ownerAssign}
                    disabled={!isCompleteDataToAssign()}
                    onClick={() => assignUsers()}
                />
            </div>
        </>
    )
}

OwnerAssignedUsersTable.propTypes = {
    getRoles: PropTypes.func.isRequired,
    removePreAssignUser: PropTypes.func.isRequired,
    selectRole: PropTypes.func.isRequired,
    usersToAssign: PropTypes.array.isRequired,
    isCompleteDataToAssign: PropTypes.func.isRequired,
    assignUsers: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
}

export default OwnerAssignedUsersTable
