import { useCallback } from 'react'

let isDown = false
let scrolledLeft
let startX

export const useDraggable = (slider, limitsPerPage, updateIndicators) => {
    const scrollTo = useCallback((move) => {
        slider.scrollTo({
            top: 0,
            left: move,
            behavior: 'smooth',
        })
    })

    const handleInitialDraggingState = useCallback((e) => {
        e.stopPropagation()
        isDown = false
        
        slider.classList.remove('dragging-slider')
        slider.childNodes.forEach((node) => node.classList.remove('dragging-slider'))
        
        const { scrollLeft } = slider
        
        const fitChild = limitsPerPage.reduce((prev, curr) => {
            return Math.abs(curr - scrollLeft) < Math.abs(prev - scrollLeft) ? curr : prev
        })
        
        scrollTo(fitChild)
        updateIndicators(fitChild)
    })

    const handleMouseDown = useCallback((e) => {
        isDown = true

        slider.classList.add('dragging-slider')
        slider.childNodes.forEach((node) => node.classList.add('dragging-slider'))
        startX = e.pageX - slider.offsetLeft
        scrolledLeft = slider.scrollLeft
    })

    const handleMouseMove = useCallback((e) => {
        if (!isDown) return

        const x = e.pageX - slider.offsetLeft
        const walk = x - startX
        slider.scrollLeft = scrolledLeft - walk
        updateIndicators(slider.scrollLeft)
    })

    return {
        handleMouseMove,
        handleMouseDown,
        handleInitialDraggingState,
    }
}
