import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CourseInfo from './CourseInfo'
import { loadCourseInfo } from '@/redux/thunks/courseInfoThunks'
import { 
    courseDataSelector, 
    courseToolsSelector, 
    courseUnitsSelector, 
    courseTeachersSelector,
    courseInfoLangSelector, 
} from '../../../redux/selectors/courseInfo'
import { getIsMobile } from '@/redux/selectors/auth'
import { setLocation } from '@/routes/guards'
import { CLASSROOM_LOCATION } from '@/constants/locations'

const CourseInfoContainer = ({match}) => {
    const isMobile = useSelector(getIsMobile)
    const {
        name, 
        description,
        initial_date, 
        finish_date, 
        alias,
    } = useSelector(courseDataSelector)
    const units = useSelector(courseUnitsSelector)
    const tools = useSelector(courseToolsSelector)
    const teachers = useSelector(courseTeachersSelector)
    const lang = useSelector(courseInfoLangSelector)
    const loading = useSelector(({ loading }) => loading.courseInfo.loadCourseInfo)
    const { courseId } = match.params
    
    const dispatch = useDispatch()

    useEffect( () => {
        setLocation(CLASSROOM_LOCATION)
        dispatch(loadCourseInfo(courseId))
    },[dispatch, courseId])

    const getUnitNames = () => {
        return units.map(unit=>unit.name)
    }

    return (
        <>
            <CourseInfo
                units={getUnitNames()}
                initial_date={initial_date}
                finish_date={finish_date}
                alias={alias}
                teachers={teachers}
                description={description} 
                name={name}
                isMobile={isMobile}
                lang={lang}
                tools={tools}
                loading={loading}
            />
        </>
    )
}

CourseInfoContainer.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            lang: PropTypes.string,
            courseId: PropTypes.string,
        }),
    }).isRequired,
}
export default CourseInfoContainer
