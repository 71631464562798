/**
 * This module builds a query string needed for the search 
 * function used in backend to be able to solve complex queries to mysql or mongodb
 * @param {*} query 
 */
const URLQueryString = query => {
    let str = '?'

    const resolvers = {
        string: key => (str += `&${key}=${query[key]}`),
        number: key => (str += `&${key}=${query[key]}`),
        object: key => {
            const entries = Object.entries(query[key])
            entries.forEach(([target, value]) => {
                if (!value) return
                str += `&${target}=${value}`
            })
        },
    }

    for (const i in query) {
        const type = Array.isArray(query[i]) ? 'array' : typeof query[i]
        resolvers[type](i)
    }

    return str
}

export default URLQueryString
