import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './EmptyState.scss'

const EmptyState = ({ className, hidden, id, imgUrl, text }) => {
    if (hidden) return null

    return (
        <div className='empty-state-wrapper' id={id}>
            <div className={cn('empty-state-wrapper__content', className)}>
                <p className='empty-state-wrapper__content-text'>{text}</p>
                <img
                    src={imgUrl}
                    alt='Report empty state'
                    className='empty-state-wrapper__content-default-img'
                />
            </div>
        </div>
    )
}

EmptyState.propTypes = {
    className: PropTypes.string,
    hidden: PropTypes.bool,
    id: PropTypes.string.isRequired,
    imgUrl: PropTypes.string,
    text: PropTypes.string,
}

EmptyState.defaultProps = {
    className: '',
    hidden: false,
    imgUrl: '',
    text: '',
}

export default EmptyState
