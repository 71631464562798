import React from 'react';
import { connect } from 'react-redux'
import ConfirmMessageMobile from '@/components/ConfirmMessageMobile/ConfirmMessageMobile'

import { AlertCircle } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'

import './LogoutContainer.scss'

import { history } from '@/app/store'
import { logoutUser } from '@/redux/reducers/_deprecated/authReducer'
import { useEffect } from 'react';

// todo: Este container deberia estar dentro de login module
const LogOutContainer = ({
    lang: {
        goBack: goBackLang,
        message: messageLang,
        confirm: confirmLang,
        cancel: cancelLang
    },
    logoutUser
}) => {
    const onGoBackHandler = () => history.goBack()
    const onFisrtHandler = () => logoutUser()
    const onSecondHandler = () => history.goBack()

    useEffect(() => {
        const isForced = new URLSearchParams(window?.location?.search).has("forced");
        if (isForced) logoutUser();
    }, [])

    return (
        <div className="viewContainer logoutContainer">
            <ConfirmMessageMobile
                onGoBack={onGoBackHandler}
                onFisrt={onFisrtHandler}
                onSecond={onSecondHandler}
                onThird={() => { }}
                lang={{
                    goBack: goBackLang,
                    title: '',
                    message: messageLang,
                    firstOption: confirmLang,
                    secondOption: cancelLang,
                    thirdOption: ''
                }}
                customIcon={<AlertCircle className="logoutContainer icon" />}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    lang: state.langStore.logOutConfirm,
})

const mapDispatch = (dispatch) => ({
    logoutUser: () => dispatch(logoutUser()),
})

export default connect(mapStateToProps, mapDispatch)(LogOutContainer)