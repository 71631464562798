export const monthConfig = (lang) => {
    const months = {
        'es-AR': [
            'es',
            {
                months: [
                    'ENE',
                    'FEB',
                    'MAR',
                    'ABR',
                    'MAYO',
                    'JUN',
                    'JUL',
                    'AGO',
                    'SEPT',
                    'OCT',
                    'NOV',
                    'DIC',
                ],
            },
        ],
        'pt-BR': [
            'pt',
            {
                months: [
                    'JAN',
                    'FEV',
                    'MAR',
                    'ABR',
                    'MAIO',
                    'JUN',
                    'JUL',
                    'AGO',
                    'SET',
                    'OUT',
                    'NOV',
                    'DEZ',
                ],
            },
        ],
    }
    return months[lang] ? months[lang] : []
}

export const hourFormatLang = {
    ['es-AR']: 'a las',
    ['pt-BR']: 'as',
}
