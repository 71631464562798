import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { page } from '@digitalhouse-tech/react-sdk-analytics'
import { clearClassroomProgress, clearContentMap } from '@/redux/reducers/student/classroom'
import { getContentMap, getTopicContent } from '@/redux/thunks/student/classroom'
import { getProgressByCourse } from '@/redux/thunks/student/progress'
import { isMobileSelector } from '@/redux/selectors/auth'
import { CLASSROOM_LOCATION } from '@/constants/locations'
import { setLocation } from '@/routes/guards'
import { courseContentPage } from '@/constants/pageViewEvents'
import { isCurrentPlatformVersion } from '@/helpers/users/helpers'


export default () => {
    const dispatch = useDispatch()
    const { lessonId, topicId, unitId, courseId } = useParams()

    const [isClassroomLoaded, setIsClassroomLoaded] = useState(false)
    const [isProgressLoaded, setIsProgressLoaded] = useState(false)

    const isMobile = useSelector(isMobileSelector)

    const {
        content_map: {
            Course,
            Lessons,
            name: unitName,
            realOrder: moduleNumber,
            prevUnit,
            nextUnit,
            loading: contentMapLoading,
            User,
        } = {},
        topic_content: { loading: topicContentLoading, ...topic_content } = {},
        toggleMobileMenu,
    } = useSelector(({ student: { classroom } = {} }) => classroom)

    useEffect(() => {
        if (courseId) {
            dispatch(getProgressByCourse({ courseId })).then(() => {
                setIsProgressLoaded(true)
            })
        }
    }, [dispatch, courseId])

    useEffect(() => {
        setLocation(CLASSROOM_LOCATION)
        if(isCurrentPlatformVersion()) page(courseContentPage, {
            platformVersion: '1.x',
        })
    }, [])

    useEffect(() => {
        if (isProgressLoaded) {
            dispatch(getContentMap({ courseId, unitId })).then(async () => {
                setIsClassroomLoaded(true)
            })
        }
    }, [dispatch, courseId, unitId, isProgressLoaded])

    useEffect(() => {
        dispatch(getTopicContent({ courseId, unitId, lessonId, topicId }))
    }, [dispatch, courseId, unitId, lessonId, topicId])

    useEffect(() => {
        return () => {
            dispatch(clearClassroomProgress())
            dispatch(clearContentMap())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showContenMap = useMemo(() => toggleMobileMenu || !isMobile, [toggleMobileMenu, isMobile])
    const showTopicContent = useMemo(() => !toggleMobileMenu || !isMobile, [
        toggleMobileMenu,
        isMobile,
    ])

    return {
        Course,
        Lessons,
        contentMapLoading: !isClassroomLoaded || contentMapLoading,
        courseId,
        isMobile,
        moduleNumber,
        nextUnit,
        prevUnit,
        showContenMap,
        showTopicContent,
        topicContentLoading: !isClassroomLoaded || topicContentLoading,
        topicId,
        topic_content,
        unitName,
        isClassroomLoaded,
        isOwner: User?.isOwner,
    }
}
