import React from 'react'
import useQuizMf from '../useQuizMf'

export const QuizCreate = () => {
    const MF_ID = 'mfBoQuiz'
    const MF_NAME = 'create'
    const SELECTOR_ID = 'mfBoQuizCreate'

    useQuizMf(MF_ID, SELECTOR_ID, MF_NAME)

    return <div id={SELECTOR_ID} className='quiz-app-general-container' />
}

export default QuizCreate
