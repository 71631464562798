import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { atomAsset } from '@/assets/img/config'
import './MobileContent.scss'
import { ExerciseBlock } from '@/constants/blocks'

const images = {
    [ExerciseBlock]: atomAsset('developer-coding.png'),
}

const MobileContent = ({ type, message }) => {
    const { message: exerciseMessage } = useSelector(
        ({ langStore: { playground } }) => playground.mobile,
    )
    return (
        <div className="mobile-content-container">
            <img className="mobile-content-container__image" src={images[type]} alt="" />
            <p className="mobile-content-container__text">{message || exerciseMessage}</p>
        </div>
    )
}

MobileContent.propTypes = {
    type: PropTypes.string,
    message: PropTypes.string,
}

MobileContent.defaultProps = {
    type: ExerciseBlock,
    message: null,
}

export default MobileContent
