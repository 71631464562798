import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { actions as modalActions } from '@/redux/reducers/modalReducer'
import { CONFIRMATION_MODAL } from '@/constants/modals'
import Modal from '@digitalhouse-tech/react-lib-ui-explorer/lib/Modal'

const ConfirmationModal = () => {
    const dispatch = useDispatch()
    const modalState = useSelector(({ modal }) => modal)
    const { attention: attentionText, close: closeText } = useSelector(
        ({ langStore }) => langStore.modalConfirmation
    )
    const { message, buttonConfirmText, handleConfirm } = useSelector(
        ({ modal }) => modal.data
    )

    const handleClose = () => {
        dispatch(modalActions.close())
    }

    const handleConfirmModal = () => {
        if ('function' === typeof handleConfirm) {
            handleConfirm()
        }

        dispatch(modalActions.close())
    }

    return (
        <Modal
            id='confirmationModalExerciseBlock'
            showModal={CONFIRMATION_MODAL === modalState.id}
            modalTitle={attentionText}
            mainTextAction={buttonConfirmText}
            secondaryTextAction={closeText}
            onMainAction={handleConfirmModal}
            onSecondaryAction={handleClose}
            onCloseAction={handleClose}
            theme='success'
        >
            <p className='font-color--common-text __font_size_16'>{message}</p>
        </Modal>
    )
}

export default ConfirmationModal
