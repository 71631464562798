import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import { ArrowLeftCircle, Move, PlusCircle } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import CallToAction from '@digitalhouse-tech/react-lib-ui-explorer/lib/CallToAction'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'

import CourseOptions from '../../CourseOptions'
import { ORDER_UNITS, CREATE_UNITS } from '@/constants/coursePermissions'
import { showUnitsAndLessonsActions } from '@/helpers/granters/hideMamushkaElements'

import CourseModal from '../../Modal'
import './CourseHeader.scss'

const CourseHeader = ({
    changeOrder,
    course,
    courseHasProgress,
    dragDisable,
    dragGeneralDisable,
    handleGoBack,
    handleOrder,
    lang,
    openModalUnit,
    renderAlert,
}) => {
    const [modalCourse, setModalCourse] = useState(null)

    const openModalCourse = async (clone, course) => {
        return modalCourse.handleShow(clone, course)
    }

    return (
        <div className='course-header'>
            <CallToAction
                icon={<ArrowLeftCircle />}
                id='HandleGoBackButton'
                onClick={handleGoBack}
                strong
                text={lang.goBackButton}
            />
            {renderAlert()}
            <div className='course-header__course-info'>
                <div>
                    {course.alias && (
                        <h1 className='course-header__course-info__title'>{course.alias}</h1>
                    )}
                    <h2 className='course-header__course-info__subtitle'>
                        <div className='course-header__course-info__subtitle-tag'>
                            {course.base ? lang.program : lang.commission}
                        </div>
                        <div className='course-header__course-info__subtitle-name'>
                            {course.name}
                        </div>
                    </h2>
                </div>
                <div className='course-header__course-info__button'>
                    <ButtonGroup className='pull-right'>
                        {showUnitsAndLessonsActions(course) && (
                            <>
                                <CallToAction
                                    disabled={!(dragDisable && dragGeneralDisable)}
                                    icon={<PlusCircle />}
                                    id='CreateUnitButton'
                                    onClick={openModalUnit}
                                    strong
                                    text={lang.createUnitButton}
                                    visible={CREATE_UNITS()}
                                />
                                <ButtonRound
                                    disabled={!dragGeneralDisable && dragDisable}
                                    icon={<Move />}
                                    id='SortButton'
                                    onClick={dragDisable ? handleOrder : changeOrder}
                                    theme='primary'
                                    visible={ORDER_UNITS()}
                                />
                            </>
                        )}
                        <CourseOptions
                            cloneCourseHandler={() => openModalCourse('clone', course)}
                            courseInfo={course}
                            disabled={!(dragDisable && dragGeneralDisable)}
                            dropdownId={'dropdown-options__course-header'}
                            textLang={lang}
                        />
                    </ButtonGroup>
                </div>
            </div>
            {courseHasProgress && (
                <Row className='_margin_top_20'>
                    <Col lg="12">
                        <MessageBox theme='warning' title={lang.editAlert} fullWidth />
                    </Col>
                </Row>
            )}
            <CourseModal
                ref={(modalCourse) => {
                    setModalCourse(modalCourse)
                }}
                id={course.id}
            />
        </div>
    )
}

CourseHeader.propTypes = {
    changeOrder: PropTypes.func.isRequired,
    course: PropTypes.object.isRequired,
    courseHasProgress: PropTypes.bool.isRequired,
    dragDisable: PropTypes.bool.isRequired,
    dragGeneralDisable: PropTypes.bool.isRequired,
    handleGoBack: PropTypes.func.isRequired,
    handleOrder: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    openModalUnit: PropTypes.func.isRequired,
    renderAlert: PropTypes.func.isRequired,
}

export default CourseHeader
