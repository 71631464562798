import React from 'react'
import PropTypes from 'prop-types'
import ReadOnly from './ReadOnly'
import DefaultOpen from './DefaultOpen'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'

const MAX_FILE_LENGTH = 15

const File = ({
    index,
    file,
    icon,
    section,
    readOnlySections,
    activeFile,
    openFile,
    setActiveFile,
    setDefaultFile,
    isAdministration,
    setShowPopUp,
    allowFilesNameChange,
}) => {
    const getFilename = () => {
        if (file.name.length > MAX_FILE_LENGTH) {
            return (
                <Tooltip tooltipId={file.name} tooltipContent={file.name} tooltipPlacement='right'>
                    <span>{file.name.slice(0, MAX_FILE_LENGTH)}...</span>
                </Tooltip>
            )
        }
        return file.name
    }
    return (
        <li
            key={`main${file.name}${index}`}
            onClick={(e) => {
                setActiveFile(file)
                openFile(file)
                e.stopPropagation()
            }}
            onDoubleClick={() => allowFilesNameChange && setShowPopUp(true)}
            active={`${activeFile?._id === file._id}`}
        >
            {icon}
            {getFilename()}
            <ReadOnly readOnlySections={["readOnlySections"]} sectionName={section.name} />
            {section.name === 'SRC' && isAdministration ? (
                <DefaultOpen
                    sectionName={section.name}
                    onClick={() => setDefaultFile(file)}
                    isDefault={!!file.defaultOpen}
                />
            ) : null}
        </li>
    )
}

File.defaultProps = {
    readOnlySections: [],
    isAdministration: false,
}

File.propTypes = {
    readOnlySections: PropTypes.arrayOf(PropTypes.string),
    isAdministration: PropTypes.bool,
    activeFile: PropTypes.shape({
        name: PropTypes.string,
        content: PropTypes.string,
        language: PropTypes.string,
        section: PropTypes.string,
        defaultOpen: PropTypes.bool,
    }).isRequired,
    openFile: PropTypes.func.isRequired,
    setActiveFile: PropTypes.func.isRequired,
    setDefaultFile: PropTypes.func.isRequired,
    setShowPopUp: PropTypes.func.isRequired,
}

export default File
