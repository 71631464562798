import NonSecuredLayout from '@/components/Layout/NonSecuredLayout'
import RecoverPasswordManager from '@/modules/Auth/RecoverPasswordManager'
import Login from '@/modules/Auth/Login'
import TermsAndConditions from '@/components/Policies/TermsAndConditions'
import PrivacyPolicy from '@/components/Policies/PrivacyPolicy'
import LoginErrorPage from '@/components/LoginErrorPage/LoginErrorPage'

const routes = [
    {
        path: '/login',
        key: 'Non Secured - Login',
        exact: true,
        component: Login,
        strict: true,
    },
    {
        path: '/login-error',
        key: 'Non Secured - Login Error',
        exact: true,
        component: LoginErrorPage,
    },
    {
        path: '/forgot-password',
        key: 'Non Secured - Forgot Password',
        exact: true,
        component: RecoverPasswordManager,
    },
    {
        path: '/renew-password',
        key: 'Non Secured - Renew Password',
        exact: true,
        component: RecoverPasswordManager,
    },
    {
        path: '/terms-and-conditions',
        key: 'Non Secured - Terms and Conditions',
        exact: true,
        component: TermsAndConditions,
    },
    {
        path: '/privacy-policy',
        key: 'Non Secured - Privacy Policy',
        exact: true,
        component: PrivacyPolicy,
    },
]

export default routes.map((route) => ({
    ...route,
    path: route.path,
    layout: NonSecuredLayout,
}))
