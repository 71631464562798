import React, { useCallback, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'

import 'react-datepicker/dist/react-datepicker.css'

import BlockModal from './BlockModal'
import { BLOCK_TYPES } from '@/constants/blocks'
import { hasAnyProgressSelector, createModalDataSelector } from '@/redux/selectors'
import { getSelectOptions } from '@/helpers/arrays'
import {
    YOUTUBE,
    VIMEO,
    VIMEO_URL_REGEX,
    YOUTUBE_URL_REGEX,
    VIMEO_PLAYER_URL,
    YOUTUBE_PLAYER_URL,
} from '@/constants/videoBlock'
import { BAD_URL } from './constants/errorTypes'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import InputNumber from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputNumber'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

const DEFAULT_BLOCK = {
    VideoBlock: {
        width: '100',
        height: '100',
    },
}

const langSelector = ({ langStore: { modalVideoBlock, modalExerciseBlock, genericPresentationForm } }) => ({
    ...modalVideoBlock,
    ...modalExerciseBlock,
    ...genericPresentationForm,
})

const videoBlockSelector = createModalDataSelector(DEFAULT_BLOCK)

export const ModalVideoBlock = () => {
    const lang = useSelector(langSelector)
    const hasAnyProgress = useSelector(hasAnyProgressSelector)
    const data = useSelector(videoBlockSelector)

    const urlTypes = {
        [YOUTUBE]: `https://www.youtube.com/watch?v=${data.VideoBlock.url}`,
        [VIMEO]: `https://vimeo.com/${data.VideoBlock.url}`,
    }

    const getInitialUrl = () => {
        if (data.VideoBlock.url) {
            return urlTypes[data.VideoBlock.type]
        }

        return ''
    }

    const [url, setUrl] = useState(getInitialUrl())
    const [width, setWidth] = useState(data.VideoBlock.width)
    const [height, setHeight] = useState(data.VideoBlock.height)
    const [type, setType] = useState(data.VideoBlock.type)
    const [urlError, setUrlError] = useState(null)
    const [extra, setExtra] = useState(data.extra)

    const playersUrl = {
        [YOUTUBE]: url.replace(YOUTUBE_URL_REGEX, YOUTUBE_PLAYER_URL),
        [VIMEO]: url.replace(VIMEO_URL_REGEX, VIMEO_PLAYER_URL),
    }
    const urlIds = {
        [YOUTUBE]: url.replace(YOUTUBE_URL_REGEX, ''),
        [VIMEO]: url.replace(VIMEO_URL_REGEX, ''),
    }

    const isUrlValid = useMemo(
        () => url && (VIMEO_URL_REGEX.test(url) || YOUTUBE_URL_REGEX.test(url)),
        [url]
    )
    const playerUrl = () => (isUrlValid ? playersUrl[type] : '')

    const values = useMemo(
        () => ({
            url: urlIds[type] || '',
            width,
            height,
            type,
        }),
        [url, width, height, type]
    )

    const completeLang = useMemo(() => {
        const { blockName, isVisible, showBlockName } = lang
        return {
            ...lang,
            blockNameLabel: blockName,
            isVisibleLabel: isVisible,
            showBlockNameLabel: showBlockName,
        }
    }, [lang])
    const getHeight = useCallback(() => (isUrlValid ? (height * 538) / 100 : 0), [
        height,
        isUrlValid,
    ])
    const validateInputValues = useCallback(() => {
        const error = !isUrlValid ? lang.requiredUrlMessage : null
        setUrlError(error)
        return error ? [BAD_URL] : []
    }, [lang, setUrlError, isUrlValid])

    const blockTitle = data.id ? lang.modalVideoTitleEdit : lang.modalVideoTitleCreate
    const selectOptions = getSelectOptions(lang.yes, lang.no)
    const getValue = (option) => (option ? selectOptions[0] : selectOptions[1])
    const id = BLOCK_TYPES.VIDEO_BLOCK.modal

    const setNewUrl = (newUrl) => {
        setUrl(newUrl)
        if (YOUTUBE_URL_REGEX.test(newUrl)) {
            setType(YOUTUBE)
            return
        }
        if (VIMEO_URL_REGEX.test(newUrl)) {
            setType(VIMEO)
            return
        }

        setType('')
    }

    return (
        <BlockModal
            blockId={data.id}
            visibility={data.visibility}
            show_name={data.show_name}
            name={data.name}
            values={values}
            validateInputValues={validateInputValues}
            lang={completeLang}
            blockType={BLOCK_TYPES.VIDEO_BLOCK}
            blockTitle={blockTitle}
            id={id}
            extra={extra}
        >
            <Row>
                <Col lg="6" md="6">
                    <Label text={lang.urlVideo} />
                    <InputText
                        key='blockURL'
                        id='blockURL'
                        value={url}
                        placeholder='e.g. https://vimeo.com/...'
                        onChange={(e) => setNewUrl(e.target.value)}
                        error={urlError}
                    />
                </Col>
                <Col lg="3" md="3" className='modal-panel__number-input'>
                    <Label text={lang.width} />
                    <InputNumber
                        id='blockWidth'
                        value={width}
                        min={0}
                        max={100}
                        placeholder='100%'
                        onChange={(number) => setWidth(number)}
                    />
                </Col>
                <Col lg="3" md="3" className='modal-panel__number-input'>
                    <Label text={lang.height} />
                    <InputNumber
                        id='blockHeight'
                        value={height}
                        min={0}
                        max={100}
                        placeholder='100'
                        onChange={(number) => setHeight(number)}
                    />
                </Col>
                <Col md="12">
                    <Label text={lang.isExtraLabel} />
                    <InputComboBox
                        disabled={hasAnyProgress}
                        inputName='extra'
                        placeholder={lang.select}
                        id='extra'
                        dropdownItems={selectOptions.map(({ label }) => label)}
                        onChange={label => setExtra(label === selectOptions[0].label)}
                        value={getValue(extra).label}
                    />
                </Col>
            </Row>
            <Row style={{marginTop: '20px'}}>
                <Col lg="12" md="12">
                    <iframe
                        src={playerUrl()}
                        width={width + '%'}
                        height={getHeight() + 'vh'}
                        frameBorder='0'
                        title={BLOCK_TYPES.VIDEO_BLOCK.modal}
                        webkitallowfullscreen='true'
                        mozallowfullscreen='true'
                        allowFullScreen
                    />
                </Col>
            </Row>
        </BlockModal>
    )
}

export default ModalVideoBlock
