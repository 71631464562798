import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {
    Terminal,
    Youtube,
    Archive,
    FileText,
    File,
    Clipboard,
    Airplay,
    Code,
    Play,
    Box
} from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import './BlockIcon.scss'

const BlockIcon = ({ type, className, size, defaultColor }) => {
    const icons = {
        TextBlock: FileText,
        VideoBlock: Youtube,
        ExerciseBlock: Terminal,
        FileBlock: Archive,
        PdfBlock: File,
        QuizBlock: Clipboard,
        SlideBlock: Airplay,
        TextHtmlBlock: Code,
        EvaluableBlock: Play,
        NotionBlock: Box,
    }

    const Icon = icons[type]
    return (
        <Icon
            size={size}
            alt={`block-icon-${type}`}
            className={cn(
                {
                    'block-icon-default' : defaultColor,
                },
                className
            )}
        />
    )
}

BlockIcon.propTypes = {
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
    defaultColor: PropTypes.bool,
    size: PropTypes.number
}

BlockIcon.defaultProps = {
    className: '',
    defaultColor: false,
    size: 18
}

export default BlockIcon
