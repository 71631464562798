import { persistCombineReducers } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import { connectRouter } from 'connected-react-router'

import courseReducer from './_deprecated/courseReducer/index'
import authReducer from './_deprecated/authReducer'
import userReducer from './_deprecated/userReducer'
import instituteReducer from './_deprecated/instituteReducer'
import surveyReducer from './_deprecated/surveyReducer'

import fileManagerStore from '@/modules/Backoffice/Courses/Files/fileManagerStore'
import deployment from './deployment'
import langStore from './_deprecated/langReducer/langStore'
import exerciseManagerStore from './_deprecated/exerciseManagerReducer'
import globalAlertsStore from './_deprecated/globalAlertsReducer/alertsDispatcher'
import addNewPasswordStore from './_deprecated/addNewPasswordReducer/dispatcher'
import navigationStore from './_deprecated/navigationReducer/dispatcher'
import modalsReducer from './_deprecated/modalsReducer'
/**
 * Nuevos Reducers
 */
import blockReducer from './blockReducer'
import loadingReducer from './loadingReducer'
import mamushkaReducer from './mamushkaReducer'
import modalReducer from './modalReducer'
import managerReducer from './managerReducer'
import newCourseReducer from './courseReducer'
import chatbotReducer from './chatbotReducer'
import unitLessonTopic from './unitLessonTopicReducer'
import idleTime from './idleTimeReducer'
import roadmap from './roadmapReducer'
import courseInfo from './courseInfo'
import student from './student'
import roleManagerStore from './_deprecated/roleReducer'
import owner from './ownerReducer'
import navigationReducer from './navigationReducer'
import user from './userReducer'
import dashboardReducer from './dashboardReducer'
import context from './contextReducer'
import stratgyReducer from './strategyReducer'

const config = {
  key: 'primary',
  storage: storageSession,
  blacklist: ['router', 'loading', 'chatbot', 'modal', 'deployment', 'notifications', 'unitLessonTopic', 'course', 'idleTime', 'roadmap', 'courseInfo', 'student', 'progress']
}

export default (history) => {
  let appReducer = persistCombineReducers(config,
    {
      router: connectRouter(history),
      courseReducer,
      authReducer,
      userReducer,
      instituteReducer,
      surveyReducer,
      fileManagerStore,
      langStore,
      exerciseManagerStore,
      deployment,
      globalAlertsStore,
      addNewPasswordStore,
      navigationStore,
      loading: loadingReducer,
      block: blockReducer,
      mamushka: mamushkaReducer,
      modal: modalReducer,
      manager: managerReducer,
      modalsReducer,
      course: newCourseReducer,
      chatbot: chatbotReducer,
      unitLessonTopic,
      idleTime,
      roadmap,
      courseInfo,
      student,
      roleManagerStore,
      owner,
      navigationReducer,
      user,
      dashboardReducer,
      context,
      stratgyReducer
    })

    return (state, action) => {
        return appReducer(state, action)
    }
}