import React from 'react'

import { connect } from 'react-redux'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Modal } from 'react-bootstrap'
import moment from "moment"
import _ from 'lodash'
import Select from 'react-select'

import CustomCargando from '@/components/CustomCargando'
import { CustomFormField } from '@/components/CustomFormField'
import {
    EDIT_COURSE_DESCRIPTION,
    EDIT_COURSE_DATES,
    EDIT_COURSE_CONFIG,
} from '@/constants/coursePermissions'
import { ALLOW_API_CREATE, ENABLE_ROADMAP_URL } from '@/constants/courseRules'
import { dateFormat } from '@/helpers/dates/dateFormat'
import { timezoneConfig } from '@/helpers/dates/timezoneConfig'
import httpHandler, { codes } from '@/helpers/error-handling/httpHandler'
import {
    enableShowCompleteDate,
    allowApiLessons,
    enableCourseDescription,
    onlyBaseCourse,
    enableShowCourseDates,
    allowApiCreate,
    enableCertificate,
} from '@/helpers/granters/courseTypeRules'
import { courseHasApiManagementRule } from '@/helpers/granters/hideMamushkaElements'
import { getTenantLang } from '@/helpers/tenants/helpers'

import showNewMessage from '@/redux/reducers/_deprecated/globalAlertsReducer/alertsControllers'
import {
    saveNewCourse,
    updateOneCourse,
    cloneCourse,
    loadAllCourseTypes,
    loadStudentQuantityByCourse,
    clearStudentsQtyAction,
    changeSavingCourseAction
} from '@/redux/reducers/_deprecated/courseReducer'
import {
    loadAllInstitutes,
    loadPaginatedInstitutes,
    loadInstitute,
    clearInstituteAction
} from '@/redux/reducers/_deprecated/instituteReducer'

import { AlertCircle } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import InputNumber from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputNumber'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import InputTextArea from '@digitalhouse-tech/react-lib-ui-explorer/lib/TextAreaInput'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

import {
    CREATE_COURSE,
    CLONE_COURSE,
    FULL_COURSE_ACCESS,
} from '@digitalhouse-tech/ts-lib-store/lib/session-storage/permission'

import { enabledCourseTypes } from './enabledCourseTypes'

import './CourseModal.scss'
import { COURSE_TYPE_ON_DEMAND_ID } from '@/constants/courseTypes'

const [tz] = timezoneConfig()

moment.locale(getTenantLang())

const DEFAULT_INPUT_OBJ ={
    value: '',
    label: '',
}

const formatOptions = (arr, filters) => {

    if (!Array.isArray(arr) || (arr && !arr.length)) { return Array() }
    const [value, label] = filters
    return arr.map(obj => ({
        value: obj[value],
        label: obj[label]
    }))
}

const formatCurrentValue = (current, filters) => {
    if (!current) { return null }
    const [value, label] = filters
    return { value: current[value], label: current[label] }
}

export class ModalCourse extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.defaultMessage = this.props.lang.requiredInputMessage

        this.defaultState = {
            order: { error: null, value: null, message: '' },
            studentQuantity: null,
            course: null,
            ready: false,
            show: false,
            isClone: false,
            isEdit: false,
            course_type: { error: null, value: null },
            code: { error: null, value: '' },
            name: { error: null, value: '', message: '' },
            institute: { error: null, value: null },
            initial_date: { error: null, value: null },
            finish_date: { error: null, value: null },
            base: { error: null, value: { value: 0, label: this.props.lang.no }, isDisabled: false },
            alias: { error: null, value: '' },
            show_dates: { error: null, value: { value: true, label: this.props.lang.yes } },
            show_progress_bar: { error: null, value: { value: false, label: this.props.lang.no } },
            cover_url: { error: null, value: '' },
            roadmap_url: { error: null, value: '' },
            completed_form_url: { error: null, value: '' },
            duration_hours: { error: null, value: null },
            validationStyles: null,
            description: { error: null, value: '', message: '' },
            live_class_url: { error: null, value: '', message: '' },
        }

        this.state = {
            ...this.defaultState,
            base: { error: null, value: { value: 0, label: this.props.lang.no }, isDisabled: false },
            show_dates: { error: null, value: { value: true, label: this.props.lang.yes } },

            loading: false,
        }

        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleInstituteChange = this.handleInstituteChange.bind(this)
        this.handleInstituteInputChange = this.handleInstituteInputChange.bind(this)
        this.onChangeSelect = this.onChangeSelect.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
        this.onChangeTimePicker = this.onChangeTimePicker.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.modalBody = this.modalBody.bind(this)
        this.onChangeAliasInput = this.onChangeAliasInput.bind(this)
        this.optionsType = this.optionsType.bind(this)
        this.getLabels = this.getLabels.bind(this)
        this.closeWithoutErrors = this.closeWithoutErrors.bind(this)
        this.checkPermissions = this.checkPermissions.bind(this)


        this.showDates = this.showDates.bind(this)
        this.allowApiCreateRule = this.allowApiCreateRule.bind(this)
        this.enableDescription = this.enableDescription.bind(this)
        this.onlyBase = this.onlyBase.bind(this)
        this.enableShowDates = this.enableShowDates.bind(this)
        this.getDateFormat = this.getDateFormat.bind(this)

        this.courseTypeHasRoadmapUrl = this.courseTypeHasRoadmapUrl.bind(this)
        this.disabledField = this.disabledField.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        if (state.course && props && props.id === state.course.id) {
            if ((state.isEdit || state.isClone) && !state.ready) {
                const isInstituteReady = state.isEdit && (state.course.institute_id && props.activeInstitute?.isLoaded || !state.course.institute_id);

                if (props.allInstitutes.isLoaded && props.courseTypes.isLoaded && props.studentQuantity.isLoaded && isInstituteReady) {
                    const instituteFromList = props.allInstitutes.institutes.find(institute => institute.id === state.course.institute_id);
                    const activeInstitute = props.activeInstitute?.id ? { id: props.activeInstitute.id, name: props.activeInstitute.name } : instituteFromList

                    return {
                        ready: true,
                        studentQuantity: props.studentQuantity.qty,
                        name: {
                            ...state.name,
                            value: state.course.name
                        },
                        order: {
                            ...state.order,
                            value: state.course.order
                        },
                        alias: {
                            ...state.alias,
                            value: state.course.alias
                        },
                        base: {
                            ...state.base,
                            value: {
                                value: state.isClone ? false : state.course.base,
                                label: state.isClone ? props.lang.no : (state.course.base ? props.lang.yes : props.lang.no)
                            },
                            isDisabled: !state.course.base,
                        },
                        code: {
                            ...state.code,
                            value: state.isClone ? "" : state.course.code
                        },
                        initial_date: {
                            ...state.initial_date,
                            value: state.isClone
                                ? null
                                : state.course.initial_date
                                ? moment(state.course.initial_date).tz(tz)
                                : null,
                        },
                        finish_date: {
                            ...state.finish_date,
                            value: state.isClone
                                ? null
                                : state.course.finish_date
                                ? moment(state.course.finish_date).tz(tz)
                                : null,
                        },
                        institute: {
                            ...state.institute,
                            value: state.isClone
                                ? null
                                : !state.institute.value
                                    ? formatCurrentValue(activeInstitute, ['id', 'name'])
                                    : state.institute.value
                        },
                        course_type: {
                            ...state.course_type,
                            value: !state.course_type.value
                                ? formatCurrentValue(
                                    props.courseTypes.items.find(courseType => courseType.id === state.course.course_type_id), ['id', 'name']
                                )
                                : state.course_type.value
                        },
                        show_dates: {
                            ...state.show_dates,
                            value: {
                                value: state.course.show_dates ? true : false,
                                label: state.course.show_dates ? props.lang.yes : props.lang.no
                            }
                        },
                        show_progress_bar: {
                            ...state.show_progress_bar,
                            value: {
                                value: state.course.show_progress_bar ? true : false,
                                label: state.course.show_progress_bar ? props.lang.yes : props.lang.no
                            }
                        },
                        cover_url: {
                            ...state.cover_url,
                            value: state.course.cover_url,
                        },
                        roadmap_url: {
                            ...state.roadmap_url,
                            value: state.course.roadmap_url,
                        },
                        completed_form_url: {
                            ...state.completed_form_url,
                            value: state.course.completed_form_url,
                        },
                        duration_hours: {
                            ...state.duration_hours,
                            value: state.course.duration_hours,
                        },
                        description: {
                            ...state.description,
                            value: !state.course.description ? '' : state.course.description
                        },
                        live_class_url: {
                            ...state.live_class_url,
                            value: state.course.live_class_url,
                        }
                    }
                }
            }
        }
        return {}
    }

    validateForm() {
        let ok = true
        let course_type = { error: null, value: this.state.course_type.value }
        let name = { error: null, value: this.state.name.value }
        let base = { error: null, value: this.state.base.value }
        let show_dates = { error: null, value: this.state.show_dates.value }
        let show_progress_bar = { error: null, value: this.state.show_progress_bar.value }
        let description = { error: null, value: this.state.description.value }
        let roadmap_url = { error: null, value: this.state.roadmap_url.value }
        let completed_form_url = { error: null, value: this.state.completed_form_url.value }
        let duration_hours = { error: null, value: this.state.duration_hours.value }
        let live_class_url = { error: null, value: this.state.live_class_url.value }

        const courseType = _.find(this.props.courseTypes.items, { id: this.state.course_type?.value?.value })

        if (this.state.isClone) {
            if (name.value === this.props.name) {
                ok = false
                name.error = 'error'
                name.message = this.props.lang.nameAlreadyExistsMessage
            }
        }

        if (!name.value || name.value.trim() === "") {
            ok = false
            name.error = 'error'
            name.message = this.defaultMessage
        }

        if (!course_type.value) {
            ok = false
            course_type.error = 'error'
            course_type.message = this.defaultMessage
        }
        if (!base.value) {
            ok = false
            base.error = 'error'
            base.message = this.defaultMessage
        }
        if (!show_dates.value) {
            ok = false
            show_dates.error = 'error'
            show_dates.message = this.defaultMessage
        }
        if (!show_progress_bar.value) {
            ok = false
            show_progress_bar.error = 'error'
            show_progress_bar.message = this.defaultMessage
        }
        if (description.value.length > this.props.descriptionMaxLength) {
            ok = false
            description.error = 'error'
            description.message = this.props.lang.descriptionMaxLength
        }
        if (roadmap_url.value && !/^https?:\/\/.*.pdf/.test(roadmap_url.value)) {
            ok = false
            roadmap_url.error = 'error'
            roadmap_url.message = this.props.lang.invalidURL
        }

        if(courseType && enableCertificate(courseType)){
            if (
                (completed_form_url.value && !/^https:\/\//g.test(completed_form_url.value)) ||
                !completed_form_url.value
            ) {
                ok = false
                completed_form_url.error = 'error'
                completed_form_url.message = !completed_form_url.value
                    ? this.defaultMessage
                    : this.props.lang.invalidURL
            }
            if (
                (duration_hours.value &&
                    !/^\s*(?=.*[1-9])\d{0,2}(?:\.\d{1,1})?\s*$/g.test(duration_hours.value)) ||
                !duration_hours.value
            ) {
                ok = false
                duration_hours.error = 'error'
                duration_hours.message = !duration_hours.value
                    ? this.defaultMessage
                    : this.props.lang.invalidDuration
            }
        }

        if (live_class_url.value && !/^https:\/\//g.test(live_class_url.value)) {
            ok = false
            live_class_url.error = 'error'
            live_class_url.message = this.props.lang.invalidURL
        }


        if (!ok) {
            this.setState({
                ...this.state,
                course_type,
                name,
                base,
                show_dates,
                show_progress_bar,
                validationStyles: { color: 'red' },
                description,
                roadmap_url,
                completed_form_url,
                duration_hours,
                live_class_url,
            })
        }


        return ok
    }

    async onSubmit() {
        const isOk = this.validateForm()
        let base = this.state.base.value.value
        if (this.onlyBase() && this.state.code.value === "") {
            base = 1
        }

        const formatFinishDate = this.state.isEdit
        ? moment(this.state.finish_date.value).utc().format('YYYY-MM-DD HH:mm:ss')
        : moment(this.state.finish_date.value)
            .add(1, 'days')
            .subtract(1, 'minute')
            .utc()
            .format('YYYY-MM-DD HH:mm:ss')

        if (isOk) {
            let courseData = {
                course_type_id: this.state.course_type.value.value,
                order: +this.state.order?.value,
                name: this.state.name.value.trim(),
                code: this.state.code.value === "" ? null : this.state.code.value,
                initial_date: this.state.initial_date.value
                    ? moment(this.state.initial_date.value).utc().format('YYYY-MM-DD HH:mm:ss')
                    : null,
                finish_date: this.state.finish_date.value
                    ? formatFinishDate
                    : null,
                alias: this.state.alias.value,
                base,
                institute_id: this.state.institute.value ? this.state.institute.value.value : null,
                show_dates: this.state.show_dates.value.value,
                show_progress_bar: this.state.show_progress_bar.value.value,
                cover_url: this.state.cover_url.value,
                roadmap_url: this.state.roadmap_url.value,
                completed_form_url: this.state.completed_form_url.value,
                duration_hours: this.state.duration_hours.value,
                description: this.state.description.value,
                live_class_url: this.state.live_class_url.value
            }
            this.props.changeSavingCourse(true)

            if (this.state.isClone) {
                try {
                    this.setState({
                        ...this.state,
                        loading: true
                    })

                    courseData.id = this.state.course.id
                    await cloneCourse(courseData, this.props.allCourses)
                    this.handleClose()
                }
                catch (e) {
                    showNewMessage({
                        type: this.props.courseErrors.style,
                        message: this.props.lang.correctCloneCourseError,
                        isTemporal: false,
                    })
                }
                finally {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                }
            } else {
                if (!this.state.course) {
                    try {
                        this.setState({
                            ...this.state,
                            loading: true
                        })

                        await saveNewCourse(courseData, this.props.allCourses)
                        this.handleClose()
                    }
                    catch (e) {
                        httpHandler(e)({
                            [codes.CONFLICT]: () => {
                                showNewMessage({
                                    type: this.props.courseErrors.style,
                                    message: this.props.lang.nameAlreadyExistsMessage,
                                    isTemporal: false,
                                })
                            },
                            [codes.BAD_REQUEST]: () => {
                                showNewMessage({
                                    type: this.props.courseErrors.style,
                                    message: this.props.lang.sgeCoursesShouldBeBases,
                                    isTemporal: false,
                                })
                            }
                        })
                    }
                    finally {
                        this.setState({
                            ...this.state,
                            loading: false
                        })
                    }
                } else {
                    try {
                        this.setState({
                            ...this.state,
                            loading: true
                        })

                        courseData.id = this.state.course.id
                        await updateOneCourse(courseData, this.props.allCourses)
                        this.handleClose()
                    }
                    catch (e) {
                        httpHandler(e)({
                            [codes.INTERNAL_SERVER_ERROR]: () => {
                                showNewMessage({
                                    type: this.props.courseErrors.style,
                                    message: this.props.lang.nameAlreadyExistsMessage,
                                    isTemporal: false,
                                })
                            },
                            [codes.BAD_REQUEST]: () => {
                                showNewMessage({
                                    type: this.props.courseErrors.style,
                                    message: this.props.lang.sgeCoursesShouldBeBases,
                                    isTemporal: false,
                                })
                            }
                        })
                    }
                    finally {
                        this.setState({
                            ...this.state,
                            loading: false
                        })
                    }
                }
            }
        }

        return
    }

    closeWithoutErrors() {
        if (!this.props.courseErrors) {
            this.handleClose()
        }
    }

    handleClose() {
        this.setState({
            ...this.defaultState,
            base: { error: null, value: { value: 0, label: this.props.lang.no } },
            show_dates: { error: null, value: { value: true, label: this.props.lang.yes } },
        })
        this.props.clearInstituteData()
        this.props.clearStudentsQty()
    }

    handleShow(clone, course) {
        let isClone = !!clone
        let isEdit = !!course

        this.props.clearInstituteData()
        this.props.changeSavingCourse(false)
        this.props.loadAllInstitutes()
        this.props.loadAllCourseTypes()

        if (isEdit && course.institute_id) this.props.loadInstitute(course.institute_id)
        if (course) this.props.loadStudentQuantityByCourse(course.id)
        let state = _.cloneDeep({
            ...this.defaultState,
            base: { error: null, value: { value: 0, label: this.props.lang.no } },
            show_dates: { error: null, value: { value: true, label: this.props.lang.yes } },
        })

        this.setState({
            ...state,
            show: true,
            course,
            isEdit,
            isClone,
        })
    }

    onChangeSelect(item, target) {
        let base = _.cloneDeep(this.state.base)
        let courseType
        let previousCourseType
        if (target === 'course_type') {
            const isBase = this.allowApiCreateRule(item);
            courseType = _.find(this.props.courseTypes.items, { id: item.value })
            previousCourseType = _.find(this.props.courseTypes.items, {
                id: this.state.course_type?.value?.value,
            })

            base = {
                error: null,
                value: isBase
                    ? { value: 1, label: this.props.lang.yes }
                    : { value: 0, label: this.props.lang.no }
            }
        }

        this.setState({
            ...this.state,
            base,
            [target]: {
                ...this.state[target],
                value: item,
            },
            code: target === 'course_type' ? this.defaultState.code : this.state.code,
            ...(courseType &&
                !this.enableShowDates(courseType) && {
                    show_dates: { error: null, value: { value: false, label: this.props.lang.no } },
                }),
            ...(courseType &&
                previousCourseType &&
                this.enableShowDates(courseType) &&
                !this.enableShowDates(previousCourseType) && {
                    show_dates: { error: null, value: { value: true, label: this.props.lang.yes } },
                }),
        })
    }

    onChangeInput(e) {
        if (e.target.name == 'order' && !(e.target.value >= 0)) e.target.value = 0;
        this.setState({
            ...this.state,
            [e.target.name]: {
                ...this.state[e.target.name],
                value: e.target.value
            }
        })
    }

    onChangeTimePicker(time, target) {
        this.setState({
            ...this.state,
            [target]: {
                ...this.state[target],
                value: time
            }
        })
    }

    onChangeAliasInput(e) {
        this.setState({
            ...this.state,
            alias: {
                ...this.state[e.alias],
                value: e
            }
        })
    }

    optionsType() {
        let courseTypes = enabledCourseTypes(this.props.courseTypes.items)
        if (this.state.isClone || this.state.isEdit) {
            (courseTypes = courseTypes.filter(
                (type) => type.Rules.includes(ALLOW_API_CREATE) === this.allowApiCreateRule()
            )),
            ['id', 'name']
        }
        return formatOptions(courseTypes, ['id', 'name'])
    }

    getDateFormat(){
        if (!this.state.course_type.value) {
            return false
        }
        const courseType = _.find(this.props.courseTypes.items, { id: this.state.course_type.value.value })
        const enableCompleteDate = enableShowCompleteDate(courseType)
        return dateFormat(enableCompleteDate)
    }

    showDates() {
        if (!this.state.course_type.value) {
            return false
        }
        const courseType = _.find(this.props.courseTypes.items, { id: this.state.course_type.value.value })
        if(!this.enableShowDates(courseType))
            return true
        return false
    }

    enableDescription(){
        if (!this.state.course_type.value) {
            return false
        }
        const courseType = _.find(this.props.courseTypes.items, { id: this.state.course_type.value.value })
        return enableCourseDescription(courseType)
    }

    onlyBase(){
        if (!this.state.course_type.value) {
            return false
        }
        const courseType = _.find(this.props.courseTypes.items, { id: this.state.course_type.value.value })
        return onlyBaseCourse(courseType)
    }

    enableShowDates(courseType){
        return enableShowCourseDates(courseType)
    }

    allowApiCreateRule(data) {
        const courseTypeId = data?.value ?? this.state.course_type?.value?.value;
        if (!courseTypeId) {
            return false
        }
        const courseType = _.find(this.props.courseTypes.items, { id: courseTypeId })
        return allowApiCreate(courseType)
    }

    enableCourseCertificate(){
        if (!this.state.course_type.value) {
            return false
        }
        const courseType = _.find(this.props.courseTypes.items, { id: this.state.course_type.value.value })
        return enableCertificate(courseType)
    }

    allowApiLessonsRule(){
        if (!this.state.course_type.value) {
            return false
        }
        const courseType = _.find(this.props.courseTypes.items, { id: this.state.course_type.value.value })
        return allowApiLessons(courseType)
    }

    getLabels(text, element) {
        const validationError = this.state[element].error
        return (
            <>
                {text + ' '} <span key={element + '_span'} style={validationError ? { color: 'red' } : {}} >*</span>
            </>
        )
    }

    checkPermissions(permissionType) {
        const fullAccess = FULL_COURSE_ACCESS()
        if (fullAccess)
            return fullAccess

        const types = {
            'description': EDIT_COURSE_DESCRIPTION,
            'dates': EDIT_COURSE_DATES,
            'config': EDIT_COURSE_CONFIG,
        }

        const { isEdit, isClone } = this.state

        if (!isEdit && !isClone) {
            return CREATE_COURSE()
        }

        if (isClone) {
            return CLONE_COURSE()
        }

        if (isEdit) {
            return types[permissionType]()
        }

        return false
    }

    courseTypeHasRoadmapUrl() {
        if (!this.state.course_type.value) return false
        const courseType = _.find(this.props.courseTypes.items, { id: this.state.course_type.value.value })
        return courseType.Rules.includes(ENABLE_ROADMAP_URL)
    }

    disabledField() {
        return (
            this.props.CourseType &&
            courseHasApiManagementRule({ CourseType: this.props.CourseType })
        )
    }

    handleInstituteChange(selectedOption) {
        this.onChangeSelect(selectedOption, 'institute')
    }

    handleInstituteInputChange(value) {
        this.props.loadPaginatedInstitutes(1, value)
    }

    getInstituteOptions() {
        return this.props.allInstitutes?.institutes?.map((institute) => {
            return { value: institute.id, label: institute.name }
        })
    }

    modalBody() {
        if ((this.state.isClone || this.state.isEdit) && !this.state.ready) {
            return <CustomCargando />
        }
        return (
            <>
                {this.checkPermissions('description') &&
                    <>
                        <Row>
                            <Col md="4" className='_margin_bottom_25'>
                                <Label text={this.getLabels(this.props.lang.name, 'name')} />
                                <InputText
                                    id="name"
                                    key="name"
                                    name="name"
                                    value={this.state.name.value}
                                    onChange={this.onChangeInput}
                                    autoFocus
                                    error={this.state.name.error}
                                    errorMsg={this.state.name.message}
                                    disabled={this.disabledField()}
                                />
                            </Col>

                            <Col md="4">
                                <Label text={this.props.lang.alias} />
                                <InputText
                                    id="alias"
                                    key="alias"
                                    name="alias"
                                    value={this.state.alias.value}
                                    type="text"
                                    onChange={e => this.onChangeAliasInput(e.target.value)}
                                    error={this.state.alias.error}
                                    errorMsg={this.state.alias.message}
                                    disabled={this.disabledField()}
                                />
                            </Col>

                            <Col md="4">
                                <Label text={this.props.lang.code} />
                                <InputText
                                    id="code"
                                    key="code"
                                    name="code"
                                    value={this.state.code.value}
                                    onChange={this.onChangeInput}
                                    type="text"
                                    error={this.state.code.error}
                                    errorMsg={this.state.code.message}
                                    disabled={this.state.isEdit && !this.state.isClone}
                                />
                            </Col>

                        </Row>
                        <Row className='_margin_bottom_25'>
                            {(this.state.studentQuantity === 0 || this.state.isClone || !(this.state.isClone || this.state.isEdit))
                                ? (
                                    <Col md="4">
                                        <Label text={this.getLabels(this.props.lang.courseType, 'course_type')} />
                                        <InputComboBox
                                            inputName="course_type"
                                            placeholder={this.props.lang.select}
                                            id="course_type"
                                            key="course_type"
                                            dropdownItems={this.optionsType()?.map(option => option.label)}
                                            onChange={(item) => this.onChangeSelect({
                                                value: this.optionsType()?.find(option => option.label === item).value,
                                                label: item,
                                            }, 'course_type')}
                                            value={this.state.course_type.value?.label}
                                            error={this.state.course_type.error}
                                            errorMsg={this.defaultMessage}
                                            disabled={this.disabledField()}
                                        />
                                    </Col>
                                )
                                : (
                                    this.state.course_type.value
                                        ? (
                                            <Col md="4">
                                                <div className="alert alert-info">
                                                    <strong>{this.props.lang.important}</strong>
                                                    <br />
                                                    <strong>{this.props.lang.type}: </strong>{this.state.course_type.value.label}
                                                    {this.allowApiCreateRule()
                                                        ? (
                                                            <>
                                                                , &nbsp;
                            <strong>{this.props.lang.code}: </strong>{this.state.code.value}
                                                            </>
                                                        )
                                                        : null
                                                    }
                                                </div>
                                            </Col>
                                        )
                                        : null
                                )
                            }

                            <Col md="4">
                                <CustomFormField
                                    className="course-finder__institute"
                                    controlId="institute"
                                    label={this.props.lang.institute}
                                    inputComponent={
                                        <Select
                                            name="institute"
                                            placeholder={this.props.lang.select}
                                            id="institute"
                                            key="institute"
                                            options={this.getInstituteOptions()}
                                            onChange={this.handleInstituteChange}
                                            onInputChange={this.handleInstituteInputChange}
                                            value={this.state.institute?.value}
                                            isSearchable
                                            isClearable
                                        />
                                    }
                                />
                            </Col>
                            <Col md="4">
                                {this.allowApiCreateRule() ? (
                                            (
                                                <>
                                                    <Label text={this.getLabels(this.props.lang.isItABaseCourse, 'base')} />
                                                    <InputText
                                                        id="base"
                                                        key="base"
                                                        name="base"
                                                        bsSize="small"
                                                        value={this.props.lang.yes}
                                                        disabled={true}
                                                        type="text"
                                                    />
                                                </>
                                            )
                                        )
                                    :
                                    (
                                        <>
                                            <Label text={this.getLabels(this.props.lang.isItABaseCourse, 'base')} />
                                            <InputComboBox
                                                name="base"
                                                placeholder={this.props.lang.select}
                                                id="base"
                                                key="base"
                                                dropdownItems={[this.props.lang.yes, this.props.lang.no]}
                                                onChange={(item) => this.onChangeSelect({
                                                    label: item,
                                                    value: !!(item === this.props.lang.yes),
                                                }, 'base')}
                                                value={this.state.base.value.label}
                                                disabled={this.state.base.isDisabled}
                                            />
                                        </>
                                    )
                                }
                            </Col>
                        </Row>
                    </>
                }
                {this.checkPermissions('dates') &&
                    <Row className='_margin_bottom_25'>
                        <Col md="4">
                            <CustomFormField
                                bsSize="small"
                                controlId="initial_date"
                                label={this.props.lang.startDate}
                                inputComponent={
                                    <DatePicker
                                        className="form-control input-md __date_picker_width"
                                        dateFormat={this.getDateFormat()}
                                        name="initial_date"
                                        disabled={this.allowApiCreateRule()}
                                        selected={this.state.initial_date.value}
                                        onChange={(time) => this.onChangeTimePicker(time, 'initial_date')}
                                        placeholderText={this.props.lang.selectDate}
                                    />
                                } />
                        </Col>
                        <Col md="4">
                            <CustomFormField
                                bsSize="small"
                                controlId="finish_date"
                                label={this.props.lang.finishDate}
                                inputComponent={
                                    <DatePicker
                                        className="form-control input-md __date_picker_width"
                                        dateFormat={this.getDateFormat()}
                                        name="finish_date"
                                        disabled={this.allowApiCreateRule()}
                                        selected={this.state.finish_date.value}
                                        onChange={(time) => this.onChangeTimePicker(time, 'finish_date')}
                                        placeholderText={this.props.lang.selectDate}
                                    />
                                } />
                        </Col>
                        <Col md="4">
                            <Label text={this.getLabels(this.props.lang.showDates, 'show_dates')} />
                            <InputComboBox
                                disabled={this.showDates()}
                                name="show_dates"
                                placeholder={this.props.lang.select}
                                id="show_dates"
                                key="show_dates"
                                dropdownItems={[this.props.lang.yes, this.props.lang.no]}
                                onChange={(item) => this.onChangeSelect({
                                    value: !!(item === this.props.lang.yes),
                                    label: item
                                }, 'show_dates')}
                                value={this.showDates() ? this.props.lang.no : this.state.show_dates.value?.label}
                            />
                        </Col>
                    </Row>
                }
                {this.checkPermissions('config') &&
                    <Row className='_margin_bottom_25'>
                        <Col md="4">
                            <div className="__url_cover_title">
                                <Label className="__cover_label" text={this.props.lang.coverUrl} />
                                <div className="__cover_tooltip">
                                    <Tooltip
                                        tooltipId="cover_url"
                                        tooltipContent={this.props.lang.tooltipUrl}
                                    >
                                        <AlertCircle />
                                    </Tooltip>
                                </div>
                            </div>
                            <InputText
                                key="cover_url"
                                name="cover_url"
                                id="cover_url"
                                value={this.state.cover_url.value}
                                onChange={this.onChangeInput}
                                placeholder={'e. g. https://image.free.com/vector-gratis/diseno-ux-ui.jpg'}
                                error={this.state.cover_url.error}
                                errorMsg={this.state.cover_url.message}
                            />
                        </Col>
                        <Col md="4">
                            <Label text={this.getLabels(this.props.lang.showProgressBar, 'show_progress_bar')} />
                            <InputComboBox
                                name="show_progress_bar"
                                placeholder={this.props.lang.select}
                                id="show_progress_bar"
                                key="show_progress_bar"
                                dropdownItems={[this.props.lang.yes ,this.props.lang.no]}
                                onChange={(item) => this.onChangeSelect({
                                    label: item,
                                    value: !!(item === this.props.lang.yes)
                                }, 'show_progress_bar')}
                                value={this.state.show_progress_bar.value.label}
                            />
                        </Col>
                    </Row>
                }
                {this.enableCourseCertificate() &&
                    <Row>
                        <Col md="8" className='_margin_bottom_25'>
                            <Label text={this.getLabels(this.props.lang.completedFormUrl, 'completed_form_url')} />
                            <InputText
                                key="completed_form_url"
                                name="completed_form_url"
                                value={this.state.completed_form_url.value}
                                onChange={this.onChangeInput}
                                error={this.state.completed_form_url.error}
                                errorMsg={this.state.completed_form_url.message}
                            />
                        </Col>
                        <Col md="4">
                            <div className="modal-panel__number-input">
                                <Label text={this.getLabels(this.props.lang.duration, 'duration_hours')} />
                                <InputNumber
                                    name="duration_hours"
                                    value={this.state.duration_hours.value}
                                    min={1}
                                    placeholder="_ hs"
                                    onChange={(value) => this.onChangeInput({ target: { name: 'duration_hours', value } })}
                                />
                                {this.state.duration_hours.error && (
                                    <MessageBox theme='danger' noFill text={this.state.duration_hours.message}/>
                                )}
                            </div>
                        </Col>
                    </Row>
                }
                {this.courseTypeHasRoadmapUrl() &&
                    <Row>
                        <Col md="12" className='_margin_bottom_25'>
                            <Label text={this.props.lang.roadmapUrl} />
                            <InputText
                                id="roadmapUrl"
                                key="roadmap_url"
                                name="roadmap_url"
                                value={this.state.roadmap_url.value}
                                onChange={this.onChangeInput}
                                placeholder={'e. g. https://some.site.com/documents/roadmap.pdf'}
                                error={this.state.roadmap_url.error}
                                errorMsg={this.state.roadmap_url.message}
                            />
                        </Col>
                    </Row>
                }
                {this.checkPermissions('description') && this.enableDescription() ?
                    <Row style={{marginTop: '10px', marginBottom: '30px'}}>
                        <Col md="12">
                            <Label text={this.props.lang.description}  />
                        </Col>
                        <Col md="12">
                            <InputTextArea
                                id="description_course"
                                name="description"
                                label={this.props.lang.description}
                                placeholder={this.props.lang.descriptionSubtitle}
                                value={this.state.description.value}
                                onChange={this.onChangeInput}
                                componentClass="textarea"
                                maxLength={this.props.descriptionMaxLength}
                                validationMessage={this.state.description.message}
                                validationState={this.state.description.error}
                            />
                        </Col>
                    </Row>
                : null}
                {this.allowApiLessonsRule() ?
                    <Row style={{marginTop: '10px', marginBottom: '30px'}}>
                        <Col md="12">
                            <Label htmlFor='live_class_url_course' text={this.props.lang.linkToRemoteClass} />
                            <InputText
                                error={this.state.live_class_url.error}
                                errorMsg={this.state.live_class_url.message}
                                id='live_class_url_course'
                                key='live_class_url'
                                name='live_class_url'
                                onChange={this.onChangeInput}
                                placeholder={this.props.lang.linkToRemoteClassPlaceholder}
                                value={this.state.live_class_url.value}
                            />
                        </Col>
                    </Row>
                    : null}
                {(this.state.course_type.value?.value === COURSE_TYPE_ON_DEMAND_ID) &&
                    <Row style={{ marginTop: '10px', marginBottom: '30px' }}>
                        <Col md="4">
                            <Label htmlFor='order' text={this.props.lang.order} />
                            <InputText
                                key='order'
                                type='number'
                                id='order'
                                name='order'
                                min="0"
                                onChange={this.onChangeInput}
                                value={this.state.order?.value}
                            />
                        </Col>
                    </Row>
                }
            </>
        )
    }

    render() {
        return (
            <>
                <Modal className="__modal" bsSize="large" show={this.state.show} onHide={this.handleClose} keyboard={false} backdrop="static">
                    <Modal.Header closeButton className="__modal_header __border_bottom">
                        <h3 className="__modal_title">
                            {this.state.isClone ? this.props.lang.copyCourse : (this.state.isEdit ? this.props.lang.editTitle : this.props.lang.modalTitle)}
                        </h3>
                    </Modal.Header>

                    <Modal.Body className="__modal_body __course">
                        {this.modalBody()}
                    </Modal.Body>

                    <Modal.Footer className="__modal_footer __border_top">
                        <ButtonGroup className='pull-right'>
                            <Button
                                id="CancelButton"
                                onClick={this.handleClose}
                                text={this.props.lang.cancel}
                                theme="tertiary"
                                disabled={this.state.loading}
                            />
                            <Button
                                id="SubmitButton"
                                loading={this.state.loading}
                                disabled={this.state.loading}
                                onClick={this.onSubmit}
                                text={this.props.lang.save}
                            />
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

const mapDispatch = (dispatch) => ({
    loadAllInstitutes: () => {
        dispatch(loadAllInstitutes())
    },
    loadPaginatedInstitutes: (page, name) => {
        dispatch(loadPaginatedInstitutes(page, name))
    },
    loadInstitute: (id) => {
        dispatch(loadInstitute(id))
    },
    loadAllCourseTypes: () => {
        dispatch(loadAllCourseTypes())
    },
    loadStudentQuantityByCourse: (courseId) => {
        dispatch(loadStudentQuantityByCourse(courseId))
    },
    clearStudentsQty: () => {
        dispatch(clearStudentsQtyAction())
    },
    changeSavingCourse: (status) => {
        dispatch(changeSavingCourseAction(status))
    },
    clearInstituteData: () => {
        dispatch(clearInstituteAction())
    },
})

const mapStateToProps = (state) => {
    return {
        allCourses: state.courseReducer.allPaginatedCourses,
        allInstitutes: state.instituteReducer.allInstitutes,
        activeInstitute: state.instituteReducer.activeInstitute,
        courseTypes: state.courseReducer.courseTypes,
        studentQuantity: state.courseReducer.studentQuantity,
        lang: state.langStore.modalCourse,
        courseErrors: state.courseReducer.alert,
        descriptionMaxLength: state.courseReducer.descriptionCourseMaxLength
    }
}

export default connect(mapStateToProps, mapDispatch, null, { forwardRef: true })(ModalCourse)
