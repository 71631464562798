import React, { useEffect } from 'react'

let fileUploadInputRef = null

const FileUploadWindow = ({
    refInputFile,
    onSelectFile,
    onError,
    acceptExtensions,
    sizeLimitInMegabytes
}) => {

    useEffect(() => {
        if (fileUploadInputRef != null) refInputFile(fileUploadInputRef)
    }, [])


    const validateFileName = fileName => {
        if (!fileName.includes('.')) return false
        const fileNameSplited = fileName.split(".");
        if (!fileNameSplited.length) return false
        const posibleExtensionExtracted = fileNameSplited[fileNameSplited.length - 1]
        if (posibleExtensionExtracted.includes(' ')) return false
        return true
    }

    const selectFileHandler = async ({ target: { files } }) => {
        const file = files[0]
        
        if (sizeLimitInMegabytes) {
            const megasFileSize = file.size / 1024 / 1024
            if (megasFileSize > sizeLimitInMegabytes) {
                onError(file.name, 'size')
                return
            }
        }

        if (!validateFileName(file.name)) {
            onError(file.name, 'name')
            return
        }

        // Reset the input so it trigger the onChange event on the same file
        fileUploadInputRef.value = ''

        onSelectFile(file)
    }

    return (
        <div>
            <input
                type="file"
                name="file"
                style={{ display: 'none' }}
                ref={ref => {
                    if (ref !== null) fileUploadInputRef = ref
                }}
                accept={acceptExtensions ? acceptExtensions : null}
                onChange={selectFileHandler}
            />
        </div>
    )
}

export default FileUploadWindow