import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import GuardRedirect from '../Layout/GuardRedirect'
import { getUser } from '@/redux/selectors/auth'
import { DISCLAIMER_LOCATION } from '@/constants/locations'
import { getGuard } from '@/routes/guards'
import LoginRedirect from '../Layout/LoginRedirect'
import Route from './Route'
import { getTenantSession } from '@/helpers/tenants/helpers'
import LegalDisclaimer from '@/modules/Auth/LegalDisclaimer'
import { getUserSession } from '@/helpers/users/helpers'

const PrivateRoute = ({
    exact,
    path,
    guard,
    permission,
    component,
    layout,
    location,
}) => {
    const currentGuard = getGuard()
    const { legal_disclaimer } = useSelector(getUser)
    const { showTermsAndConditions } = getTenantSession()
    const { firstRedirected } = useSelector(state => state.context)
    const userSession = getUserSession()

    const itsNavigateInOtherGuard = () => guard !== currentGuard

    const userHasNotAcceptedTheTerms = () =>
        !(legal_disclaimer || userSession.legal_disclaimer) && !location.pathname.includes(DISCLAIMER_LOCATION)

    if (!userSession?.id) {
        return <LoginRedirect location={location} path={path} />
    }
    if ((itsNavigateInOtherGuard() || !permission()) && guard) {
        return <GuardRedirect />
    }
    if (userHasNotAcceptedTheTerms() && showTermsAndConditions) {
        return <LegalDisclaimer />
    }
    if (!firstRedirected) {
        return null
    }

    return <Route path={path} exact={exact} component={component} layout={layout} />
}

PrivateRoute.defaultProps = {
    exact: false,
    component: null,
    guards: [],
    permission: () => true,
    computedMatch: {
        params: {
            lang: '',
        },
    },
    location: {
        pathname: '',
    },
}

PrivateRoute.propTypes = {
    exact: PropTypes.bool,
    path: PropTypes.string.isRequired,
    component: PropTypes.node,
    computedMatch: PropTypes.shape({
        params: PropTypes.shape({
            lang: PropTypes.string,
        }),
    }),
    layout: PropTypes.node.isRequired,
    guard: PropTypes.string,
    permission: PropTypes.func,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
}

export default PrivateRoute
