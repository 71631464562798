import React from 'react'
import PropTypes from 'prop-types'
import * as env from '@/config/env'
import { BLOCK_ISSUE_FILES } from '@/constants/vaultyPaths'

import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { X, ThumbsUp } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import RadioButton from '@digitalhouse-tech/react-lib-ui-explorer/lib/RadioButton'
import InputTextArea from '@digitalhouse-tech/react-lib-ui-explorer/lib/TextAreaInput'
import Modal from '@digitalhouse-tech/react-lib-ui-explorer/lib/Modal'
import { modalSteps, modalStepDirections } from './constants/modalSteps'
import { uploaderConfig } from './constants/imageUploaderConfig'
import { restrictionMessage, acceptedTypes } from './helpers/imageUpload'
import FilesUploaderManager from '@/components/FilesUploaderManager'
import './BlockIssueModal.scss'

const vaultyConfig = {
    apiKey: env.VAULTY_API_KEY,
    writerUrl: `${env.VAULTY_WRITER_URL}`,
    readerUrl: `${env.VAULTY_READER_URL}/${env.VAULTY_PATH}`,
}

const BlockIssueModal = ({
    issueExplanation,
    changeIssueExplanation,
    selectedIssueIndex,
    selectIssue,
    modalStep,
    changeModalStep,
    issuesByBlockType,
    showModal,
    closeModal,
    sendTrackingData,
    lang,
    nextStep,
    issueImages,
    handleIssueImagesUpload,
}) => {
    const issuesStep = () => {
        return (
            <>
                <div className='issues-options-modal__options'>
                    <p className='issues-options-modal__options--subtitle'>{lang.optionsListTitle}</p>
                    {issuesByBlockType.map((item, optionIndex) => {
                        return (
                            <div
                                className='issues-options-modal__options--item'
                                key={`issue-${optionIndex}`}
                            >
                                <RadioButton
                                    checked={optionIndex === selectedIssueIndex}
                                    id={`issue-${optionIndex}`}
                                    inputName={`issue-${optionIndex}`}
                                    onClick={(e) => selectIssue(optionIndex, e.target.value)}
                                    value={item}
                                />
                                <p>{item}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='issues-options-modal__buttons'>
                    <Button
                        text={lang.nextButton}
                        onClick={nextStep}
                        disabled={selectedIssueIndex === null}
                    />
                </div>
            </>
        )
    }

    const issueExplanationStep = () => {
        return (
            <>
                <div className='issues-options-modal__options'>
                    <p className='issues-options-modal__options--subtitle'>
                        {lang.issueExplanation}
                    </p>
                    <div className='issues-options-modal__options--textarea'>
                        <InputTextArea
                            id='block_issue'
                            name='block_issue'
                            value={issueExplanation}
                            onChange={(e) => changeIssueExplanation(e.target.value)}
                            componentClass='textarea'
                        />
                    </div>
                    <div className="issues-options-modal__options__uploader">
                        <FilesUploaderManager
                            title={lang.attachImages}
                            subtitle={restrictionMessage(lang.attachImagesRestriction)}
                            onChange={handleIssueImagesUpload}
                            files={issueImages}
                            acceptedFormats={acceptedTypes()}
                            maxUploadLimit={uploaderConfig.FILES_LIMIT}
                            maxFileSizeLimit={env.FILES_SIZE_LIMIT_MB}
                            vaultyConfig={vaultyConfig}
                            errorMsg={lang.uploadError}
                            skeletonProps={{fileNameWidth: '330px'}}
                            vaultyFolder={BLOCK_ISSUE_FILES}
                        />
                    </div>
                </div>
                <div className='issues-options-modal__buttons'>
                    <Button
                        className='issues-options-modal__buttons--back'
                        id='back_block_issue_modal'
                        text={lang.backButton}
                        onClick={() => changeModalStep(modalStepDirections.BACK)}
                        theme='secondary'
                    />
                    <Button
                        id='next_block_issue_modal'
                        text={lang.continueButton}
                        onClick={() => sendTrackingData()}
                        theme='primary'
                    />
                </div>
            </>
        )
    }

    const successMessageStep = () => {
        return (
            <>
                <div className='issues-options-modal__success'>
                    <div className='issues-options-modal__success--icon'>
                        <ThumbsUp size={40} />
                    </div>
                    <p>{lang.successMessageTitle}</p>
                    <p>{lang.successMessageSubtitle}</p>
                    <p className='issues-options-modal__success--team'>{lang.successMessageTeam}</p>
                </div>
                <div className='issues-options-modal__buttons'>
                    <Button
                        id='finish_block_issue_modal'
                        text={lang.successButton}
                        onClick={() => changeModalStep(null, true)}
                        theme='primary'
                    />
                </div>
            </>
        )
    }

    return (
        <Modal showModal={showModal} customModal id='block_issue_modal'>
            <div className='issues-options-modal'>
                {modalStep !== modalSteps.SUCCESS_MESSAGE && (
                    <div className='issues-options-modal__title'>
                        <p>{lang.suggestRevisionTitle}</p>
                        <Button
                            id='close_block_issue_modal'
                            className='issues-options-modal__title--close'
                            onClick={() => closeModal()}
                            theme='clear'
                            icon={<X size={16} />}
                        />
                    </div>
                )}
                {modalStep === modalSteps.OPTIONS && issuesStep()}
                {modalStep === modalSteps.EXPLANATION && issueExplanationStep()}
                {modalStep === modalSteps.SUCCESS_MESSAGE && successMessageStep()}
            </div>
        </Modal>
    )
}

BlockIssueModal.propTypes = {
    issueExplanation: PropTypes.string,
    changeIssueExplanation: PropTypes.func.isRequired,
    selectedIssueIndex: PropTypes.number,
    selectIssue: PropTypes.func.isRequired,
    modalStep: PropTypes.string,
    changeModalStep: PropTypes.func.isRequired,
    issuesByBlockType: PropTypes.array,
    showModal: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    sendTrackingData: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
}

BlockIssueModal.defaultProps = {
    showModal: false,
    issuesByBlockType: [],
}

export default BlockIssueModal
