import React from 'react'
import _ from 'lodash'
import Dropdown from '../Dropdown'
import { ChevronDown } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import RuleModals from './RuleModals'
import RuleTable from './RuleTable'

const ExerciseRules = ({
    lang = {},
    rules = [],
    allRuleTypes = [],
    handlers = {
        setField: () => { }
    },
    availableFiles = []
}) => {
    const refModals = {}
    const ruleModalName = (ruleType) => {
        return `${ruleType}ModalForm`
    }
    const modals = () => allRuleTypes.map((name, i) => {
        const ModalRuleComponent = RuleModals[ruleModalName(name)]
        if (ModalRuleComponent) {
            return (
                <ModalRuleComponent
                    key={`modalRule${i}`}
                    id={name+i}
                    ref={node => refModals[ruleModalName(name)] = node}
                    handlers={handlers}
                    rules={rules}
                    availableFiles={availableFiles}
                />
            )
        }
        return null
    })
    const dropdownItems = () => allRuleTypes.map(name => ({
        name,
        onClick: () => {
            refModals[ruleModalName(name)].showModal()
        }
    }))
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <ButtonGroup className="pull-right">
                        <Dropdown
                            ddStyle='_new_btn--tertiary'
                            items={dropdownItems()}
                        >
                            <span>
                                {lang.createNewRule}
                                <ChevronDown />
                            </span>
                        </Dropdown>
                    </ButtonGroup>
                </div>
                <div className="col-md-12">
                    <RuleTable
                        rules={rules}
                        actions={{
                            onEdit: (key, ruleType) => {
                                refModals[ruleModalName(ruleType)].showModal(
                                    rules[key].id, rules[key].config, key
                                )
                            },
                            onDelete: key => {
                                const allRules = [...rules]
                                allRules.splice(key, 1)
                                handlers.setField('rules', allRules)
                            }
                        }}
                        lang={lang.ruleTable}
                    />
                </div>
            </div>
            {modals()}
        </>
    )
}

export default ExerciseRules