import React from 'react'
import PropTypes from 'prop-types'
import AppHeader from './AppHeader'

const SharedLayout = ({ children }) => {
    return (
        <>
            {/* <AppHeader /> */}
            {children}
        </>
    )
}

SharedLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default SharedLayout
