import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { FormControl } from 'react-bootstrap'
import Select from 'react-select'
import { Country, State, City } from 'country-state-city'
import { CustomFormField } from '@/components/CustomFormField'

import {
  saveNewInstitute,
  updateOneInstitute,
  loadInstitute,
  clearInstituteAction,
  changeSelectedCountry,
  changeSelectedState,
  changeSelectedCity,
  changeSelectedOrganizationType,
  clearSelectionsAction
} from '../../../redux/reducers/_deprecated/instituteReducer'
import { history } from '@/app/store.js'
import _ from 'lodash'
import CustomCargando from '@/components/CustomCargando'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'
import { isValidEmail } from '@/helpers/utils/validators'

export class InstituteForm extends React.Component {

  constructor(props, context) {
    super(props, context)

    this.state = {
      instituteId: this.props.match.params.id,
      name: { error: false, message: '' },
      description: { error: false, message: '' },
      email: { error: false, message: '' },
      phone: { error: false, message: '' },
      address: { error: false, message: '', show: false },
      active: true,
      levels: { error: '', message: '', selectedLevels: [] },
      country: { error: '', message: '', show: true },
      state: { error: '', message: '', show: false },
      city: { error: '', message: '', show: false, value: null },
      organizationType: { error: '', message: '', selected: '' },
      isEdit: false
    }

    this.levels = [
      { value: 'PRIMARY', label: this.props.lang.primary },
      { value: 'SECONDARY', label: this.props.lang.secondary },
      { value: 'SUPERIOR', label: this.props.lang.higher }
    ]

    this.organizationTypes = [
      { value: 'b2b', label: this.props.lang.company },
      { value: 'schools', label: this.props.lang.schools },
      { value: 'university', label: this.props.lang.university }
    ]

    this.submitInstitute = this.submitInstitute.bind(this)
    this.changeSelect = this.changeSelect.bind(this)
    this.handleActive = this.handleActive.bind(this)
    this.changeSelectCountry = this.changeSelectCountry.bind(this)
    this.changeSelectState = this.changeSelectState.bind(this)
    this.changeSelectCity = this.changeSelectCity.bind(this)
    this.changeSelectOrganizationType = this.changeSelectOrganizationType.bind(this)
    this.getDefaultCountry = this.getDefaultCountry.bind(this)
    this.getDefaultState = this.getDefaultState.bind(this)
    this.getDefaultCity = this.getDefaultCity.bind(this)
    this.getDefaultOrganizationType = this.getDefaultOrganizationType.bind(this)
    this.handleGoBack = this.handleGoBack.bind(this)
  }

  componentDidMount() {
    this.props.clearInstituteData()
    this.props.clearSelections()

    if (this.props.match.params.id) {
      this.props.loadInstitute(this.props.match.params.id)
      this.setState({
        ...this.state,
        isEdit: true
      })
    }
  }

  getDefaultLevels(data) {
    if (!this.state.isEdit)  return null

    if (data.length === undefined) {
      return { value: data.id, label: data.name }
    }
    return data.map(level => {
      return this.levels.find(item => item.value === level)
    })
  }

  getDefaultCountry(value) {
    const country = Country.getCountryByCode(value)

    if (!country || !value || !this.state.isEdit) return null

    return {
      label: country.name,
      value
    }
  }

  getDefaultState(value) {
    const country = this.props.selectedCountry?.value
    const state = State.getStateByCodeAndCountry(value, country)

    if (!state || !value || !this.state.isEdit) return null

    return {
      label: state.name,
      value
    }
  }

  getDefaultCity(value) {
    const country = this.props.selectedCountry?.value
    const state = this.props.selectedState?.value
    const city = City.getCitiesOfState(country, state).filter(item => item.name === value)[0]

    if (!city || !value || !this.state.isEdit) return null

    return {
      label: city.name,
      value
    }
  }

  getDefaultOrganizationType() {
    return this.organizationTypes.find(item => item.value === this.props.selectedOrganizationType)
  }

  getCountries() {
    const countries = Country.getAllCountries()

    return countries.map(country => (
      {
        value: country.isoCode,
        label: country.name
      }
    ))
  }

  getStatesByCountry(country) {
    const states = State.getStatesOfCountry(country)

    return states.map(state => (
      {
        value: state.isoCode,
        label: state.name
      }
    ))
  }

  getCitiesByState(country, state) {
    const cities = City.getCitiesOfState(country, state)

    return cities.map(city => (
      {
        value: city.name,
        label: city.name
      }
    ))
  }

  handleActive(selectedOption) {
    this.setState({
      ...this.state,
      active: selectedOption.value
    })
  }

  changeSelect(selectedLevels) {
    this.setState({
      ...this.state,
      levels: {
        ...this.state.levels,
        selectedLevels
      }
    })
  }

  validateInfo(name, levels, organizationType, email) {
    let formOk = true
    const nameValue = { error: false, message: '' }
    const emailValue = { error: false, message: '' }
    const levelsValue = { error: false, message: '' }
    const organizationTypeValue = { error: false, message: '' }

    if (name == null || name == '') {
      nameValue.error = true
      nameValue.message = this.props.lang.requiredInputMessage
      formOk = false
    } else {
      nameValue.error = false
      nameValue.message = ''
    }

    if (email === '' || isValidEmail(email)) {
      emailValue.error = false
      emailValue.message = ''
    } else {
      emailValue.error = true
      emailValue.message = this.props.lang.emailFormatMessage
      formOk = false
    }

    if (!organizationType) {
      organizationTypeValue.error = true
      organizationTypeValue.message = this.props.lang.requiredInputMessage
      formOk = false
    }

    this.setState({
      ...this.state,
      name: nameValue,
      email: emailValue,
      levels: levelsValue,
      organizationType: {
        ...organizationTypeValue,
        selected: organizationType
      }
    })

    return formOk
  }

  submitInstitute() {
    const instituteId = this.state.instituteId
    const allInstitutes = this.props.allInstitutes.institutes
    const name = ReactDOM.findDOMNode(this.name).value
    const phone = ReactDOM.findDOMNode(this.phone).value
    const description = ReactDOM.findDOMNode(this.description).value
    const address = this.address ? ReactDOM.findDOMNode(this.address).value : ''
    const email = ReactDOM.findDOMNode(this.email).value
    const active = this.state.active
    const country = this.props.selectedCountry?.value
    const state = this.props.selectedState?.value
    const city = this.props.selectedCity?.value
    const organizationType = this.props.selectedOrganizationType

    // let levels = this.state.levels.selectedLevels
    const levels = this.refs.levels.select.commonProps.getValue().map(item => item.value)

    // no sabemos que hacia esto pero la bardeaba mal
    /*if (!levels.length && this.state.isEdit) {
        levels = this.formatSelect(this.props.activeInstitute.Levels)
    }*/

    const formValidation = this.validateInfo(name, levels, organizationType, email)

    if (formValidation) {
      const formData = {
        name,
        phone,
        description,
        address,
        active,
        levels,
        email,
        country,
        state,
        city,
        organizationType
      }

      if (this.state.isEdit) {
        const updatedInfo = { id: instituteId, ...formData }

        this.props.updateInstitute(updatedInfo, allInstitutes)
      } else {
        this.props.createInstitute(formData, allInstitutes)
      }

      history.push('/admin/institutes')
    }
  }

  changeSelectCountry(selectedCountry) {
    this.props.changeSelectedCountry(selectedCountry)
    this.props.changeSelectedState(null)
    this.props.changeSelectedCity(null)
  }

  changeSelectState(selectedState) {
    this.props.changeSelectedState(selectedState)
    this.props.changeSelectedCity(null)
  }

  changeSelectCity(selectedCity) {
    this.props.changeSelectedCity(selectedCity)
  }

  changeSelectOrganizationType(selectedOrganizationType) {
    this.props.changeSelectedOrganizationType(selectedOrganizationType.value)
  }

  handleGoBack() {
    history.goBack()
  }

  render() {
    if (!this.props.match.params.id || this.props.activeInstitute.isLoaded) {

      return (
        <>
          <div className="container">
            <div className="__main_header">
              <Row>
                <Col lg="12">
                  <h1 className="__main_title">{(this.state.isEdit ? this.props.lang.editInstitution : this.props.lang.createInstitution)}</h1>
                </Col>
              </Row>
            </div>
            <Panel className="__card __fixedHeight">
              <Row>
                <Col md="6">
                  <h3 className="__card_secondary_title">{this.props.lang.institutionInformation}</h3>
                  <CustomFormField
                    validationState={this.state.name.error ? 'error' : null}
                    validationMessage={this.state.name.message} bsSize="small" controlId="name"
                    label={this.props.lang.nameLabel} inputComponent={
                      <FormControl key="instituteName" bsSize="small"
                        defaultValue={this.state.isEdit ? this.props.activeInstitute.name : null}
                        ref={name => { this.name = name }} type="text"
                        autoFocus>
                      </FormControl>
                    }
                  />

                  <CustomFormField
                    validationState={this.state.email.error ? 'error' : null}
                    validationMessage={this.state.email.message} bsSize="small" controlId="email"
                    label={this.props.lang.emailLabel} inputComponent={
                      <FormControl key="instituteEmail" bsSize="small"
                        defaultValue={this.state.isEdit ? this.props.activeInstitute.contactEmail : null}
                        ref={email => { this.email = email }} type="text">
                      </FormControl>
                    }
                  />

                  <CustomFormField
                    validationState={this.state.description.error ? 'error' : null}
                    validationMessage={this.state.description.message} bsSize="small" controlId="description"
                    label={this.props.lang.descriptionLabel} inputComponent={
                      <FormControl key="instituteDescription" bsSize="small"
                        defaultValue={this.state.isEdit ? this.props.activeInstitute.description : null}
                        ref={description => { this.description = description }} type="textarea">
                      </FormControl>
                    }
                  />

                  <Row>
                    <Col md="6">
                      <CustomFormField
                        validationState={this.state.phone.error ? 'error' : null}
                        validationMessage={this.state.phone.message} bsSize="small" controlId="phone"
                        label={this.props.lang.phoneNumberLabel} inputComponent={
                          <FormControl key="institutePhone" bsSize="small"
                            defaultValue={this.state.isEdit ? this.props.activeInstitute.contactPhone : null}
                            ref={phone => { this.phone = phone }} type="text">
                          </FormControl>
                        }
                      />
                    </Col>
                    <Col md="6">
                      <CustomFormField
                        label={this.props.lang.institutionStateLabel}
                        inputComponent={
                          <Select
                            name={this.props.lang.userState}
                            placeholder={this.props.lang.selectOption}
                            id="active"
                            key="active"
                            options={[
                              { value: true, label: this.props.lang.activeState },
                              { value: false, label: this.props.lang.inactiveState }
                            ]}
                            onChange={this.handleActive}
                            defaultValue={this.state.isEdit ? (this.props.activeInstitute.active ? { value: true, label: this.props.lang.activeState } : { value: false, label: this.props.lang.inactiveState }) : { value: true, label: this.props.lang.activeState }}
                          />
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  <h3 className="__card_secondary_title">{this.props.lang.aditionalInformation}</h3>
                  <CustomFormField
                    bsSize="small"
                    controlId="organizationType"
                    label={this.props.lang.organizationType}
                    validationState={this.state.organizationType.error ? 'error' : null}
                    validationMessage={this.state.organizationType.message}
                    inputComponent={
                      <Select
                        name={this.props.lang.organizationType}
                        placeholder={this.props.lang.selectOption}
                        id="organizationType"
                        key="organizationType"
                        options={this.organizationTypes}
                        onChange={this.changeSelectOrganizationType}
                        defaultValue={this.state.isEdit ? this.getDefaultOrganizationType() : null}
                      />
                    }
                  />
                  <CustomFormField className="selectInputStyle" validationState={this.state.levels.error ? 'error' : null}
                    validationMessage={this.state.levels.message} bsSize="small" controlId="levels"
                    label={this.props.lang.levelLabel}
                    inputComponent={
                      <Select
                        name={this.props.lang.levels}
                        placeholder={this.props.lang.selectLevel}
                        ref="levels"
                        id="levels"
                        key="levels"
                        options={this.levels}
                        onChange={this.changeSelect}
                        defaultValue={this.getDefaultLevels(this.props.activeInstitute.levels)}
                        isSearchable={true}
                        isMulti={true}
                      />
                    }
                  />

                  {(this.state.country.show || this.props.activeInstitute.country) &&
                    <CustomFormField
                      className="selectInputStyle"
                      bsSize="small"
                      controlId="country"
                      label={this.props.lang.countryLabel}
                      inputComponent={
                        <Select
                          name={this.props.lang.countries}
                          placeholder={this.props.lang.selectOption}
                          id="countries"
                          key="countries"
                          options={this.getCountries()}
                          onChange={this.changeSelectCountry}
                          defaultValue={this.getDefaultCountry(this.props.selectedCountry?.value)}
                          isSearchable={true}
                        />
                      }
                    />
                  }

                  {(this.props.selectedCountry || this.props.selectedState) &&
                    <CustomFormField
                      className="selectInputStyle"
                      bsSize="small"
                      controlId="state"
                      label={this.props.lang.stateLabel}
                      inputComponent={
                        <Select
                          name={this.props.lang.states}
                          placeholder={this.props.lang.selectOption}
                          id="states"
                          key="states"
                          options={this.getStatesByCountry(this.props.selectedCountry?.value)}
                          onChange={this.changeSelectState}
                          value={this.props.selectedState}
                          defaultValue={this.getDefaultState(this.props.selectedState?.value)}
                          isSearchable={true}
                        />
                      }
                    />
                  }

                  {(this.props.activeInstitute.state || this.props.activeInstitute.city || this.props.selectedState?.value) &&
                    <CustomFormField className="selectInputStyle"
                      bsSize="small"
                      controlId="city"
                      label={this.props.lang.cityLabel}
                      inputComponent={
                        <Select
                          name={this.props.lang.cities}
                          placeholder={this.props.lang.selectOption}
                          id="cities"
                          key="cities"
                          options={this.getCitiesByState(this.props.selectedCountry?.value, this.props.selectedState?.value)}
                          onChange={this.changeSelectCity}
                          value={this.props.selectedCity}
                          defaultValue={this.getDefaultCity(this.props.selectedCity?.value)}
                          isSearchable={true}
                        />
                      }
                    />
                  }

                  {(this.state.address.show || this.props.activeInstitute.address) &&
                    <CustomFormField validationState={this.state.address.error ? 'error' : null}
                      validationMessage={this.state.address.message} bsSize="small" controlId="code"
                      label={this.props.lang.addressLabel} inputComponent={
                        <FormControl key="instituteAddress"
                          bsSize="small"
                          defaultValue={this.state.isEdit ? this.props.activeInstitute.address : null}
                          ref={address => { this.address = address }} type="textarea">
                        </FormControl>
                      } />
                  }
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <div className="__main_footer">
                    <ButtonGroup className="pull-right _margin_bottom_12">
                      <Button
                        id="GoBackButton"
                        theme="secondary"
                        onClick={this.handleGoBack}
                        text={this.props.lang.goBackButton}
                      />
                      <Button
                        id="SaveInstitutionButton"
                        theme="primary"
                        onClick={this.submitInstitute}
                        text={this.props.lang.saveInstitutionButton}
                      />
                    </ButtonGroup>
                  </div>
                </Col>
              </Row>
            </Panel>
          </div>
        </>
      )
    } else {
      return (
        <React.Fragment>
          <CustomCargando />
        </React.Fragment>
      )
    }
  }
}

const mapDispatch = (dispatch) => ({
  createInstitute: (saveInfo, allInstitutes) => {
    dispatch(saveNewInstitute(saveInfo, allInstitutes))
  },
  updateInstitute: (updatedInfo, allInstitutes) => {
    dispatch(updateOneInstitute(updatedInfo, allInstitutes))
  },
  loadInstitute: (instituteId) => {
    dispatch(loadInstitute(instituteId))
  },
  clearInstituteData: () => {
    dispatch(clearInstituteAction())
  },
  changeSelectedCountry: (selectedCountry) => {
    dispatch(changeSelectedCountry(selectedCountry))
  },
  changeSelectedState: (selectedState) => {
    dispatch(changeSelectedState(selectedState))
  },
  changeSelectedCity: (selectedCity) => {
    dispatch(changeSelectedCity(selectedCity))
  },
  changeSelectedOrganizationType: (selectedOrganizationType) => {
    dispatch(changeSelectedOrganizationType(selectedOrganizationType))
  },
  clearSelections: () => {
    dispatch(clearSelectionsAction())
  },
})

const mapStateToProps = (state) => {
  return {
    allInstitutes: state.instituteReducer.allInstitutes,
    allLevels: state.instituteReducer.allLevels,
    activeInstitute: state.instituteReducer.activeInstitute,
    allCountries: state.instituteReducer.allCountries,
    allStates: state.instituteReducer.allStates,
    allCities: state.instituteReducer.allCities,
    valueState: state.instituteReducer.valueState,
    valueCity: state.instituteReducer.valueCity,
    selectedCountry: state.instituteReducer.selectedCountry,
    selectedState: state.instituteReducer.selectedState,
    selectedCity: state.instituteReducer.selectedCity,
    selectedOrganizationType: state.instituteReducer.selectedOrganizationType,
    lang: state.langStore.createNewInstitution
  }
}

export default connect(mapStateToProps, mapDispatch, null, { forwardRef: true })(InstituteForm)
