import { createSelector } from 'reselect'

/**
 * OLD COURSE REDUCER SELECTORS
 */
export const getCourse = state => ({ ...state.courseReducer.activeCourse })

export const courseIdSelector = createSelector(getCourse, course => course.id)

export const unitsSelector = createSelector(getCourse, course => ([ ...course.Units ]))
export const unitSelector = createSelector(
    unitsSelector,
    (state, unitId) => unitId,
    (units, unitId) => ({ ...units.find(unit => unit.id === unitId) }),
)

export const lessonsSelector = createSelector(unitSelector, unit => ([ ...unit.Lessons ]))
export const lessonSelector = createSelector(
    lessonsSelector,
    (state, unitId, lessonId) => lessonId,
    (lessons, lessonId) => ({ ...lessons.find(lesson => lesson.id === lessonId) }),
)

export const topicsSelector = createSelector(lessonSelector, lesson => ([ ...lesson.Topics ]))
export const topicSelector = createSelector(
    topicsSelector,
    (state, unitId, lessonId, topicId) => topicId,
    (topics, topicId) => ({ ...topics.find(topic => topic.id === topicId) }),
)

export const blocksSelector = createSelector(topicSelector, topic => ([ ...topic.Blocks ]))
export const blockSelector = createSelector(
    blocksSelector,
    (state, unitId, lessonId, topicId, blockId) => blockId,
    (blocks, blockId) => ({ ...blocks.find(block => block.id === blockId) }),
)

/**
 * NEW COURSE REDUCER SELECTORS
 */
export const getAllCourses = ({ course }) => [ ...course.all ]

export const getCourseType = ({ course }) => course.CourseType
