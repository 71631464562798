import {
    STRING_MIN_LENGHT as STRING_MIN_LENGTH,
    TOOL_NAME_MAX_LENGTH,
    TOOL_DESCRIPTION_MAX_LENGTH,
} from '../../../../constants/formValidations'

const WHITE_SPACE_REGEX = new RegExp(' ', 'g')

const name = value => {
    if (value.replace(WHITE_SPACE_REGEX, '').length === STRING_MIN_LENGTH) return 'fieldIsRequired'

    if (value.length > TOOL_NAME_MAX_LENGTH) return 'toolNameMaxLength'
}

const description = value => {
    if (value.replace(WHITE_SPACE_REGEX, '').length === STRING_MIN_LENGTH) return 'fieldIsRequired'

    if (value.length > TOOL_DESCRIPTION_MAX_LENGTH) return 'toolDescriptionMaxLength'
}

const tool_url = value => {
    const URL_REGEX = /^((http|https):\/\/)(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    const url = new RegExp(URL_REGEX)

    if (value.replace(WHITE_SPACE_REGEX, '').length === STRING_MIN_LENGTH) return 'fieldIsRequired'

    if (!url.test(value)) return 'invalidURL'

    if (!value.includes('https')) return 'noHttps'
}

const tutorial_url = value => {
    const URL_REGEX = /^((http|https):\/\/)(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    const url = new RegExp(URL_REGEX)

    if (!url.test(value)) return 'invalidURL'

    if (!value.includes('https')) return 'noHttps'
}

export const schema = { name, description, tool_url, tutorial_url }
export const emptyErrors = {
    name: { state: null, message: '' },
    description: { state: null, message: '' },
    tool_url: { state: null, message: '' },
    tutorial_url: { state: null, message: '' },
}
