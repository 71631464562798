import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useCourseCard } from '../hooks/useCourseCard'
import { emptyStateAsset } from '@/assets/img/config'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { MoreVertical, Video, Calendar } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import ButtonDropdown from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonDropdown'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'
import OptionsList from './OptionsList'
import { useButtons } from './hooks/useButtons'

import './CourseCard.scss'
import { useSchedules } from '@/hooks/useSchedule'

const CourseCard = ({ course, setConfirmModal }) => {
    const { alias, id, name } = course

    const courseName = alias || name

    const { courseState, nextLessonDate, survey, scheduleLang } = useCourseCard(course)
    const filteredSchedules = course?.schedules?.filter(s => s.periodicity === 1)
    const getSchedules = useSchedules(scheduleLang, filteredSchedules)

    const [mainButtons, dropdownOptionsButtons, moreOptionsAriaLabel] = useButtons(
        course,
        setConfirmModal,
        survey
    )

    const [state, cardClassNames] = courseState
    const [subtitle, info] = nextLessonDate

    const isOneLine = (text) => {
        if (!text) return null

        return text.trim().indexOf(' ') === -1
    }

    return (
        <div className='course-card-wrapper' data-testid={`welcome-course-card-desktop-${id}`}>
            {state && <span className={cardClassNames}>{state}</span>}
            <div className='course-card__options__list'>
                <ButtonDropdown
                    theme='clear'
                    customDropdown={<OptionsList options={dropdownOptionsButtons} courseId={id} />}
                    expandIcon={<MoreVertical className='course-card__options__list--icon' />}
                    aria-label={moreOptionsAriaLabel}
                />
            </div>
            <div
                className={cn(
                    { 'course-card__name--one-line': isOneLine(courseName) },
                    'course-card__name'
                )}
            >
                <Tooltip
                    tooltipId='courseCardName'
                    tooltipContent={courseName}
                    className='course-card__tooltip'
                >
                    <p>{courseName}</p>
                </Tooltip>
            </div>
            
            <div className={cn({__hidden: !filteredSchedules.length }, 'course-card__days')}>
                <div className='course-card__days-icon'>
                    <Calendar />
                </div>
                <div className='course-card__days-text'>
                    { getSchedules }
                </div>
            </div>

            <div className={cn({ __hidden: !subtitle || !info }, 'course-card__date')}>
                <Video className='course-card__date-icon' />
                <span className='course-card__date-info'>{(subtitle || info) && info}</span>
            </div>

            {mainButtons.map((button) => (
                <Button
                    id={button.id}
                    test-id={button.testId}
                    onClick={button.onClick}
                    text={button.text}
                    disabled={button.isDisabled}
                    visible={button.isVisible}
                    theme={button.theme}
                    loading={button.loading}
                    aria-label={button.ariaLabel}
                />
            ))}
        </div>
    )
}

export default CourseCard
