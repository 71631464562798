import React, { useState } from 'react'
import { useWelcome } from './hooks/useWelcome'
import ChangePersonalInfo from '../../Auth/Login/ChangePersonalInfo'
import { ONBOARDING_TYPE } from '@/constants/onboarding'
import ModalConfirmation from '@/components/ModalConfirmation'
import Onboarding from '@/components/Onboarding'
import CustomCargando from '@/components/CustomCargando'

import ExpandablePanel from './ExpandablePanel'
import Slider from './Slider/Slider'
import CourseCard from './CourseCard/CourseCard'
import WelcomeHeader from './Header/WelcomeHeader'
import EmptyState from './EmptyState'
import CourseCardSkeleton from './CourseCard/CourseCardSkeleton'

import './Welcome.scss'

const Welcome = () => {
    const {
        confirmModal,
        finishedCourses,
        hasEnrolments,
        hasFinishedCourses,
        isMobile,
        lang,
        loading,
        mustChangePassword,
        onGoingCourses,
        setConfirmModal,
        showGreeting,
        starredCourses,
        thereIsComingLesson,
        updateUserPersonalInfo,
        isOnSiteLessonStarted,
        formatedDate,
        nextOnSiteCourseName,
        user,
        onSiteLessonLink,
        goToLiveClass,
        handleNextPage
    } = useWelcome()

    const {
        coursesTitleEmptyState,
        coursesSubtitleEmptyState,
        starredSection,
        starredSectionMessage,
        onGoingSection,
        finishedSection,
    } = lang.welcome

    const [coursesExpanded, setCoursesExpanded] = useState(true)
    const [finishedExpanded, setFinishedExpanded] = useState(false)
    const starredExpanded = !!starredCourses.courses.length

    if (mustChangePassword) {
        return (
            <ChangePersonalInfo
                data-testid='change-personal-info'
                user={user}
                submit={updateUserPersonalInfo}
            />
        )
    }

    const courseCards = (courses, showSkeleton = false) => {
        return courses.map((course) => {
            if (loading.setStarredCourse && showSkeleton) return <CourseCardSkeleton />
            return <CourseCard course={course} setConfirmModal={setConfirmModal} />
        })
    }

    return (
        <div className='container'>
            {showGreeting && (
                <WelcomeHeader
                    lang={lang.greeting}
                    loggedUserName={user.name}
                    formatedDate={formatedDate}
                    isOnSiteLessonStarted={isOnSiteLessonStarted}
                    nextOnSiteCourseName={nextOnSiteCourseName}
                    onSiteLessonLink={onSiteLessonLink}
                    thereIsComingLesson={thereIsComingLesson}
                    goToLiveClass={goToLiveClass}
                />
            )}
            { loading.loadAllStudentEnrolments ?
                <div data-testid='welcome-loading'>
                    <CustomCargando />
                </div>
                :
                <>
                    <ExpandablePanel
                        className='welcome-panel-section'
                        title={`${starredSection} (${starredCourses.pagination.totalCount})`}
                        description={!starredCourses.courses.length && starredSectionMessage}
                        theme='marked'
                        isExpanded={starredExpanded}
                    >
                        <Slider
                            id='starredCourses'
                            showFooter={!isMobile}
                            items={courseCards(starredCourses.courses, true)}
                            isExpanded={starredExpanded}
                            pagination={starredCourses.pagination}
                            handleNextPage={() => handleNextPage('starredCourses')}
                        />
                    </ExpandablePanel>

                    <ExpandablePanel
                        className='welcome-panel-section'
                        title={`${onGoingSection} (${onGoingCourses.pagination.totalCount})`}
                        theme='highlight'
                        enableExpand={hasEnrolments}
                        isExpanded={coursesExpanded}
                        onClickPanel={() => setCoursesExpanded(!coursesExpanded)}
                    >
                        <EmptyState
                            title={coursesTitleEmptyState}
                            subtitle={coursesSubtitleEmptyState}
                            show={!hasEnrolments}
                        />
                        <Slider
                            id='onGoingCourses'
                            showFooter={!isMobile}
                            items={courseCards(onGoingCourses.courses)}
                            isExpanded={coursesExpanded}
                            pagination={onGoingCourses.pagination}
                            handleNextPage={() => handleNextPage('ongoingCourses')}
                        />
                    </ExpandablePanel>

                    <ExpandablePanel
                        className='welcome-panel-section'
                        title={`${finishedSection} (${finishedCourses.pagination.totalCount})`}
                        enableExpand
                        isExpanded={finishedExpanded}
                        onClickPanel={() => setFinishedExpanded(!finishedExpanded)}
                        showPanel={hasFinishedCourses}
                    >
                        <Slider
                            id='finishedCourses'
                            showFooter={!isMobile}
                            items={courseCards(finishedCourses.courses)}
                            isExpanded={finishedExpanded}
                            pagination={finishedCourses.pagination}
                            handleNextPage={() => handleNextPage('finishedCourses')}
                        />
                    </ExpandablePanel>
                    <Onboarding step={ONBOARDING_TYPE.FIRST_TIME} />
                    <ModalConfirmation {...confirmModal} />
                </>
            }
        </div>
    )
}

export default Welcome
