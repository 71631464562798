import { enableShowCompleteDate } from '@/helpers/granters/courseTypeRules'

const MAX_COURSE_NAME_CHARS = 50

const nextOnSite = (course) => {
    const { id, alias, name, lesson, CourseType, live_class_url } = course
    const enableShowCompleteDateRule = enableShowCompleteDate(CourseType)
    const nextOnSiteCourseName =
        (alias || name).length > MAX_COURSE_NAME_CHARS
            ? (alias || name).slice(0, MAX_COURSE_NAME_CHARS) + '...'
            : alias || name
    return [id, nextOnSiteCourseName, lesson.nextOnSite, enableShowCompleteDateRule, live_class_url]
}

export default nextOnSite
