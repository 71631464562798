import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as env from '@/config/env'
import { loadEggWidgetToken } from '@/redux/thunks/student/classroom'
import { getUser } from '../../../redux/selectors/auth'
import { getTenantLang } from '@/helpers/tenants/helpers'

export default (courseId, showEgg) => {
    const dispatch = useDispatch()
    const [eggWidgetLoaded, setEggWidgetLoaded] = useState(false)
    const { id } = useSelector(getUser)

    const getEggWidgetToken = (widget) => {
        dispatch(loadEggWidgetToken(id, courseId)).then((token) => {
            setEggWidgetLoaded(true)
            widget.launch('eggContainer', token, getTenantLang())
        })
    }

    const loadEggScript = () => {
        const script = document.createElement('script')
        script.src = env.EGG_SCRIPT_URL
        script.async = true
        document.body.appendChild(script)
        script.onload = () => getEggWidgetToken(window.EGG_WIDGET)
        return script
    }

    useEffect(() => {
        if (showEgg) {
            const eggScript = loadEggScript()
            return () => eggScript.remove()
        }
    }, [courseId, showEgg])

    return {
        eggWidgetLoaded,
    }
}
