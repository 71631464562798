import React from 'react'
import './GradesList.scss'
import Table from '@digitalhouse-tech/react-lib-ui-explorer/lib/Table'
import GradesModal from './components/GradesModal/GradesModal'
import PublishModal from './components/PublishModal/PublishModal'
import UsersSearch from './components/UsersSearch/UsersSearch'
import Banner from './components/Banner'
import Container from '@digitalhouse-tech/react-lib-ui-explorer/lib/Container'
import Footer from './components/Footer/Footer'
import LoadingOrContent from '@digitalhouse-tech/react-lib-ui-explorer/lib/LoadingOrContent'
import useGradeList from './hooks/useGradeList'
import { GRADES_SECTION } from '../../shared/constants/dashboard'
import DashboardHeader from '../../shared/components/DashboardHeader/DashboardHeader'
import useCourseInfo from '../../shared/hooks/useCourseInfo'
import TableStudentItem from './components/TableStudentItem/TableStudentItem'
import EmptyState from '../../shared/components/EmptyState/EmptyState'
import { emptyStateAsset } from '@/assets/img/config/index'

const TableEmptyState = React.memo(({ data, lang }) => {
    return (
        !data.length && (
            <div className='grades-list__empty-box'>
                <span>{lang.table.emptyState}</span>
            </div>
        )
    )
})

const GradesList = ({ match }) => {
    const courseId = match.params.courseId

    const { name, alias, assessment_strategy_id: strategyId } = useCourseInfo(courseId)

    const {
        count,
        existData,
        tableHeaders,
        grades,
        showModal,
        openModal,
        closeModal,
        error,
        textToFilter,
        setTextToFilter,
        lang,
        loading,
        sendUpdatedGradebook,
        gradesFiles,
        handleGradesFiles,
        getMessageBoxText,
        emptyMessage,
        lastPublishDate,
        handleDownloadGrades,
        step,
        openPublishModal,
    } = useGradeList(strategyId, courseId)

    return (
        <Container>
            <DashboardHeader courseName={name} courseAlias={alias} section={GRADES_SECTION} />
            <GradesModal
                {...{
                    showModal,
                    closeModal,
                    sendUpdatedGradebook,
                    error,
                    gradesFiles,
                    handleGradesFiles,
                    existData,
                    getMessageBoxText,
                }}
                lang={lang.uploadModal}
                loading={loading?.updateGradebook}
            />
            <PublishModal {...{ courseId }} />
            <LoadingOrContent loading={loading?.getGradesList}>
                <EmptyState
                    id='gradesEmptyState'
                    imgUrl={emptyStateAsset('default-no-results.svg')}
                    text={emptyMessage()}
                    hidden={count}
                />
                {!!count && (
                    <>
                        <Banner
                            lang={lang.banner}
                            {...{ lastPublishDate, handleDownloadGrades, openPublishModal, step }}
                        />
                        <Table
                            headers={tableHeaders}
                            containerClassName='grades-list__table'
                            data={grades}
                            leftHeaderComponent={
                                <UsersSearch
                                    value={textToFilter}
                                    onChange={setTextToFilter}
                                    placeholder={lang.table.searcher.replace('{{STUDENTS}}', count)}
                                />
                            }
                            leftItemComponent={({ user }) => <TableStudentItem {...{ user }} />}
                        />
                        <TableEmptyState data={grades} {...{ lang }} />
                        <Footer {...{ openModal, lang }} />
                    </>
                )}
            </LoadingOrContent>
        </Container>
    )
}

export default GradesList
