import { getAll, update } from '../http/services/changePaymentService'
import { setLoading } from '../reducers/loadingReducer/index'
import { setPaymentMethods } from '../reducers/_deprecated/authReducer/index'

export const getPaymentMethods = () => async (dispatch, getState) => {
    dispatch(setLoading.true('changePayment.getPaymentMethods'))
    const [error, response] = await getAll()
    dispatch(setLoading.false('changePayment.getPaymentMethods'))
    if(!error) {
        dispatch(setPaymentMethods(response))
    }
    return [error, response]
}

export const updatePaymentMethod = (paymentMethod, xuow) => async (dispatch, getState) => {
    dispatch(setLoading.true('changePayment.updatePaymentMethod'))
    const walletId = paymentMethod.id
    const [error, response] = await update(walletId, paymentMethod, xuow)
    dispatch(setLoading.false('changePayment.updatePaymentMethod'))
    return [error, response]
}