import React from 'react'
import PropTypes from 'prop-types'
import StudentItem from '@digitalhouse-tech/react-lib-ui-explorer/lib/StudentItem'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import StatusInfo from '@digitalhouse-tech/react-lib-ui-explorer/lib/StatusInfo'
import './ListItem.scss'

const ListItem = ({
    avatar,
    cta,
    ctaId,
    ctaLineAppearence,
    ctaText,
    date,
    email,
    fullName,
    status,
    statusDescription,
    statusIcon,
    statusTheme,
}) => {
    return (
        <div className='list-item-panel'>
            <div className='list-item-panel__content'>
                <div className='list-item-panel__content-student'>
                    <StudentItem avatar={avatar} fullName={fullName} email={email} ellipsis />
                </div>
                <div className='list-item-panel__content-date'>
                    <p>{status}</p>
                    <p>{date}</p>
                </div>
                <div className='list-item-panel__content-status'>
                    <StatusInfo text={statusDescription} theme={statusTheme} icon={statusIcon} />
                </div>
            </div>
            <div className='list-item-panel__button'>
                <Button
                    line={ctaLineAppearence}
                    onClick={cta}
                    text={ctaText}
                    id={ctaId}
                />
            </div>
        </div>
    )
}

ListItem.propTypes = {
    avatar: PropTypes.string,
    cta: PropTypes.func,
    ctaId: PropTypes.string,
    ctaLineAppearence: PropTypes.bool,
    ctaText: PropTypes.string,
    date: PropTypes.string,
    email: PropTypes.string,
    fullName: PropTypes.string,
    status: PropTypes.string,
    statusDescription: PropTypes.string,
    statusIcon: PropTypes.string,
    statusTheme: PropTypes.string,
}

ListItem.defaultProp = {
    avatar: null,
    cta: () => {},
    ctaId: '',
    ctaLineAppearence: false,
    ctaText: '',
    date: '',
    email: '',
    fullName: '',
    status: '',
    statusDescription: '',
    statusIcon: '',
    statusTheme: '',
}

export default ListItem
