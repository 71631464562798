import React from 'react'
import { connect } from 'react-redux'

import moment from "moment"
import Select from 'react-select'
import DatePicker from "react-datepicker"

import { Modal } from 'react-bootstrap'
import { CustomFormField } from '@/components/CustomFormField'
import "react-datepicker/dist/react-datepicker.css"
import {
    closeModalSurveyAction,
    updateCoursesSurveysAction,
    updateCourseSurvey,
    createCourseSurvey
} from '../../../../redux/reducers/_deprecated/surveyReducer'
import ModalClonesConfirmation from '@/components/ModalClonesConfirmation'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import { CREATE_SURVEY, EDIT_SURVEY_VISIBILITY } from '@/constants/coursePermissions'
import { getTenantLang } from '@/helpers/tenants/helpers'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

moment.locale(getTenantLang())

export class ModalSurvey extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            courseId: null,
            selectedOption: {},
            selection: { default: '', error: false, message: '' },
            dateSelection: { default: null, error: false, message: '' },
            showConfirmation: false,
            display: 'block',
            isCreate: false,
        }

        this.handleClose = this.handleClose.bind(this)
        this.showSurveysSelect = this.showSurveysSelect.bind(this)
        this.submitSurvey = this.submitSurvey.bind(this)
        this.handleVisibility = this.handleVisibility.bind(this)
        this.handleMandatory = this.handleMandatory.bind(this)
        this.handleReleaseDate = this.handleReleaseDate.bind(this)
        this.datePick = this.datePick.bind(this)
        this.changeSelect = this.changeSelect.bind(this)
        this.validateInfo = this.validateInfo.bind(this)
        this.openConfirmationModal = this.openConfirmationModal.bind(this)
        this.handleCancelConfirmation = this.handleCancelConfirmation.bind(this)
        this.visibilitySelectDefaultValue = this.visibilitySelectDefaultValue.bind(this)
        this.mandatorySelectDefaultValue = this.mandatorySelectDefaultValue.bind(this)
    }

    openConfirmationModal() {
        let date = this.props.courseSurvey.date
        let selectedSurvey = this.props.courseSurvey

        let validateForm = this.validateInfo(selectedSurvey, date)

        if (date) {
            date = moment(date).utc().format("YYYY-MM-DD")
        }

        this.props.courseSurvey.date = date

        if (validateForm) {
            this.setState(state => ({
                ...state,
                showConfirmation: true,
                display: 'none'
            }))
        }
    }

    handleConfirmationClone() {
        this.setState(state => ({
            ...state,
            showConfirmation: false,
        }))

    }

    handleCancelConfirmation() {
        this.setState({
            showConfirmation: false,
            display: 'block',
            isCreate: false,
        },
            // () => {
            //   this.props.displayModalTopicAction()
            // }
        )
    }

    handleClose() {

        this.setState((state, props) => ({
            ...state,
            courseId: null,
            selectedOption: {},
            selection: { default: '', error: false, message: '' },
            dateSelection: { default: null, error: false, message: '' },
            isCreate: false,
        }))

        this.props.closeModalSurvey()
    }

    changeSelect(surveyData) {

        let surveyId = surveyData.value
        let courseSurveyData = {
            nameSurvey: this.props.courseSurvey.nameSurvey,
            courseId: this.props.courseId,
            id: this.props.courseSurvey.id,
            date: this.props.courseSurvey.date,
            visibility: this.props.courseSurvey.visibility,
            mandatory: this.props.courseSurvey.mandatory,
            surveyId: surveyId,
            base: this.props.base
        }
        this.props.updateCourseSurveyData(courseSurveyData)
        this.setState({...this.state, isCreate: true})
    }

    visibilitySelectDefaultValue() {
        if (this.props.courseSurvey.surveyId) {
            if (this.props.courseSurvey.visibility) {
                return this.props.lang.yes
            } else {
                return this.props.lang.no
            }
        }

        return this.props.courseSurvey.visibility ? this.props.lang.yes : this.props.lang.no

    }

    mandatorySelectDefaultValue() {
        if (this.props.courseSurvey.surveyId) {
            if (this.props.courseSurvey.mandatory) {
                return this.props.lang.yes
            } else {
                return this.props.lang.no
            }
        }

        return this.props.courseSurvey.mandatory ? this.props.lang.yes : this.props.lang.no
    }

    showSurveysSelect() {
        if (this.props.courseSurvey.id) {
            return (
                <>
                    <Label text={this.props.lang.selectedSurveyLabel} />
                    <InputComboBox
                        inputName={this.props.lang.surveyLabel}
                        placeholder={this.props.courseSurvey.nameSurvey}
                        id="surveys"
                        disabled
                    />
                </>
            )
        }
        else {
            return <CustomFormField
                validationState={this.state.selection.error ? 'error' : null}
                validationMessage={this.state.selection.message}
                bsSize="small" controlId="survey"
                label={this.props.lang.surveyLabel}
                inputComponent={
                    <Select
                        name={this.props.lang.surveyLabel}
                        placeholder={this.props.lang.selectSurvey}
                        id="surveys"
                        key="surveys"
                        options={this.props.allSurveys}
                        onChange={this.changeSelect}
                        isSearchable={true}
                        autoFocus
                    ></Select>
                } />
        }
    }

    datePick() {

        if (!this.props.courseSurvey.date || this.props.courseSurvey.date === '') {
            return null
        } else {
            return moment(this.props.courseSurvey.date).utc()
        }
    }

    handleVisibility(selectedValue) {
        let newCheck = selectedValue === this.props.lang.yes
        let courseSurveyData = {
            nameSurvey: this.props.courseSurvey.nameSurvey,
            courseId: this.props.courseId,
            id: this.props.courseSurvey.id,
            date: this.props.courseSurvey.date,
            visibility: newCheck,
            mandatory: this.props.courseSurvey.mandatory,
            surveyId: this.props.courseSurvey.surveyId,
            base: this.props.base
        }

        this.props.updateCourseSurveyData(courseSurveyData)
        this.visibilitySelectDefaultValue()
    }

    handleMandatory(selectedValue) {
        let newMandatory = selectedValue === this.props.lang.yes
        let courseSurveyData = {
            nameSurvey: this.props.courseSurvey.nameSurvey,
            courseId: this.props.courseId,
            id: this.props.courseSurvey.id,
            date: this.props.courseSurvey.date,
            visibility: this.props.courseSurvey.visibility,
            mandatory: newMandatory,
            surveyId: this.props.courseSurvey.surveyId,
            base: this.props.base
        }
        this.props.updateCourseSurveyData(courseSurveyData)
        this.mandatorySelectDefaultValue()
    }

    handleReleaseDate(date) {
        let courseSurveyData = {
            nameSurvey: this.props.courseSurvey.nameSurvey,
            courseId: this.props.courseId,
            id: this.props.courseSurvey.id,
            date: date,
            visibility: this.props.courseSurvey.visibility,
            mandatory: this.props.courseSurvey.mandatory,
            surveyId: this.props.courseSurvey.surveyId,
            base: this.props.base
        }
        this.props.updateCourseSurveyData(courseSurveyData)
    }

    validateInfo(selectedSurvey, date) {

        let formOk = true

        let selectionValue = { error: false, message: '' }
        let selectionDateValue = { error: false, message: '' }
        let allSurveysData = this.props.allCourseSurveys.data

        if (selectedSurvey.surveyId == null || selectedSurvey.surveyId == '') {
            selectionValue.error = true
            selectionValue.message = this.props.lang.requiredSurveyMessage
            formOk = false
        }

        if (selectedSurvey.visibility) {

            if (date !== null && date !== '') {

                let dateAux = moment(date).utc().format("DD/MM/YYYY")

                allSurveysData.forEach(survey => {

                    if (moment(survey.date).utc().format("DD/MM/YYYY") == dateAux && selectedSurvey.id !== survey.id && selectedSurvey.visibility == survey.visibility) {

                        selectionDateValue.error = true
                        selectionDateValue.message = this.props.lang.surveyAreadySetWarning
                        formOk = false
                    }
                })
            }

        }

        this.setState({
            ...this.state,
            selection: selectionValue,
            dateSelection: selectionDateValue
        })

        return formOk
    }

    submitSurvey() {
        let date = this.props.courseSurvey.date
        let selectedSurvey = this.props.courseSurvey

        let validateForm = this.validateInfo(selectedSurvey, date)

        let students = this.props.allCourseStudents.map(student => {
            return student.id
        })

        if (date) {
            date = moment(date).utc().format("YYYY-MM-DD")
        }

        this.props.courseSurvey.date = date

        if (validateForm) {
            if (this.props.courseSurvey.id == null) {
                this.props.createCourseSurvey(this.props.courseSurvey, this.props.allCourseSurveys, students)
            } else {
                this.props.updateCourseSurvey(this.props.courseSurvey, this.props.allCourseSurveys)
            }
            this.setState(state => ({
                ...state,
                showConfirmation: false,
                display: 'block',
                isCreate: false,
            }))
        }

    }

    showVisibility() {
        const { courseSurvey } = this.props
        if (!courseSurvey.id) return CREATE_SURVEY()

        return EDIT_SURVEY_VISIBILITY()
    }

    render() {

        return (

            <React.Fragment>
                <Modal style={{ display: this.state.display }} className="__modal" bsSize="large" show={this.props.show} onHide={this.handleClose} keyboard={false} backdrop="static">
                    <Modal.Header closeButton className="__modal_header __border_bottom">
                        <h3 className="__modal_title">{this.props.courseSurvey.surveyId && !this.state.isCreate ? this.props.lang.editTitle : this.props.lang.modalTitle}</h3>
                    </Modal.Header>

                    <Modal.Body className="__modal_body">
                        <Row style={{ marginBottom: '20px' }}>
                            <Col md="6">
                                {this.showSurveysSelect()}
                            </Col>

                            <Col md="6">
                                <CustomFormField
                                    validationState={this.state.dateSelection.error ? 'error' : null}
                                    validationMessage={this.state.dateSelection.message}
                                    bsSize="small" controlId="date"
                                    label={this.props.lang.initialDateLabel} inputComponent={
                                        <DatePicker
                                            className="form-control input-sm datePickerWidth"
                                            dateFormat="DD/MM/YYYY"
                                            minDate={moment()}
                                            selected={this.datePick()}
                                            onChange={this.handleReleaseDate}
                                            placeholderText={this.props.lang.selectDate}
                                        />
                                    } />
                            </Col>
                        </Row>

                        <Row className='_margin_bottom_25'>
                            {this.showVisibility() &&
                                <Col md="6">
                                    <Label text={this.props.lang.isVisibleLabel} />
                                    <InputComboBox
                                        inputName="visibility"
                                        placeholder={this.props.lang.select}
                                        id="visibility"
                                        dropdownItems={[this.props.lang.yes, this.props.lang.no]}
                                        onChange={this.handleVisibility}
                                        value={this.visibilitySelectDefaultValue()}
                                    />
                                </Col>
                            }

                            <Col md="6">
                                <Label text={this.props.lang.isMandatoryLabel} />
                                <InputComboBox
                                    inputName="mandatory"
                                    placeholder={this.props.lang.select}
                                    id="mandatory"
                                    dropdownItems={[this.props.lang.yes, this.props.lang.no]}
                                    onChange={this.handleMandatory}
                                    value={this.mandatorySelectDefaultValue()}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer className="__modal_footer __border_top">
                        <ButtonGroup className="pull-right">
                            <Button
                                id="handleCloseButton"
                                theme="tertiary"
                                onClick={this.handleClose}
                                text={this.props.lang.cancelButton}
                            />
                            <Button
                                id="handleSubmitButton"
                                onClick={(this.props.base && this.props.count > 0) ? this.openConfirmationModal : this.submitSurvey}
                                text={this.props.lang.saveButton}
                            />
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>
                <ModalClonesConfirmation
                    show={this.state.showConfirmation}
                    confirmTitle={this.props.lang.confirmTitle}
                    cancelTitle={this.props.lang.cancelTitle}
                    onConfirm={() => { this.submitSurvey() }}
                    onCancel={this.handleCancelConfirmation}
                    body={`${this.props.lang.modalBodyWarning} ${this.props.count} ${this.props.lang.modalBodyCurses}. ${this.props.lang.modalBodyWarningConfirmation}`}
                />
            </React.Fragment>
        )
    }
}

const mapDispatch = (dispatch) => ({
    closeModalSurvey: () => {
        dispatch(closeModalSurveyAction())
    },
    updateCourseSurveyData: (data) => {
        dispatch(updateCoursesSurveysAction(data))
    },
    updateCourseSurvey: (data, allCoursesSurveys) => {
        dispatch(updateCourseSurvey(data, allCoursesSurveys))
    },
    createCourseSurvey: (data, allCoursesSurveys, students) => {
        dispatch(createCourseSurvey(data, allCoursesSurveys, students))
    }
})

const mapStateToProps = (state, ownProps) => {
    return {
        show: state.surveyReducer.show,
        modalTitle: state.surveyReducer.modalTitle,
        courseSurvey: state.surveyReducer.courseSurvey,
        allCourseSurveys: state.surveyReducer.allCourseSurveys,
        allSurveys: state.surveyReducer.allSurveys,
        allCourseStudents: state.userReducer.allCourseStudents.students,
        lang: state.langStore.modalSurvey
    }
}

export default connect(mapStateToProps, mapDispatch, null, { forwardRef: true })(ModalSurvey)
