import React, { useMemo } from 'react'
import {debounce} from 'lodash'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { ArrowLeftCircle, ArrowRightCircle, ArrowLeft } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import useTopicNav from './useTopicNav'
import './BlockNav.scss'

const DEBOUNCE_WAIT = 200

const BlockNav = ({ blocks, currentBlock, onlyArrows, setCurrentBlock }) => {

    const {
        handleForward: handleNextTopic,
        handleGoBack: handlePrevTopic,
        hasNext,
        hasActiveQuiz,
    } = useTopicNav({
        isMobile: true,
        blockId: currentBlock && currentBlock.id
    })

    const handleGoBack = () => {
        const prevBlock = blocks[currentBlock.index - 1]

        if (prevBlock && !hasActiveQuiz) {
            setCurrentBlock(prevBlock)
        } else {
            handlePrevTopic({ showLastBlock: true })
        }
    }
    
    const handleGoNext = () => {
        const nextBlock = blocks[currentBlock.index + 1]

        if (nextBlock) {
            setCurrentBlock(nextBlock)
        } else {
            handleNextTopic()
        }
    }

    const isLastBlock = useMemo(() => {
        return currentBlock.index === blocks.length - 1
    }, [currentBlock, blocks])

    const steps = []

    for (let index = 0; index < blocks.length; index++) {
        steps.push(
            <span
                key={index}
                className={cn('block-nav-container__step-counter__step', {
                    'block-nav-container__step-counter__step--active':
                        currentBlock && currentBlock.index === index,
                })}
            />,
        )
    }

    return (
        <div
            className={cn('block-nav-container', {
                'block-nav-container--active-quiz': hasActiveQuiz,
            })}
        >
            {!hasActiveQuiz && !onlyArrows && <div className="block-nav-container__step-counter">{steps}</div>}

            <div className="block-nav-container__nav">
                <Button
                    id="BlockNavPrevButton"
                    icon={
                        hasActiveQuiz ? (
                            <ArrowLeft className="block-nav-container__icon" />
                        ) : (
                            <ArrowLeftCircle className="block-nav-container__icon" />
                        )
                    }
                    theme="clear"
                    onClick={debounce(handleGoBack, DEBOUNCE_WAIT)}
                />

                {currentBlock && !onlyArrows && currentBlock.name}

                <Button
                    id="BlockNavNextButton"
                    icon={<ArrowRightCircle className="block-nav-container__icon" />}
                    theme="clear"
                    onClick={debounce(handleGoNext, DEBOUNCE_WAIT)}
                    hidden={hasActiveQuiz || (!hasNext && isLastBlock)}
                />
            </div>
        </div>
    )
}

BlockNav.propTypes = {
    blocks: PropTypes.array,
    currentBlock: PropTypes.object.isRequired,
    onlyArrows: PropTypes.bool,
}

BlockNav.defaultProps = {
    blocks: [],
    onlyArrows: false,
}

export default BlockNav
