import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './CourseProgress.scss'
import { Popover, Overlay } from 'react-bootstrap'

import { history } from '@/app/store'
import { loadCourseProgress, clearCourseProgressAction } from '@/redux/reducers/_deprecated/courseReducer'
import CustomCargando from '@/components/CustomCargando'
import { RefreshCw } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import '../shared.scss'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'

export class CourseProgress extends React.Component {

    constructor() {
        super()
        this.attachRef = target => this.setState({ target })
        this.state = {
            show: false,
            title: '',
            content: '',
            target: null
        }

        this.changeColor = this.changeColor.bind(this)
        this.getHeaders = this.getHeaders.bind(this)
        this.getAverages = this.getAverages.bind(this)
        this.getStudentsProgress = this.getStudentsProgress.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleGoBack = this.handleGoBack.bind(this)
        this.getIconClasses = this.getIconClasses.bind(this)
        this.setLoadingClass = this.setLoadingClass.bind(this)
    }

    componentDidMount() {
        if (!this.props.prework) {
            this.props.clearCourseProgressAction()
            this.props.loadCourseProgress(this.props.match.params.courseId)
        }
    }

    handleGoBack() {
        history.goBack()
    }

    handleClick(e, title, content, show) {
        let { target } = e

        this.setState(state => ({
            ...state,
            target,
            show: show ? show : !state.show,
            title,
            content
        }))
    }

    changeColor(value, topicsWithError) {
        let classColor = ''

        if (value == 0) {
            if (topicsWithError > 0) {
                classColor = this.props.progressStyles.danger.color
            } else {
                classColor = ''
            }
        } else if (value < 40) {
            classColor = this.props.progressStyles.danger.color
        } else if (value < 80) {
            classColor = this.props.progressStyles.warning.color
        } else {
            classColor = this.props.progressStyles.success.color
        }

        return classColor
    }

    getIconClasses() {
        let classes = 'fa-fw'

        if (this.props.reloadingProgress) {
            classes += ' fa-spin'
        }

        return classes
    }

    setLoadingClass() {
        let classes = ''
        if (this.props.reloadingProgress) {
            classes += '_loading_progress'
        }

        return classes
    }

    getHeaders() {
        if (this.props.progressLoaded && this.props.courseProgress) {
            let unitColor = true
            return (
                this.props.courseProgress.Units.map((singleUnit, x) => {
                    if (singleUnit.Lessons.length > 0 && singleUnit.visibility) {
                        unitColor = !unitColor
                        return (
                            <div className="__report_table_header" key={"headercolumn" + x + "td"}>
                                <ul className="__listReport _ul_container">
                                    {singleUnit.Lessons.map((singleLesson, i) => {
                                        if (singleLesson.visibility) {
                                            return (
                                                <li key={"headeritem" + i + "li"} className="_li_items">
                                                    <span
                                                        id={"headeritem" + i + "spanid"}
                                                        key={"headeritem" + i + "span"}
                                                        className="__dot"
                                                        style={{ backgroundColor: (unitColor ? this.props.progressStyles.active.color : 'var(--light-gray)') }}
                                                        onClick={(e) => this.handleClick(e, singleUnit.name, singleLesson.name, true)}
                                                    >
                                                    </span>
                                                </li>
                                            )
                                        }
                                    })}
                                </ul>
                            </div>
                        )
                    }
                })
            )
        } else {
            return null
        }
    }

    sortLessons(data) {
        let filteredData = data.filter(element => element != null)
        filteredData.sort((a, b) => a.lessonOrder - b.lessonOrder)

        return filteredData
    }

    getAverages() {
        if (this.props.progressLoaded) {
            return (
                this.props.courseProgress.globalLesson.map((singleUnit, x) => {
                    if (singleUnit && singleUnit.lessons.length > 0) {
                        let sortedLessons = this.sortLessons(singleUnit.lessons)
                        return (
                            <div className="__report_table_data" key={"averagecolumn" + x + "td"} >
                                <ul className="__listReport _ul_container">
                                    {sortedLessons.map((singleLesson, i) => {
                                        if (singleLesson) {
                                            let lessonPercent = (Math.round((singleLesson.percent / singleUnit.studentsQty) * 100) / 100 + '%')
                                            return (
                                                <li key={"averageitem" + i + "td"} className="_li_items">
                                                    <span
                                                        id={"averageitem" + i + "spanid"}
                                                        key={"averageitem" + i + "span"}
                                                        className="__dot"
                                                        style={{ backgroundColor: this.changeColor(singleLesson.percent / singleUnit.studentsQty) }}
                                                        onClick={(e) => this.handleClick(e, singleLesson.lessonName, lessonPercent, true)}
                                                    >
                                                    </span>
                                                </li>
                                            )
                                        }
                                    })}
                                </ul>
                            </div>
                        )
                    }
                })
            )
        } else {
            return null
        }
    }

    getStudentsProgress() {
        if (this.props.progressLoaded) {
            return (
                this.props.formattedProgressData.map((user, x) => {
                    return (
                        <div key={"studentprogressrow" + x + "tr"} className="__report_table_row">
                            <div className="__pinned __report_table_data">
                                {user.name + ' ' + user.surname}
                            </div>
                            {user.units.map((unit, z) => {
                                if (unit.lessons.length > 0) {
                                    return (
                                        <div className="__report_table_data" key={"studentprogresscolumn" + z + "td"} >
                                            <ul className="__listReport _ul_container">
                                                {unit.lessons.map((lesson, i) => {
                                                    let popoverContent = <div>{this.props.lang.allTopics} {lesson.topicsQty}<br />{this.props.lang.topicsCompleted} {lesson.completedTopics}<br /> {this.props.lang.topicsNotCompleted} {lesson.topicsNotCompleted}<br /> {this.props.lang.topicsWithErrors} {lesson.topicsWithError}<br /></div>
                                                    return (
                                                        <li key={"studentprogressitem" + i + "li"}>
                                                            <span
                                                                id={"studentprogressitem" + i + "spanid"}
                                                                key={"studentprogressitem" + i + "span"}
                                                                className='__dot'
                                                                style={{ backgroundColor: this.changeColor(lesson.lessonPercent, lesson.topicsWithError) }}
                                                                onClick={(e) => this.handleClick(e, lesson.lessonName, popoverContent, true)}
                                                            >
                                                            </span>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    )
                                }
                            })
                            }
                        </div >
                    )
                })
            )
        } else {
            return null
        }
    }


    render() {
        if (this.props.progressLoaded) {
            return (
                <div className="container">
                    {!this.props.progressReportError ?
                        <>
                            <div className={this.props.prework?"__mainHeaderPrework":"__main_header"}>
                                <Row>
                                    <Col lg="10">
                                        {this.props.prework ?
                                            <small className="__small">{this.props.courseProgress.name}</small>
                                            : <h1 className="__main_title">{this.props.courseProgress.name}</h1>}
                                    </Col>
                                </Row>
                            </div>
                            <div className={this.setLoadingClass()}>

                                {this.props.prework ? <h4 className="__progress_title_prework">{this.props.title}</h4> : null}
                                <Panel className="__card __fixedHeight">
                                    {this.props.reloadingProgress &&
                                        <div className="__reload_progress_spinner">
                                            <RefreshCw className="__progress_spinner" />
                                        </div>
                                    }

                                    <Row>
                                        <Col lg="12">
                                            <div className={this.props.prework ? "_scroll_prework" : "_scroll_x"}>
                                                <div className="__table_heigth">
                                                    <div className="__progress_table __table_container">
                                                        <header className="__report_table_head __table_item">
                                                            <div className="__report_table_row">
                                                                <div className="__corner __report_table_header">
                                                                </div>
                                                                {this.getHeaders()}
                                                            </div>
                                                        </header>

                                                        <div className="__report_table_body __table_item">
                                                            {!this.props.prework ? <div className="__report_table_row">
                                                                <div className="__pinned __report_table_data"><strong>{this.props.lang.generalAverage}</strong></div>
                                                                {this.getAverages()}
                                                            </div> : null}
                                                            {this.getStudentsProgress()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Panel>
                            </div>
                            <Overlay
                                show={this.state.show}
                                target={this.state.target}
                                trigger="click"
                                placement="top"
                                rootClose={true}
                                key={this.state.title}
                                onHide={(e) => {
                                    if (e.target.localName === "span") {
                                        return
                                    }
                                    this.setState(() => ({ show: false }))
                                }}
                            >
                                <Popover
                                    key={this.state.title}
                                    id={this.state.title}
                                    title={this.state.title}
                                >
                                    {this.state.content}
                                </Popover>
                            </Overlay>
                        </>
                        :
                        <div className="__main_header">
                            <Row>
                                <Col lg="6" lgOffset="3">
                                    <Panel className="__card __fixedHeight">
                                        <Row>
                                            <Col lg="12">
                                                <h3 className="">{this.props.lang.errorMessage}<small></small></h3>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Col>
                            </Row>
                        </div>
                    }
                    {this.props.prework ? null :
                        <Row>
                            <Col lg="12">
                                <ButtonGroup className="pull-right">
                                    <Button
                                        id="GoBackButton"
                                        onClick={this.handleGoBack}
                                        text={this.props.lang.goBackButton}
                                        theme='secondary'
                                    />
                                </ButtonGroup>
                            </Col>
                        </Row>}
                </div>
            )
        } else {
            return (
                <React.Fragment>
                    {this.props.courseReportError ?
                        <div className="__main_header">
                            <Row>
                                <Col lg="6" lgOffset="3">
                                    <Panel className="__card __fixedHeight">
                                        <Row>
                                            <Col lg="12">
                                                <h3 className="">{this.props.lang.errorMessage}<small></small></h3>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Col>
                            </Row>
                        </div>
                        :
                        <CustomCargando />
                    }
                </React.Fragment>
            )
        }
    }
}


const mapDispatch = dispatch => ({
    loadCourseProgress: (courseId) => dispatch(loadCourseProgress(courseId)),
    clearCourseProgressAction: () => dispatch(clearCourseProgressAction()),
})

const mapStateToProps = state => {
    return {
        // COURSE reducer
        courseProgress: state.courseReducer.courseProgressData.courseProgress,
        formattedProgressData: state.courseReducer.courseProgressData.formattedData,
        progressReportError: state.courseReducer.courseProgressData.progressReportError,
        courseProgressData: state.courseReducer.courseProgressData,
        progressLoaded: state.courseReducer.progressLoaded,
        reloadingProgress: state.courseReducer.reloadingProgress,
        courseReportError: state.courseReducer.courseReportError,
        // PROGRESS reducer
        progressStyles: {
            success: {
                color: 'var(--success)',
                icon: 'check'
            },
            danger: {
                color: 'var(--danger)',
                icon: 'faTimes'
            },
            warning: {
                color: 'var(--warning)',
                icon: 'warning'
            },
            default: {
                color: 'var(--white)'
            },
            active: {
                color: 'var(--common-text)'
            },
            extra: {
                icon: 'extra'
            }
        },
        // AUTH reducer
        lang: state.langStore.courseProgress
    }
}

export default withRouter(connect(mapStateToProps, mapDispatch)(CourseProgress))
