import React from 'react'
import PropTypes from 'prop-types'
import Spinner from '@digitalhouse-tech/react-lib-ui-explorer/lib/Spinner'
import './Terminal.scss'

const JavaTerminal = ({ start, content, logs, throws, random }) => {
    return (
        <div className='CodingTerminal'>
            <div className='header'>
                <ul>
                    <li>
                        {start && (
                            <div className='terminalLoader'>
                                <Spinner />
                            </div>
                        )}
                        Terminal
                    </li>
                </ul>
            </div>
            <div className='logArea'>
                <>
                    <span className='whiteLog'>Executing tasks ':classes :testClasses'</span>
                    <br />
                    <br />
                    <span className='whiteLog'>Starting Gradle Daemon...</span>
                    <br />
                    <span className='grayLog'>Gradle Daemon started </span>
                    <br />
                    <span className='grayLog'>&gt; Task :compileJava UP-TO-DATE </span>
                    <br />
                    <span className='grayLog'>&gt; Task :processResources UP-TO-DATE </span>
                    <br />
                    <span className='grayLog'>&gt; Task :classes UP-TO-DATE </span>
                    <br />
                    <span className='grayLog'>&gt; Task :compileTestJava </span>
                    <br />
                    <span className='grayLog'>&gt; Task :testClasses </span>
                    <br />
                    <span className='grayLog'>&gt; Task :processTestResources NO-SOURCE </span>
                    <br />
                    {[...logs].map((log, i) => (
                        <React.Fragment key={`log${i}`}>
                            <span className='whiteLog'>{log}</span>
                            <br />
                        </React.Fragment>
                    ))}
                    {[...throws].map((t, i) => (
                        <React.Fragment key={`throw${i}`}>
                            <span className='throwError'>ThrowError: {t}</span>
                            <br />
                        </React.Fragment>
                    ))}
                    <iframe
                        srcDoc={content}
                        className='frame'
                        key={random}
                        title='pg'
                        width='0px'
                        height='0px'
                        style={{ border: 'none' }}
                        sandbox='allow-forms allow-scripts allow-same-origin allow-modals allow-popups allow-presentation'
                    />
                </>
            </div>
        </div>
    )
}

JavaTerminal.defaultProps = {
    start: false,
    user: 'dh',
    content: '',
    logs: [],
    throws: [],
}

JavaTerminal.propTypes = {
    start: PropTypes.bool,
    user: PropTypes.string,
    content: PropTypes.string,
    logs: PropTypes.arrayOf(PropTypes.shape()),
    throws: PropTypes.arrayOf(PropTypes.shape()),
    random: PropTypes.number.isRequired,
}

export default JavaTerminal
