import {
    ADD_MESSAGE,
    DELETE_MESSAGE,
    DELETE_FIRST_MESSAGE
} from './alertsConstants'

export default {
    [ADD_MESSAGE]: payload => ({
        type: ADD_MESSAGE,
        payload
    }),
    [DELETE_MESSAGE]: payload => ({
        type: DELETE_MESSAGE,
        payload
    }),
    [DELETE_FIRST_MESSAGE]: () => ({
        type: DELETE_FIRST_MESSAGE,
        payload: null
    })
}