import { filterVisibleTopicsByLessons, hasOnlyStudentRole } from '@/helpers/blocks/filterVisibleContent'
import { LOGOUT_USER } from '../_deprecated/authReducer'

export const GET_CONTENT_MAP_START = 'GET_CONTENT_MAP_START'
export const GET_CONTENT_MAP_SUCCESS = 'GET_CONTENT_MAP_SUCCESS'
export const CLEAR_CONTENT_MAP = 'CLEAR_CONTENT_MAP'
export const GET_CONTENT_MAP_ERROR = 'GET_CONTENT_MAP_ERROR'

export const GET_TOPIC_CONTENT_START = 'GET_TOPIC_CONTENT_START'
export const GET_TOPIC_CONTENT_SUCCESS = 'GET_TOPIC_CONTENT_SUCCESS'
export const GET_TOPIC_CONTENT_ERROR = 'GET_TOPIC_CONTENT_ERROR'

export const UPDATE_BLOCK_PROGRESS = 'UPDATE_BLOCK_PROGRESS'
export const ADD_BLOCK_PROGRESS = 'ADD_BLOCK_PROGRESS'

export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU'
export const UPDATE_QUIZ_ANSWERS = 'UPDATE_QUIZ_ANSWERS'
export const CLEAR_QUIZ_ANSWERS = 'CLEAR_QUIZ_ANSWERS'

export const SET_PROGRESS_EXERCISE = 'SET_PROGRESS_EXERCISE'
export const UPDATE_PROGRESS_EXERCISE = 'UPDATE_PROGRESS_EXERCISE'

export const CLEAR_CLASSROOM_PROGRESS = 'CLEAR_CLASSROOM_PROGRESS'

const EMPTY_LIST = []

const initialState = {
    content_map: {
        loading: false,
    },
    topic_content: {
        loading: false,
        nextContent: {},
        prevContent: {},
        topic: {
            Blocks: EMPTY_LIST,
        },
    },
    progress: {
        blockProgress: [],
        quizProgress: {
            answers: EMPTY_LIST,
        },
        progressExercises: [],
    },
    toggleMobileMenu: true,
}

export const getContentMapStart = () => ({ type: GET_CONTENT_MAP_START })
export const getContentMapSuccess = data => ({ type: GET_CONTENT_MAP_SUCCESS, payload: data })
export const getContentMapError = error => ({ type: GET_CONTENT_MAP_ERROR, payload: error })
export const clearContentMap = error => ({ type: CLEAR_CONTENT_MAP })

export const getTopicContentStart = () => ({ type: GET_TOPIC_CONTENT_START })
export const getTopicContentSuccess = data => ({ type: GET_TOPIC_CONTENT_SUCCESS, payload: data })
export const getTopicContentError = error => ({ type: GET_TOPIC_CONTENT_ERROR, payload: error })

export const updateQuizAnswers = answers => ({ type: UPDATE_QUIZ_ANSWERS, payload: answers })
export const clearQuizAnswers = () => ({ type: CLEAR_QUIZ_ANSWERS })

export const setProgressExercise = (payload) => ({ type: SET_PROGRESS_EXERCISE, payload })
export const updateProgressExercise = (payload) => ({ type: UPDATE_PROGRESS_EXERCISE, payload })

export const updateBlockProgress = blockProgress => ({
    type: UPDATE_BLOCK_PROGRESS,
    payload: blockProgress,
})

export const addBlockProgress = blockProgress => ({
    type: ADD_BLOCK_PROGRESS,
    payload: blockProgress,
})

export const clearClassroomProgress = () => ({
    type: CLEAR_CLASSROOM_PROGRESS,
})

export const handleToggleMobileMenu = () => ({ type: TOGGLE_MOBILE_MENU })

const classRoomReducer = (state = initialState, { type, payload }) => {
    const actions = {
        [GET_CONTENT_MAP_START]: { ...state, content_map: { ...state.content_map, loading: true } },
        [GET_CONTENT_MAP_SUCCESS]: {
            ...state,
            content_map: { 
                ...state.content_map, 
                ...payload, 
                Lessons: hasOnlyStudentRole(payload) ? filterVisibleTopicsByLessons(payload?.Lessons) : payload?.Lessons, 
                loading: false 
            },
        },
        [GET_CONTENT_MAP_ERROR]: {
            ...state,
            error: payload,
            content_map: { ...state.content_map, loading: false },
        },
        [CLEAR_CONTENT_MAP]: {
            ...state,
            content_map: initialState.content_map
        },
        [GET_TOPIC_CONTENT_START]: {
            ...state,
            topic_content: { ...state.topic_content, loading: true },
        },
        [GET_TOPIC_CONTENT_SUCCESS]: {
            ...state,
            topic_content: { ...state.topic_content, ...payload, loading: false },
        },
        [GET_TOPIC_CONTENT_ERROR]: {
            ...state,
            error: payload,
            topic_content: { ...state.topic_content, loading: false },
        },

        [TOGGLE_MOBILE_MENU]: { ...state, toggleMobileMenu: !state.toggleMobileMenu },

        [UPDATE_QUIZ_ANSWERS]: {
            ...state,
            progress: {
                ...state.progress,
                quizProgress: { answers: payload },
            },
        },
        [CLEAR_QUIZ_ANSWERS]: {
            ...state,
            progress: {
                ...state.progress,
                quizProgress: { answers: EMPTY_LIST },
            },
        },

        [ADD_BLOCK_PROGRESS]: {
            ...state,
            progress: {
                ...state.progress,
                blockProgress: payload,
            },
        },

        [CLEAR_CLASSROOM_PROGRESS]: {
            ...state,
            progress: {
                ...state.progress,
                blockProgress: EMPTY_LIST,
            },
        },

        [UPDATE_BLOCK_PROGRESS]: () => {
            if (!payload) {
                return state
            }

            const idsToUpdate = payload.map(({ block_id }) => block_id)

            const blockProgresses = state.progress.blockProgress.filter(
                ({ block_id }) => !idsToUpdate.includes(block_id),
            )

            return {
                ...state,
                progress: {
                    ...state.progress,
                    blockProgress: [...blockProgresses, ...payload],
                },
            }
        },

        [SET_PROGRESS_EXERCISE]: () => {
            const progressExercises = state.progress.progressExercises.filter(
                ({ block_id }) => block_id !== payload.block_id
            )
            const currentProgressExercise = state.progress.progressExercises.find(
                ({ block_id }) => block_id === payload.block_id
            )
            /**
             * It's used when an exercise is edited without executing it 
             * and you want to navigate and go back and keep the changes
             */
            if (currentProgressExercise?.divergent && !payload?.forceUpdate) {
                return state
            }
            return {
                ...state,
                progress: {
                    ...state.progress,
                    progressExercises: [
                        ...progressExercises,
                        payload
                    ]
                }
            }
        },

        [UPDATE_PROGRESS_EXERCISE]: () => {
            const { progressExercises } = state.progress

            const progressExercise = progressExercises.find(
                ({ block_id }) => block_id === payload.block_id
            )

            const otherFiles = progressExercise.files.filter(file => file.name !== payload.name)

            const editingProgress = {
                ...progressExercise,
                files: [
                    ...otherFiles,
                    {
                        _id: payload._id,
                        content: payload.code,
                        section: payload.section,
                        name: payload.name,
                        language: payload.language
                    },
                ],
                divergent: payload.divergent
            }
            
            return {
                ...state,
                progress: {
                    ...state.progress,
                    progressExercises: [
                        ...progressExercises.filter(({ block_id }) => block_id !== payload.block_id),
                        editingProgress
                    ]
                }
            }
        },

        [LOGOUT_USER]: () => initialState
    }

    const action = actions[type]

    if ('function' === typeof action) {
        return action()
    }

    return actions[type] || state
}

export default classRoomReducer
