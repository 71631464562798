import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import ChoiceOption from './ChoiceOption'
import './ChoiceQuestion.scss'
import { SINGLE_CHOICE, MULTIPLE_CHOICE } from '../../constants'

const ChoiceQuestion = ({
    question,
    onUpdateAnswerSingleChoice,
    onUpdateAnswerMultipleChoice,
    studentAnswer,
    hasQuizComplete,
    showOptionFeedback,
    lang
}) => {
    const { options, type, id } = question.choiceQuestion
    const hasSomeImage = useMemo(() => options.some(({ imageUrl }) => !!imageUrl), [options])

    const onUpdateAnswerChoice = {
        [SINGLE_CHOICE]: onUpdateAnswerSingleChoice,
        [MULTIPLE_CHOICE]: onUpdateAnswerMultipleChoice,
    }[type]

    return (
        <div
            className={cn('choice-question-container', {
                'choice-question-container--image': hasSomeImage,
            })}
        >
            {options.map(({ id: optionId, statement, imageUrl, feedback, isCorrect }) => {
                const checked = studentAnswer?.options?.find(
                    (opt) => opt.optionId === optionId
                )?.selected
                return (
                    <ChoiceOption
                        optionId={optionId}
                        questionId={id}
                        key={optionId}
                        statement={statement}
                        imageUrl={imageUrl}
                        onUpdateAnswerChoice={onUpdateAnswerChoice}
                        type={type}
                        feedback={feedback}
                        isCorrect={isCorrect}
                        hasQuizComplete={hasQuizComplete}
                        checked={checked}
                        showOptionFeedback={showOptionFeedback}
                        lang={lang}
                    />
                )
            })}
        </div>
    )
}

ChoiceQuestion.propTypes = {
    question: PropTypes.object,
    onUpdateAnswerSingleChoice: PropTypes.func,
    onUpdateAnswerMultipleChoice: PropTypes.func,
    studentAnswer: PropTypes.object,
    hasQuizComplete: PropTypes.bool,
    showOptionFeedback: PropTypes.bool,
    lang: PropTypes.object,
}

export default ChoiceQuestion
