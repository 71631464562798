import React from 'react'
import { connect } from 'react-redux'
import RadioBtnCard from './RadioBtnCard'
import './RadioGrpCard.scss'
import { saveSurveyAnswer } from '@/redux/reducers/_deprecated/surveyReducer'

export class RadioGrpCard extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedIndex: null,
            selectedValue: null,
            options: this.props.options
        }

    }

    toggle(option) {

        this.setState({
            ...this.state,
            selectedValue: option
        })
        //Problemas de asincronismo, estamos mandando el valor anterior al que realment eestamos eligiendo. Pasar a Redux:
        //en answer pasamos option directamente y no el state de react
        let data = {
            survey_question_id: this.props.surveyQuestionId,
            answer: option,
            course_survey_user_id: this.props.surveyData.course_survey_user_id,
            survey_id: this.props.surveyData.survey_id
        }

        let surveyAnswers = this.props.surveyAnswers

        this.props.saveSurveyAnswer(data, surveyAnswers)
    }

    render() {
        const { options } = this.state;

        const allOptions = options.map((option, i) => {
            return (
                <RadioBtnCard
                    key={"radiobuttoncard" + i}
                    surveyQuestionId={this.props.surveyQuestionId}
                    selectedValue={this.state.selectedValue}
                    opt={option.option} item={i + (!this.props.scoreType ? 1 : 0)}
                    toggle={this.toggle.bind(this, (i + (!this.props.scoreType ? 1 : 0)))}
                />
            )
        });

        let lastOpt = this.props.options.length

        return (
            <div className="__cardRadioButtonBox">
                <ul className="__cardRadioButtonsGroup">{allOptions}</ul>
            </div>
        )
    }
}


const mapDispatch = (dispatch) => ({
    saveSurveyAnswer: (data, surveyAnswers) => {
        dispatch(saveSurveyAnswer(data, surveyAnswers))
    },
})

const mapStateToProps = (state, ownProps) => {
    return {
        surveyAnswers: state.surveyReducer.surveyAnswers,
        surveyByUser: state.surveyReducer.surveyByUser
    }
}

export default connect(mapStateToProps, mapDispatch, null, { forwardRef: true })(RadioGrpCard)
