import moment from 'moment-timezone'
import { capitalize } from '../strings'
import { getTenantLang } from '../tenants/helpers'

export const formatConfig = (
    date,
    dateFormat = 'DD/MM/YYYY',
    hourFormat = '',
    showCompleteDate = false
) => {
    const [tz, locale] = timezoneConfig()
    moment.updateLocale('es', {
        weekdaysShort : ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sab"]
    })
    moment.locale(locale)
    const momentDate = moment(date)
    return showCompleteDate
        ? capitalize(momentDate.tz(tz).format(`${dateFormat}${hourFormat}`))
        : capitalize(momentDate.tz(tz).format(`${dateFormat}`))
}

export const timezoneConfig = () => {
    // eslint-disable-next-line no-undef
    const locale = getTenantLang()
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'Etc/GMT+3'
    return [tz, locale]
}
