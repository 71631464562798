'use-strict'

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import RoleManagerList from './RoleManagerList'
import controller from '@/redux/reducers/_deprecated/roleReducer/controller'
import routing from '@/redux/reducers/_deprecated/roleReducer/routing'
import roleActions from '@/redux/reducers/_deprecated/roleReducer/actions'
import { returnButtonHideAction } from '@/redux/reducers/_deprecated/courseReducer'
import {
    CREATE_ROLE,
    EDIT_ROLES,
    DELETE_ROLES,
} from '@digitalhouse-tech/ts-lib-store/lib/session-storage/permission'

const RoleManagerListContainer = ({
    lang,
    generalSearcherLang,
    all,
    paginationConfig,
    loading,
    setCurrentPage,
    clearPaginatedRoles,
    clearAllRoles,
    clearRolePermissions,
    clearActiveRole,
}) => {
    React.useEffect(() => {
        clearActiveRole()
        clearAllRoles()
        returnButtonHideAction()
    }, [])

    return (
        <RoleManagerList
            lang={lang.list}
            paginated={all}
            currentPage={paginationConfig.page}
            handlers={{
                setCurrentPage: setCurrentPage,
                clearPaginated: clearPaginatedRoles,
                loadPaginated: controller.loadPaginatedRoles,
                clearRolePermissions: clearRolePermissions,
                deleteRole: controller.deleteRole
            }}
            routing={routing}
            generalSearcherLang={generalSearcherLang}
            loading={loading}
            showCreateRole={CREATE_ROLE()}
            showEditRole={EDIT_ROLES()}
            showDeleteRole={DELETE_ROLES()}
        />
    )
}

const mapDispatch = dispatch => ({
    setCurrentPage: page => {
        dispatch(roleActions.setRoleCurrentPage(page))
    },
    clearPaginatedRoles: () => {
        dispatch(roleActions.clearPaginatedRoles())
    },
    clearAllRoles: () => {
        dispatch(roleActions.clearAllRoles())
    },
    clearRolePermissions: () => {
        dispatch(roleActions.clearRolePermissions())
    },
    clearActiveRole: () => {
        dispatch(roleActions.clearActiveRole())
    },
})

const mapStateToProps = (state) => {
    return {
        lang: state.langStore.roles,
        generalSearcherLang: state.langStore.generalSearcher,
        all: state.roleManagerStore.all,
        paginationConfig: state.roleManagerStore.paginationConfig,
        loading: state.roleManagerStore.loading.loadPaginatedRoles,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatch)(RoleManagerListContainer))
