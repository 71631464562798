import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Modal from '@digitalhouse-tech/react-lib-ui-explorer/lib/Modal'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import { Award } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import FilesUploaderManager from '@/components/FilesUploaderManager'
import './GradesModal.scss'
import { SEND, UPLOAD } from '@/constants/grades'

const GradesModal = ({
    showModal,
    closeModal,
    lang,
    sendUpdatedGradebook,
    loading,
    error,
    gradesFiles,
    handleGradesFiles,
    getMessageBoxText,
}) => {
    const { title, text, theme } = getMessageBoxText()

    const [modalStep, setModalStep] = useState(UPLOAD)

    const isDisabled = loading || !gradesFiles.length

    const modalProps = {
        [UPLOAD]: {
            onMainAction: () => {},
            onSecondaryAction: closeModal,
            secondaryTextAction: lang.cancel,
            mainTextAction: lang.continue,
        },
        [SEND]: {
            onMainAction: () => sendUpdatedGradebook(gradesFiles[0].file),
            onSecondaryAction: () => {
                handleGradesFiles([])
                setModalStep(UPLOAD)
            },
            secondaryTextAction: lang.previusStep,
            mainTextAction: error ? lang.retry : lang.upgradeNotes,
            loadingTextMain: lang.buttonLoadingText,
        },
    }

    useEffect(() => {
        !gradesFiles.length ? setModalStep(UPLOAD) : setModalStep(SEND)
    }, [gradesFiles])

    return (
        <Modal
            showModal={showModal}
            loadingMain={loading}
            size='sm'
            modalTitle={
                <span className='grades-modal__title'>
                    <Award className='grades-modal__icon' />
                    <span>{lang.modalTitle}</span>
                </span>
            }
            onCloseAction={closeModal}
            disabledMain={isDisabled}
            {...modalProps[modalStep]}
        >
            {error && (
                <MessageBox theme={theme} className='grades-modal__message' {...{ title, text }} />
            )}

            {modalStep === UPLOAD && <p className='grades-modal__info'>{lang.uploadMessage}</p>}

            <FilesUploaderManager
                className={cn(
                    { 'grades-modal__file-uploader--limit': !!gradesFiles.length },
                    'grades-modal__file-uploader'
                )}
                title={lang.uploadTemplate}
                skeletonProps={{ fileNameWidth: '330px' }}
                files={gradesFiles}
                onChange={handleGradesFiles}
                acceptedFormats='.csv'
                maxUploadLimit={1}
                ignoreVaulty
                hideUploadButton={gradesFiles.length === 1}
            />
            {modalStep === SEND && <p className='grades-modal__send-message'>{lang.sendMessage}</p>}
        </Modal>
    )
}

GradesModal.propTypes = {
    showModal: PropTypes.bool,
    closeModal: PropTypes.func,
    lang: PropTypes.object.isRequired,
    sendUpdatedGradebook: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.string,
    gradesFiles: PropTypes.array,
    handleGradesFiles: PropTypes.func,
    getMessageBoxText: PropTypes.func,
}

GradesModal.defaultProps = {
    showModal: false,
    closeModal: () => {},
    sendUpdatedGradebook: () => {},
    loading: false,
    error: null,
    gradesFiles: null,
    handleGradesFiles: () => {},
    getMessageBoxText: () => {},
}

export default GradesModal
