import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import DashboardHeader from './../shared/components/DashboardHeader'
import { clearGeneralCourseInfo } from '@/redux/reducers/dashboardReducer'
import CourseReport from './CourseReport'
import CourseReportNotFound from './CourseReportNotFound'
import { GENERAL_PROGRESS_SECTION } from '../shared/constants/dashboard'
import useCourseInfo from '../shared/hooks/useCourseInfo'
import disabledSectionMassiveCourse from '../shared/utils/disabledSectionMassiveCourse'
import {
    refreshGeneralCourseInfo,
    getGeneralCourseInfo,
    downloadProgressReport,
} from '@/redux/thunks/dashboardThunks'

const CourseReportContainer = ({
    match,
    refreshCourseCache,
    lang,
    loadGeneralCourseInfo,
    clearGeneralCourseInfo,
    generalCourseInfo,
    downloadProgressReport,
    loading,
}) => {
    const [isMounted, setIsMounted] = useState(false)
    const courseId = match.params.courseId
    const { name, alias, is_massive, CourseType } = useCourseInfo(courseId)

    useEffect(() => {
        if (is_massive) disabledSectionMassiveCourse(is_massive)
    }, [is_massive])

    useEffect(() => {
        const loadData = async () => {
            await loadGeneralCourseInfo(courseId)
            setIsMounted(true)
        }
        loadData()
        return () => clearGeneralCourseInfo()
    }, [loadGeneralCourseInfo, courseId, clearGeneralCourseInfo])

    const contentLoaded = generalCourseInfo?.Course?.Units?.length > 0

    return (
        <>
            <div className='container'>
                <DashboardHeader
                    section={GENERAL_PROGRESS_SECTION}
                    courseName={name}
                    courseAlias={alias}
                />
            </div>
            {!isMounted || !contentLoaded ? (
                <CourseReportNotFound isMounted={isMounted} lang={lang} />
            ) : (
                <CourseReport
                    lang={lang}
                    refreshCourseCache={() => refreshCourseCache(courseId)}
                    courseDashboardInfo={generalCourseInfo}
                    downloadProgressReport={() => downloadProgressReport(courseId)}
                    loadingDownloadProgressReport={loading.downloadProgressReport}
                    courseType={CourseType}
                />
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    lang: state.langStore.courseReports.report,
    generalCourseInfo: state.dashboardReducer.generalCourseInfo,
    loading: state.loading.dashboard,
})

const mapDispatch = (dispatch) => ({
    refreshCourseCache: async (courseId) => {
        await dispatch(refreshGeneralCourseInfo(courseId))
        await dispatch(getGeneralCourseInfo(courseId))
    },
    loadGeneralCourseInfo: (courseId) => {
        return dispatch(getGeneralCourseInfo(courseId))
    },
    clearGeneralCourseInfo: () => {
        dispatch(clearGeneralCourseInfo())
    },
    downloadProgressReport: (courseId) => dispatch(downloadProgressReport(courseId))
})

CourseReportContainer.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            courseId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    refreshCourseCache: PropTypes.func.isRequired,
    downloadProgressReport: PropTypes.func.isRequired,
    loadGeneralCourseInfo: PropTypes.func.isRequired,
    clearGeneralCourseInfo: PropTypes.func.isRequired,
    lang: PropTypes.shape({
        filterByState: PropTypes.string.isRequired,
        lessonDescription: PropTypes.string.isRequired,
        topicDescription: PropTypes.string.isRequired,
        referenceTable: PropTypes.string.isRequired,
        updateCourse: PropTypes.string.isRequired,
        downloadReport: PropTypes.string.isRequired,
        students: PropTypes.string.isRequired,
        showReferenceTable: PropTypes.string.isRequired,
        hideReferenceTable: PropTypes.string.isRequired,
        total: PropTypes.string.isRequired,
        searchStudent: PropTypes.string.isRequired,
        onlineClass: PropTypes.string.isRequired,
        unitPrefix: PropTypes.string.isRequired,
        lessonPrefix: PropTypes.string.isRequired,
        topicPrefix: PropTypes.string.isRequired,
        course: PropTypes.string.isRequired,
        tabTitle: PropTypes.string.isRequired,
        scrollToLeft: PropTypes.string.isRequired,
        scrollToRight: PropTypes.string.isRequired,
        lastLogin: PropTypes.string.isRequired,
        noStudentsByState: PropTypes.string.isRequired,
        courseWithoutStudents: PropTypes.string.isRequired,
        courseWithoutContent: PropTypes.string.isRequired,
        return: PropTypes.string.isRequired,
        correctContentPercent: PropTypes.string.isRequired,
        viewedContentPercent: PropTypes.string.isRequired,
        blockNameTitle: PropTypes.string.isRequired,
        blockCompletePercent: PropTypes.string.isRequired,
        blockTypes: PropTypes.shape({
            TextBlock: PropTypes.string.isRequired,
            VideoBlock: PropTypes.string.isRequired,
            ExerciseBlock: PropTypes.string.isRequired,
            FileBlock: PropTypes.string.isRequired,
            PdfBlock: PropTypes.string.isRequired,
            QuizBlock: PropTypes.string.isRequired,
            SlideBlock: PropTypes.string.isRequired,
            TextHtmlBlock: PropTypes.string.isRequired,
            EvaluableBlock: PropTypes.string.isRequired,
        }).isRequired,
        extraBlock: PropTypes.string.isRequired,
        unitOnlyExtraLessons: PropTypes.string.isRequired,
        lesssonOnlyExtraTopics: PropTypes.string.isRequired,
        states: PropTypes.shape({
            UP_TO_DATE: PropTypes.string.isRequired,
            WITH_DELAY: PropTypes.string.isRequired,
            WITH_LONG_DELAY: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    generalCourseInfo: PropTypes.shape({
        Course: PropTypes.shape({
            Units: PropTypes.array,
            alias: PropTypes.string,
            finish_date: PropTypes.string,
            name: PropTypes.string,
            progress: PropTypes.shape({
                UP_TO_DATE: PropTypes.number.isRequired,
                WITH_DELAY: PropTypes.number.isRequired,
                WITH_LONG_DELAY: PropTypes.number.isRequired,
            }),
        }),
        Users: PropTypes.array,
    }),
}

export default withRouter(connect(mapStateToProps, mapDispatch)(CourseReportContainer))
