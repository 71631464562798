import { parseQueryStringToFilters } from '@/helpers/queryParams/queryParams';
import { useMemo } from 'react';

const useQueryParams = () => {
  const params = useMemo(() => {
    const queryParams = {
      module: '',
      lesson: '',
      topic: '',
      block: '',
      queryString: '',
    };

    if (typeof window === 'undefined') return queryParams;

    const queryParamsParsed = parseQueryStringToFilters(window.location.search);
    
    if (queryParamsParsed.module) {
      queryParams.module = queryParamsParsed.module;
    }
    if (queryParamsParsed.lesson) {
      queryParams.lesson = queryParamsParsed.lesson;
    }
    if (queryParamsParsed.topic) {
      queryParams.topic = queryParamsParsed.topic;
    }
    if (queryParamsParsed.block) {
      queryParams.block = queryParamsParsed.block;
    }
    
    return queryParams;
  }, []);

  return params;
};

export default useQueryParams;