import { useState } from 'react'
import * as forms from '../../helpers/constants'

export const useInputValidations = (formType, inputValue, inputConfirmValue, lang) => {
    const [errorMessage, setErrorMessage] = useState('')
    const [errorMessageConfirm, setErrorMessageConfirm] = useState('')

    const validateEmail = (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    const validateEmailForm = () => {
        setErrorMessage('')
        setErrorMessageConfirm('')
        let validation = true

        const value = inputValue.trim()
        const confirmValue = inputConfirmValue.trim()

        if (value === '') {
            setErrorMessage(lang.errorEmpty)
            validation = false
        } else if (!validateEmail(value)) {
            setErrorMessage(lang.invalidEmail)
            validation = false
        }

        if (confirmValue === '') {
            setErrorMessageConfirm(lang.errorEmpty)
            validation = false
        } else if (!validateEmail(confirmValue)) {
            setErrorMessageConfirm(lang.invalidEmail)
            validation = false
        } else if (value !== confirmValue) {
            setErrorMessageConfirm(lang.error)
            validation = false
        }
        return validation
    }

    const validatePasswordForm = () => {
        let validation = true

        if (inputValue === '') {
            setErrorMessage(lang.errorEmpty)
            validation = false
        }

        if (inputConfirmValue === '') {
            setErrorMessageConfirm(lang.errorEmpty)
            validation = false
        }

        if (inputValue.includes(' ')) {
            setErrorMessageConfirm(lang.invalidChars)
            validation = false
        }

        if (inputValue !== inputConfirmValue) {
            setErrorMessageConfirm(lang.errorEmpty)
            validation = false
        }

        return validation
    }

    const formValidity = () => {
        return {
            [forms.EMAIL_FORM]: () => validateEmailForm(),
            [forms.PASSWORD_FORM]: () => validatePasswordForm(),
        }[formType]()
    }

    return [errorMessage, errorMessageConfirm, formValidity]
}
