import React from 'react'
import moment from 'moment'
import cn from 'classnames'
import PropTypes from 'prop-types'
import ItemTitle from '@digitalhouse-tech/react-lib-ui-explorer/lib/ItemTitle'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import { Edit2, Copy } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'
import './ResultsItem.scss'

const ResultsItem = ({
    closedMessage,
    lang,
    onClickCopyExercise,
    onClickEditExercise,
    onCloseMessageBox,
    exercise,
    exerciseCreatedId,
    resultIndex,
    showEditButton,
    showCloneButton,
}) => {
    const editIcon = <Edit2 size={18} />
    const copyIcon = <Copy size={18} />
    const processDate = (date) => moment(date).format('DD/MM/YYYY')
    const itemGroup = cn(
        {
            sep: exercise.hasProgress || exercise.draft,
        },
        'exercise-item__box__group__data__item'
    )

    const boxClasses = cn(
        {
            'exercise-item__box--with-message': exerciseCreatedId,
            'exercise-item__box--with-message--closed': closedMessage,
            'exercise-item__box--draft': exercise.draft,
        },
        'exercise-item__box'
    )

    const issuedBoxGroupDetail = cn(
        {
            'issued__box__group__detail--draft': exercise.draft,
        },
        'issued__box__group__detail'
    )

    const itemTitle = cn(
        {
            'item-title--draft': exercise.draft,
        },
        'item-title'
    )

    const messageBoxClasses = cn(
        {
            'exercise-item__box__message--closed': closedMessage,
            'exercise-item__box__message--draft': exercise.draft,
        },
        'exercise-item__box__message'
    )

    return (
        <div className={boxClasses}>
            <div className='exercise-item__box__header'>
                <div className='exercise-item__box__group'>
                    <div className='exercise-item__box__group__data'>
                        <div className='exercise-item__box__group__data__item sep'>
                            <ItemTitle className={itemTitle} text={exercise.name} />
                            <span className='exercise-item__box__group__data__item--tags'>
                                {exercise.tags.map((tag) => (
                                    <span key={tag._id} className='item__tag'>
                                        {tag.name}
                                    </span>
                                ))}
                            </span>
                        </div>
                    </div>
                    <div className='exercise-item__box__group__data'>
                        <div className={itemGroup}>
                            <div className='issued__box'>
                                <div className='issued__box__group'>
                                    <span className='issued__box__group__title'>
                                        {`${lang.exerciseCreatedAt}:`}
                                    </span>
                                    <span className={issuedBoxGroupDetail}>
                                        {processDate(exercise.createdAt)}
                                    </span>
                                </div>
                                <div className='issued__box__group'>
                                    <span className='issued__box__group__title'>
                                        {`${lang.exerciseUpdatedAt}:`}
                                    </span>
                                    <span className={issuedBoxGroupDetail}>
                                        {processDate(exercise.updatedAt)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(exercise.hasProgress || exercise.draft) && (
                        <div className='exercise-item__box__group__data'>
                            <div className='exercise-item__box__group__data__item--center-v'>
                                <Tooltip
                                    tooltipId={exercise.hasProgress ? 'progressTooltip' : ''}
                                    tooltipContent={
                                        exercise.hasProgress ? lang.exerciseProgressTooltip : ''
                                    }
                                >
                                    <MessageBox
                                        text={
                                            exercise.hasProgress
                                                ? lang.exerciseItemProgress
                                                : lang.exerciseItemDraft
                                        }
                                        theme='warning'
                                        noFill
                                        fit
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    )}
                </div>
                <div className='exercise-item__box__group__buttons'>
                    {showEditButton && (
                        <ButtonRound
                            id={`exerciseBoEditExercise${resultIndex}Btn`}
                            icon={editIcon}
                            theme='primary_dark'
                            onClick={() => onClickEditExercise(exercise._id, exercise.exerciseType)}
                            className='exercise-item__box__group__buttons--first'
                            tooltipId='btn-edit-tooltip'
                            tooltipContent={lang.editExercise}
                        />
                    )}
                    {showCloneButton && (
                        <ButtonRound
                            id={`exerciseBoCopyExercise${resultIndex}Btn`}
                            icon={copyIcon}
                            theme='primary'
                            onClick={() => onClickCopyExercise(exercise._id, exercise.name)}
                            tooltipId='btn-copy-tooltip'
                            tooltipContent={lang.duplicateExercise}
                        />
                    )}
                </div>
            </div>
            {exerciseCreatedId && (
                <div className={messageBoxClasses}>
                    <MessageBox
                        text={lang.exerciseCreationSuccess}
                        fullWidth
                        theme='success'
                        isTemporal
                        onClose={onCloseMessageBox}
                    />
                </div>
            )}
        </div>
    )
}

ResultsItem.propTypes = {
    exercise: PropTypes.object,
    onClickCopyExercise: PropTypes.func,
    onClickEditExercise: PropTypes.func,
    lang: PropTypes.any,
    exerciseCreatedId: PropTypes.string,
    onCloseMessageBox: PropTypes.func,
    closedMessage: PropTypes.bool,
    showCloneButton: PropTypes.bool,
}

ResultsItem.defaultProps = {
    exercise: {},
    closedMessage: false,
    showCloneButton: false,
    exerciseCreatedId: '',
    onClickCopyExercise: () => {},
    onClickEditExercise: () => {},
    lang: {},
    onCloseMessageBox: () => {},
}

export default ResultsItem
