import React from 'react'
import PropTypes from 'prop-types'
import HeaderFeedback from './Feedback/HeaderFeedback'
import QuizTitle from './QuizTitle'
import './QuizHeader.scss'

const QuizHeader = ({
    title,
    attempts,
    maxAttempts,
    rightAnswer,
    hasQuizComplete,
    approvedMessage,
    redoMessage,
    failedMessage,
    approvedPercentage,
    blockName,
    blockDescription,
    availableReDoQuiz,
    quiz,
    lang,
    ...props
}) => {
    const remainingAttempts = !!maxAttempts ? attempts + '/' + maxAttempts : lang.unlimited

    return (
        <>
            <div className='quiz-header-container'>
                <QuizTitle title={title} />
                {!quiz.isSurvey && <div className='quiz-header-container__attempts'>
                    {lang.attempts + ' ' + remainingAttempts}
                </div>}
            </div>
            {hasQuizComplete && (
                <HeaderFeedback
                    approvedMessage={approvedMessage}
                    redoMessage={redoMessage}
                    failedMessage={failedMessage}
                    rightAnswer={rightAnswer}
                    approvedPercentage={approvedPercentage}
                    availableReDoQuiz={availableReDoQuiz}
                    lang={lang}
                    isSurvey={quiz.isSurvey}
                    affectsProgress={quiz.affectsProgress}
                />
            )}
        </>
    )
}

QuizHeader.propTypes = {
    title: PropTypes.string.isRequired,
    attempts: PropTypes.number,
    maxAttempts: PropTypes.number.isRequired,
    quiz: PropTypes.object,
    approvedMessage: PropTypes.string,
    redoMessage: PropTypes.string,
    failedMessage: PropTypes.string,
    approvedPercentage: PropTypes.number,
    blockName: PropTypes.string,
    blockDescription: PropTypes.string,
    availableReDoQuiz: PropTypes.bool,
    lang: PropTypes.object,
}

QuizHeader.defaultProps = {
    attempts: '',
}

export default QuizHeader
