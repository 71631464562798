import { createSelector } from 'reselect'
import {
    STUDENT_GUARD_PERMISSION,
    ADMIN_GUARD_PERMISSION,
} from '../../constants/guards'

export const getPermissions = ({ authReducer }) => authReducer.permissions

export const getUser = ({ authReducer }) => authReducer.user

export const getAuth = state => state.authReducer

export const getIsAuthenticated = createSelector(getAuth, auth => auth.isAuthenticated)

export const getLoggedUserId = createSelector(getAuth, auth => auth.user.id)

export const getIsMobile = ({ authReducer }) => authReducer.isMobile

export const isMobileSelector = createSelector(getAuth, auth => auth.isMobile)

export const hasBackofficeAccessSelector = createSelector(
    getPermissions,
    (permissions) =>
        !!permissions.find((permission) => permission == ADMIN_GUARD_PERMISSION)
)

export const isStudentSelector = createSelector(
    getPermissions,
    (permissions) =>
        !!permissions.find(
            (permission) => permission === STUDENT_GUARD_PERMISSION
        )
)

export const paymentMethod = createSelector(getUser, auth => auth.paymentMethod)

export const hasPermission = (p) => createSelector(
    getPermissions,
    (permissions) => !!permissions.find((permission) => permission == p)
)
