import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useDraggable } from './hooks/useDraggable'
import { useSlider } from './hooks/useSlider'

import PagesIndicator from './PagesIndicator'
import NavigationArrows from './NavigationArrows'
import PaginationArrow from './PaginationArrow'

import './Slider.scss'
import { LEFT_ARROW, RIGHT_ARROW, SPINNER } from './constants/index'

const noop = () => {}

const Slider = ({ id, items, showFooter, handleNextPage, isExpanded, pagination }) => {
    const [sliderRef, setSliderRef] = useState()

    const {
        activeNext,
        activePage,
        activePrev,
        handleNextNavigation,
        handlePrevNavigation,
        handleChangePage,
        pagesCount,
        updateIndicators,
        limitsPerPage,
    } = useSlider(sliderRef, items)

    const { handleMouseMove, handleMouseDown, handleInitialDraggingState } = useDraggable(
        sliderRef,
        limitsPerPage,
        updateIndicators
    )
    const [isLoadingNextPage, setIsLoadingNextPage] = useState(false)

    return (
        <div className='slider-wrapper'>
            <div
                className='slider-wrapper__content'
                ref={(newRef) => setSliderRef(newRef)}
                id={id}
                onMouseUp={handleInitialDraggingState}
                onMouseDown={pagesCount > 1 ? handleMouseDown : noop}
                onMouseLeave={handleInitialDraggingState}
                onMouseMove={handleMouseMove}
            >
                {items}
            </div>
            {isExpanded && (
                <>
                    {pagesCount > 1 && (
                        <PaginationArrow
                            active={activePrev}
                            onClick={() => handlePrevNavigation()}
                            arrowType={LEFT_ARROW}
                        />
                    )}
                    {(activeNext || pagination.page < pagination.pageCount) && items.length > 3 && (
                        <PaginationArrow
                            active
                            onClick={async () => {
                                if(pagination.page < pagination.pageCount) {
                                    setIsLoadingNextPage(true)
                                    await handleNextPage()
                                    setIsLoadingNextPage(false)
                                }
                                handleNextNavigation()
                            }}
                            arrowType={isLoadingNextPage ? SPINNER : RIGHT_ARROW}
                        />
                    )}
                </>
            )}
        </div>
    )
}

Slider.defaultProps = {
    items: null,
    showFooter: true,
    isExpanded: false,
}

Slider.propTypes = {
    id: PropTypes.string.isRequired,
    items: PropTypes.array,
    showFooter: PropTypes.bool,
    isExpanded: PropTypes.bool,
}

export default Slider
