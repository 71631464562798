import React from 'react'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { isNull, isUndefined } from '@/helpers/utils'
import { Plus, Trash2 } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import InputTag from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputTag'
import InputNumber from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputNumber'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import { formatStatus } from './helpers'

import './RuleModals.scss'

const formatAttributes = (attributes) => {
    return attributes.map((attr, key) => ({
        ...attr, status: true, id: key, message: "Debe completar este campo.", errorName: null
    }))
}
class SelectorModalForm extends React.Component {

    constructor(props) {
        super(props)
        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.saveRule = this.saveRule.bind(this)
        this.handleChangeSelect = this.handleChangeSelect.bind(this)
        this.defaultState = {
            isEdit: false,
            ruleKey: null,
            show: false,
            rule_id: null,
            inputs: {
                selector: {
                    error: null,
                    message: this.props.lang.thisIsAMandatoryField,
                    value: "",
                    type: "string",
                    required: true
                },
                hint: {
                    error: null,
                    message: this.props.lang.thisIsAMandatoryField,
                    value: "",
                    type: "string",
                    required: true
                },
                content: {
                    error: null,
                    message: "",
                    value: "",
                    type: "string",
                    required: false
                },
                quantity: {
                    error: null,
                    message: "",
                    value: null,
                    type: "number",
                    required: false
                },
                attributes: {
                    error: null,
                    message: "",
                    value: [],
                    type: "string",
                    required: false
                },
                target: {
                    error: null,
                    message: this.props.lang.thisIsAMandatoryField,
                    value: [],
                    type: "string",
                    required: false
                },
                statusChange: {
                    error: null,
                    message: "",
                    value: {
                        value: false,
                        label: this.props.lang.no,
                    },
                    type: "boolean",
                    required: false
                }
            }
        }

        this.state = this.defaultState

    }

    showModal(rule_id, rule, ruleKey) {
        if (!isUndefined(rule) && !isUndefined(ruleKey)) {
            let editingState = _.cloneDeep(this.defaultState.inputs)
            Object.keys(rule).forEach(r => {
                if (!isUndefined(editingState[r])) {
                    if (r === "attributes") {
                        editingState[r].value = formatAttributes(rule[r])
                    } else if (r === "target") {
                        editingState[r].value = rule[r].map(elem => elem)
                    } else if (r === "statusChange") {
                        editingState[r].value = formatStatus(rule[r], this.props.lang.yes)
                    } else {
                        editingState[r].value = rule[r]
                    }
                }
            })
            this.setState({
                ...this.defaultState,
                inputs: editingState,
                isEdit: true,
                ruleKey,
                rule_id,
                show: true
            })
        } else {
            this.setState({ ...this.defaultState, show: true })
        }
    }

    hideModal() {
        this.setState(this.defaultState)
    }

    handleChangeInput(e) {
        this.setState({
            ...this.state,
            inputs: {
                ...this.state.inputs,
                [e.target.name]: {
                    ...this.state.inputs[e.target.name],
                    value: e.target.value
                }
            }
        })
    }

    handleChangeSelect(type, e) {
        this.setState({
            ...this.state,
            inputs: {
                ...this.state.inputs,
                [type]: {
                    ...this.state.inputs[type],
                    value: e
                }
            }
        })
    }

    saveRule() {
        const inputs = this.state.inputs
        let selectorError = null, hintError = null, targetError = null, hasError = false
        if (inputs.selector.value === "" || typeof inputs.selector.value !== inputs.selector.type) {
            selectorError = "error"
            hasError = true
        }
        if (inputs.hint.value === "" || typeof inputs.hint.value !== inputs.hint.type) {
            hintError = "error"
            hasError = true
        }
        if (!inputs.target.value.length) {
            targetError = "error"
            hasError = true
        }
        this.setState({
            ...this.state,
            inputs: {
                ...this.state.inputs,
                selector: {
                    ...this.state.inputs.selector,
                    error: selectorError
                },
                hint: {
                    ...this.state.inputs.hint,
                    error: hintError
                },
                target: {
                    ...this.state.inputs.target,
                    error: targetError
                }
            }
        })
        const [attrErrors, attributes] = this.refs.attributesTableRef.validate()
        if (hasError || attrErrors) {
            return
        }
        let rule = {}
        rule.type = "Selector"
        rule.selector = inputs.selector.value
        rule.hint = inputs.hint.value
        rule.target = inputs.target.value.map(elem => elem)
        rule.statusChange = inputs.statusChange.value
        if (inputs.content.value !== "") {
            rule.content = inputs.content.value
        }
        if (!isNull(inputs.quantity.value)) {
            rule.quantity = Number(inputs.quantity.value)
        }
        if (attributes.length) {
            rule.attributes = attributes
        }
        let ruleObj = { ruleType: 'Selector', config: rule }
        if (this.state.isEdit) {
            if (this.state.rule_id) {
                ruleObj.id = this.state.rule_id
            }
            const allRules = [...this.props.rules]
            allRules[this.state.ruleKey] = ruleObj
            this.props.handlers.setField('rules', allRules)
        } else {
            const allRules = [...this.props.rules]
            allRules.push(ruleObj)
            this.props.handlers.setField('rules', allRules)
        }
        this.hideModal()
    }


    render() {
        return (
            <Modal className="__modal" bsSize="large" show={this.state.show} onHide={this.hideModal} keyboard={false} backdrop="static">
                <Modal.Header closeButton className="__modal_header __border_bottom">
                    <h3 className="__modal_title">{this.props.lang.createANewRule}</h3>
                </Modal.Header>

                <Modal.Body className="__modal_body">
                    <>
                        <Row className='rule-modal__input-container'>
                            <Col md="12">
                                <Label text={this.props.lang.targetFiles} />
                                <InputTag
                                    name='target'
                                    placeholder={!this.state.inputs.target.value.length && this.props.lang.choose}
                                    id='target'
                                    dropdownItems={this.props.availableFiles}
                                    onChange={elems => this.handleChangeSelect('target', elems)}
                                    selectedTags={this.state.inputs.target.value}
                                    error={this.state.inputs.target.error === 'error'}
                                    errorMsg={this.state.inputs.target.message}
                                    autoFocus
                                />
                            </Col>
                        </Row>
                        <Row className='rule-modal__input-container'>
                            <Col md="6">
                                <Label text={this.props.lang.ruleType} />
                                <InputText
                                    id="rule_type_name"
                                    key="rule_type_name"
                                    disabled
                                    value="Selector"
                                />
                            </Col>
                            <Col md="6">
                                <Label text="Selector *" />
                                <InputText
                                    id="selector"
                                    key="selector"
                                    inputName="selector"
                                    value={this.state.inputs.selector.value}
                                    onChange={this.handleChangeInput}
                                    error={this.state.inputs.selector.error === 'error'}
                                    errorMsg={this.state.inputs.selector.message}
                                />
                            </Col>
                        </Row>
                        <Row className='rule-modal__input-container'>
                            <Col md="6">
                                <Label text='Hint *' />
                                <InputText
                                    id="hint"
                                    key="hint"
                                    inputName="hint"
                                    onChange={this.handleChangeInput}
                                    value={this.state.inputs.hint.value}
                                    error={this.state.inputs.hint.error === 'error'}
                                    errorMsg={this.state.inputs.hint.message}
                                />
                            </Col>
                            <Col md="6">
                                <Label text='Content' />
                                <InputText
                                    id="content"
                                    key="content"
                                    inputName="content"
                                    onChange={this.handleChangeInput}
                                    value={this.state.inputs.content.value}
                                    error={this.state.inputs.content.error === 'error'}
                                    errorMsg={this.state.inputs.content.message}
                                />
                            </Col>
                        </Row>
                        <Row className='rule-modal__input-container'>
                            <Col md="6">
                                    <Label text='Quantity' />
                                    <InputNumber
                                        inputName='quantity'
                                        value={this.state.inputs.quantity.value}
                                        placeholder='Quantity'
                                        onChange={(value) => this.handleChangeInput({ target: { name: 'quantity', value } })}
                                    />
                            </Col>
                            <Col md="6">
                                <Label text='Denied content' />
                                <InputComboBox
                                    inputName="statusChange"
                                    placeholder={this.props.lang.choose}
                                    id="statusChange"
                                    key="statusChange"
                                    dropdownItems={[this.props.lang.yes, this.props.lang.no]}
                                    onChange={(item) =>
                                        this.handleChangeSelect('statusChange', {
                                            value: item === this.props.lang.yes,
                                            label: item,
                                    })}
                                    value={this.state.inputs.statusChange.value.label}
                                    error={this.state.inputs.statusChange.error === 'error'}
                                    errorMsg={this.state.inputs.statusChange.message}
                                />
                            </Col>
                        </Row>
                        <div className="tableModalAttributesContainer">
                            <AttributesTable
                                attributes={this.state.inputs.attributes.value}
                                ref="attributesTableRef"
                                lang={this.props.lang}
                            />
                        </div>
                    </>
                </Modal.Body>

                <Modal.Footer className="__modal_footer __border_top">
                    <ButtonGroup>
                        <Button
                            id="CancelButton"
                            onClick={this.hideModal}
                            text={this.props.lang.cancel}
                            theme='tertiary'
                        />
                        <Button
                            id="SaveButton"
                            theme='primary'
                            onClick={this.saveRule}
                            text={this.props.lang.save}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        )
    }
}

class AttributesTable extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            attributes: props.attributes.length
                ? props.attributes
                : [],
            counter: props.attributes.length
                ? (props.attributes.length)
                : 0
        }

        this.headers = this.headers.bind(this)
        this.rows = this.rows.bind(this)
        this.onAdd = this.onAdd.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.onWrite = this.onWrite.bind(this)
    }

    validate() {
        let attrAux = _.cloneDeep(this.state.attributes)
        let hasError = false
        attrAux.forEach(attr => {
            if (attr.status) {
                if (attr.name === "" || attr.value === "") {
                    hasError = true
                }
                attr.errorName = (attr.name === "") ? 'error' : null
                attr.errorValue = (attr.value === "") ? 'error' : null
            }
        })
        this.setState({
            ...this.state,
            attributes: attrAux
        })
        let formattedAttributes = Array()
        attrAux.forEach(attr => {
            if (attr.status)
                formattedAttributes.push({ name: attr.name, value: attr.value })
        })
        return [hasError, formattedAttributes]
    }

    headers() {
        return (
            <thead>
                <tr>
                    <th key="name" className="col-md-6 text-center">Name</th>
                    <th key="value" className="col-md-6 text-center">Value</th>
                    <th key="actions" className="col-md-1 text-center"></th>
                </tr>
            </thead>
        )
    }

    rows() {
        let rows = Array()
        this.state.attributes.forEach((_, key) => {
            if (_.status) {
                rows.push(
                    <tr key={key}>
                        <td style={{ verticalAlign: 'baseline' }}>
                            <InputText
                                key="name"
                                id="name"
                                inputName="name"
                                value={_.name}
                                onChange={(e) => this.onWrite(e, _.id)}
                                error={_.errorName === 'error'}
                                errorMsg={_.message}
                            />
                        </td>
                        <td style={{ verticalAlign: 'baseline' }}>
                            <InputText
                                id="value"
                                key="value"
                                inputName="value"
                                value={_.value}
                                onChange={(e) => this.onWrite(e, _.id)}
                                error={_.errorvalue === 'error'}
                                errorMsg={_.message}
                            />
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                            <ButtonRound
                                id="DeleteButton"
                                theme='danger'
                                onClick={() => this.onDelete(_.id)}
                                icon={<Trash2 />}
                            />
                        </td>
                    </tr>
                )
            }
        })
        if (!rows.length) {
            return (<tr><td className="text-center" colSpan="3">{this.props.lang.thereIsNot} <strong>attributes</strong> {this.props.lang.toShow}</td></tr>)
        }
        return rows
    }

    onWrite(e, key) {
        let newAttributes = _.cloneDeep(this.state.attributes)
        let valueToChange = _.find(newAttributes, { id: key })
        valueToChange[e.target.name] = e.target.value
        this.setState({
            ...this.state,
            attributes: newAttributes
        })
    }

    onDelete(key) {
        let newAttributes = _.cloneDeep(this.state.attributes)
        let valueToChange = _.find(newAttributes, { id: key })
        valueToChange.status = false
        this.setState({
            ...this.state,
            attributes: newAttributes
        })
    }

    onAdd() {
        let newAttributes = _.cloneDeep(this.state.attributes)
        newAttributes.push({
            id: this.state.counter,
            name: "", value: "", status: true,
            errorName: null, errorValue: null,
            message: this.props.lang.youMustCompleteThisField
        })
        this.setState({
            ...this.state,
            attributes: newAttributes,
            counter: (this.state.counter + 1)
        })
    }

    render() {
        return (
            <Row className='rule-modal__input-container'>
                <Col md="6">
                    <h3 className="__secondary_title">Attributes</h3>
                </Col>
                <Col md="6">
                    <ButtonRound
                        id="AddButton"
                        className="pull-right"
                        onClick={this.onAdd}
                        icon={<Plus />}
                        theme='primary_dark'
                    />
                </Col>
                <Col md="12">
                    <table className="pdg-table __table __no_margin __no_padding">
                        {this.headers()}
                        <tbody>
                            {this.rows()}
                        </tbody>
                    </table>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = state => ({
    lang: state.langStore.rulesModals
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(SelectorModalForm)
