import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import { withRouter } from 'react-router-dom'
import { useDispatch, connect, useSelector } from 'react-redux'
import { loadRoadmap } from '@/redux/thunks/roadmapThunks'
import {
    expandAllUnits,
    collapseAllUnits,
    expandUnit,
    collapseUnit,
    clearRoadmap
} from '@/redux/reducers/roadmapReducer'
import useGrades from './Grades/hooks/useGrades'
import { history } from '@/app/store'
import LessonConstants from '@/helpers/lessons/lessonTypes'
import StudentUnitHeader from './StudentUnitHeader'
import StudentUnitCard from './StudentUnitCard'
import Breadcrumb from '@/components/Breadcrumb'
import CustomCargando from '@/components/CustomCargando'
import './StudentUnitsContainer.scss'
import RoadmapCarousel from './RoadmapCarousel'
import { setLocation } from '@/routes/guards'
import { CLASSROOM_LOCATION } from '@/constants/locations'
import { enableDiscordAccess, enableEnhancedModules } from '@/helpers/granters/courseTypeRules'
import { timezoneConfig } from '@/helpers/dates/timezoneConfig'
import { checkLessonIsReleased } from '@digitalhouse-tech/ts-lib-store/lib/shared/dates'
import { ChevronsUp, ChevronsDown } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { identify } from '@digitalhouse-tech/react-sdk-analytics'
import { getCourseType } from '@/redux/selectors/course'
import Grades from './Grades'
import { COMMUNITY_URL } from '@/config/env'

const [tz, locale] = timezoneConfig()
moment.locale(locale)

const StudentUnitsContainer = ({
    user,
    isMobile,
    roadmap,
    lang,
    loading,
    match
}) => {
    const [courseTypeRegistered, setCourseTypeRegistered] = useState(false)
    const dispatch = useDispatch()
    const courseType = useSelector(getCourseType)
    const courseId = match.params.courseId
    const allowCommunity = enableDiscordAccess(courseType);
    const allowEnhancedModules = enableEnhancedModules(courseType);
    const { langNav } = useSelector(({ langStore }) => ({
        langNav: langStore.studentNav,
    }))

    const { finalGrade, assessments, fixedAssessments, showFinalGrade } = useGrades()
    useEffect(() => {
        setLocation(CLASSROOM_LOCATION)
        dispatch(loadRoadmap(user.id, courseId))
        return () => dispatch(clearRoadmap())
    }, [])

    const { unitsStatus, course } = roadmap

    const {
        name,
        alias,
        Units,
        show_dates,
        userProgress,
        CourseType,
        lastOnSiteLesson,
        finish_date,
        nextOnSiteLesson,
        schedules,
        initial_date,
    } = course

    const courseAlias = alias || name

    const unitsExpanded = unitsStatus && unitsStatus.filter(unit => unit.expanded).length

    const expandAllUnitsFlag = !unitsExpanded || (unitsStatus && unitsExpanded < unitsStatus.length)

    const handleExpandAllUnits = () => {
        if(expandAllUnitsFlag) {
            dispatch(expandAllUnits())
        } else {
            dispatch(collapseAllUnits())
        }
    }

    const handleExpandUnit = (expanded, id) => {
        if(expanded) {
            dispatch(collapseUnit(id))
        } else {
            dispatch(expandUnit(id))
        }
    }

    const isActiveUnit = (id) => userProgress && userProgress.unit_id === id

    const isLiveClass = ({ LessonType }) => show_dates && LessonType.name === LessonConstants.SYNC

    const getMonthAndDay = (fullDate) => {
        const date = moment(fullDate)
        if(date.isValid()) {
            const [day, monthName] = date.tz(tz).format("D MMMM").split(' ')
            const monthCapitalized = monthName.charAt(0).toUpperCase() + monthName.slice(1)
            return `${day} de ${monthCapitalized}`
        }
    }

    const lessonsReleased = lessons => {
        return lessons.filter(lesson => checkLessonIsReleased(lesson.release_date)).length
    }

    const url = (unitId, lessonId, topicId) => `/course/${courseId}/unit/${unitId}/lesson/${lessonId}/topic/${topicId}`

    const getFirstLessonEnabled = (unit) => {
        return unit.Lessons.find(lesson =>
            lesson.visibility &&
            lesson.Topics.some(topic =>
                topic.visibility &&
                topic.Blocks.length &&
                topic.Blocks.some(block =>
                    block.visibility
                ),
            ),
        )
    }
    const handleUnitUrl = (isActiveUnit, unit) => {
        if (isActiveUnit) return history.push(url(userProgress.unit_id, userProgress.lesson_id, userProgress.topic_id))
        const unitId = unit && unit.id
        const { id: lessonId, Topics: topics } = getFirstLessonEnabled(unit)
        const topic = topics.find(topic => {
            return topic.Blocks.some(block =>
                block.visibility
            )
        })
        return topic ? history.push(url(unitId, lessonId, topic.id)) : history.goBack()
    }

    const handleLessonUrl = (unitId, lessonId, topicId, isReleased) => {
        if(!isReleased) return () => {}
        if(unitId && lessonId && topicId) {
            history.push(url(unitId, lessonId, topicId))
        }
    }

    const crumbSetup = [{ name: lang.breadcrumb.progress, url: '#'}]

    const getLessonsProgress = (unitId) => {
        return userProgress.lessons && allowEnhancedModules ?
            userProgress.lessons.filter(
                lesson=>lesson.unitId === unitId) :
            []
    }

    const getUnitProgress = (unitId) => {
        return userProgress.units && allowEnhancedModules ?
            userProgress.units.find(unit=>unit.id===unitId) :
            []
    }

    const getVisibleUnits = () => {
        const visibleUnits = Units.map(({id})=>id)
        return visibleUnits
    }

    const onGoingCourses = useSelector(({ course }) => course.ongoingCourses)
    
    useEffect(() => {
        if (courseTypeRegistered) return;
        if (!onGoingCourses.courses?.length) return;

        let currentCourse = onGoingCourses?.courses?.find(c => c.id === courseId)
        const isNoActive = !currentCourse?.content?.released && !!currentCourse?.isTeacher
        const isFinished = !!currentCourse?.finished

        if (!currentCourse || !CourseType?.id || isFinished || isNoActive) return;

        setCourseTypeRegistered(true)

        identify({
            course_type: CourseType.name,
            course_type_id: CourseType.id,
        })
    }, [onGoingCourses, CourseType, courseTypeRegistered])

    return (
        <div className='StudentUnitsContainer container'>
            <Breadcrumb crumbs={crumbSetup} courseAlias={courseAlias} />
            {loading?.loadRoadmap ? (
                <CustomCargando />
            ) :
                <>
                    <div className='StudentUnitsContainer__grades-container'>
                        {allowCommunity && 
                            <a href={COMMUNITY_URL} target='_blank' className='_community-button StudentUnitsContainer__community-button'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className='StudentUnitsContainer__community-icon'>
                                    <path fill="currentColor" d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515a.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0a12.64 12.64 0 0 0-.617-1.25a.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057a19.9 19.9 0 0 0 5.993 3.03a.078.078 0 0 0 .084-.028a14.09 14.09 0 0 0 1.226-1.994a.076.076 0 0 0-.041-.106a13.107 13.107 0 0 1-1.872-.892a.077.077 0 0 1-.008-.128a10.2 10.2 0 0 0 .372-.292a.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127a12.299 12.299 0 0 1-1.873.892a.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028a19.839 19.839 0 0 0 6.002-3.03a.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.956-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.955-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.946 2.418-2.157 2.418Z" />
                                </svg>
                                {langNav.community}
                            </a>
                        }
                        <div style={{ flex: 'auto' }}>
                            {<Grades {...{ isMobile, finalGrade, assessments, fixedAssessments, showFinalGrade, schedules, initial_date, nextOnSiteLesson }} courseName={alias || name} />}
                        </div>
                    </div>
                    <div className="StudentUnitsGridContainer">
                        {allowEnhancedModules &&
                            <RoadmapCarousel
                                isMobile={isMobile}
                                userProgress={userProgress}
                                lastOnSiteLesson={lastOnSiteLesson}
                                lang={lang}
                                getMonthAndDay={getMonthAndDay}
                                visibleUnits={getVisibleUnits()}
                                finishDate={finish_date}
                                units={Units}
                            />}
                        <StudentUnitHeader
                            modulesCount={Units && Units.length}
                            title={lang.modules}
                            linkTitle={expandAllUnitsFlag ? lang.expandAll : lang.collapseAll}
                            buttonLink={expandAllUnitsFlag ? <ChevronsDown /> : <ChevronsUp />}
                            handleClick={handleExpandAllUnits}
                            isMobile={isMobile}
                            allowApiCreateRule={allowEnhancedModules}
                            accesilibityMessage={expandAllUnitsFlag ? lang.accesibility.expandAll : lang.accesibility.collapseAll}
                        />
                        {
                            Units && Units.map((unit, index) => {
                                return( <StudentUnitCard
                                    lang={lang}
                                    unit={unit}
                                    moduleNumber={index + 1}
                                    isReleased={checkLessonIsReleased}
                                    lessonsReleased={lessonsReleased}
                                    isActiveUnit={isActiveUnit(unit.id)}
                                    isLive={isLiveClass}
                                    getMonthAndDay={getMonthAndDay}
                                    handleLessonUrl={handleLessonUrl}
                                    handleExpandUnit={handleExpandUnit}
                                    expanded={unitsStatus && unitsStatus.find(u => u.id === unit.id).expanded}
                                    goToUnit={handleUnitUrl}
                                    allowApiCreateRule={allowEnhancedModules}
                                    isMobile={isMobile}
                                    key={unit.id}
                                    unitProgress={allowEnhancedModules ? getUnitProgress(unit.id) : null}
                                    lessonsProgress={allowEnhancedModules ? getLessonsProgress(unit.id) : null}
                                    nextLessonId={nextOnSiteLesson ? nextOnSiteLesson.id : null}
                                />
                                )})
                        }
                    </div>
                </>}
        </div>
    )
}


StudentUnitsContainer.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string
    }).isRequired,
    isMobile: PropTypes.bool.isRequired,
    roadmap: PropTypes.shape({
        course: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            alias: PropTypes.string,
            show_dates: PropTypes.bool,
            CourseType: PropTypes.shape({
                Rules: PropTypes.array
            }),
            Units: PropTypes.array,
            userProgress: PropTypes.shape({
                unit_id: PropTypes.string,
                lesson_id: PropTypes.string,
                topic_id: PropTypes.string,
                units: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.string,
                        percent: PropTypes.number,
                        completedLessons: PropTypes.number,
                        lessonsQty: PropTypes.number,
                    }),
                ),
                lessons: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.string,
                        percent: PropTypes.number,
                        completedTopics: PropTypes.number,
                        topicsQty: PropTypes.number,
                    }),
                )
            }),
            lastOnSiteLesson: PropTypes.shape({
                id: PropTypes.string,
                release_date: PropTypes.string,
                Unit: PropTypes.shape({
                    id: PropTypes.string,
                }),
            }),
            nextOnSiteLesson: PropTypes.shape({
                id: PropTypes.string,
                release_date: PropTypes.string,
                Unit: PropTypes.shape({
                    id: PropTypes.string,
                }),
            }),
            initial_date: PropTypes.string,
            finish_date: PropTypes.string,
        }),
        loading: PropTypes.bool,
        unitsStatus: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                expanded: PropTypes.bool
            })
        )
    }).isRequired,
    lang: PropTypes.any.isRequired,
    loading: PropTypes.shape({
        loadRoadmap: PropTypes.bool,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            courseId: PropTypes.string,
        })
    }).isRequired
}

const mapStateToProps = state => ({
    user: state.authReducer.user,
    isMobile: state.authReducer.isMobile,
    roadmap: state.roadmap,
    lang: state.langStore.studentUnits,
    loading: state.loading.roadmap
})

export default withRouter(connect(mapStateToProps, null)(StudentUnitsContainer))
