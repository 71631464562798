import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRejectReasons, sendStudentReview } from '@/redux/thunks/dashboardThunks'
import { stripHtml } from '@/helpers/utils'
import { shapeReviewToSend } from '../utils/reviewHelpers'
import useCharRestriction from '../hooks/useCharRestriction'
import * as dashboard from '../constants/dashboard'

const useReview = (courseId, blockId, userId) => {
    const dispatch = useDispatch()

    const { actualProgress, rejectTags } = useSelector(
        ({ dashboardReducer }) => dashboardReducer.evaluableActivity
    )

    const { lastStatus, lastSubmission } = actualProgress

    const [showModal, setShowModal] = useState(false)
    const [modalKey, setModalKey] = useState('')
    const [reviewType, setReviewType] = useState('')

    const [teacherReview, setTeacherReview] = useState(dashboard.FEEDBACK)
    const [reviewHasError, setReviewHasError] = useState(false)
    const [reasonsHasError, setReasonsHasError] = useState(false)
    const [htmlReviewProps, setHtmlReviewProps] = useState(dashboard.HTML_REVIEW_PROPS)

    useCharRestriction(htmlReviewProps.length)

    const validateRequestRedo = (feedback, rejectReasons) => {
        if (!feedback.length) setReviewHasError(true)
        if (!rejectReasons.length) setReasonsHasError(true)

        return !feedback.length || !rejectReasons.length
    }

    const onReviewChange = ({ target }) => {
        const review = target.value
        if (review.length <= dashboard.MAX_REVIEW_CHARS) {
            setReviewHasError(!review.length)
            setTeacherReview((prevState) => ({ ...prevState, feedback: review }))
        }
    }

    const onHtmlReviewChange = (review) => {
        const reviewLength = stripHtml(review).length
        setHtmlReviewProps((prevState) => ({ ...prevState, chars: reviewLength }))
        if (reviewLength <= dashboard.MAX_REVIEW_CHARS_FEEDBACK) {
            setHtmlReviewProps((prevState) => ({ ...prevState, error: !reviewLength }))
            setTeacherReview((prevState) => ({ ...prevState, feedback: review }))
        }
    }

    const onReasonsChange = (reasons) => {
        if (reasons.length <= dashboard.MAX_REJECT_REASONS_TAGS) {
            setTeacherReview((prevState) => ({ ...prevState, rejectReasons: reasons }))
        }
        setReasonsHasError(!reasons.length)
    }

    const onChangeReviewFiles = (files) =>
        setTeacherReview((prevState) => ({
            ...prevState,
            files,
        }))

    const onClickFooterActions = (reviewType) => {
        const reviewLength = stripHtml(teacherReview.feedback).length
        if (!reviewLength) {
            setHtmlReviewProps((prevState) => ({ ...prevState, error: true }))
            return
        }

        setModalKey(dashboard.EVALUATE)
        setShowModal(true)
        setReviewType(reviewType)
    }

    const onSendReview = async ({ reviewType, type, teacherReview, reporter }) => {
        const { id: reporterId } = reporter
        const requestRetry = reviewType === dashboard.PENDING_RETRY

        setShowModal(false)
        setReviewType('')
        if (requestRetry) {
            document.querySelector('.note-editable').innerHTML = ''
        }

        const reviewToSend = {
            ...teacherReview,
            files: requestRetry ? [] : teacherReview.files,
        }

        const review = shapeReviewToSend({ reviewType, type, reviewToSend, reporterId })
        await dispatch(sendStudentReview(userId, courseId, blockId, review, reporter))
        setHtmlReviewProps(dashboard.HTML_REVIEW_PROPS)
        setReviewHasError(false)
    }

    const onRequestRedo = () => {
        dispatch(getRejectReasons())
        setModalKey(dashboard.REQUEST_REDO)
        setReviewType(dashboard.PENDING_RETRY)
        setTeacherReview((prevState) => ({ ...prevState, feedback: '' }))
        setShowModal(true)
    }

    const onCancelModal = () => {
        setShowModal(false)
        setReviewType('')
        setReviewHasError(false)
        setReasonsHasError(false)
    }

    useEffect(() => {
        setReviewHasError(false)
        setHtmlReviewProps(dashboard.HTML_REVIEW_PROPS)
    }, [userId])

    useEffect(() => {
        setTeacherReview(lastSubmission.feedback || dashboard.FEEDBACK)
    }, [lastStatus])

    return {
        actualProgress,
        rejectTags,
        showModal,
        modalKey,
        reviewType,
        teacherReview,
        reviewHasError,
        htmlReviewProps,
        onReviewChange,
        onHtmlReviewChange,
        onReasonsChange,
        onChangeReviewFiles,
        onClickFooterActions,
        onSendReview,
        onRequestRedo,
        onCancelModal,
        validateRequestRedo,
        reasonsHasError,
    }
}

export default useReview
