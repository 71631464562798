import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { validateEmptyString } from '@/helpers/strings'
import fileManagerControllers from '../../../../../Files/fileManagerControllers'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import BlockModal from './BlockModal'
import { BLOCK_TYPES } from '@/constants/blocks'
import {
    fileListSelector,
    createModalDataSelector,
    courseIdSelector,
} from '../../../../../../../../redux/selectors'
import { EMPTY_FIELD } from './constants/errorTypes'

const DEFAULT_FILE_BLOCK = {
    file_block_id: 0,
}

const fileBlockSelector = createModalDataSelector(DEFAULT_FILE_BLOCK)
const fileBlockLangSelector = ({ langStore }) => langStore.fileBlockModalBlock

const ModalFileBlock = () => {
    const data = useSelector(fileBlockSelector)
    const lang = useSelector(fileBlockLangSelector)
    const fileBlockList = useSelector(fileListSelector)
    const courseId = useSelector(courseIdSelector)

    const [error, setError] = useState(null)
    const [selectedFileBlockId, setSelectedFileBlockId] = useState(
        data.file_block_id
    )

    const fileBlockOptions = useMemo(
        () =>
            fileBlockList.map((fileBlock) => ({
                value: fileBlock.id,
                label: fileBlock.name,
            })),
        [fileBlockList]
    )

    const selectValue = useMemo(
        () =>
            fileBlockOptions.find(
                (fileBlockOpt) => fileBlockOpt.value === selectedFileBlockId
            ) || { value: '', label: '' },
        [fileBlockOptions, selectedFileBlockId]
    )

    const values = useMemo(() => selectedFileBlockId, [selectedFileBlockId])

    const completeLang = useMemo(() => {
        const { blockName, cancelTitle, confirmTitle } = lang
        return {
            ...lang,
            blockNameLabel: blockName,
            cancelButton: cancelTitle,
            saveButton: confirmTitle,
        }
    }, [lang])

    const validateInputValues = useCallback(() => {
        const error = validateEmptyString(
            selectValue.label,
            lang.requiredInputMessage
        )
        setError(error)
        return error ? [EMPTY_FIELD] : []
    }, [lang, setError, selectValue])

    // modal file
    useEffect(() => {
        fileManagerControllers.getFileList(courseId)
        /* eslint-disable-next-line */
    }, [])
    const id = BLOCK_TYPES.FILE_BLOCK.modal
    const blockTitle = data.id ? lang.editTitle : lang.createTitle

    return (
        <BlockModal
            validateInputValues={validateInputValues}
            blockId={data.id}
            visibility={data.visibility}
            show_name={data.show_name}
            name={data.name}
            values={values}
            lang={completeLang}
            blockTitle={blockTitle}
            blockType={BLOCK_TYPES.FILE_BLOCK}
            id={id}
        >
            <Row>
                <Col md="6">
                    <Label text={lang.file} />
                    <InputComboBox
                        id={`${id}.fileBlockSelect`}
                        inputName={`${id}.fileBlockSelect`}
                        placeholder={lang.choose}
                        dropdownItems={fileBlockOptions.map(({ label }) => label)}
                        onChange={(fileBlockOpt, index) => {
                            const selected = fileBlockOptions.find((file, i) => fileBlockOpt === file.label && index === i)
                            setSelectedFileBlockId(selected.value)
                        }}
                        value={selectValue.label}
                        error={error}
                        disabled={!fileBlockOptions.length}
                    />
                </Col>
            </Row>
        </BlockModal>
    )
}

export default ModalFileBlock
