import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
    Manager,
    ManagerTableBody,
} from '../../../../components/Manager'
import useHistory from '@/hooks/useHistory'
import StudentListSearcherForm from './StudentListSearcherForm'
import StudentListActions from './StudentListActions'
import StudentListHeaderToolbar from './StudentListHeaderToolbar'
import StudentListPanelToolbar from './StudentListPanelToolbar'
import ModalStudentPassword from '@/components/ModalStudentPassword'
import ModalConfirmation from '@/components/ModalConfirmation'

const StudentList = ({
    lang,
    users,
    totalUsers,
    courseName,
    enableAssignStudentsFromSearch,
    enableAssignStudentsFromCSV,
    enableChangePassword,
    enableDeleteStudents,
    showToolbar,
    loadingTable,
    managerDataLoader,
    searcherFields,
    downloadLoginReport,
    showModalPassword,
    deleteStudentCourse,
    showModalAssign,
    enableDownloadLastLoginReport,
}) => {
    const history = useHistory()
    const courseId = searcherFields.course_id
    const [showModalConfirm, setShowModalConfirm] = useState(false)
    const [studentToDelete, setStudentToDelete] = useState(null)
    const showManagerBody = users.length > 0
    const addActions = (users) => {

        if (enableChangePassword || enableDeleteStudents) {
            return users.map((user) => ({
                ...user,
                actions: (
                    <StudentListActions
                        enableChangePassword={enableChangePassword}
                        enableDeleteStudents={enableDeleteStudents}
                        handleChangePassword={() => showModalPassword(user.id)}
                        openModalDelete={() => openModalDelete(user.id)}
                    />
                ),
            }))
        }
        return users
    }

    const openModalDelete = useCallback(
        (userId) => {
            setShowModalConfirm(true)
            setStudentToDelete(userId)
        },
        [setShowModalConfirm]
    )

    const closeModalDelete = () => {
        setShowModalConfirm(false)
    }

    const confirmDelete = async () => {
        setShowModalConfirm(false)
        await deleteStudentCourse(studentToDelete)
    }

    const downloadReport = useCallback(() => {
        downloadLoginReport(courseId, courseName)
    }, [courseId, courseName, downloadLoginReport])

    const getHeaders = useMemo(() => {
        const headers = [
            {
                label: lang.name,
                ref: 'name',
                className: 'col-sm-3',
            },
            {
                label: lang.lastName,
                ref: 'surname',
                className: 'col-sm-3',
            },
            {
                label: lang.email,
                ref: 'email',
                className: 'col-sm-4 text-center',
            }
        ]

        if (enableChangePassword || enableDeleteStudents) {
            headers.push({
                label: lang.actions,
                ref: 'actions',
                className: 'col-sm-2 text-center',
            })
        }

        return headers
    }, [enableChangePassword, enableDeleteStudents, lang.name, lang.lastName, lang.email, lang.actions])


    return (
        <>
            <Manager
                paginated
                withPanel
                headerEnabled
                searchEnabled
                searchOnMount
                loading={loadingTable}
                managerDataLoader={managerDataLoader}
                screenTitle={`${totalUsers} ${lang.students}`}
                screenSmallTitle={courseName}
                headerRightToolbar={
                    (enableAssignStudentsFromSearch || enableAssignStudentsFromCSV)
                        && showToolbar
                        ?
                        <StudentListHeaderToolbar
                            enableAssignStudentsFromSearch={enableAssignStudentsFromSearch}
                            enableAssignStudentsFromCSV={enableAssignStudentsFromCSV}
                            lang={lang}
                            showModalAssign={showModalAssign}
                            courseId={searcherFields.course_id}
                        />
                        :
                        null
                }
                searcherTitle={lang.advancedSearch}
                searcherFormBody={<StudentListSearcherForm lang={lang} />}
                searcherCleanButtonText={lang.clear}
                searcherSubmitButtonText={lang.search}
                searcherFields={searcherFields}
                goBackText={lang.return}
                handleGoBack={() => history.goBack()}
                panelRightToolbar={
                    showManagerBody && enableDownloadLastLoginReport ? (
                        <StudentListPanelToolbar
                            downloadLoginReport={downloadReport}
                        />
                    ) : null
                }
            >
                {showManagerBody ? (
                    <ManagerTableBody
                        headers={getHeaders}
                        rows={addActions(users)}
                        loading={loadingTable}
                        notFoundMessage={lang.noResultsFound}
                        useSearchMessage={lang.useSearch}
                    />
                ) : (
                        <p className='text-center _margin_top_20'>
                            <strong>{lang.noStudents}</strong>
                        </p>
                    )}
            </Manager>
            <ModalStudentPassword />
            <ModalConfirmation
                show={showModalConfirm}
                confirmTitle='Eliminar'
                cancelTitle='Cancelar'
                body={lang.confirmDeleteStudent}
                onCancel={() => closeModalDelete()}
                onConfirm={() => confirmDelete()}
            />
        </>
    )
}

StudentList.propTypes = {
    lang: PropTypes.shape({
        students: PropTypes.string.isRequired,
        studentAssign: PropTypes.string.isRequired,
        attachCSV: PropTypes.string.isRequired,
        advancedSearch: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        noResultsFound: PropTypes.string.isRequired,
        actions: PropTypes.string.isRequired,
        searchName: PropTypes.string.isRequired,
        searchLastName: PropTypes.string.isRequired,
        searchEmail: PropTypes.string.isRequired,
        clear: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
        return: PropTypes.string.isRequired,
        confirmDeleteStudent: PropTypes.string.isRequired,
        useSearch: PropTypes.string.isRequired,
        noStudents: PropTypes.string.isRequired,
    }).isRequired,
    users: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            surname: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    totalUsers: PropTypes.number,
    courseName: PropTypes.string.isRequired,
    enableAssignStudentsFromSearch: PropTypes.bool.isRequired,
    enableAssignStudentsFromCSV: PropTypes.bool.isRequired,
    enableDownloadLastLoginReport: PropTypes.bool.isRequired,
    enableChangePassword: PropTypes.bool.isRequired,
    enableDeleteStudents: PropTypes.bool.isRequired,
    showToolbar: PropTypes.bool.isRequired,
    loadingTable: PropTypes.bool.isRequired,
    searcherFields: PropTypes.shape({
        search: PropTypes.shape({
            ['!name']: PropTypes.string.isRequired,
            ['!surname']: PropTypes.string.isRequired,
            ['!email']: PropTypes.string.isRequired,
        }).isRequired,
        order: PropTypes.string.isRequired,
        course_id: PropTypes.string.isRequired,
        active: PropTypes.number.isRequired,
    }).isRequired,
    managerDataLoader: PropTypes.func.isRequired,
    downloadLoginReport: PropTypes.func.isRequired,
    showModalPassword: PropTypes.func.isRequired,
    deleteStudentCourse: PropTypes.func.isRequired,
    showModalAssign: PropTypes.func.isRequired,
}

export default StudentList