import React, { useEffect, useState } from 'react'
import './AnimateComponent.scss'

const AnimateComponent = ({ componentToShow }) => {

    const [visibleComponent, setVisibleComponent] = useState(null)
    const [styleClass, setStyleClass] = useState('animateComponent')

    const setShowStyles = () => setStyleClass('animateComponent show')
    const setHideStyles = () => setStyleClass('animateComponent')

    useEffect(() => {
        setVisibleComponent(componentToShow)
        setShowStyles()
    }, [])

    useEffect(() => {
        setHideStyles()
        setTimeout(() => {
            setVisibleComponent(componentToShow)
            setShowStyles()
        }, 200)
    }, [componentToShow])

    return (
        <div className={styleClass}>
            {visibleComponent}
        </div>
    )
}

export default AnimateComponent
