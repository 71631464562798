import React from 'react'
import PropTypes from 'prop-types'

import Quiz from '@/components/Quiz'
import BlockIssueMenu from '../BlockIssueMenu/BlockIssueMenu'
import useQuizBlock from './useQuizBlock'
import './QuizBlock.scss'

const QuizBlock = ({ Quiz: quiz, progressReport, block = {}, isCourseExpired, blockType }) => {
    const {
        showCover,
        activeQuestionIdx,
        activeQuestion,
        onUpdateAnswerText,
        onUpdateAnswerSingleChoice,
        onUpdateAnswerMultipleChoice,
        setActiveQuestionIdx,
        completenessPercentage,
        disabled,
        showSendQuizButton,
        showReDoButton,
        studentProgressAnswers,
        handleStartQuiz,
        hasQuizComplete,
        handleSendQuiz,
        isLoading,
        lang,
    } = useQuizBlock(quiz, progressReport, block.id, isCourseExpired)

    const { show_name, name: blockName, description } = block

    return (
        <div className='block-container'>
            <BlockIssueMenu blockType={blockType} blockId={block.id} blockName={block.name} />
            {show_name && (
                <div className='quiz-block-name-container'>
                    <div className='quiz-block-name-container__title-container pg-block-title'>
                        <h1>{blockName}</h1>
                    </div>
                    <div className='quuz-block-name-container__description-container'>
                        {description}
                    </div>
                </div>
            )}
            <div className='quiz-block-container'>
                <Quiz
                    disabled={disabled}
                    activeQuestion={activeQuestion}
                    activeQuestionIdx={activeQuestionIdx}
                    onUpdateAnswerText={onUpdateAnswerText}
                    onUpdateAnswerSingleChoice={onUpdateAnswerSingleChoice}
                    onUpdateAnswerMultipleChoice={onUpdateAnswerMultipleChoice}
                    onClickQuestion={setActiveQuestionIdx}
                    completenessPercentage={completenessPercentage}
                    onClickSendQuiz={handleSendQuiz}
                    onClickStartQuiz={handleStartQuiz}
                    showSendQuizButton={showSendQuizButton}
                    showReDoButton={showReDoButton}
                    answers={studentProgressAnswers}
                    blockId={block.id}
                    quiz={quiz}
                    hasQuizComplete={hasQuizComplete}
                    showCover={showCover}
                    isLoading={isLoading}
                    lang={lang}
                />
            </div>
        </div>
    )
}

QuizBlock.propTypes = {
    quiz: PropTypes.object,
    progressReport: PropTypes.func,
    block: PropTypes.object,
}

export default QuizBlock
