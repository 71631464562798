import { useMemo, useEffect, useState } from 'react'
import { getTenantSession } from '@/helpers/tenants/helpers'
import { OAuthStrategy } from '@/helpers/tenants/authStrategies'
import { signToken } from '@/redux/reducers/_deprecated/authReducer'
import { useDispatch } from 'react-redux'
import useQuery from '@/hooks/useQuery'

const useStategies = () => {
    const tenant = getTenantSession()
    const [isLoaded, setIsLoaded] = useState(false)

    const dispatch = useDispatch()
    const query = useQuery()
    const redirect = query.get('redirect')
    const redirectGuard = query.get('redirectGuard')
    const accessToken = query.get('accessToken')
    const join = query.get('join')
    const _fnl_ = query.get('_fnl_') // force native login for tenants that don't have it (used for debugging)

    const { authStrategies = [], _id, logoUrl, host, showTermsAndConditions } = tenant || {}

    useEffect(() => {
        if (_id) {
            if (accessToken) {
                dispatch(signToken(accessToken, { redirect, redirectGuard })).then(() =>
                    setIsLoaded(true)
                )
            } else {
                setIsLoaded(true)
            }
        }
    }, [_id])

    const oAuthStrategies = useMemo(
        () =>
            authStrategies
                .filter(({ type }) => type === OAuthStrategy)
                .map((strategy) => {
                    const callbackUrl = `${host}/login?${encodeURIComponent(
                        `${redirect ? `redirect=${redirect}` : ''}${
                            redirectGuard ? `&redirectGuard=${redirectGuard}` : ''
                        }`
                    )}`
                    return {
                        ...strategy,
                        config: {
                            ...strategy.config,
                            providerUrl: `${
                                strategy.config.providerUrl
                            }?tenantId=${_id}&callback=${callbackUrl}&host=${host}${
                                join ? `&join=${join}` : ''
                            }`,
                        },
                    }
                }),
        [authStrategies]
    )

    const mustRedirectOnOpen = oAuthStrategies.find((strategy) => strategy.config?.redirectOnOpen)

    if (mustRedirectOnOpen && !accessToken && !_fnl_) {
        window.location.href = mustRedirectOnOpen.config?.providerUrl
        return { isLoaded: false }
    }

    return {
        isLoaded,
        logoUrl,
        oAuthStrategies,
        showTermsAndConditions,
    }
}

export default useStategies
