import React, { useState, useEffect, useCallback } from 'react'
import ScrollBar from 'react-overlayscrollbars'
import 'react-overlayscrollbars/dist/es/index.css'
import PropTypes from 'prop-types'
import CourseReportContent from './table/body/content/CourseReportContent'
import CourseReportGeneralData from './info/CourseReportGeneralData'
import CourseReportStudents from './table/body/CourseReportStudents'
import CourseReportFilters from './table/header/CourseReportFilters'
import CourseReportUnitList from './table/header/CourseReportUnitList'
import CourseReportHeader from './table/header/CourseReportHeader'
import CourseReportControl from './table/header/CourseReportControl'
import CourseReportLoginInfo from './table/body/CourseReportLoginInfo'
import { COURSE_ENTITIES_DATA } from '@/constants/courseEntities'
import { allowApiLessons } from '@/helpers/granters/courseTypeRules'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import { emptyStateAsset } from '@/assets/img/config/index'
import EmptyState from '../shared/components/EmptyState'
import './CourseReport.scss'

const CourseReport = ({
    refreshCourseCache,
    lang,
    courseDashboardInfo,
    downloadProgressReport,
    loadingDownloadProgressReport,
    courseType
}) => {
    const [courseData, setCourseData] = useState(courseDashboardInfo)
    const [filteredCourseData, setFilteredCourseData] = useState(courseData.Course.Units)
    const [users, setUsers] = useState(courseData.Users)

    const [dataLevel, setDataLevel] = useState(COURSE_ENTITIES_DATA.UNITS)
    const [selectedFilter, setSelectedFilter] = useState(lang.filterByState)
    const [showReferenceTable, setShowReferenceTable] = useState(false)
    const [currentUnit, setCurrentUnit] = useState()
    const [currentLesson, setCurrentLesson] = useState()
    const [userFound, setUserFound] = useState('')
    const [showFilters, setShowFilters] = useState(false)
    const [showUsersDetail, setShowUsersDetail] = useState([])

    const [activeUnit, setActiveUnit] = useState()
    const [activeLesson, setActiveLesson] = useState()

    const [syncCache, setSyncCache] = useState(false)
    const [dataList, setDataList] = useState()
    const [showList, setShowList] = useState()

    useEffect(() => {
        setCourseData(courseDashboardInfo)
        setUsers(courseDashboardInfo.Users)
        setFilteredCourseData(courseDashboardInfo.Course.Units)
        setDataLevel(COURSE_ENTITIES_DATA.UNITS)
        setInitialUsersDetail(courseData.Users)
        setUserFound('')
        setSyncCache(false)
        setDataList(courseDashboardInfo.Course.Units)
    }, [courseDashboardInfo, setInitialUsersDetail, courseData.Users])

    const filterSelectionHandler = (filterSelected, keyFilter) => {
        if (keyFilter) {
            //si hay un filtro seleccionado
            const usersByFilter = courseData.Users.filter((user) => user.status === keyFilter)
            setUsers(usersByFilter)
        } else setUsers(courseData.Users)

        setSelectedFilter(filterSelected)
        setShowFilters(false)
    }

    const showUserDetailHandler = (userId) => {
        const userIndex = showUsersDetail.indexOf(userId)
        let newUsers = showUsersDetail
        if (userIndex >= 0) {
            //si existe el user, lo borro
            newUsers.splice(userIndex, 1)
            setShowUsersDetail([...newUsers])
        } //si no existe, lo agrego
        else setShowUsersDetail([...showUsersDetail, userId])
    }

    const collapseUsersHandler = () => {
        const visibleUsers = showUsersDetail.length > 0 ? [] : courseData.Users
        setInitialUsersDetail(visibleUsers)
    }

    const changeDataLevel = (entityId, nextDataLevel) => {
        let filteredData

        if (nextDataLevel === COURSE_ENTITIES_DATA.UNITS) {
            filteredData = courseData.Course.Units
            setDataList(filteredData)
        } else if (nextDataLevel === COURSE_ENTITIES_DATA.LESSONS) {
            const unitIndex = courseData.Course.Units.findIndex((unit) => unit.id == entityId)
            const unit = courseData.Course.Units.find((unit) => unit.id == entityId)
            filteredData = unit.Lessons
            setActiveUnit(unitIndex)
            setCurrentUnit(unit)
            setDataList(courseData.Course.Units)
        } else {
            const lessonIndex = courseData.Course.Units[activeUnit].Lessons.findIndex(
                (unit) => unit.id == entityId
            )
            const lesson = courseData.Course.Units[activeUnit].Lessons.find(
                (lesson) => lesson.id == entityId
            )
            filteredData = lesson.Topics
            setDataList(courseData.Course.Units[activeUnit].Lessons)
            setActiveLesson(lessonIndex)
            setCurrentLesson(lesson)
        }

        setDataLevel(nextDataLevel)
        setFilteredCourseData(filteredData)
        setShowList(false)
    }

    const showListHandler = (show) => {
        setShowList(!show)
    }

    const userSearchHandler = (userSearch) => {
        const userIndex = courseData.Users.filter(
            (user) => user.surname + ' ' + user.name == userSearch
        )

        if (userSearch === '') setUsers(courseData.Users)
        if (userIndex.length > 0) setUsers(userIndex)
        setUserFound(userSearch)
    }

    const refreshCourse = () => {
        setSyncCache(true)
        refreshCourseCache()
    }

    const calculateScrollItems = () => {
        let items = 0
        let widthItems = 0
        if (dataLevel != COURSE_ENTITIES_DATA.TOPICS) items = widthItems = filteredCourseData.length
        else {
            items = filteredCourseData.length
            //los temas con un solo bloque tienen el doble de width (100px)
            filteredCourseData.map(
                (item) => (widthItems += item.Blocks.length === 1 ? 2 : item.Blocks.length)
            )
        }
        return { items, widthItems }
    }

    const setInitialUsersDetail = useCallback((users) => {
        const initialUsersUncollapsed = users.map((user) => user.id)
        setShowUsersDetail(initialUsersUncollapsed)
    }, [])

    const allowApiLessonsRule = allowApiLessons(courseType)

    return (
        <div className='container'>
            <div className='__main_header'>
                <CourseReportGeneralData
                    progressStatus={courseData.Course.progress}
                    showReferenceTable={showReferenceTable}
                    showRefTableHandler={() => setShowReferenceTable(!showReferenceTable)}
                    refreshCourse={refreshCourse}
                    onClickDownloadReport={downloadProgressReport}
                    loadingDownloadProgressReport={loadingDownloadProgressReport}
                    loading={syncCache}
                    totalUsers={courseData.Users.length}
                    dataLevel={dataLevel}
                    lang={lang}
                />
                {courseData.Users.length > 0 && (
                    <div>
                        <Row className='_report_content'>
                            <Col lg="3" className='_report_filters'>
                                <CourseReportFilters
                                    users={users}
                                    showFilters={showFilters}
                                    selectedFilter={selectedFilter}
                                    filterSelectionHandler={filterSelectionHandler}
                                    showFiltersHandler={() => setShowFilters(!showFilters)}
                                    userFound={userFound}
                                    userSearchHandler={userSearchHandler}
                                    statusByApprovalRange={courseData.Course.progress}
                                    dataLevel={dataLevel}
                                    collapseUsersHandler={collapseUsersHandler}
                                    visibleUsers={showUsersDetail.length}
                                    lang={lang}
                                />
                            </Col>
                            <Col lg="9" className='_report_data'>
                                <CourseReportUnitList
                                    currentUnit={currentUnit}
                                    currentLesson={currentLesson}
                                    dataLevel={dataLevel}
                                    dataList={dataList}
                                    activeUnit={activeUnit}
                                    activeLesson={activeLesson}
                                    showList={showList}
                                    showListHandler={showListHandler}
                                    changeDataLevel={changeDataLevel}
                                    lang={lang}
                                />
                                <CourseReportHeader
                                    allowApiLessons={allowApiLessonsRule}
                                    filteredCourseData={filteredCourseData}
                                    dataLevel={dataLevel}
                                    changeDataLevel={changeDataLevel}
                                    totalUsers={courseData.Users.length}
                                    lang={lang}
                                />
                                <CourseReportControl
                                    totalItems={calculateScrollItems()}
                                    filteredCourseData={filteredCourseData}
                                    dataLevel={dataLevel}
                                    lang={lang}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <EmptyState
                                    hidden={users.length > 0}
                                    imgUrl={emptyStateAsset('default-no-results.svg')}
                                    text={lang.noStudentsByState}
                                />
                            </Col>
                        </Row>
                        <ScrollBar
                            id='_report_scroll_area'
                            autoUpdate
                            overflowBehavior={{ x: 'hidden' }}
                            scrollbars={{ autoHide: 's', visibility: 'auto' }}
                        >
                            <Row className='_report_data _report_data_content'>
                                <Col lg="3" className='_report_content_detail'>
                                    <CourseReportStudents
                                        usersDetail={showUsersDetail}
                                        users={users}
                                        dataLevel={dataLevel}
                                        showUserDetailHandler={showUserDetailHandler}
                                        lang={lang}
                                    />
                                </Col>
                                <Col lg="9" className='_report_content_detail _report_data'>
                                    <CourseReportContent
                                        filteredCourseData={filteredCourseData}
                                        dataLevel={dataLevel}
                                        users={users}
                                        usersDetail={showUsersDetail}
                                        activeUnit={activeUnit}
                                        activeLesson={activeLesson}
                                        lang={lang}
                                    />
                                    <CourseReportLoginInfo
                                        users={users}
                                        dataLevel={dataLevel}
                                        usersDetail={showUsersDetail}
                                        lang={lang}
                                    />
                                </Col>
                            </Row>
                        </ScrollBar>
                    </div>
                )}
            </div>
        </div>
    )
}

CourseReport.propTypes = {
    refreshCourseCache: PropTypes.func.isRequired,
    courseDashboardInfo: PropTypes.object.isRequired,
    downloadProgressReport: PropTypes.object.isRequired,
    courseType: PropTypes.object.isRequired,
    lang: PropTypes.object.isRequired,
}

export default CourseReport
