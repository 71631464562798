import React from 'react'
import PropTypes from 'prop-types'
import { calculatePaginationRange } from '@/helpers/pagination'
import LoadingOrContent from '@digitalhouse-tech/react-lib-ui-explorer/lib/LoadingOrContent'
import Paginator from '@digitalhouse-tech/react-lib-ui-explorer/lib/Paginator'

import NoResults from './NoResults'
import ResultsItem from './ResultsItem'

import './Results.scss'

const Results = ({
    goToPage,
    lang,
    loading,
    onClickCopyExercise,
    onClickEditExercise,
    onCloseMessageBox,
    paginator,
    searchResults,
    showEditButton,
    showCloneButton,
}) => {

    const onPageChange = (page) => {
        goToPage(page)
    }

    const showPagination = () => {
        const { page: currentPage, pagesQuantity } = paginator

        return loading || paginator ? (
            <Paginator
                currentPage={currentPage}
                disabled={loading}
                onPageChange={(page) => onPageChange(page)}
                range={calculatePaginationRange(pagesQuantity)}
                totalPages={pagesQuantity}
            />
        ) : (
            <></>
        )
    }

    return (
        <div className='exercise-results__box'>
            <LoadingOrContent loading={loading} size='medium'>
                <div className='exercise-results__box__main'>
                    {searchResults &&
                        (searchResults.length ? (
                            searchResults.map((result, resultIndex) => (
                                <ResultsItem
                                    key={result.id}
                                    lang={lang}
                                    onClickCopyExercise={onClickCopyExercise}
                                    onClickEditExercise={onClickEditExercise}
                                    onCloseMessageBox={onCloseMessageBox}
                                    exercise={result}
                                    resultIndex={resultIndex}
                                    showEditButton={showEditButton}
                                    showCloneButton={showCloneButton}
                                />
                            ))
                        ) : (
                            <NoResults title={lang.noExerciseWasFound} />
                        ))}
                </div>
            </LoadingOrContent>
            <div className='exercise-results__box__footer'>
                {showPagination()}
            </div>
        </div>
    )
}

Results.propTypes = {
    goToPage: PropTypes.func,
    lang: PropTypes.any,
    loading: PropTypes.bool,
    onClickCopyExercise: PropTypes.func,
    onClickEditExercise: PropTypes.func,
    onCloseMessageBox: PropTypes.func,
    paginator: PropTypes.shape({
        limit: PropTypes.number,
        page: PropTypes.number,
        pagesQuantity: PropTypes.number,
        total: PropTypes.number,
    }),
    searchResults: PropTypes.array,
    showCloneButton: PropTypes.bool,
    showEditButton: PropTypes.bool,
}

Results.defaultProps = {
    goToPage: () => {},
    lang: {},
    loading: false,
    onClickCopyExercise: () => {},
    onClickEditExercise: () => {},
    onCloseMessageBox: () => {},
    paginator: {},
    searchResults: [],
    showCloneButton: false,
    showEditButton: false,
}

export default Results
