import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { ITEM_START, DASHED_LINE, ITEM_FINISH } from './itemCarouselTypes'

const ExtremeCarouselItem = ({
    type,
    date,
}) => {
    const extremityWidth = {width: 64}

    const shapeClassName = cn(
        'startShape',
        {finish: type === ITEM_FINISH}
    )

    const dateClassName = cn(
        'dateText',
        {finish: type === ITEM_FINISH}
    )

    const getLineDot = () => {
        const types = {
            [ITEM_START] :(
                <>
                    <div className="extremeDot" key="exStartDot" />
                    <p className="startLine" key="dashedStartLine">{DASHED_LINE}</p>
                </>),
            [ITEM_FINISH]: (
                <>
                    <p className="startLine" key="exFinishDot">{DASHED_LINE}</p>
                    <div className="extremeDot" key="dashedFinishLine" />
                </>)
        }

        return types[type] 
    }

    return (
        <div className="unitSeparator" style={extremityWidth}>
            <div className={shapeClassName}>
                {getLineDot()}
            </div>
            <p className={dateClassName}>{date}</p>
        </div>
    )
}

ExtremeCarouselItem.propTypes = {
    type: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
}

export default ExtremeCarouselItem
