/**
 * This function calculates the hours and minutes left since "now" until the date received
 * @param {string} date
 * The due date in string format
 * @example
 * date = '2020-07-15 20:30:00' // hypothetical now date: '2020-07-15 18:10:00'
 * getTimeLeft(date)
 * returns { hoursLeft: 2, minutesLeft: 20 }
 */

export const getTimeLeft = (date) => {
    const dueDate = new Date(date).getTime()
    const now = new Date().getTime()
    const diff = dueDate - now

    const hoursLeft = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutesLeft = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))

    return { hoursLeft, minutesLeft }
}