import * as service from '../../http/services/blockProgress'
import { getLoggedUserId } from '../../selectors/auth'
import { getCourseType } from '@/redux/selectors/course'
import { setLoading } from '../../reducers/loadingReducer'
import { addBlockProgress } from '../../reducers/student/classroom'
import { getContentMap } from '@/redux/thunks/student/classroom'
import { enableCertificate } from '@/helpers/granters/courseTypeRules'

import { updateBlockProgress, getContentMapStart } from '../../reducers/student/classroom'

export const getProgressByCourse = ({ courseId }) => async (dispatch, getState) => {
    const userId = getLoggedUserId(getState())
    dispatch(getContentMapStart())
    
    const [error, data] = await service.getProgressesByCourse({
        userId,
        courseId,
    })
    
    if (!error) {
        dispatch(addBlockProgress(data))
    }
}

export const saveBlockProgressByBlock = ({ courseId, blockId, progress, schema, unitId, isOwner }) => async (
    dispatch,
    getState,
) => {
    dispatch(setLoading.true(`classroomProgress.${blockId}`))

    const userId = getLoggedUserId(getState())
    const courseType = getCourseType(getState())

    const [error, data] = await service.saveBlockProgressByBlock({
        userId,
        courseId,
        blockId,
        progress,
        schema,
    })

    if (!error) {
        if(!isOwner) dispatch(updateBlockProgress([data]))

        if(enableCertificate(courseType))
            dispatch(getContentMap({ courseId, unitId }))

    }

    dispatch(setLoading.false(`classroomProgress.${blockId}`))
}

export const saveBlockProgressByTopic = ({ courseId, topicId, progresses, unitId }) => async (
    dispatch,
    getState,
) => {

    dispatch(setLoading.true('classroomProgress.loading'))
    const userId = getLoggedUserId(getState())
    const courseType = getCourseType(getState())

    const [error, data] = await service.saveBlockProgressByTopic({
        userId,
        courseId,
        topicId,
        progresses,
    })

    if (error) {
        dispatch(setLoading.false('classroomProgress.loading'))
    } else {
        dispatch(updateBlockProgress(data))
        dispatch(setLoading.false('classroomProgress.loading'))

        if(enableCertificate(courseType))
            dispatch(getContentMap({ courseId, unitId }))
    }
}
