import { createStore, applyMiddleware, compose } from 'redux'
import mainReducer from '@/redux/reducers'
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import { persistStore } from 'redux-persist'
import { createBrowserHistory } from 'history'
import * as Sentry from '@sentry/react'
import { resizeController } from '@/redux/reducers/_deprecated/authReducer'
import { IGNORE_REDUX_LOGGER } from '@/config/env'

export const history = createBrowserHistory()

const isDevelopmentEnv = process.env.NODE_ENV === 'development'

let middlewares = [thunkMiddleware, routerMiddleware(history)]

if (!IGNORE_REDUX_LOGGER) {
    const { createLogger } = require('redux-logger')

    const logger = createLogger({
        predicate: () => process.env.NODE_ENV !== 'production',
        collapsed: true,
    })

    middlewares.push(logger)
}

const DEVTOOLS_CONFIG = {
    name: 'Playground',
    trace: true,
    traceLimit: 15,
}

const getEnhancers = () => {
    if (isDevelopmentEnv && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(DEVTOOLS_CONFIG)
    }
    return compose
}

const composeEnhancers = getEnhancers()

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: (state) => {
        return { ...state.authReducer.user, token: null }
    },
})

const enhancer = composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)

const store = createStore(mainReducer(history), enhancer)

let persistor = persistStore(store, null, () => {
    store.getState() // if you want to get restoredState
})

export const getStore = () => store

setImmediate(() => {
    resizeController()
})

window.addEventListener('resize', resizeController)

export default { store, persistor }
