import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextAreaInput from '@digitalhouse-tech/react-lib-ui-explorer/lib/TextAreaInput'
import cn from 'classnames'
import Feedback from '../Feedback/Feedback'
import './TextQuestion.scss'

const TextQuestion = ({
    question: { feedback },
    onUpdateAnswerText,
    studentAnswer,
    hasQuizComplete,
    showOptionFeedback,
    lang,
}) => {
    const maxInputLength = 1500
    const isCorrectChoice = studentAnswer?.isCorrect
    const [inputLength, setInputLength] = useState(0)
    const onChangeTextInput = (value) => {
        setInputLength(String(value).length)
        onUpdateAnswerText(value)
    }

    useEffect(() => {
        setInputLength(studentAnswer?.text?.length)
    }, [studentAnswer])

    return (
        <div className='text-question-container'>
            <Feedback
                hasFeedback={showOptionFeedback && hasQuizComplete && feedback}
                feedback={feedback}
                isCorrectChoice={isCorrectChoice}
                lang={lang}
            >
                <small className='input__counter-text pull-right'>
                    {`${inputLength}/${maxInputLength} ${lang.characters}`}
                </small>
                <TextAreaInput
                    className={cn('text-question-container__input', {
                        'text-question-container__input--right-feedback':
                            hasQuizComplete && isCorrectChoice,
                        'text-question-container__input--wrong-feedback':
                            hasQuizComplete && !isCorrectChoice,
                    })}
                    autoFocus
                    placeholder={lang.textInputPlaceholder}
                    value={studentAnswer?.text}
                    onChange={(e) => onChangeTextInput(e.target.value)}
                    disabled={hasQuizComplete}
                    maxLength={maxInputLength}
                />
            </Feedback>
        </div>
    )
}

TextQuestion.propTypes = {
    question: PropTypes.shape({
        id: PropTypes.string,
        rightFeedback: PropTypes.string,
        wrongFeedback: PropTypes.string,
    }).isRequired,
    onUpdateAnswerText: PropTypes.func.isRequired,
    hasQuizComplete: PropTypes.bool,
    showOptionFeedback: PropTypes.bool,
    lang: PropTypes.object,
}

export default TextQuestion
