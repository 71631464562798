import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { ArrowLeftCircle, ArrowRightCircle } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { ChevronRight, ChevronLeft, Check, X } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import './QuizNav.scss'

const QuizNav = ({
    active,
    questions,
    onClickItem,
    hasQuizComplete,
    questionsResults,
    validateInputValue,
    lang,
    isSurvey,
    attempts,
}) => {
    const length = questions.length
    const maxItems = 10
    const totalItems = Array(length)
        .fill()
        .map((_, idx) => 0 + idx)
    const pages = Math.ceil(length / maxItems)
    const currentPage = Math.floor(active / maxItems) + 1
    const remainingItems = length - currentPage * maxItems
    const startNav = (currentPage - 1) * maxItems
    const endNav = currentPage === pages ? length : maxItems * currentPage

    const moveButton = (side, onClickValue) => (
        <div className='quiz-nav__button-nav'>
            {side == 'left' ? (
                <ChevronLeft onClick={() => onClickItem(onClickValue)} />
            ) : (
                <ChevronRight onClick={() => onClickItem(onClickValue)} />
            )}
        </div>
    )

    return (
        <div className='quiz-nav'>
            <div className='quiz-nav__text-button-container'>
                <Button
                    id='QuizNavBackButton'
                    theme='tertiary'
                    text={lang.previous}
                    leftIcon
                    icon={<ArrowLeftCircle />}
                    onClick={() => onClickItem(active - 1)}
                    hidden={active === 0}
                />
                <Button
                    id='QuizNavNextButton'
                    theme='tertiary'
                    text={lang.next}
                    icon={<ArrowRightCircle />}
                    onClick={() => onClickItem(active + 1)}
                    hidden={active === length - 1}
                />
            </div>
            <div className='quiz-nav__nav-container'>
                {active >= maxItems && moveButton('left', active - maxItems)}
                {totalItems.slice(startNav, endNav).map((i) => {
                    const hasValue = validateInputValue(questions[i].id, hasQuizComplete)
                    return (
                        <React.Fragment key={`QuizNav-${i}`}>
                            <div>
                                <Button
                                    className={cn('quiz-nav__button-container__button', {
                                        'quiz-nav__button-container__button--selected':
                                            i === active,
                                        'quiz-nav__button-container__button--not-empty':
                                            hasValue && !hasQuizComplete,
                                    })}
                                    theme='clear'
                                    text={i + 1}
                                    onClick={() => onClickItem(i)}
                                />
                                {!isSurvey && attempts > 0 && (
                                    <div
                                        className={cn(
                                            'quiz-nav__button-container__button-indicator',
                                            {
                                                'quiz-nav__button-container__button-indicator--correct':
                                                    questionsResults[i],
                                            }
                                        )}
                                    >
                                        {questionsResults[i] ? <Check /> : <X />}
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    )
                })}
                {remainingItems > 0 && moveButton('right', maxItems * currentPage)}
            </div>
        </div>
    )
}

QuizNav.propTypes = {
    active: PropTypes.number,
    length: PropTypes.number,
    onClickItem: PropTypes.func,
    hasQuizComplete: PropTypes.bool,
    questionsResults: PropTypes.array,
    validateInputValue: PropTypes.func,
    lang: PropTypes.object,
    attempts: PropTypes.number,
}

export default QuizNav
