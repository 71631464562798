import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup } from 'react-bootstrap'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import { Upload } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'

const FileManagerMenu = ({ serachInputValue, onSerachInputValue, onUploadFile, lang, showUploadButton }) => (
    <div className="FileTools">
        <div className="FileFilter">
            <FormGroup>
                <Label text={lang.filter} />
                <InputText
                    id='file.name'
                    type='text'
                    className='__filter_input'
                    value={serachInputValue}
                    onChange={(e) => onSerachInputValue(e.target.value)}
                    autoFocus
                />
            </FormGroup>
        </div>
        <ButtonGroup className="FileControl">
            {showUploadButton &&
                <ButtonRound
                    id='UploadButton'
                    theme='primary'
                    onClick={onUploadFile}
                    icon={<Upload />}
                />
            }
        </ButtonGroup>
    </div>
)

FileManagerMenu.propTypes = {
    serachInputValue: PropTypes.string,
    onSerachInputValue: PropTypes.func,
    onUploadFile: PropTypes.func
}

FileManagerMenu.defaultProps = {
    serachInputValue: '',
    onSerachInputValue: /* istanbul ignore next */ (value = '') => { },
    onUploadFile: /* istanbul ignore next */ () => { },
}

export default FileManagerMenu