/* eslint-disable no-undef */
import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import cn from 'classnames'
import FilesUploader from '@digitalhouse-tech/react-lib-ui-explorer/lib/FilesUploader'
import { getIconType, getFileExtension, openLinkInNewTab } from '@/helpers/files'
import { setLoading as setLoadingReducer } from '@/redux/reducers/loadingReducer'
import { multilineEllipsisCrop } from '@/helpers/strings/multilineEllipsisCrop'
import fileUploaderService from './helpers/fileUploaderService'
import './FilesUploaderManager.scss'

const DEFAULT_CROP_LENGTH = 40

const FilesUploaderManager = ({
    title,
    subtitle,
    onChange,
    files,
    maxUploadLimit,
    maxFileSizeLimit,
    acceptedFormats,
    vaultyConfig,
    vaultyFolder,
    errorMsg,
    filenameCropLength,
    disabled,
    skeletonProps,
    customSubtitle,
    ignoreVaulty,
    hideUploadButton,
    className,
}) => {
    const [error, setError] = useState({})
    const [loading, setLoading] = useState(false)
    const fileRef = useRef(null)
    const dispatch = useDispatch()

    const onClick = () => {
        fileRef && files.length < maxUploadLimit && fileRef.current.click()
    }

    const onClose = (index) => {
        const filesToSave = [...files]
        filesToSave.splice(index, 1)
        onChange(filesToSave)
    }

    const cropFilename = (filename, extension) => {
        if (filename.length >= filenameCropLength - 4) {
            return `${multilineEllipsisCrop(filename, filenameCropLength)}${extension}`
        }
        return filename
    }

    const checkFileFormat = (extension) => acceptedFormats.includes(extension)

    const getExtension = (name) => {
        const split = name.split('.')
        return split[split.length - 1]
    } 

    const handleUploadedFile = async (e) => {
        const [file] = e.target.files
        e.target.value = ''
        const fileExtension = getExtension(file.name)
        if (file && files.length < maxUploadLimit) {
            setLoading(true)
            dispatch(setLoadingReducer.true('filesManager.uploading'))
            try {
                const fileSize = (file.size / 1000000).toFixed(3)
                if (fileSize > maxFileSizeLimit || !checkFileFormat(getFileExtension(file.name))) {
                    throw { type: 'invalid-file' }
                }

                if (ignoreVaulty) {
                    onChange([
                        ...files,
                        {
                            name: cropFilename(file.name, fileExtension),
                            size: fileSize * 1000,
                            icon: getIconType(fileExtension),
                            onClick: () => {},
                            url: '',
                            file
                        },
                    ])
                } else {
                    const [urlResponse, extension] = await fileUploaderService.uploadFile(
                        file,
                        vaultyFolder,
                        vaultyConfig
                    )
                    onChange([
                        ...files,
                        {
                            name: cropFilename(file.name, extension),
                            size: fileSize * 1000,
                            icon: getIconType(extension),
                            onClick: () => window.open(urlResponse, '_blank'),
                            url: urlResponse,
                        },
                    ])
                }
                
            } catch (e) {
                setError({
                    message: errorMsg,
                    onClose: () => setError({}),
                })
            }
            setLoading(false)
            dispatch(setLoadingReducer.false('filesManager.uploading'))
        }
    }

    return (
        <>
            <input
                type='file'
                name='file'
                onInput={handleUploadedFile}
                style={{ display: 'none' }}
                ref={fileRef}
                accept={acceptedFormats}
                disabled={loading || error.message || disabled}
            />
            <div className={cn(className, 'file-uploader-manager')}>
                {disabled && <div className='file-uploader-manager--disabled' />}
                <FilesUploader
                    title={title}
                    subtitle={
                        customSubtitle ||
                        subtitle
                            .replace('{FILE_NUMBER}', maxUploadLimit)
                            .replace('{FILE_SIZE}', maxFileSizeLimit)
                    }
                    error={error}
                    files={files.map((file) => {
                        return {
                            ...file,
                            icon: getIconType(getFileExtension(file.name)),
                            onClick: () => openLinkInNewTab(file.url),
                        }
                    })}
                    loading={loading}
                    onClick={onClick}
                    onClose={onClose}
                    skeletonProps={skeletonProps}
                    hideUploadButton={hideUploadButton}
                />
            </div>
        </>
    )
}

FilesUploaderManager.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    files: PropTypes.array.isRequired,
    maxUploadLimit: PropTypes.number,
    acceptedFormats: PropTypes.string,
    vaultyConfig: PropTypes.shape({
        apiKey: PropTypes.string,
        writeUrl: PropTypes.string,
        readerUrl: PropTypes.string,
    }).isRequired,
    vaultyFolder: PropTypes.string.isRequired,
    maxFileSizeLimit: PropTypes.number,
    errorMsg: PropTypes.string,
    filenameCropLength: PropTypes.number,
    disabled: PropTypes.bool,
    customSubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    ignoreVaulty: PropTypes.bool,
    hideUploadButton: PropTypes.bool,
    className: PropTypes.string,
}

FilesUploaderManager.defaultProps = {
    maxUploadLimit: 4,
    maxFileSizeLimit: 10,
    acceptedFormats:
        '.pdf,.jpg,.jpeg,.png,.xls,.xlsx,.ppt,.pptx,.doc,.docx,.gif,.xcl,.zip,.rar,.tar',
    filenameCropLength: DEFAULT_CROP_LENGTH,
    disabled: false,
    customSubtitle: null,
    subtitle: '',
    errorMsg: '',
    ignoreVaulty: false,
    hideUploadButton: false,
    className: '',
}

export default FilesUploaderManager
