import httpClient from '../httpClient'
import * as ownerApi from '../api/ownerApi'
import { getTenantLang } from '@/helpers/tenants/helpers'

const api = (courseId) => httpClient(ownerApi.owners(courseId))
const assignApi = (courseId) => httpClient(ownerApi.ownerAssign(courseId))
const userRoleApi = (userId, courseId) => httpClient(ownerApi.usersOwnership(userId, courseId))
const courseTypeApi = (courseTypeId) => httpClient(`/course-types/${courseTypeId}`)

export const loadOwners = (courseId) => {
    return assignApi(courseId).get('/ownerships')
}

export const loadOwnershipRoles = (courseTypeId) => {
    return courseTypeApi(courseTypeId).get(`/ownership-roles?lang=${getTenantLang()}`)
}

export const loadUserOwnershipDetail = (userId, courseId) => {
    return userRoleApi(userId, courseId).get(`/ownerships`)
}

export const removeCourseOwnership = (userId, courseId) => {
    return userRoleApi(userId, courseId).delete('/ownerships')
}

export const getOwnerCoursePermissions = (userId, courseId) => {
    return userRoleApi(userId, courseId).get('/ownerships')
}

export const create = (courseId, ownersData) => {
    return api(courseId).post(`/ownerships`, ownersData)
}

export const getPermissions = (courseId, userId) => {
    return api(courseId).get(`/users/${userId}/ownerships/owner-permissions`)
}

export const updatePermissions = (courseId, user_id, single) => {
    return api(courseId).put(`/users/${user_id}/ownerships`, single)
}

export const ownershipAssign = (courseId, data) => {
    return assignApi(courseId).post('/ownership-roles', data)
}

export const updateOwnerCourseVisibility = (userId, courseId) => {
    return userRoleApi(userId, courseId).patch('/ownerships/visibility')
}

export const updateOwnerCourseRole = (userId, courseId, ownershipRoleId) => {
    return userRoleApi(userId, courseId).patch(`/ownership-roles/${ownershipRoleId}`)
}
