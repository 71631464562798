'use-strict'

import React from 'react'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import Wysiwyg from '../Wysiwyg'

const ExerciseMoral = ({
    data = {
        moral: ''
    },
    validations = {
        moral: {}
    },
    handlers = {
        setField: () => {}
    }
}) => {
    return (
        <>
                <div className={validations.moral.state === "error" ? '__wysiwyg--error' : null}>
                    <Wysiwyg
                        onChange={content => handlers.setField('moral', content)}
                        value={data.moral}
                    />
                </div>
                {validations.moral.state === "error" &&
                    <MessageBox
                        theme='danger'
                        text={validations.moral.message}
                        noFill
                    />
                }
        </>
    )
}

export default ExerciseMoral