import backoffice from './backoffice'
import student from './student'
import shared from './shared'
import nonSecured from './non-secured'
import notFound from './not-found'

export default [
    ...backoffice,
    ...student,
    ...shared,
    ...nonSecured,
    ...notFound
]