import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash/lang'
import Playground from '@/components/Playground'
import useExerciseBlock from './useExerciseBlock'
import MobileContent from '../../MobileContent'
import ConfirmationModal from './ConfirmationModal'
import BlockIssueMenu from '../BlockIssueMenu/BlockIssueMenu'
import './ExerciseBlock.scss'

const ExerciseBlock = ({ isMobile, blockType, ...props }) => {
    const {
        files,
        playgroundConfig,
        validateLoading,
        playgroundLang,
        isReadOnly,
        progress,
        logs,
        throws,
        exercise,
        solvedValidations,
        handleChange,
        handleOnValidate,
        openResetCodeModal,
    } = useExerciseBlock(props)

    return (
        <div className='exercise-block-container'>
            <BlockIssueMenu blockType={blockType} blockId={props.block.id} blockName={props.block.name} />
            {isMobile && (
                <div className='exercise-block-container__mobile-message'>
                    <MobileContent type={blockType} />
                </div>
            )}
            <div className='exercise-block-container__content'>
                <Playground
                    {...playgroundConfig}
                    executing={validateLoading}
                    exercise={exercise}
                    files={files}
                    handleChange={handleChange}
                    isMobile={isMobile}
                    lang={playgroundLang}
                    logs={logs}
                    onReset={openResetCodeModal}
                    onValidate={handleOnValidate}
                    progress={isEmpty(progress) ? false : progress}
                    readOnly={isReadOnly}
                    solvedValidations={solvedValidations}
                    throws={throws}
                />
            </div>
            <ConfirmationModal />
        </div>
    )
}

ExerciseBlock.propTypes = {
    block: PropTypes.object,
    statement: PropTypes.string.isRequired,
    hints: PropTypes.array.isRequired,
    infinite: PropTypes.bool.isRequired,
    exerciseType: PropTypes.string.isRequired,
    progressReport: PropTypes.func.isRequired,
    files: PropTypes.array,
    isMobile: PropTypes.bool,
    blockType: PropTypes.string.isRequired,
}

ExerciseBlock.defaultProps = {
    block: {
        id: null,
        name: null,
        ExerciseBlock: { files: [] },
    },
    files: [],
    isMobile: false,
}

export default ExerciseBlock
