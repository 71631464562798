import React from 'react'
import PropTypes from 'prop-types'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import MadeWithLove from '@digitalhouse-tech/react-lib-ui-explorer/lib/MadeWithLove'
import './RecoverMessageCard.scss'
import { getTenantSession } from '@/helpers/tenants/helpers'
import { DH_WEB_URL } from '@/config/env'
const RecoverMessageCard = ({
    email,
    text,
    hasContinueButton,
    lang,
    icon,
    redirectToLogin,
    hasReturnButton,
}) => {
    const getSplittedParagraph = () => text.split('|')
    const { logoUrl, dhLogoUrl, country } = getTenantSession()
    const DH_URL = `${DH_WEB_URL}/${country}`

    return (
        <div className='recover-msg'>
            <div className='recover-msg__card'>
                <div className='recover-msg__card__icon-title'>
                    <div className='recover-msg__card__icon'>{icon}</div>
                    <h4 className='recover-msg__card__title'>{lang.title}</h4>
                </div>
                <div className='recover-msg__card__body' data-testid='recover-msg__card__body'>
                    {email ? (
                        <p className='recover-msg__card__text'>
                            <span>{getSplittedParagraph()[0]}</span>
                            <span>
                                <a href={`mailto:${email}`}>{email}</a>
                            </span>
                            <span>{getSplittedParagraph()[1]}</span>
                        </p>
                    ) : (
                        <p className='recover-msg__card__text'>{text}</p>
                    )}
                    {lang.footer && <p className='recover-msg__card__footer'>{lang.footer}</p>}
                    {hasReturnButton && (
                        <Button
                            id='BackToLogin'
                            data-testid='BackToLogin'
                            className='recover-msg__card__button--link'
                            onClick={redirectToLogin}
                            theme='clear'
                            block
                            text={lang.returnButton}
                        />
                    )}
                </div>
                {hasContinueButton && (
                    <Button
                        id='ContinueButton'
                        data-testid='ContinueButton'
                        theme='primary'
                        block
                        type='submit'
                        onClick={redirectToLogin}
                        text={lang.continue}
                    />
                )}
                <div className='auth-page__card__reset-password__footer'>
                    <div className='auth-page__card__pg-logo'>
                        <img src={logoUrl} alt='logo' />
                    </div>
                    <MadeWithLove
                        altImg='Digital House'
                        className='auth-page__signature'
                        imgUrl={dhLogoUrl}
                        logoUrl={DH_URL}
                    />
                </div>
            </div>
        </div>
    )
}

RecoverMessageCard.propTypes = {
    email: PropTypes.string,
    text: PropTypes.string.isRequired,
    hasContinueButton: PropTypes.bool,
    onContinue: PropTypes.func,
    hasReturnButton: PropTypes.bool,
    lang: PropTypes.any,
    icon: PropTypes.node,
    redirectToLogin: PropTypes.func,
}

RecoverMessageCard.defaultProps = {
    email: '',
    text: '',
    hasContinueButton: false,
    onContinue: () => {},
    hasReturnButton: false,
    lang: {},
    icon: null,
    redirectToLogin: () => {},
}

export default RecoverMessageCard
