import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Spinner from '@digitalhouse-tech/react-lib-ui-explorer/lib/Spinner'

import './Dropdown.scss'
class Dropdown extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
        }

        this.handleClick = this.handleClick.bind(this)
        this.handleOutsideClick = this.handleOutsideClick.bind(this)
    }

    handleClick() {
        if (this.props.disabled) {
            return
        }
        if (!this.state.show) {
            document.addEventListener('click', this.handleOutsideClick, true)
        } else {
            document.removeEventListener('click', this.handleOutsideClick, true)
        }

        this.setState((prevState) => ({
            show: !prevState.show,
        }))
    }

    handleOutsideClick(e) {
        if (this.dropdown && this.dropdown.contains(e.target)) {
            return
        }
        this.handleClick()
    }

    render() {
        const { children, ddStyle, disabled, dontPull, id, items, loading } = this.props
        const { show } = this.state
        return (
            <div
                ref={(node) => (this.dropdown = node)}
                onClick={this.handleClick}
                className={
                    '_btn _new_btn ' + ddStyle + (dontPull ? '' : ' pull-right')
                }
                id={id}
                style={{ backgroundColor: disabled ? 'var(--light-gray)' : '' }}
            >
                {children}
                {loading && <Spinner size={18} />}
                {show && !loading ? (
                    <ul className='__dropdown-submenu'>
                        {items.map((item, key) => {
                            return (
                                <li
                                    key={key}
                                    className={item.className}
                                    onClick={item.onClick}
                                    data-testid={item.testId || item.name}
                                >
                                    {item.name}
                                </li>
                            )
                        })}
                    </ul>
                ) : null}
            </div>
        )
    }
}

Dropdown.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    ddStyle: PropTypes.string,
    disabled: PropTypes.bool,
    dontPull: PropTypes.bool,
    id: PropTypes.string.isRequired,
    items: PropTypes.object.isRequired,
    loading: PropTypes.bool,
}

Dropdown.defaultProps = {
    ddStyle: '_new_btn--tertiary',
    disabled: false,
    dontPull: true,
    loading: false,
}

export default Dropdown
