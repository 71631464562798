import { combineReducers } from 'redux'
import classRoomReducer from './classroom'
import quizzesReducer from './quizzes'
import blockIssueOptionReducer from './blockIssueOption'

export default combineReducers({
    classroom: classRoomReducer,
    quizzes: quizzesReducer,
    blockIssueOptions: blockIssueOptionReducer
})
