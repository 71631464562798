import React from "react"
import PropTypes from 'prop-types'
import { FormGroup } from "react-bootstrap"
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import './CustomFormField.scss'

export const CustomFormField = ({
    controlId,
    className,
    validationState,
    bsSize,
    label,
    counter,
    inputComponent,
    validationMessage,
    isRequired,
    subtitle,
    showMessageBox
}) => {
    return (
        <FormGroup
            className={className}
            key={controlId + "group"}
            validationState={validationState}
            controlId={controlId}
            bsSize={bsSize}
        >
            <Row>
                {label && (
                    <Col md="9">
                        <Label
                            text={
                                <>
                                    {label}
                                    {isRequired && <span className='input-required-field'>*</span>}
                                </>
                            }
                        />
                    </Col>
                )}
                {counter && !subtitle && (
                    <Col className="input__counter" md="3">
                        <small className="input__counter-text">
                            {counter}
                        </small>
                    </Col>
                )}
            </Row>
            {subtitle && (
                <Row>
                    <Col className="input__subtitle" md="9">
                        <Label text={subtitle} />
                    </Col>
                    {counter && (
                        <Col className="input__counter" md="3">
                            <small className="input__counter-text">
                                {counter}
                            </small>
                        </Col>
                    )}
                </Row>
            )}
            {inputComponent}
            {showMessageBox && validationState == "error" && (
                <MessageBox
                    theme='danger'
                    text={validationMessage}
                    noFill
                    key={controlId + "help"}
                />
            )}
        </FormGroup>
    )

}

CustomFormField.propTypes = {
    controlId: PropTypes.string,
    className: PropTypes.string,
    validationState: PropTypes.string,
    bsSize: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    counter: PropTypes.string,
    inputComponent: PropTypes.node,
    validationMessage: PropTypes.string,
    isRequired: PropTypes.bool,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    showMessageBox: PropTypes.bool,
}

CustomFormField.defaultProps = {
    controlId: null,
    className: null,
    validationState: null,
    bsSize: null,
    label: null,
    counter: null,
    inputComponent: null,
    validationMessage: null,
    isRequired: false,
    subtitle: null,
    showMessageBox: true
}
