import React from 'react'
import _ from 'lodash'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { ChevronLeft, ChevronRight, ChevronUp, ChevronDown } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'

export default class Hints extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            show: this.props.show,
            currentValue: 0,
            hint: null
        }

        this.showHints = this.showHints.bind(this)
        this.onClickLeft = this.onClickLeft.bind(this)
        this.onClickRight = this.onClickRight.bind(this)
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            generalHints: this.props.data,
            hint: Hints.getHintDescription(this.props.data, 0)
        })
    }

    static getHintDescription(generalHints, hintValue) {
        if (generalHints && generalHints.length) {
            return (
                <div className="hintDescription">
                    {generalHints[hintValue]}
                </div>
            )
        }
        return null
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!_.isEqual(nextProps.data, prevState.generalHints)) {
            return {
                hint: Hints.getHintDescription(nextProps.data, 0),
                show: false,
                currentValue: 0,
                generalHints: nextProps.data
            }
        }
        return {}
    }

    showHints() {
        let toggle = !this.state.show
        this.setState({
            ...this.state,
            show: toggle
        })
    }

    onClickLeft() {
        let currentValue = this.state.currentValue - 1
        this.setState({
            ...this.state,
            currentValue,
            hint: Hints.getHintDescription(this.state.generalHints, currentValue)
        })
    }

    onClickRight() {
        let currentValue = this.state.currentValue + 1
        this.setState({
            ...this.state,
            currentValue,
            hint: Hints.getHintDescription(this.state.generalHints, currentValue)
        })
    }

    hintArrows() {
        let arrows = Array()
        if (this.state.show) {
            if (this.state.currentValue) {
                arrows.push(
                    <div className="leftArrow arrow" key="left" onClick={this.onClickLeft}>
                        <ChevronLeft />
                    </div>)
            }
            if (this.state.currentValue < (this.props.data.length - 1)) {
                arrows.push(
                    <div className="rightArrow arrow" key="right" onClick={this.onClickRight}>
                        <ChevronRight />
                    </div>)
            }
        }
        return arrows
    }

    render() {
        return (
            <div className="__hints_container">

                <div
                    className="_btn_group pull-left _margin_bottom_20"
                >
                    <Button
                        id="ShowHintsButton"
                        theme="primary"
                        onClick={this.showHints}
                        disabled={!(this.props.data && this.props.data.length)}
                        text={this.props.text}
                        icon={!this.state.show ? <ChevronDown className="__hints_chevron" /> : <ChevronUp className="__hints_chevron" />}
                    />
                </div>

                {this.state.show &&
                    <div className="blockHints">
                        {this.state.hint}
                        {this.hintArrows()}
                    </div>
                }
            </div>
        )
    }
}