import React from 'react'
import PropTypes from 'prop-types'
import './NoResults.scss'

const NoResults = ({ title }) => (
    <div className='exercise-list-no-results'>
        {title && <p className='exercise-list-no-results__title'>{title}</p>}
    </div>
)

NoResults.propTypes = {
    title: PropTypes.string,
}

NoResults.defaultProps = {
    title: '',
}

export default NoResults
