import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { CheckCircle, AlertCircle, Circle, PlusCircle } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { COURSE_ENTITIES_DATA } from '@/constants/courseEntities'
import { checkLessonIsReleased } from '@digitalhouse-tech/ts-lib-store/lib/shared/dates'
import { UP_TO_DATE, WITH_DELAY, WITH_LONG_DELAY } from '../../../constants/courseApprovalRanges'

const CourseReportUnitContent = ({
    userItem,
    usersDetail,
    dataLevel,
    courseItem,
    activeUnit,
    lang,
}) => {
    const showDetail = usersDetail.includes(userItem.id)
    let filteredDataByLevel

    if (dataLevel === COURSE_ENTITIES_DATA.UNITS)
        filteredDataByLevel = userItem.Course[dataLevel].find((item) => item.id == courseItem.id)
    else if (dataLevel === COURSE_ENTITIES_DATA.LESSONS)
        filteredDataByLevel = userItem.Course.Units[activeUnit][dataLevel].find(
            (item) => item.id == courseItem.id
        )

    const extraContent = filteredDataByLevel.denominator === 0
    const contentReleased = checkLessonIsReleased(courseItem.releaseDate)
    const disableCol = !contentReleased || !courseItem.releaseDate || extraContent
    const tooltipExtraContent =
        dataLevel === COURSE_ENTITIES_DATA.UNITS
            ? lang.unitOnlyExtraLessons
            : lang.lesssonOnlyExtraTopics

    return (
        <div className='_report_unit_detail' key={`student_detail_${userItem.id}`}>
            <div
                className={cn(
                    {
                        _report_white_text: disableCol,
                        _report_block_extra: extraContent,
                        _approved_content_background: filteredDataByLevel.status === UP_TO_DATE,
                        _not_approved_content_background: filteredDataByLevel.status === WITH_DELAY,
                        _report_gray_text: filteredDataByLevel.status === WITH_LONG_DELAY ,
                    },
                    '_report_detail _report_class_num'
                )}
            >
                {!extraContent &&
                    `${filteredDataByLevel.numerator}/${filteredDataByLevel.denominator}`}
                {extraContent && (
                    <Button
                        id={`extra_content_${courseItem.id}`}
                        className='_report_inactive_button _extra_content_icon'
                        theme='clear'
                        onClick={() => {}}
                        tooltipId={`extra_content_${courseItem.id}`}
                        icon={<PlusCircle />}
                        tooltipContent={tooltipExtraContent}
                    />
                )}
            </div>
            <div
                className={cn({
                    '_report_detail _report_detail_num': showDetail,
                    _disable_col: disableCol,
                    _report_gray_detail: filteredDataByLevel.status === WITH_LONG_DELAY,
                    _report_hide_detail: !showDetail,
                })}
            >
                <CheckCircle
                    className={cn(
                        {
                            _report_gray_detail: filteredDataByLevel.approved == 0,
                        },
                        '_approved_content'
                    )}
                />
                <span
                    className={cn({
                        _report_gray_detail: filteredDataByLevel.approved == 0,
                    })}
                >
                    {!extraContent ? filteredDataByLevel.approved : '-'}
                </span>
                <AlertCircle
                    className={cn(
                        {
                            _report_gray_detail: filteredDataByLevel.notApproved == 0,
                        },
                        '_not_approved_content'
                    )}
                />
                <span
                    className={cn({
                        _report_gray_detail: filteredDataByLevel.notApproved == 0,
                    })}
                >
                    {!extraContent ? filteredDataByLevel.notApproved : '-'}
                </span>
                <Circle
                    className={cn(
                        {
                            _report_gray_detail: filteredDataByLevel.notStarted == 0,
                        },
                        '_not_started_content'
                    )}
                />
                <span
                    className={cn({
                        _report_gray_detail: filteredDataByLevel.notStarted == 0,
                    })}
                >
                    {!extraContent ? filteredDataByLevel.notStarted : '-'}
                </span>
            </div>
        </div>
    )
}

CourseReportUnitContent.propTypes = {
    userItem: PropTypes.object.isRequired,
    usersDetail: PropTypes.array.isRequired,
    dataLevel: PropTypes.string.isRequired,
    courseItem: PropTypes.object.isRequired,
    activeUnit: PropTypes.number,
    lang: PropTypes.object.isRequired,
}

export default CourseReportUnitContent
