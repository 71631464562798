/**
 * Looks up an object within a deeply nested array structure.
 * This assumes every nested element is an array of objects and that they all have the same key "comparator".
 * @param {{}} obj
 * The object to search
 * @param {{}} map
 * A map containing a set of keys and values.
 * - Keys represent Objects in which to look up the Value
 * - Value represents the value of the comparator for that object
 * @param {string} comparator
 * The key to be used in the search
 *
 * @example
 * obj = {
 *  nested: [{
 *    id: 1
 *    nestedDeep: [{
 *      id: 2
 *    }]
 *  }]
 * }
 * map = {
 *  nested: 1,
 *  nestedDeep: 2,
 * }
 * findDeep(obj, map, 'id')
 * returns { id: 2 } // === obj.nested[0].nestedDeep[0]
 */
export const findDeep = (obj = {}, map = {}, comparator = 'id') => {
    let found
    for (let key in map) {
        const fn = i => i[comparator] === map[key]
        if (found) {
            found = found[key].find(fn)
        } else if (obj[key]) {
            found = obj[key].find(fn)
        } else {
            break
        }
    }
    return found
}