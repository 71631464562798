import React from 'react'
import PropTypes from 'prop-types'
import CircularProgressBar from '@/components/CircularProgressBar'
import { Video } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'

const StudentUnitLessonsList = ({
    lessons,
    isLive,
    liveClass,
    getMonthAndDay,
    unitId,
    handleLessonUrl,
    isReleased,
    lessonsProgress,
    nextLiveLesson,
    nextLessonId,
}) => {
    const findLessonProgress = (lessonId) => 
        lessonsProgress.find(lesson => lesson.id === lessonId)

    const handleLessonDisable = ({ visibility }, topics) => 
        (!visibility || topics.length === 0)

    return (
        <ul>
            {
                lessons.map((lesson, index) => {
                    const lessonProgress = findLessonProgress(lesson.id)
                    const lessonReleased = isReleased(lesson.release_date)
                    const topics = lesson.Topics.filter(topic => {
                        return topic.visibility && topic.Blocks.some(block => block.visibility)
                    })
                    return (
                        <li className={!lessonReleased || handleLessonDisable(lesson, topics)  ? "disabled" : null} key={`${lesson.id}`}>
                            <div className="titleAndDate">
                                <span style={{position:'relative'}}>
                                    <CircularProgressBar 
                                        percent={(lessonProgress && lessonProgress.percent) || 0} 
                                        fillCompleted
                                        small 
                                    />
                                </span>
                                <a className="link" onClick={() => handleLessonUrl(unitId, lesson.id, topics[0].id, lessonReleased)}>
                                    {lesson.name}
                                    {isLive(lesson) && ` - ${liveClass}`}
                                </a>
                                {isLive(lesson) && <span className="liveClass">{getMonthAndDay(lesson.release_date)}</span>}
                                {nextLessonId===lesson.id &&
                                <span className="nextLesson">
                                    <Video className='videoIcon' />
                                    <span>{nextLiveLesson}</span>
                                </span>}
                            </div>
                            
                        </li>
                    )
                })
            }
        </ul>
    )
}

StudentUnitLessonsList.propTypes = {
    lessons: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            release_date: PropTypes.string,
            Topics: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string
                })
            )
        })
    ).isRequired,
    isLive: PropTypes.func.isRequired,
    liveClass: PropTypes.string.isRequired,
    getMonthAndDay: PropTypes.func.isRequired,
    unitId: PropTypes.string.isRequired,
    handleLessonUrl: PropTypes.func.isRequired,
    isReleased: PropTypes.func.isRequired,
    lessonsProgress: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            percent: PropTypes.number,
            completedTopics: PropTypes.number,
            topicsQty: PropTypes.number,
        }),
    ).isRequired,
    nextLiveLesson: PropTypes.string.isRequired,
    nextLessonId: PropTypes.number,
}

export default StudentUnitLessonsList