'use-strict'
import { filterVisibleTopicsByUnits, hasOnlyStudentRole } from '@/helpers/blocks/filterVisibleContent'
import { generateProgress } from './defaultProgressGenerator'

export const initialState = {
    course: {
        id: null,
        name: '',
        alias: '',
        assessment_strategy_id: null,
        show_dates: false,
        CourseType: {
            Rules: [],
        },
        Units: [],
        userProgress: {
            unit_id: null,
            lesson_id: null,
            topic_id: null,
            units: [],
            lessons: [],
            course: null,
        },
        lastOnsiteLesson: {
            id: null,
            release_date: '',
            Unit: {
                id: null,
            },
        },
    },
    unitsStatus: [],
    gradebook: {
        grades: [],
        subjectGrade: null,
    },
}

export const GET_ROADMAP = '@@roadmap/GET_ROADMAP'
export const CLEAR_ROADMAP = '@@roadmap/CLEAR_ROADMAP'
export const EXPAND_ALL_UNITS = '@@roadmap/EXPAND_ALL_UNITS'
export const COLLAPSE_ALL_UNITS = '@@roadmap/COLLAPSE_ALL_UNITS'
export const EXPAND_UNIT = '@@roadmap/EXPAND_UNIT'
export const COLLAPSE_UNIT = '@@roadmap/COLLAPSE_UNIT'
export const SET_GRADEBOOK = '@@roadmap/SET_GRADEBOOK'

export const getRoadmap = payload => ({
    type: GET_ROADMAP,
    payload
})

export const clearRoadmap = payload => ({
    type: CLEAR_ROADMAP,
    payload
})

export const expandAllUnits = () => ({
    type: EXPAND_ALL_UNITS
})

export const collapseAllUnits = () => ({
    type: COLLAPSE_ALL_UNITS
})

export const expandUnit = (id) => ({
    type: EXPAND_UNIT,
    payload: { id },
})

export const collapseUnit = (id) => ({
    type: COLLAPSE_UNIT,
    payload: { id },
})

export const setGradebook = (payload) => ({
    type: SET_GRADEBOOK,
    payload
})

const roadmapReducer = (state = initialState, { type, payload }) => {
    const reducers = {
        [GET_ROADMAP]: () => {
            const { userProgress, Units } = payload
            const { unitsMock, lessonsMock } = generateProgress(Units)
            const units = userProgress?.units && JSON.parse(userProgress.units)
            const lessons = userProgress?.lessons && JSON.parse(userProgress.lessons)
            const unitsStatus = payload.Units.map(unit => ({ id: unit.id, expanded: false }) )
            return { 
                ...state,
                course: {
                    ...payload,
                    Units: hasOnlyStudentRole(payload) ? filterVisibleTopicsByUnits(payload.Units) : payload.Units,
                    userProgress: {
                        ...userProgress,
                        units: units || unitsMock,
                        lessons: lessons || lessonsMock,
                        firstTime: !units
                    }
                },
                unitsStatus
            }
        },
        [EXPAND_ALL_UNITS]: () => {
            const unitsStatus = state.unitsStatus.map(unit => ({...unit, expanded: true}) )
            return {
                ...state,
                unitsStatus
            }
        },
        [COLLAPSE_ALL_UNITS]: () => {
            const unitsStatus = state.unitsStatus.map(unit => ({...unit, expanded: false}) )
            return {
                ...state,
                unitsStatus
            }
        },
        [EXPAND_UNIT]: () => {
            const unitsStatus = state.unitsStatus.map(unit => {
                if(unit.id === payload.id) {
                    return { ...unit, expanded: true }
                }
                return unit
            })
            return {
                ...state,
                unitsStatus
            }
        },
        [COLLAPSE_UNIT]: () => {
            const unitsStatus = state.unitsStatus.map(unit => {
                if(unit.id === payload.id) {
                    return { ...unit, expanded: false }
                }
                return unit
            })
            return {
                ...state,
                unitsStatus
            }
        },
        [SET_GRADEBOOK]: () => ({
            ...state,
            gradebook: payload
        }),
        [CLEAR_ROADMAP]: () => ({
            ...initialState,
        }),
    }
    return reducers[type] ? reducers[type]() : state
}

export default roadmapReducer