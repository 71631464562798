import React from 'react'
import PropTypes from 'prop-types'
import { Route as ReactRoute } from 'react-router-dom'
import * as env from '@/config/env'

const Route = ({
    exact,
    path,
    component: Component,
    layout: Layout,
}) => {

    return (
        <ReactRoute
            path={path}
            exact={exact}
            render={(props) => (
                <Layout {...props}>
                    {Component && <Component {...props} />}
                </Layout>
            )}
        />
    )
}

Route.defaultProps = {
    exact: false,
    component: null,
    strict: true,
    computedMatch: {
        params: {
            lang: '',
        },
    },
}

Route.propTypes = {
    exact: PropTypes.bool,
    path: PropTypes.string.isRequired,
    component: PropTypes.node,
    computedMatch: PropTypes.shape({
        params: PropTypes.shape({
            lang: PropTypes.string,
        }),
    }),
    layout: PropTypes.node.isRequired,
    strict: PropTypes.bool,
}

export default Route
