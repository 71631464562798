import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const RoadSeparator = ({
    incomplete,
    isMobile
}) => {
    const roadSeparatorClass = cn({
        'incomplete': incomplete
    }, 'separator')

    const processWidth = () => {
        return isMobile ? {width: '23px'} : {width: '50px'}
    }

    return (
        <div className="roadSeparator" style={processWidth()}>
            <div className={roadSeparatorClass} />
        </div>
    )
}

RoadSeparator.propTypes = {
    incomplete: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
}

RoadSeparator.defaultProps = {
    incomplete: false,
}

export default RoadSeparator
