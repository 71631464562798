import React from 'react'
import { Redirect } from 'react-router-dom'
import appGuards, { getGuard } from '@/routes/guards'

const GuardRedirect = () => {
    const currentGuard = getGuard()
    const { defaultPath } = appGuards.find((guard) => guard.name === currentGuard) || {
        defaultPath: '/',
    }
    return (
        <Redirect
            to={{
                pathname: defaultPath,
            }}
        />
    )
}

export default GuardRedirect
