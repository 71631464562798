import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './TablePresence.scss'
import { MINIMUM_TO_APPROVE_PRESENCE } from '@/constants/grades'

const TablePresence = ({presence}) => {
    const classes = useCallback(() => {
        return cn('table-presence', {
            'table-presence--success': presence >= MINIMUM_TO_APPROVE_PRESENCE,
        })
    }, [presence])

    return !presence ? '-' : <div className={classes()}>{presence}%</div>
}

TablePresence.propTypes = {
    presence: PropTypes.number,
}

TablePresence.defaultProps = {
    presence: 0
}

export default TablePresence