import React from 'react'
import PropTypes from 'prop-types'

import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'

import './QuizFooter.scss'

const QuizFooter = ({
    completenessPercentage,
    disabled,
    showSendQuizButton,
    showRedoButton,
    onClickFinishQuiz,
    onClickReDoQuiz,
    isLoading,
    lang,
}) => {
    return (
        (showRedoButton || showSendQuizButton) && (
            <div className='quiz-classroom-footer'>
                {(showSendQuizButton || isLoading) && (
                    <>
                        <p className='quiz-classroom-footer__complete-text'>{`${completenessPercentage}% ${lang.completed}`}</p>
                        <Button
                            id='QuizSendButton'
                            text={lang.sendQuiz}
                            theme='primary'
                            onClick={onClickFinishQuiz}
                            disabled={disabled}
                            loading={isLoading}
                        />
                    </>
                )}
                {showRedoButton && !isLoading && (
                    <Button
                        className='quiz-classroom-footer__button-redo'
                        id='QuizReDoButton'
                        text={lang.reDoQuiz}
                        theme='primary'
                        onClick={onClickReDoQuiz}
                    />
                )}
            </div>
        )
    )
}

QuizFooter.propTypes = {
    completenessPercentage: PropTypes.number,
    disabled: PropTypes.bool,
    showSendQuizButton: PropTypes.bool,
    showRedoButton: PropTypes.bool,
    onClickFinishQuiz: PropTypes.func.isRequired,
    onClickReDoQuiz: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    lang: PropTypes.object,
}

QuizFooter.defaultProp = {
    completenessPercentage: 0,
    showRedoButton: false,
    showSendQuizButton: false,
    isLoading: false,
}

export default QuizFooter