import configureStore from '@/app/store'
import { actions } from './fileManagerStore'
import fileManagerServices from './fileManagerServices'
import moment from 'moment'

const { store } = configureStore

const controllers = {
    getFileList: async (courseId = 0) => {
        store.dispatch(actions.setLoading(true))
        store.dispatch(actions.setFileList([]))
        const fileListGetted = await fileManagerServices.fileList(courseId)
        store.dispatch(actions.setLoading(false))
        store.dispatch(actions.setCourseId(courseId))
        store.dispatch(actions.setFileList(fileListGetted))
    },
    downloadFile: async (path, filename) => {
        store.dispatch(actions.setLoading(true))
        await fileManagerServices.downloadFile(path, filename)
        store.dispatch(actions.setLoading(false))
    },
    uploadFile: async (courseId = 0, file = {}, fileName = '') => {
        store.dispatch(actions.setLoading(true))
        const fileIdGetted = await fileManagerServices.uploadFile(courseId, file, fileName)
        store.dispatch(actions.setLoading(false))
        const newFileForAddedToList = {
            name: fileName,
            id: fileIdGetted,
            date: moment(new Date()).format('YYYY-MM-DD'),
            isEditable: true,
        }
        const filesCopy = [...store.getState().fileManagerStore.fileList]
        filesCopy.push(newFileForAddedToList)
        store.dispatch(actions.setFileList(filesCopy))
    },
    editFile: async (courseId, file = { id: 0, name: '' }) => {
        await fileManagerServices.editFile(courseId, file.id, file.name)
        store.dispatch(actions.updateFile({ id: file.id, name: file.name }))
    },
    deleteFile: async (courseId, fileId) => {
        await fileManagerServices.deleteFile(courseId, fileId)
        store.dispatch(actions.delete(fileId))
    }
}

export default controllers
