import React from 'react'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { emptyStateAsset } from '@/assets/img/config'
import './LoginErrorPage.scss'
import useHistory from '@/hooks/useHistory'

const LoginErrorPage = () => {
    const history = useHistory()
    const imageUrl = emptyStateAsset('404.png')
    const { title, subtitle, goToHome } = {
        title: 'Ups. Ocurrió un error',
        subtitle: 'No te preocupes, serás redirigido a un página para que puedas crear tu contraseña',
        goToHome: 'Si no, haz click aquí'
    }

    setTimeout(() => {
        history.push('/forgot-password')
    }, 10000)

    return (
        <div className='__not_found'>
            <div className='__not_found__content'>
                <img className='__not_found__content--image' src={imageUrl} alt='Not found' />
                <div className='__not_found__content--title'>{title}</div>
                <div className='__not_found__content--subtitle'>{subtitle}</div>
                <div className='__not_found__content--buttons'>
                    <Button
                        id='GoToMenuButton'
                        theme='secondary'
                        text={goToHome}
                        onClick={(e) => {
                            e.preventDefault()
                            history.push('/forgot-password')
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default LoginErrorPage
