'use-strict'

import exerciseTypes from './exerciseTypes'

export default {
    [exerciseTypes.HTML_CSS]: [
        {
            _id: '8bcb6f76-fb18-4483-8afb-441781f2cdd5',
            name: 'index.html',
            content: '',
            section: 'SRC',
            language: 'html',
        },
        {
            _id: 'ccc31f42-2a5f-43bd-a60b-9c8ebd4a84ca',
            name: 'style.css',
            content: '',
            section: 'SRC',
            language: 'css',
        },
        {
            _id: '84ff6e94-9d49-40af-aab5-2f36bef5f1bc',
            name: 'index.html',
            content: '',
            section: 'HIDDENCODE',
            language: 'html',
        },
        {
            _id: '1b15bd0d-0fb5-4dbd-b545-7b12f1181cb8',
            name: 'style.css',
            content: '',
            section: 'HIDDENCODE',
            language: 'css',
        },
        {
            _id: '6adbe352-6d87-4a2a-b65b-42f0c5fa074a',
            name: 'index.html',
            content: '',
            section: 'SOLUTION',
            language: 'html',
        },
        {
            _id: '807500d4-1761-411a-b38f-35b805bd15ac',
            name: 'style.css',
            content: '',
            section: 'SOLUTION',
            language: 'css',
        },
    ],
    [exerciseTypes.JAVASCRIPT]: [
        {
            _id: 'ea255aa6-6200-4602-b878-450d4bb8795f',
            name: 'script.js',
            content: '',
            section: 'SRC',
            language: 'javascript',
        },
        {
            _id: '9fceb850-8c1a-461a-a9f6-1a614c644e59',
            name: 'script.js',
            content: '',
            section: 'HIDDENCODE',
            language: 'javascript',
        },
        {
            _id: 'eb2610d4-8940-4e07-bf63-12e2dbbf42ab',
            name: 'script.js',
            content: '',
            section: 'TESTS',
            language: 'javascript',
        },
        {
            _id: 'bfa86d91-0acc-4eba-9a69-1f43f00231b6',
            name: 'script.js',
            content: '',
            section: 'MOCKS',
            language: 'javascript',
        },
        {
            _id: '5a06fbd0-68fc-4d67-b2b2-eca04094d1d6',
            name: 'script.js',
            content: '',
            section: 'SOLUTION',
            language: 'javascript',
        },
    ],
    [exerciseTypes.DOM]: [
        {
            _id: '8f5dafac-b687-4cda-a476-a80c73b21b7c',
            name: 'index.html',
            content: '',
            section: 'SRC',
            language: 'html',
        },
        {
            _id: 'd845b748-b296-40e2-9b46-57d7e6c84b1e',
            name: 'style.css',
            content: '',
            section: 'SRC',
            language: 'css',
        },
        {
            _id: 'df8de276-b5e1-4af8-836d-04591a495bf5',
            name: 'script.js',
            content: '',
            section: 'SRC',
            language: 'javascript',
        },
        {
            _id: '3211fa2c-c550-44a1-8db9-857ba27a4205',
            name: 'index.html',
            content: '',
            section: 'HIDDENCODE',
            language: 'html',
        },
        {
            _id: '4511c922-499c-4799-afe9-2c512a0356d5',
            name: 'style.css',
            content: '',
            section: 'HIDDENCODE',
            language: 'css',
        },
        {
            _id: '28b2eae2-85bc-4590-82e6-2261e0d8d5bd',
            name: 'script.js',
            content: '',
            section: 'HIDDENCODE',
            language: 'javascript',
        },
        {
            _id: 'b3ff89bc-9ec5-439b-8ecf-804bf0c92006',
            name: 'script.js',
            content: '',
            section: 'MOCKS',
            language: 'javascript',
        },
        {
            _id: '0f48324c-f943-40cd-9cd1-feba67845d75',
            name: 'script.js',
            content: '',
            section: 'TESTS',
            language: 'javascript',
        },
        {
            _id: 'd10a6f76-06a1-40b0-aa56-5e6cf9184264',
            name: 'index.html',
            content: '',
            section: 'SOLUTION',
            language: 'html',
        },
        {
            _id: '4304ce4f-d14e-4e3d-8178-c293f36b9f38',
            name: 'style.css',
            content: '',
            section: 'SOLUTION',
            language: 'css',
        },
        {
            _id: '7f6d09d3-c62c-4bdb-b292-7cea54a15499',
            name: 'script.js',
            content: '',
            section: 'SOLUTION',
            language: 'javascript',
        },
    ],
    [exerciseTypes.JAVA_JDK11]: [
        {
            _id: 'fdd36f51-317f-4a6a-9bd9-8c6d8c00fc40',
            name: 'Main.java',
            content: '',
            section: 'SRC',
            language: 'java',
        },
        {
            _id: '6761c3d3-7c14-4c3a-ba43-e3693b759b38',
            name: 'MainHidden.java',
            content: '// This file will be global and will always be hidden',
            section: 'HIDDENCODE',
            language: 'java',
        },
        {
            _id: '1eec681b-b3b1-497e-abad-52cb4fd427bb',
            name: 'MainTest.java',
            content: `import java.util.*;
import java.util.ArrayList;
import java.util.Collection;
import java.util.Arrays;
import org.junit.*;
import static org.junit.Assert.*;

public class MainTest extends JRCore {
    @Test
    public void must_be_equal() {
        assertEquals(1, 1);
    }

    /**
     * Returns a list that will be used to map 
     * the test methods with the feedback in case the test is unsuccessful
     */
    public static ArrayList<Collection<String>> feedbackMap() {
        ArrayList<Collection<String>> map = new ArrayList<Collection<String>>();
        map.add(Arrays.asList("must_be_equal", "El resultado debe ser igual a 1"));
        return map;
    }

    public static void main(String[] args) {
        JRCore.run(MainTest.class, feedbackMap());
    }
}`,
            section: 'TESTS',
            language: 'java',
        },
        {
            _id: 'da12cdde-d4ab-4553-a8a9-041c8572542d',
            name: 'Main.java',
            content: '',
            section: 'SOLUTION',
            language: 'java',
        },
    ]
}
