import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { X } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import './IncompatibleVersionAlert.scss'

const IncompatibleVersionAlert = ({
    show,
    isAuth,
    showConfirmButton,
    onConfirm,
    onCancel,
    lang,
}) => {
    return (
        <div
            className={cn(
                {
                    'incomplatible-version--hidden': !show,
                },
                'incomplatible-version'
            )}
        >
            <div className='incomplatible-version__alert'>
                <div className='incomplatible-version__alert--text'>
                    {isAuth ? lang.userLoggedIn.message : lang.userLoggedOut.message}
                </div>
                <div className='incomplatible-version__alert--button'>
                    <Button
                        id='ConfirmButton'
                        visible={showConfirmButton}
                        theme='primary'
                        onClick={onConfirm}
                        text={isAuth ? lang.signOut : lang.update}
                    />
                    <X
                        onClick={onCancel}
                        className={cn({
                            'incomplatible-version--hidden': !isAuth,
                        })}
                    />
                </div>
            </div>
        </div>
    )
}

IncompatibleVersionAlert.propTypes = {
    show: PropTypes.bool,
    isAuth: PropTypes.bool,
    showConfirmButton: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    lang: PropTypes.any.isRequired,
}

IncompatibleVersionAlert.defaultProps = {
    show: false,
    isAuth: false,
    showConfirmButton: false,
    onConfirm: () => {},
    onCancel: () => {},
}

export default IncompatibleVersionAlert
