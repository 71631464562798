import React from 'react'
import PropTypes from 'prop-types'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { AlertCircle, X } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { getTimeLeft, formatTimeLeftMessage } from '@/helpers/dates'
import './DeploymentComingSoonAlert.scss'

const DeploymentComingSoonAlert = ({
    show,
    lang,
    onClose,
    hour,
}) => {

    const getFullMessage = () => {
        const { hoursLeft, minutesLeft } = getTimeLeft(hour)
        const timeLeftMsg = formatTimeLeftMessage(hoursLeft, minutesLeft) || '1 min'
        return lang.title.replace('{{hour}}', timeLeftMsg)
    }

    if (!show) return null

    return (
        <div className="floating-alert-warning">
            <div>
                <AlertCircle className="exclamation_icon" />
                <p>{getFullMessage()}</p>
            </div>
            <Button 
                id="CloseAlert"
                className="close_icon" 
                onClick={onClose}
                theme='"no-theme"'
                icon={<X />}
            />
        </div>
    )
}

DeploymentComingSoonAlert.propTypes = {
    show: PropTypes.bool,
    lang: PropTypes.any.isRequired,
    hour: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
}

DeploymentComingSoonAlert.defaultProps = {
    show: false,
}

export default DeploymentComingSoonAlert
