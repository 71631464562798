import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Video } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import lessonTypesConstants from '@/helpers/lessons/lessonTypes'
import { COURSE_ENTITIES_DATA } from '@/constants/courseEntities'
import CourseReportBlockData from './CourseReportBlockData'
import { checkLessonIsReleased } from '@digitalhouse-tech/ts-lib-store/lib/shared/dates'

const CourseReportHeader = ({
    allowApiLessons,
    filteredCourseData,
    dataLevel,
    changeDataLevel,
    totalUsers,
    lang,
}) => {
    const isUnitsLevel = dataLevel === COURSE_ENTITIES_DATA.UNITS
    const isLessonsLevel = dataLevel === COURSE_ENTITIES_DATA.LESSONS
    const isTopicsLevel = dataLevel === COURSE_ENTITIES_DATA.TOPICS

    let nextLevelInfo
    let prefixHeader
    let nextLevelTitle

    if (isUnitsLevel) {
        nextLevelInfo = COURSE_ENTITIES_DATA.LESSONS
        prefixHeader = lang.unitPrefix
        nextLevelTitle = lang.lessonDescription
    } else if (isLessonsLevel) {
        nextLevelInfo = COURSE_ENTITIES_DATA.TOPICS
        prefixHeader = lang.lessonPrefix
        nextLevelTitle = lang.topicDescription
    } else {
        nextLevelInfo = COURSE_ENTITIES_DATA.BLOCKS
        prefixHeader = lang.topicPrefix
        nextLevelTitle = lang.blockDescription
    }

    const detailUnit = (index, totalNextLevelContents, nextContentTitle, courseItem, liveClass) => {
        const title =
            totalNextLevelContents > 0
                ? `(${totalNextLevelContents} ${nextContentTitle})`
                : `(${nextContentTitle}/s ${lang.extraContent})`

        return (
            <>
                <span className='_report_lesson'>
                    {prefixHeader}
                    {index + 1} <span className="_report_lesson_num">{title}</span>
                    {liveClass && allowApiLessons && <Video />}
                </span>
                <p>{courseItem.name}</p>
            </>
        )
    }

    return (
        <div
            className={cn(
                {
                    _report_background_topics: isTopicsLevel,
                },
                '_report_cols',
            )}
            id="_report_detail_cols"
        >
            <div>
                {filteredCourseData.map((courseItem, index) => {
                    const disableCol =
                        !checkLessonIsReleased(courseItem.releaseDate) ||
                        (!courseItem.releaseDate && !isTopicsLevel)

                    const notReleased = filteredCourseData[index][nextLevelInfo].filter(content =>
                        !checkLessonIsReleased(content.releaseDate)
                    ).length

                    //solo a nivel de unidad y si esta liberada, se excluyen las clases no liberadas
                    const totalNextLevelContents = isUnitsLevel
                        ? notReleased > 0
                            ? filteredCourseData[index].mandatoryContent
                            : filteredCourseData[index].mandatoryContent - notReleased
                        : filteredCourseData[index].mandatoryContent

                    let nextContentTitle = nextLevelTitle
                    if (totalNextLevelContents > 1) nextContentTitle += 's'

                    const tooltipContent =
                        isLessonsLevel &&
                        courseItem.LessonType.name === lessonTypesConstants.SYNC &&
                        allowApiLessons
                            ? lang.onlineClass
                            : ''

                    const topicWidth = isTopicsLevel
                        ? {
                            width:
                                filteredCourseData[index][nextLevelInfo].length > 1
                                    ? 50 * filteredCourseData[index][nextLevelInfo].length + 'px'
                                    : '100px',
                        }
                        : {}

                    return (
                        <div
                            className="_report_col"
                            key={`detail_unit_${courseItem.id}`}
                            style={topicWidth}
                        >
                            <div
                                className={cn(
                                    {
                                        _disable_col: disableCol && !isTopicsLevel,
                                        '_no_click_col _report_block_header': isTopicsLevel,
                                    },
                                    '_report_units _report_unit_detail',
                                )}
                            >
                                <Button
                                    id={`student_total_${courseItem.id}`}
                                    className="_report_general_button _report_topic_name"
                                    theme='clear'
                                    text={detailUnit(
                                        index,
                                        totalNextLevelContents,
                                        nextContentTitle,
                                        courseItem,
                                        tooltipContent,
                                    )}
                                    onClick={() => changeDataLevel(courseItem.id, nextLevelInfo)}
                                    tooltipId={`student_tooltip_${courseItem.id}`}
                                    tooltipContent={tooltipContent}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
            {isTopicsLevel && (
                <CourseReportBlockData
                    filteredCourseData={filteredCourseData}
                    totalUsers={totalUsers}
                    lang={lang}
                />
            )}
        </div>
    )
}

CourseReportHeader.defaultProps = {
    allowApiLessons: false,
}

CourseReportHeader.propTypes = {
    allowApiLessons: PropTypes.bool,
    filteredCourseData: PropTypes.array.isRequired,
    dataLevel: PropTypes.string.isRequired,
    changeDataLevel: PropTypes.func.isRequired,
    totalUsers: PropTypes.number.isRequired,
    lang: PropTypes.object.isRequired,
}

export default CourseReportHeader