import React from 'react'
import { Modal } from 'react-bootstrap'
import ReduxModal from '@/components/ReduxModal'
import { DELETE_TOPIC_WARNING_MODAL } from '@/constants/modals'
import useDeleteTopicModal from './useDeleteTopicModal'
import { AlertCircle } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'

const DeleteTopicModal = () => { 

    const { lang, onConfirm, onCancel, deletingTopic } = useDeleteTopicModal()
    
    return (
        <ReduxModal
            id={DELETE_TOPIC_WARNING_MODAL}
            className='__modal __modal_confirmation'
            keyboard={false}
            backdrop='static'
        >
            <Modal.Header closeButton className='__modal_header __border_bottom'>
                <h3 className='__modal_title danger'>{lang.title}</h3>
            </Modal.Header>

            <Modal.Body className='__modal_body'>
                <div className='delete-block-warning-modal-body'>
                    {lang.bodyAsk}
                    <h2 className='warning-title'>
                        {lang.warnings}
                    </h2>
                    <ul className="warnings-list">
                        <li><AlertCircle size={48} />{lang.warningDetail}</li>
                    </ul>
                </div>
            </Modal.Body>

            <Modal.Footer className='__modal_footer __border_top'>
                <ButtonGroup>
                    <Button
                        id='ContitnueButton'
                        theme='tertiary'
                        text={lang.permanentlyDelete}
                        onClick={onConfirm}
                        loading={deletingTopic}
                    />
                    <Button
                        id='CancelButton'
                        theme='primary'
                        text={lang.cancel}
                        onClick={() => onCancel(DELETE_TOPIC_WARNING_MODAL)}
                    />
                </ButtonGroup>
            </Modal.Footer>
        </ReduxModal>
    )
}

export default DeleteTopicModal
