import React from 'react'
import PropTypes from 'prop-types'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import './DigitalTools.scss'

const DigitalTools = ({
    tools,
    lang,
}) => {
    const processTools = () => {
        return tools.map( (tool,index) => {
            const {name, description, tutorial_url, tool_url} = tool
            const key = `${name}${index}`
            return (
                <div className="tool" key={key}>
                    <div className="nameAndDescription">
                        <h2 className="name">{name}</h2>
                        <p className="description">{description}</p>
                    </div>
                    <div className="toolLinks"> 
                        {tool_url && (
                            <Button
                                id='openToolButton'
                                rel="noreferrer"
                                text={lang.open}
                                theme='tertiary'
                                onClick={()=> window.open(tool_url, '_blank')}
                            />
                        )}
                        {tutorial_url && (
                            <Button
                                id='whatchToolTutorialButton'
                                rel="noreferrer"
                                text={lang.tutorial}
                                theme='tertiary'
                                onClick={()=> window.open(tutorial_url, '_blank')}
                            />
                        )}
                    </div>
                </div>
            )
        })
    }

    const processBox = () => {
        return (
            <div className="digital-tools">
                <h2 className="title">{lang.title}</h2> 
                <div className="toolList">
                    {processTools()}
                </div>
            </div>
        )
    }

    return (
        <>
            {tools && tools.length > 0 && processBox()}
        </>
    )
}

DigitalTools.defaultProps = {
    tools: null,
}

DigitalTools.propTypes = {
    tools: PropTypes.array,
    lang: PropTypes.any.isRequired,
}

export default DigitalTools
