export const APPROVED_STATUS = 'APPROVED'
export const DESAPPROVED_STATUS = 'DESAPPROVED'
export const RECOVERY_STATUS = 'RECOVERY'
export const MINIMUM_TO_APPROVE = 7
export const MINIMUM_TO_APPROVE_PROGRESS = 80
export const MINIMUM_TO_APPROVE_PRESENCE = 90
// gradebook modal props
export const UPLOAD = 'UPLOAD'
export const SEND = 'SEND'

export const GRADES_STEPS = {
    CREATED: 'CREATED',
    PUBLISHED: 'PUBLISHED',
}
