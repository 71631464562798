import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './onboardingRemoteBalloon.scss'
import { Overlay, Popover } from 'react-bootstrap'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { X } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { ONBOARDING_TYPE } from '@/constants/onboarding'

const OnboardingRemoteBalloon = ({
    reference,
    subComponents,
    update,
    lang,
}) => { 
    const [show,setShow] = useState(true)
    const confirm = () => {
        update(ONBOARDING_TYPE.SIDE_POPOVER)
        setShow(false)
    }
    const placement = 'bottom'
    return(   
        <div className='overCinema__container'>
            <li className="overCinema">
                {subComponents}
            </li>
            <li>
                <div className={show ? "backCinema" : undefined} />
            </li>
            <Overlay
                target={reference && reference.current}
                show={show}
                placement={placement}
            >
                <Popover id="onboardingNavPopover" positionLeft={1}>
                    <div className="onboardingPopoverHeader">
                        <Button 
                            id="closeIconRemote"
                            className="close_icon" 
                            theme="no-theme"
                            icon={<X />}
                            onClick={()=>confirm()}
                        />
                    </div>
                    <div className="onboardingPopoverBody">
                        <p className="paragraph">{lang.onboardingNavMessage}</p>
                    </div>
                    <div className="onboardingPopoverFooter">
                        <div className="_btn_group popoverConfirm">
                            <Button
                                id="CancelButton"
                                visible
                                theme='secondary'
                                onClick={()=>confirm()}
                                text={lang.confirmMessage}
                            />
                        </div>
                    </div>
                </Popover>
            </Overlay>
            
        </div> )

}

OnboardingRemoteBalloon.defaultProps = {
    reference: undefined,
    subComponents: undefined,
}

OnboardingRemoteBalloon.propTypes = {
    lang: PropTypes.any.isRequired,
    update: PropTypes.func.isRequired,
    reference: PropTypes.object,
    subComponents: PropTypes.element,
}
export default OnboardingRemoteBalloon