import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { track } from '@digitalhouse-tech/react-sdk-analytics'
import { getLoggedUser } from '@/redux/selectors/user'
import { BLOCK_ISSUES_MODAL } from '@/constants/modals'
import { getContentMap } from '@/redux/selectors/students/classroom'
import { actions as modalActions } from '@/redux/reducers/modalReducer'
import { modalSteps, modalStepDirections } from './constants/modalSteps'
import { blockIssueReport } from '@/constants/trackEvents'
import { enableBlockReport } from '@/helpers/granters/courseTypeRules'
import { loadBlockIssuesOptions } from '@/redux/thunks/blockIssueOptionsThunks'
import { PdfBlock, SlideBlock } from '@/constants/blocks'

export default () => {
    const dispatch = useDispatch()

    const lang = useSelector(({ langStore }) => langStore.blockIssues)
    const { id: user_id } = useSelector(getLoggedUser)
    const modalState = useSelector(({ modal }) => modal)
    const {
        Course: { id: course_id, alias: course_alias, CourseType },
        id: unit_id,
        name: unit_name,
        Lessons: lessons,
    } = useSelector(getContentMap)
    const blockIssueOptions = useSelector(({ student }) => student.blockIssueOptions.all)

    const enableBlockIssueReport = enableBlockReport(CourseType)

    const { lessonId: lesson_id, topicId: topic_id } = useParams()
    const currentLesson = lessons.find((lesson) => lesson.id === lesson_id)
    const topicName = currentLesson?.Topics.find((topic) => topic.id === topic_id)?.name

    useEffect(() => {
        if (enableBlockIssueReport && !blockIssueOptions.length) dispatch(loadBlockIssuesOptions())
    }, [])

    const defaultIssuesType = 'Default'
    const blocksWithParticularIssues = ['Quiz', 'Evaluable', 'Exercise']

    const [blockId, setBlockId] = useState(null)
    const [blockName, setBlockName] = useState(null)
    const [blockType, setBlockType] = useState(null)
    const [modalStep, setModalStep] = useState(modalSteps.OPTIONS)
    const [selectedIssue, setSelectedIssue] = useState(null)
    const [selectedIssueIndex, setSelectedIssueIndex] = useState(null)
    const [issueExplanation, setIssueExplanation] = useState(null)
    const [issuesByBlockType, setIssuesByBlockType] = useState([])
    const [issueImages, setIssueImages] = useState([])

    const changeModalStep = (direction, isFinish) => {
        if (isFinish) {
            closeBlockIssueModal()
            setModalStep(modalSteps.OPTIONS)
            return
        }
        const currentStep = modalStep
        const nextStep =
            direction === modalStepDirections.BACK
                ? modalSteps.OPTIONS
                : currentStep === modalSteps.OPTIONS
                ? modalSteps.EXPLANATION
                : modalSteps.SUCCESS_MESSAGE
        setModalStep(nextStep)
    }

    const selectIssue = (issueIndex, selectedIssue) => {
        setSelectedIssue(selectedIssue)
        setSelectedIssueIndex(issueIndex)
    }

    const nextStep = () => changeModalStep(modalStepDirections.FORWARD)

    const handleIssueImagesUpload = (files) => setIssueImages(files)

    const changeIssueExplanation = (value) => {
        setIssueExplanation(value)
    }

    const closeBlockIssueModal = () => {
        dispatch(modalActions.close())
    }

    const openBlockIssueModal = (blockId, blockName, blockType) => {
        dispatch(modalActions.open(BLOCK_ISSUES_MODAL, { blockId }))

        let type = blockType.replace('Block', '')
        if (!blocksWithParticularIssues.includes(type)) type = defaultIssuesType

        const issues = blockIssueOptions
            .filter((option) => option.blockType === type)
            .map((opt) => opt.issueOption)
        issues.push(issues.splice(issues.indexOf(lang.otherOption), 1).pop())

        setIssuesByBlockType(issues)
        setBlockId(blockId)
        setBlockType(blockType)
        setBlockName(blockName)
    }

    const issueImagesUrls = (files) => files.map(({url}) => url)

    const sendTrackingData = () => {
        const trakingData = {
            context: blockIssueReport.EVENT_CONTEXT,
            label: blockIssueReport.EVENT_LABEL,
            user_id,
            fields: {
                course_id,
                course_alias,
                unit_id,
                unit_name,
                lesson_id,
                lesson_name: currentLesson?.name,
                topic_id,
                topic_name: topicName,
                block_id: blockId,
                block_name: blockName,
                block_type: blockType,
                reason_selected: selectedIssue,
                comment: issueExplanation,
                source: issueImagesUrls(issueImages)
            },
        }

        track(blockIssueReport.EVENT_NAME, trakingData)
        changeModalStep(modalStepDirections.FORWARD)
        setSelectedIssue(null)
        setSelectedIssueIndex(null)
        setIssueExplanation(null)
        setIssueImages([])
    }

    const showModal = BLOCK_ISSUES_MODAL === modalState.id && modalState.data.blockId === blockId

    // eslint-disable-next-line react-hooks/exhaustive-deps
     const getDownloadUrl = useCallback((url, type) => {
        let newUrl = url
        // Google case only
        if (type === PdfBlock) {
            if (url.includes('.com/file/u/1/d/')) {
                newUrl = url.replace('.com/file/u/1/d/', '.com/uc?export=download&id=')
            } else if (url.includes('.com/file/d/')) {
                newUrl = url.replace('.com/file/d/', '.com/uc?export=download&id=')
            }
    
            newUrl = newUrl.replace('?usp=sharing', '')
            newUrl = newUrl.replace('/view', '')
            newUrl = newUrl.replace('/preview', '')
        }
        if (type === SlideBlock) {
            if (newUrl.includes('embed')) {
                newUrl = newUrl.split('embed')
                newUrl = newUrl[0] + 'export/pdf'
                newUrl = newUrl.replace('e/2PACX-', '')
            } else if (newUrl.includes('edit')) {
                newUrl = newUrl.split('edit')
                newUrl = newUrl[0] + 'export/pdf'
            } else if (newUrl.includes('preview')) {
                newUrl = newUrl.split('preview')
                newUrl = newUrl[0] + 'export/pdf'
            }
        }
    
        return newUrl
    }) 

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleDownload = useCallback((url, type) => window.open(getDownloadUrl(url, type), '_blank'))

    return {
        enableBlockIssueReport,
        openBlockIssueModal,
        closeBlockIssueModal,
        modalStep,
        changeModalStep,
        issueExplanation,
        changeIssueExplanation,
        selectedIssueIndex,
        selectIssue,
        issuesByBlockType,
        sendTrackingData,
        showModal,
        lang,
        nextStep,
        issueImages,
        handleIssueImagesUpload,
        handleDownload
    }
}
