import React from 'react'
import PropTypes from 'prop-types'
import CustomCargando from '../CustomCargando'

const LoadingOrContent = ({ loading, children, loadingComponent }) => {
    if (loading) {
        return loadingComponent || <CustomCargando />
    }

    return children
}

LoadingOrContent.propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    loadingComponent: PropTypes.node,
}

LoadingOrContent.defaultProps = {
    loadingComponent: null
}

export default LoadingOrContent