import React, { useEffect } from 'react'
import Select from 'react-select'
import { FormControl } from 'react-bootstrap'
import { CustomFormField } from '@/components/CustomFormField'
import './UserFiscalId.scss'

const UserFiscalId = ({ isEdit, lang, types, activeUser, onChangeType, onChangeNumber, errors }) => {
    // these effects handle an issue with initial value undefined on state after user data loaded
    useEffect(() => {
        if (activeUser.fiscal_id_type) onChangeType({ value: activeUser.fiscal_id_type})
    }, activeUser.fiscal_id_type)

    useEffect(() => {
        if (activeUser.fiscal_id_number) onChangeNumber({ target: { value: activeUser.fiscal_id_number } })
    }, activeUser.fiscal_id_number)

    return (
        <div className='user-fiscal-id__container'>
            <div className='user-fiscal-id__fields'>
                <CustomFormField
                    className='user-form__id-field'
                    bsSize='small'
                    controlId='fiscalType'
                    label={lang.fiscalIdTypeLabel}
                    inputComponent={
                        <Select
                            name={lang.idType}
                            placeholder={lang.selectTypeOption}
                            id='fiscalType'
                            key='fiscalType'
                            options={types.map((doc) => (
                                {
                                    value: doc.name,
                                    label: `${doc.flag} ${doc.name}`,
                                }
                            ))}
                            onChange={onChangeType}
                            defaultValue={types.map(() => {
                                const defaultValue = types.find((type) => type.name === activeUser.fiscal_id_type);

                                if (!defaultValue) return null;

                                return {
                                    value: defaultValue.name,
                                    label: `${defaultValue.flag} ${defaultValue.name}`,
                                }
                            })}
                            isClearable
                        />
                    }
                    validationState={errors.type.error ? 'error' : null}
                    validationMessage={errors.type.message}
                />

                <CustomFormField
                    className='user-form__id-type-field'
                    bsSize='small'
                    controlId='fiscalId'
                    label={lang.fiscalIdNumberLabel}
                    inputComponent={
                        <FormControl
                            key='fiscalId'
                            bsSize='small'
                            defaultValue={isEdit ? activeUser.fiscal_id_number : null}
                            type='text'
                            autoFocus
                            onChange={onChangeNumber}
                        />
                    }
                    validationState={errors.number.error ? 'error' : null}
                    validationMessage={errors.number.message}
                />
            </div>
        </div>
    )
}

export default UserFiscalId
