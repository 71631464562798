import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { getLoggedUser } from '@/redux/selectors/user'
import { getAllCourses } from '@/redux/selectors/course'
import OnboardingWelcomeModal from './OnboardingWelcomeModal'
import OnboardingRemoteBalloon from './OnboardingRemoteBalloon'
import OnboardingMenuPopover from './OnboardingMenuPopover'
import { updateOnboarding } from '@/redux/reducers/_deprecated/authReducer'
import { ONBOARDING_TYPE } from '@/constants/onboarding'
import { ENABLE_ONBOARDING } from '@/constants/courseRules'

const OnboardingContainer = ({
    reference,
    children,
    step,
    className
}) => {
    const dispatch = useDispatch()
    const courses = useSelector(getAllCourses)
    const { onboarding, id:userId } = useSelector(getLoggedUser)
    const lang = useSelector(({
        langStore: { onboarding }}) => ({ ...onboarding }))
    const { isMobile } = useSelector(({ 
        authReducer: { isMobile },}) => ({ isMobile }))
    const { notifications } = useSelector(({
        langStore: { notifications }}) => ({ notifications }))
    const { navigation } = useSelector(({ 
        navigationReducer: { navigation } }) => ({ navigation }))
    const { tabs } = navigation
    const { remote_classes } = notifications.subTypes

    const updateOnboardingStatus =  (status) => {
        dispatch(updateOnboarding(userId,status))
    }

    const findRemoteClassTab = () =>{
        return tabs && tabs.find( tab =>
            tab.name.toLowerCase() === remote_classes.toLowerCase()
            && tab.visibility)
    }

    const getOnboardingComponent = (type) => {
        const types = {
            [ONBOARDING_TYPE.FIRST_TIME]: (
                <OnboardingWelcomeModal 
                    update={updateOnboardingStatus}
                    lang={lang}
                />
            ),
            [ONBOARDING_TYPE.NAV_POPOVER]: (
                <OnboardingRemoteBalloon
                    reference={reference}
                    subComponents={children}
                    update={updateOnboardingStatus}
                    lang={lang}
                />
            ),
            [ONBOARDING_TYPE.SIDE_POPOVER]: (
                <OnboardingMenuPopover
                    update={updateOnboardingStatus}
                    lang={lang}
                    subComponents={children}
                    className={className}
                />
            ),
        }
        return types[type]
    }

    const findCourseEnabledOnboarding = () => {
        return courses.find(course=> course.CourseType.Rules.includes(ENABLE_ONBOARDING))
    }
    
    const checkFirstTime = () => {
        return onboarding === ONBOARDING_TYPE.FIRST_TIME 
        && step === onboarding
        && courses.length 
        && courses.find(course=>course.started===1)
        && findCourseEnabledOnboarding() 
    }

    const checkNavPopoverWithTab = () => {
        return onboarding === ONBOARDING_TYPE.NAV_POPOVER 
        && findRemoteClassTab() 
        && step === onboarding
    }

    const checkNavPopoverWithoutTab = () => {
        return onboarding === ONBOARDING_TYPE.NAV_POPOVER 
        && !findRemoteClassTab() 
        && step === ONBOARDING_TYPE.SIDE_POPOVER
    }

    const checkSidePopover = () => {
        return onboarding === ONBOARDING_TYPE.SIDE_POPOVER 
        && step === onboarding
    }

    const selectOnboarding = () =>{
        if(checkFirstTime()){
            return getOnboardingComponent(ONBOARDING_TYPE.FIRST_TIME)
        }
        if(checkNavPopoverWithTab()){
            return getOnboardingComponent(ONBOARDING_TYPE.NAV_POPOVER)
        }
        if(checkNavPopoverWithoutTab()){
            updateOnboardingStatus(ONBOARDING_TYPE.SIDE_POPOVER)
            return getOnboardingComponent(ONBOARDING_TYPE.SIDE_POPOVER)
        }
        if(checkSidePopover()){
            return getOnboardingComponent(ONBOARDING_TYPE.SIDE_POPOVER)
        }
    }

    return (
        <>
            { onboarding!==ONBOARDING_TYPE.NAV_POPOVER && children }
            { !isMobile && selectOnboarding() }
        </>
    )
}

OnboardingContainer.defaultProps = {
    children: null,
    reference: null,
    className: null
}

OnboardingContainer.propTypes = {
    reference: PropTypes.any,
    step: PropTypes.string.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
}



export default OnboardingContainer
