import { useState, useEffect } from 'react'

export default ({ random }) => {
    const [stateLogs, setStateLogs] = useState([])
    const [stateThrows, setStateThrows] = useState([])

    useEffect(() => {
        setStateLogs([])
        setStateThrows([])
    }, [random])

    useEffect(() => {
        const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
        const eventer = window[eventMethod]
        const messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message'
        eventer(
            messageEvent,
            function (e) {
                if (e.data.source === 'captured_logs') {
                    const filteredLogs = []
                    const filteredThrows = []
                    e.data.logs.forEach((log) => {
                        const logTypes = {
                            log: () => filteredLogs.push(log.logs),
                            throw: () => filteredThrows.push(log.msg),
                        }
                        logTypes[log.type]()
                    })
                    setStateLogs(filteredLogs)
                    setStateThrows(filteredThrows)
                }
            },
            false
        )
    }, [])

    return { stateLogs, stateThrows }
}
