import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Section from './Section'

const Sections = ({
    show,
    sections,
    readOnlySections,
    files,
    activeFile,
    openFile,
    setActiveFile,
    setDefaultFile,
    isAdministration,
    setShowPopUp,
    allowFilesNameChange,
}) => {
    const [sectionsStatus, setSectionsStatus] = useState(
        sections.map((section) => ({
            name: section,
            toggle: true,
        }))
    )
    const onChangeSectionStatus = (section) => {
        const sectionsCopy = [...sectionsStatus]
        const currentSection = sectionsCopy.find((s) => s.name === section.name)
        currentSection.toggle = !currentSection.toggle
        setSectionsStatus(sectionsCopy)
    }
    return (
        <>
            <ul className='sections' show={`${show}`}>
                {sectionsStatus.map((section, i) => {
                    const sectionFiles = files
                        .filter((f) => f.section === section.name)
                        .sort((a, b) => a.name.localeCompare(b.name))
                    return (
                        <Section
                            i={i}
                            section={section}
                            files={sectionFiles}
                            readOnlySections={readOnlySections}
                            activeFile={activeFile}
                            openFile={openFile}
                            setActiveFile={setActiveFile}
                            setDefaultFile={setDefaultFile}
                            isAdministration={isAdministration}
                            setShowPopUp={setShowPopUp}
                            allowFilesNameChange={allowFilesNameChange}
                            onChangeSectionStatus={onChangeSectionStatus}
                        />
                    )
                })}
            </ul>
        </>
    )
}

Sections.defaultProps = {
    readOnlySections: [],
    isAdministration: false,
}

Sections.propTypes = {
    show: PropTypes.bool.isRequired,
    sections: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    readOnlySections: PropTypes.arrayOf(PropTypes.string),
    files: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            content: PropTypes.string,
            language: PropTypes.string,
            section: PropTypes.string,
            defaultOpen: PropTypes.bool,
        })
    ).isRequired,
    activeFile: PropTypes.shape({
        name: PropTypes.string,
        content: PropTypes.string,
        language: PropTypes.string,
        section: PropTypes.string,
        defaultOpen: PropTypes.bool,
    }).isRequired,
    openFile: PropTypes.func.isRequired,
    setActiveFile: PropTypes.func.isRequired,
    setDefaultFile: PropTypes.func.isRequired,
    isAdministration: PropTypes.bool,
    setShowPopUp: PropTypes.func.isRequired,
}

export default Sections
