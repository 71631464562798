import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { page } from '@digitalhouse-tech/react-sdk-analytics'
import { formatConfig } from '@/helpers/dates/timezoneConfig'
import { setLocation } from '@/routes/guards'
import { WELCOME_LOCATION } from '@/constants/locations'
import { createChatbot, destroyChatbot } from '@/redux/reducers/chatbotReducer'
import { loadAllStudentEnrolments, loadNextCourseLesson } from '@/redux/thunks/courseThunks'
import { studentEggAttendance } from '@/redux/thunks/student/welcome'
import { getAllCourses } from '@/redux/selectors/course'
import { getLoggedUser } from '@/redux/selectors/user'
import { updatePersonalInfo } from '@/redux/reducers/_deprecated/authReducer'
import nextOnSite from '../helpers/nextOnSiteLesson'
import { isCurrentPlatformVersion } from '@/helpers/users/helpers'
import { studentHomePage } from '@/constants/pageViewEvents'
import { loadNextCoursesPage } from '@/redux/thunks/courseThunks'

import {
    loadSurveyCourseUser,
    returnToWelcomeAction,
} from '../../../../redux/reducers/_deprecated/surveyReducer'

const noop = () => {}

const CONFIRM_MODAL_STATE = {
    show: false,
    confirmTitle: '',
    cancelTitle: '',
    body: '',
    onConfirm: noop,
}

export const useWelcome = () => {
    const dispatch = useDispatch()

    const user = useSelector(getLoggedUser)
    const courses = useSelector(getAllCourses)
    const starredCourses = useSelector(({ course }) => course.starredCourses)
    const onGoingCourses = useSelector(({ course }) => course.ongoingCourses)
    const finishedCourses = useSelector(({ course }) => course.finishedCourses)

    const [confirmModal, setConfirmModal] = useState(CONFIRM_MODAL_STATE)

    const { isMobile, showGreeting, mustChangePassword } = useSelector(
        ({ authReducer: { isMobile, showGreeting, user } }) => ({
            isMobile,
            showGreeting,
            mustChangePassword: user.mustChangePassword,
        })
    )

    const loading = useSelector(({ loading }) => loading.course)
    const nextCourseLesson = useSelector(({ course }) => course.nextCourseLesson)
    const lang = useSelector(({ langStore }) => ({
        welcome: langStore.welcomeScreen,
        greeting: langStore.greeting,
    }))

    const updateUserPersonalInfo = (data) => dispatch(updatePersonalInfo(data))

    const [courseId, nextOnSiteCourseName, nextOnSiteDate, showCompleteDate, onSiteLessonLink] =
        nextCourseLesson ? nextOnSite(nextCourseLesson) : [null, '', '']

    useEffect(() => {
        setLocation(WELCOME_LOCATION)
        if (isCurrentPlatformVersion()) page(studentHomePage, {
            platformVersion: '1.x',
        })
        dispatch(loadSurveyCourseUser())
        dispatch(returnToWelcomeAction())
        dispatch(loadNextCourseLesson())
        if(!starredCourses.courses.length && !onGoingCourses.courses.length && !finishedCourses.courses.length){
            dispatch(loadAllStudentEnrolments()).then(() => dispatch(createChatbot()))
        }

        return () => dispatch(destroyChatbot())
    }, [dispatch])

    const handleNextPage = async (filter) => {
        await dispatch(loadNextCoursesPage(filter))
    }

    const hasFinishedCourses = !!finishedCourses.courses.length

    const hasEnrolments = !!onGoingCourses.courses.length

    const isOnSiteLessonStarted = () => new Date(nextOnSiteDate) <= new Date(moment().format())

    const formatedDate = () => {
        const hourFormat = nextOnSiteDate.split(':')[1] === '00' ? 'kk' : 'kk:mm'
        return formatConfig(
            nextOnSiteDate,
            'ddd DD/MM',
            ` [a las] ${hourFormat}[hs]`,
            showCompleteDate
        )
    }

    const goToLiveClass = () => {
        dispatch(studentEggAttendance(user.id, courseId))
        window.open(onSiteLessonLink, '_blank')
    }

    return {
        confirmModal,
        finishedCourses,
        hasEnrolments,
        hasFinishedCourses,
        isMobile,
        lang,
        loading,
        mustChangePassword,
        onGoingCourses,
        setConfirmModal,
        showGreeting,
        starredCourses,
        thereIsComingLesson: !!nextCourseLesson,
        updateUserPersonalInfo,
        isOnSiteLessonStarted,
        nextOnSiteCourseName,
        formatedDate,
        user,
        onSiteLessonLink,
        goToLiveClass,
        handleNextPage,
    }
}
