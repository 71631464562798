import React from 'react'
import PropTypes from 'prop-types'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import UserManualLink from '../UserManualLink/UserManualLink'
import TemplateButton from '../TemplateButton'

import './Footer.scss'

const Footer = ({ openModal, lang }) => {
    return (
        <div className='grades-footer'>
            <UserManualLink />
            <ButtonGroup>
                <TemplateButton />
                <Button id='update-grades' onClick={openModal} text={lang.table.uploadGrades} />
            </ButtonGroup>
        </div>
    )
}

Footer.propTypes = {
    openModal: PropTypes.func,
    lang: PropTypes.object,
}

Footer.defaultProps = {
    openModal: () => {},
    lang: {},
}

export default Footer
