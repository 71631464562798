import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const TermsAndConditions = ({ lang }) => {
    return (
        <div id="legalDisclaimerConianer" className="container __legaldisclaimer">
            <div className="__card">
                <h1 style={{ textAlign: "center" }}> {lang.termsAndConditionsTitle} </h1>
                <br />
                <ol id="termsAndConditions" dangerouslySetInnerHTML={{ __html: lang.termsAndConditionsBody }} />
                <br />
            </div>
        </div>
    )
}

TermsAndConditions.propTypes = {
    lang: PropTypes.any.isRequired
}

const mapStateToProps = state => ({
    lang: state.langStore.legalTerms,
})

export default connect(mapStateToProps)(TermsAndConditions)
export { TermsAndConditions, mapStateToProps }