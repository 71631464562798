import React from 'react'
import PropTypes from 'prop-types'
import { Check, AlertCircle } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import './Feedback.scss'
import cn from 'classnames'

const Feedback = ({ hasFeedback, feedback, children, isCorrectChoice, imageChoice, lang }) => {
    const isRightFeedback = isCorrectChoice
    if (!hasFeedback) {
        return children
    }

    return (
        <div>
            {children}
            <div
                className={cn('choice-feedback', {
                    'choice-feedback--right-image-feedback': imageChoice && isRightFeedback,
                    'choice-feedback--wrong-image-feedback': imageChoice && !isRightFeedback,
                })}
            >
                <div
                    className={cn('choice-feedback__icon', {
                        'choice-feedback__icon--wrong': !isRightFeedback,
                    })}
                >
                    {isRightFeedback ? (
                        <Check />
                    ) : (
                        <AlertCircle />
                    )}
                </div>
                <div className='choice-feedback__text'>{feedback}</div>
            </div>
        </div>
    )
}

Feedback.propTypes = {
    hasFeedback: PropTypes.bool,
    feedback: PropTypes.string,
    children: PropTypes.node.isRequired,
    isCorrectChoice: PropTypes.bool,
    hasImage: PropTypes.bool,
}

Feedback.defaultProps = {
    hasFeedback: false,
    feedback: '',
}

export default Feedback
