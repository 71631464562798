import { VERSION } from '@/config/env'

export const iframeConfigString = (iframeId, contentId, data, playstrapUrl, lookandfeelUrl) => `
<script src="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.0.1/highlight.min.js" integrity="sha512-W7EehcwtSbRF63FIQlXEOOd5mnq0Et0V0nUOvwcUvjnCKgOLLYbqriQxEQSp63sfrkryxIg/A/O8v8O18QwQCQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
<script>
    window.onload = function() {
        const iframe = window.parent.document.getElementById('${iframeId}')
        const container = document.getElementById('${contentId}')
        const blocks = iframe.contentWindow.document.querySelectorAll('pre code')
        blocks.forEach(block => {
            if (block.className.includes('html')) {
                block.innerHTML = block.innerHTML.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;')
            }
            hljs.highlightElement(block)
        })
        const height = Math.max(
            document.documentElement["clientHeight"],
            document.body["scrollHeight"],
            document.documentElement["scrollHeight"],
            document.body["offsetHeight"],
            document.documentElement["offsetHeight"]
        )
        iframe.style.height = height + 'px'
    }
</script>
<link type="text/css" rel="Stylesheet" href="${playstrapUrl}?v=${VERSION}" />
<link type="text/css" rel="Stylesheet" href="${lookandfeelUrl}?v=${VERSION} " />
<div id='${contentId}' class='lesson__markdown__body'>${data}</div>
<base target='_blank' />
`
