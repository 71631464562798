import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import 'react-datepicker/dist/react-datepicker.css'

import { BLOCK_TYPES } from '@/constants/blocks'
import { validateEmptyString } from '@/helpers/strings'
import { getSelectOptions } from '@/helpers/arrays'
import SummerNote from '@/components/Wysiwyg'
import BlockModal from './BlockModal'
import { hasAnyProgressSelector, createModalDataSelector } from '@/redux/selectors'
import { EMPTY_FIELD } from './constants/errorTypes'
import { stripHtml } from '@/helpers/utils'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

const DEFAULT_TEXT_BLOCK = {
    TextBlock: {
        text: '', 
        extra: false,
    },
}
const langSelector = ({ langStore: { modalTextBlock, modalExerciseBlock } }) => ({
    ...modalTextBlock,
    ...modalExerciseBlock,
})

const textBlockSelector = createModalDataSelector(DEFAULT_TEXT_BLOCK)

const ModalTextBlock = () => {
    const lang = useSelector(langSelector)
    const hasAnyProgress = useSelector(hasAnyProgressSelector)
    const data = useSelector(textBlockSelector)
    const [text, setText] = useState(data.TextBlock.text)
    const [textError, setTextError] = useState(null)
    const [extra, setExtra] = useState(data.extra)

    const values = { text }

    const validateInputValues = useCallback(() => {
        const error = validateEmptyString(stripHtml(text), lang.requiredTextMessage)
        setTextError(error)
        return error ? [EMPTY_FIELD] : []
    }, [lang, text])

    const blockTitle = data.id ? lang.modalTextTitleEdit : lang.modalTextTitleCreate
    const selectOptions = getSelectOptions(lang.yes, lang.no)
    const getValue = (option) => (option ? selectOptions[0] : selectOptions[1])
    const id = BLOCK_TYPES.TEXT_BLOCK.modal

    return (
        <BlockModal
            validateInputValues={validateInputValues}
            block={data}
            values={values}
            lang={lang}
            blockTitle={blockTitle}
            blockType={BLOCK_TYPES.TEXT_BLOCK}
            id={id}
            blockId={data.id}
            visibility={data.visibility}
            show_name={data.show_name}
            name={data.name}
            extra={extra}
        >
            <Row>
                <Col lg="12" md="12">
                    <Label text={lang.isExtraLabel} />
                    <InputComboBox
                        disabled={hasAnyProgress}
                        inputName='extra'
                        placeholder={lang.select}
                        id='extra'
                        dropdownItems={selectOptions.map(({ label }) => label)}
                        onChange={label => setExtra(label === selectOptions[0].label)}
                        value={getValue(extra).label}
                    />
                </Col>
                <Col lg="12" md="12">
                    <Label text={lang.textLabel} />
                </Col>
                <Col lg="12" md="12">
                    <div className={textError ? '__wysiwyg--error' : null}>
                        <SummerNote value={text} onChange={(text) => setText(text)} />
                    </div>
                </Col>
            </Row>
        </BlockModal>
    )
}
export default ModalTextBlock
