import { useMemo, useRef, useEffect, useState } from 'react'
import { isEmpty } from 'lodash/lang'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { saveBlockProgressByTopic } from '@/redux/thunks/student/progress'
import { getBlockProgressesToReportByID } from '@/redux/selectors/students/progress'
import { getCourseFinishDate } from '@/redux/selectors/students/classroom'
import useTopicNav from './Navigators/useTopicNav'
import { getBlockData } from '../utils'
import moment from 'moment'

import {
    FileBlock,
    PdfBlock,
    SlideBlock,
    TextBlock,
    VideoBlock,
    TextHtmlBlock,
    QuizBlock
} from '../../../../constants/blocks'
import { EMPTY_LIST } from '@/constants/utils'

const PROGRESS_TEXT = 'progress-text'
const PROGRESS_SLIDE = 'progress-slide'
const PROGRESS_PDF = 'progress-pdf'
const PROGRESS_VIDEO = 'progress-video'
const PROGRESS_FILE = 'progress-file'
const PROGRESS_TEXT_HTML = 'progress-text-html'

const SHEMAS = {
    [FileBlock]: PROGRESS_FILE,
    [PdfBlock]: PROGRESS_PDF,
    [SlideBlock]: PROGRESS_SLIDE,
    [TextBlock]: PROGRESS_TEXT,
    [VideoBlock]: PROGRESS_VIDEO,
    [TextHtmlBlock]: PROGRESS_TEXT_HTML,
}

const HEIGHT_SCROLL_LIMIT = 429

export default ({ content, courseId, topicId, isMobile, showLastBlock, isClassroomLoaded }) => {
    const dispatch = useDispatch()
    const sliderRef = useRef(null)
    const { unitId } = useParams()

    const [currentBlock, setCurrentBlock] = useState({})
    const [needScroll, setNeedScroll] = useState(false)

    const { hasActiveQuiz } = useTopicNav({ isMobile, blockId: currentBlock && currentBlock.id })

    const Blocks = useMemo(() => {
        if (!content.topic) {
            return EMPTY_LIST
        }
        return content.topic.Blocks
    }, [content.topic])

    const blocksIds = useMemo(() => Blocks.map(({ id }) => id), [Blocks])

    const blockProgressesByIds = useSelector(getBlockProgressesToReportByID(blocksIds))

    const finish_date = useSelector(getCourseFinishDate())

    const isCourseExpired = useMemo(() => moment().isAfter(moment(finish_date)), [finish_date])

    /**
     * Get if you should report progress by topic.
     * @returns {Boolean}
     */
    const shoudReportProgressByTopic = useMemo(() => {
        if (!isClassroomLoaded || isCourseExpired) {
            return false
        }

        const nonConsumableBlocks = Blocks.filter((block) => {
            const { blockType } = getBlockData(block)
            return !!SHEMAS[blockType]
        })

        const hasSameBlocksToReport = blockProgressesByIds.length === nonConsumableBlocks.length
        const hasAllCompleted = blockProgressesByIds.every(({ completed }) => !!completed)

        return !(hasSameBlocksToReport && hasAllCompleted)
    }, [Blocks, blockProgressesByIds, isClassroomLoaded])

    const stepBlocks = useMemo(() => {
        return Blocks.map(({ name, id, ...block }, index) => {
            const { blockType } = getBlockData(block)
            return { name, id, index, blockType }
        })
    }, [Blocks])

    const showBottomNav = useMemo(() => {
        const blocksWithBottomNav = [TextHtmlBlock, TextBlock, QuizBlock]

        return needScroll && !hasActiveQuiz && blocksWithBottomNav.includes(currentBlock.blockType)
    }, [needScroll, hasActiveQuiz, currentBlock])


    /**
     * this is to dynamically handle slider height when swiping in mobile.
     * @todo refactor.
     */
    useEffect(() => {
        if (!isMobile) {
            return
        }
        setNeedScroll(false)
        setTimeout(() => {
            const { offsetHeight } = document.querySelector('.slider-container') || {}
            setNeedScroll(offsetHeight >= HEIGHT_SCROLL_LIMIT)
        }, 100)
    }, [currentBlock, isMobile])

    useEffect(() => {
        const index = showLastBlock ? stepBlocks.length - 1 : 0
        sliderRef.current && sliderRef.current.slickGoTo(index)
        setCurrentBlock(stepBlocks[index])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepBlocks, showLastBlock])

    /**
     * Report bulk progress if necessary.
     * @returns dispatch saveBlockProgressByTopic if have progresses.
     */
    useEffect(() => {
        if (shoudReportProgressByTopic) {
            const progresses = Blocks.reduce((init, { id: block_id, ...block }) => {
                const { blockType } = getBlockData(block)
                if (!SHEMAS[blockType]) {
                    return init
                }
                return [...init, { block_id, schema: SHEMAS[blockType] }]
            }, []).filter((el) => el !== null)

            if (isEmpty(progresses)) {
                return
            }

            dispatch(saveBlockProgressByTopic({ courseId, topicId, progresses, unitId }))
        }
        window.scrollTo(0, 0)
    }, [shoudReportProgressByTopic, dispatch, Blocks, courseId, topicId])

    return {
        Blocks,
        currentBlock,
        hasActiveQuiz,
        needScroll,
        setCurrentBlock,
        showBottomNav,
        sliderRef,
        stepBlocks,
        isCourseExpired,
    }
}
