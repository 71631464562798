'use-strict'

import React, { useState } from 'react'

import { Edit2, Trash2 } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import ModalConfirmation from '@/components/ModalConfirmation'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'


export default ({
    id = '',
    name = '',
    isDefault = true,
    description,
    handleEdit = () => { },
    handleDelete = () => { },
    lang = {},
    showEditRole,
    showDeleteRole
}) => {

    const [showModalConfirmation, setShowModalConfirmation] = useState(false)

    const confirmDelete = () => {
        setShowModalConfirmation(true)
    }

    const deleteRole = () => {
        handleDelete(id)
        setShowModalConfirmation(false)
    }

    return (
        <>
            <div className="__card __fixedHeight" >
                <div className="col-lg-9">
                    <h3 className="__card_secondary_title">{name}</h3>
                </div>
                <div className="col-lg-3 __card_button_box">
                    {!isDefault &&
                        <ButtonGroup>
                            {showEditRole &&
                                <ButtonRound
                                    theme='primary_dark'
                                    onClick={() => handleEdit(id)}
                                    icon={<Edit2 size={18} />}
                                />
                            }
                            {showDeleteRole &&
                                <ButtonRound
                                    theme='danger'
                                    onClick={() => {
                                        confirmDelete()
                                    }}
                                    icon={<Trash2 size={18} />}
                                />
                            }
                        </ButtonGroup>
                    }
                </div>
            </div>

            <ModalConfirmation
                show={showModalConfirmation}
                confirmTitle={lang.confirmTitle}
                onConfirm={deleteRole}
                cancelTitle={lang.cancelTitle}
                body={`${lang.deleteRole} ${description}`}
                onCancel={() => setShowModalConfirmation(false)}
            />
        </>
    )
}
