import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getGradebookTemplate } from '@/redux/thunks/dashboardThunks'

const useTemplateButton = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const { downloadTemplate } = useSelector(({ langStore }) => langStore.notebook.table)
    const courseId = useSelector(({ course }) => course.course.id)

    const downloadTemplateLink = async () => {
        setLoading(true)

        await dispatch(getGradebookTemplate(courseId))

        setLoading(false)
    }

    const downloadingTemplate = useSelector(({ loading }) => loading.dashboard.templateDownload)

    return {
        downloadTemplateLink,
        loading: downloadingTemplate && loading,
        text: downloadTemplate,
    }
}

export default useTemplateButton
