import React from 'react'
import PropTypes from 'prop-types'
import { history } from '@/app/store'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import CustomCargando from '@/components/CustomCargando'
import EmptyState from '../shared/components/EmptyState'
import { emptyStateAsset } from '@/assets/img/config/index'

const CourseReportNotFound = ({ isMounted, lang }) => {

    const handleGoBack = () => history.goBack()

    return (
        <div className='container'>
            <div className='__main_header'>
                {!isMounted ? (
                    <CustomCargando />
                ) : (
                    <>
                        <EmptyState
                            imgUrl={emptyStateAsset('default-no-results.svg')}
                            text={lang.courseWithoutContent}
                        />
                        <div className='_btn_group pull-right'>
                            <Button
                                id='handleGoBackButton'
                                theme='secondary'
                                onClick={handleGoBack}
                                text={lang.return}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

CourseReportNotFound.defaultProps = {
    isMounted: false,
}

CourseReportNotFound.propTypes = {
    isMounted: PropTypes.bool,
    lang: PropTypes.object.isRequired,
}

export default CourseReportNotFound
