import { VERSION } from '@/config/env'
import { getTenantSession } from './helpers'

const setFavicon = (url) => {
    if (!url) return
    let link = document.querySelector("link[rel~='icon']")
    if (!link) {
        link = document.createElement('link')
        link.setAttribute('rel', 'icon')
        document.getElementsByTagName('head')[0]?.appendChild(link)
    }
    link.setAttribute('href', url)
}

const setBrowserTitle = (browserTitle) => {
    if (!browserTitle) return
    let title = document.getElementsByTagName('head')[0]?.getElementsByTagName('title')[0]
    if (!title) {
        title = document.createElement('title')
        document.getElementsByTagName('head')[0]?.appendChild(title)
    }
    title.innerHTML = browserTitle
}

const setLookAndFeel = (lookandfeelUrl) => {
    return new Promise((resolve) => {
        const linkId = 'LookAndFeel'
        if (!document.getElementById(linkId)) {
            const link = document.createElement('link')
            link.setAttribute('id', linkId)
            link.setAttribute('rel', 'stylesheet')
            link.setAttribute('type', 'text/css')
            link.setAttribute('href', `${lookandfeelUrl}?v=${VERSION}`)
            link.onload = () => resolve()
            document.body.appendChild(link)
            return
        }
    })
}

export default async () => {
    const tenant = getTenantSession()

    setFavicon(tenant.faviconUrl)
    setBrowserTitle(tenant.browserTitle)
    await setLookAndFeel(tenant.lookandfeelUrl)
}
