import React from 'react'
import PropTypes from 'prop-types'
import { Check, AlertCircle } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import cn from 'classnames'
import './GeneralFeedback.scss'

const GeneralFeedback = ({ showFeedback, rightFeedback, wrongFeedback, isRightGeneralFeedback }) => {
      
    return showFeedback && (
        <div
            className={cn('general-feedback', {
                'general-feedback--wrong': !isRightGeneralFeedback,
            })}
        >
            <div
                className={cn('general-feedback__icon', {
                    'general-feedback__icon--wrong': !isRightGeneralFeedback,
                })}
            >
                {isRightGeneralFeedback ? (
                    <Check />
                ) : (
                    <AlertCircle />
                )}
            </div>
            <div className='general-feedback__text'>
                {isRightGeneralFeedback ? rightFeedback : wrongFeedback}
            </div>
        </div>
    )
}

GeneralFeedback.propTypes = {
    showFeedback: PropTypes.bool,
    rightFeedback: PropTypes.string,
    wrongFeedback: PropTypes.string,
    isRightGeneralFeedback: PropTypes.bool
}

export default GeneralFeedback
