import axios from 'axios'
import configureStore, { history } from '@/app/store'
import { logout } from '../reducers/_deprecated/authReducer'
import * as env from '@/config/env'
import alertTypes from '@/components/Alerts/alertTypes'
import { getTenantSession } from '@/helpers/tenants/helpers'
import { timezoneConfig } from '@/helpers/dates/timezoneConfig'

// @review: in order to be testable, this could be implemented using es6 classes
const httpClient = (
    api,
    baseURL = env.COURSE_API_URL.slice(0, -1),
    { secured = true, useVersion = true, apiToken = '', xuow = '', timezone = false, rawData = false } = {}
) => {
    // instance authStore
    const { store } = configureStore
    const { authReducer } = store.getState()
    const headers = {}

    if (useVersion) {
        headers.ApiVersion = env.VERSION
    }

    if (secured) {
        // if the initialization of the httpClient is secured, must be add [Authorization] header
        headers.Authorization = `${authReducer.user.token}`
        headers['tenant-id'] = getTenantSession()._id
    }

    if (apiToken) {
        headers['x-api-key'] = apiToken
    }

    if (xuow) {
        headers['x-uow'] = xuow
    }

    if (timezone) {
        const [tz] = timezoneConfig()
        headers['timezone'] = tz
    }

    // generate new api reference
    const instance = axios.create({ baseURL, headers })

    // added new path for the rest api
    instance.defaults.baseURL += api

    const dispatchAlert = (e) => {
        const response = e.response.data
        const alert = alertTypes(response)
        if (alert) store.dispatch(alert)
        return !!alert
    }

    // transform http response on [error, response] pattern
    instance.interceptors.response.use(
        (response) => {
            if (response.data instanceof Blob) {
                return Promise.resolve([null, response])
            }
            if (rawData) {
                return Promise.resolve([null, response.data])
            }
            return Promise.resolve([null, response.data.data])
        },
        (e) => {
            console.error(
                `The request has been failed with the status code: ${e.response.data.code} and the next message: ${e.response.data.message}`
            )
            if (e.response.status === 401) {
                store.dispatch(logout())
            }
            if (e.response.status === 404) {
                history.push('/not-found')
            }
            dispatchAlert(e)
            return Promise.resolve([e, null])
        }
    )

    return instance
}

export default httpClient
