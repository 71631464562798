import * as studentService from '../http/services/studentService'
import { getRoadmap, setGradebook } from '../reducers/roadmapReducer'
import { setLoading } from '../reducers/loadingReducer'
import httpHandler, { codes } from '@/helpers/error-handling/httpHandler'
import showNewMessage from '../reducers/_deprecated/globalAlertsReducer/alertsControllers'
import { history } from '@/app/store'

export const loadRoadmap = (userId, courseId) => async (dispatch, getState) => {
    dispatch(setLoading.true('roadmap.loadRoadmap'))
    const [error, roadmap] = await studentService.getStudentRoadmap(userId, courseId)

    if (error) {
        const errorMsg = getState().langStore.studentUnits.loadingUnitsError
        dispatch(setLoading.false('roadmap.loadRoadmap'))

        return httpHandler(error)({
            [codes.NOT_FOUND]: () => history.push('/not-found'),
            [codes.INTERNAL_SERVER_ERROR]: () => {
                showNewMessage({
                    type: 'danger',
                    message: errorMsg,
                    isTemporal: true,
                })
            },
        })
    }

    const { userProgress, assessment_strategy_id: strategyId } = roadmap
    let parsedCourse = null

    if (userProgress?.course) {
        parsedCourse = JSON.parse(userProgress.course)
    }

    const finalRoadmap = {
        ...roadmap,
        userProgress: { ...userProgress, course: parsedCourse },
        userRoles: getState().authReducer.user.roles
    }

    if (strategyId) await dispatch(loadGradebook(strategyId, courseId, userId))

    dispatch(setLoading.false('roadmap.loadRoadmap'))
    dispatch(getRoadmap(finalRoadmap))
}

export const loadGradebook = (strategyId, courseId, userId) => async (dispatch) => {
    const [error, response] = await studentService.getStudentGradebook(strategyId, courseId, userId)

    if (error) {
        return httpHandler(error)({
            [codes.INTERNAL_SERVER_ERROR]: () => {
                showNewMessage({
                    type: 'danger',
                    message: error.message,
                    isTemporal: true,
                })
            },
        })
    }

    const { grades, subjectGrade } = response
    dispatch(setGradebook({ grades, subjectGrade }))
}
