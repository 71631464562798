'use-strict'

import React from 'react'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import '../Roles.scss'

export default ({
    lang = {},
    goBack = () => { },
    onSave = () => { },
    loading = {}
}) => {

    return (
        <div className="__main_footer">
            <div className="_btn_group pull-right">
                <Button
                    theme='secondary'
                    onClick={goBack}
                    text={lang.goBack}
                />
                {/* <LoadingButton
                    status={loading.saveRole}
                    onClick={onSave}
                    title={lang.saveRole}
                /> */}
                <Button
                    className='role-button'
                    id="SearchButton"
                    theme='primary'
                    onClick={onSave}
                    text={lang.saveRole}
                    loading={loading.saveRole}
                />
            </div>
        </div>
    )
}