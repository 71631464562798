'use-strict'

import constants from './constants'
import initialState from './state'


const loading = (loadingState, payload) => {
    const loadingCopy = { ...loadingState }
    loadingCopy[payload.loader] = payload.bool
    return loadingCopy
}

const paginationConfig = (paginationConfigState, page) => {
    return { ...paginationConfigState, page }
}


export default {
    [constants.SET_PAGINATED_ROLES]: (state, payload) => ({
        ...state,
        all: payload
    }),
    [constants.SET_PAGINATED_PERMISSIONS]: (state, payload) => ({
        ...state,
        allPermissions: payload
    }),
    [constants.SET_IS_LOADING]: (state, payload) => ({
        ...state,
        loading: loading(state.loading, payload)
    }),
    [constants.CLEAR_ACTIVE_ROLE]: (state) => ({
        ...state,
        activeRole: initialState.activeRole
    }),
    [constants.CLEAR_ALL_ROLES]: (state) => ({
        ...state,
        all: initialState.all
    }),
    [constants.SET_ROLE_PERMISSIONS]: (state, payload) => ({
        ...state,
        defaultPermissionsRole: payload
    }),
    [constants.SET_ACTIVE_ROLE]: (state, payload) => ({
        ...state,
        activeRole: payload
    }),
    [constants.CLEAR_ROLE_PERMISSIONS]: (state) => ({
        ...state,
        defaultPermissionsRole: initialState.defaultPermissionsRole
    }),
    [constants.SET_ROLE_CURRENT_PAGE]: (state, payload) => ({
        ...state,
        paginationConfig: paginationConfig(state.paginationConfig, payload)
    }),
    [constants.CLEAR_PAGINATED_ROLES]: (state) => ({
        ...state,
        all: initialState.all
    }),
}
