import React from 'react'
import ReactDOM from 'react-dom'
import { withRouter } from 'react-router-dom'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import { connect } from 'react-redux'
import { history } from '@/app/store.js'
import { ArrowRight } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import RadioGrp from './RadioGrp'
import RadioGrpCard from './RadioGrpCard'
import { submitSurveyAnswers, saveSurveyAnswer, showSurveyAnswerError, loadLastSurvey, clearLoadedAction } from '@/redux/reducers/_deprecated/surveyReducer'
import { isObjectEmpty } from '@/helpers/utils'
import CustomCargando from '@/components/CustomCargando'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import InputTextArea from '@digitalhouse-tech/react-lib-ui-explorer/lib/TextAreaInput'
import './StudentSurvey.scss'
export class StudentSurvey extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            value: null
        }

        this.getQuestions = this.getQuestions.bind(this)
        this.submitSurvey = this.submitSurvey.bind(this)
        this.handleAnswerChange = this.handleAnswerChange.bind(this)
        this.handleGoBack = this.handleGoBack.bind(this)
    }

    componentDidMount() {
        this.props.clearLoadedAction()
        let surveyId = this.props.location.state ? this.props.location.state.survey.survey_id : null
        let courseId = this.props.match.params.courseId

        let ind = this.props.allreadyAnswered.findIndex(answer => answer.id === this.props.location.state.survey.course_survey_user_id)

        if (ind === -1) {
            this.props.loadLastSurvey(courseId, surveyId)
        } else if (!ind || this.props.location.state?.survey?.completed) {
            this.handleGoBack()
        }
    }

    handleAnswerChange(question, event) {
        let data = {
            survey_question_id: question,
            answer: event.target.value,
            course_survey_user_id: this.props.location.state.survey.course_survey_user_id,
            survey_id: this.props.location.state.survey.survey_id
        }

        let surveyAnswers = this.props.surveyAnswers
        this.props.saveSurveyAnswer(data, surveyAnswers)
    }

    handleGoBack() {
        history.goBack()
    }

    getQuestions() {
        let questions = this.props.surveyByUser.data
        let aux = []
        let index = []
        questions.map((question, i) => {

            question.question = question.question.includes('clases de ?') ?
                question.question.split('?').join(this.props.lang.yourDigitalHouseInstructors) :
                question.question

            switch (question.type) {
                case ("choice"):
                    let choiceOptions = JSON.parse(question.options)
                    let showChoiceOptions = []
                    showChoiceOptions.push(<RadioGrpCard key={"rdc" + i} options={choiceOptions} surveyQuestionId={question.id} surveyData={this.props.location.state.survey} />)

                    aux.push(
                        <div key={"choice" + i}>

                            <div className=" _question_box">
                                <h3 className="_question_info">
                                    <span className="__font_weight_bold">{question.order}</span>
                                    <ArrowRight className="_arrow_right" />
                                    {question.question}
                                    {question.mandatory ? <span className="font-color--primary"> *</span> : ''}
                                </h3>
                                <div>{showChoiceOptions}</div>
                            </div>
                        </div>
                    )
                    break

                case ("score"):
                    let scoreOptions = JSON.parse(question.options)
                    let showScoreOptions = []

                    if (this.props.isMobile) {
                        showScoreOptions.push(
                            <RadioGrpCard
                                key={"rd"}
                                options={scoreOptions}
                                surveyQuestionId={question.id}
                                surveyData={this.props.location.state.survey}
                                scoreType={true}
                            />
                        )
                    }
                    else {
                        showScoreOptions.push(
                            <RadioGrp
                                key={"rd"}
                                options={scoreOptions}
                                surveyQuestionId={question.id}
                                surveyData={this.props.location.state.survey} />
                        )
                    }

                    aux.push(
                        <div key={"score" + i}>
                            <div className=" _question_box">
                                <h3 className="_question_info">
                                    <span className="__font_weight_bold">{question.order}</span>
                                    <ArrowRight className="_arrow_right" />
                                    {question.question}
                                    {question.mandatory ? <span className="font-color--primary"> *</span> : ''}
                                </h3>
                                <div className="text-center">{showScoreOptions}</div>
                            </div>
                        </div>
                    )

                    break

                case ("text"):
                    index.push(i)
                    aux.push(
                        <div className=" _question_box" key={"text" + i}>
                            <h3 className="_question_info">
                                <span className="__font_weight_bold">{question.order}</span>
                                <ArrowRight className="_arrow_right" /> {question.question}
                                {question.mandatory ? <span className="font-color--primary"> *</span> : ''}
                            </h3>
                            <InputTextArea
                                key={"surveyAnswer" + i}
                                value={this.state.value}
                                onChange={this.handleAnswerChange.bind(this, question.id)}
                            />
                        </div>)
                    break
            }

        })

        return aux
    }

    validateInfo(surveyAnswers) {

        let questions = this.props.surveyByUser.data
        let surveyFormOk = false
        let myErrors = []

        questions.forEach(element => {

            let i = surveyAnswers.findIndex(x => x.survey_question_id == element.id)

            if (element.mandatory && i == -1) {
                myErrors.push(this.props.lang.thereIsAnError)
            }
        })

        if (myErrors.length > 0) {
            setTimeout(() => {
                scrollTo(window, ReactDOM.findDOMNode(this.validationsRef).offsetTop - 20)
            }, 250)
            this.props.showSurveyAnswerError(true)
            return surveyFormOk = false
        }
        else {
            this.props.showSurveyAnswerError(false)
            return surveyFormOk = true
        }

    }

    submitSurvey() {

        let surveyAnswers = this.props.surveyAnswers
        let userId = this.props.userId
        let courseId = this.props.match.params.courseId
        let questions = this.props.surveyByUser.data
        let returnToWelcome = this.props.returnToWelcome
        let surveyValidation = this.validateInfo(surveyAnswers)

        if (surveyValidation) {

            questions.forEach(element => {

                let i = surveyAnswers.findIndex(x => x.survey_question_id == element.id)

                if (!element.mandatory && i == -1) {
                    surveyAnswers.push({
                        survey_question_id: element.id,
                        answer: null,
                        course_survey_user_id: this.props.location.state.survey.course_survey_user_id,
                        survey_id: this.props.location.state.survey.survey_id
                    })
                }
            })

            this.props.submitSurveyAnswers(surveyAnswers, courseId, userId, returnToWelcome, this.props.location.state.survey.course_survey_user_id)
        }
    }

    render() {
        if (this.props.surveyByUser.isLoaded && !isObjectEmpty(this.props.surveyByUser.data) && this.props.location.state) {
            return (
                <div className="container">
                    <div className="__main_header _header_responsive_margin">
                        <Row>
                            <Col md="12">
                                <Panel className="__card">
                                    <Panel.Heading className="_padding_bottom_none">
                                        <div className="_padding_sides_22p">
                                            <h3 className="__card_title">{this.props.lang.survey}</h3>
                                        </div>
                                    </Panel.Heading>
                                    <Panel.Body className="_padding_top_none">
                                        <div className="_padding_sides_22p">
                                            {this.props.showErrors ? <div className="_radioBox_alert_message _padding_sides_none _margin_top_20"
                                                ref={validationsRef => this.validationsRef = validationsRef}><p>{this.props.lang.itIsNecessaryToAnswerAllFieldsContaining}</p></div> : ''}
                                            <form>
                                                {this.getQuestions()}
                                            </form>
                                        </div>
                                    </Panel.Body>
                                    <Row>
                                        <Col lg="12">
                                            <div className="__main_footer">
                                                <div className="_btn_group pull-right _margin_bottom_28">
                                                    <Button
                                                        id="handleGoBackButton"
                                                        theme="secondary"
                                                        onClick={this.handleGoBack}
                                                        text={this.props.lang.return}
                                                    />
                                                    <Button
                                                        id="submitSurveyButton"
                                                        theme="primary"
                                                        className='survey-button--submit'
                                                        onClick={this.submitSurvey}
                                                        text={this.props.isMobile ? this.props.lang.send : this.props.lang.sendReplies}
                                                        loading={this.props.success}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Col>
                        </Row>
                    </div>
                </div>
            )
        }
        else { return (<div>{this.props.location.state ? <div><CustomCargando /></div> : <div>{location.href = '/'}</div>}</div>) }
    }
}

const mapDispatch = (dispatch) => ({
    loadLastSurvey: (courseId, userId) => {
        dispatch(loadLastSurvey(courseId, userId))
    },
    submitSurveyAnswers: (data, courseId, userId, returnToWelcome, courseSurveuyUserId) => {
        dispatch(submitSurveyAnswers(data, courseId, userId, returnToWelcome, courseSurveuyUserId))
    },
    saveSurveyAnswer: (data, surveyAnswers) => {
        dispatch(saveSurveyAnswer(data, surveyAnswers))
    },
    clearLoadedAction: () => {
        dispatch(clearLoadedAction())
    },
    showSurveyAnswerError: (data) => {
        dispatch(showSurveyAnswerError(data))
    },
})

const mapStateToProps = (state, ownProps) => {
    return {
        surveyByUser: state.surveyReducer.surveyByUser,
        userId: state.authReducer.user.id,
        surveyAnswers: state.surveyReducer.surveyAnswers,
        surveyErrors: state.surveyReducer.surveyErrors,
        showErrors: state.surveyReducer.showErrors,
        survey: state.surveyReducer.prueba,
        success: state.surveyReducer.success,
        returnToWelcome: state.surveyReducer.returnToWelcome,
        allreadyAnswered: state.surveyReducer.allreadyAnswered,
        lang: state.langStore.surveyStudent,
        isMobile: state.authReducer.isMobile,
        surveysWelcome: state.surveyReducer.surveysWelcome,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatch)(StudentSurvey))
