import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { ChevronDown, ChevronRight } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import CourseReportEntityList from './CourseReportEntityList'

const CourseReportEntityButton = ({
    showList,
    showRegularButton,
    id,
    data,
    dataLevel,
    currentEntity,
    entityPrefix,
    buttonText,
    onClick,
    defaultLevel,
    items,
    changeDataLevel
}) => {
    if (!items) return null
    return (
        <div id={id}>
            <Button
                className={cn(
                    {
                        _report_regular_button: showRegularButton,
                    },
                    '_report_general_button',
                )}
                theme="clear"
                text={buttonText}
                icon={
                    showRegularButton ? (
                        <ChevronRight />
                    ) : (
                        <ChevronDown
                            className={cn({
                                _open_arrow: showList,
                                _no_show_element: items.length <= 1 && !defaultLevel
                            })}
                        />
                    )
                }
                onClick={onClick}
            />
            {showList && (
                <CourseReportEntityList
                    data={data}
                    dataLevel={dataLevel}
                    currentEntity={currentEntity}
                    entityPrefix={entityPrefix}
                    changeDataLevel={changeDataLevel}
                    showList={!showRegularButton}
                />
            )}
        </div>
    )
}

CourseReportEntityButton.defaultProps = {
    defaultLevel: false
}

CourseReportEntityButton.propTypes = {
    showList: PropTypes.bool.isRequired,
    showRegularButton: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    defaultLevel: PropTypes.bool,
    items: PropTypes.array,
    data: PropTypes.array.isRequired,
    dataLevel: PropTypes.string.isRequired,
    currentEntity: PropTypes.object.isRequired,
    entityPrefix: PropTypes.string.isRequired,
    changeDataLevel: PropTypes.func.isRequired,
}

export default CourseReportEntityButton
