import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import useHistory from '@/hooks/useHistory'
import * as helpers from '../../shared/utils/reviewHelpers'
import ReviewHeader from './ReviewHeader'
import ReviewModal from './ReviewModal'
import Panel from './Panel'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import LoadingOrContent from '@digitalhouse-tech/react-lib-ui-explorer/lib/LoadingOrContent'
import { checkHandInIsExpired, formatedExpiredMessage } from '../../shared/utils/dateHelpers'
import { EVALUABLE_BLOCK } from '../../shared/constants/dashboard'
import useStudentsStatus from '../../shared/hooks/useStudentsStatus'
import useReview from '../../shared/hooks/useReview'
import { EDIT_FEEDBACK } from '@/constants/coursePermissions'
import { loadCoursePermissions } from '@/redux/reducers/_deprecated/authReducer'

const Review = ({ match }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { courseId, blockId, userId } = match.params

    const loading = useSelector(({ loading }) => loading.dashboard)
    const reporter = useSelector(({ authReducer }) => authReducer.user)
    const lang = useSelector(({ langStore }) => langStore.dashboard)

    const { 
        studentsDropdown,
        search,
        selected,
        onChangeSearch
    } = useStudentsStatus(courseId, blockId, userId, EVALUABLE_BLOCK)

    const {
        actualProgress,
        rejectTags,
        showModal,
        modalKey,
        reviewType,
        teacherReview,
        reviewHasError,
        htmlReviewProps,
        onReviewChange,
        onHtmlReviewChange,
        onReasonsChange,
        onChangeReviewFiles,
        onClickFooterActions,
        onSendReview,
        onRequestRedo,
        onCancelModal,
        validateRequestRedo,
        reasonsHasError,
    } = useReview(courseId, blockId, userId)

    const { blockInfo, lastStatus, type, lastSubmission } = actualProgress

    const [studentTracking] = lastSubmission.statusTracking
    const [expireDate, isExpired] = checkHandInIsExpired(blockInfo.releaseDate, blockInfo.limitDays, studentTracking.date)
    const loadingContent = loading?.studentProgress || loading?.studentReview || loading?.studentsStatus

    useEffect(() => {
       dispatch(loadCoursePermissions(courseId))
    }, [dispatch])

    return (
        <div className='container review-activity'>
            <ReviewModal
                reviewType={reviewType}
                modalKey={modalKey}
                lang={lang}
                onCancelModal={onCancelModal}
                onSendReview={({ reviewType }) =>
                    onSendReview({ reviewType, type, teacherReview, reporter })
                }
                rejectTags={rejectTags}
                showModal={showModal}
                studentFullName={selected.fullName}
                teacherReview={teacherReview}
                onReviewChange={onReviewChange}
                onReasonsChange={onReasonsChange}
                reviewHasError={reviewHasError}
                reasonsHasError={reasonsHasError}
                validateRequestRedo={validateRequestRedo}
            />
            <ReviewHeader
                ctaText={lang.goBackToList}
                title={lang.evaluableActivity}
                onClick={() => history.push(helpers.progressListUrl(courseId))}
            />
            <LoadingOrContent loading={loadingContent}>
                <>
                    {blockInfo.hasLimit && isExpired && (
                        <MessageBox
                            text={formatedExpiredMessage(expireDate, lang.expiredActivity)}
                            theme='validation'
                            fullWidth
                        />
                    )}
                    <Panel
                        blockName={blockInfo.name}
                        lang={lang}
                        studentsList={studentsDropdown}
                        selected={selected}
                        onRequestRedo={onRequestRedo}
                        onChangeSearch={onChangeSearch}
                        lastStatus={lastStatus}
                        type={type}
                        search={search}
                        handIn={lastSubmission.handIn}
                        statusTracking={lastSubmission.statusTracking}
                        teacherReview={teacherReview}
                        htmlReviewProps={htmlReviewProps}
                        onClickFooterActions={onClickFooterActions}
                        onHtmlReviewChange={onHtmlReviewChange}
                        onChangeReviewFiles={onChangeReviewFiles}
                        canEdit={EDIT_FEEDBACK()}
                    />
                </>
            </LoadingOrContent>
        </div>
    )
}

Review.propTypes = {
    match: PropTypes.object,
}

Review.defaultProps = {
    match: {},
}

export default Review
