import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './FinalGradeCard.scss'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'

const FinalGradeCard = ({ grade, description, tooltipContent, theme }) => {
    return (
        <div className='final-grade-card'>
            <Tooltip tooltipContent={tooltipContent} tooltipPlacement='bottom'>
                <div className='final-grade-card__content'>
                    <div className='final-grade-card__text-container'>
                        <div className='final-grade-card__text'>Nota final</div>
                    </div>
                    <div className={cn('final-grade-card__description-container',`final-grade-card__description-container--${theme}`)}>
                        <div className='final-grade-card__description'>{description}</div>
                    </div>
                    <div className={cn('final-grade-card__grade-container', `final-grade-card__grade-container--${theme}`)}>
                        <div className={cn('final-grade-card__grade', `final-grade-card__grade--${theme}`)}>{grade}</div>
                    </div>
                </div>
            </Tooltip>
        </div>
    )
}

FinalGradeCard.propTypes = {
    grade: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    theme: PropTypes.oneOf(['success', 'warning']),
    description: PropTypes.string,
    tooltipContent: PropTypes.string,
}

FinalGradeCard.defaultProps = {
    theme: 'success',
    description: '',
    tooltipContent: '',
}

export default FinalGradeCard
