import React from 'react'
import PropTypes from 'prop-types'
import { FileText } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'

const StudentListPanelToolbar = ({downloadLoginReport}) => {
    return (
        <ButtonRound
            className='pull-right'
            onClick={() => downloadLoginReport()}
            icon={<FileText />}
            id='downloadLoginReport'
            theme='primary'
        />
    )
}

StudentListPanelToolbar.propTypes = {
    downloadLoginReport: PropTypes.func.isRequired
}

export default StudentListPanelToolbar
