import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import { ArrowLeft, ArrowRight, Loader } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { LEFT_ARROW, RIGHT_ARROW, SPINNER } from './constants/index'

const PaginationArrow = ({ arrowType, active, onClick }) => {

    const arrowClasses = () => cn({
        'slider-wrapper__content__navigation__active': active,
        'slider-wrapper__content__navigation-left': arrowType === LEFT_ARROW,
        'slider-wrapper__content__navigation-right': arrowType === RIGHT_ARROW || arrowType === SPINNER,
    }, 'slider-wrapper__content__navigation')

    const arrowTypes = {
        [LEFT_ARROW]: <ArrowLeft />,
        [RIGHT_ARROW]: <ArrowRight />,
        [SPINNER]: <Loader />,
    }

    return (
        <div className={arrowClasses()}>
            {
                <ButtonRound
                    id='arrow-pagination'
                    theme='primary'
                    icon={arrowTypes[arrowType] || <ArrowRight />}
                    onClick={onClick}
                    loading={arrowType === SPINNER}
                    disabled={arrowType === SPINNER}
                />
            }
        </div>
    )
}

PaginationArrow.defaultProps = {
    activeLeft: false,
    activeRight: false,
    arrowType: LEFT_ARROW,
}

PaginationArrow.propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    arrowType: PropTypes.string,
}

export default PaginationArrow
