import React from 'react'
import { Edit2, Trash2 } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import ModalConfirmation from '../ModalConfirmation'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'

class HintTable extends React.Component {
    constructor(props) {
        super(props)

        this.state = { hintKey: null }

        this.headers = this.headers.bind(this)
        this.rows = this.rows.bind(this)
    }

    headers() {
        return (
            <thead>
                <tr>
                    <th key="description" className="col-md-10">{this.props.lang.hintsDescription}</th>
                    <th key="actions" className="col-md-2 text-center">{this.props.lang.actions}</th>
                </tr>
            </thead>
        )
    }

    rows() {
        if (this.props.hints.length) {
            return this.props.hints.map((hint, key) => (
                <tr key={key}>
                    <td>{hint}</td>
                    <td className="text-center">
                        <ButtonGroup>
                            <ButtonRound
                                id="EditButton"
                                theme="primary_dark"
                                onClick={() => this.props.actions.onEdit(key)}
                                icon={<Edit2 />}
                            />
                            <ButtonRound
                                id="DeleteButton"
                                theme="danger"
                                onClick={() => {
                                    this.modalConfirmation.handleShow()
                                    this.setState({ ...this.state, hintKey: key })
                                }}
                                icon={<Trash2 />}
                            />
                        </ButtonGroup>
                    </td>
                </tr>
            ))
        }
        return (<tr><td className="text-center" colSpan="3">{this.props.lang.thereIsNot} <strong>{this.props.lang.hints}</strong> {this.props.lang.toShow}</td></tr>)
    }

    render() {
        return (
            <>
                <table className="pdg-table __table">
                    {this.headers()}
                    <tbody>
                        {this.rows()}
                    </tbody>
                </table>
                <ModalConfirmation
                    ref={ref => this.modalConfirmation = ref}
                    confirmTitle={this.props.lang.yes}
                    onConfirm={() => this.props.actions.onDelete(this.state.hintKey)}
                    cancelTitle={this.props.lang.no}
                    body={this.props.lang.areYouSureYouWantToDeleteThisTrack}
                />
            </>
        )
    }
}

export default HintTable
