import React from 'react'
import PropTypes from 'prop-types'
import {
    APPROVED,
    FAILED,
} from '../../../shared/constants/dashboard'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import { XCircle, CheckCircle } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import './PanelFooter.scss'

const PanelFooter = ({ disabled, lang, onClick }) => {

    return (
        <div className='activity-panel-footer'>
            <ButtonGroup>
                <Button
                    id='faileActivityBtn'
                    theme='secondary'
                    text={lang.disapprove}
                    icon={<XCircle size={18} />}
                    leftIcon
                    disabled={disabled}
                    onClick={() => onClick(FAILED)}
                />
                <Button
                    id='approveActivityBtn'
                    theme='primary'
                    text={lang.approve}
                    icon={<CheckCircle size={18} />}
                    leftIcon
                    disabled={disabled}
                    onClick={() => onClick(APPROVED)}
                />
            </ButtonGroup>
        </div>
    )
}

PanelFooter.propTypes = {
    disabled: PropTypes.bool,
    lang: PropTypes.object,
    onClick: PropTypes.func,
}

PanelFooter.defaultProps = {
    disabled: false,
    lang: {},
    onClick: () => {},
}

export default PanelFooter
