'use-strict'

import { history } from '@/app/store'

const base = `/admin/roles`

export default {
    goToRolesList: () => {
        return history.push(base)
    },
    goToCreateRole: () => {
        return history.push(`${base}/create`)
    },
    goToEditRole: id => {
        return history.push(`${base}/${id}/edit`)
    },
}