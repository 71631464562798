import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import { Download, Edit2, Trash2 } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'

const FileItem = ({
    id,
    name,
    date,
    path,
    isEditable,
    onDownload,
    onDelete,
    onEdit,
    showDeleteButton,
    showEditButton,
    showDownloadButton
}) => {
    return (
        <div className="FileListGroupItem">
            <div className="FileDetails">
                <div className="Detail">
                    <div>{name}</div>
                </div>
                <div className="Detail">
                    <div>{moment(date).format('DD/MM/YYYY')}</div>
                </div>
            </div>
            <ButtonGroup>
                {showDownloadButton &&
                    <ButtonRound
                        id="DownloadButton"
                        theme='primary'
                        className="download"
                        onClick={() => onDownload(path, name)}
                        icon={<Download />}
                    />
                }
                {isEditable && showEditButton &&
                    <ButtonRound
                        id="EditButton"
                        className="edit"
                        theme='primary_dark'
                        onClick={e => onEdit(id, name)}
                        icon={<Edit2 />}
                    />}
                {isEditable && showDeleteButton &&
                    <ButtonRound
                        id="DeleteButton"
                        className="delete"
                        theme='danger'
                        onClick={e => onDelete(id, name)}
                        icon={<Trash2 />}
                    />}
            </ButtonGroup>
        </div>
    )
}

FileItem.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    isEditable: PropTypes.bool.isRequired,
    onDownload: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
}

export default FileItem
