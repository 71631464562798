import React from 'react'
import PropTypes from 'prop-types'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { Send } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { GRADES_STEPS } from '@/constants/grades'

import './Banner.scss'

const Banner = ({ lastPublishDate, lang, openPublishModal, step }) => {
    const gradebookStep = {
        [GRADES_STEPS.CREATED]: {
            theme: 'success',
            title: lang.createdGrades,
            rightComponent: (
                <Button
                    theme='primary'
                    text={lang.publish}
                    icon={<Send />}
                    leftIcon
                    onClick={openPublishModal}
                    status='success'
                />
            ),
        },
        [GRADES_STEPS.PUBLISHED]: {
            theme: 'info',
            title: (
                <span
                    dangerouslySetInnerHTML={{
                        __html: lang.updatedGrades.replace(
                            '{{DATE}}',
                            `<strong>${lastPublishDate}</strong>`
                        ),
                    }}
                />
            ),
        },
    }

    if (!step) return null

    return <MessageBox fullWidth className='grades-list-banner__message' {...gradebookStep[step]} />
}

Banner.propTypes = {
    lastPublishDate: PropTypes.string,
    lang: PropTypes.object,
    openPublishModal: PropTypes.func,
    step: PropTypes.string,
}

Banner.defaultProps = {
    lastPublishDate: '',
    lang: null,
    openPublishModal: () => {},
    step: '',
}

export default Banner
