export const breakpointsTable = [
    {
        width: 2600,
        initialScroll: 9,
        toCenter:4,
    },
    { 
        width: 1366,
        initialScroll: 7,
        toCenter:5,
    },
    {
        width: 768,
        initialScroll: 8,
        toCenter: 3,
    },
    {
        width: 560,
        initialScroll: 6,
        toCenter:2,
    },
    {
        width: 485,
        initialScroll: 4,
        toCenter: 1,
    },
    {
        width: 425,
        initialScroll: 3,
        toCenter: 0,
    }
]