export const slider = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 21,
    slidesToScroll: 3,
    initialSlide: 0,
    variableWidth: true,
    responsive: [
        {
            breakpoint: 2600,
            settings: {
                slidesToShow: 8,
                slidesToScroll: 1,
                initialSlide: 0
            }
        },
        {
            breakpoint: 1366,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
                initialSlide: 0
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 8,
                slidesToScroll: 1,
                initialSlide: 0
            }
        },
        {
            breakpoint: 560,
            settings: {
                slidesToShow: 7,
                slidesToScroll: 1,
                initialSlide: 0
            }
        },
        {
            breakpoint: 485,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
                initialSlide: 0
            }
        },
        {
            breakpoint: 425,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                initialSlide: 0
            }
        },
        
        
        
        
    ]
};