import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BLOCK_TYPES } from '@/constants/blocks'
import { EVALUABLE_BLOCK_FILES } from '@/constants/vaultyPaths'
import { validateEmptyString } from '@/helpers/strings'
import HTMLEditor from '@/components/HTMLEditor'
import BlockModal from './BlockModal'
import { createModalDataSelector, externalPlatformsSelector } from '@/redux/selectors'
import ToggleButton from '@digitalhouse-tech/react-lib-ui-explorer/lib/ToggleButton'
import InputNumber from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputNumber'
import InputCustomComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputCustomComboBox'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import { Info, Link } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import FilesUploaderManager from '@/components/FilesUploaderManager'
import DropdownItem from '@digitalhouse-tech/react-lib-ui-explorer/lib/DropdownItem'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import ModalPanel from '@/components/ModalPanel'
import { loadExternalPlatforms } from '@/redux/thunks/courseExternalPlatformsThunks'
import * as env from '@/config/env'
import { hasAnyProgressSelector } from '@/redux/selectors'
import { BAD_URL, EMPTY_FIELD } from './constants/errorTypes'
import { stripHtml } from '@/helpers/utils'

const DEFAULT_EVALUABLE_BLOCK = {
    EvaluableBlock: {
        statement: '',
        has_limit: false,
        limit_days: 1,
        is_external: true,
        external_platform_url: '',
        EvaluableBlockFiles: [],
    },
}

const evaluableBlockSelector = createModalDataSelector(DEFAULT_EVALUABLE_BLOCK)

const ModalEvaluableBlock = () => {
    const lang = useSelector(({ langStore }) => langStore.evaluableBlock)
    const vaultyConfig = {
        apiKey: env.VAULTY_API_KEY,
        writerUrl: `${env.VAULTY_WRITER_URL}`,
        readerUrl: `${env.VAULTY_READER_URL}/${env.VAULTY_PATH}`,
    }

    const noPlatformSelection = (
        <DropdownItem id='dropdown-default' theme='primary' text={lang.platform} strong />
    )
    const data = useSelector(evaluableBlockSelector)
    const externalPlatforms = useSelector(externalPlatformsSelector)
    const hasAnyProgress = useSelector(hasAnyProgressSelector)
    const [statement, setStatement] = useState(data.EvaluableBlock.statement)
    const [statementError, setStatementError] = useState(null)
    const [externalPlatformError, setExternalPlatformError] = useState(false)
    const [externalPlatformUrlError, setExternalPlatformUrlError] = useState(null)
    const [hasLimit, setHasLimit] = useState(data.EvaluableBlock.has_limit)
    const [limitDays, setLimitDays] = useState(data.EvaluableBlock.limit_days)
    const [isExternal, setIsExternal] = useState(data.EvaluableBlock.is_external)
    const [externalPlatformUrl, setExternalPlatformUrl] = useState(
        data.EvaluableBlock.external_platform_url
    )
    const [evaluableBlockFiles, setEvaluableBlockFiles] = useState(
        data.EvaluableBlock.EvaluableBlockFiles || []
    )

    const dispatch = useDispatch()
    const createDropdownItem = (platform) => {
        return (
            <DropdownItem
                id={`dropdown-${platform.id}`}
                icon={
                    platform.logo_url && (
                        <img src={platform.logo_url} alt={`${platform.name} logo`} />
                    )
                }
                text={platform.name}
                theme={'primary'}
                value={`${platform.id}`}
                strong
            />
        )
    }

    useEffect(() => {
        dispatch(loadExternalPlatforms())
    }, [])

    const externalPlatformsDropdowns = externalPlatforms.map((platform) =>
        createDropdownItem(platform)
    )

    const getExternalPlatformById = (platformId) => {
        const platform = externalPlatforms.find(({ id }) => id == platformId)
        return platform ? createDropdownItem(platform) : noPlatformSelection
    }

    const [selectedExternalPlatform, setSelectedExternalPlatform] = useState(noPlatformSelection)

    useEffect(() => {
        setSelectedExternalPlatform(
            getExternalPlatformById(data.EvaluableBlock.external_platform_id)
        )
    }, [externalPlatforms])

    const onSelectPlatform = (value) => {
        setSelectedExternalPlatform(value)
    }

    const onClickIsExternal = () => {
        if (isExternal) {
            setSelectedExternalPlatform(noPlatformSelection)
            setExternalPlatformUrl('')
        }
        setIsExternal(!isExternal)
    }

    const values = useMemo(
        () => ({
            has_limit: hasLimit,
            limit_days: limitDays,
            is_external: isExternal,
            external_platform_url: externalPlatformUrl,
            statement,
            EvaluableBlockFiles: evaluableBlockFiles,
            external_platform_id: selectedExternalPlatform?.props?.value || null,
        }),
        [
            hasLimit,
            limitDays,
            isExternal,
            externalPlatformUrl,
            statement,
            evaluableBlockFiles,
            selectedExternalPlatform,
        ]
    )

    const validateUrl = (url) => url.match(/^https:\/\//g)

    const validateInputValues = useCallback(() => {
        const statementValidation = validateEmptyString(
            stripHtml(statement),
            lang.requiredInputMessage
        )
        const externalPlatformUrlValidation = isExternal && !!externalPlatformUrl && !validateUrl(externalPlatformUrl)
        setStatementError(statementValidation)
        setExternalPlatformUrlError(externalPlatformUrlValidation)
        let errors = []

        if (statementValidation) {
            errors.push(EMPTY_FIELD)
        }

        if (externalPlatformUrlValidation) {
            errors.push(BAD_URL)
        }

        return errors
    }, [lang, statement, externalPlatformUrl, isExternal, selectedExternalPlatform])

    const blockTitle = !!data.id ? lang.modalEvaluableTitleEdit : lang.modalEvaluableTitleCreate
    const id = BLOCK_TYPES.EVALUABLE_BLOCK.modal

    return (
        <BlockModal
            validateInputValues={validateInputValues}
            block={data}
            values={values}
            lang={lang}
            blockTitle={blockTitle}
            blockType={BLOCK_TYPES.EVALUABLE_BLOCK}
            id={id}
            blockId={data.id}
            visibility={data.visibility}
            show_name={data.show_name}
            name={data.name}
        >
            <Row>
                <Col md="12">
                    <ModalPanel>
                        <div className='modal-panel__input'>
                            <ToggleButton
                                disabled={false}
                                onToggle={() => setHasLimit(!hasLimit)}
                                toggled={!hasLimit}
                                inputName={'timelimit'}
                            />
                        </div>

                        <Label
                            className='modal-panel__label'
                            theme='no-theme'
                            indent
                            text={lang.noTimelimit}
                        />
                        <div className='modal-panel__input'>
                            <Tooltip
                                tooltipContent={lang.activityTooltip}
                                tooltipId={'limitDays-tooltip'}
                            >
                                <div className='legend'>
                                    <Label theme='classic' text={lang.limitDays} />
                                    <Info className='legend__icon' />
                                </div>
                            </Tooltip>
                            <InputNumber
                                disabled={!hasLimit}
                                min={1}
                                max={100}
                                onChange={(value) => setLimitDays(value)}
                                inputName={'dayTime'}
                                value={limitDays}
                            />
                        </div>
                        <div className='modal-panel__input'>
                            <ToggleButton
                                disabled={hasAnyProgress}
                                onToggle={() => onClickIsExternal()}
                                toggled={isExternal}
                                inputName={'external'}
                            />
                        </div>
                        <Label
                            className='modal-panel__label'
                            theme='no-theme'
                            indent
                            text={lang.isExternal}
                        />

                        <div className='modal-panel__input'>
                            <InputCustomComboBox
                                disabled={!isExternal}
                                onSelect={(value) => onSelectPlatform(value)}
                                inputName={'externalSelect'}
                                dropdownItems={externalPlatformsDropdowns}
                                selected={selectedExternalPlatform}
                                error={externalPlatformError}
                            />
                        </div>
                    </ModalPanel>
                </Col>
            </Row>
            {isExternal && (
                <Row>
                    <Col md="12">
                        <ModalPanel>
                            <div className='modal-panel__label'>
                                <Link />
                            </div>
                            <div className='modal-panel__label'>
                                <Label indent theme='classic' text={lang.pasteActivityLink} />
                            </div>
                            <div className='modal-panel__input full-width'>
                                <InputText
                                    id='externalPlatformUrlInput'
                                    onChange={(e) => setExternalPlatformUrl(e.currentTarget.value)}
                                    error={!!externalPlatformUrlError}
                                    value={externalPlatformUrl}
                                    glow={false}
                                />
                            </div>
                        </ModalPanel>
                    </Col>
                </Row>
            )}
            <Row>
                <Col md="12">
                    <ModalPanel theme='no-theme'>
                        <FilesUploaderManager
                            title={lang.attachDocs}
                            subtitle={lang.maxSizeFiles}
                            onChange={(files) => setEvaluableBlockFiles(files)}
                            files={evaluableBlockFiles}
                            vaultyConfig={vaultyConfig}
                            errorMsg={lang.uploadFileError}
                            maxFileSizeLimit={env.FILES_SIZE_LIMIT_MB}
                            vaultyFolder={EVALUABLE_BLOCK_FILES}
                        />
                    </ModalPanel>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <div className={statementError ? '__wysiwyg--error' : null}>
                        <HTMLEditor html={statement} handleChange={setStatement} />
                    </div>
                </Col>
            </Row>
        </BlockModal>
    )
}

export default ModalEvaluableBlock
