'use-strict'

import axios from 'axios'
import configureStore from './store'
import { logout } from '@/redux/reducers/_deprecated/authReducer'
import alertTypes from '@/components/Alerts/alertTypes'

const dispatchAlert = e => {
    const { dispatch } = configureStore.store
    const response = e.response.data
    const alert = alertTypes(response)
    if (alert) dispatch(alert)

    return !!alert
}

const download = (args, data) => {
    let test = args.filter(arg => {
        return typeof(arg) === 'object' && arg.responseType === "blob"
    })

    if(test.length){
        return data
    }
    else {
        return data.data
    }
}

const request = (type) => async (...args) => {
    try {
        // const { data: { data } } = await axios[type](...args)
        const { data } = await axios[type](...args)
        return download(args, data)

    } catch (e) {
        console.error(`The request has been failed with the status code: ${e.response.data.code} and the next message: ${e.response.data.message}`)
        if (e.response.status === 401) {
            configureStore.store.dispatch(logout())
        }
        if (!dispatchAlert(e)) {
            throw e
        }
    }
}

export default {
    get: (url, options) => request('get')(url, options),
    delete: (url, options) => request('delete')(url, options),
    post: (url, body, options) => request('post')(url, body, options),
    patch: (url, body, options) => request('patch')(url, body, options),
    put: (url, body, options) => request('put')(url, body, options)
}