import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Searcher from '@digitalhouse-tech/react-lib-ui-explorer/lib/Searcher'
import ToggleButton from '@digitalhouse-tech/react-lib-ui-explorer/lib/ToggleButton'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import { loadPaginatedInstitutes } from '@/redux/reducers/_deprecated/instituteReducer/index'
import { CustomFormField } from '@/components/CustomFormField/CustomFormField'
import Select from 'react-select'

const searchValues = (values) => {
    let searchData = {}
    if (values.name && values.name !== '') {
        searchData.name = values.name
    }
    if (values.alias && values.alias !== '') {
        searchData.alias = values.alias
    }
    if (values.isBase !== null && values.isBase !== '') {
        searchData.isBase = values.isBase
    }
    if (values.institute_id && values.institute_id !== '') {
        searchData.institute_id = values.institute_id
    }
    if (values.course_type_id && values.course_type_id !== '') {
        searchData.course_type_id = values.course_type_id
    }
    return searchData
}

export class CourseFinder extends React.Component {
    constructor(props) {
        super(props)

        this.defaultState = {
            searchValues: {
                name: '',
                alias: '',
                institute: null,
                isBase: false,
                course_type: null,
            },
            selects: { institute: null, course_type: null },
            isEmpty: false,
        }

        const { activeSearch } = this.props
        const selectedInstitute = _.find(this.props.allInstitutes.institutes, {
            id: activeSearch.institute_id,
        })
        const selectedCourseType = _.find(this.props.courseTypes.items, {
            id: activeSearch.course_type_id,
        })

        this.state = {
            searchValues: {
                name: activeSearch.name ? activeSearch.name : '',
                alias: activeSearch.alias ? activeSearch.alias : '',
                institute: selectedInstitute ? selectedInstitute.id : null,
                isBase: activeSearch.isBase,
                course_type: selectedCourseType ? selectedCourseType.id : null,
            },
            selects: {
                institute: selectedInstitute
                    ? { value: selectedInstitute.id, label: selectedInstitute.name }
                    : null,
                course_type: selectedCourseType
                    ? { value: selectedCourseType.id, label: selectedCourseType.name }
                    : null,
            },
            isEmpty: false,
        }

        this.formatSelectCourseTypes = this.formatSelectCourseTypes.bind(this)
        this.formatSelectInstitutes = this.formatSelectInstitutes.bind(this)
        this.getFormBody = this.getFormBody.bind(this)
        this.handleChangeAlias = this.handleChangeAlias.bind(this)
        this.handleChangeIsBase = this.handleChangeIsBase.bind(this)
        this.handleChangeName = this.handleChangeName.bind(this)
        this.handleChangeSelect = this.handleChangeSelect.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInstituteChange = this.handleInstituteChange.bind(this)
        this.handleInstituteInputChange = this.handleInstituteInputChange.bind(this)
        this.resetAliasSearch = this.resetAliasSearch.bind(this)
        this.resetNameSearch = this.resetNameSearch.bind(this)
        this.searchBar = this.searchBar.bind(this)
        this.searchValues = this.searchValues.bind(this)
    }

    componentWillUnmount() {
        this.props.clearActiveSearch()
    }

    searchBar() {
        const { getFormBody, handleSubmit } = this
        const {
            allInstitutes,
            courseTypes,
            lang: { generalSearcherLang },
            loading,
            searcherActions,
            searcherActionsPermission,
        } = this.props

        if (allInstitutes.isLoaded && courseTypes.isLoaded) {
            return (
                <Searcher
                    actions={searcherActions}
                    actionsComponentPermission={searcherActionsPermission}
                    formBody={getFormBody()}
                    loadingStatus={loading}
                    resultsTitle={generalSearcherLang?.mostRecent}
                    searchSubmitHandler={handleSubmit}
                    submitActionHandler
                    submitText={generalSearcherLang?.submitSearch}
                />
            )
        }
        return null
    }

    handleChangeIsBase() {
        this.setState({
            ...this.state,
            searchValues: {
                ...this.state.searchValues,
                isBase: !this.state.searchValues.isBase,
            },
        })
    }

    getFormBody() {
        const { courseSearcherFormLang, generalSearcherLang } = this.props.lang
        const { searchValues, selects } = this.state

        const selectStyles = {
            menu: (provided) => ({
                ...provided,
                borderRadius: '10px',
                overflow: 'hidden',
            }),
        }

        return (
            <>
                <Row>
                    <Col md="5">
                        <Label text={courseSearcherFormLang.searchByName} />
                        <InputText
                            autoFocus
                            deletable
                            id='courseName'
                            onChange={this.handleChangeName}
                            onCloseIcon={this.resetNameSearch}
                            value={searchValues.name}
                        />
                    </Col>
                    <Col md="5">
                        <Label text={courseSearcherFormLang.searchByAlias} />
                        <InputText
                            deletable
                            id='searchByCourseAlias'
                            onChange={this.handleChangeAlias}
                            onCloseIcon={this.resetAliasSearch}
                            value={searchValues.alias}
                        />
                    </Col>
                    <Col md="2">
                        <div className='base-toggle-button'>
                            <Label text={courseSearcherFormLang.baseCourse} />
                            <ToggleButton
                                inputName='searchByIsBase'
                                onToggle={() => this.handleChangeIsBase()}
                                toggled={searchValues.isBase}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className='_margin_bottom_25'>
                    <Col md="5">
                        <Label text={courseSearcherFormLang.searchByType} />
                        <InputComboBox
                            name='searchByCourseType'
                            placeholder={generalSearcherLang?.selectOne}
                            ref={(ref) => (this.courseTypeRef = ref)}
                            id='searchByCourseType'
                            key='searchByCourseType'
                            dropdownItems={this.formatSelectCourseTypes()}
                            onChange={(selectedOption) =>
                                this.handleChangeSelect(
                                    {
                                        value: this.props.courseTypes?.items.find(
                                            (i) => i.name === selectedOption
                                        )?.id,
                                        label: selectedOption,
                                    },
                                    'course_type'
                                )
                            }
                            value={selects.course_type?.label}
                            clearable
                        />
                    </Col>
                    <Col md="5">
                        <CustomFormField
                            className="course-finder__institute"
                            controlId="searchByInstitute"
                            label={courseSearcherFormLang.searchByInstitute}
                            inputComponent={
                                <Select
                                    name="searchByInstitute"
                                    placeholder={generalSearcherLang?.selectOne}
                                    id="searchByInstitute"
                                    key="searchByInstitute"
                                    options={this.getInstituteOptions()}
                                    onChange={this.handleInstituteChange}
                                    onInputChange={this.handleInstituteInputChange}
                                    styles={selectStyles}
                                    value={selects.institute}
                                    isSearchable
                                    isClearable
                                />
                            }
                        />
                    </Col>
                </Row>
            </>
        )
    }

    handleSubmit(e) {
        e.preventDefault()

        let { name, alias, isBase, institute, course_type } =
            this.state.searchValues
        const [search, setActiveSearch, changeLoading, goToPageTop, setCurrentPage] = this.props.onSubmit
        let searchData = {}
        if (name !== null && name !== '') {
            searchData.name = name
        }
        if (alias !== null && alias !== '') {
            searchData.alias = alias
        }
        if (isBase !== null) {
            searchData.base = isBase
        }
        if (institute !== null) {
            searchData.institute_id = institute
        }
        if (course_type !== null) {
            searchData.course_type_id = course_type
        }

        search({ page: 1, ...searchData }, { order_by: { item: 'created_at', value: 'desc' } })
        setActiveSearch(
            searchValues({
                name,
                alias,
                isBase,
                institute_id: institute,
                course_type_id: course_type,
            })
        )
        changeLoading(true)
        goToPageTop()
        setCurrentPage(1)
    }

    handleChangeName(e) {
        this.setState({
            ...this.state,
            searchValues: {
                ...this.state.searchValues,
                name: e.target.value,
            },
        })
    }

    resetNameSearch() {
        this.setState({
            ...this.state,
            searchValues: {
                ...this.state.searchValues,
                name: '',
            }
        })
    }

    handleChangeAlias(e) {
        this.setState({
            ...this.state,
            searchValues: {
                ...this.state.searchValues,
                alias: e.target.value,
            },
        })
    }

    resetAliasSearch() {
        this.setState({
            ...this.state,
            searchValues: {
                ...this.state.searchValues,
                alias: '',
            }
        })
    }

    handleChangeSelect(selectedOption, type) {
        let value = !selectedOption ? null : selectedOption.value
        this.setState({
            ...this.state,
            searchValues: {
                ...this.state.searchValues,
                [type]: value,
            },
            selects: {
                ...this.state.selects,
                [type]: selectedOption,
            },
        })
    }

    handleInstituteChange(selectedOption) {
        this.handleChangeSelect(
            selectedOption,
            'institute'
        )
    }

    handleInstituteInputChange(value) {
        this.props.loadPaginatedInstitutes(1, value)
    }

    formatSelectInstitutes() {
        return this.props.allInstitutes?.institutes?.map((i) => i.name)
    }

    getInstituteOptions() {
        return this.props.allInstitutes?.institutes?.map((institute) => {
            return { value: institute.id, label: institute.name }
        })
    }

    formatSelectCourseTypes() {
        return this.props.courseTypes.items?.filter((type) => type.visibility).map((i) => i.name)
    }

    searchValues() {
        return { ...this.state.searchValues, isEmpty: this.state.isEmpty }
    }

    render() {
        return this.searchBar()
    }
}

const mapDispatch = (dispatch) => ({
    loadPaginatedInstitutes: (page, name) => {
        dispatch(loadPaginatedInstitutes(page, name))
    }
})

const mapStateToProps = (state) => {
    return {
        allInstitutes: state.instituteReducer.allInstitutes,
    }
}

export default connect(mapStateToProps, mapDispatch)(CourseFinder)
