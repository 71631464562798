import React from 'react'
import PropTypes from 'prop-types'
import BlockIcon from '@/components/BlockIcon'
import {
    PENDING_FEEDBACK,
    APPROVED,
    FAILED,
    PENDING_RETRY,
} from '../../../../shared/constants/evaluableActivityStatus'

const CourseReportBlockContent = ({ userItem, activeUnit, activeLesson, courseItem }) => {
    const blocks = userItem.Course.Units[activeUnit].Lessons[activeLesson].Topics.find(
        item => item.id == courseItem.id,
    )?.Blocks ?? []
    const blockWidth = blocks.length === 1 ? { width: '100px' } : {}

    const classByBlockStatus = {
        completed: '_approved_content',
        incompleted: '_not_approved_content',
        pending_feedback: '_pending_feedback_content',
    }

    return (
        <div className='_report_blocks_content'>
            {blocks.map((block) => {
                const statusField = block.status ? block.status : block.completed
                const iconClass =
                    statusField === APPROVED || statusField === 1
                        ? classByBlockStatus.completed
                        : statusField === FAILED || statusField === 0
                        ? classByBlockStatus.incompleted
                        : statusField === PENDING_FEEDBACK
                        ? classByBlockStatus.pending_feedback
                        : null
                return (
                    <div key={block.id} style={blockWidth}>
                        <BlockIcon
                            type={block.blockType}
                            className={iconClass}
                            defaultColor={!iconClass || block?.status === PENDING_RETRY}
                        />
                    </div>
                )
            })}
        </div>
    )
}

CourseReportBlockContent.propTypes = {
    userItem: PropTypes.object.isRequired,
    activeUnit: PropTypes.number.isRequired,
    activeLesson: PropTypes.number.isRequired,
    courseItem: PropTypes.object.isRequired,
}

export default CourseReportBlockContent
