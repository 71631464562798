import { FILES_SIZE_LIMIT_MB } from '@/config/env'
import { uploaderConfig } from '../constants/imageUploaderConfig'

export const restrictionMessage = (baseString) =>
    baseString
        .replace('{{FILES_LIMIT}}', uploaderConfig.FILES_LIMIT)
        .replace('{{FILES_SIZE}}', FILES_SIZE_LIMIT_MB)
        .replace('{{FILES_UNIT}}', uploaderConfig.FILES_UNIT)
        .replace('{{FILES_TYPES}}', uploaderConfig.FILES_TYPES)

export const acceptedTypes = () =>
    uploaderConfig.FILES_TYPES.split(',')
        .map((type) => `.${type}`)
        .join()
