'use-strict'

const ROLES_QTY = 5

export default {
    all: [],
    allPermissions: [],
    defaultPermissionsRole: [],
    activeRole: {
        name: '',
        description: '',
        Permissions: []
    },
    paginated: {
        totalRows: 0,
        pagesQuantity: 0,
        rows: [],
        nextPage: null
    },
    paginationConfig: {
        page: 1,
        limit: ROLES_QTY
    },
    loading: {
        loadPaginatedRoles: false,
        loadAllRoles: false,
        loadSingleRole: false,
        saveRole: false,
    }
}