'use-strict'

import React from 'react'

import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Searcher from '@digitalhouse-tech/react-lib-ui-explorer/lib/Searcher'

import '../Roles.scss'

export default ({
    generalSearcherLang,
    handleCreate,
    lang,
    loading,
    onChangeHandler,
    searchSubmit,
    searchValues,
    showCreateRole,
}) => {

    const getFinderBody = () => (
        <div className='row _margin_bottom_25'>
            <div className='col-md-12'>
                <Label text={lang.searchByRole} />
                <InputText
                    autoFocus
                    deletable
                    id='searchByDescription'
                    onChange={(e) => onChangeHandler('description', e.currentTarget.value)}
                    onCloseIcon={() => onChangeHandler('description', '')}
                    value={searchValues.description}
                />
            </div>
        </div>
    )

    return (
        <>
            <Searcher
                actions={[
                    {
                        handler: handleCreate,
                        id: 'CreateRoleListButton',
                        name: generalSearcherLang?.create,
                        permission: { showCreateRole },
                    },
                ]}
                formBody={getFinderBody()}
                loadingStatus={loading}
                searchSubmitHandler={searchSubmit}
                submitText={generalSearcherLang?.submitSearch}
            />
        </>
    )
}
