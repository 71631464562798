import React from "react";

export const Javascript = () => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.3929 0H1.60714C0.719866 0 0 0.687145 0 1.53409V12.7841C0 13.631 0.719866 14.3182 1.60714 14.3182H13.3929C14.2801 14.3182 15 13.631 15 12.7841V1.53409C15 0.687145 14.2801 0 13.3929 0ZM8.16295 11.1669C8.16295 12.5604 7.3058 13.1964 6.05692 13.1964C4.92857 13.1964 4.27567 12.6403 3.94085 11.9659L5.08929 11.3043C5.31027 11.6783 5.51116 11.9947 5.99665 11.9947C6.45871 11.9947 6.75335 11.8221 6.75335 11.1477V6.57422H8.16295V11.1669ZM11.4978 13.1964C10.1886 13.1964 9.34152 12.6019 8.92969 11.8221L10.0781 11.1893C10.3795 11.6591 10.7746 12.0075 11.4676 12.0075C12.0502 12.0075 12.4252 11.7294 12.4252 11.3427C12.4252 10.8825 12.0435 10.7195 11.3973 10.4478L11.0458 10.304C10.0279 9.89169 9.35491 9.37074 9.35491 8.2745C9.35491 7.26456 10.1618 6.49751 11.4174 6.49751C12.3147 6.49751 12.9576 6.79474 13.4196 7.57457L12.3214 8.24574C12.0804 7.83345 11.8192 7.67045 11.4141 7.67045C11.0022 7.67045 10.7411 7.91974 10.7411 8.24574C10.7411 8.64844 11.0022 8.81143 11.6083 9.06392L11.9598 9.20774C13.1585 9.69673 13.8315 10.1985 13.8315 11.3235C13.8315 12.5316 12.8337 13.1964 11.4978 13.1964Z" fill="#D9D9D9"/>
    </svg>
);

export const Html = () => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0L1.36328 13.2522L7.48047 15L13.6367 13.2522L15 0H0ZM12.0391 4.28237H4.85938L5.01953 5.93638H11.8789L11.3477 10.9051L7.52344 11.8092V11.8192H7.48047L3.625 10.9051L3.39063 8.36719H5.25391L5.39062 9.64286L7.48047 10.1283L9.57812 9.64286L9.8125 7.56027H3.29297L2.79297 2.68527H12.2109L12.0391 4.28237V4.28237Z" fill="#D9D9D9"/>
    </svg>
);

export const Css = () => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0L1.36328 12.6499L7.5 14.3182L13.6367 12.6499L15 0H0ZM12.2305 2.55682L12.043 4.06854L7.53906 5.64418L7.52734 5.64737H11.8828L11.3828 10.3327L7.54687 11.25L3.6875 10.3168L3.4375 7.9549H5.34766L5.47266 9.17898L7.52734 9.60405L9.66406 9.11186L9.80859 7.14311L3.3125 7.12713V7.12393L3.30469 7.12713L3.16406 5.64737L7.54297 4.15483L7.79688 4.06854H2.99609L2.76953 2.55682H12.2305Z" fill="#D9D9D9"/>
    </svg>
);

export const Java = () => (
    <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.1866 13.6314C4.1866 13.6314 3.51345 14.0231 4.66629 14.1553C6.06314 14.3149 6.77696 14.292 8.31587 14.0011C8.31587 14.0011 8.72128 14.2546 9.28641 14.4743C5.83537 15.9527 1.47636 14.3886 4.1866 13.6314ZM3.76467 11.7018C3.76467 11.7018 3.00947 12.2608 4.1633 12.3803C5.65547 12.5343 6.83359 12.5469 8.87322 12.1543C8.87322 12.1543 9.15466 12.4403 9.59791 12.5965C5.42629 13.8165 0.779907 12.6925 3.76467 11.7018Z" fill="#D9D9D9"/>
        <path d="M7.31931 8.42797C8.1701 9.40726 7.09619 10.2877 7.09619 10.2877C7.09619 10.2877 9.25515 9.17341 8.26386 7.77741C7.33766 6.47616 6.62766 5.82984 10.4717 3.60042C10.4717 3.60056 4.43763 5.10713 7.31931 8.42797Z" fill="#D9D9D9"/>
        <path d="M11.8834 15.0588C11.8834 15.0588 12.3817 15.4698 11.3343 15.7873C9.34298 16.3906 3.04445 16.5725 1.29513 15.8115C0.666608 15.5378 1.84571 15.1584 2.21667 15.0783C2.60345 14.9946 2.8243 15.0098 2.8243 15.0098C2.12489 14.5174 -1.69626 15.9771 0.883648 16.3958C7.91888 17.5362 13.7079 15.8822 11.8834 15.0588ZM4.51063 9.70203C4.51063 9.70203 1.30714 10.463 3.37629 10.7396C4.2501 10.8566 5.99122 10.8297 7.61415 10.6936C8.94011 10.5822 10.2707 10.3443 10.2707 10.3443C10.2707 10.3443 9.80361 10.5446 9.46527 10.7754C6.21151 11.6312 -0.072627 11.2326 1.7367 10.3577C3.26629 9.61787 4.51063 9.70203 4.51063 9.70203ZM10.2575 12.9141C13.5647 11.196 12.0354 9.54486 10.968 9.76727C10.7071 9.82164 10.59 9.86894 10.59 9.86894C10.59 9.86894 10.6872 9.71658 10.8724 9.65105C12.9839 8.909 14.6076 11.8402 10.1914 13.0013C10.1914 13.001 10.2421 12.9551 10.2575 12.9141Z" fill="#D9D9D9"/>
        <path d="M8.26384 0C8.26384 0 10.0951 1.83234 6.52653 4.64906C3.66477 6.90927 5.874 8.19767 6.52554 9.67035C4.85488 8.16321 3.62946 6.83626 4.45145 5.60138C5.65851 3.78909 9.00251 2.91006 8.26384 0Z" fill="#D9D9D9"/>
        <path d="M4.83566 17.5713C8.00949 17.7742 12.8846 17.4584 13 15.9564C13 15.9564 12.7782 16.5258 10.3767 16.9775C7.66748 17.4876 4.32532 17.4281 2.34372 17.101C2.34372 17.1011 2.7497 17.4372 4.83566 17.5713Z" fill="#D9D9D9"/>
    </svg>
)