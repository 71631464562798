import React from 'react'
import PropTypes from 'prop-types'
import { Edit2, Trash2 } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'

const ToolListActions = ({ openDeleteModal, openFormModal, showEditButton, showDeleteButton }) => {
    return (
        <>
            <ButtonGroup className="toolPanel__buttons">
                {showDeleteButton &&
                    <ButtonRound
                        icon={<Trash2 />}
                        id="deleteToolButton"
                        onClick={() => openDeleteModal()}
                        theme='danger'
                    />
                }
                {showEditButton &&
                    <ButtonRound
                        icon={<Edit2 />}
                        id="editCourseToolButton"
                        onClick={() => openFormModal()}
                        theme='primary_dark'
                    />
                }
            </ButtonGroup>
        </>
    )
}

ToolListActions.propTypes = {
    openDeleteModal: PropTypes.func.isRequired,
    openFormModal: PropTypes.func.isRequired,
    showEditButton: PropTypes.bool.isRequired,
    showDeleteButton: PropTypes.bool.isRequired
}

export default ToolListActions
