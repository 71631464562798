import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { schema, emptyErrors } from './validations'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import TextAreaInput from '@digitalhouse-tech/react-lib-ui-explorer/lib/TextAreaInput'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'
import LimitCounter from '@digitalhouse-tech/react-lib-ui-explorer/lib/LimitCounter'
import { Info } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import useValidations from '@/hooks/useValidations'
import {
    TOOL_NAME_MAX_LENGTH as NAME_MAX_LENGTH,
    TOOL_DESCRIPTION_MAX_LENGTH as DESCRIPTION_MAX_LENGTH,
} from '../../../../constants/formValidations'

import './styles/ToolsModal.scss'

const ToolsModal = ({
    lang,
    modalTitle,
    onCancel,
    onSubmit,
    show,
    tool,
}) => {
    const toolValidationsLang = lang.validations
    const EMPTY_TOOL = { name: '', description: '', tool_url: '', tutorial_url: '' }
    const [formTool, setFormTool] = useState(EMPTY_TOOL)

    const { char: charLang } = useSelector(({ langStore: { forms } }) => forms)

    useEffect(() => {
        setFormTool(tool)
    }, [tool])

    useEffect(() => {
        if (tool.id) {
            setHasErrors(false)
        }
        return () => setHasErrors(true)
    },[tool])

    const [hasErrors, setHasErrors] = useState(false)

    const { handleInputChange, errors } = useValidations({
        emptyErrors,
        setHasErrors,
        schema,
        setData: setFormTool,
        data: formTool,
    })

    useEffect(() => {
        const keys = Object.keys(errors)
        keys.map(key => {
            errors[key].state = 'success'
        })
    }, [tool])

    const clearFormData = () => setFormTool(EMPTY_TOOL)

    const handleClose = () => {
        clearFormData()
        onCancel()
    }

    const formatErrorMessage = (key, limit) =>
        toolValidationsLang[key] && toolValidationsLang[key].replace('{{LIMIT}}', limit)

    return (
        <Modal backdrop="static" bsSize="large" className="__modal" keyboard={false} onHide={handleClose} show={show}>
            <Modal.Header className="__modal_header __border_bottom" closeButton>
                <h3 className="__modal_title">{modalTitle}</h3>
            </Modal.Header>

            <Modal.Body className="__modal_body tools-modal-body">
                <form>
                    <div className='tools-modal-body__label'>
                        <Label text={`${lang.toolNameFormLabel} *`} />
                        <LimitCounter
                            className='tools-modal-body__label-counter'
                            limitTheme='danger'
                            limit={NAME_MAX_LENGTH}
                            icon='AlertCircle'
                            limitReached={formTool.name.length >= NAME_MAX_LENGTH}
                            text={charLang}
                            count={Number(formTool.name.length)}
                        />
                    </div>
                    <div className="tools-modal-body__input">
                        <InputText
                            id="ToolsNameInput"
                            onChange={({ target: { value } }) => handleInputChange(value, 'name')}
                            errorMsg={formatErrorMessage(errors.name.message, NAME_MAX_LENGTH)}
                            error={errors.name.state === 'error'}
                            value={formTool.name}
                        />
                    </div>

                    <div className='tools-modal-body__label'>
                        <Label text={`${lang.toolDescriptionFormLabel} *`} />
                        <LimitCounter
                            className='tools-modal-body__label-counter'
                            limitTheme='danger'
                            limit={DESCRIPTION_MAX_LENGTH}
                            icon='AlertCircle'
                            limitReached={formTool.description.length >= DESCRIPTION_MAX_LENGTH}
                            text={charLang}
                            count={Number(formTool.description.length)}
                        />
                    </div>
                    <div className="tools-modal-body__input">
                        <TextAreaInput
                            id="ToolsDescriptionInput"
                            onChange={({ target: { value } }) => handleInputChange(value, 'description')}
                            errorMsg={formatErrorMessage(errors.description.message, DESCRIPTION_MAX_LENGTH)}
                            error={errors.description.state === 'error'}
                            value={formTool.description}
                        />
                    </div>

                    <div className='tools-modal-body__label'>
                        <Label text={`${lang.toolUrlFormLabel} *`} />
                        <div className='tools-modal-body__label--tooltip'>
                            <Tooltip
                                tooltipContent={lang.toolDescriptionTooltip}
                                tooltipId={`${lang.toolUrlFormLabel}`}
                            >
                                <Info size={16} />
                            </Tooltip>
                        </div>
                    </div>
                    <div className="tools-modal-body__input">
                        <InputText
                            id="ToolsURLInput"
                            onChange={({ target: { value } }) => handleInputChange(value, 'tool_url')}
                            errorMsg={toolValidationsLang[errors.tool_url.message]}
                            error={errors.tool_url.state === 'error'}
                            value={formTool.tool_url}
                        />
                    </div>

                    <Label text={lang.toolTutorialFormLabel} />
                    <InputText
                        id="TutorialURLInput"
                        onChange={({ target: { value } }) => handleInputChange(value, 'tutorial_url')}
                        errorMsg={toolValidationsLang[errors.tutorial_url.message]}
                        error={errors.tutorial_url.state === 'error'}
                        value={formTool.tutorial_url}
                    />
                </form>
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup className="pull-right tools-modal-buttons">
                    <Button
                        id="NotificationsModalCancelButton"
                        onClick={handleClose}
                        text={lang.cancel}
                        theme='tertiary'
                    />

                    <Button
                        disabled={hasErrors}
                        id="NotificationsModalSaveButton"
                        onClick={() => onSubmit(formTool)}
                        text={lang.save}
                        theme='primary'
                    />
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    )
}

ToolsModal.propTypes = {
    lang: PropTypes.object.isRequired,
    modalTitle: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    tool: PropTypes.object,
}

ToolsModal.defaultProps = {
    modalTitle: '',
    tool: {},
}

export default ToolsModal
