import React, { useEffect } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import ToolList from './ToolList'
import { actions as managerActions } from '@/redux/reducers/managerReducer'
import * as managerThunks from '@/redux/thunks/managerThunks'
import * as courseToolsThunks from '@/redux/thunks/courseToolsThunks'
import * as courseService from '@/redux/http/services/courseService'
import showNewMessage from '@/redux/reducers/_deprecated/globalAlertsReducer/alertsControllers'
import { loadCoursePermissions } from '@/redux/reducers/_deprecated/authReducer'
import {
    CREATE_TOOLS,
    EDIT_TOOLS,
    DELETE_TOOLS
} from '../../../../constants/coursePermissions'

const ToolListContainer = ({
    createCourseTool,
    deleteCourseTool,
    lang,
    location,
    managerDataLoader,
    match,
    tools,
    updateCourseTool,
    loadCoursePermissions
}) => {
    const dispatch = useDispatch()
    useEffect(() => {
        return () => dispatch(managerActions.clearRowsValues())
    }, [])

    const { alertMessage: alertMessageLang } = useSelector(
        ({ langStore: { courseTools } }) => courseTools,
    )

    const courseId = match.params.courseId

    useEffect(() => {
        loadCoursePermissions(courseId)
    })

    const showAlert = message => {
        showNewMessage({
            type: 'success',
            message: message,
            isTemporal: true,
        })
    }

    const deleteTool = (courseId, toolId) => {
        deleteCourseTool(courseId, toolId)
        showAlert(alertMessageLang.successDeleteTool)
    }

    const handleSubmit = async tool => {
        const { id: toolId } = tool
        if (toolId) {
            await updateCourseTool(courseId, toolId, tool)
            showAlert(alertMessageLang.successUpdateTool)
        } else {
            await createCourseTool(courseId, tool)
            showAlert(alertMessageLang.successCreateTool)
        }
    }

    return (
        <ToolList
            courseName={location.state.courseName}
            deleteCourseTool={deleteTool}
            handleSubmit={submittedTool => handleSubmit(submittedTool)}
            lang={lang}
            managerDataLoader={managerDataLoader}
            searcherFields={{
                search: {},
                course_id: courseId,
            }}
            tools={tools}
            showCreateButton={CREATE_TOOLS()}
            showEditButton={EDIT_TOOLS()}
            showDeleteButton={DELETE_TOOLS()}
        />
    )
}

ToolListContainer.propTypes = {
    createCourseTool: PropTypes.func.isRequired,
    deleteCourseTool: PropTypes.func.isRequired,
    lang: PropTypes.shape().isRequired,
    location: PropTypes.shape({
        hash: PropTypes.string,
        key: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
        state: PropTypes.shape({
            courseName: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    managerDataLoader: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            courseId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    tools: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            tool_url: PropTypes.string.isRequired,
            tutorial_url: PropTypes.string,
        }).isRequired,
    ).isRequired,
    updateCourseTool: PropTypes.func.isRequired,
    loadCoursePermissions: PropTypes.func.isRequired,
}

const managerDataLoaderThunk = managerThunks.managerDataLoader({
    loadingName: 'courseTools.loadCourseTools',
    serviceHandler: courseService.loadCourseTools,
})

const mapDispatch = {
    createCourseTool: courseToolsThunks.createCourseTool,
    deleteCourseTool: courseToolsThunks.deleteCourseTool,
    managerDataLoader: managerDataLoaderThunk,
    updateCourseTool: courseToolsThunks.updateCourseTool,
    loadCoursePermissions: courseId => loadCoursePermissions(courseId)
}

const mapState = state => ({
    lang: state.langStore.courseTools,
    tools: state.manager.rows,
})

export default connect(mapState, mapDispatch)(ToolListContainer)
