import { type } from '@/helpers/utils/propTypes'

const VideoBlock = {
    id: type.number,
    url: type.string,
    height: type.string,
    width: type.string,
    created_at: type.string,
    updated_at: type.string,
}

const TextBlock = {
    created_at: type.string,
    id: type.number,
    text: type.string,
    updated_at: type.string,
}

const SlideBlock = {
    id: type.number,
    url: type.string,
    height: type.string,
    width: type.string,
    created_at: type.string,
    updated_at: type.string
}

const TextHtmlBlock = {
    created_at: type.string,
    id: type.number,
    html: type.string,
    updated_at: type.string,
}

const variableDeclaration = {
    type: type.oneValue(['VariableDeclaration']),
    hint: type.undefinableString,
    properties: type.object({
        name: type.string,
        type: type.string,
        value: type.number,
        kind: type.string
    })
}

const Rule = {
    id: type.number,
    rule_type_id: type.number,
    created_at: type.nulleableString,
    updated_at: type.nulleableString,
    ExerciseRule: type.object({
        exercise_block_id: type.number,
        rule_id: type.number,
        created_at: type.nulleableString,
        updated_at: type.nulleableString,
    }),
    RuleType: type.object({
        id: type.number,
        name: type.string,
        parameters: type.any,
        created_at: type.nulleableString,
        updated_at: type.nulleableString
    }),

    config: type.json(
        type.oneType([
            type.object({
                type: type.oneValue(['CSS']),
                selector: type.string,
                hint: type.string,
                properties: type.array(type.object({
                    attribute: type.string,
                    value: type.string
                }))
            }),
            type.object({
                type: type.oneValue(['HeadChild']),
                child: type.string,
                hint: type.string,
            }),
            type.object({
                type: type.oneValue(['Selector']),
                selector: type.string,
                hint: type.string,
                quantity: type.undefinableOneType([type.string, type.number]),
                content: type.undefinableString,
                attributes: type.undefinableArray(type.object({
                    name: type.string,
                    value: type.string
                }))
            }),

            type.object({
                type: type.oneValue(['VariableDeclaration']),
                hint: type.string,
                properties: type.object({
                    name: type.string,
                    type: type.string,
                    value: type.string,
                    kind: type.string
                })
            }),

            type.object({
                type: type.oneValue(['FunctionDeclaration']),
                properties: type.object({
                    name: type.string,
                    args: type.undefinableArray(type.sring),
                    argsLength: type.undefinableNumber,
                    rules: type.undefinableArray(type.object(variableDeclaration)),
                }),
                hint: type.string
            }),

            type.object({
                type: type.oneValue(['CallFunction']),
                hint: type.string,
                properties: type.object({
                    name: type.string,
                    calls: type.array(type.object({
                        args: type.array(type.string),
                        returnValue: type.oneType([
                            type.string,
                            type.array(type.any),
                            type.number,
                            type.anyObject
                        ])
                    }))
                })
            }),

            type.object({
                type: type.oneValue(['SearchText']),
                hint: type.string,
                properties: type.object({
                    regexp: type.boolean,
                    text: type.string,
                    exists: type.undefinableBoolean,
                    quantity: type.undefinableNumber,
                }),
            })
        ])
    ),
}

const ExerciseBlock = {
    id: type.number,
    exercise_type_id: type.number,
    name: type.string,
    statement: type.string,
    contextual_code: type.string,
    contextual_css: type.string,
    hidden_code: type.string,
    contextual_js: type.string,
    hidden_code_js: type.string,
    tests: type.string,
    mocked_functions: type.string,
    general_hints: type.json(type.array(type.string)),
    moral: type.string,
    created_at: type.nulleableString,
    updated_at: type.nulleableString,
    Rules: type.array(type.object(Rule)),
    ExerciseType: type.object({
        id: type.number,
        name: type.string,
        created_at: type.string,
        updated_at: type.nulleableString,
    })
}

const QuizBlock = {
    id: type.number,
    quiz_id: type.string,
    created_at: type.string,
    updated_at: type.string
}

const Block = {
    ExerciseBlock: type.nulleableObject(ExerciseBlock),
    FileBlock: type.null,
    QuizBlock: type.nulleableObject(QuizBlock),
    SlideBlock: type.nulleableObject(SlideBlock),
    TextBlock: type.nulleableObject(TextBlock),
    VideoBlock: type.nulleableObject(VideoBlock),
    TextHtmlBlock: type.nulleableObject(TextHtmlBlock),
    created_at: type.string,
    exercise_block_id: type.nulleableNumber,
    extra: type.boolean,
    file_block_id: type.nulleableNumber,
    id: type.number,
    name: type.string,
    order: type.number,
    parent_id: type.nulleableNumber,
    quiz_block_id: type.nulleableNumber,
    slide_block_id: type.nulleableNumber,
    text_block_id: type.nulleableNumber,
    topic_id: type.number,
    updated_at: type.string,
    video_block_id: type.nulleableNumber,
    visibility: type.boolean
}

const Topic = {
    Blocks: type.array(type.object(Block)),
    created_at: type.string,
    id: type.number,
    lesson_id: type.number,
    name: type.string,
    order: type.number,
    parent_id: type.nulleableNumber,
    updated_at: type.string,
    visibility: type.boolean,
    disableStudentVisibility: type.boolean
}

const Lesson = {
    GroupLesson: type.any,
    LessonType: type.object({
        id: type.number,
        name: type.string,
        created_at: type.string,
        updated_at: type.nulleableString
    }),
    Topics: type.array(type.object(Topic)),
    created_at: type.string,
    due_date: type.nulleableString,
    group_lesson_id: type.nulleableNumber,
    id: type.number,
    lesson_type_id: type.number,
    name: type.string,
    order: type.number,
    parent_id: type.nulleableNumber,
    release_date: type.string,
    unit_id: type.number,
    updated_at: type.string,
    visibility: type.boolean,
}

const Unit = {
    Lessons: type.array(type.object(Lesson)),
    course_id: type.number,
    created_at: type.string,
    id: type.number,
    name: type.string,
    order: type.number,
    parent_id: type.nulleableNumber,
    release_date: type.string,
    updated_at: type.string,
    visibility: type.boolean
}

export const loadUserActiveCoursePayloadScheme = {
    CourseType: type.object({
        created_at: type.string,
        id: type.number,
        name: type.string,
        updated_at: type.nulleableString
    }),
    Parent: type.nulleableObject({
        alias: type.string,
        base: type.boolean,
        code: type.nulleableNumber,
        course_type_id: type.number,
        created_at: type.string,
        finish_date: type.string,
        id: type.number,
        initial_date: type.string,
        institute_id: type.number,
        name: type.string,
        parent_id: type.nulleableNumber,
        poster: type.nulleableString,
        updated_at: type.string,
    }),
    Units: type.array(type.object(Unit)),
    alias: type.nulleableString,
    base: type.boolean,
    code: type.nulleableNumber,
    course_type_id: type.number,
    created_at: type.string,
    finish_date: type.string,
    id: type.number,
    initial_date: type.string,
    institute_id: type.number,
    isLoaded: type.boolean,
    isValidCourse: type.boolean,
    name: type.string,
    parent_id: type.nulleableNumber,
    updated_at: type.string
}
