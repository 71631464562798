import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import FileTools from './FileTools'
import FileList from './FileList'
import FileEditModal from './FileEditModal'
import FileUploadModal from './FileUploadModal'
import FileDeleteModal from './FileDeleteModal'
import CardLoader from '@/components/CardLoader'
import FileUploadWindow from '@/components/FileUploadWindow'
import * as env from '@/config/env'
import './FileManager.scss'

let fileUploadInputRef = null

const FileManager = ({
    onUploadFile,
    onDownloadFile,
    onEditFile,
    onDeleteFile,
    onError,
    fileList,
    lang,
    loading,
    showDeleteButton,
    showEditButton,
    showDownloadButton,
    showUploadButton
}) => {
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [searchInputValue, setSearchInputValue] = useState('')
    const [fileSelected, setFileSelected] = useState(null)
    const [fileNameForUploadModal, setFileNameForUploadModal] = useState('')

    const [fileNameAndIdForEditModal, setFileNameAndIdForEditModal] = useState({
        id: 0,
        name: ''
    })
    const [fileForDeleteModal, setFileForDeleteModal] = useState({
        id: 0,
        name: ''
    })

    const filterFiles = (files, filterValue) => {
        if (filterValue === '') return files
        return files.filter(file => {
            let fileAux = file.name.replace('.jpeg', '').toLocaleLowerCase()
            let filterValueAux = filterValue.toLocaleLowerCase()

            return fileAux.includes(filterValueAux) || moment(file.date.toString()).format('MM/DD/YYYY').includes(filterValueAux)
        })
    }

    const uploadModalConfirmHandler = async (newFileName) => {
        onUploadFile(fileSelected, newFileName)
        setShowUploadModal(false)
        setFileNameForUploadModal('')
    }

    const uploadModalCancelHandler = () => {
        setShowUploadModal(false)
        setFileNameForUploadModal('')
    }

    const validateFileName = fileName => {
        if (!fileName.includes('.')) return false
        const fileNameSplited = fileName.split(".");
        if (!fileNameSplited.length) return false
        const posibleExtensionExtracted = fileNameSplited[fileNameSplited.length - 1]
        if (posibleExtensionExtracted.includes(' ')) return false
        return true
    }

    const selectFileErrorHandler = (fileName, error)=> onError(fileName, error)

    const selectFileHandler = async (file) => {
        setFileSelected(file)
        setFileNameForUploadModal(file.name)
        setShowUploadModal(true)
    }

    const serachInputValueHandler = value => {
        setSearchInputValue(value)
    }

    const uploadFileHandler = e => {
        fileUploadInputRef.click()
    }

    const downloadHandler = async (path, filename) => {
        onDownloadFile(path, filename)
    }

    const editHandler = (fileId, fileName) => {
        setShowEditModal(true)
        setFileNameAndIdForEditModal({
            id: fileId,
            name: fileName
        })
    }

    const deleteHandler = async (fileId) => {
        setFileForDeleteModal({
            id: fileId,
            name: fileList.find(file => file.id === fileId).name
        })
        setShowDeleteModal(true)
    }

    const deleteConfirmHandler = (fileName, fileId) => {
        onDeleteFile(fileId)
        setShowDeleteModal(false)
    }

    const deleteCancelHandler = () => {
        setShowDeleteModal(false)
    }

    const acceptEditHandler = async (fileId, fileName) => {
        onEditFile(fileId, fileName)
        setShowEditModal(false)
    }

    const cancelEditHandler = () => {
        setShowEditModal(false)
    }

    return (
        <div className="FileManager __card">
            {loading && (
                <div className="container">
                    <CardLoader show={loading} />
                </div>
            )}
            <FileUploadWindow
                onSelectFile={selectFileHandler}
                onError={selectFileErrorHandler}
                refInputFile={ref => (fileUploadInputRef = ref)}
                sizeLimitInMegabytes={env.FILES_SIZE_LIMIT_MB}
            />

            <FileEditModal
                id={fileNameAndIdForEditModal.id}
                name={fileNameAndIdForEditModal.name}
                onAccept={acceptEditHandler}
                show={showEditModal}
                onCancel={cancelEditHandler}
                lang={lang.fileModal}
                fileList={fileList}
            />

            <FileUploadModal
                name={fileNameForUploadModal}
                show={showUploadModal}
                onCancel={uploadModalCancelHandler}
                onAccept={uploadModalConfirmHandler}
                lang={lang.fileModal}
                fileList={fileList}
            />

            <FileDeleteModal
                fileName={fileForDeleteModal.name}
                fileId={fileForDeleteModal.id}
                onAccept={deleteConfirmHandler}
                onCancel={deleteCancelHandler}
                show={showDeleteModal}
                lang={lang.fileModal}
            />

            <FileTools
                serachInputValue={searchInputValue}
                onSerachInputValue={serachInputValueHandler}
                onUploadFile={uploadFileHandler}
                lang={lang.tools}
                showUploadButton={showUploadButton}
            />

            <FileList
                files={filterFiles(fileList, searchInputValue)}
                onDownload={downloadHandler}
                onDelete={deleteHandler}
                onEdit={editHandler}
                showDeleteButton={showDeleteButton}
                showEditButton={showEditButton}
                showDownloadButton={showDownloadButton}
            />
        </div>
    )
}

FileManager.propTypes = {
    onUploadFile: PropTypes.func,
    onDownloadFile: PropTypes.func,
    onEditFile: PropTypes.func,
    onDeleteFile: PropTypes.func,
    onError: PropTypes.func,
    fileList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        courseId: PropTypes.number,
        isEditable: PropTypes.bool,
        name: PropTypes.string,
        date: PropTypes.string
    })),
    loading: PropTypes.bool.isRequired,
}

export default FileManager
