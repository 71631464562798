import React from 'react'
import Skeleton from '@/components/Skeleton'
import './Skeleton.scss'

const CourseSkeleton = ({ isBlock }) => (
    <>
        <Skeleton containerStyle='quiz-skeleton' height='100px' width='100%' />
        <div className='quiz-skeleton__buttons'>
            <span className='quiz-skeleton__buttons--item'></span>
            <span className='quiz-skeleton__buttons--item'></span>
            {isBlock && <span className='quiz-skeleton__buttons--item'></span>}
        </div>
    </>
)

export default CourseSkeleton
