import useHistory from '@/hooks/useHistory'

export default () => {
    const history = useHistory()

    const certificateRedirect = (courseId, trackFinishCourseEvent) => {
        history.push(`/course/${courseId}/certificate`)
        trackFinishCourseEvent(window.location.href)
    }

    return {
        certificateRedirect,
    }
}
