export const slider = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 21,
    slidesToScroll: 3,
    initialSlide: 0,
    variableWidth: true,
    responsive: [
        {
            breakpoint: 2600,
            settings: {
                slidesToShow: 21,
                slidesToScroll: 2,
                initialSlide: 0
            }
        },
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 14,
                slidesToScroll: 2,
                initialSlide: 0
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 10,
                slidesToScroll: 2,
                initialSlide: 0
            }
        },
        {
            breakpoint: 979,
            settings: {
                slidesToShow: 12,
                slidesToScroll: 2,
                initialSlide: 0
            }
        },
        {
            breakpoint: 750,
            settings: {
                slidesToShow: 10,
                slidesToScroll: 2,
                initialSlide: 0
            }
        },
        {
            breakpoint: 650,
            settings: {
                slidesToShow: 7,
                slidesToScroll: 2,
                initialSlide: 0
            }
        },
        {
            breakpoint: 582,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 2,
                initialSlide: 0
            }
        },
        {
            breakpoint: 510,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 2,
                initialSlide: 0
            }
        },
        {
            breakpoint: 426,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
                initialSlide: 0
            }
        },
        
    ]
};