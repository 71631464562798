import React from 'react'
import PropTypes from 'prop-types'
import SummerNote from '@/components/Wysiwyg'
import LimitCounter from '@digitalhouse-tech/react-lib-ui-explorer/lib/LimitCounter'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import {
    MAX_REVIEW_CHARS_FEEDBACK,
    EXCLAMATION_ICON,
    FILES_LIMI,
    FILES_UNIT,
    FILES_TYPES,
} from '../../../shared/constants/dashboard'
import FilesUploaderManager from '@/components/FilesUploaderManager'
import * as env from '@/config/env'
import { EVALUABLE_BLOCK_FILES } from '@/constants/vaultyPaths'
import './FeedbackForm.scss'

const FeedbackForm = ({
    lang,
    onChangeReviewFiles,
    onReviewChange,
    reviewFiles,
    htmlReviewProps,
    filesUploaderErrorMsg,
    canEdit,
}) => {
    const vaultyConfig = {
        apiKey: env.VAULTY_API_KEY,
        writerUrl: `${env.VAULTY_WRITER_URL}`,
        readerUrl: `${env.VAULTY_READER_URL}/${env.VAULTY_PATH}`,
    }

    const filesUploaderRestrcitionMsg = lang.attachFilesRestriction
        .replace('{{FILES_LIMIT}}', FILES_LIMI)
        .replace('{{FILES_SIZE}}', env.FILES_SIZE_LIMIT_MB)
        .replace('{{FILES_UNIT}}', FILES_UNIT)
        .replace('{{FILES_TYPES}}', FILES_TYPES)

    return (
        <div className='teacher-feedback-form'>
            <h3 className='teacher-feedback-form__feedback'>{lang.feedback}</h3>
            <div className='teacher-feedback-form__summernote-container'>
                <LimitCounter
                    limit={MAX_REVIEW_CHARS_FEEDBACK}
                    text={lang.chars}
                    count={htmlReviewProps.chars}
                    limitTheme='danger'
                    limitReached={htmlReviewProps.chars >= MAX_REVIEW_CHARS_FEEDBACK}
                    icon={EXCLAMATION_ICON}
                    className='summernote-limit-counter'
                />
                <div className={htmlReviewProps.error ? '__wysiwyg--error' : null}>
                    <SummerNote
                        id='FeedbackFormEvaluableActivity'
                        onChange={(value) => onReviewChange(value)}
                        disabled={!canEdit}
                    />
                </div>
                {htmlReviewProps.error && (
                    <MessageBox theme='danger' text={lang.requiredInput} noFill />
                )}
            </div>
            <FilesUploaderManager
                title={lang.attachFiles}
                subtitle={filesUploaderRestrcitionMsg}
                onChange={onChangeReviewFiles}
                files={reviewFiles}
                maxUploadLimit={FILES_LIMI}
                maxFileSizeLimit={env.FILES_SIZE_LIMIT_MB}
                vaultyConfig={vaultyConfig}
                errorMsg={filesUploaderErrorMsg}
                disabled={!canEdit}
                vaultyFolder={EVALUABLE_BLOCK_FILES}
            />
        </div>
    )
}

FeedbackForm.propTypes = {
    lang: PropTypes.object,
    onChangeReviewFiles: PropTypes.func,
    onReviewChange: PropTypes.func,
    reviewFiles: PropTypes.array,
    reviewTextLength: PropTypes.number,
    filesUploaderErrorMsg: PropTypes.string,
    canEdit: PropTypes.bool,
}

FeedbackForm.defaultProps = {
    lang: {},
    onChangeReviewFiles: () => {},
    onReviewChange: () => {},
    reviewFiles: [],
    reviewTextLength: 0,
    filesUploaderErrorMsg: '',
    canEdit: false,
}

export default FeedbackForm
