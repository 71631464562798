import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import './IncompatibleVersionAlert.scss'

import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'

const DefaultStaticAlert = ({
    show,
    showConfirmButton,
    onCancel,
    lang,
}) => {

    return (
        <>
            <Modal
                className="__modal __modal_confirmation staticAlertModal"
                show={show}
                keyboard={false}
                backdrop="static"
                onHide={onCancel}
            >
                <Modal.Header closeButton className="__modal_header __border_bottom" />

                <Modal.Body className="__modal_body">
                    <h3 className="modalTitle">{lang?.title ?? 'Error'}</h3>
                    <p className="modalMessage">{lang?.message}</p>
                </Modal.Body>

                <Modal.Footer className="__modal_footer __border_top staticAlertFooter">
                    <div className="_btn_group pull-right">
                   
                        <Button
                            id="ConfirmButton"
                            visible={showConfirmButton}
                            theme='primary'
                            onClick={onCancel}
                            text={lang.closeMessage}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

DefaultStaticAlert.propTypes = {
    show: PropTypes.bool,
    showConfirmButton: PropTypes.bool,
    onCancel: PropTypes.func,
    lang: PropTypes.any.isRequired,
}

DefaultStaticAlert.defaultProps = {
    show: false,
    showConfirmButton: false,
    onCancel: () => {}
}


export default DefaultStaticAlert