import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getTopicProgress } from '@/redux/selectors/students/progress'
import Lesson from './Lesson'
import './LessonList.scss'

const LessonList = ({ lessons, showDates, allowApiCreate }) => {
    const topicProgresses = useSelector(getTopicProgress)

    return (
        <div className="lesson-list-container">
            <ul className="lesson-list-container__lessons">
                {lessons.map(lesson => (
                    <Lesson
                        lesson={lesson}
                        key={lesson.id}
                        progresses={topicProgresses}
                        showDates={showDates}
                        allowApiCreate={allowApiCreate}
                    />
                ))}
            </ul>
        </div>
    )
}

LessonList.propTypes = {
    lessons: PropTypes.array.isRequired,
    showDates: PropTypes.bool,
    allowApiCreate: PropTypes.bool,
}

LessonList.defaultProps = {
    showDates: false,
    allowApiCreate: false,
}

export default LessonList