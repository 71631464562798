// export const HTML_CSS = 'HTML-CSS'
export const JAVASCRIPT = 'JAVASCRIPT'
export const JAVA_JDK11 = 'JAVA-JDK11'
// export const DOM = 'DOM'

export const BROWSER = 'Browser'
export const NODEJS_TERMINAL = 'NodejsTerminal'
export const JAVA_TERMINAL = 'JavaTerminal'
export const SRC = 'SRC'
export const SOLUTION = 'SOLUTION'

export const LOG = 'log'
export const THROW = 'throw'

export const HTML_LANGUAGE = 'html'
export const CSS_LANGUAGE = 'css'
export const JS_LANGUAGE = 'javascript'
export const JAVA_LANGUAGE = 'java'

export const HTML_EXTENSION = 'html'
export const CSS_EXTENSION = 'css'
export const JS_EXTENSION = 'js'
export const JAVA_EXTENSION = 'java'
