/**
 * @param {string} input
 * The string to be checked
 * @param {string} errorMessage
 * The string to be used as an error message if validation fails
 * @returns {string}
 * returns error message if validation fails
 */
export const validateEmptyString = (input = '', errorMessage = '') => {
    if (!input || input.trim() === '') {
        return errorMessage
    }
}
