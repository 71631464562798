import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import OwnerAssign from './OwnerAssign'
import { loadUsers } from '@/redux/thunks/userThunks'
import { courseOwnershipAssign, loadOwnershipRoles } from '@/redux/thunks/ownerThunks'
import { loadCourse } from '@/redux/thunks/courseThunks'
import { loadCoursePermissions } from '@/redux/reducers/_deprecated/authReducer'
import { clearAllUsers } from '@/redux/reducers/userReducer'

const OwnerAssignContainer = ({
    users,
    roles,
    getUsers,
    courseTypeId,
    clearAllUsers,
    getOwnershipRoles,
    loadCourse,
    courseOwnershipAssign,
    match,
    nextPage,
    pagesQuantity,
    lang,
    loadCoursePermissions
}) => {

    const usersPerPage = 5 //en el listado de búsqueda de usuarios

    const [loadingUserSearch, setLoadingUserSearch] = useState(false)

    const courseId = match.params.courseId
    const pagination = {
        nextPage,
        pagesQuantity,
    }

    const usersSearch = async (name, surname, email, page) => {
        setLoadingUserSearch(true)

        const pageNumber = page ? page : 1

        let userDataSearch = pageNumber ? '?page=' + pageNumber : ''
        userDataSearch += name ? '&name=' + name : ''
        userDataSearch += surname ? '&surname=' + surname : ''
        userDataSearch += email ? '&email=' + email : ''
        userDataSearch += '&limit=' + usersPerPage
        userDataSearch += '&course_id=' + courseId
        userDataSearch += '&skippable=ownerships'

        await getUsers(userDataSearch)
        setLoadingUserSearch(false)
    }

    useEffect(() => {
        loadCourse(courseId)
        loadCoursePermissions(courseId)
    }, [loadCourse, loadCoursePermissions, courseId])

    useEffect(() => {
        courseTypeId && getOwnershipRoles(courseTypeId)
    }, [getOwnershipRoles, courseTypeId])

    return (
        <OwnerAssign
            courseId={courseId}
            users={users}
            getUsers={usersSearch}
            clearUsers={clearAllUsers}
            pagination={pagination}
            roles={roles}
            loadingUserSearch={loadingUserSearch}
            courseOwnershipAssign={courseOwnershipAssign}
            lang={lang}
        />
    )
}

const mapDispatch = dispatch => ({
    getUsers: userData => dispatch(loadUsers(userData)),
    clearAllUsers: () => dispatch(clearAllUsers()),
    getOwnershipRoles: (courseTypeId) => dispatch(loadOwnershipRoles(courseTypeId)),
    loadCourse: (courseId) => dispatch(loadCourse(courseId)),
    courseOwnershipAssign: (courseId, data) =>
        dispatch(courseOwnershipAssign(courseId, data)),
    loadCoursePermissions: (courseId) => dispatch(loadCoursePermissions(courseId))
})

const mapStateToProps = state => {
    return {
        roles: state.owner.ownershipRoles,
        users: state.user.paginated.rows,
        courseTypeId: state.course.course.course_type_id,
        pagesQuantity: state.user.paginated.pagesQuantity,
        nextPage: state.user.paginated.nextPage,
        lang: state.langStore.ownershipAssign
    }
}

OwnerAssignContainer.propTypes = {
    users: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    getUsers: PropTypes.func.isRequired,
    clearAllUsers: PropTypes.func.isRequired,
    getOwnershipRoles: PropTypes.func.isRequired,
    loadCourse: PropTypes.func.isRequired,
    courseOwnershipAssign: PropTypes.func.isRequired,
    courseTypeId: PropTypes.string.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            courseId: PropTypes.string,
        }),
    }).isRequired,
    loadCoursePermissions: PropTypes.func.isRequired,
    nextPage: PropTypes.number.isRequired,
    pagesQuantity: PropTypes.number.isRequired,
    lang: PropTypes.shape({
        ownerAssign: PropTypes.string.isRequired,
        backButton: PropTypes.string.isRequired,
        cancel: PropTypes.string.isRequired,
        leaveProcess: PropTypes.string.isRequired,
        ownerAssignSubtitle: PropTypes.string.isRequired,
        firstStep: PropTypes.string.isRequired,
        secondStep: PropTypes.string.isRequired,
        usersSearch: PropTypes.string.isRequired,
        usersSearchSubtitle: PropTypes.string.isRequired,
        searchByName: PropTypes.string.isRequired,
        searchBySurname: PropTypes.string.isRequired,
        searchByEmail: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
        userSelection: PropTypes.string.isRequired,
        emptyResults: PropTypes.string.isRequired,
        roleAssign: PropTypes.string.isRequired,
        roleAssignSubtitle: PropTypes.string.isRequired,
        notSelectedUsers: PropTypes.string.isRequired,
        incompleteAssign: PropTypes.string.isRequired,
        incompleteAssignMsg: PropTypes.string.isRequired
    }).isRequired,
}

export default withRouter(connect(mapStateToProps, mapDispatch)(OwnerAssignContainer))
