import React from 'react'
import { history } from '@/app/store'

import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import { Edit2, Eye, EyeOff } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { VISIBILITY_MODAL } from '@/constants/modals'
import Tab from './Tab'

export default class TabsListPresentational extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {}

        this.showTabs = this.showTabs.bind(this)
        this.openModalVisibility = this.openModalVisibility.bind(this)
        this.actionButtons = this.actionButtons.bind(this)
        this.goToEditTab = this.goToEditTab.bind(this)

    }

    goToEditTab(e, tabId) {
        e.preventDefault()
        let courseId = this.props.courseId
        history.push("/admin/courses/" + courseId + "/tabs/" + tabId)
    }

    openModalVisibility(e, tab) {
        let visibilityInfo = tab

        this.props.showModalVisibility(VISIBILITY_MODAL, {})
        this.props.setActiveTabAction(visibilityInfo)

    }

    actionButtons(tab, i) {
        return (
            <ButtonGroup key={'tabButtons' + i}>
            {this.props.showVisibilityButton &&
                <ButtonRound
                    id="openModalVisibiltyButton"
                    key={'visibilityBtn' + i}
                    theme='info'
                    onClick={(e) => this.openModalVisibility(e, tab)}
                    icon={tab.visibility ? <Eye /> : <EyeOff />}
                />
            }
            {this.props.showEditButton &&
                <ButtonRound
                    id="goToEditTabButton"
                    key={'editBtn' + i}
                    theme='primary_dark'
                    onClick={(e) => this.goToEditTab(e, tab.id)}
                    icon={<Edit2 />}
                />
            }
            </ButtonGroup>
        )
    }

    showTabs() {
        let tabs = []

        this.props.courseTabs.map((tab, i) => {
            tabs.push(
                <Tab key={i} tabName={tab.name} buttons={tab.is_editable && this.actionButtons(tab, i)} />
            )
        })

        return (tabs)
    }

    render() {
        return (
            <React.Fragment>
                {this.showTabs()}
            </React.Fragment>
        )
    }
}
