import {
    BASE_PRESENTEEISM,
    BASE_CONTENT_VIEWED,
    BASE_APPROVED,
    STATUS,
    APPROVED,
    DISAPPROVED,
    IS_CHALLENGER_LIMIT,
    IS_AVANCED_LIMIT,
    IS_BEGGINER_LIMIT
} from '../constants'

export const finalGradeRanges = {
    isBegginer: (note) => note < IS_BEGGINER_LIMIT,
    isApprentice: (note) => note >= IS_BEGGINER_LIMIT && note < IS_AVANCED_LIMIT,
    isAdvanced: (note) => note >= IS_AVANCED_LIMIT,
    isChallenger: (note) => note === IS_CHALLENGER_LIMIT,
}

export const isPresenteeismApproved = (percentage) => percentage >= BASE_PRESENTEEISM
export const isContentApproved = (contentViewed) => contentViewed >= BASE_CONTENT_VIEWED

const getGradeStatus = (grade) => (grade >= BASE_APPROVED ? APPROVED : DISAPPROVED)

export const shapeStatus = (grade) => {
    const gradeStatus = getGradeStatus(grade)
    return STATUS[gradeStatus]
}

