import React from 'react'
import { connect } from 'react-redux'
import Spinner from '@digitalhouse-tech/react-lib-ui-explorer/lib/Spinner'
import './CustomCargando.scss'


export class CustomCargando extends React.Component {

    render() {
        return (
            <div className="CustomCargando">
                <div className="text-center" >
                    <h5 >
                        <Spinner />&nbsp;
                        {this.props.lang.load}
                    </h5>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        lang: state.langStore.customCargando
    }
}

export default connect(mapStateToProps)(CustomCargando)