/**
 * This function receives hours and minutes and add the corresponding abbreviation to each one
 * @param {number} hoursLeft 
 * The hours left to a due date
 * @param {number} minutesLeft 
 * The minutes left to a due date
 * @example
 * hoursLeft = 2
 * minuterLeft = 20
 * formatTimeLeftMessage(hoursLeft, minutesLeft)
 * returns '2hs 20mins'
 * 
 * hoursLeft = 1
 * minuterLeft = 1
 * formatTimeLeftMessage(hoursLeft, minutesLeft)
 * returns '1h 1min'
 */

export const formatTimeLeftMessage = (hoursLeft, minutesLeft) => {
    let msg = ''

    if(hoursLeft) {
        msg += `${hoursLeft} hs `
    }

    if(minutesLeft) {
        msg += `${minutesLeft} min`
    }

    return msg
}