import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { emptyStateAsset } from '@/assets/img/config'
import  './NoResults.scss'

const NoResults = ({ text, bgColor }) => {
    const { noElementsText } = useSelector(({langStore: {notifications}}) => notifications)
    return (
        <div className="no-results" style={{ backgroundColor: bgColor }}>
            <p className="no-results__text">{ text || noElementsText }</p>
            <img
                className="no-results__image"
                src={emptyStateAsset('default-no-results.svg')}
                alt={text || noElementsText}
            />
        </div>
    )
}

NoResults.propTypes = {
    bgColor: PropTypes.string,
    text: PropTypes.string
}

NoResults.defaultProps = {
    bgColor: null,
    text: null
}

export default NoResults