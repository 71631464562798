/**
 *
 * @param {string} yes
 * The label for the option yes
 * @param {string} no
 * The label for the option no
 */
export const getSelectOptions = (yes = '', no = '') => {
    return [
        { value: true, label: yes },
        { value: false, label: no },
    ]
}