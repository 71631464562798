import React, { createRef } from 'react'
import { useSelector } from 'react-redux'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import InputTag from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputTag'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Searcher from '@digitalhouse-tech/react-lib-ui-explorer/lib/Searcher'
import { offset, scrollTo } from '@/helpers/utils'
import { history } from '@/app/store'

import Results from './Results'
import {
    EDIT_EXERCISE,
    CREATE_EXERCISE,
    CLONE_EXERCISE,
} from '@digitalhouse-tech/ts-lib-store/lib/session-storage/permission'
import ExerciseCloneModal from './ExerciseCloneModal/ExerciseCloneModal'
import { useExerciseSearcher } from './hooks/useExerciseSearcher'
import './ExerciseList.scss'

const ExerciseList = () => {
    const lang = useSelector(({ langStore }) => langStore.exerciseList)
    const generalSearcherLang = useSelector(({ langStore }) => langStore.generalSearcher)
    const exerciseManager = useSelector(({ exerciseManagerStore }) => exerciseManagerStore)
    const { allTags, allExerciseTypes, paginated, loading, paginationConfig } = exerciseManager
    const mapTagsName = () => allTags.map((tag) => tag.name)
    const mapExercisesNameTypes = () => allExerciseTypes.map((type) => type.name)

    const exerciseListRef = createRef()
    const {
        handleCloneExercise,
        handleCloseModal,
        onEditExercise,
        onCopyExercise,
        onChangeTags,
        onChangeType,
        onChangeName,
        onClickSearchButton,
        searchName,
        searchTags,
        searchType,
        showCloneModal,
        currentCloneId,
        currentCloneName,
        goToPage,
        checkUsedName,
    } = useExerciseSearcher()

    const actionsComponentId = 'CreateExerciseListButton'

    const scrollToListTop = () => {
        const exerciseListTop = offset(exerciseListRef.current).top - 50
        scrollTo(window, exerciseListTop)
    }

    const searchSubmitHandler = (e) => {
        e.preventDefault()
        onClickSearchButton()
        scrollToListTop()
    }

    const changePageHandler = (page) => {
        goToPage(page)
        scrollToListTop()
    }

    const getFormBody = () => (
        <div className='_margin_bottom_25'>
            <form className='exercise-search__box__inputs'>
                <div className='exercise-search__box__inputs__group--text'>
                    <Label text={lang.name} theme='searcher' />
                    <InputText
                        autoFocus
                        className='input-name'
                        deletable
                        glow={false}
                        id='exerciseNameSearchInput'
                        onChange={(e) => onChangeName(e.target.value)}
                        onClick={() => {}}
                        onCloseIcon={() => onChangeName('')}
                        value={searchName}
                    />
                </div>
                <div className='exercise-search__box__inputs__group--text'>
                    <Label text={lang.tags} theme='searcher' />
                    <InputTag
                        dropdownItems={mapTagsName()}
                        id='exerciseTagSearchInput'
                        maxTags={2}
                        noResultsText={lang.exerciseNoTypeResults}
                        onChange={(tag) => onChangeTags(tag)}
                        selectedTags={searchTags}
                    />
                </div>
                <div className='exercise-search__box__inputs__group--text'>
                    <Label text={lang.type} theme='searcher' />
                    <InputComboBox
                        dropdownItems={mapExercisesNameTypes()}
                        glow={false}
                        id='exerciseTypeSearchInput'
                        noResultsText={lang.exerciseNoTypeResults}
                        onChange={(value) => onChangeType(value)}
                        placeholder={generalSearcherLang?.selectOne}
                        searchable
                        value={searchType}
                    />
                </div>
            </form>
        </div>
    )

    const getSearcherActions = () =>
        allExerciseTypes.map((type) => {
            const url = `/admin/exercises/create?exerciseType=${type.name}`
            return {
                handler: () => history.push(url),
                id: `${actionsComponentId}_${type.name}`,
                name: type.name,
            }
        })

    return (
        <div className='exercise'>
            <Searcher
                actions={getSearcherActions()}
                actionsComponentId={actionsComponentId}
                actionsComponentText={generalSearcherLang?.create}
                actionsComponentPermission={CREATE_EXERCISE()}
                formBody={getFormBody()}
                resultsTitle={generalSearcherLang?.mostRecent}
                searchSubmitHandler={searchSubmitHandler}
                submitText={generalSearcherLang?.submitSearch}
            />
            <div className='exercise-results' ref={exerciseListRef}>
                <Results
                    goToPage={(page) => changePageHandler(page)}
                    lang={lang}
                    loading={loading.loadPaginatedExercises}
                    onClickCopyExercise={onCopyExercise}
                    onClickEditExercise={onEditExercise}
                    onCloseMessageBox={() => {}}
                    paginator={{ ...paginationConfig, total: paginated.totalRows, pagesQuantity: paginated.pagesQuantity }}
                    searchResults={paginated.rows}
                    showEditButton={EDIT_EXERCISE()}
                    showCloneButton={CLONE_EXERCISE()}
                />
            </div>
            <ExerciseCloneModal
                loading={loading}
                show={showCloneModal}
                lang={lang}
                closeModal={handleCloseModal}
                exerciseId={currentCloneId}
                exerciseName={currentCloneName}
                cloneExercise={handleCloneExercise}
                checkUsedName={checkUsedName}
            />
        </div>
    )
}

export default ExerciseList
