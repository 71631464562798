import * as permissions from '@digitalhouse-tech/ts-lib-store/lib/session-storage/permission'
import { STUDENT_GUARD } from '@/constants/guards'

import Surveys from '@/modules/Student/Surveys'
import StudentModules from '@/modules/Student/Modules'
import StudentTab from '@/modules/Student/StudentTab'
import Welcome from '@/modules/Student/Welcome'
import StudentLayout from '@/components/Layout/StudentLayout'
import CourseInfo from '@/modules/Student/CourseInfo'
import Classroom from '@/modules/Student/Classroom'
import Certificate from '@/modules/Student/Classroom/Certificate'

const routes = [
    {
        path: '/',
        key: 'Student - Welcome',
        exact: true,
        permission: permissions.STUDENT_PERMISSION,
        component: Welcome,
        secured: true,
    },
    {
        path: '/courses/:courseId/lastSurvey',
        key: 'Student - Last Survey',
        exact: true,
        permission: permissions.STUDENT_PERMISSION,
        component: Surveys,
        secured: true,
    },
    {
        path: '/course/:courseId/units',
        key: 'Student - Course Modules / Units',
        exact: true,
        permission: permissions.STUDENT_PERMISSION,
        component: StudentModules,
        secured: true,
    },
    {
        path: '/course/:courseId/unit/:unitId/lesson/:lessonId/topic/:topicId',
        key: 'Student - Classroom',
        exact: true,
        permission: permissions.STUDENT_PERMISSION,
        component: Classroom,
        secured: true,
    },
    {
        path: '/course/:courseId/tab/:tabId',
        key: 'Student - Tab',
        exact: true,
        permission: permissions.STUDENT_PERMISSION,
        component: StudentTab,
        secured: true,
    },
    {
        path: '/course/:courseId/info',
        key: 'Student - Course info',
        exact: true,
        permission: permissions.STUDENT_PERMISSION,
        component: CourseInfo,
        secured: true,
    },
    {
        path: '/course/:courseId/certificate',
        key: 'Student - Course Certificate',
        exact: true,
        permission: permissions.STUDENT_PERMISSION,
        component: Certificate,
        secured: true,
    },
]

export default routes.map((route) => ({
    ...route,
    path: route.path,
    layout: StudentLayout,
    guard: STUDENT_GUARD,
}))
