export const ENABLE_FILES = 'ENABLE_FILES'
export const ENABLE_PROGRESS_REPORTS = 'ENABLE_PROGRESS_REPORTS'
export const ENABLE_SURVEYS = 'ENABLE_SURVEYS'
export const ENABLE_TABS = 'ENABLE_TABS'
export const ENABLE_REPORTS = 'ENABLE_REPORTS'
export const ENABLE_TOOLS = 'ENABLE_TOOLS'
export const ENABLE_ENHANCED_REPORTS = 'ENABLE_ENHANCED_REPORTS'
export const ENABLE_SEND_EMAILS = 'ENABLE_SEND_EMAILS'
export const ENABLE_PREWORK_REPORT = 'ENABLE_PREWORK_REPORT'
export const ENABLE_OWNERS = 'ENABLE_OWNERS'
export const ENABLE_STUDENTS = 'ENABLE_STUDENTS'
export const ENABLE_CLONE = 'ENABLE_CLONE'
export const ENABLE_SHOW_COMPLETE_DATE = 'ENABLE_SHOW_COMPLETE_DATE'

export const DISABLE_UNIT_DATES = 'DISABLE_UNIT_DATES'
export const DISABLE_UNIT_COMPLETE_DATE = 'DISABLE_UNIT_COMPLETE_DATE'

export const DISABLE_LESSON_DATES = 'DISABLE_LESSON_DATES'
export const DISABLE_LESSON_COMPLETE_DATE = 'DISABLE_LESSON_COMPLETE_DATE'

export const ENABLE_ROADMAP_URL = 'ENABLE_ROADMAP_URL'
export const ENABLE_EGG = 'ENABLE_EGG'
export const ENABLE_CERTIFICATE = 'ENABLE_CERTIFICATE'
export const ENABLE_BLOCK_ISSUE_REPORT = 'ENABLE_BLOCK_ISSUE_REPORT'

export const PG_ONLY_BASE = 'PG_ONLY_BASE'
export const ENABLE_SHOW_COURSE_DATES = 'ENABLE_SHOW_COURSE_DATES'
export const ENABLE_DESCRIPTION = 'ENABLE_DESCRIPTION'
export const ENABLE_ENHANCED_MODULES = 'ENABLE_ENHANCED_MODULES'
export const ENABLE_COURSE_INFO = 'ENABLE_COURSE_INFO'
export const ENABLE_LESSON_ICONS = 'ENABLE_LESSON_ICONS'
export const MUST_CHANGE_PASSWORD = 'MUST_CHANGE_PASSWORD'
export const ENABLE_ONBOARDING = 'ENABLE_ONBOARDING'
export const ENABLE_BASE_CLONE_VISIBILITY = 'ENABLE_BASE_CLONE_VISIBILITY'

export const ALLOW_API_CREATE = 'ALLOW_API_CREATE'
export const ALLOW_API_ENROLMENT = 'ALLOW_API_ENROLMENT'
export const ALLOW_API_LESSONS = 'ALLOW_API_LESSONS'
export const ENABLE_API_MANAGEMENT = 'ENABLE_API_MANAGEMENT'

export const ENABLE_STUDENT_PROFILE_ACCESS = 'ENABLE_STUDENT_PROFILE_ACCESS'
export const ENABLE_DISCORD_ACCESS = 'ENABLE_DISCORD_ACCESS'

export const ALLOW_BASE_COURSE_MODIFY_UNIT_LESSON = 'ALLOW_BASE_COURSE_MODIFY_UNIT_LESSON'
export const ALLOW_BASE_COURSE_UPDATE_UNIT_LESSON = 'ALLOW_BASE_COURSE_UPDATE_UNIT_LESSON'
export const ALLOW_BASE_COURSE_MODIFY_TOPIC_BLOCK = 'ALLOW_BASE_COURSE_MODIFY_TOPIC_BLOCK'

export const ALLOW_CHILD_COURSE_MODIFY_UNIT_LESSON = 'ALLOW_CHILD_COURSE_MODIFY_UNIT_LESSON'
export const ALLOW_CHILD_COURSE_UPDATE_UNIT_LESSON = 'ALLOW_CHILD_COURSE_UPDATE_UNIT_LESSON'
export const ALLOW_CHILD_COURSE_MODIFY_TOPIC_BLOCK = 'ALLOW_CHILD_COURSE_MODIFY_TOPIC_BLOCK'
