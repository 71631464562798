import React, { useState } from 'react'
import { history } from '@/app/store'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import './AddNewPasswordForm.scss'
import GoBackBar from '../GoBackBar'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import InputPassword from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputPassword'

const INPUT_STATE = () => ({
    error: false,
    message: '',
    value: '',
})

const AddNewPasswordForm = ({
    lang: {
        cancelAddNewPassword: cancelText,
        error: errorText,
        errorConfirmEmpty: errorConfirmEmptyText,
        errorEmpty: errorEmptyText,
        goBack: goBackText,
        inputConfirm: inputConfirmText,
        inputConfirmPlaceholder,
        inputPassword: inputPasswordText,
        inputPasswordPlaceholder,
        invalidPassword: invalidPasswordText,
        sendNewPassword: sendNewPasswordText,
    },
    onGoBack,
    onSubmit,
}) => {
    const [newPassword, setNewPassword] = useState(INPUT_STATE())
    const [passwordConfirm, setPasswordConfirm] = useState(INPUT_STATE())
    const [passwordPlaceholder, setPasswordPlaceholder] = useState(inputPasswordPlaceholder)
    const [confirmPasswordPlaceholder, setConfirmPasswordPlaceholder] = useState(
        inputConfirmPlaceholder
    )

    const hidePlaceholder = ({ name }) => {
        name === 'password' ? setPasswordPlaceholder('') : setConfirmPasswordPlaceholder('')
    }

    const showPlaceholder = ({ name }) => {
        name === 'password'
            ? setPasswordPlaceholder(inputPasswordPlaceholder)
            : setConfirmPasswordPlaceholder(inputConfirmPlaceholder)
    }

    const handleCancel = () => {
        history.goBack()
    }

    const validatePassword = (password) => !password.includes(' ')

    const onSubmitHandler = () => {
        setNewPassword({ ...newPassword, error: false, message: '' })
        setPasswordConfirm({ ...passwordConfirm, error: false, message: '' })
        let validation = true

        if (newPassword.value === '') {
            setNewPassword({ ...newPassword, error: true, message: errorEmptyText })
            validation = false
        }

        if (passwordConfirm.value === '') {
            setPasswordConfirm({ ...passwordConfirm, error: true, message: errorConfirmEmptyText })
            validation = false
        }

        if (!validatePassword(newPassword.value)) {
            setNewPassword({ ...newPassword, error: true, message: invalidPasswordText })
            validation = false
        }

        if (newPassword.value !== passwordConfirm.value) {
            setPasswordConfirm({ ...passwordConfirm, error: true, message: errorText })
            validation = false
        }

        if (validation) {
            onSubmit(newPassword.value)
            return
        }
        return
    }

    return (
        <div className='addNewPasswordFormMobile'>
            <GoBackBar text={goBackText} onGoBack={onGoBack} />
            <div className='form'>
                <Row>
                    <Col lg="12">
                        <InputPassword
                            id='input-password'
                            label={inputPasswordText}
                            value={newPassword.value}
                            name='password'
                            placeholder={passwordPlaceholder}
                            onChange={(e) => {
                                setNewPassword({ ...newPassword, value: e.target.value })
                            }}
                            onFocus={({ target }) => hidePlaceholder(target)}
                            onBlur={({ target }) => showPlaceholder(target)}
                            error={newPassword.error}
                            errorMsg={newPassword.message}
                        />
                    </Col>
                    <Col lg="12">
                        <InputPassword
                            id='password-confirm'
                            label={inputConfirmText}
                            value={passwordConfirm.value}
                            name='confirmPassword'
                            placeholder={confirmPasswordPlaceholder}
                            onChange={(e) => {
                                setPasswordConfirm({ ...passwordConfirm, value: e.target.value })
                            }}
                            onFocus={({ target }) => hidePlaceholder(target)}
                            onBlur={({ target }) => showPlaceholder(target)}
                            error={passwordConfirm.error}
                            errorMsg={passwordConfirm.message}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" className='centerLoginButton'>
                        <Button
                            id='CancelButton'
                            theme='secondary'
                            block
                            onClick={handleCancel}
                            text={cancelText}
                        />
                        <Button
                            id='SubmitButton'
                            theme='primary'
                            block
                            type='submit'
                            onClick={onSubmitHandler}
                            text={sendNewPasswordText}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default AddNewPasswordForm
