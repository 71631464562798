import React, { useState, useEffect, useRef } from 'react'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import PropTypes from 'prop-types'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'

const IframeExercise = ({ setUrlReference, defaultUrlReference }) => {
    const [url, setUrl] = useState(defaultUrlReference ?? '')
    const [postMessage, setPostMessage] = useState('')
    const initialState = '{"blocks":{"languageVersion":0,"blocks":[{"type":"on_execute","id":"rwW]g?!-iwJNk))r*~^C","x":61,"y":69}]}}'
    const ref = useRef();

    const handleUrlReference = (newUrl) => {
        setUrl(newUrl)
        setUrlReference(newUrl)
    }


    useEffect(() => {
        window.onmessage = function (event) {
            if (event.data.type === 'blockly-type') setPostMessage(event.data.message)
        }

        const iframe = ref.current

        iframe.addEventListener('load', () => {
            iframe.contentWindow.postMessage({
                data: initialState,
                type: 'init'
            }, '*');
        });

    }, [url, initialState])

    return (
        <div className='row'>
            <div className='col-md-12'>
                <Label text={`URL`} />
                <InputText
                    id='name'
                    value={url}
                    onChange={(e) => {handleUrlReference(e.target.value)}}
                    autoFocus
                />
            </div>
            <div className='col-md-12' style={{ marginBottom: '20px', marginTop: '20px' }}>
                <Label text={`Preview`} />

                <iframe
                    id='dhs-lab'
                    src={url}
                    style={{
                        padding: 0,
                        border: '1px solid #d9d9d9',
                        outline: 'none',
                        boxSizing: 'border-box',
                        width: '100%',
                        height: '560px',
                        display: 'block',
                        margin: 'auto',
                        textAlign: 'center',
                    }}
                    ref={ref}
                ></iframe>
                {postMessage !== "" && (
                    <MessageBox
                        centeredText
                        fullWidth
                        text={postMessage}
                    />
                )}
            </div>
        </div>
    )
}

export default IframeExercise

IframeExercise.propTypes = {
    urlReference: PropTypes.string,
    defaultUrlReference: PropTypes.string,
    setUrlReference: PropTypes.func.isRequired,
}

IframeExercise.defaultProps = {
    urlReference: '',
    defaultUrlReference: '',
}
