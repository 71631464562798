import * as env from './env'
import SearchURL from '@/helpers/utils/SearchURL'

export const base = env.COURSE_API_URL

const login = 'auth'
const oauth = 'oauth'
const users = 'users'
export const courses = 'courses'
const courseTypes = 'courseTypes'
const lessonTypes = 'lessonTypes'
const groupsLesson = 'groupsLesson'
const units = 'units'
const lessons = 'lessons'
const topics = 'topics'
const blocks = 'blocks'
const exercises = 'exercises'
const enrolments = 'enrolments'
const institutes = 'institutes'
const countries = 'countries'
const states = 'states'
const cities = 'cities'
const levels = 'levels'
const roles = 'roles'
const userTypes = 'users/types'
const userprogress = 'userprogress'
const runners = 'runners'
const reports = 'reports'
const quizzes = 'quizzes'
export const tabs = 'tabs'
const files = 'files'
const resetPassword = 'users/recover'
const userValidateToken = 'users/validate'
const updatePassword = 'users/updatePassword'
const legalDisclaimerAccept = '/legal-disclaimer/accept'
const permissions = 'permissions'
export const usersAvatar = 'usersAvatar'

export default {
    base: base,
    login: base + login,
    oauth: base + oauth,
    users: base + users,
    files: base + files,
    resetPassword: base + resetPassword,
    userValidateToken: base + userValidateToken,
    updatePassword: base + updatePassword,
    legalDisclaimerAccept: base + users + legalDisclaimerAccept,
    searchCourseURLGenerator: (params) => {
        let urlString = `${base}${courses}?`
        let paramListForURL = []

        // ! = busqueda tipo like
        // > = mayor a fecha de inicio
        // < = mayor a fecha de finalización

        params.page && paramListForURL.push(`page=${params.page}`)
        paramListForURL.push(`limit=15`)
        params.order_by &&
            paramListForURL.push(`order=${params.order_by.item}:${params.order_by.value}`)
        params.name && paramListForURL.push(`search[]=!name[:]${encodeURIComponent(params.name)}`)
        params.alias &&
            paramListForURL.push(`search[]=!alias[:]${encodeURIComponent(params.alias)}`)
        params.base !== undefined && paramListForURL.push(`search[]=base[:]${params.base ? 1 : 0}`)
        params.initial_date &&
            paramListForURL.push(`search[]=>initial_date[:]${params.initial_date}`)
        params.finish_date && paramListForURL.push(`search[]=<finish_date[:]${params.finish_date}`)
        params.institute_id &&
            paramListForURL.push(`search[]=institute_id[:]${params.institute_id}`)
        params.course_type_id &&
            paramListForURL.push(`search[]=course_type_id[:]${params.course_type_id}`)

        paramListForURL.map((param, index) => {
            urlString += param
            if (index < paramListForURL.length - 1) urlString += '&'
        })

        return urlString
    },
    courseTypes: base + courseTypes,
    lessonTypes: base + lessonTypes,
    groupsLesson: base + groupsLesson,
    filterUsers: (page, name, surname, email, relatedId, type, order_by) => {
        let route = base + users
        route += page ? '?page=' + page : ''
        route += name ? '&name=' + name : ''
        route += surname ? '&surname=' + surname : ''
        route += email ? '&email=' + email : ''
        route += relatedId ? '&relatedId=' + relatedId : ''
        route += type ? '&type=' + type : ''
        route += order_by
            ? page
                ? '&order_by[created_at]=' + order_by
                : '?order_by[created_at]=' + order_by
            : ''
        return route
    },
    courses: base + courses,
    cloneCourse: base + courses + '/' + 'clonecourse',
    runners: base + runners,
    executePreview: (options) =>
        `${base}${runners}/preview?userEmail=${options.userEmail}&type=${options.type}`,
    units: (courseId) => base + courses + '/' + courseId + '/' + units,
    lessons: (courseId, unitId) =>
        base + courses + '/' + courseId + '/' + units + '/' + unitId + '/' + lessons,
    topics: (courseId, unitId, lessonId) =>
        base +
        courses +
        '/' +
        courseId +
        '/' +
        units +
        '/' +
        unitId +
        '/' +
        lessons +
        '/' +
        lessonId +
        '/' +
        topics,
    blocks: (courseId, unitId, lessonId, topicId) =>
        base +
        courses +
        '/' +
        courseId +
        '/' +
        units +
        '/' +
        unitId +
        '/' +
        lessons +
        '/' +
        lessonId +
        '/' +
        topics +
        '/' +
        topicId +
        '/' +
        blocks,

    exercises: (finder) => SearchURL(`${base}${exercises}?`, finder),
    searchPaginatedExercises: (finder, paginationConfig) => {
        return SearchURL(`${base}${exercises}?`, finder, paginationConfig)
    },
    updateExercise: (id) => `${base}${exercises}/${id}`,
    checkUsedName: (name) => `${base}${exercises}/check-name?name=${name}`,
    cloneExercise: (id) => `${base}${exercises}/${id}/clone`,
    singleExercise: (id) => `${base}${exercises}/${id}`,
    exerciseTags: `${base}${exercises}/tags`,
    exerciseTypes: `${base}${exercises}/types`,
    ruleTypes: `${base}${exercises}/rule-types`,
    exerciseHasProgress: (exerciseId) => `${base}${exercises}/${exerciseId}/has-progress`,
    uploadUsers: base + users + '/addusers',
    enrolments: base + enrolments,
    uploadUsersWithCourse: (courseId) => base + courses + '/' + courseId + '/' + enrolments,
    institutes: base + institutes,
    countries: base + countries,
    states: base + states,
    cities: base + cities,
    levels: base + levels,
    filterStudents: (courseId, name, surname, email) => {
        let route = base + enrolments
        route += `?course_id=${courseId}`
        route += name ? `&search[]=!name[:]${name}` : ''
        route += surname ? `&search[]=!surname[:]${surname}` : ''
        route += email ? `&search[]=!email[:]${email}` : ''

        return route
    },
    userTypes: `${base}${userTypes}`,
    roles: base + roles,
    singleRole: (id) => `${base}${roles}/${id}`,
    updateRole: (id) => `${base}${roles}/${id}`,
    deleteRole: (id) => `${base}${roles}/${id}`,
    searchPaginatedRoles: (params) => {
        let urlString = `${base}${roles}?`
        let paramListForURL = []

        params.page && paramListForURL.push(`page=${params.page}`)
        params.limit && paramListForURL.push(`limit=${params.limit}`)
        params.order_by &&
            paramListForURL.push(`order=${params.order_by.item}:${params.order_by.value}`)
        params.description &&
            paramListForURL.push(
                `search[]=!description[:]${encodeURIComponent(params.description)}`
            )

        paramListForURL.map((param, index) => {
            urlString += param
            if (index < paramListForURL.length - 1) urlString += '&'
        })

        return urlString
    },
    permissions: (name) => `${base}${permissions}${name ? '/' + name : ''}`,
    reports: (courseId) => {
        let route = base + reports + '/courseprogress'
        route += courseId ? '?course_id=' + courseId : ''

        return route
    },
    blockReport: (courseId, blockId) => {
        let route = base + reports + '/blockProgress'
        route += courseId ? '?course_id=' + courseId : ''
        route += blockId ? '&block_id=' + blockId : ''

        return route
    },
    preworkReport: (courseId, userId) => {
        let route = base + reports + '/prework'
        route += courseId ? '?course_id=' + courseId : ''
        route += userId ? '&userId=' + userId : ''

        return route
    },
    preworkReportExercise: (userId, courseId, blockId) => {
        let route = base + reports + '/prework/exercise'
        route += userId ? '?userId=' + userId : ''
        route += blockId ? '&blockId=' + blockId : ''
        route += courseId ? '&course_id=' + courseId : ''

        return route
    },
    downloadQuizReport: (courseId, blockId) => {
        let route = base + reports + '/quizReport'
        route += courseId ? '?course_id=' + courseId : ''
        route += blockId ? '&blockId=' + blockId : ''

        return route
    },
    reloadProgress: (courseId) => {
        let route = base + userprogress + '/refresh'
        route += courseId ? '?course_id=' + courseId : ''

        return route
    },
    coursesToModifyByBlock: (blockId) => base + courses + '/' + blockId + '/' + blocks,
    coursesToModifyByTopic: (topicId) => base + courses + '/' + topicId + '/' + topics,
    coursesToModifyByLesson: (lessonId) => base + courses + '/' + lessonId + '/' + lessons,
    quizzes: (block_id, quiz_id, start) => {
        let route = base + runners + '/block/' + block_id + '/quiz/' + quiz_id
        route += start ? '?start=' + start : ''
        return route
    },
    quizCreate: base + quizzes + '/quizzes',
    quizUpdate: (quizId) => base + quizzes + '/' + quizId,
    checkQuiz: (quizId) => {
        let route = base + quizzes + '/checkQuiz'
        route += quizId ? '?quiz_id=' + quizId : ''
        return route
    },
    getTabs: (courseId) => {
        return base + tabs + '/course/' + courseId
    },
    getTab: (id) => base + tabs + '/' + id,
    editTabs: (id) => base + tabs + '/' + id,
    emails: (courseId) => base + courses + '/' + courseId + '/emails',
}

const URI = (base, filters, options) => {
    let route = base
    let i = 1
    if (filters) {
        Object.keys(filters).forEach((filter) => {
            route += (i > 1 ? '&' : '') + filter + '=' + encodeURI(filters[filter])
            i++
        })
    }
    if (options) {
        Object.keys(options).forEach(
            (option) =>
                (route +=
                    '&' + option + '[' + options[option].item + ']' + '=' + options[option].value)
        )
    }
    return route
}
