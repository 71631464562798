import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import NativeLogin from './NativeLogin'
import useStategies from './useStrategies'
import useQuery from '@/hooks/useQuery'
import { loginUser } from '@/redux/reducers/_deprecated/authReducer'
import PRELOADER from '../../../../public/imgs/preloader.gif'

import './LoginContainer.scss'

const LoginContainer = () => {
    const { isLoaded, oAuthStrategies, logoUrl, showTermsAndConditions } = useStategies()

    const query = useQuery()
    const dispatch = useDispatch()

    const lang = useSelector((state) => ({
        ...state.langStore.login,
        ...state.langStore.resetPasswordSender,
        ...state.langStore.idle,
    }))
    const error = useSelector((state) => state.authReducer.error.message)
    const sessionExpired = useSelector(({ idleTime }) => idleTime.isExpired)

    const loginUserHandler = (email, password) =>
        dispatch(
            loginUser(email, password, {
                redirect: query.get('redirect'),
                redirectGuard: query.get('redirectGuard'),
            })
        )

    if (!isLoaded)
        return (
            <div className='spinner_login'>
                <img src={PRELOADER} />
            </div>
        )

    return (
        <NativeLogin
            lang={lang}
            error={error || query.get('errorMessage') || (sessionExpired && lang.expiredSession)}
            logoUrl={logoUrl}
            onClickSubmit={loginUserHandler}
            oAuthStrategies={oAuthStrategies}
            showTermsAndConditions={showTermsAndConditions}
        />
    )
}

export default LoginContainer
