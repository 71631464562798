import React from 'react'
import PropTypes from 'prop-types'
import RequestRedo from './ModalChildrens/RequestRedo'
import Evaluate from './ModalChildrens/Evaluate'
import { REQUEST_REDO, EVALUATE, APPROVED, FAILED } from '../../shared/constants/dashboard'
import Modal from '@digitalhouse-tech/react-lib-ui-explorer/lib/Modal'

const ReviewModal = ({
    lang,
    modalKey,
    onCancelModal,
    onReasonsChange,
    onReviewChange,
    onSendReview,
    reviewHasError,
    rejectTags,
    reviewType,
    showModal,
    studentFullName,
    teacherReview,
    validateRequestRedo,
    reasonsHasError,
}) => {
    const formatMsg = (string) => {
        return string
            .split(' ')
            .map((word, i) =>
                word.includes('/') ? <strong key={i}>{word} </strong> : <span key={i}>{word} </span>
            )
    }

    const evaluateMsg = {
        [APPROVED]: formatMsg(lang.aproveStudentActivity),
        [FAILED]: formatMsg(lang.faileStudentActivity),
    }

    const modalChildren = {
        [REQUEST_REDO]: (
            <RequestRedo
                reasonsHasError={reasonsHasError}
                rejectTags={rejectTags}
                reviewCounterText={lang.chars}
                review={teacherReview.feedback}
                reviewHasError={reviewHasError}
                requiredInputMsg={lang.requiredInput}
                reviewTitle={lang.explainedRedoRequest}
                noResultsText={lang.create}
                onReviewChange={onReviewChange}
                onReasonsChange={onReasonsChange}
                reasonsCounterText={lang.reasons}
                selectedReasons={teacherReview.rejectReasons}
                title={lang.requestRedoQuestion}
                textCharsCount={teacherReview.feedback.length}
            />
        ),
        [EVALUATE]: (
            <Evaluate
                studentFullName={studentFullName}
                disclaimer={lang.disclaimerModalMessage}
                headerMessage={evaluateMsg[reviewType]}
            />
        ),
    }

    const modalProps = {
        [REQUEST_REDO]: {
            id: 'requestRedoActivityModal',
            mainTextAction: lang.send,
            modalTitle: lang.atention,
            secondaryTextAction: lang.cancel,
            onMainAction: () => {
                const { feedback, rejectReasons } = teacherReview
                const hasError = validateRequestRedo(feedback, rejectReasons)
                if (hasError) return
                onSendReview({ reviewType })
            },
        },
        [EVALUATE]: {
            id: 'requestRedoActivityModal',
            mainTextAction: lang.sendEvaluationText,
            modalTitle: lang.sendEvaluation,
            secondaryTextAction: lang.cancel,
            onMainAction: () => onSendReview({ reviewType }),
        },
    }

    return (
        <Modal
            showModal={showModal}
            id={modalProps[modalKey]?.id || ''}
            mainTextAction={modalProps[modalKey]?.mainTextAction}
            modalTitle={modalProps[modalKey]?.modalTitle}
            onMainAction={modalProps[modalKey]?.onMainAction}
            onSecondaryAction={onCancelModal}
            onCloseAction={onCancelModal}
            secondaryTextAction={modalProps[modalKey]?.secondaryTextAction}
        >
            {modalChildren[modalKey]}
        </Modal>
    )
}

ReviewModal.propTypes = {
    lang: PropTypes.object,
    modalKey: PropTypes.string,
    onCancelModal: PropTypes.func,
    onReasonsChange: PropTypes.func,
    onReviewChange: PropTypes.func,
    onSendReview: PropTypes.func,
    reviewHasError: PropTypes.bool,
    rejectTags: PropTypes.arrayOf(PropTypes.string),
    reviewType: PropTypes.string,
    showModal: PropTypes.bool,
    studentFullName: PropTypes.string,
    teacherReview: PropTypes.shape({
        rejectReasons: PropTypes.arrayOf(PropTypes.string),
        feedback: PropTypes.string,
    }),
    setReviewHasError: PropTypes.func,
}

ReviewModal.defaultProps = {
    lang: {},
    modalKey: '',
    onCancelModal: () => {},
    onReasonsChange: () => {},
    onReviewChange: () => {},
    onSendReview: () => {},
    reviewHasError: false,
    rejectTags: [],
    reviewType: '',
    showModal: false,
    studentFullName: '',
    teacherReview: {
        feedback: '',
        rejectReasons: [],
    },
    setReviewHasError: () => {},
}

export default ReviewModal
