import { useEffect } from 'react'
import useMicrofrontend from '@/hooks/useMicrofrontend'
import { mfBoQuizUrl } from '@/config/microfrontends'
import useRouteHandler from './useRouteHandler'

const useQuizMf = (mfId, selectorId, mfName, props) => {
    const { isLoaded, mfBoQuiz } = useMicrofrontend(mfId, mfBoQuizUrl)
    const {
        onCreateQuiz,
        onEditQuiz,
        onCopyQuiz,
        onGoToSearcher,
        resetNewQuizCreatedState,
        onSaveRedirection,
    } = useRouteHandler()

    useEffect(() => {
        if (!mfBoQuiz) return
        const { render, unMount } = mfBoQuiz

        render(mfName, selectorId, {
            ...props,
            onCreateQuiz,
            onEditQuiz,
            onCopyQuiz,
            onGoToSearcher,
            resetNewQuizCreatedState,
            onSaveRedirection,
        })

        return () => unMount(selectorId)
    }, [isLoaded]) // eslint-disable-line

    return { isLoaded }
}

export default useQuizMf
