import React from 'react'
import PropTypes from 'prop-types'
import { atomAsset } from '@/assets/img/config'
import './QuizHeader.scss'

const QuizTitle = ({ title }) => {
    return (
        <div className='quiz-header-container__title-container'>
            <img
                className='quiz-header-container__title-container__icon'
                src={atomAsset('sheet-holder.svg')}
                alt='quiz cover'
            />
            <div className='quiz-header-container__title-container__title'>{title}</div>
        </div>
    )
}

QuizTitle.propTypes = {
    title: PropTypes.string.isRequired,
}

export default QuizTitle
