import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { actions as blockActions } from '@/redux/reducers/blockReducer'

const DownloadReportAlert = () => {
    const dispatch = useDispatch()
    const lang = useSelector(({ langStore }) => langStore.deleteBlockSuite.infoAlert)
    const reportUrl = useSelector(({ block }) => block.report)
    const onClickDownloadReport = () => window.open(reportUrl, 'blank')

    useEffect(() => {
        return () => dispatch(blockActions.clearBlockReport())
    }, [])

    if (!reportUrl) return null

    return (
        <div className="toastA alert alert-success">
            <a className="close" onClick={() => dispatch(blockActions.clearBlockReport())}>
                &times;
            </a>
            {reportUrl && (
                <h3>
                    <a onClick={onClickDownloadReport}>{lang.clickHere}</a> {lang.clickHereRightText}
                </h3>
            )}
        </div>
    )
}

export default DownloadReportAlert
