import React from 'react'
import PropTypes from 'prop-types'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import './ReportDownload.scss'

const ReportDownload = ({ lang, onClick, disabled }) => (
    <div className='report-quiz-download'>
        <Button
            disabled={disabled}
            id='DownloadQuizReportButton'
            onClick={onClick}
            theme='primary'
            text={lang.downloadAnswerByStudent}
        />
    </div>
)

ReportDownload.propTypes = {
    lang: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
}

ReportDownload.defaultProps = {
    disabled: false
}

export default ReportDownload
