import React from 'react'
import PropTypes from 'prop-types'
import FileItem from './FileItem'

const FileList = ({
    files,
    onDownload,
    onDelete,
    onEdit,
    showDeleteButton,
    showEditButton,
    showDownloadButton
 }) => (
    <div className='FileListGroup'>
        {files.map(file =>
            <FileItem
                key={`FileItemNumber${file.id}`}
                id={file.id}
                name={file.name}
                date={file.date}
                path={file.path}
                isEditable={file.isEditable}
                onDownload={onDownload}
                onDelete={onDelete}
                onEdit={onEdit}
                showDeleteButton={showDeleteButton}
                showEditButton={showEditButton}
                showDownloadButton={showDownloadButton}
            />
        )}
    </div>
)

FileList.propTypes = {
    files: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        date: PropTypes.string,
        isEditable: PropTypes.bool
    })).isRequired,
    onDownload: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
}

export default FileList
