import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
    CHOICE_QUESTION,
    TEXT_CONTROL_QUESTION,
    TEXT_FREE_QUESTIONS,
} from '../../../constants/quiz'

import ChoiceQuestion from './ChoiceQuestion'
import TextQuestion from './TextQuestion'

import QuestionHeader from './QuestionHeader'
import GeneralFeedback from '../Feedback/GeneralFeedback'
import './Question.scss'
import { QUESTION_TYPES } from '../constants'
/**
 * Questions type map.
 */
const QUESTION_COMPONENT = {
    [CHOICE_QUESTION]: (props) => <ChoiceQuestion {...props} />,
    [TEXT_CONTROL_QUESTION]: (props) => <TextQuestion {...props} />,
    [TEXT_FREE_QUESTIONS]: (props) => <TextQuestion {...props} />,
}

const Question = ({
    question,
    onUpdateAnswerText,
    onUpdateAnswerSingleChoice,
    onUpdateAnswerMultipleChoice,
    hasQuizComplete,
    studentAnswer,
    isSurvey,
    affectsProgress,
    lang
}) => {

    const showGeneralFeedback = !!question.rightFeedback
    const type = useMemo(
        () => QUESTION_TYPES.find((type) => Object.keys(question).find((key) => key === type)),
        [question]
    )
    const Component = QUESTION_COMPONENT[type]

    return (
        <>
            <div className='question-container'>
                <QuestionHeader 
                    order={question.order + 1} 
                    statement={question.statement} 
                    lang={lang} 
                    isSurvey={isSurvey} 
                    affectsProgress={affectsProgress} 
                    hasQuizComplete={hasQuizComplete} 
                />

                <div className='question-container__question'>
                    <Component
                        question={question}
                        hasQuizComplete={hasQuizComplete}
                        onUpdateAnswerText={onUpdateAnswerText}
                        onUpdateAnswerSingleChoice={onUpdateAnswerSingleChoice}
                        onUpdateAnswerMultipleChoice={onUpdateAnswerMultipleChoice}
                        studentAnswer={studentAnswer}
                        showOptionFeedback={!showGeneralFeedback && !isSurvey}
                        lang={lang}
                    />
                </div>
            </div>
            {hasQuizComplete && showGeneralFeedback && (
                <GeneralFeedback
                    showFeedback={!isSurvey}
                    rightFeedback={question.rightFeedback}
                    wrongFeedback={question.wrongFeedback}
                    isRightGeneralFeedback={studentAnswer?.isCorrect}
                />
            )}
        </>
    )
}

Question.propTypes = {
    question: PropTypes.object.isRequired,
    onUpdateAnswerText: PropTypes.func,
    onUpdateAnswerSingleChoice: PropTypes.func,
    onUpdateAnswerMultipleChoice: PropTypes.func,
    hasQuizComplete: PropTypes.bool,
    studentAnswer: PropTypes.object,
    isSurvey: PropTypes.bool,
    affectsProgress: PropTypes.bool,
    lang: PropTypes.object
}

export default Question
