import React from 'react'
import { Check } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'

export class RadioBtnCard extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      value: props.item
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.value === null) {
      return {
        value: props.item
      }
    }
    return {}
  }

  render() {
    
    return (
      <React.Fragment>
        <li onClick={this.props.toggle} className={this.state.value === this.props.selectedValue ? "bg-color--primary active" : ''}>

          <div className="_cardCol_1">
            <div className={this.state.value === this.props.selectedValue ? "_cardRadioBtn_number bg-color--white font-color--primary __font_weight_bold active" : '_cardRadioBtn_number'}>{this.props.item}</div>
            <div className={this.state.value === this.props.selectedValue ? "_cardRadioBtn_option font-color--white __font_weight_bold" : '_cardRadioBtn_option'}>{this.props.opt}</div>
          </div>


          {this.state.value === this.props.selectedValue ? <div className="_cardRadioBtn_check"><Check className="_arrow_right fa-fw font-color--white" /></div> : ''}
        </li>
      </React.Fragment>
    )
  }
}

export default RadioBtnCard
