import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { history } from '@/app/store'

import { loadAllInstitutes } from '@/redux/reducers/_deprecated/instituteReducer'
import { VIEW_INSTITUTE, EDIT_INSTITUTE } from '@digitalhouse-tech/ts-lib-store/lib/session-storage/permission'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'
import { Edit2 } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
export class Institute extends React.Component {

    constructor() {
        super()
        this.state = {
            redirect: []
        }
        this.goToInstitute = this.goToInstitute.bind(this)
    }

    handleEdit() {
        let instituteId = this.props.id
        history.push('/admin/institutes/' + instituteId + '/edit')
    }

    goToInstitute() {
        let courseLink = "/admin/institutes/" + this.props.id + "/view"
        history.push(courseLink)
    }

    render() {
        return (
            <Panel className="__card_flex_container">
                <div>
                    <h3 className="__card_secondary_title">{this.props.name}</h3>
                </div>

                <ButtonGroup>
                    {VIEW_INSTITUTE() &&
                        <Button
                            id="SeeMoreButton"
                            theme='tertiary'
                            onClick={this.goToInstitute}
                            text={this.props.lang.seeMoreButton}
                        />
                    }
                    {EDIT_INSTITUTE() &&
                        <ButtonRound
                            id="EditButton"
                            theme='primary_dark'
                            onClick={this.handleEdit.bind(this)}
                            icon={<Edit2 />}
                        />
                    }
                </ButtonGroup>
            </Panel>
        )
    }
}

const mapDispatch = (dispatch) => ({
    loadAllInstitutes: () => {
        dispatch(loadAllInstitutes())
    },
})


const mapStateToProps = (state) => {
    return {
        allInstitutes: state.instituteReducer.allInstitutes,
        lang: state.langStore.institution
    }
}

export default withRouter(connect(mapStateToProps, mapDispatch)(Institute))