import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import './UnitCarousel.scss'
import { slider } from './unitCarouselSetup'
import CustomArrow from '@/components/CustomArrow'
import ExtremeCarouselItem from './ExtremeCarouselItem'
import CarouselItem from './CarouselItem'
import { ITEM_START, ITEM_FINISH } from './itemCarouselTypes'

const UnitCarousel = ({
    dates,
    unitNames,
}) => {

    const setup = {
        ...slider,
        prevArrow: <CustomArrow right={false} />,
        nextArrow: <CustomArrow right />
    }

    const getCarouselItems = () => {
        let items = []
        unitNames.forEach( (name,index) => {
            items.push(
                <CarouselItem 
                    name={name}
                    title={`${index+1}`}
                    separator={false}
                />
            )
            items.push(<CarouselItem separator />)
        })
        items.pop()
        return items
    }

    return (
        <Slider {...setup} className="unitSlider">
            <ExtremeCarouselItem 
                date={dates.start}
                type={ITEM_START}
            />
            {getCarouselItems()}
            <ExtremeCarouselItem 
                date={dates.end}
                type={ITEM_FINISH}
            />
            
        </Slider>
        
    )
}

UnitCarousel.defaultProps = {
    dates: {start:null, end:null},
}

UnitCarousel.propTypes = {
    dates: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
    }),
    unitNames: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default UnitCarousel
