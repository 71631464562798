'use-strict'

import constants from './constants'
import initialState from './state'
import { htmlentities } from '@/helpers/utils'

const finder = (finderState, payload) => {
    const finderCopy = {...finderState}
    finderCopy[payload.key] = payload.value
    return finderCopy
}

const loading = (loadingState, payload) => {
    const loadingCopy = {...loadingState}
    loadingCopy[payload.loader] = payload.bool
    return loadingCopy
}

const solvedValidations = (solvedValidationsState, payload) => {
    return { 
        ...solvedValidationsState, 
        ...payload, 
        joined: htmlentities.decode(payload.joined)
    }
}

const paginationConfig = (paginationConfigState, page) => {
    return {
        ...paginationConfigState,
        page
    }
}

export default {
    [constants.SET_ALL_EXERCISES]: (state, payload) => ({
        ...state,
        all: payload       
    }),
    [constants.SET_CURRENT_PAGE]: (state, payload) => ({
        ...state,
        paginationConfig: paginationConfig(state.paginationConfig, payload)
    }),
    [constants.SET_PAGINATED_EXERCISES]: (state, payload) => ({
        ...state,
        paginated: payload
    }),
    [constants.SET_FINDER_FIELD]: (state, payload) => ({
        ...state,
        finder: finder(state.finder, payload)
    }),
    [constants.SET_ALL_TAGS]: (state, payload) => ({
        ...state,
        allTags: payload
    }),
    [constants.SET_ALL_EXERCISE_TYPES]: (state, payload) => ({
        ...state,
        allExerciseTypes: payload
    }),
    [constants.SET_ALL_RULE_TYPES]: (state, payload) => ({
        ...state,
        allRuleTypes: payload
    }),
    [constants.SET_WORKING_EXERCISE]: (state, payload) => ({
        ...state,
        workingExercise: payload
    }),
    [constants.SET_REQUEST_LOADING]: (state, payload) => ({
        ...state,
        loading: loading(state.loading, payload)
    }),
    [constants.SET_SOLVED_VALIDATIONS]: (state, payload) => ({
        ...state,
        solvedValidations: solvedValidations(state.solvedValidations, payload)
    }),
    [constants.SET_IF_EXERCISE_HAS_PROGRESS]: (state, payload) => ({
        ...state,
        hasProgress: payload
    }),
    [constants.CLEAR_FINDER_FIELDS]: (state) => ({
        ...state,
        finder: initialState.finder
    }),
    [constants.CLEAR_WORKING_EXERCISE]: (state) => ({
        ...state,
        workingExercise: initialState.workingExercise
    }),
    [constants.CLEAR_SOLVED_VALIDATIONS]: (state) => ({
        ...state,
        solvedValidations: initialState.solvedValidations
    }),
    [constants.CLEAR_IF_EXERCISE_HAS_PROGRESS]: (state) => ({
        ...state,
        hasProgress: initialState.hasProgress
    })
}