import axios from 'axios'
import { NOTION_API_KEY, NOTION_API_URL } from '@/config/env'

const getNotionEndpoint = (pathName) => {
  const splitPathName = pathName.split('/')
  const notionId = splitPathName.length ? splitPathName[splitPathName.length - 1] : pathName
  return `${NOTION_API_URL}/blocks/${notionId}`
}

export const getNotionPage = (pathName) => {
  const endpoint = getNotionEndpoint(pathName);

  return  axios.get(endpoint, {
    headers: { 'x-api-key': NOTION_API_KEY }
  })
}

export const refreshNotionPage = (pathName) => {
  const endpoint = getNotionEndpoint(pathName);

  return  axios.patch(endpoint, {}, {
    headers: { 'x-api-key': NOTION_API_KEY }
  })
}