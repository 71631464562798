import * as recoverPasswordService from '../http/services/recoverPasswordService'
import { setLoading } from '../reducers/loadingReducer'

export const recoverPasswordByEmail = (email) => async (dispatch, _getState) => {
    dispatch(setLoading.true('recoverPassword.sendEmail'))
    const [error, response] = await recoverPasswordService.recoverPassword(email)
    dispatch(setLoading.false('recoverPassword.sendEmail'))
    return [error, response]
}

export const sendToken = (token) => async () => {
    const [error, response] = await recoverPasswordService.sendToken(token)
    return [error, response]
}

export const sendNewPasswordAndToken = (password, token) => async () => {
    const [error, response] = await recoverPasswordService.sendNewPasswordAndToken(password, token)
    return [error, response]
}
