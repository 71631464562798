import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import { Trash2, Edit2, Eye, EyeOff } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { enableCourseInfo } from '@/helpers/granters/courseTypeRules'

const OwnerListActions = ({
    lang,
    onClickEditOwner,
    openModalDelete,
    showDeleteButton,
    showEditPermissionsButton,
    showVisibilityButton,
    isVisible,
    onClickToggleVisibility,
}) => {

    const courseType = useSelector(({ course }) => course.course.CourseType)
    const enableToggleVisibility = enableCourseInfo(courseType)

    const visibility = useMemo(() => ({
        icon: isVisible ? <Eye /> : <EyeOff />,
        tooltipText: isVisible ? lang.visibleButtonTooltip : lang.notVisibleButtonTooltip,
    }), [isVisible, lang])

    return (
        <>
            <ButtonGroup>
                {showDeleteButton && (
                    <ButtonRound
                        icon={<Trash2 />}
                        onClick={() => openModalDelete()}
                        id="deleteOwner"
                        theme='danger'
                    />
                )}
                {showEditPermissionsButton && (
                    <ButtonRound
                        theme='primary_dark'
                        icon={<Edit2 />}
                        onClick={() => onClickEditOwner()}
                        id="ownerPermissions"
                        tooltipId="ownerPermissions"
                        tooltipContent={lang.editButtonTooltip}
                    />
                )}
                {showVisibilityButton && enableToggleVisibility && (
                    <ButtonRound
                        theme='info'
                        icon={visibility.icon}
                        onClick={() => onClickToggleVisibility()}
                        id="ownerVisibility"
                        tooltipId="ownerVisibility"
                        tooltipContent={visibility.tooltipText}
                    />
                )}
            </ButtonGroup>
        </>
    )
}

OwnerListActions.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    lang: PropTypes.shape({
        editButtonTooltip: PropTypes.string,
        visibleButtonTooltip: PropTypes.string,
        notVisibleButtonTooltip: PropTypes.string,
    }).isRequired,
    onClickEditOwner: PropTypes.func.isRequired,
    openModalDelete: PropTypes.func.isRequired,
    onClickToggleVisibility: PropTypes.func.isRequired,
    showDeleteButton: PropTypes.bool.isRequired,
    showEditPermissionsButton: PropTypes.bool.isRequired,
    showVisibilityButton: PropTypes.bool.isRequired,
}

export default OwnerListActions
