'use-strict'

import axios from 'axios'
import api from '@/config/endpoints'
import { getConfig } from '@/helpers/utils'

export default ({
    loadAllExercises: async (attributes) => {
        const endpoint = api.exercises(attributes)
        const { data: { data } } = await axios.get(endpoint, getConfig())
        return data
    },
    loadAllTags: async () => {
        const endpoint = api.exerciseTags
        const { data: { data } } = await axios.get(endpoint, getConfig())
        return data
    },
    loadAllExerciseTypes: async () => {
        const endpoint = api.exerciseTypes
        const { data: { data } } = await axios.get(endpoint, getConfig())
        return data
    },
    loadPaginatedExercises: async (finder, paginationConfig) => {
        const endpoint = api.searchPaginatedExercises(finder, paginationConfig)
        const { data: { data } } = await axios.get(endpoint, getConfig())
        return data
    },
    loadSingleExercise: async id => {
        const endpoint = api.singleExercise(id)
        const { data: { data } } = await axios.get(endpoint, getConfig())
        return data
    },
    executePreview: async (payload, options) => {
        const endpoint = api.executePreview(options)
        const { data: { data } } = await axios.post(endpoint, payload, getConfig())
        return data
    },
    createExercise: async payload => {
        const endpoint = api.exercises()
        const { data: { data } } = await axios.post(endpoint, payload, getConfig())
        return data
    },
    updateExercise: async (id, payload) => {
        const endpoint = api.updateExercise(id)
        const { data: { data } } = await axios.put(endpoint, payload, getConfig())
        return data
    },
    exerciseHasProgress: async id => {
        const endpoint = api.exerciseHasProgress(id)
        const { data: { data } } = await axios.get(endpoint, getConfig())
        return data
    },
    checkUsedName: async name => {
        const endpoint = api.checkUsedName(name)
        const { data: { data } } = await axios.get(endpoint, getConfig())
        return data
    },
    cloneExercise: async (id, payload) => {
        const endpoint = api.cloneExercise(id)
        const { data: { data } } = await axios.post(endpoint, payload, getConfig())
        return data
    }
})