import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { ChevronDown, Star } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'

import './ExpandablePanel.scss'

const ExpandablePanel = ({
    children,
    className,
    description,
    enableExpand,
    isExpanded,
    theme,
    title,
    onClickPanel,
    showPanel,
}) => {
    const containerClasses = cn(
        {
            __marked: theme === 'marked',
        },
        'expandable-card',
        className
    )

    const headerClasses = cn(
        {
            'expandable-card__header--expandable': enableExpand,
        },
        'expandable-card__header'
    )

    const contentClasses = cn(
        {
            '__enable-expand': enableExpand,
            '__enable-expand--expanded': isExpanded && enableExpand,
            __expanded: isExpanded && !enableExpand,
        },
        'expandable-card__content'
    )

    const iconClasses = cn(
        {
            __expanded: isExpanded,
        },
        'expandable-card__icon'
    )

    if (!showPanel) return null

    return (
        <div className={containerClasses}>
            <div className={headerClasses} onClick={onClickPanel}>
                {theme === 'marked' && (
                    <span className='expandable-card__header-icon'>
                        <Star />
                    </span>
                )}
                <p className='expandable-card__header-title'>{title}</p>
                {description && (
                    <p className='expandable-card__header-description'>{description}</p>
                )}
            </div>
            <span className={contentClasses}>{children}</span>
            {enableExpand && (
                <span className={iconClasses} onClick={onClickPanel}>
                    <ChevronDown />
                </span>
            )}
        </div>
    )
}

ExpandablePanel.propTypes = {
    children: PropTypes.any,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    enableExpand: PropTypes.bool,
    icon: PropTypes.number,
    theme: PropTypes.oneOf(['marked', 'idle', 'highlight']),
    title: PropTypes.string,
    onClickPanel: PropTypes.func,
    showPanel: PropTypes.bool,
}

ExpandablePanel.defaultProps = {
    children: null,
    description: '',
    enableExpand: false,
    icon: null,
    theme: 'idle',
    title: '',
    onClickPanel: () => {},
    showPanel: true,
}

export default ExpandablePanel
