import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { PlusCircle } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'

const CourseReportBlockData = ({ filteredCourseData, totalUsers, lang }) => {
    const tooltipContent = block => {
        return (
            <div className="_report_block_tooltip">
                {block.extra && <p>{lang.extraBlock}</p>}
                <p>{lang.blockTypes[block.blockType]}:</p>
                <p>{block.name}</p>
            </div>
        )
    }

    const blockName = block => {
        return (
            <>
                {block.extra && <PlusCircle />}
                <div>{block.name}</div>
            </>
        )
    }

    return (
        <>
            <div className="_report_blocks_container">
                {filteredCourseData.map(topic => {
                    const blockWidth = topic.Blocks.length === 1 ? { width: '100px' } : {}
                    return topic.Blocks.map(block => {
                        return (
                            <div
                                className={cn(
                                    {
                                        _report_block_extra: block.extra,
                                    },
                                    '_report_block',
                                )}
                                key={`block_${block.id}`}
                                style={blockWidth}
                            >
                                <Button
                                    id={`block_id_${block.id}`}
                                    key={`block_key_${block.id}`}
                                    className="_report_general_button _report_block_name"
                                    theme="clear"
                                    tooltipId={`name_${block.id}`}
                                    tooltipContent={tooltipContent(block)}
                                    onClick={() => {}}
                                    text={blockName(block)}
                                />
                                <div className="_report_block_completed">{`${block.completedBlockPercent}%`}</div>
                            </div>
                        )
                    })
                })}
            </div>
        </>
    )
}

CourseReportBlockData.propTypes = {
    filteredCourseData: PropTypes.array.isRequired,
    totalUsers: PropTypes.number.isRequired,
    lang: PropTypes.object.isRequired,
}

export default CourseReportBlockData
