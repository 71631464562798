'use-strict'

export const initialState = {
    all: [],
}

export const SET_BLOCK_ISSUE_OPTIONS = 'SET_BLOCK_ISSUE_OPTIONS'

export const setBlockIssueOptions = (blockIssueOptions) => ({
    type: SET_BLOCK_ISSUE_OPTIONS,
    payload: blockIssueOptions,
})

const blockIssueOptionReducer = (state = initialState, { type, payload }) => {
    const reducers = {
        [SET_BLOCK_ISSUE_OPTIONS]: () => ({
            ...state,
            all: payload,
        }),
    }
    return reducers[type] ? reducers[type]() : state
}

export default blockIssueOptionReducer
