import React from 'react'
import PropTypes from 'prop-types'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'

const OptionsList = ({ options }) => {
    return (
        <div className='course-card__options__dropdown'>
            {options.map((option) => (
                <Button
                    id={option.id}
                    data-testid={option.testId}
                    className='course-card__options__button'
                    onClick={option.onClick}
                    text={option.text}
                    visible={option.isVisible}
                    disabled={option.isDisabled}
                    icon={option.icon}
                    leftIcon
                    theme='clear'
                    key={option.id}
                    aria-label={option.ariaLabel}
                />
            ))}
        </div>
    )
}

OptionsList.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            testId: PropTypes.string.isRequired,
            onClick: PropTypes.func,
            text: PropTypes.string,
            isVisible: PropTypes.bool,
            isDisabled: PropTypes.bool,
            icon: PropTypes.any,
        })
    ),
}

OptionsList.defaultProp = {
    options: {
        id: '',
        onClick: () => {},
        text: '',
        isVisible: true,
        isDisabled: false,
        icon: null,
    },
}

export default OptionsList
