
export const type = {
    json: scheme => json => {
        try{
            const jsonParsed = JSON.parse(json)
            scheme(jsonParsed)
        }catch(e) {
            throw e 
        }
    },
    nulleableJson: (scheme) => (json, key) => {
        if(json !== null){
            try{
                const jsonParsed = JSON.parse(json)
                scheme(jsonParsed, key)
            }catch(e) {
                throw e
            }
        }
    },
    undefinableJson:  (scheme) => (json, key)  => {
        if(json !== undefined){
            try{
                const jsonParsed = JSON.parse(json)
                scheme(jsonParsed, key)
            }catch(e) {
                throw e 
            }
        }
    },

    any: () => {},

    object: scheme => object => {
        let errorList = []
        Object.keys(scheme).forEach(key => {
            try{
                scheme[key](object[key])
            }catch (e) {
                e.map(error=>{
                    errorList.push(`.${key}${error}`)
                })
            }
        })
        if(errorList.length) throw errorList
    },
    nulleableObject: scheme => object => {
        let errorList = []
        if(object !== null) Object.keys(scheme).forEach(key => {
            try{
                scheme[key](object[key])
            }catch (e) {
                e.map(error=>{
                    errorList.push(`.${key}${e}`)
                })
            }
            if(errorList.length) throw errorList
        })
    },
    undefinableObject: scheme => object => {
        let errorList = []
        if(object !== undefined) Object.keys(scheme).forEach(key => {
            try{
                scheme[key](object[key])
            }catch (e) {
                e.map(error=>{
                    errorList.push(`.${key}${e}`)
                })
            }
        })
        if(errorList.length) throw errorList
    },
    anyObject: object => {
        if(typeof object === 'object') return
        throw [` This prop is not a object`]
    },

    array: scheme => (array) => {
        let position = 0
        const errorList = []
            array.forEach((item, index) => {
                try {
                    position = index
                    scheme(item)
                } catch (e) {
                    errorList.push(`[${position}]${e}`)
                }
            })
        if(errorList.length) throw errorList
    },

    nulleableArray: scheme => array => {
        if(array !== null) {
            let position = 0
            const errorList = []
                array.forEach((item, index) => {
                    try {
                        position = index
                        scheme(item)
                    } catch (e) {
                        errorList.push(`[${position}]${e}`)
                    }
                })
            if(errorList.length) throw errorList
        }
    },

    undefinableArray: scheme => array => {
        if(array !== undefined){
            let position = 0
            const errorList = []
            array.forEach((item, index) => {
                try {
                    position = index
                    scheme(item)
                } catch (e) {
                    errorList.push(`[${position}]${e}`)
                }
            })
            if(errorList.length) throw errorList 
        }
    },

    oneValue: arrayValues => value =>{
        if(!arrayValues.find(itemValue => itemValue === value )) throw [` has value ${value} but this must be one of these values ${arrayValues}`]
    },

    oneType: arrayScheme => (value, key) => {
        let numberErrors = 0
        for(let i=0; i < arrayScheme.length ; i++){
            try{
                arrayScheme[i](value)
            }catch (e) {
                numberErrors++
                continue
            }
        }
        if(numberErrors === arrayScheme.length) {
            throw [` have not a correct type`]
        }
    },

    undefinableOneType: arrayScheme => value => {
        if(value === undefined) return;
        if(value === null) throw [' has value null but this must one type']
    
        let numberErrors = 0
        for(let i=0; i < arrayScheme.length ; i++){
            try{
                arrayScheme[i](value)
            }catch (e) {
                numberErrors++
                continue
            }
        }
        if(numberErrors === arrayScheme.length) {
            throw [` have not a correct type`]
        }
    }, 


    null: value => {
        if (value !== null) throw [` has value ${value} but this must be a null`]
    },


    nulleableStringstring: value  => {
        if (typeof value !== 'string') throw [` has value ${value} but this must be a string`]
    },

    nulleableString: value => {
        if(value !== null){
            if(typeof value !== 'string'){
                throw [` has value ${value} but this must be a string or null`]
            }
        }
    },
    undefinableString: value => {
        if(value !== undefined){
            if(typeof value !== 'string'){
                throw [` has value ${value} but this must be a string or undefined`]
            }
        }
    }, 
    number: value => {
        if (typeof value !== 'number') throw [` has value ${value} but this must be a number`]
    },
    nulleableNumber: value => {
        if(value !== null){
            if(typeof value !== 'number'){
                throw [` has value ${value} but this must be a number or null`]
            }
        }
    },
    undefinableNumber: value => {
        if(value !== undefined){
            if(typeof value !== 'number'){
                throw [` has value ${value} but this must be a number or undefined`]
            }
        }
    },

    boolean: value => {
        if (typeof value !== 'boolean') throw [` has value ${value} but this must be a boolean`]
    },

    nulleableBoolean: value => {
        if(value !== null){
            if(typeof value !== 'boolean'){
                throw [` has value ${value} but this must be a boolean or null`]
            }
        }
    }, 
    undefinableBoolean: value => {
        if(value !== undefined){
            if(typeof value !== 'boolean'){
                throw [` has value ${value} but this must be a boolean or undefined`]
            }
        }
    }, 

    instance: aClass => value => {
        if(value !== null){
            if (!(value instanceof aClass)) {
                throw [` is a ${value.constructor.name}'s instance but this must be a ${aClass.name}'s instance`]
            }
        }
    },

    nulleableInstance: aClass => value => {
        if (value !== null) {
            if (!(value instanceof aClass)) {
                throw [` is a ${value.constructor.name}'s instance but this must be a ${aClass.name}'s instance`]
            }
        }
    },

    undefinableInstance: aClass => value => {
        if(value !== undefined) {
            if (!(value instanceof aClass)) {
                throw [` is a ${value.constructor.name}'s instance but this must be a ${aClass.name}'s instance`]
            }
        }
    },

    date: value => {
        if(typeof value !== 'string') throw  [` has value ${value} but this must be a date string`]
        if (new Date(value) === 'Invalid Date') throw  [` has value ${value} but this must be a date string`]
    },

    nulleableDate: value => {
        if (value !== null) {
            if(typeof value !== 'string') throw  [` has value ${value} but this must be a date string or null`]
            if (new Date(value) === 'Invalid Date') {
                throw  [` has value ${value} but this must be a date string or null`]
            }
        }
    },
    
    undefinableDate: value => {
        if (value !== undefined) {
            if(typeof value !== 'string') throw  [` has value ${value} but this must be a date string or undefined`]
            if (new Date(value) === 'Invalid Date') {
                throw  [` has value ${value} but this must be a string date or undefinedaaa`]
            }
        }
    }
}

export const validator = (scheme, object) => {
    if(typeof scheme === 'object'){
        let errorList = []
        Object.keys(scheme).map( key => {
            try{
                scheme[key](object[key], key)
            }catch(e){
                const newErrorList = e.map(error => {
                    return `${key}${error}`
                })
                newErrorList.forEach(error=>errorList.push(error))
            }
        })
        if(errorList.length) throw errorList
    }else if (typeof scheme === 'function'){
        scheme(object)
    }
}