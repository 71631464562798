import * as enrolmentService from '../http/services/enrolmentService'
import { actions as managerActions } from '../reducers/managerReducer'
import { setLoading } from '../reducers/loadingReducer'
import httpHandler, { codes } from '@/helpers/error-handling/httpHandler'
import showNewMessage from '../reducers/_deprecated/globalAlertsReducer/alertsControllers'

export const removeStudentFromCourse = (userId, courseId) => async (dispatch, getState) => {
    const studentEnrolmentLang = getState().langStore.studentEnrolment
    dispatch(setLoading.true('enrolment.removeStudentFromCourse'))
    const [error] = await enrolmentService.deleteStudentCourse(userId, courseId)
    dispatch(setLoading.false('enrolment.removeStudentFromCourse'))

    if (error) {
        return httpHandler(error)({
            [codes.INTERNAL_SERVER_ERROR]: () => {
                showNewMessage({
                    type: 'danger',
                    message: studentEnrolmentLang.studentRemoveFromCourseError,
                    isTemporal: true,
                })
            },
        })
    }

    dispatch(managerActions.deletePaginationRow(userId))
}
