import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './CourseTeachers.scss'
import StudentUnitTeacherCard from './CourseTeachersCard'

const CourseTeachers = ({
    teachers,
    isMobile,
    lang,
}) => {
    const teachersGrid = cn({
        "mobileGrid": isMobile,
        "desktopGrid": !isMobile,
    })

    return (
        <>
            {!!teachers && !!teachers.length &&
            <div className="TeachersList">
                <h1 className="title">{lang.title}</h1>
                <div className={teachersGrid}>
                    { teachers.map( (teacher, index) => {
                        const indexKey = `${teacher.name}${index}`
                        return (
                            <StudentUnitTeacherCard
                                teacher={teacher}
                                key={indexKey}
                            /> )
                    })}
                </div>
            </div>}
        </>
    )
}

CourseTeachers.defaultProps = {
    teachers: null,
    lang: {title: 'Equipo de Digital House'},
}

CourseTeachers.propTypes = {
    teachers: PropTypes.array,
    isMobile: PropTypes.bool.isRequired,
    lang: PropTypes.any,
}

export default CourseTeachers
