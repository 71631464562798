import axios from 'axios'
import * as env from '@/config/env'
import httpClient from '../httpClient'
import { getTenantLang } from '@/helpers/tenants/helpers'
import fileDownload from 'js-file-download'

const dashboardApiUrl = env.DASHBOARD_API_URL
const courseApiUrl = env.COURSE_API_URL
const progressApiUrl = env.PROGRESS_API_URL
const assessmentApiUrl = env.ASSESSMENT_API_URL

const httpCourse = () => httpClient(`/courses`, courseApiUrl)
const httpDashboard = () => httpClient(`/courses/`, dashboardApiUrl, { useVersion: false, apiToken: env.DASHBOARD_API_TOKEN })
const httpUserProgress = () => httpClient(`userprogress/refresh`, courseApiUrl)
const httpStudents = () => httpClient(`/students/`, courseApiUrl)
const httpProgress = () => httpClient('/', progressApiUrl, { useVersion: false, apiToken: env.PROGRESS_API_TOKEN })
const httpAssessment = () => httpClient('/assessment/', assessmentApiUrl, { useVersion: false, apiToken: env.ASSESSMENT_API_TOKEN })

export const generalCourseInfoUrl = (courseId) => httpDashboard().get(`${courseId}/reports`)

export const generalCourseInfo = async (url) => {
    const response = await axios.get(url)
    return response.data
}

export const courseStructureByBlockType = (courseId, blockType) =>
    httpDashboard().get(`${courseId}/units/related-to/${blockType}`)

//este endpoint estando en course api
export const refreshGeneralCourseInfo = (course_id) =>
    httpUserProgress().get(null, {
        params: { course_id },
    })

export const studentsStatus = (courseId, blockId, blockType) =>
    httpDashboard().get(`${courseId}/blocks/${blockId}/students/${blockType}/status`)

export const studentProgress = (userId, courseId, blockId) =>
    httpProgress().get(`/students/${userId}/courses/${courseId}/blocks/${blockId}/evaluable`)

export const teacherReview = (userId, courseId, blockId, review) =>
    httpStudents().post(
        `${userId}/courses/${courseId}/blocks/${blockId}/block-progresses/progress-evaluable`,
        review
    )

export const rejectReasons = () => httpProgress().get('/evaluable/reject-reasons')

export const quizReportUrl = (courseId, blockId) =>
    httpDashboard().get(
        `${courseId}/blocks/${blockId}/students/quiz/answers`,
        {
            params: {
                lang: getTenantLang(),
            },
        }
    )

export const downloadProgressReport = async (courseId) => {
    const [error, response] = await httpCourse().get(
        `${courseId}/progress-report`,
        {
            params: {
                lang: getTenantLang(),
            },
            responseType: 'blob',
        }
    )
    fileDownload(response.data, `${courseId}.xlsx`)
    return [error]
}

export const getClassProgressReportUrl = (courseId, dateFrom, dateTo) =>
    httpDashboard().get(`${courseId}/lesson-level/report/csv`, {
        params: {
            dateFrom,
            dateTo,
        },
    })

export const getGradesList = (strategyId, courseId) =>
    httpAssessment().get(
        `strategies/${strategyId}/courses/${courseId}/gradebook`
    )

export const getUserProgresses = (courseId) => httpCourse().get(`${courseId}/user-progress`)

export const getTemplate = (courseId) =>
    httpAssessment().get(`courses/${courseId}/gradebook/csv?lang=${getTenantLang()}`)

export const updateGradebook = (courseId, file) =>
    httpAssessment().post(`courses/${courseId}/gradebook?lang=${getTenantLang()}`, file)

export const publishGradebook = (courseId) =>
    httpAssessment().patch(`courses/${courseId}/course-assessments/visibility?lang=${getTenantLang()}`)
