import React, { Component } from 'react'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import { isUndefined } from '@/helpers/utils'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import InputTextArea from '@digitalhouse-tech/react-lib-ui-explorer/lib/TextAreaInput'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'

class HintModal extends Component {
    constructor(props) {
        super(props)

        this.defaultState = {
            hintKey: null,
            isEdit: false,
            show: false,
            hintValidation: {
                error: null,
                message: this.props.lang.thisFieldIsRequired
            },
            hintDescription: ""
        }

        this.state = this.defaultState
        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.saveHint = this.saveHint.bind(this)
    }

    showModal(hint, hintKey) {
        if (!isUndefined(hint) && !isUndefined(hintKey)) {
            this.setState({
                ...this.state,
                isEdit: true,
                hintDescription: hint,
                hintKey,
                show: true
            })
        } else {
            this.setState({ ...this.defaultState, show: true })
        }
    }

    hideModal() {
        this.setState(this.defaultState)
    }

    saveHint() {
        if (this.state.hintDescription === "") {
            this.setState({
                ...this.state,
                hintValidation: {
                    ...this.state.hintValidation,
                    error: 'error'
                }
            })
            return
        }
        let allHints = _.cloneDeep(this.props.hints)
        if (this.state.isEdit) {
            allHints[this.state.hintKey] = this.state.hintDescription
        } else {
            allHints.push(this.state.hintDescription)
        }
        this.props.onSave(allHints)
        this.hideModal()
    }

    render() {
        return (
            <Modal className="__modal" show={this.state.show} onHide={this.hideModal} keyboard={false} backdrop="static">
                <Modal.Header closeButton className="__modal_header __border_bottom">
                    <h3 className="__modal_title">{this.props.lang.createANewHints}</h3>
                </Modal.Header>

                <Modal.Body className="__modal_body">
                    <Label text={this.props.lang.hints} />
                    <InputTextArea
                        name="hint"
                        key="hints"
                        id="HintModalTextArea"
                        onChange={(e) => {
                            this.setState({
                                ...this.state,
                                hintDescription: e.target.value
                            })
                        }}
                        placeholder={this.props.lang.createHints}
                        value={this.state.hintDescription}
                        ref="hintInputRef"
                        autoFocus
                        error={this.state.hintValidation.error}
                        errorMsg={this.state.hintValidation.message}
                    />
                </Modal.Body>

                <Modal.Footer className="__modal_footer __border_top">
                    <ButtonGroup>
                        <Button
                            id="CancelButton"
                            onClick={this.hideModal}
                            text={this.props.lang.cancel}
                            theme='tertiary'
                        />
                        <Button
                            id="SaveButton"
                            theme="primary"
                            onClick={this.saveHint}
                            text={this.props.lang.save}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default HintModal