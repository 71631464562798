import React from 'react'
import Certificate from './Certificate'
import useCertificate from './useCertificate'

const CertificateContainer = () => {
    const {
        courseName,
        redirectHandler,
        goToCourseHandler,
        downloadHandler,
        showKeepLearningButton,
        certificateLang,
        showCertificate,
        downloadIsLoading,
    } = useCertificate()

    return (
        showCertificate && (
            <Certificate
                lang={certificateLang}
                courseName={courseName}
                goToCourseHandler={goToCourseHandler}
                redirectHandler={redirectHandler}
                downloadHandler={downloadHandler}
                showKeepLearningButton={showKeepLearningButton}
                downloadIsLoading={downloadIsLoading}
            />
        )
    )
}

export default CertificateContainer
