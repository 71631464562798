import React from 'react'
import PropTypes from 'prop-types'

import useBlock from './useBlock'
import NoConnectionEmptyState from '@/components/NoConnectionEmptyState'

const Block = ({ block: { BlockProgress, ...block }, isCourseExpired, isMobile, lang }) => {
    const {
        available,
        BlockComponent,
        handleProgressReport,
        extraProps,
        blockData,
        blockType,
        courseId,
        isOwner,
    } = useBlock({
        block,
        BlockProgress,
    })

    if (!available) {
        return (
            <NoConnectionEmptyState blockName={block.name} lang={lang} showName={block.show_name} />
        )
    }

    return (
        <BlockComponent
            {...blockData}
            currentProgress={BlockProgress}
            block={block}
            blockType={blockType}
            progressReport={handleProgressReport}
            courseId={courseId}
            isMobile={isMobile}
            isCourseExpired={isCourseExpired}
            isOwner={isOwner}
            {...extraProps}
        />
    )
}

Block.propTypes = {
    block: PropTypes.object,
    isCourseExpired: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool,
}

Block.defaultProps = {
    block: {},
    isMobile: false,
}

export default Block
