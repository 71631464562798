import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { history }  from '@/app/store'

import SendEmailPresentational from './SendEmailPresentational'

import { loadStudentsByCourse } from '@/redux/reducers/_deprecated/userReducer'
import { clearEmailAlertAction, sendEmail } from '@/redux/reducers/_deprecated/courseReducer'

export class SendEmailContainer extends Component {
  constructor() {
    super()

    this.handleGoBack = this.handleGoBack.bind(this)
    this.renderAlert = this.renderAlert.bind(this)
  }

  componentDidMount() {
    this.props.loadStudentsByCourse(this.props.match.params.courseId)
    this.props.clearEmailAlert(false)
  }

  handleGoBack() {
    this.props.clearEmailAlert(false)
    history.goBack()
  }

  removeAlert(e) {
    e.preventDefault()
    this.props.clearEmailAlert(false)
  }

  renderAlert() {
    if (this.props.emailAlert) {
      return (
        <div className="toastA alert alert-danger" ref="alert">
          <a href="#" className="close" onClick={this.removeAlert.bind(this)}>&times;</a>
          <h3 className="alert-text">{this.props.lang.sendErrorMessage}</h3>
        </div>
      )
    }
    else {
      return null
    }
  }


  render() {
    return (
      <>
        {this.renderAlert()}
        <SendEmailPresentational
          teacherEmail={this.props.user.email}
          teacherName={this.props.user.name + ' ' + this.props.user.surname}
          // students={this.props.allCourseStudents.isLoaded ? this.props.allCourseStudents.students : null}
          // submitSendEmail={}
          handleGoBack={this.handleGoBack}
          courseId={this.props.match.params.courseId}
          sendEmail={this.props.sendEmail}
          lang={this.props.lang}
        />
      </>
    )
  }

}

const mapDispatch = dispatch => ({
  sendEmail: data => dispatch(sendEmail(data)),
  loadStudentsByCourse: (courseId) => dispatch(loadStudentsByCourse(courseId)),
  clearEmailAlert: (data) => dispatch(clearEmailAlertAction(data)),
})

const mapStateToProps = state => {
  return {
    user: state.authReducer.user,
    allCourseStudents: state.userReducer.allCourseStudents,
    emailAlert: state.courseReducer.emailAlert,
    lang: state.langStore.sendEmailContainer
  }
}

export default withRouter(connect(mapStateToProps, mapDispatch)(SendEmailContainer))