import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { FormControl, Modal } from 'react-bootstrap'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Select from 'react-select'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import { closeModalLessonAction, updateLessonAction } from '@/redux/reducers/_deprecated/modalsReducer'
import { saveNewLesson, updateOneLesson, loadAllLessonTypes, changeSavingLessonAction, closeLessonModalAndClearGroupLesson, clearAllGroupsLessonAction } from '@/redux/reducers/_deprecated/courseReducer'
import { CustomFormField } from '@/components/CustomFormField'
import lessonTypesConstants from '@/helpers/lessons/lessonTypes'
import { allowApiCreate } from '@/helpers/granters/courseTypeRules'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import { EDIT_LESSON_NAME, EDIT_LESSON_DATES, EDIT_LESSON_VISIBILITY, CREATE_LESSONS } from '@/constants/coursePermissions'
import { timezoneConfig } from '@/helpers/dates/timezoneConfig'
import { dateFormat } from '@/helpers/dates/dateFormat'
import { disableLessonCompleteDate } from '@/helpers/granters/courseTypeRules'
import { enableLessonDateInputs } from '@/helpers/granters/hideMamushkaElements'

const [tz, locale] = timezoneConfig()
moment.locale(locale)

export class ModalLesson extends React.Component {

  constructor(props, context) {
    super(props, context)

    this.state = {
      name: { default: '', error: false, message: '' },
      lesson_type: { default: '', error: false, message: '' },
    }

    this.handleClose = this.handleClose.bind(this)
    this.submitLesson = this.submitLesson.bind(this)
    this.handleVisibilityLesson = this.handleVisibilityLesson.bind(this)
    this.handleReleaseDate = this.handleReleaseDate.bind(this)
    this.handleDueDate = this.handleDueDate.bind(this)
    this.datePickDue = this.datePickDue.bind(this)
    this.datePickRelease = this.datePickRelease.bind(this)
    this.handleLessonType = this.handleLessonType.bind(this)
    this.getLessonTypeOption = this.getLessonTypeOption.bind(this)
    this.checkPermissions = this.checkPermissions.bind(this)
    this.getName = this.getName.bind(this)
  }

  componentDidMount() {
    this.props.changeSavingLesson(false)
    this.props.clearAllGroupsLessonAction()
    this.props.loadAllLessonTypes()
  }

  dateFormatByCourseRule() {
    !disableLessonCompleteDate(this.props.activeCourse.CourseType) ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
  }

  handleClose() {
    this.setState({
      name: { default: '', error: false, message: '' },
      lesson_type: { default: '', error: false, message: '' },
    })
    this.props.closeLessonModalAndClearGroupLesson()
  }

  getLessonReleaseDateFromLessonType(group_lesson, lesson_type_id) {
    let release_date = null
    if (lesson_type_id) {
      let lessonType = _.find(this.props.lessonTypes.items, { id: lesson_type_id })
      if (lessonType.name === lessonTypesConstants.SYNC) {
        release_date = group_lesson.due_date
      } else if (lessonType.name === lessonTypesConstants.ASYNC) {
        release_date = group_lesson.release_date
      }
    }
    return release_date
  }

  handleVisibilityLesson(selectedValue) {
    let newCheck = selectedValue.value
    let lessonData = { ...this.props.lesson, visibility: newCheck }
    this.props.updateLesson(lessonData)
  }

  handleLessonType(selectedValue) {
    let lessonData = { ...this.props.lesson, lesson_type_id: selectedValue.value }
    this.props.updateLesson(lessonData)
  }

  handleReleaseDate(date) {
    let dateValue
    if (!date) {
      dateValue = date
    }
    else {
      dateValue = date.format(this.dateFormatByCourseRule())
    }
    let lessonData = { unitId: this.props.lesson.unitId, name: this.props.lesson.name, id: this.props.lesson.id, release_date: dateValue, due_date: this.props.lesson.due_date, visibility: this.props.lesson.visibility, order: this.props.lesson.order, lesson_type_id: this.props.lesson.lesson_type_id, group_lesson: this.props.lesson.group_lesson }
    this.props.updateLesson(lessonData)
  }

  handleDueDate(date) {
    let dateValue
    if (!date) {
      dateValue = date
    }
    else {
      dateValue = date.format(this.dateFormatByCourseRule())
    }
    let lessonData = { unitId: this.props.lesson.unitId, name: this.props.lesson.name, id: this.props.lesson.id, release_date: this.props.lesson.release_date, due_date: dateValue, visibility: this.props.lesson.visibility, order: this.props.lesson.order, lesson_type_id: this.props.lesson.lesson_type_id, group_lesson: this.props.lesson.group_lesson }
    this.props.updateLesson(lessonData)
  }

  datePickRelease() {
    if (!this.props.lesson.release_date || this.props.lesson.release_date === '') {
      return null
    } else {
      return moment(this.props.lesson.release_date).tz(tz)
    }
  }

  datePickDue() {
    if (!this.props.lesson.due_date || this.props.lesson.due_date === '') {
      return null
    } else {
      return moment(this.props.lesson.due_date).tz(tz)
    }
  }

  validateInfo(name, lesson_type_id, release_date, due_date) {
    let lessonFormOk = true
    let nameValue = { error: false, message: '' }
    let lesson_typeValue = { error: false, message: '' }

    if (typeof (name) === 'string') {
      name = name.trim()
    }
    if ((name == null || name == '')) {
      nameValue.error = true
      nameValue.message = this.props.lang.required
      lessonFormOk = false
    }

    if (lesson_type_id == null) {
      lesson_typeValue.message = this.props.lang.required
      lesson_typeValue.error = true
      lessonFormOk = false
    }

    this.setState({
      ...this.state,
      name: nameValue,
      lesson_type: lesson_typeValue
    })

    return lessonFormOk
  }

  submitLesson() {
    let unitId = this.props.lesson.unitId
    let lessonId = this.props.lesson.id
    let courseId = this.props.courseId
    let name = this.getName()
    let order = this.props.lesson.order
    let visibility = this.props.lesson.visibility
    let release_date = this.props.lesson.release_date
    let due_date = this.props.lesson.due_date
    let lesson_type_id = this.allowApiCreateRule() ? this.props.lesson.lesson_type_id : this.props.lessonTypes.items.find(type => type.name == lessonTypesConstants.SYNC).id
    let isBaseCourse = this.props.activeCourse.base

    if (release_date) {
      let rd = moment(release_date).utc()
      if (disableLessonCompleteDate(this.props.activeCourse.CourseType)) {
        rd.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      }
      release_date = rd.format('YYYY-MM-DD HH:mm:ss')
    }

    if (due_date) {
      let dd = moment(due_date).utc()
      if (disableLessonCompleteDate(this.props.activeCourse.CourseType)) {
        dd.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      }
      due_date = dd.format('YYYY-MM-DD HH:mm:ss')
    }

    let formValidation = this.validateInfo(name, lesson_type_id, release_date, due_date)

    if (formValidation) {
      this.props.changeSavingLesson(true)

      if (lessonId == null) {
        let saveInfo = { name, visibility, release_date, due_date, courseId, unitId, lesson_type_id, isBaseCourse }
        this.props.saveNewLesson(saveInfo, this.props.activeCourse)
      } else {
        let updateInfo = { id: lessonId, name, order, visibility, release_date, due_date, courseId, unitId, lesson_type_id, isBaseCourse }
        this.props.updateOneLesson(updateInfo, this.props.activeCourse)
      }

      //CLEAR
      this.setState({
        name: { default: '', error: false, message: '' },
        lesson_type: { default: '', error: false, message: '' },
      })
    }
  }

  allowApiCreateRule() {
    let ok = false
    if (
      this.props.activeCourse &&
      this.props.activeCourse.CourseType !== undefined &&
      allowApiCreate(this.props.activeCourse.CourseType)
    ) {
      ok = true
    }
    return ok
  }

  getLessonTypeOption(lesson) {
    const lessonTypeName = lessonTypesConstants[lesson.name]
    return { value: lesson.id, label: lessonTypeName }
  }

  checkPermissions(permissionType) {
    const { lesson } = this.props

    const types = {
      'name': EDIT_LESSON_NAME,
      'dates': EDIT_LESSON_DATES,
      'visibility': EDIT_LESSON_VISIBILITY
    }

    switch (permissionType) {
      case 'dates':
        if (!lesson.id)
          return CREATE_LESSONS() && enableLessonDateInputs(this.props.activeCourse)
        return types[permissionType]() && enableLessonDateInputs(this.props.activeCourse)
      default:
        if (!lesson.id) return CREATE_LESSONS()
        return types[permissionType]()
    }
  }

  getName() {
    if (ReactDOM.findDOMNode(this.name)) {
      return ReactDOM.findDOMNode(this.name).value
    }
    if (this.props.lesson.name) {
      return this.props.lesson.name
    }
    return null
  }

  render() {
    if (!this.props.groupsLesson.isLoaded) {
      return null
    }

    return (
      <React.Fragment>
        <Modal className="__modal" bsSize="large" show={this.props.show} onHide={this.handleClose} keyboard={false} backdrop="static">
          <Modal.Header closeButton className="__modal_header __border_bottom">
            <h3 className="__modal_title">{this.props.lesson && this.props.lesson.id ? this.props.lang.editLessonTitle : this.props.lang.createLessonTitle}</h3>
          </Modal.Header>

          <Modal.Body className="__modal_body">
            {this.checkPermissions('name') &&
              <Row>
                <Col md="12">
                  <CustomFormField validationState={this.state.name.error ? 'error' : null}
                    validationMessage={this.state.name.message} bsSize="small" controlId="name"
                    label={this.props.lang.nameLabel}
                    inputComponent={
                      <FormControl key="unitName" bsSize="small"
                        defaultValue={this.props.lesson.name}
                        ref={name => { this.name = name }} type="text"
                        autoFocus>
                      </FormControl>
                    } />
                </Col>
              </Row>
            }
            {/* // TODO : IF ONDEMAND */}
            {this.checkPermissions('dates') &&
              <Row style={{ marginTop: '20px' }}>
                <Col md="6">
                  <CustomFormField bsSize="small" controlId="release_date"
                    label={this.props.lang.initialDate}
                    inputComponent={
                      <DatePicker
                        className="form-control input-sm datePickerWidth"
                        dateFormat={dateFormat(!disableLessonCompleteDate(this.props.activeCourse.CourseType))}
                        selected={this.datePickRelease()}
                        onChange={this.handleReleaseDate}
                        placeholderText={this.props.lang.selectDate}
                        disabled={!enableLessonDateInputs(this.props.activeCourse)}
                        showTimeSelect={!disableLessonCompleteDate(this.props.activeCourse.CourseType)}
                      />
                    } />
                </Col>
                <Col md="6">
                  <CustomFormField bsSize="small" controlId="due_date"
                    label={this.props.lang.finishDate}
                    inputComponent={
                      <DatePicker
                        className="form-control input-sm datePickerWidth"
                        dateFormat={dateFormat(!disableLessonCompleteDate(this.props.activeCourse.CourseType))}
                        selected={this.datePickDue()}
                        onChange={this.handleDueDate}
                        placeholderText={this.props.lang.selectDate}
                        disabled={!enableLessonDateInputs(this.props.activeCourse)}
                        showTimeSelect={!disableLessonCompleteDate(this.props.activeCourse.CourseType)}
                      />
                    } />
                </Col>
              </Row>
            }
            <Row style={{ marginTop: '20px' }}>
              {this.checkPermissions('visibility') &&
                <Col md="6">
                  <CustomFormField
                    bsSize="small" controlId="visibility"
                    label={this.props.lang.isVisible}
                    inputComponent={
                      <Select
                        name="visibility"
                        placeholder={this.props.lang.select}
                        id="visibility"
                        key="visibility"
                        options={[
                          { value: true, label: this.props.lang.yes },
                          { value: false, label: this.props.lang.no }
                        ]}
                        onChange={this.handleVisibilityLesson}
                        defaultValue={this.props.lesson.id ? (this.props.lesson.visibility ? { value: true, label: this.props.lang.yes } : { value: false, label: this.props.lang.no }) : { value: true, label: this.props.lang.yes }}
                      />
                    } />
                </Col>
              }
              <Col md="6">
                <CustomFormField
                  validationState={this.state.lesson_type.error ? 'error' : null}
                  validationMessage={this.state.lesson_type.message}
                  bsSize="small" controlId="lessonType"
                  label={this.props.lang.kindOfClass}
                  inputComponent={
                    <Select
                      name="lessonType"
                      placeholder={this.props.lang.kindOfClass}
                      id="lessonType"
                      key="lessonType"
                      options={this.props.lessonTypes.isLoaded
                        ? this.props.lessonTypes.items.map(lesson => this.getLessonTypeOption(lesson))
                        : null
                      }
                      defaultValue={this.props.lesson.id
                        ? { label: _.find(this.props.lessonTypes.items, { id: this.props.lesson.lesson_type_id }).name, value: this.props.lesson.lesson_type_id }
                        : null
                      }
                      onChange={this.handleLessonType}
                    />
                  } />
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer className="__modal_footer __border_top">
            <ButtonGroup>
              <Button
                id="CloseButton"
                onClick={this.handleClose}
                text={this.props.lang.cancel}
                theme='tertiary'
                disabled={this.props.savingLesson}
              />
              <Button
                id="SaveLessonButton"
                theme='primary'
                loading={this.props.savingLesson}
                onClick={this.submitLesson}
                text={this.props.lang.save}
                disabled={this.props.savingLesson}
              />
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapDispatch = (dispatch) => ({
  saveNewLesson: (data, activeCourse) => {
    dispatch(saveNewLesson(data, activeCourse))
  },
  updateOneLesson: (data, activeCourse) => {
    dispatch(updateOneLesson(data, activeCourse))
  },
  closeModalLesson: () => {
    dispatch(closeModalLessonAction())
  },
  updateLesson: (data) => {
    dispatch(updateLessonAction(data))
  },
  loadAllLessonTypes: () => {
    dispatch(loadAllLessonTypes())
  },
  changeSavingLesson: (status) => {
    dispatch(changeSavingLessonAction(status))
  },
  closeLessonModalAndClearGroupLesson: () => {
    dispatch(closeLessonModalAndClearGroupLesson())
  },
  clearAllGroupsLessonAction: () => {
    dispatch(clearAllGroupsLessonAction())
  }
})

const mapStateToProps = (state, ownProps) => {
  return {
    activeCourse: state.courseReducer.activeCourse,
    lesson: state.modalsReducer.lesson,
    show: state.modalsReducer.showModalLesson,
    lessonTypes: state.courseReducer.lessonTypes,
    groupsLesson: state.courseReducer.groupsLesson,
    courseClonesQty: state.courseReducer.courseClonesQty,
    savingLesson: state.courseReducer.savingLesson,
    lang: state.langStore.modalLesson
  }
}

export default connect(mapStateToProps, mapDispatch, null, { forwardRef: true })(ModalLesson)