'use-strict'

import constants from './constants'

export default {
    setPaginatedRoles: payload => ({
        type: constants.SET_PAGINATED_ROLES,
        payload
    }),
    setPaginatedPermissions: payload => ({
        type: constants.SET_PAGINATED_PERMISSIONS,
        payload
    }),
    setIsLoading: (bool, loader) => ({
        type: constants.SET_IS_LOADING,
        payload: { bool, loader }
    }),
    clearActiveRole: () => ({
        type: constants.CLEAR_ACTIVE_ROLE
    }),
    clearAllRoles: () => ({
        type: constants.CLEAR_ALL_ROLES
    }),
    setRolePermissions: payload => ({
        type: constants.SET_ROLE_PERMISSIONS,
        payload
    }),
    setActiveRole: payload => ({
        type: constants.SET_ACTIVE_ROLE,
        payload
    }),
    clearRolePermissions: () => ({
        type: constants.CLEAR_ROLE_PERMISSIONS
    }),
    setRoleCurrentPage: payload => ({
        type: constants.SET_ROLE_CURRENT_PAGE,
        payload
    }),
    clearPaginatedRoles: () => ({
        type: constants.CLEAR_PAGINATED_ROLES
    })
}