export const finishCourse = {
    EVENT_NAME: 'Button Clicked',
    EVENT_CONTEXT: 'Free Course Completed',
    EVENT_LABEL: 'Finish Course',
}

export const keepLearning = {
    EVENT_NAME: 'Button Clicked',
    EVENT_CONTEXT: 'Free Course Completed',
    EVENT_LABEL: 'More Courses',
}

export const startCourse = {
    EVENT_NAME: 'Button Clicked',
    EVENT_CONTEXT: 'Course Card',
    EVENT_LABEL: 'Start Course',
}
export const blockIssueReport = {
    EVENT_NAME: 'Report Submitted',
    EVENT_CONTEXT: 'Course Content',
    EVENT_LABEL: 'Block Report',
}
