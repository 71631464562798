import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { AppContainer } from 'react-hot-loader'
import { ConnectedRouter } from 'connected-react-router'
import { initialize } from '@digitalhouse-tech/react-sdk-analytics'

import configureStore, { history } from '@/app/store.js'
import App from '@/app/App'

import * as env from '@/config/env'

import getLangController from '@/redux/reducers/_deprecated/langReducer/getLangController'
import removePreloader from '@/helpers/utils/removePreloader'
import { loadLookAndFeel, loadTenant, loadHeap } from '@/helpers/tenants'

if (process.env.NODE_ENV === 'production') {
    initialize(env.SEGMENT_WRITE_KEY, env.SEGMENT_URL)
}

const rootEl = document.getElementById('root')

const render = (Component) => {
    ReactDOM.render(
        <Provider store={configureStore.store}>
            <PersistGate loading={null} persistor={configureStore.persistor}>
                <ConnectedRouter history={history}>
                    <Router history={history}>
                        <AppContainer>
                            <Component />
                        </AppContainer>
                    </Router>
                </ConnectedRouter>
            </PersistGate>
        </Provider>,
        rootEl,
        afterRender
    )
}

render(App)

if (module.hot) {
    module.hot.accept('./app/App', () => {
        const App = require('./app/App').default
        render(App)
    })
}

async function afterRender() {
    loadHeap()
    await loadTenant()
    // load i18n and load the look & feel of the tenant
    await Promise.all([getLangController(), loadLookAndFeel()])
    // remove preloader when app loaded
    removePreloader()
}
