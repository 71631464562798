import React, { useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Breadcrumb from '@/components/Breadcrumb'
import { loadActiveTab } from '@/redux/thunks/courseThunks'
import './StudentTabContainer.scss'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

const StudentTabContainer = () => {

    const params = useParams()
    const dispatch = useDispatch()

    const lang = useSelector(({ langStore }) => langStore.studentTabContainer)
    const activeTab = useSelector(({ course }) => course.activeTab)

    useEffect(() => {
        dispatch(loadActiveTab(params.courseId, params.tabId))
    }, [params, dispatch])

    const getContent = (activeTab) => {
        if (activeTab) {
            if (activeTab.tab_info) return (
                <div
                    dangerouslySetInnerHTML={
                        { __html: activeTab.tab_info }
                    }
                />
            )

            return (
                <div className="__search_msg_result">
                    <p>{lang.noContentAvailable}</p>
                </div>
            )
        }

        return null
    }

    const getTabName = useCallback((activeTab) => {
        if (activeTab) return (activeTab.name)
        return null
    }, [])

    const crumbs = useMemo(() => [{ name: getTabName(activeTab) }], [activeTab, getTabName])

    return (
        <div className="container _student_tab_container">
            <Breadcrumb
                crumbs={crumbs}
            />
            <div className="__main_header">
                <Row>
                    <Col lg="6">
                        <h1 className="__main_title">{getTabName(activeTab)}</h1>
                    </Col>
                </Row>
            </div>

            <div className="StudentTab __card __min_card_height">
                {getContent(activeTab)}
            </div>
        </div>
    )
}

StudentTabContainer.propTypes = {
    activeTab: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        course_id: PropTypes.string,
        visibility: PropTypes.bool,
        tab_info: PropTypes.string
    })
}

StudentTabContainer.defaultProps = {
    activeTab: {},
}

export default StudentTabContainer
