'use-strict'

export default {
    SET_ALL_EXERCISES: 'SET_ALL_EXERCISES',
    SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
    SET_PAGINATED_EXERCISES: 'SET_PAGINATED_EXERCISES',
    SET_FINDER_FIELD: 'SET_FINDER_FIELD',
    SET_ALL_TAGS: 'SET_ALL_TAGS',
    SET_ALL_EXERCISE_TYPES: 'SET_ALL_EXERCISE_TYPES',
    SET_ALL_RULE_TYPES: 'SET_ALL_RULE_TYPES',
    SET_REQUEST_LOADING: 'SET_REQUEST_LOADING',
    SET_WORKING_EXERCISE: 'SET_WORKING_EXERCISE',
    SET_SOLVED_VALIDATIONS: 'SET_SOLVED_VALIDATIONS',
    SET_IF_EXERCISE_HAS_PROGRESS: 'SET_IF_EXERCISE_HAS_PROGRESS',
    CLEAR_FINDER_FIELDS: 'CLEAR_FINDER_FIELDS',
    CLEAR_WORKING_EXERCISE: 'CLEAR_WORKING_EXERCISE',
    CLEAR_SOLVED_VALIDATIONS: 'CLEAR_SOLVED_VALIDATIONS',
    CLEAR_IF_EXERCISE_HAS_PROGRESS: 'CLEAR_IF_EXERCISE_HAS_PROGRESS'
}