import * as dashboard from '../constants/dashboard'

const DEFAULT_STATUS_INFO = {
    ctaLineAppearence: false,
    ctaText: '',
    icon: '',
    text: '',
    theme: '',
}

export const getStatusInfo = (status, lang = {}) => {
    const statusInfo = {
        [dashboard.APPROVED]: {
            ctaLineAppearence: true,
            ctaText: lang.seeActivity,
            icon: dashboard.CIRCLE_CHECK_ICON,
            text: lang.approvedStudent,
            theme: dashboard.SUCCESS_STATUS,
        },
        [dashboard.FAILED]: {
            ctaLineAppearence: true,
            ctaText: lang.seeActivity,
            icon: dashboard.CIRCLE_CROSS_ICON,
            text: lang.disapprovedStudent,
            theme: dashboard.WARNING_STATUS,
        },
        [dashboard.PENDING_RETRY]: {
            ctaLineAppearence: true,
            ctaText: lang.seeActivity,
            icon: dashboard.CLOCK_ICON,
            text: lang.pendingRedo,
            theme: dashboard.DISABLED_STATUS,
        },
        [dashboard.PENDING_FEEDBACK]: {
            ctaLineAppearence: false,
            ctaText: lang.evaluate,
            icon: dashboard.CLOCK_ICON,
            text: lang.pendingFeedback,
            theme: dashboard.INFO_STATUS,
        },
    }

    return statusInfo?.[status] || DEFAULT_STATUS_INFO
}

export const studentProgressUrl = (blockId, userId) =>
    `evaluable-activity/blocks/${blockId}/users/${userId}/review`

export const shapeStudentInfo = (student = { name: '', surname: '', email: '', id: '' }) => ({
    fullName: `${student.surname} ${student.name}`,
    email: student.email,
    id: student.id,
})

export const cleanString = (string) =>
    string
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')

export const studentReviewUrl = (courseId, blockId, id) =>
    `/admin/courses/${courseId}/dashboard/evaluable-activity/blocks/${blockId}/users/${id}/review`

export const progressListUrl = (courseId) =>
    `/admin/courses/${courseId}/dashboard/evaluable-activity`

export const shapeReviewToSend = ({ reviewType, type, reviewToSend, reporterId }) => {
    const formatedFiles = reviewToSend?.files?.map((file) => {
        delete file.icon
        delete file.onClick
        return file
    })
    return {
        type,
        Submission: {
            status: reviewType,
            feedback: {
                ...reviewToSend,
                files: formatedFiles,
                reporterId,
            },
        },
    }
}
