import React from 'react'
import PropTypes from 'prop-types'
import { emptyStateAsset } from '@/assets/img/config'

const CourseTeachersCard = ({teacher, indexKey: key}) => {
    return (
        <div className="teacherCard" key={key}>
            <div className="avatar">
                <div className="profileImg">
                    <img
                        src={teacher.avatar || emptyStateAsset('default-user-avatar.svg')}
                        alt="teacher-img"
                    />
                </div>
            </div>
            <div className="teacherDetails">
                <div className="name">
                    {`${teacher.name} ${teacher.surname}`}
                </div>
                <div className="role">
                    {teacher.OwnershipRole.description}
                </div>
                <div className="email">
                    <span>{teacher.email}</span>
                </div>
            </div>
        </div>
    )
}

CourseTeachersCard.defaultProps = {
    indexKey: `teacher${Math.floor(Math.random(0,50))}`,
}

CourseTeachersCard.propTypes = {
    teacher: PropTypes.shape({
        name: PropTypes.string,
        surname: PropTypes.string,
        email: PropTypes.string,
        id: PropTypes.string,
        avatar: PropTypes.string,
        OwnershipRole: PropTypes.shape({
            description: PropTypes.string,
        }).isRequired
    }).isRequired,
    indexKey: PropTypes.string,
}
export default CourseTeachersCard
