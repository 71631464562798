import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import moment from "moment"
import Lesson from './Lessons/LessonCard'
import ModalConfirmation from '@/components/ModalConfirmation'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import { Panel } from 'react-bootstrap'
import { updateActiveCourseAction, toggleDragLessonAction, toggleDisableLesson, loadAllGroupsByCourseId, loadActiveLessons } from '@/redux/reducers/_deprecated/courseReducer'
import { actions as modalActions } from '@/redux/reducers/modalReducer'
import { UNIT_MODAL, VISIBILITY_MODAL } from '@/constants/modals'
import { showModalLessonEditAction } from '@/redux/reducers/_deprecated/modalsReducer'
import { Eye, EyeOff, Edit2, Move } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import {
    ORDER_LESSONS,
    CREATE_LESSONS,
    EDIT_UNIT_VISIBILITY,
    EDIT_UNIT_NAME,
    EDIT_UNIT_DESCRIPTION,
    EDIT_UNIT_DATES
} from '../../../../../constants/coursePermissions'

import { checkPermissionsGroup } from '@digitalhouse-tech/ts-lib-store/lib/session-storage/permission'
import { formatConfig } from '@/helpers/dates/timezoneConfig'
import { disableUnitCompleteDate } from '@/helpers/granters/courseTypeRules'
import { showUnitsAndLessonsActions, hideChangeVisibility, showUnitsAndLessonsEditActions } from '@/helpers/granters/hideMamushkaElements'

import CourseSkeleton from '../Skeleton'
import './styles/Card.scss'

export class UnitCard extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            items: [],
            renderLessons: false,
            panelBodyOpen: true,
        }

        this.openModalUnitDelete = this.openModalUnitDelete.bind(this)
        this.openModalUnit = this.openModalUnit.bind(this)
        this.openModalLesson = this.openModalLesson.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)
        this.getLessons = this.getLessons.bind(this)
        this.showLessons = this.showLessons.bind(this)
        this.handleOrder = this.handleOrder.bind(this)
        this.renderPanelTitle = this.renderPanelTitle.bind(this)
        this.changeOrder = this.changeOrder.bind(this)
        this.openModalVisibility = this.openModalVisibility.bind(this)
        this.toggleRenderLessons = this.toggleRenderLessons.bind(this);
    }

    componentDidMount() {
        let allLessonsAux = this.props.Lessons

        this.setState({
            ...this.state,
            items: allLessonsAux,
        })
    }

    handleOrder() {
        this.props.toggleDragLessonDisable()
    }

    changeOrder() {
        let data = {}
        data.courseId = this.props.courseId
        data.unitId = this.props.id
        data.activeCourse = this.props.activeCourse

        this.props.toggleDisableLesson(data)

        this.props.toggleDragLessonDisable()
    }

    getLessons() {
        let course = this.props.activeCourse
        let unit = _.find(course.Units, { 'id': this.props.id })

        return unit.Lessons || []
    }

    openModalUnitDelete() {
        return this.modalUnitDelete.handleShow()
    }

    openModalVisibility() {
        let visibilityInfo = {
            courseId: this.props.courseId,
            unitId: this.props.id,
            visibilityRoute: 'units',
            visibility: !this.props.visibility,
            modalText: this.props.lang.modalVisibilityText
        }
        this.props.openModal(VISIBILITY_MODAL, visibilityInfo)
    }

    openModalUnit() {
        let unitData = { name: this.props.name, id: this.props.id, release_date: this.props.release_date, visibility: this.props.visibility, order: this.props.order, description: this.props.description }
        this.props.openModal(UNIT_MODAL, unitData)
    }

    openModalLesson() {
        this.props.loadAllGroupsByCourseId(this.props.activeCourse.id)
        let lessonData = { unitId: this.props.id, name: '', id: null, release_date: null, due_date: null, visibility: true, order: null }
        this.props.showModalLesson(lessonData)
    }

    showLessons() {
        if (!this.state.renderLessons) return null

        let courseAux = this.props.activeCourse
        let unitAux = _.find(courseAux.Units, { 'id': this.props.id })
        let lessons = unitAux.Lessons

        if (!lessons) {
            this.props.loadActiveLessons(courseAux.id, unitAux.id)
            return <CourseSkeleton width='100%' height='100px' />
        }

        let resultLessons = Array()

        lessons?.map((lesson) => {
            resultLessons.push(<Lesson {...lesson} key={lesson.id} courseId={courseAux.id} unitId={unitAux.id} />)
        })

        return resultLessons
    }

    reorder(items, startIndex, endIndex) {
        let result = Array.from(items)
        let [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        let activeCourseCopy = _.cloneDeep(this.props.activeCourse)
        let unitAux = _.find(activeCourseCopy.Units, { 'id': this.props.id })

        let newLessons = this.reorder(
            unitAux.Lessons,
            result.source.index,
            result.destination.index
        )

        newLessons.map((item, index) => (
            item.order = index
        ))

        unitAux.Lessons = newLessons
        this.props.updateElementsOrder(activeCourseCopy)

    }

    toggleRenderLessons() {
        this.setState({ renderLessons: !this.state.renderLessons });
    }

    renderPanelTitle() {
        let title =
            <h3 className="__card_title __title_margin"
                onClick={this.toggleRenderLessons}>
                {this.props.name}
            </h3>
        return this.props.dragDisable ? <Panel.Toggle>{title}</Panel.Toggle> : title
    }

    // getInheritsVisibility() {
    //     return !this.props.visibility || !this.isReleased()
    // }

    // getVisibilityClass() {
    //     return this.getInheritsVisibility() ? 'notVisible' : ''
    // }

    // isReleased() {
    //     const releaseDate = this.releaseDate()
    //     const now = moment(new Date).utc()
    //     return (!!this.props.release_date && now.isSameOrAfter(releaseDate))
    // }

    releaseDate() {
        return moment(new Date(this.props.release_date)).utc()
    }

    formatDate(date) {
        const defaultFormat = ' DD/MM/YYYY'
        const hourFormat = ' HH:mm [hs]'
        return formatConfig(date, defaultFormat, hourFormat, !disableUnitCompleteDate(this.props.activeCourse.CourseType))
    }

    render() {
        if (!this.props.activeCourse.CourseType) return null
        const isDragging = (!this.props.dragGeneralDisable && this.props.dragDisableLessons)
        return (
            <>
                <Panel className={`__card left-border--disabled`}>
                    <Panel.Heading>
                        <Row>
                            <Col lg="9">
                                {this.renderPanelTitle()}
                                <ul className="__card_detail">
                                    {
                                        this.props.release_date
                                            ? <li>{this.props.lang.initialDate} {this.formatDate(this.releaseDate())}</li>
                                            : <li>{this.props.lang.initialDate} {this.props.lang.notAvailable}</li>
                                    }
                                </ul>
                            </Col>
                            <Col lg="3" className="__card_button_box __center_buttons_course">
                                <ButtonGroup>
                                    { !hideChangeVisibility(this.props.activeCourse) &&
                                        <ButtonRound
                                            id="OpenModalVisibilityButton"
                                            onClick={this.openModalVisibility}
                                            disabled={isDragging || !EDIT_UNIT_VISIBILITY()}
                                            icon={this.props.visibility ? <Eye /> : <EyeOff />}
                                            theme='info'
                                        />
                                    }
                                    { showUnitsAndLessonsEditActions(this.props.activeCourse) &&
                                        <ButtonRound
                                            id="OpenModalUnitButton"
                                            theme='primary_dark'
                                            onClick={this.openModalUnit}
                                            icon={<Edit2 />}
                                            disabled={isDragging}
                                            visible={checkPermissionsGroup.oneOf([
                                                EDIT_UNIT_NAME,
                                                EDIT_UNIT_DESCRIPTION,
                                                EDIT_UNIT_DATES
                                            ])}
                                        />
                                    }
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <Panel className={`__card __borderGray`}>
                            <Row className="__card_header">
                                <Col lg="2">
                                    <h3 className="__card_title __title_margin __font_weight_bold _cursor_pointer"
                                        onClick={() => {
                                            this.setState({ panelBodyOpen: !this.state.panelBodyOpen });
                                            this.toggleRenderLessons();
                                        }}>
                                        {this.props.lang.classesTitle}
                                    </h3>
                                </Col>
                                { showUnitsAndLessonsActions(this.props.activeCourse) &&
                                    <Col lg="10" className="__card_button_box __center_buttons_course">
                                        <ButtonGroup>
                                            <Button
                                                id="OrderButton"
                                                visible={ORDER_LESSONS()}
                                                text={this.props.dragDisableLessons ? this.props.lang.sort : this.props.lang.finish}
                                                icon={<Move />}
                                                leftIcon
                                                onClick={this.props.dragDisableLessons ? this.handleOrder : this.changeOrder}
                                                disabled={!this.props.dragGeneralDisable && this.props.dragDisableLessons}
                                                theme='tertiary'
                                            />
                                            <Button
                                                id="OpenModalLessonButton"
                                                visible={CREATE_LESSONS()}
                                                theme='tertiary'
                                                text={this.props.lang.createNewClass}
                                                onClick={this.openModalLesson}
                                                disabled={!(this.props.dragDisableLessons && this.props.dragGeneralDisable)}
                                            />
                                        </ButtonGroup>
                                    </Col>
                                }
                            </Row>
                            {this.props.dragDisableLessons ? this.state.panelBodyOpen && this.showLessons() : <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId={'droppable-' + this.props.courseId + '-' + this.props.unitId}>
                                    {(provided) => (
                                        <div ref={provided.innerRef} >
                                            {this.getLessons().map((lesson, index) => (
                                                <Draggable key={index} draggableId={lesson.id} index={index} >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            style={{ backgroundColor: snapshot.isDraggingOver ? 'var(--primary)' : 'var(--disabled)' }}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Lesson {...lesson} key={lesson.id} courseId={this.props.courseId} unitId={this.props.id} />
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>}
                        </Panel>
                    </Panel.Body>
                </Panel>
                <ModalConfirmation
                    ref={(modalUnitDelete) => { this.modalUnitDelete = modalUnitDelete }}
                    cancelTitle={this.props.lang.cancelTitle}
                    confirmTitle={this.props.lang.confirmTitle}
                    body={this.props.lang.modalBody}
                    onConfirm={() => {/**Aun no se eliminan units**/ }}
                />
            </>
        )
    }
}

const mapDispatch = (dispatch) => ({
    updateElementsOrder: (data) => {
        dispatch(updateActiveCourseAction(data))
    },
    toggleDragLessonDisable: () => {
        dispatch(toggleDragLessonAction())
    },
    toggleDisableLesson: (data) => {
        dispatch(toggleDisableLesson(data))
    },
    showModalLesson: (lessonData) => {
        dispatch(showModalLessonEditAction(lessonData))
    },
    openModal: (id, data) => dispatch(modalActions.open(id, data)),
    loadAllGroupsByCourseId: (courseId) => {
        dispatch(loadAllGroupsByCourseId(courseId))
    },
    loadActiveLessons: (courseId, unitId) => {
        dispatch(loadActiveLessons(courseId, unitId))
    },
})

const mapStateToProps = state => ({
    activeCourse: state.courseReducer.activeCourse,
    dragDisable: state.courseReducer.dragDisable,
    dragDisableLessons: state.courseReducer.dragDisableLessons,
    dragGeneralDisable: state.courseReducer.dragGeneralDisable,
    lang: {
        ...state.langStore.singleUnit,
        ...state.langStore.courseIndexButtons
    }
})

export default withRouter(connect(mapStateToProps, mapDispatch)(UnitCard))