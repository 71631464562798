import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import useCourseInfo from './shared/hooks/useCourseInfo'
import DashboardHeader from './shared/components/DashboardHeader/DashboardHeader'
import EmptyState from './shared/components/EmptyState'
import { atomAsset } from '@/assets/img/config'
import { loadCoursePermissions } from '@/redux/reducers/_deprecated/authReducer'
import * as coursePermissions from '@/constants/coursePermissions'
import { withRouter } from 'react-router'
import { useParams } from 'react-router-dom'

const Dashboard = ({ match, loadCoursePermissions }) => {
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const {courseId} = params
    const emptySelection = useSelector(({ langStore }) => langStore.dashboard.emptySelection)
    const { name, alias, is_massive } = useCourseInfo(courseId)
    
    useEffect(() => {
        loadCoursePermissions(courseId).then(() => {
            setTimeout(() => {
                if (!coursePermissions.VIEW_COURSE_PROGRESS_REPORT()) {
                    location.href = '/admin/'
                } else {
                    setLoading(false)
                }
            }, [1000])
        })
    })
    
    if (loading) return <div></div>;

    return (
        <div className='container dashboard-wrapper'>
            <DashboardHeader courseName={name} courseAlias={alias} courseMassive={is_massive} />
            <EmptyState
                text={emptySelection}
                id='dashboardIndexEpmtyState'
                imgUrl={atomAsset('grayscale-chart.svg')}
            />
        </div>
    )
}

Dashboard.propTypes = {
    match: PropTypes.object,
}

Dashboard.defaultProps = {
    match: {},
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatch = (
    dispatch,
    functions = {
        loadCoursePermissions,
    }
) => ({
    loadCoursePermissions: (courseId) => dispatch(functions.loadCoursePermissions(courseId)),
})

export default withRouter(connect(mapStateToProps, mapDispatch)(Dashboard))