import { isEmpty } from 'lodash'
import { createSelector } from 'reselect'
import { getContentMap } from './classroom'

/**
 * Get student.classroom.progress store key.
 * @param {Object} state
 * @returns {Object}
 */
export const getProgress = state => state.student.classroom.progress

/**
 * Get the progress of topics formatted as key value.
 * @returns {Array}
 */
export const getTopicProgress = createSelector(getProgress, ({ topicProgress = [] }) =>
    topicProgress.reduce(
        (init, { topic_id, completed }) => ({ ...init, [topic_id]: completed }),
        {},
    ),
)

/**
 * Get progress for the current block.
 * @param {String|Number} blockId
 * @returns {Object}
 */
export const getBlockProgress = blockId =>
    createSelector(getProgress, ({ blockProgress, quizProgress }) => {
        const currentBlockProgress = blockProgress.find(({ block_id }) => block_id == blockId)

        if (currentBlockProgress && !isEmpty(currentBlockProgress.ProgressQuiz)) {
            return { ...currentBlockProgress, ...quizProgress }
        }

        return currentBlockProgress || {}
    })

export const getTopicProgressByTopicId = (topicId, lessonId) =>
    createSelector(getProgress, getContentMap, ({ blockProgress }, { Lessons }) => {
        const topicProgresses = blockProgress.filter(({ topic_id }) => topic_id == topicId)
        const completedTopicProgresses = topicProgresses.filter(({ completed }) => !!completed)

        const topicLesson = Lessons.find(lesson => lesson.id == lessonId)
        const topic = topicLesson.Topics.find(topic => topic.id == topicId)

        if (!topicProgresses.length) {
            return null
        }

        return completedTopicProgresses.length >= topic.Blocks.length
    })

/**
 * Get Blocks to report progress.
 * @param {Array} blockIds 
 */
export const getBlockProgressesToReportByID = (blockIds = []) =>
    createSelector(getProgress, ({ blockProgress }) => {
        return blockProgress.filter(
            ({ block_id, ProgressQuiz, ProgressExercise }) => {
                const isQuizBlock = ProgressQuiz && ProgressQuiz.last_sending !== null
                const isExerciseBlock = ProgressExercise && ProgressExercise.last_sending !== null

                if (isQuizBlock || isExerciseBlock) {
                    return false
                }

                return blockIds.includes(block_id)
            },
        )
    })

export const getUserProgressPercent = () =>
    createSelector(getContentMap, ({ userProgress }) => userProgress?.percent)
