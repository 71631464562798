import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { history } from '@/app/store'
import { Manager, ManagerTableBody } from '@/components/Manager'
import OwnerListActions from './OwnerListActions'
import ModalConfirmation from '@/components/ModalConfirmation'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'

const OwnerList = ({
    courseId,
    owners,
    onClickDeleteOwner,
    lang,
    showEnrolmentButton,
    showDeleteButton,
    showEditPermissionsButton,
    showVisibilityButton,
    courseName,
    handleGoBack,
    onClickToggleVisibility,
    loading,
}) => {
    const [ownerDelete, setOwnerDelete] = useState(null)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const showActions = useMemo(
        () => showDeleteButton || showEditPermissionsButton || showVisibilityButton,
        [showDeleteButton, showEditPermissionsButton, showVisibilityButton],
    )

    const tableHeaders = useMemo(() => {
        const headers = [
            {
                label: lang.nameAndLastName,
                ref: 'name',
                className: 'col-sm-3',
            },
            {
                label: lang.educationalRole,
                ref: 'ownershipRole',
                className: 'col-sm-3',
            },
            {
                label: lang.email,
                ref: 'email',
                className: 'col-sm-4',
            },
        ]

        if (showActions) {
            headers.push({
                label: lang.actions,
                ref: 'actions',
                className: 'col-sm-2',
            })
        }

        return headers
    }, [lang.nameAndLastName, lang.educationalRole, lang.email, lang.actions, showActions])

    const openModalDelete = useCallback(
        ownerId => {
            setOwnerDelete(ownerId)
            setShowConfirmationModal(true)
        },
        [setOwnerDelete],
    )

    const onClickEditOwner = useCallback(
        ownerId => history.push(`/admin/courses/${courseId}/owners/${ownerId}/edit`),
        [courseId],
    )

    const onClickDeleteOwnerHandler = useCallback(() => {
        onClickDeleteOwner(ownerDelete)
        setShowConfirmationModal(false)
    }, [onClickDeleteOwner, ownerDelete])

    const closeModalConfirmation = () => setShowConfirmationModal(false)

    const addActions = useCallback(
        owners => {
            if (!showActions) return owners

            return owners.map(owner => ({
                ...owner,
                name: `${owner.name} ${owner.surname}`,
                ownershipRole: owner.OwnershipRole.description,
                actions: (
                    <OwnerListActions
                        isVisible={owner.OwnershipRole.UserHasOwnershipRole.visibility}
                        lang={lang}
                        onClickEditOwner={() => onClickEditOwner(owner.id)}
                        openModalDelete={() => openModalDelete(owner.id)}
                        showDeleteButton={showDeleteButton}
                        showEditPermissionsButton={showEditPermissionsButton}
                        showVisibilityButton={showVisibilityButton}
                        onClickToggleVisibility={() => onClickToggleVisibility(owner.id)}
                    />
                ),
            }))
        },
        [
            openModalDelete,
            onClickEditOwner,
            showActions,
            showDeleteButton,
            showEditPermissionsButton,
            showVisibilityButton,
            onClickToggleVisibility,
            lang,
        ],
    )

    const onClickAddNewOwner = useCallback(() => {
        history.push(`/admin/courses/${courseId}/owners/add`)
    }, [courseId])

    return (
        <>
            <Manager
                withPanel
                headerEnabled
                searchEnabled={false}
                searchOnMount={false}
                managerDataLoader={() => {}}
                screenTitle={lang.owners}
                screenSmallTitle={courseName}
                headerRightToolbar={
                    showEnrolmentButton ? (
                        <Button
                            id="AddOwnerButton"
                            onClick={onClickAddNewOwner}
                            text={lang.ownerAssign}
                            theme='tertiary'
                        />
                    ) : null
                }
                goBackText={lang.returnButton}
                handleGoBack={handleGoBack}
            >
                <ManagerTableBody
                    headers={tableHeaders}
                    rows={addActions(owners)}
                    loading={loading}
                    useSearchMessage={lang.noUsersAssigned}
                    notFoundMessage={lang.noUsersAssigned}
                />
            </Manager>

            <ModalConfirmation
                show={showConfirmationModal}
                confirmTitle={lang.confirmDelete}
                cancelTitle={lang.cancel}
                body={lang.confirmationDeleteOwnerMessage}
                onConfirm={onClickDeleteOwnerHandler}
                onCancel={closeModalConfirmation}
            />
        </>
    )
}

OwnerList.propTypes = {
    courseName: PropTypes.string,
    courseId: PropTypes.string.isRequired,
    owners: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            surname: PropTypes.string,
            email: PropTypes.string,
        }),
    ),
    onClickDeleteOwner: PropTypes.func,
    lang: PropTypes.shape({
        owners: PropTypes.string,
        ownerAssign: PropTypes.string,
        returnButton: PropTypes.string,
        name: PropTypes.string,
        lastname: PropTypes.string,
        nameAndLastName: PropTypes.string,
        educationalRole: PropTypes.string,
        email: PropTypes.string,
        noUsersAssigned: PropTypes.string,
        confirmationDeleteOwnerMessage: PropTypes.string,
        confirmDelete: PropTypes.string,
        cancel: PropTypes.string,
        save: PropTypes.string,
        coursePermissionsModalTitle: PropTypes.string,
        actions: PropTypes.string,
    }).isRequired,
    showEnrolmentButton: PropTypes.bool,
    showDeleteButton: PropTypes.bool,
    showEditPermissionsButton: PropTypes.bool,
    showVisibilityButton: PropTypes.bool,
    handleGoBack: PropTypes.func.isRequired,
    onClickToggleVisibility: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
}

OwnerList.defaultProps = {
    courseName: '',
    owners: [],
    onClickDeleteOwner: () => {},
    showEnrolmentButton: false,
    showDeleteButton: false,
    showEditPermissionsButton: false,
    showVisibilityButton: false,
}

export default OwnerList
