import * as actions from './constants'
import initialState, { studentQuantityInit, courseTypesInit } from './initialState'
import _ from 'lodash'

export const formatChangeVisibility = (state, data) => {
  let activeCourse = _.cloneDeep(state.activeCourse)
  let unit = {}, lesson = {}, topic = {}, block = {}

  switch (data.visibilityRoute) {
    case "units":
      unit = _.find(activeCourse.Units, { 'id': data.unitId })
      unit.visibility = data.visibility
      break
    case "lessons":
      unit = _.find(activeCourse.Units, { 'id': data.unitId })
      lesson = _.find(unit.Lessons, { id: data.lessonId })
      lesson.visibility = data.visibility
      if (data.visibility) {
        lesson.Topics?.map(topic => {
          topic.Blocks.map(block => {
            block.visibility = true
            return block
          })
          topic.visibility = true
          return topic
        })
      }
      break
    case "topics":
      unit = _.find(activeCourse.Units, { 'id': data.unitId })
      lesson = _.find(unit.Lessons, { id: data.lessonId })
      topic = _.find(lesson.Topics, { id: data.topicId })
      topic.visibility = data.visibility
      if (data.visibility) {
        topic.Blocks?.map(block => {
          block.visibility = true
          return block
        })
      }
      break
    case "blocks":
      unit = _.find(activeCourse.Units, { 'id': data.unitId })
      lesson = _.find(unit.Lessons, { id: data.lessonId })
      topic = _.find(lesson.Topics, { id: data.topicId })
      block = _.find(topic.Blocks, { id: data.blockId })
      block.visibility = data.visibility
      break
  }
  return activeCourse
}


const reducers = {

  [actions.LOAD_USER_DATA]: (state, payload) => ({
    ...state,
    activeCourse: initialState.activeCourse,
    allUserCourses: payload.courses,
    isLoaded: true
  }),

  [actions.ALL_COURSES]: (state, payload) => ({
    ...state,
    allCourses: payload,
    isLoaded: true
  }),

  [actions.UPDATE_COURSES]: (state, payload) => ({
    ...state,
    allCourses: payload,
    allPaginatedCourses: {
      ...payload,
      isLoaded: true
    }
  }),

  [actions.LOAD_ACTIVE_COURSE]: (state, payload) => ({
    ...state,
    activeCourse: {
      ...state.activeCourse,
      ...payload
    },
    dragDisable: true,
    dragDisableLessons: true,
    dragDisableTopics: true,
    dragDisableBlocks: true,
    dragGeneralDisable: true,
  }),
  [actions.LOAD_ACTIVE_UNITS]: (state, payload) => ({
    ...state,
    activeCourse: {
      ...state.activeCourse,
      Units: payload
    },
  }),
  [actions.LOAD_ACTIVE_LESSONS]: (state, payload) => {
    const units = [...state.activeCourse.Units]
    units.map(unit => {
      if (unit.id === payload.unitId)
        unit.Lessons = payload.lessons
    })
    return {
        ...state,
        activeCourse: {
            ...state.activeCourse,
            Units: units
        },
    }
},
  [actions.LOAD_ACTIVE_TOPICS]: (state, payload) => {
    const units = [...state.activeCourse.Units]
    units.map(unit => {
      if (unit.id === payload.unitId)
        unit.Lessons.map(lesson => {
          if (lesson.id === payload.lessonId)
            lesson.Topics = payload.topics
        })
    })
    return {
        ...state,
        activeCourse: {
            ...state.activeCourse,
            Units: units
        },
    }
  },
  [actions.LOAD_ACTIVE_BLOCKS]: (state, payload) => {
    const units = [...state.activeCourse.Units]
    units.map(unit => {
      if (unit.id === payload.unitId)
        unit.Lessons.map(lesson => {
          if (lesson.id === payload.lessonId)
            lesson.Topics.map(topic => {
              if(topic.id === payload.topicId)
                topic.Blocks = payload.blocks
            })
        })
    })
    return {
        ...state,
        activeCourse: {
            ...state.activeCourse,
            Units: units
        },
    }
  },

  [actions.UPDATE_ACTIVE_COURSE]: (state, payload) => ({
    ...state,
    activeCourse: payload,
  }),

  [actions.CLEAR_ACTIVE_COURSE]: (state, payload) => ({
    ...state,
    activeCourse: {},
  }),

  [actions.CHANGE_VISIBILITY]: (state, payload) => ({
    ...state,
    activeCourse: formatChangeVisibility(state, payload)
  }),

  [actions.CHANGE_ERROR]: (state, payload) => ({
    ...state,
    errorMessage: payload.errorMessage,
    errorCourse: payload.errorCourse
  }),

  [actions.UPDATE_KEY]: (state, payload) => ({
    ...state,
    titleKey: payload
  }),

  [actions.CLEAR_REDUCER]: () => initialState,

  [actions.RETURN_BUTTON_SHOW]: (state) => ({
    ...state,
    returnButtonShow: true
  }),

  [actions.RETURN_BUTTON_HIDE]: state => ({
    ...state,
    returnButtonShow: false
  }),

  [actions.TOGGLE_DRAG_UNITS]: (state) => ({
    ...state,
    dragDisable: !state.dragDisable,
    dragGeneralDisable: !state.dragGeneralDisable
  }),

  [actions.TOGGLE_DRAG_LESSONS]: (state) => ({
    ...state,
    dragDisableLessons: !state.dragDisableLessons,
    dragGeneralDisable: !state.dragGeneralDisable
  }),

  [actions.TOGGLE_DRAG_TOPICS]: (state) => ({
    ...state,
    dragDisableTopics: !state.dragDisableTopics,
    dragGeneralDisable: !state.dragGeneralDisable,
  }),

  [actions.TOGGLE_DRAG_BLOCKS]: (state) => ({
    ...state,
    dragDisableBlocks: !state.dragDisableBlocks,
    dragGeneralDisable: !state.dragGeneralDisable
  }),

  [actions.SUCCESSFUL_COURSE]: (state, payload) => ({
    ...state,
    alert: { style: 'success', text: payload }
  }),

  [actions.QUERY_ERROR_COURSE]: (state, payload) => ({
    ...state,
    alert: { style: 'danger', text: payload }
  }),

  [actions.CLEAR_COURSE_ALERT]: (state) => ({
    ...state,
    alert: {}
  }),

  [actions.LOAD_USER_ACTIVE_COURSE]: (state, payload) => ({
    ...state,
    activeCourse: payload
  }),

  [actions.ITS_NOT_A_VALID_COURSE]: (state, payload) => ({
    ...state,
    activeCourse: {
      isLoaded: true,
      isValidCourse: false, // todo, este es flag que debo consultar
      errorMessage: payload
    }
  }),

  [actions.ALL_PAGINATED_COURSES]: (state, payload) => ({
    ...state,
    allCourses: (payload.courses) ? payload.courses : payload,
    allPaginatedCourses: {
      ...payload,
      isLoaded: true
    }
  }),

  [actions.LOAD_COURSE_PROGRESS]: (state, payload) => ({
    ...state,
    progressLoaded: true,
    courseProgressData: payload,
  }),

  [actions.CLEAR_COURSE_PROGRESS]: state => ({
    ...state,
    progressLoaded: false,
    courseProgressData: [],
  }),

  [actions.COURSES_WHERE_FOUND]: (state, payload) => ({
    ...state,
    coursesWhereFound: payload
  }),

  [actions.IS_SCHOOLS_COURSE]: (state, payload) => ({
    ...state,
    isSchool: payload
  }),

  [actions.HTTP_REQUEST_LOADING]: (state, payload) => ({
    ...state,
    isLoading: payload
  }),

  [actions.CLEAR_ACTIVE_SEARCH]: (state) => ({
    ...state,
    activeSearch: initialState.activeSearch,
  }),

  [actions.SET_ACTIVE_SEARCH]: (state, payload) => ({
    ...state,
    activeSearch: payload
  }),

  [actions.MODIFY_COURSES]: (state, payload) => ({
    ...state,
    coursesToModify: payload
  }),

  [actions.CLEAR_COURSES_COUNT]: (state) => ({
    ...state,
    coursesToModify: ''
  }),

  [actions.SET_UPDATED_CLONES]: (state, payload) => ({
    ...state,
    updatedClones: payload
  }),

  [actions.CLEAR_UPDATED_CLONES]: (state) => ({
    ...state,
    updatedClones: {}
  }),

  [actions.UNABLE_CLONE_SURVEY]: (state, payload) => ({
    ...state,
    cloneSurveyMsg: payload
  }),

  [actions.CLEAR_SURVEY_MSG]: (state) => ({
    ...state,
    cloneSurveyMsg: null
  }),

  [actions.CHANGE_IS_LOADING]: (state, payload) => ({
    ...state,
    isLoading: payload
  }),

  [actions.SET_ACTIVE_QUIZ_ANSWERS]: (state, payload) => ({
    ...state,
    activeQuizAnswers: payload
  }),

  [actions.SET_FINISH_BUTTON_ACTIVE_QUIZ]: (state, payload) => ({
    ...state,
    finishButtonActiveQuiz: payload
  }),

  [actions.SHOW_QUIZ_RESULTS]: (state, payload) => ({
    ...state,
    showQuizResults: payload,
  }),

  [actions.USER_QUIZ_ANSWERS]: (state, payload) => ({
    ...state,
    userQuizAnswers: {
      ...payload,
      isLoaded: true
    },
    activeQuizAnswers: payload.results
  }),

  [actions.GET_LESSONS_BY_COURSE]: (state, payload) => ({
    ...state,
    lessonsByCourse: payload.data

  }),

  [actions.GET_BLOCKS_BY_LESSON]: (state, payload) => ({
    ...state,
    blocksByLesson: payload.data
  }),

  [actions.CLEAR_QUIZ_REPORT_SEARCH]: (state, payload) => ({
    ...state,
    lessonsByCourse: [],
    blocksByLesson: []

  }),

  [actions.LOAD_QUIZ]: (state, payload) => ({
    ...state,
    quiz: [...state.quiz, { isLoaded: true, Quiz: payload.data, blockId: payload.block_id }],
    activeQuizAnswers: [],
    initialiceQuiz: true,
    activeQuiz: {
      started: true,
      blockId: payload.block_id
    },
  }),

  [actions.SET_ANSWER_QUIZ]: (state, payload) => ({
    ...state,
    initialiceQuiz: payload
  }),

  [actions.QUIZ_RE_DO]: (state, payload) => ({
    ...state,
    quizReDo: payload,
  }),

  [actions.CLEAR_QUIZ]: (state) => ({
    ...state,
    quiz: [],
    activeQuizAnswers: [],
    finishButtonActiveQuiz: false,
    userQuizAnswers: {
      isLoaded: false
    },
    quizReDo: false,
    showStartQuiz: false,
    activeQuiz: {
      started: false,
    },
  }),

  [actions.CLEAR_QUIZ_RE_DO]: (state) => ({
    ...state,
    quizReDo: false
  }),

  [actions.LOAD_ALL_COURSE_TYPES]: (state, payload) => ({
    ...state,
    courseTypes: {
      ...state.courseTypes,
      isLoaded: true,
      items: payload
    }
  }),

  [actions.LOAD_ALL_LESSON_TYPES]: (state, payload) => ({
    ...state,
    lessonTypes: {
      ...state.lessonTypes,
      isLoaded: true,
      items: payload
    }
  }),

  [actions.LOAD_ALL_GROUPS_LESSON]: (state, payload) => ({
    ...state,
    groupsLesson: {
      ...state.groupsLesson,
      isLoaded: true,
      items: payload
    }
  }),

  [actions.CLEAR_ALL_GROUPS_LESSON]: (state) => ({
    ...state,
    groupsLesson: {
      isLoaded: false,
      items: Array()
    }
  }),

  [actions.CLEAR_ALL_COURSE_TYPES]: (state) => ({
    ...state,
    courseTypes: courseTypesInit
  }),

  [actions.SET_STUDENTS_QTY]: (state, payload) => ({
    ...state,
    studentQuantity: {
      ...state.studentQuantity,
      isLoaded: true,
      qty: payload
    }
  }),

  [actions.LOAD_COURSE_CLONES_QTY]: (state, payload) => ({
    ...state,
    courseClonesQty: payload
  }),

  [actions.CLEAR_STUDENT_QTY]: (state, payload) => ({
    ...state,
    studentQuantity: studentQuantityInit()
  }),

  [actions.CHANGE_SAVING_COURSE]: (state, payload) => ({
    ...state,
    savingCourse: payload
  }),

  [actions.CHANGE_SAVING_UNIT]: (state, payload) => ({
    ...state,
    savingUnit: payload
  }),

  [actions.CHANGE_SAVING_LESSON]: (state, payload) => ({
    ...state,
    savingLesson: payload
  }),

  [actions.CHANGE_SAVING_TOPIC]: (state, payload) => ({
    ...state,
    savingTopic: payload
  }),

  [actions.CHANGE_DELETE_TOPIC]: (state, payload) => ({
    ...state,
    deletingTopic: payload
  }),

  [actions.CHANGE_SAVING_BLOCK]: (state, payload) => ({
    ...state,
    savingBlock: payload
  }),

  //TABS

  [actions.CLEAR_COURSE_TABS]: (state, payload) => ({
    ...state,
    activeCourseTabs: [],
    activeTab: []
  }),

  [actions.CLEAR_ACTIVE_TAB]: (state, payload) => ({
    ...state,
    activeTab: []
  }),

  [actions.LOAD_COURSE_TABS]: (state, payload) => ({
    ...state,
    activeCourseTabs: payload
  }),

  [actions.UPDATE_TAB_CONTENT]: (state, payload) => ({
    ...state,
    activeCourseTabs: payload
  }),

  [actions.SET_COURSE_TABS]: (state, payload) => ({
    ...state,
    activeCourseTabs: payload
  }),

  [actions.SET_ACTIVE_TAB]: (state, payload) => ({
    ...state,
    activeTab: payload
  }),



  [actions.SET_ACTIVE_COURSE_TAB]: (state, payload) => ({
    ...state,
    courseTab: payload
  }),
  [actions.CLEAR_ACTIVE_COURSE_TAB]: (state, payload) => ({
    ...state,
    courseTab: {}
  }),


  [actions.SET_EMAIL_ALERT]: (state, payload) => ({
    ...state,
    emailAlert: payload
  }),

  [actions.CLEAR_EMAIL_ALERT]: (state, payload) => ({
    ...state,
    emailAlert: payload
  }),

  [actions.SET_REPORT_DATA]: (state, payload) => ({
    ...state,
    reportData: payload
  }),

  [actions.CLEAR_REPORT_DATA]: (state) => ({
    ...state,
    reportData: []
  }),

  [actions.RELOAD_COURSE_PROGRESS]: (state, payload) => ({
    ...state,
    courseProgressData: payload,
  }),

  [actions.SET_RELOADING]: (state, payload) => ({
    ...state,
    reloadingProgress: payload,
  }),

  [actions.SET_COURSE_REPORT_ERROR]: (state, payload) => ({
    ...state,
    courseReportError: payload,
  }),

  [actions.LOAD_COURSE_STUDENT]: (state, payload) => ({
    ...state,
    courseStudentSearch: payload
  }),

  [actions.CLEAR_SEARCH_STUDENT]: (state) => ({
    ...state,
    courseStudentSearch: []
  })

}

export default reducers