export function courseTypesInit() {
    return { isLoaded: false, items: Array() }
}

function lessonTypesInit() {
    return { isLoaded: false, items: Array() }
}

function groupsLessonInit() {
    return { isLoaded: false, items: Array() }
}

export function studentQuantityInit() {
    return { isLoaded: false, qty: 0 }
}
//todo: LIMPIAR EL ESTADO DE TABS INNECESARIO

const initialState = {
    isLoaded: false,
    allUserCourses: Array(),
    allCourses: { pagesQuantity: 0, rows:[], totalRows: 0, nextPage: null },
    allPaginatedCourses: {
        isLoaded: false
    },
    activeCourse: {},
    courseId: '',
    titleKey: 1,
    errorMessage: "",
    errorCourse: false,
    dragDisable: true,
    dragGeneralDisable: true,
    allBeforeDragDisable: true,
    dragDisableLessons: true,
    dragDisableTopics: true,
    dragDisableBlocks: true,
    returnButtonShow: false,
    alert: {},
    courseProgressData: [],
    progressLoaded: false,
    coursesWhereFound: false,
    isLoading: false,
    activeSearch: {},
    coursesToModify: '',
    updatedClones: {},
    cloneSurveyMsg: null,
    quiz: [],
    activeQuizAnswers: [],
    finishButtonActiveQuiz: false,
    showQuizResults: false,
    userQuizAnswers: {
        isLoaded: false
    },
    initialiceQuiz: false,
    quizReDo: false,
    activeQuiz: {
        started: false
    },
    courseTypes: courseTypesInit(),
    lessonTypes: lessonTypesInit(),
    groupsLesson: groupsLessonInit(),
    studentQuantity: studentQuantityInit(),
    courseClonesQty: 0,
    savingCourse: false,
    savingUnit: false,
    savingLesson: false,
    savingTopic: false,
    deletingTopic: false,
    savingBlock: false,
    isSchool:false,
    descriptionCourseMaxLength: 650,
    //TABS
    activeCourseTabs: [],
    activeTab: [],
    emailAlert: false,
    lessonsByCourse: [],
    blocksByLesson: [],
    reportData: [],
    courseTab:{},
    reloadingProgress: false,
    courseReportError: false,
    courseStudentSearch:[]
}

export default initialState