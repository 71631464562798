import React from 'react'
import PropTypes from 'prop-types'
import InputAutoSugest from 'react-input-autosugest'
import cn from 'classnames'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { ChevronDown, Filter, X, ChevronsDown, ChevronsUp } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { COURSE_ENTITIES_DATA } from '@/constants/courseEntities'

const CourseReportFilters = ({
    users,
    showFilters,
    selectedFilter,
    filterSelectionHandler,
    showFiltersHandler,
    userFound,
    userSearchHandler,
    statusByApprovalRange,
    dataLevel,
    collapseUsersHandler,
    visibleUsers,
    lang,
}) => {
    const isTopicLevel = dataLevel === COURSE_ENTITIES_DATA.TOPICS
    const usersNames = users.map((user) => {
        return user.surname + ' ' + user.name
    })
    const collapseIcon =
        visibleUsers > 0 ? (
            <ChevronsUp />
        ) : (
            <ChevronsDown />
        )

    const buttonFilterClassName = (filter) =>
        cn(
            {
                _report_option_selected: selectedFilter === filter,
            },
            '_report_options_filters'
        )

    return (
        <>
            <div className='_report_search'>
                <InputAutoSugest
                    value={userFound}
                    onChange={(e) => {
                        userSearchHandler(e.target.value)
                    }}
                    data={usersNames}
                    placeholder={` ${lang.searchStudent} ${users.length} ${lang.students}`}
                />
            </div>
            <div
                className={cn(
                    {
                        _report_filter_selected: selectedFilter !== lang.filterByState,
                    },
                    '_report_filter_by_state'
                )}
            >
                <div>
                    <Filter />
                    <Button
                        id='search_course_report'
                        className='_report_general_button'
                        theme='clear'
                        text={selectedFilter}
                        icon={<ChevronDown />}
                        onClick={() => showFiltersHandler()}
                    />
                </div>
            </div>
            <div
                className={cn({
                    _report_filters_status: showFilters,
                    _no_show_element: !showFilters,
                })}
            >
                {Object.keys(statusByApprovalRange).map(status => {
                    return (
                        <div
                            key={`_report_filter_${status}`}
                            className={buttonFilterClassName(lang.states[status])}
                        >
                            <Button
                                id={`apply_filter_${status}`}
                                className='_report_general_button _report_apply_filters'
                                theme='clear'
                                text={lang.states[status]}
                                onClick={() => filterSelectionHandler(lang.states[status], status)}
                            />
                            {selectedFilter === lang.states[status] && (
                                <Button
                                    id={`remove_filter_${status}`}
                                    className='_report_general_button _report_remove_filter'
                                    theme='clear'
                                    onClick={() => filterSelectionHandler(lang.filterByState)}
                                    icon={<X />}
                                />
                            )}
                        </div>
                    )
                })}
            </div>
            <div
                className={cn(
                    {
                        _report_collapse_disabled: isTopicLevel,
                        _report_collapse_enable: !isTopicLevel,
                    },
                    '_report_filter_by_state'
                )}
            >
                {!isTopicLevel ? (
                    <Button
                        id='collapse_course_report'
                        className='_report_general_button'
                        theme='clear'
                        leftIcon
                        text={visibleUsers > 0 ? lang.collapseAll : lang.expandAll}
                        icon={collapseIcon}
                        onClick={() => collapseUsersHandler()}
                    />
                ) : (
                    <div className='_report_block_title'>{lang.topicNameTitle}</div>
                )}
            </div>
            {isTopicLevel && (
                <div className='_report_border_right'>
                    <div className='_report_block_title'>{lang.blockNameTitle}</div>
                    <div className='_report_block_percent'>% {lang.blockCompletePercent}</div>
                </div>
            )}
        </>
    )
}

CourseReportFilters.propTypes = {
    users: PropTypes.array.isRequired,
    showFilters: PropTypes.bool.isRequired,
    selectedFilter: PropTypes.string.isRequired,
    showFiltersHandler: PropTypes.func.isRequired,
    filterSelectionHandler: PropTypes.func.isRequired,
    userFound: PropTypes.string.isRequired,
    userSearchHandler: PropTypes.func.isRequired,
    statusByApprovalRange: PropTypes.object.isRequired,
    dataLevel: PropTypes.string.isRequired,
    collapseUsersHandler: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
}

export default CourseReportFilters
