/**
 * @param {string} paragraph
 * The string to be processed.
 * @param {Number} index
 * The string index between 0 and paragraph length.
 * @returns {string}
 * Returns the cropped string with ellipsis ending.
 */
export const multilineEllipsisCrop = (paragraph, index) => {
    return paragraph.slice(0, index) + '...'
}
