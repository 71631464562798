import {
    ENABLE_SHOW_COURSE_DATES,
    ALLOW_API_CREATE,
    ENABLE_DESCRIPTION,
    PG_ONLY_BASE,
    ENABLE_CLONE,
    ALLOW_API_LESSONS,
    ALLOW_API_ENROLMENT,
    ENABLE_COURSE_INFO,
    ENABLE_STUDENTS,
    ENABLE_SHOW_COMPLETE_DATE,
    ENABLE_ROADMAP_URL,
    ENABLE_EGG,
    ENABLE_CERTIFICATE,
    ENABLE_BLOCK_ISSUE_REPORT,
    ENABLE_API_MANAGEMENT,
    ENABLE_STUDENT_PROFILE_ACCESS,
    ENABLE_BASE_CLONE_VISIBILITY,
    ENABLE_DISCORD_ACCESS,
    ENABLE_ENHANCED_MODULES,
    ALLOW_BASE_COURSE_MODIFY_UNIT_LESSON,
    ALLOW_BASE_COURSE_UPDATE_UNIT_LESSON,
    ALLOW_BASE_COURSE_MODIFY_TOPIC_BLOCK,
    ALLOW_CHILD_COURSE_MODIFY_UNIT_LESSON,
    ALLOW_CHILD_COURSE_UPDATE_UNIT_LESSON,
    ALLOW_CHILD_COURSE_MODIFY_TOPIC_BLOCK,
    DISABLE_LESSON_COMPLETE_DATE,
    DISABLE_LESSON_DATES,
    DISABLE_UNIT_COMPLETE_DATE,
    DISABLE_UNIT_DATES
} from '../../constants/courseRules'

export const allowApiCreate = (courseType) => {
    return courseType.Rules.includes(ALLOW_API_CREATE)
}

export const allowApiLessons = (courseType) => {
    return courseType.Rules.includes(ALLOW_API_LESSONS)
}

export const allowApiEnrolment = (courseType) => {
    return courseType.Rules.includes(ALLOW_API_ENROLMENT)
}

export const enableShowCourseDates = (courseType) => {
    return courseType.Rules.includes(ENABLE_SHOW_COURSE_DATES)
}

export const enableCourseDescription = (courseType) => {
    return courseType.Rules.includes(ENABLE_DESCRIPTION)
}

export const onlyBaseCourse = (courseType) => {
    return courseType.Rules.includes(PG_ONLY_BASE)
}

export const enableClone = (courseType) => {
    return courseType.Rules.includes(ENABLE_CLONE)
}

export const enableCourseInfo = (courseType) => {
    return courseType.Rules.includes(ENABLE_COURSE_INFO)
}

export const enableStudents = (courseType) => {
    return courseType.Rules.includes(ENABLE_STUDENTS)
}

export const enableShowCompleteDate = (courseType) => {
    return courseType.Rules.includes(ENABLE_SHOW_COMPLETE_DATE)
}

export const disableUnitDates = (courseType) => {
    return courseType.Rules.includes(DISABLE_UNIT_DATES)
}

export const disableUnitCompleteDate = (courseType) => {
    return courseType.Rules.includes(DISABLE_UNIT_COMPLETE_DATE)
}

export const disableLessonDates = (courseType) => {
    return courseType.Rules.includes(DISABLE_LESSON_DATES)
}

export const disableLessonCompleteDate = (courseType) => {
    return courseType.Rules.includes(DISABLE_LESSON_COMPLETE_DATE)
}

export const enableRoadmapUrl = (courseType) => {
    return courseType.Rules.includes(ENABLE_ROADMAP_URL)
}

export const enableCourseEgg = (courseType) => {
    return courseType.Rules.includes(ENABLE_EGG)
}

export const enableCertificate = (courseType) => {
    return courseType.Rules.includes(ENABLE_CERTIFICATE)
}

export const enableBlockReport = (courseType) => {
    return courseType.Rules.includes(ENABLE_BLOCK_ISSUE_REPORT)
}

export const enableApiManagement = (courseType) => {
    return courseType.Rules.includes(ENABLE_API_MANAGEMENT)
}

export const enableStudentProfileAccess = (courseType) => {
    return courseType.Rules.includes(ENABLE_STUDENT_PROFILE_ACCESS)
}

export const enableBaseCloneVisibility = (courseType) => {
    return courseType.Rules.includes(ENABLE_BASE_CLONE_VISIBILITY)
}

export const enableDiscordAccess = (courseType) => {
    return courseType.Rules.includes(ENABLE_DISCORD_ACCESS)
}

export const enableEnhancedModules = (courseType) => {
    return courseType.Rules.includes(ENABLE_ENHANCED_MODULES)
}

export const allowBaseCourseModifyUnitLesson = (courseType) => {
    return courseType.Rules.includes(ALLOW_BASE_COURSE_MODIFY_UNIT_LESSON)
}

export const allowBaseCourseUpdateUnitLesson = (courseType) => {
    return courseType.Rules.includes(ALLOW_BASE_COURSE_UPDATE_UNIT_LESSON)
}

export const allowBaseCourseModifyTopicBlock = (courseType) => {
    return courseType.Rules.includes(ALLOW_BASE_COURSE_MODIFY_TOPIC_BLOCK)
}

export const allowChildCourseModifyUnitLesson = (courseType) => {
    return courseType.Rules.includes(ALLOW_CHILD_COURSE_MODIFY_UNIT_LESSON)
}

export const allowChildCourseUpdateUnitLesson = (courseType) => {
    return courseType.Rules.includes(ALLOW_CHILD_COURSE_UPDATE_UNIT_LESSON)
}

export const allowChildCourseModifyTopicBlock = (courseType) => {
    return courseType.Rules.includes(ALLOW_CHILD_COURSE_MODIFY_TOPIC_BLOCK)
}

