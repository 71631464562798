import React from 'react'
import PropTypes from 'prop-types'
import { Circle, CheckCircle } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'

const DefaultOpen = ({ sectionName, onClick, isDefault }) => {
    return (
        <span
            className={`isRightIcon ${isDefault ? 'isActive' : ''}`}
            onClick={e => {
                e.stopPropagation()
                onClick()
            }}
        >
            <Tooltip
                tooltipContent={isDefault ? 'Default file' : 'Mark as default file'}
                tooltipId={`${sectionName}-defaultOpen`}
                tooltipPlacement='right'
            >
                {isDefault ? <CheckCircle /> : <Circle className='filledIcon' />}
            </Tooltip>
        </span>
    )
}

DefaultOpen.propTypes = {
    sectionName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isDefault: PropTypes.bool.isRequired,
}

export default DefaultOpen
