import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { actions as modalActions } from '@/redux/reducers/modalReducer'
import { useEffect } from 'react'
import { useState } from 'react'

/**
 * Este componente actúa como un wrapper de React Bootstrap Modal, se comporta de la misma manera,
 * recibe los mismos componentes, pero su show prop está definida por modalReducer
 * @param {*} props
 */
const ReduxModal = (props) => {
    const newProps = { ...props }
    delete newProps.closeReduxModal

    const [isShowed, setIsShowed] = useState(false)

    const show = newProps.id === newProps.modal.id

    useEffect(() => {
        if (show && !isShowed) {
            if (props.onShow) props.onShow()
            setIsShowed(true)
        }
    }, [show])

    return (
        <Modal
            {...newProps}
            show={show}
            onHide={() => props.closeReduxModal(props.id)}
        >
            {newProps.children}
        </Modal>
    )
}

const mapState = state => ({
    modal: state.modal
})

const mapDispatch = dispatch => ({
    closeReduxModal: (id) => dispatch(modalActions.close())
})

export default connect(mapState, mapDispatch)(ReduxModal)