import React from 'react'
import PropTypes from 'prop-types'
import { getTenantSession } from '@/helpers/tenants/helpers'
import { iframeConfigString } from './config'

import './Playstrap.scss'

const Playstrap = ({ data, id }) => {
    const iframeId = `HTMLBlock-${id}`
    const contentId = `HTMLBlockContent-${id}`

    const { playstrapUrl, lookandfeelUrl } = getTenantSession()

    const enhancedHTML = iframeConfigString(iframeId, contentId, data, playstrapUrl, lookandfeelUrl)

    return (
        <iframe
            id={iframeId}
            srcDoc={enhancedHTML}
            className='html-block-iframe'
            sandbox='allow-forms allow-scripts allow-same-origin allow-modals allow-popups allow-presentation allow-popups-to-escape-sandbox'
            scrolling='no'
            data-testid='playstrapIframe'
        />
    )
}

Playstrap.propTypes = {
    data: PropTypes.string.isRequired,
    id: PropTypes.string,
}

Playstrap.defaultProps = {
    id: '',
}

export default Playstrap
