import React from 'react'
import PropTypes from 'prop-types'
import ChangePayment from './ChangePayment'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getPaymentMethods } from '@/redux/thunks/changePaymentThunks'
import LoadingOrContent from '@/components/LoadingOrContent/LoadingOrContent'
import { usePaymentMethods } from './hooks/usePaymentMethods'
import PaymentsNotFound from './PaymentsNotFound/PaymentsNotFound'

const ChangePaymentContainer = () => {
    const dispatch = useDispatch()
    const loading = useSelector(({ loading }) => loading.changePayment.getPaymentMethods)
    const paymentMethods = usePaymentMethods()
    const { isMobile } = useSelector(({ authReducer }) => authReducer)

    useEffect(() => {
        const fetchPaymentMethods = async () => {
            await dispatch(getPaymentMethods())
        }

        fetchPaymentMethods()
    }, [])

    const onChangePayment = async () => {
        await dispatch(getPaymentMethods())
    }
    return (
        <>
            <div className={isMobile ? 'container-mobile' : ''}>
                <LoadingOrContent loading={loading} size='medium'>
                    {paymentMethods?.length ? (
                        paymentMethods.map((pm) => {
                            return (
                                <ChangePayment
                                    key={pm.id}
                                    {...pm}
                                    onChangePaymentMethod={onChangePayment}
                                />
                            )
                        })
                    ) : (
                        <PaymentsNotFound />
                    )}
                </LoadingOrContent>
            </div>
        </>
    )
}

ChangePaymentContainer.propTypes = {}

export default ChangePaymentContainer
