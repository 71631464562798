import { getTenantLang } from '@/helpers/tenants/helpers'
import httpClient from '../httpClient'

const client = userId => httpClient(`/students/${userId}`)

export const saveBlockProgressByBlock = ({ userId, courseId, blockId, progress, schema }) =>
    client(userId).post(
        `/courses/${courseId}/blocks/${blockId}/block-progresses/${schema}`,
        progress, {
            params: {
                lang: getTenantLang()
            }
        }
    )

export const saveBlockProgressByTopic = ({ userId, courseId, topicId, progresses = {} }) =>
    client(userId).post(`/courses/${courseId}/topics/${topicId}/block-progresses`, progresses)

export const getProgressesByCourse = ({ userId, courseId }) =>
    client(userId).get(`/courses/${courseId}/block-progresses`)
