import React from 'react'
import Spinner from '@digitalhouse-tech/react-lib-ui-explorer/lib/Spinner'
import './CardLoader.scss'

export default ({ show }) => {
    if (show) {
        return (
            <div className='__card_loading'>
                <Spinner className='loadingIcon' />
            </div>
        )
    }
    return null
}
