import { useParams } from 'react-router-dom'
import { buildURL } from './utils'

export default () => {
    const {
        courseId: course_id,
        lessonId: lesson_id,
        topicId: topic_id,
        unitId: unit_id,
    } = useParams()

    const currentParams = {
        course_id,
        lesson_id,
        topic_id,
        unit_id,
    }

    const classroomRedirect = (newParams, { showLastBlock = false } = {}) => location.replace(buildURL({ ...currentParams, ...newParams }), { showLastBlock })

    return {
        classroomRedirect,
        topic_id
    }
}
