import { useCallback, useEffect, useState, useLayoutEffect } from 'react'
import { mappedPages, childFullWidth, findActivePage, visibleArea, getMaxScrollLeft, getChildsLimitFit } from '../helpers'
import { REACHED_RIGHT_LIMIT, REACHED_LEFT_LIMIT } from '../constants'

export const useSlider = (slider, items) => {
    const [childWidth, setChildWidth] = useState(0)
    const [visibleWidth, setVisibleWidth] = useState()
    const [limitsPerPage, setLimitsPerPage] = useState()

    const [activePrev, setActivePrev] = useState(false)
    const [activeNext, setActiveNext] = useState(true)
    const [activePage, setActivePage] = useState(1)
    const [pages, setPages] = useState([])
    const [pagesCount, setPagesCount] = useState(0)

    const [pageSize, setPageSize] = useState([0, 0])

    const calculateSliderPages = useCallback(() => {
        const pages = mappedPages(slider)
        if (!pages.length) setTimeout(() => calculateSliderPages(), 500)

        const childWidth = childFullWidth(slider) 
        setPages(pages)
        setChildWidth(childWidth)
        setPagesCount(pages.length - 1)
        setVisibleWidth(visibleArea(slider))
        setLimitsPerPage(getChildsLimitFit(slider, childWidth))
    })

    const updateIndicators = (scrollLeft) => {
        const { scrollWidth } = slider
        const visibleWidth = visibleArea(slider)
        const maxScrollLeft = getMaxScrollLeft(slider)
        const pages = mappedPages(slider)
        const [actualPage, , remainingSteps] = findActivePage(
            pages,
            scrollLeft,
            visibleWidth,
            scrollWidth,
            'grab',
            maxScrollLeft
        )

        setActivePage(actualPage)
        setActiveNext(remainingSteps !== REACHED_RIGHT_LIMIT)
        setActivePrev(remainingSteps !== REACHED_LEFT_LIMIT)
    }

    useEffect(() => {
        if (slider) calculateSliderPages()
    }, [slider, items, pageSize])

    useLayoutEffect(() => {
        const updatePageSize = () => setPageSize([window.innerWidth, window.innerHeight])
        window.addEventListener('resize', updatePageSize)
        return () => window.removeEventListener('resize', updatePageSize)
    }, [])

    const handleHorizontalScroll = useCallback(
        (move) => {
            slider?.scrollTo({
                top: 0,
                left: move,
                behavior: 'smooth',
            })
        },
        [slider]
    )

    const handleNextNavigation = () => {
        setActivePrev(true)

        const { scrollLeft, scrollWidth } = slider
        const moveRight = scrollLeft + childWidth
        const [actualPage, enabledScrollSide] = findActivePage(
            pages,
            moveRight,
            visibleWidth,
            scrollWidth,
            'right'
        )

        setActivePage(actualPage)
        handleHorizontalScroll(moveRight)
        setActiveNext(enabledScrollSide)
    }

    const handlePrevNavigation = () => {
        setActiveNext(true)

        const { scrollLeft, scrollWidth } = slider
        const moveLeft = scrollLeft - childWidth
        const [actualPage, enabledScrollSide] = findActivePage(
            pages,
            moveLeft,
            visibleWidth,
            scrollWidth,
            'left'
        )

        setActivePage(actualPage)
        handleHorizontalScroll(moveLeft)
        setActivePrev(enabledScrollSide)
    }
    

    const handleChangePage = (index) => {
        const activeScrollPrev = index !== 0
        const activeScrollNext = index + 2 !== pages.length
        handleHorizontalScroll(pages[index])
        setActivePrev(activeScrollPrev)
        setActiveNext(activeScrollNext)
        setActivePage(index + 1)
    }

    return {
        activeNext,
        activePage,
        activePrev,
        handleNextNavigation,
        handlePrevNavigation,
        handleChangePage,
        pagesCount,
        updateIndicators,
        limitsPerPage,
    }
}
