import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { actions as managerActions } from '@/redux/reducers/managerReducer'
import { offset, scrollTo } from '@/helpers/utils'
import ManagerHeader from './header/ManagerHeader'
import ManagerPagination from './footer/Pagination'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'


const Manager = ({
    paginated,
    withPanel,
    children,
    headerEnabled,
    screenTitle,
    screenSmallTitle,
    searcherTitle,
    searcherCleanButtonText,
    searcherSubmitButtonText,
    headerRightToolbar,
    searcherFormBody,
    searchEnabled,
    panelToolbarTitle,
    panelRightToolbar,
    loading,
    managerDataLoader,
    searcherFields,
    goBackText,
    handleGoBack,
    searchOnMount,
    openSearcherOnMount,
    noContent,
    emptyStateComponent,
}) => {
    const dispatch = useDispatch()
    const cardHeadingRef = useRef()

    useEffect(() => {
        if (!paginated) {
            dispatch(managerActions.setSearcherDefaultValues(searcherFields))
            managerDataLoader(searcherFields)
        }
    }, [])

    const getPanel = body => (
        <Panel className="__card_container">
            {panelToolbarTitle !== '' || panelRightToolbar !== null ? (
                <Panel.Heading className='__card_heading _card_table_heading'>
                    {panelToolbarTitle}
                    {panelRightToolbar}
                </Panel.Heading>
            ) : null}

            <Panel.Body className="__card __card--without-margin __fixedHeight _no_border_radius_shadow">
                {body}
            </Panel.Body>
        </Panel>
    )

    const getComponent = () =>
        noContent ? (
            <>{emptyStateComponent}</>
        ) : (
            <>
                {children}
                {paginated && (
                    <ManagerPagination
                        goToPageTop={goToPageTop}
                        loading={loading}
                        onPageChange={managerDataLoader}
                    />
                )}
            </>
        )

    const goToPageTop = () => {
        if (cardHeadingRef) {
            const cardHeadingTop = offset(cardHeadingRef.current).top - 75
            scrollTo(window, cardHeadingTop)
        }
    }

    return (
        <div className="container">
            {headerEnabled && (
                <ManagerHeader
                    goToPageTop={goToPageTop}
                    loading={loading}
                    screenTitle={screenTitle}
                    screenSmallTitle={screenSmallTitle}
                    rightToolbar={headerRightToolbar}
                    searchEnabled={searchEnabled}
                    searcherTitle={searcherTitle}
                    searcherFormBody={searcherFormBody}
                    searcherCleanButtonText={searcherCleanButtonText}
                    searcherSubmitButtonText={searcherSubmitButtonText}
                    searcherSubmitHandler={managerDataLoader}
                    searcherFields={searcherFields}
                    searchOnMount={searchOnMount}
                    openSearcherOnMount={openSearcherOnMount}
                />
            )}
            <div ref={cardHeadingRef}>
                {withPanel ? getPanel(getComponent()) : getComponent()}
            </div>
            <div className='_btn_group pull-right'>
                <Button
                    id='GoBackButton'
                    theme='secondary'
                    onClick={handleGoBack}
                    text={goBackText}
                />
            </div>
        </div>
    )
}

Manager.defaultProps = {
    paginated: false,
    withPanel: false,
    headerEnabled: false,
    screenTitle: '',
    screenSmallTitle: '',
    searcherTitle: '',
    searcherCleanButtonText: '',
    searcherSubmitButtonText: '',
    headerRightToolbar: null,
    loading: false,
    panelRightToolbar: null,
    panelToolbarTitle: '',
    searchEnabled: false,
    searcherFormBody: null,
    searcherFields: {},
    searchOnMount: false,
    openSearcherOnMount: false,
    goBackText: '',
    emptyStateComponent: null,
    noContent: false,
}

Manager.propTypes = {
    paginated: PropTypes.bool,
    children: PropTypes.node.isRequired,
    headerEnabled: PropTypes.bool,
    screenTitle: PropTypes.string,
    screenSmallTitle: PropTypes.string,
    searcherTitle: PropTypes.string,
    searcherCleanButtonText: PropTypes.string,
    searcherSubmitButtonText: PropTypes.string,
    loading: PropTypes.bool,
    managerDataLoader: PropTypes.func.isRequired,
    headerRightToolbar: PropTypes.node,
    panelRightToolbar: PropTypes.node,
    panelToolbarTitle: PropTypes.string,
    searcherFormBody: PropTypes.node,
    searchEnabled: PropTypes.bool,
    withPanel: PropTypes.bool,
    searcherFields: PropTypes.shape(),
    searchOnMount: PropTypes.bool,
    openSearcherOnMount: PropTypes.bool,
    goBackText: PropTypes.string,
    handleGoBack: PropTypes.func.isRequired,
    emptyStateComponent: PropTypes.node,
    noContent: PropTypes.bool,
}

export default Manager
