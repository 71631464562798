import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import useClassroomRedirect from '../useClassroomRedirect'
import { ChevronLeft, ChevronRight } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import './UnitNav.scss'

const UnitNav = ({ prevUnit, nextUnit, unitName }) => {
    const { classroomRedirect } = useClassroomRedirect()

    const handleNextUnit = () => classroomRedirect(prevUnit)
    const handlePrevUnit = () => classroomRedirect(nextUnit)

    const havePrevUnit = prevUnit !== null
    const haveNextUnit = nextUnit !== null

    return (
        <div className="unit-nav-container separator">
            <Button
                disabled={!havePrevUnit}
                id="ContentMapNextUnitButton"
                className={cn('unit-nav-container__prev-button', {
                    'unit-nav-container__prev-button--disabled': !havePrevUnit,
                })}
                icon={
                    <ChevronLeft className="unit-nav-container__prev-button__icon" />
                }
                theme="clear"
                onClick={handleNextUnit}
            />

            <h4 className="unit-nav-container__name">{unitName}</h4>

            <Button
                disabled={!haveNextUnit}
                id="ContentMapPrevUnitButton"
                className={cn('unit-nav-container__next-button', {
                    'unit-nav-container__next-button--disabled': !haveNextUnit,
                })}
                icon={
                    <ChevronRight className="unit-nav-container__next-button__icon" />
                }
                theme="clear"
                onClick={handlePrevUnit}
            />
        </div>
    )
}

UnitNav.propTypes = {
    prevUnit: PropTypes.object,
    nextUnit: PropTypes.object,
    unitName: PropTypes.string,
}

UnitNav.defaultProps = {
    prevUnit: null,
    nextUnit: null,
    unitName: '',
}

export default UnitNav
