export const LOAD_USER_DATA = 'LOAD_USER_DATA'
export const CHANGE_SECTION = 'CHANGE_SECTION'
export const UPDATE_KEY = 'UPDATE_KEY'
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'
export const ALL_COURSES = 'ALL_COURSES'
export const ALL_SCENARIOS = 'ALL_SCENARIOS'

export const ALL_PAGINATED_COURSES = 'ALL_PAGINATED_COURSES'
export const CHANGE_ERROR = 'CHANGE_ERROR'
export const LOGOUT_USER = 'LOGOUT_USER'
export const UPDATE_UNIT_ORDER = 'UPDATE_UNIT_ORDER'
export const QUERY_ERROR_COURSE = 'QUERY_ERROR_COURSE'
export const SUCCESSFUL_COURSE = 'SUCCESSFUL_COURSE'

//COURSE
export const UPDATE_COURSES = 'UPDATE_COURSES'
export const LOAD_ACTIVE_COURSE = 'LOAD_ACTIVE_COURSE'
export const LOAD_COURSE_CLONES_QTY = 'LOAD_COURSE_CLONES_QTY'
export const UPDATE_ACTIVE_COURSE = 'UPDATE_ACTIVE_COURSE'
export const DELETE_COURSE = 'DELETE_COURSE'
export const CLEAR_ACTIVE_COURSE = 'CLEAR_ACTIVE_COURSE'
export const LOAD_ACTIVE_UNITS = 'LOAD_ACTIVE_UNITS'
export const LOAD_ACTIVE_LESSONS = 'LOAD_ACTIVE_LESSONS'
export const LOAD_ACTIVE_TOPICS = 'LOAD_ACTIVE_TOPICS'
export const LOAD_ACTIVE_BLOCKS = 'LOAD_ACTIVE_BLOCKS'


export const TOGGLE_DRAG_UNITS = 'TOGGLE_DRAG_UNITS'
export const TOGGLE_DRAG_LESSONS = 'TOGGLE_DRAG_LESSONS'
export const TOGGLE_DRAG_TOPICS = 'TOGGLE_DRAG_TOPICS'
export const TOGGLE_DRAG_BLOCKS = 'TOGGLE_DRAG_BLOCKS'

export const RETURN_BUTTON_SHOW = 'RETURN_BUTTON_SHOW'
export const RETURN_BUTTON_HIDE = 'RETURN_BUTTON_HIDE'

export const CHANGE_VISIBILITY = 'CHANGE_VISIBILITY'
export const LOAD_USER_ACTIVE_COURSE = 'LOAD_USER_ACTIVE_COURSE'
export const ITS_NOT_A_VALID_COURSE = 'ITS_NOT_A_VALID_COURSE'
export const CLEAR_REDUCER = 'CLEAR_REDUCER'
export const IF_POSSIBLE_UPDATE_EXTRA = 'IF_POSSIBLE_UPDATE_EXTRA'

export const LOAD_COURSE_PROGRESS = 'LOAD_COURSE_PROGRESS'
export const CLEAR_COURSE_PROGRESS = 'CLEAR_COURSE_PROGRESS'

export const COURSES_WHERE_FOUND = 'COURSES_WHERE_FOUND'
export const IS_SCHOOLS_COURSE ='IS_SCHOOLS_COURSE'

export const HTTP_REQUEST_LOADING = 'HTTP_REQUEST_LOADING'
export const CLEAR_ACTIVE_SEARCH = 'CLEAR_ACTIVE_SEARCH'
export const SET_ACTIVE_SEARCH = 'SET_ACTIVE_SEARCH'

export const MODIFY_COURSES = 'MODIFY_COURSES'
export const CLEAR_COURSES_COUNT = 'CLEAR_COURSES_COUNT'

export const SET_UPDATED_CLONES = 'SET_UPDATED_CLONES'
export const CLEAR_UPDATED_CLONES = 'CLEAR_UPDATED_CLONES'
export const UNABLE_CLONE_SURVEY = 'UNABLE_CLONE_SURVEY'
export const CLEAR_SURVEY_MSG = 'CLEAR_SURVEY_MSG'

export const CHANGE_IS_LOADING = 'CHANGE_IS_LOADING '
export const LOAD_ALL_COURSE_TYPES = 'LOAD_ALL_COURSE_TYPES'
export const LOAD_ALL_LESSON_TYPES = 'LOAD_ALL_LESSON_TYPES'
export const LOAD_ALL_GROUPS_LESSON = 'LOAD_ALL_GROUPS_LESSON'
export const CLEAR_ALL_COURSE_TYPES = 'CLEAR_ALL_COURSE_TYPES'
export const CLEAR_ALL_GROUPS_LESSON ='CLEAR_ALL_GROUPS_LESSON'
export const CLEAR_COURSE_ALERT = 'CLEAR_COURSE_ALERT'
export const SET_STUDENTS_QTY = 'SET_STUDENTS_QTY'
export const CLEAR_STUDENT_QTY = 'CLEAR_STUDENT_QTY'

export const LOAD_QUIZ = 'LOAD_QUIZ'
export const SET_ACTIVE_QUIZ_ANSWERS = 'SET_ACTIVE_QUIZ_ANSWERS'
export const SET_FINISH_BUTTON_ACTIVE_QUIZ = 'SET_FINISH_BUTTON_ACTIVE_QUIZ'
export const USER_QUIZ_ANSWERS = 'USER_QUIZ_ANSWERS'
export const SHOW_QUIZ_RESULTS = 'SHOW_QUIZ_RESULTS'
export const CLEAR_QUIZ = 'CLEAR_QUIZ'
export const SET_ANSWER_QUIZ = 'SET_ANSWER_QUIZ'
export const QUIZ_RE_DO = 'QUIZ_RE_DO'
export const CLEAR_QUIZ_RE_DO = 'CLEAR_QUIZ_RE_DO'
export const GET_LESSONS_BY_COURSE = 'GET_LESSONS_BY_COURSE'
export const GET_BLOCKS_BY_LESSON = 'GET_BLOCKS_BY_LESSON'
export const CLEAR_QUIZ_REPORT_SEARCH = 'CLEAR_QUIZ_REPORT_SEARCH'


export const CHANGE_SAVING_COURSE = 'CHANGE_SAVING_COURSE'
export const CHANGE_SAVING_UNIT = 'CHANGE_SAVING_UNIT'
export const CHANGE_SAVING_LESSON = 'CHANGE_SAVING_LESSON'
export const CHANGE_SAVING_TOPIC = 'CHANGE_SAVING_TOPIC'
export const CHANGE_DELETE_TOPIC = 'CHANGE_DELETE_TOPIC'
export const CHANGE_SAVING_BLOCK = 'CHANGE_SAVING_BLOCK'

//TABS
export const UPDATE_TAB_CONTENT = 'UPDATE_TAB_CONTENT'
export const SET_COURSE_TABS = 'SET_COURSE_TABS'
export const LOAD_COURSE_TABS = 'LOAD_COURSE_TABS'
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'

export const SET_ACTIVE_COURSE_TAB = 'SET_ACTIVE_COURSE_TAB'
export const CLEAR_ACTIVE_COURSE_TAB = 'CLEAR_ACTIVE_COURSE_TAB'

export const CLEAR_COURSE_TABS = 'CLEAR_COURSE_TABS'
export const CLEAR_ACTIVE_TAB = 'CLEAR_ACTIVE_TAB'

export const SET_EMAIL_ALERT = 'SET_EMAIL_ALERT'
export const CLEAR_EMAIL_ALERT = 'CLEAR_EMAIL_ALERT'

export const SET_REPORT_DATA = 'GET_REPORT_DATA'
export const CLEAR_REPORT_DATA = 'CLEAR_REPORT_DATA'
export const RELOAD_COURSE_PROGRESS = 'RELOAD_COURSE_PROGRESS'
export const SET_RELOADING = 'SET_RELOADING'
export const SET_COURSE_REPORT_ERROR = 'SET_COURSE_REPORT_ERROR'
// export const DOWNLOAD_QUIZ_REPORT = 'DOWNLOAD_QUIZ_REPORT'
export const LOAD_COURSE_STUDENT = 'LOAD_COURSE_STUDENT'
export const CLEAR_SEARCH_STUDENT = 'CLEAR_SEARCH_STUDENT'