import * as courseService from '../http/services/courseService'
import showNewMessage from '../reducers/_deprecated/globalAlertsReducer/alertsControllers'
import { setLoading } from '../reducers/loadingReducer'
import { setExternalPlatforms } from '../reducers/courseReducer'
import httpHandler, { codes } from '@/helpers/error-handling/httpHandler'

export const loadExternalPlatforms = () => async (dispatch, getState) => {
    dispatch(setLoading.true('course.loadExternalPlatforms'))
    const [error, externalPlatforms] = await courseService.loadExternalPlatforms()
    if(error) {
        const errorMsg = 'Failed to load external platforms' //TODO
        dispatch(setLoading.false('course.loadExternalPlatforms'))
        return httpHandler(error) ({
            [codes.INTERNAL_SERVER_ERROR]: () => {
                showNewMessage({
                    type: 'danger',
                    message: errorMsg,
                    isTemporal: true,
                })
            }
        })
    }

    dispatch(setLoading.false('course.loadExternalPlatforms'))
    dispatch(setExternalPlatforms(externalPlatforms))

}