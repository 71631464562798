import { LOGOUT_USER } from '../_deprecated/authReducer'

const initialState = {
    id: '',
    data: {},
}

export const constants = {
    OPEN_MODAL: '@@modal/OPEN_MODAL',
    CLOSE_MODAL: '@@modal/CLOSE_MODAL',
}

export const actions = {
    open: (id, data = {}) => ({
        type: constants.OPEN_MODAL,
        payload: { id, data }
    }),
    close: () => ({
        type: constants.CLOSE_MODAL
    }),
}

const modalReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case constants.OPEN_MODAL: {
            return payload
        }
        case constants.CLOSE_MODAL: {
            return initialState
        }
        case LOGOUT_USER: {
            return initialState
        }
        default: {
            return state
        }
    }
}

export default modalReducer