import LegalDisclaimer from '@/modules/Auth/LegalDisclaimer'
import SharedLayout from '@/components/Layout/SharedLayout'
import ChangePassword from '@/modules/Auth/ChangePassword'
import Logout from '@/modules/Auth/Logout'
import ChangePaymentContainer from '@/modules/Auth/ChangePayment'
import { STUDENT_GUARD } from '@/constants/guards'

const routes = [
    {
        path: '/legaldisclaimer',
        key: 'Shared - Legal Disclaimer',
        exact: true,
        component: LegalDisclaimer,
        secured: true,
    },
    {
        path: '/payment-method',
        key: 'Shared - Payment Method',
        exact: true,
        component: ChangePaymentContainer,
        secured: true,
        guard: STUDENT_GUARD
    },
    {
        path: '/change-password',
        key: 'Non secured - Change Password',
        exact: true,
        component: ChangePassword,
        secured: true,
    },
    {
        path: '/logOut',
        key: 'Shared - Logout',
        exact: true,
        component: Logout,
        secured: true,
    },
]

export default routes.map((route) => ({
    ...route,
    layout: SharedLayout,
}))
