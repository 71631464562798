'use-strict'

export default (
    base, 
    finder = {}, 
    paginationConfig = {}
) => {
    let route = base
    for (const x in paginationConfig) {
        route += `&${x}=${encodeURIComponent(paginationConfig[x])}`
    }
    for (const x in finder) {
        if (finder[x]) {
            if (Array.isArray(finder[x])) {
                finder[x].map(z => route += `&${x}[]=${encodeURIComponent(z)}`)
            } else {
                route += `&search[]=${x}:${encodeURIComponent(finder[x])}`
            }
        }
    }
    return route
}