import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Skeleton, { SkeletonGroup } from '@/components/Skeleton'

const TopiContentSkeleton = memo(({ isMobile, className }) => (
    <div className={className}>
        {isMobile ? (
            <>
                <SkeletonGroup>
                    <Skeleton circle />
                    <Skeleton width="95%" />
                    <Skeleton circle />
                </SkeletonGroup>
                <Skeleton width="80%" />
            </>
        ) : (
            <Skeleton width="400px" left />
        )}
        <Skeleton height={isMobile ? '40vh' : '400px'} width={isMobile ? '80%' : ''} />
    </div>
))

TopiContentSkeleton.propTypes = {
    isMobile: PropTypes.bool,
    className: PropTypes.string,
}
TopiContentSkeleton.defaultProps = {
    isMobile: false,
    className: '',
}

export default TopiContentSkeleton
