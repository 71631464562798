import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { getNotionPage as getNotionPageService } from '@/redux/http/services/notionService'
import LoadingOrContent from '@/components/LoadingOrContent/LoadingOrContent'
import NotionPage from '@/components/NotionPage/NotionPage'

const NotionBlock = ({ pageId, id }) => {
    const ref = useRef(null);
    const contentId = `NotionBlockContent-${id}`
    const [notionRecordMap, setNotionRecordMap] = useState()
    const [loading, setLoading] = useState(false)

    const getNotionPage = async (pageId) => {
        pageId = pageId.replace(/<[^>]*>?/gm, '')
        setLoading(true)
        try {
            const { data } = await getNotionPageService(pageId)
            
            setNotionRecordMap(data)
            setLoading(false)
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if (!pageId) return;
        
        getNotionPage(pageId)
    }, [pageId])

    useEffect(() => {
        const links = [...(ref.current?.getElementsByTagName('a') ?? [])]
        if (!links.length) return;

        const anchors = links.filter(link => link?.getAttribute('href').slice(0, 1) === '#')
        anchors.forEach(a => a.addEventListener('click', (event) => event.preventDefault()))
    }, [ref, notionRecordMap]);
    
    return (
        <div id={contentId} ref={ref}>
            <LoadingOrContent loading={loading} size='medium'>
                {!!notionRecordMap && <NotionPage record={notionRecordMap} />}
            </LoadingOrContent>
        </div>
    )
}

NotionBlock.propTypes = {
    pageId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
}

export default NotionBlock
