import React from 'react'
import PropTypes from 'prop-types'
import Slider from './Slider'
import BlockNav from './../Navigators/BlockNav'

import './TopicContentMobileLayout.scss'

const TopicContentMobileLayout = ({
    blocksCount,
    blocksComponents,
    currentBlock,
    setCurrentBlock,
    showBottomNav,
    sliderRef,
    stepBlocks,
}) => {
    const handleBeforeChange = (current, next) => {
        setCurrentBlock(stepBlocks[next])
    }

    return (
        <div className='topic-mobile-container'>
            <BlockNav
                sliderRef={sliderRef}
                blocksCount={blocksCount}
                blocks={stepBlocks}
                currentBlock={currentBlock}
                setCurrentBlock={setCurrentBlock}
            />
            <Slider currentSlide={currentBlock?.index}>{blocksComponents}</Slider>
            {showBottomNav && (
                <BlockNav
                    sliderRef={sliderRef}
                    blockName='Block Name'
                    blocksCount={blocksCount}
                    blocks={stepBlocks}
                    currentBlock={currentBlock}
                    setCurrentBlock={setCurrentBlock}
                    onlyArrows
                />
            )}
        </div>
    )
}

TopicContentMobileLayout.propTypes = {
    blocksCount: PropTypes.number.isRequired,
    blocksComponents: PropTypes.array.isRequired,
    currentBlock: PropTypes.object.isRequired,
    setCurrentBlock: PropTypes.func.isRequired,
    showBottomNav: PropTypes.bool.isRequired,
    sliderRef: PropTypes.any.isRequired,
    stepBlocks: PropTypes.array.isRequired,
}

export default TopicContentMobileLayout
