import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import IncompatibleVersionAlert from './IncompatibleVersionAlert'
import DeploymentComingSoonAlert from './DeploymentComingSoonAlert'
import { closeClicked } from '@/redux/reducers/deployment'
import { ALERT_TYPES } from '@/constants/alerts'
import DefaultStaticAlert from './DefaultStaticAlert'

const StaticAlertContainer = ({
    alert,
    lang,
    close,
    clicked,
}) => {

    const { isAuth, type, show, message, showConfirmButton, onConfirm, onCancel } = alert
    const types = {
        [ALERT_TYPES.INCOMPATIBLE]: (
            <IncompatibleVersionAlert
                lang={lang.incompatibleVersion}
                show={show}
                isAuth={isAuth}
                showConfirmButton={showConfirmButton}
                onConfirm={onConfirm}
                onCancel={onCancel}
            />
        ),
        [ALERT_TYPES.DEPLOYMENT_COMING_SOON]: (
            !clicked && <DeploymentComingSoonAlert
                lang={lang.deploymentCommingSoon}
                hour={message}
                show={show}
                onClose={close}
            />
        ),
        [ALERT_TYPES.DEFAULT_ALERT]:(
            <DefaultStaticAlert
                lang={lang.defaultAlert}
                show={show}
                showConfirmButton={showConfirmButton}
                onCancel={onCancel}
            />
        ),
    }
    return <>{types[type]}</>
}

StaticAlertContainer.propTypes = {
    alert: PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.string,
        showConfirmButton: PropTypes.bool,
        confirmButtonText: PropTypes.string,
        showCancelButton: PropTypes.bool,
        cancelButtonText: PropTypes.string,
        show: PropTypes.bool,
        isAuth: PropTypes.bool,
        onConfirm: PropTypes.func,
        onCancel: PropTypes.func,
    }).isRequired,
    lang: PropTypes.any.isRequired,
    close: PropTypes.func.isRequired,
    clicked: PropTypes.bool.isRequired,
}

const mapStateToProps = stores => ({
    alert: stores.deployment.static,
    clicked: stores.deployment.closeClicked,
    lang: stores.langStore.deployNotifications,
})

const mapDispatch = dispatch => ({
    close: () => dispatch(closeClicked())
})

export default withRouter(connect(mapStateToProps, mapDispatch)(StaticAlertContainer))