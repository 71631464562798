import React from 'react'
import { Edit2, Trash2 } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import ModalConfirmation from '../ModalConfirmation'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'

export default class RuleTable extends React.Component {
    constructor(props) {
        super(props)

        this.state = { ruleKey: null }

        this.headers = this.headers.bind(this)
        this.rows = this.rows.bind(this)
    }

    headers() {
        return (
            <thead>
                <tr>
                    <th key="rule_type" className="col-md-2">{this.props.lang.ruleType}</th>
                    <th key="rule_description" className="col-md-8">{this.props.lang.descriptionOfTheRule}</th>
                    <th key="actions" className="col-md-2 text-center">{this.props.lang.actions}</th>
                </tr>
            </thead>
        )
    }

    rows() {
        if (this.props.rules.length) {
            return this.props.rules.map((rule, key) => (
                <tr key={key}>
                    <td><strong>{rule.ruleType}</strong></td>
                    <td><code>{JSON.stringify(rule.config)}</code></td>
                    <td className="text-center">
                        <ButtonGroup>
                            <ButtonRound
                                id="EditButton"
                                theme='primary_dark'
                                onClick={() => {this.props.actions.onEdit(key, rule.ruleType)}}
                                icon={<Edit2 />}
                            />
                            <ButtonRound
                                id="DeleteButton"
                                theme='danger'
                                onClick={() => {
                                    this.modalConfirmation.handleShow()
                                    this.setState({ ...this.state, ruleKey: key })
                                }}
                                icon={<Trash2 />}
                            />
                        </ButtonGroup>
                    </td>
                </tr>
            ))
        }
        return (<tr><td className="text-center" colSpan="3">{this.props.lang.thereIsNot} <strong>{this.props.lang.rules}</strong> {this.props.lang.toShow}</td></tr>)
    }

    render() {
        return (
            <React.Fragment>
                <table className="pdg-table __table">
                    {this.headers()}
                    <tbody>
                        {this.rows()}
                    </tbody>
                </table>
                <ModalConfirmation
                    ref={ref => this.modalConfirmation = ref}
                    confirmTitle={this.props.lang.yes}
                    onConfirm={() => this.props.actions.onDelete(this.state.ruleKey)}
                    cancelTitle={this.props.lang.no}
                    body={this.props.lang.areYouSureYouWantToDeleteThisRule}
                />
            </React.Fragment>
        )
    }
} 