import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './TableGeneralGrade.scss'
import { CheckCircle, XCircle, RotateCw } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { APPROVED_STATUS, DESAPPROVED_STATUS, RECOVERY_STATUS, MINIMUM_TO_APPROVE } from '@/constants/grades'

const getStatus = (grade, recover) => {
    if (recover) return RECOVERY_STATUS
    return grade >= MINIMUM_TO_APPROVE ? APPROVED_STATUS : DESAPPROVED_STATUS
}

const TableGeneralGrade = ({ subjectGrade, recover }) => {
    const getValues = useCallback(() => {
        const status = getStatus(subjectGrade, recover)
        const classes = {
            [APPROVED_STATUS]: 'table-general-grade--success',
            [DESAPPROVED_STATUS]: 'table-general-grade--error',
            [RECOVERY_STATUS]: 'table-general-grade--warn',
        }
        const icons = {
            [APPROVED_STATUS]: CheckCircle,
            [DESAPPROVED_STATUS]: XCircle,
            [RECOVERY_STATUS]: RotateCw,
        }
        return {
            className: cn('table-general-grade', classes[status]),
            icon: icons[status],
        }
    }, [subjectGrade, recover])

    const { className, icon: Icon } = getValues()

    return (
        !subjectGrade ? '-' : (
            <div className={className}>
                <Icon />
                <span className='table-general-grade__text'>{subjectGrade}</span>
            </div>
        )
    )
}

TableGeneralGrade.propTypes = {
    status: PropTypes.oneOf([APPROVED_STATUS, DESAPPROVED_STATUS, RECOVERY_STATUS]),
}

TableGeneralGrade.defaultProps = {
    status: DESAPPROVED_STATUS,
}

export default TableGeneralGrade
