import { cloneDeep } from 'lodash/lang'
import * as blockService from '../http/services/blockService'
import { actions as blockActions } from '../reducers/blockReducer'
import { setLoading } from '../reducers/loadingReducer'
import * as oldVersionCourseActions from '../reducers/_deprecated/courseReducer/actions' // tomado de versiones anteriores
import { findDeep } from '@/helpers/objects'

export const hasAnyProgress = () =>
    async (dispatch, getState) => {
        const { courseId, unitId, lessonId, topicId, blockId } = getState().mamushka
        const [error, hasProgress] = await blockService.hasAnyProgress(courseId, unitId, lessonId, topicId, blockId)
        if (error) return
        dispatch(blockActions.setBlockKeyValue('hasAnyProgress', !!hasProgress))
    }

export const removeBlock = (courseId, unitId, lessonId, topicId, blockId) =>
    async (dispatch, getState) => {

        dispatch(setLoading.true('block.removeBlock'))
        const [error, report] = await blockService.remove(courseId, unitId, lessonId, topicId, blockId)
        dispatch(setLoading.false('block.removeBlock'))

        if (error) return

        const copyOne = { ...getState().courseReducer.activeCourse }
        const unit = copyOne.Units.find(unit => unit.id === unitId)
        const lesson = unit.Lessons.find(lesson => lesson.id === lessonId)
        const topic = lesson.Topics.find(topic => topic.id === topicId)
        const block = topic.Blocks.find(block => block.id === blockId)
        block.isRemoving = true

        dispatch(oldVersionCourseActions.updateActiveCourseAction(copyOne))

        setTimeout(() => {
            const copyTwo = { ...getState().courseReducer.activeCourse }
            const unit = copyTwo.Units.find(unit => unit.id === unitId)
            const lesson = unit.Lessons.find(lesson => lesson.id === lessonId)
            const topic = lesson.Topics.find(topic => topic.id === topicId)
            const index = topic.Blocks.findIndex(block => block.id === blockId)
            topic.Blocks.splice(index, 1)
            dispatch(oldVersionCourseActions.updateActiveCourseAction(copyTwo))
        }, 2000)

        return report
    }

export const saveNewBlock = (block, schemaName) => async (dispatch, getState) => {
    const { mamushka, courseReducer } = getState()
    const { courseId, unitId, lessonId, topicId } = mamushka
    const course = cloneDeep(courseReducer.activeCourse)
    dispatch(setLoading.true('block.createBlock'))

    const [err, savedBlock] = await blockService.create(
        courseId,
        unitId,
        lessonId,
        topicId,
        block,
        schemaName,
    )
    dispatch(setLoading.false('block.createBlock'))
    if (savedBlock) {
        const map = {
            Units: unitId,
            Lessons: lessonId,
            Topics: topicId,
        }
        const topic = findDeep(course, map, 'id')
        topic.Blocks.push(savedBlock)
        dispatch(oldVersionCourseActions.updateActiveCourseAction(course))

        if (savedBlock.report) {
            dispatch(blockActions.setBlockReport(savedBlock.report))
        }
    }
    return [err, savedBlock]
}

export const updateOneBlock = (block, schemaName) => async (dispatch, getState) => {
    const { mamushka, courseReducer } = getState()
    const { courseId, unitId, lessonId, topicId, blockId } = mamushka
    const course = cloneDeep(courseReducer.activeCourse)
    dispatch(setLoading.true('block.updateBlock'))

    const [err, savedBlock] = await blockService.update(
        courseId,
        unitId,
        lessonId,
        topicId,
        blockId,
        block,
        schemaName,
    )
    dispatch(setLoading.false('block.updateBlock'))
    if (savedBlock) {
        const map = {
            Units: unitId,
            Lessons: lessonId,
            Topics: topicId,
        }
        const topic = findDeep(course, map, 'id')
        const blockIndex = topic.Blocks.findIndex(b => b.id === blockId)
        topic.Blocks[blockIndex] = savedBlock
        dispatch(oldVersionCourseActions.updateActiveCourseAction(course))

        if (savedBlock.report) {
            dispatch(blockActions.setBlockReport(savedBlock.report))
        }
    }

    return [err, savedBlock]
}
