import { BLOCK_TYPES } from '@/constants/blocks'

const blockTypes = Object.keys(BLOCK_TYPES).map(key => BLOCK_TYPES[key])

/**
 * Accepts a `block` object and returns its type if it's one of the declared above
 * @param {*} block
 * @returns {}
 */
export const getBlockType = (block = {}) => blockTypes.find(type => block[type.bdField])
