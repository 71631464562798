import React from 'react'
import PropTypes from 'prop-types'
import CircularProgressBar from '@/components/CircularProgressBar'
import PositionPopover from './PositionPopover'
import { TOP, BOTTOM, ARROW_DOWN, ARROW_UP } from './roadMapConstants'

const RoadItem = ({
    moduleNumber,
    lessonsLength,
    percent,
    popovers,
    isMobile,
    lang,
    didCourseFinish,
    didStudentFinish,
}) => {
    const processTitle = () => 
        isMobile ? `M ${moduleNumber}` : `${lang.module} ${moduleNumber}`

    
    


    const processSubtitle = () =>
        lessonsLength ? 
            lessonsLength > 1 ? 
                `${lessonsLength} ${lang.lessons.toLowerCase()}` : 
                `1 ${lang.lessons.slice(0,-1).toLowerCase()}`
            : ``

    const processWidth = () => 
        isMobile ? {width:'40px'} : {width:'70px'}

    const processPopoverProps = (popoverPosition) => {
        const { roadmapPopovers } = lang
        const { 
            lessonPosition, 
            studentPosition,  
            courseCompleted
        } = roadmapPopovers
        return popoverPosition === TOP ?
            {
                text: didStudentFinish() && didCourseFinish() ? 
                    courseCompleted : 
                    studentPosition,
                arrow: ARROW_DOWN
            } :
            {
                text: lessonPosition,
                arrow:ARROW_UP
            }
    }
    
    const processPoint = () => {
        return !didCourseFinish() && 
            !!popovers.find((position)=>position===BOTTOM)
    }

    return (
        <div className="roadItem" style={processWidth()}>
            <CircularProgressBar 
                percent={percent} 
                pointed={processPoint()}
            />
            <p className="moduleTitle">{processTitle()}</p>
            {!isMobile && 
                <p className="moduleSubtitle">{processSubtitle()}</p>}
            {popovers.map( (position,index) => {
                const key = `posPopover${index}`
                const { text, arrow } = processPopoverProps(position)
                return position &&
                    (
                        <PositionPopover 
                            arrowPosition={arrow}
                            text={text} 
                            active={position===TOP}
                            key={key}
                        />
                    )
            })}
        </div>
    )
}

RoadItem.defaultProps = {
    popovers: [],
}

RoadItem.propTypes = {
    moduleNumber: PropTypes.number.isRequired,
    lessonsLength: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
    popovers: PropTypes.array,
    isMobile: PropTypes.bool.isRequired,
    lang: PropTypes.any.isRequired,
    didCourseFinish: PropTypes.func.isRequired,
    didStudentFinish: PropTypes.func.isRequired,
}

export default RoadItem
