import { LOGOUT_USER } from '../_deprecated/authReducer'

const initialState = {
    all: [],
    single: {
        id: '',
        topic_id: '',
        name: '',
        show_name: true,
        order: 0,
        visibility: true,
        extra: 0,
        description: '',
        hasAnyProgress: false
    },
    countCanBeRemoved: 0,
    countCanNotBeRemoved: 0,
    report: null,
}

export const constants = {
    SET_BLOCK_KEY_VALUE: '@@block/SET_BLOCK_KEY_VALUE',
    SET_SINGLE_BLOCK: '@@block/SET_SINGLE_BLOCK',
    SET_COUNT_CAN_BE_REMOVED: '@@block/SET_COUNT_CAN_BE_REMOVED',
    SET_COUNT_CAN_NOT_BE_REMOVED: '@@block/SET_COUNT_CAN_NOT_BE_REMOVED',
    CLEAR_SINGLE_BLOCK: '@@block/CLEAR_SINGLE_BLOCK',
    SET_BLOCK_REPORT: '@@block/SET_BLOCK_REPORT',
    CLEAR_BLOCK_REPORT: '@@block/SET_BLOCK_REPORT',
}

export const actions = {
           setBlockKeyValue: (key, value) => ({
               type: constants.SET_BLOCK_KEY_VALUE,
               key,
               value,
           }),
           setSingleBlock: block => ({
               type: constants.SET_SINGLE_BLOCK,
               block,
           }),
           setCountCanBeRemoved: count => ({
               type: constants.SET_COUNT_CAN_BE_REMOVED,
               count,
           }),
           setCountCanNotBeRemoved: count => ({
               type: constants.SET_COUNT_CAN_NOT_BE_REMOVED,
               count,
           }),
           clearSingleBlock: () => ({
               type: constants.CLEAR_SINGLE_BLOCK,
           }),
           setBlockReport: report => ({
               type: constants.SET_BLOCK_REPORT,
               payload: report,
           }),
           clearBlockReport: () => ({
               type: constants.CLEAR_BLOCK_REPORT,
           }),
       }

const blockReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_BLOCK_KEY_VALUE: {
            return {
                ...state,
                single: {
                    ...state.single,
                    [action.key]: action.value,
                },
            }
        }
        case constants.SET_SINGLE_BLOCK: {
            return {
                ...state,
                single: action.block,
            }
        }
        case constants.SET_COUNT_CAN_BE_REMOVED: {
            return {
                ...state,
                countCanBeRemoved: action.count,
            }
        }
        case constants.SET_COUNT_CAN_NOT_BE_REMOVED: {
            return {
                ...state,
                countCanNotBeRemoved: action.count,
            }
        }
        case constants.CLEAR_SINGLE_BLOCK: {
            return {
                ...state,
                single: initialState.single,
            }
        }
        case LOGOUT_USER: {
            return initialState
        }
        case constants.SET_BLOCK_REPORT: {
            return {
                ...state,
                report: action.payload,
            }
        }
        case constants.CLEAR_BLOCK_REPORT: {
            return {
                ...state,
                report: null,
            }
        }
        default: {
            return state
        }
    }
}

export default blockReducer