import langStates from '@/redux/reducers/_deprecated/langReducer/langStates'

export const COURSE_ENTITIES = {
    'COURSE': langStates.courseEntities.course,
    'UNIT': langStates.courseEntities.unit,
    'LESSON': langStates.courseEntities.lesson,
    'TOPIC': langStates.courseEntities.topic,
    'BLOCK': langStates.courseEntities.block,
    'PROGRESS': langStates.courseEntities.progress,
    'OWNER': langStates.courseEntities.owner,
    'STUDENT': langStates.courseEntities.student,
    'SURVEY': langStates.courseEntities.survey,
    'FILE': langStates.courseEntities.file,
    'EMAIL': langStates.courseEntities.email,
    'TAB': langStates.courseEntities.tab,
    'TOOL': langStates.courseEntities.tool,
}

//Nombres de entidades en progreso del usuario
export const COURSE_ENTITIES_DATA = {
    UNITS: 'Units',
    LESSONS: 'Lessons',
    TOPICS: 'Topics',
    BLOCKS: 'Blocks'
}