import React, { createRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { calculatePaginationRange } from '@/helpers/pagination'
import { offset, scrollTo } from '@/helpers/utils'
import { history } from '@/app/store'
import {
    changeLoadingAction,
    clearActiveUserSearchAction,
    clearAllUserAction,
    clearUsersAlert,
    loadPaginatedUsers,
    setActiveUserSearchAction,
} from '@/redux/reducers/_deprecated/userReducer'
import showNewMessage from '@/redux/reducers/_deprecated/globalAlertsReducer/alertsControllers'
import ModalStudentPassword from '@/components/ModalStudentPassword'
import User from '@/components/UserCard'

import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import LoadingOrContent from '@digitalhouse-tech/react-lib-ui-explorer/lib/LoadingOrContent'
import Paginator from '@digitalhouse-tech/react-lib-ui-explorer/lib/Paginator'
import Searcher from '@digitalhouse-tech/react-lib-ui-explorer/lib/Searcher'
import { CREATE_USER } from '@digitalhouse-tech/ts-lib-store/lib/session-storage/permission'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

export class UserList extends React.Component {

    constructor(props) {
        super(props)
        const { activeSearch } = props

        this.state = {
            currentPage: 1,
            pagesQuantity: 1,
            searchValue: '',
            searchActive: true,
            search: {
                userNameSearch: activeSearch.name ? activeSearch.name : '',
                userSurnameSearch: activeSearch.surname ? activeSearch.surname : '',
                userEmailSearch: activeSearch.email ? activeSearch.email : '',
            },
        }

        this.getFormBody = this.getFormBody.bind(this)
        this.getSearchBar = this.getSearchBar.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.onClickNewUser = this.onClickNewUser.bind(this)
        this.pageChangeHandler = this.pageChangeHandler.bind(this)
        this.searchUser = this.searchUser.bind(this)
        this.showPagination = this.showPagination.bind(this)
        this.showUsers = this.showUsers.bind(this)
        this.usersListRef = createRef()
    }

    static getDerivedStateFromProps(props) {
        const { pages: pagesQuantity } = props.allUsers
        return {
            ...(pagesQuantity && { pagesQuantity }),
        }
    }

    componentDidMount() {
        this.props.changeLoading(false)

        if (!_.isEmpty(this.props.activeSearch)) {
            let searchData = {}

            if (this.props.activeSearch.name && this.props.activeSearch.name !== "") {
                searchData.name = this.props.activeSearch.name
            }
            if (this.props.activeSearch.surname && this.props.activeSearch.surname !== "") {
                searchData.surname = this.props.activeSearch.surname
            }
            if (this.props.activeSearch.email && this.props.activeSearch.email !== "") {
                searchData.email = this.props.activeSearch.email
            }

            this.props.loadPaginatedUsers({ order_by: 'desc' }, this.state.currentPage, searchData.name, searchData.surname, searchData.email)
        } else {
            this.props.clearAllUserAction()
        }
    }

    componentWillUnmount() {
        this.props.clearUsersAlert()
        this.props.clearActiveUserSearch()
    }

    showAlert () {
        if(this.props.alert.text && (this.props.allUsers.isLoaded || this.props.alert.error)) {
            showNewMessage({
                type: this.props.alert.style,
                message: this.props.alert.text.message,
                isTemporal: false,
            })
        }
    }

    async searchUser(e) {
        this.props.clearUsersAlert()
        e.preventDefault()
        const searchData = {}
        const activeSearch = {}

        if (this.state.search.userNameSearch !== "") {
            searchData.name = this.state.search.userNameSearch
            activeSearch.name = this.state.search.userNameSearch
        }
        if (this.state.search.userSurnameSearch !== "") {
            searchData.surname = this.state.search.userSurnameSearch
            activeSearch.surname = this.state.search.userSurnameSearch
        }
        if (this.state.search.userEmailSearch !== "") {
            searchData.email = this.state.search.userEmailSearch
            activeSearch.email = this.state.search.userEmailSearch
        }

        this.props.setActiveUserSearchAction(activeSearch)
        await this.props.loadPaginatedUsers({ order_by: 'desc' }, 1, searchData.name, searchData.surname, searchData.email)
        this.setState({
            ...this.state,
            currentPage: 1
        })

        const usersListTop = offset(this.usersListRef.current).top - 125
        scrollTo(window, usersListTop)
    }

    handleInputChange(key, value) {
        this.setState(state => ({
            ...state,
            search: {
                ...state.search,
                [key]: value
            }
        }))
    }

    onClickNewUser(e, value) {
        e.preventDefault()
        switch (value) {
            case 'Csv':
                return history.push('/admin/users/csv')
            case 'NewUser':
                return history.push('/admin/users/create')
            default:
                return null
        }
    }

    showUsers() {
        let resultUsers = Array()
        let allUsers = this.props.allUsers

        if (allUsers.users && allUsers.isLoaded) {
            if (allUsers.users.length == 0) {
                return <div className="__search_msg_result"><p>{this.props.lang.noResultsFoundMessage}</p></div>
            }
            allUsers.users.map(function (user, key) {
                resultUsers.push(<User {...user} key={key} />)
            })
        } else {
            return <div className="__search_msg_result"><p>{this.props.lang.useSearchMessage}</p></div>
        }
        return resultUsers
    }

    async pageChangeHandler(page) {
        const searchData = {}
        const {
            search: { userNameSearch, userSurnameSearch, userEmailSearch },
        } = this.state
        const { loadPaginatedUsers } = this.props

        if (userNameSearch !== '') {
            searchData.name = userNameSearch
        }
        if (userSurnameSearch !== '') {
            searchData.surname = userSurnameSearch
        }
        if (userEmailSearch !== '') {
            searchData.email = userEmailSearch
        }

        await loadPaginatedUsers(
            { order_by: 'desc' },
            page,
            searchData.name,
            searchData.surname,
            searchData.email
        )
        this.setState({
            currentPage: page,
        })

        const usersListTop = offset(this.usersListRef.current).top - 100
        scrollTo(window, usersListTop)
    }

    showPagination() {
        const { currentPage, pagesQuantity } = this.state
        const { allUsers, isLoading } = this.props
        const { users, isLoaded } = allUsers
        return users && users.length && isLoaded ? (
            <Paginator
                currentPage={currentPage}
                disabled={isLoading}
                onPageChange={(page) => this.pageChangeHandler(page)}
                range={calculatePaginationRange(pagesQuantity)}
                totalPages={pagesQuantity}
            />
        ) : (
            <></>
        )
    }

    getFormBody() {
        const { handleInputChange } = this
        const { lang } = this.props
        const { userEmailSearch, userNameSearch, userSurnameSearch } = this.state.search
        return (
            <Row className='_margin_bottom_25'>
                <Col md="4">
                    <Label text={lang.usersTitle} />
                    <InputText
                        autoFocus
                        deletable
                        id='user.name'
                        onChange={(e) => handleInputChange('userNameSearch', e.currentTarget.value)}
                        onCloseIcon={() => handleInputChange('userNameSearch', '')}
                        placeholder={lang.namePlaceholder}
                        value={userNameSearch}
                    />
                </Col>
                <Col md="4">
                    <div style={{ visibility: 'hidden' }}>
                        <Label text='-' />
                    </div>
                    <InputText
                        deletable
                        id='user.surname'
                        onChange={(e) =>
                            handleInputChange('userSurnameSearch', e.currentTarget.value)
                        }
                        onCloseIcon={() => handleInputChange('userSurnameSearch', '')}
                        placeholder={lang.surnamePlaceholder}
                        value={userSurnameSearch}
                    />
                </Col>
                <Col md="4">
                    <div style={{ visibility: 'hidden' }}>
                        <Label text='-' />
                    </div>
                    <InputText
                        deletable
                        id='user.email'
                        onChange={(e) =>
                            handleInputChange('userEmailSearch', e.currentTarget.value)
                        }
                        onCloseIcon={() => handleInputChange('userEmailSearch', '')}
                        placeholder={lang.searchByEmail}
                        value={userEmailSearch}
                    />
                </Col>
            </Row>
        )
    }

    getSearchBar() {
        const { generalSearcherLang, isLoading, lang } = this.props
        return (
            <Searcher
                actions={[
                    {
                        handler: (e) => this.onClickNewUser(e, 'NewUser'),
                        id: 'CreateNewUser',
                        name: lang.createUser,
                        permission: CREATE_USER(),
                    },
                    {
                        handler: (e) => this.onClickNewUser(e, 'Csv'),
                        id: 'CreateNewUserWithCsv',
                        name: lang.createUserCsv,
                        permission: CREATE_USER(),
                    },
                ]}
                actionsComponentId={'CreateUserListButtonDropdown'}
                actionsComponentText={generalSearcherLang?.create}
                actionsComponentPermission={CREATE_USER()}
                formBody={this.getFormBody()}
                loadingStatus={isLoading}
                searchSubmitHandler={this.searchUser}
                submitText={generalSearcherLang?.submitSearch}
            />
        )
    }

    render() {
        const { isLoading } = this.props
        return (
            <>
                {this.getSearchBar()}
                <div className='container'>
                    {this.showAlert()}
                    <div ref={this.usersListRef}>
                        <LoadingOrContent loading={isLoading} size='medium'>
                            {this.showUsers()}
                        </LoadingOrContent>
                    </div>
                    <div className='__pagination'>{this.showPagination()}</div>

                    <ModalStudentPassword />
                </div>
            </>
        )
    }

}

const mapDispatch = (dispatch) => ({
    changeLoading: (status) => {
        dispatch(changeLoadingAction(status))
    },
    clearActiveUserSearch: () => {
        dispatch(clearActiveUserSearchAction())
    },
    clearAllUserAction: () => {
        dispatch(clearAllUserAction())
    },
    clearUsersAlert: () => {
        dispatch(clearUsersAlert())
    },
    loadPaginatedUsers: (filters, page, name, surname, email) =>
        dispatch(loadPaginatedUsers(filters, page, name, surname, email)),
    setActiveUserSearchAction: (data) => {
        dispatch(setActiveUserSearchAction(data))
    },
})

const mapStateToProps = (state) => {
    return {
        activeSearch: state.userReducer.activeSearch,
        alert:state.userReducer.alert,
        allUsers: state.userReducer.allUsers,
        generalSearcherLang: state.langStore.generalSearcher,
        isLoading: state.userReducer.isLoading,
        lang: state.langStore.addUser,
    }
}

UserList.propTypes = {
    activeSearch: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    allUsers: PropTypes.shape({
        count: PropTypes.number,
        isLoaded: PropTypes.bool,
        pages: PropTypes.number,
        users: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    changeLoading: PropTypes.func.isRequired,
    clearAllUserAction: PropTypes.func.isRequired,
    clearUserAction: PropTypes.func.isRequired,
    clearUsersAlert: PropTypes.func.isRequired,
    generalSearcherLang: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    lang: PropTypes.object.isRequired,
    loadPaginatedUsers: PropTypes.func.isRequired,
    setActiveUserSearchAction: PropTypes.func.isRequired,
}

export default withRouter(connect(mapStateToProps, mapDispatch)(UserList))
