
const initialState = {

    lesson: {
        unitId: null,
        name: '',
        id: null,
        release_date: null,
        due_date: null,
        visibility: true,
        order: null,
        lesson_type: '',
        group_lesson_id: null
    },
    showModalLesson: false,
    showModalPassword: { show: false, userId: null },

}

const SHOW_MODAL_LESSON = 'SHOW_MODAL_LESSON'
const SHOW_MODAL_LESSON_EDITION = 'SHOW_MODAL_LESSON_EDITION'
const CLOSE_MODAL_LESSON = 'CLOSE_MODAL_LESSON'
const UPDATE_MODAL_LESSON = 'UPDATE_MODAL_LESSON'
const CLOSE_MODAL_STUDENT_PASSWORD = 'CLOSE_MODAL_STUDENT_PASSWORD'
const SHOW_MODAL_STUDENT_PASSWORD = 'SHOW_MODAL_STUDENT_PASSWORD'

//LESSON
export const showModalLessonAction = (data) => ({
    type: SHOW_MODAL_LESSON, data
})

export const closeModalLessonAction = () => ({
    type: CLOSE_MODAL_LESSON
})

export const showModalLessonEditAction = (data) => ({
    type: SHOW_MODAL_LESSON_EDITION, data
})

export const updateLessonAction = (data) => ({
    type: UPDATE_MODAL_LESSON, data
})

export const closeModalPasswordAction = () => ({
    type: CLOSE_MODAL_STUDENT_PASSWORD
})

export const showModalPasswordAction = (data) => ({
    type: SHOW_MODAL_STUDENT_PASSWORD, data
})

export default (state = initialState, action) => {

    switch (action.type) {

        case SHOW_MODAL_LESSON:
            return {
                ...state,
                showModalLesson: true,
                lesson: action.data
            }
        case CLOSE_MODAL_LESSON:
            return {
                ...state,
                showModalLesson: false,
                lesson: {}
            }
        case SHOW_MODAL_LESSON_EDITION:
            return {
                ...state,
                showModalLesson: true,
                lesson: action.data
            }
        case UPDATE_MODAL_LESSON:
            return {
                ...state,
                lesson: action.data
            }
        case CLOSE_MODAL_STUDENT_PASSWORD:
            return {
                ...state,
                showModalPassword: { show: false, userId: null }
            }
        case SHOW_MODAL_STUDENT_PASSWORD:
            return {
                ...state,
                showModalPassword: { show: true, userId: action.data }
            }

        default:
            return state
    }
}
