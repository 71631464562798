'use-strict'

const initialState = {
    allStrategies: [],
    canEdit: false, 
}

export const SET_ALL_STRATEGIES = '@@strategy/SET_ALL_STRATEGIES'
export const EDIT_STRATEGY = '@@strategy/EDIT_STRATEGY'

export const setAllStrategies = data => ({
    type: SET_ALL_STRATEGIES,
    payload: data
})

export const editStrategy = (data) => ({
    type: EDIT_STRATEGY,
    payload: data
})

const strategyReducer = (state = initialState, { type, payload }) => {
    const types = {
        [SET_ALL_STRATEGIES]: () => ({
            ...state,
            allStrategies: payload,
        }),
        [EDIT_STRATEGY]: () => ({
            ...state,
            canEdit: payload
        }),
    }
    return types[type] ? types[type]() : state
}

export default strategyReducer