import moment from 'moment'

export const useSchedules = (scheduleLang, schedules, roadmap = false) => {
    const groupSchedules = schedules.reduce((acc, obj) => {
        const key = obj['start_time']
        acc[key] ??= []
        acc[key].push(obj)
        return acc
    }, {})
    const mappedDays = [
        scheduleLang.sun,
        scheduleLang.mon,
        scheduleLang.tue,
        scheduleLang.wed,
        scheduleLang.thu,
        scheduleLang.fri,
        scheduleLang.sat,
    ]   
    const withRoadmap = (h) => roadmap ? ` a ${moment(groupSchedules[h][0].end_time, 'HH:mm:ss').format('HH:mm')} hs` : ``
    return Object.keys(groupSchedules).map((hour) => {
        return (groupSchedules[hour].map(s => mappedDays[s.day]).join('/') 
            + ` ${moment(hour, 'HH:mm:ss').format('HH:mm')} hs${withRoadmap(hour)}`)
    }).join(`${roadmap ? ' - ' : ', '}`)
}