import * as userService from '../http/services/userService'
import { loadPaginatedUsers, clearAllUsers, uploadAvatarAction } from '../reducers/userReducer'
import { setLoading } from '../reducers/loadingReducer'
import httpHandler, { codes } from '@/helpers/error-handling/httpHandler'
import showNewMessage from '../reducers/_deprecated/globalAlertsReducer/alertsControllers'

export const loadUsers = (userDataSearch) => async (dispatch, getState) => {

    const errorSearchMessage = getState().langStore.ownershipAssign.errorSearchMessage

    dispatch(setLoading.true('user.loadUsers'))

    const [error, users] = await userService.loadUsers(userDataSearch)

    dispatch(setLoading.false('user.loadUsers'))

    if (error) {
        return httpHandler(error)({
            [codes.INTERNAL_SERVER_ERROR]: () => {
                showNewMessage({
                    type: 'danger',
                    message: errorSearchMessage,
                    isTemporal: true,
                })
            },
        })
    }

    dispatch(loadPaginatedUsers(users))
}

export const uploadAvatar = (formData, userId) => async (dispatch) => {
    const [error] = await userService.uploadAvatar(formData, userId)
    if (error) {
        return httpHandler(error)({
            [codes.INTERNAL_SERVER_ERROR]: () => {
                showNewMessage({
                    type: 'danger',
                    message: 'Failed to upload avatar.',
                    isTemporal: true,
                })
            },
        })
    }
    dispatch(uploadAvatarAction())
}
