import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getCourseStructureByBlockType } from '@/redux/thunks/dashboardThunks'
import { MODULE, LESSON, TOPIC, BLOCK, DEFAULT_SELECTED } from '../constants/dashboard'

const useCourseSelects = (courseId, blockType) => {
    const dispatch = useDispatch()

    const [modules, setModules] = useState([])
    const [selectedModule, setSelectedModule] = useState(DEFAULT_SELECTED)
    const [lessons, setLessons] = useState([])
    const [selectedLesson, setSelectedLesson] = useState(DEFAULT_SELECTED)
    const [topics, setTopics] = useState([])
    const [selectedTopic, setSelectedTopic] = useState(DEFAULT_SELECTED)
    const [blocks, setBlocks] = useState([])
    const [selectedBlock, setSelectedBlock] = useState(DEFAULT_SELECTED)

    const getCourseInfo = async () => {
        const response = await dispatch(getCourseStructureByBlockType(courseId, blockType))
        setModules(response)
    }

    useEffect(() => {
        getCourseInfo()
    }, [dispatch])

    const onSelectChange = (value, index, key) =>
        ({
            [MODULE]: () => {
                if (index !== selectedModule.index) {
                    const _module = modules.find((m, i) => index === i)
                    setSelectedModule({ ..._module, index })
                    setLessons(_module.lessons)
                    setSelectedLesson([])
                    setSelectedTopic([])
                    setTopics([])
                    setSelectedBlock([])
                    setBlocks([])
                }
            },
            [LESSON]: () => {
                if (index !== selectedLesson.index) {
                    const _lesson = lessons.find((l, i) => index === i)
                    setSelectedLesson({ ..._lesson, index })
                    setTopics(_lesson.topics)
                    setSelectedTopic([])
                    setSelectedBlock([])
                    setBlocks([])
                }
            },
            [TOPIC]: () => {
                if (index !== selectedTopic.index) {
                    const _topic = topics.find((t, i) => index === i)
                    setSelectedTopic({ ..._topic, index })
                    setBlocks(_topic.blocks)
                    setSelectedBlock([])
                }
            },
            [BLOCK]: () => {
                if (index !== selectedBlock.index) {
                    const _block = blocks.find((b, i) => index === i)
                    setSelectedBlock({ ..._block, index })
                }
            },
        }[key]())

    return {
        modules,
        selectedModule,
        lessons,
        selectedLesson,
        topics,
        selectedTopic,
        blocks,
        selectedBlock,
        onSelectChange,
    }
}

export default useCourseSelects
