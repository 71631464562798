import * as env from '@/config/env'

export const login = 'tokens'
export const users = 'users'
export const courses = 'courses'
export const courseTypes = 'courseTypes'
export const lessonTypes = 'lessonTypes'
export const groupsLesson = 'groupsLesson'
export const units = 'units'
export const lessons = 'lessons'
export const topics = 'topics'
export const blocks = 'blocks'
export const new_blocks = 'new_blocks' // provisional
export const blockProgresses = 'block-progresses'
export const exercises = 'exercises'
export const enrolments = 'enrolments'
export const institutes = 'institutes'
export const countries = 'countries'
export const states = 'states'
export const cities = 'cities'
export const levels = 'levels'
export const roles = 'roles'
export const managers = 'managers'
export const progress = 'progress'
export const userprogress = 'userprogress'
export const runners = 'runners'
export const reports = 'reports'
export const quizzes = 'quizzes'
export const tabs = 'tabs'
export const files = 'files'
export const students = 'students'
export const config = 'config'
export const remoteClasses = 'remote-classes'
export const permissions = 'permissions'
export const quizBlocks = 'quiz-blocks'
export const externalPlatforms = 'external-platforms'
export const blockIssueOptions = 'block-issue-options'
