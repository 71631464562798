import React from 'react'

import _ from 'lodash'

import { connect } from 'react-redux'
import { isUndefined } from '@/helpers/utils'

const ReportTabs = ({
    selectedKey,
    setActiveKey,
    tabs,
}) => {

    return (
        <>
            <ul className="nav nav-tabs col-md-9">
                {tabs && tabs.map((tab, index) => {
                    if (isUndefined(tab.show) || tab.show) {
                        return (
                            <li
                                onClick={() => {
                                    setActiveKey(tab.key)
                                }}
                                key={'nav' + index}
                                className={
                                    (selectedKey === tab.key)
                                        ? "active"
                                        : ""
                                }
                            >
                                <a href={'#' + tab.key} onClick={e => e.preventDefault()}>{tab.title}</a>
                            </li>
                        )
                    }
                    return null
                })}
            </ul>
            {tabs.length > 0 &&
                _.find(tabs, { key: selectedKey }).component
            }
        </>
    )
}

const mapStateToProps = state => ({
    lang: state.langStore.reportTabs
})

export default connect(mapStateToProps, null)(ReportTabs)