import React from 'react'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'

export default class Tab extends React.Component {

  constructor(props, context) {
    super(props, context)
    this.state = {}
  }

  render() {
    return (
      <Panel className="__card __fixedHeight">
          <Row>
            <Col lg="9">
              <h3 className="__card_secondary_title">{this.props.tabName}</h3>
            </Col>
            <Col lg="3" className="__card_button_box">
              <div className="_btn_group _margin_top_5">
                {this.props.buttons ? this.props.buttons : ''}
              </div>
            </Col>
          </Row>
        </Panel>
    )
  }
}
