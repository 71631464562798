import React from 'react'
import PropTypes from 'prop-types'
import './Evaluate.scss'

const Evaluate = ({ studentFullName, disclaimer, headerMessage }) => {
    return (
        <div className='evaluate-modal-message'>
            <p>{headerMessage}</p>
            <p className='evaluate-modal-message__student'>{studentFullName}</p>
            <p>{disclaimer}</p>
        </div>
    )
}

Evaluate.propTypes = {
    studentFullName: PropTypes.string,
    disclaimer: PropTypes.string,
    headerMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

Evaluate.defaultProps = {
    studentFullName: '',
    disclaimer: '',
    headerMessage: '',
}

export default Evaluate
