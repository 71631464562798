import { useState, useEffect } from "react"
import { EMPTY_STRING, ERROR, SUCCESS } from "@/constants/formValidations"

const useValidations = ({
    emptyErrors,
    setHasErrors,
    schema,
    setData,
    data,
}) => {
    const [errors, setErrors] = useState(emptyErrors)

    useEffect(() => {
        const valuesToValidate = [data.name, data.description, data.tool_url]
        const emptyValues = valuesToValidate.some((value) => !value || value == '')
        const keys = Object.keys(errors)
        const states = keys.map((key) => errors[key].state)
        const hasErrors = states.some((state) => state !== SUCCESS) || emptyValues
        setHasErrors(hasErrors)
    }, [data, errors, setHasErrors])

    const validate = (value, valueName) => {
        if (!schema[valueName]) {
            return
        }

        let message = schema[valueName](value) || EMPTY_STRING
        const state = message === EMPTY_STRING ? SUCCESS : ERROR

        setErrors({
            ...errors,
            [valueName]: {
                state,
                message,
            },
        })
    }

    const handleInputChange = (value, valueName) => {
        validate(value, valueName)
        setData({
            ...data,
            [valueName]: value,
        })
    }

    return { handleInputChange, errors }
}

export default useValidations
