'use-strict'

export default {
    hasProgress: false,
    all: [],
    paginated: {
        totalRows: 0,
        pagesQuantity: 0,
        rows: [],
        nextPage: null
    },
    finder: {
        ['!name']: '',
        ['tags']: [],
        ['exerciseType']: ''
    },
    paginationConfig: {
        page: 1,
        limit: 10
    },
    allTags: [],
    allExerciseTypes: [],
    workingExercise: {
        id: 0,
        exerciseType: '',
        name: '',
        tags: [],
        infinite: false,
        statement: '',
        rules: [],
        hints: [],
        moral: '',
        files: []
    },
    solvedValidations: {
        hasError: false,
        validations: [],
        output: [],
        joined: '',
    },
    loading: {
        saveExercise: false,
        executeExercise: false,
        executePreview: false,
        loadAllExerciseTypes: false,
        loadAllExercises: false,
        loadSingleExercise: false,
        loadPaginatedExercises: false
    }
}