import React from 'react'
import Skeleton from '@digitalhouse-tech/react-lib-ui-explorer/lib/Skeleton'
import './CourseCardSkeleton.scss'

const CourseCardSkeleton = () => (
    <div className='course-card-skeleton'>
        <Skeleton width='100%' height='189px' />
        <Skeleton width='100%' height='17px' />
        <Skeleton width='100%' height='17px' />
        <Skeleton width='100%' height='50px' />
    </div>
)

export default CourseCardSkeleton
