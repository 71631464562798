import React from 'react'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { connect } from 'react-redux'
import InputPassword from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputPassword'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'

import './ChangePersonalInfo.scss'

const ChangePersonalInfo = ({ user, submit, lang }) => {
    const [inputValues, setInputValues] = useState({
        name: { error: false, message: '', value: '' },
        surname: { error: false, message: '', value: '' },
        password: { error: false, message: '', value: '' },
        confirmPass: { error: false, message: '', value: '' },
    })

    const [capsLockState, setCapsLockState] = useState(false)

    useEffect(() => {
        document.onkeypress = (e) => showCapsLockMsg(e)

        setInputValues({
            name: { error: false, message: '', value: user.name },
            surname: { error: false, message: '', value: user.surname },
            password: { error: false, message: '', value: '' },
            confirmPass: { error: false, message: '', value: '' },
        })
    }, [])

    const onChangeHandler = (key, value) => {
        setInputValues({
            ...inputValues,
            [key]: {
                error: inputValues[key].error,
                message: inputValues[key].message,
                value: value,
            },
        })
    }

    const validateInfo = (data) => {
        let { name, surname, password, verification_password } = data
        let formOk = true

        const errorData = {
            name: { error: false, message: '', value: name },
            surname: { error: false, message: '', value: surname },
            password: { error: false, message: '', value: password },
            confirmPass: { error: false, message: '', value: verification_password },
        }

        if (name === null || name === '') {
            errorData.name.error = true
            errorData.name.message = lang.required
            formOk = false
        }

        if (surname == null || surname == '') {
            errorData.surname.error = true
            errorData.surname.message = lang.required
            formOk = false
        }

        if (password == null || password == '') {
            errorData.password.error = true
            errorData.password.message = lang.requiredPassword
            formOk = false
        }

        if (verification_password != password) {
            errorData.confirmPass.error = true
            errorData.confirmPass.message = lang.passwordNotMatch
            formOk = false
        }
        setInputValues(errorData)
        return formOk
    }

    const validateForm = () => {
        let data = {
            name: inputValues.name.value,
            surname: inputValues.surname.value,
            password: inputValues.password.value,
            verification_password: inputValues.confirmPass.value,
            id: user.id,
        }
        let validation = validateInfo(data)
        if (validation) {
            submit(data)
        }
    }

    const showCapsLockMsg = (e) => {
        e = e ? e : window.event
        let charCode = e.keyCode || e.which

        let isShiftOn = false
        if (e.shiftKey) {
            isShiftOn = e.shiftKey
        } else if (e.modifiers) {
            isShiftOn = !!(e.modifiers & 4)
        }

        if (charCode >= 65 && charCode <= 90 && !isShiftOn) {
            setCapsLockState(true)
        } else {
            setCapsLockState(false)
        }
    }

    return (
        <div data-testid='change-personal-info' className='__login_page _margin_top_30'>
            <div className='__login_container __card _padding_bottom_50'>
                <h3 className='secondary_title text-center _margin_top_2'>{lang.fillYourData}</h3>
                <div>
                    <Row>
                        <Col lg="12" className='_margin_bottom_20'>
                            <Label htmlFor='username' text={lang.name} />
                            <InputText
                                id='username'
                                value={inputValues.name.value}
                                onChange={(e) => onChangeHandler('name', e.target.value)}
                                error={inputValues.name.error}
                                errorMsg={inputValues.name.message}
                            />
                        </Col>
                        <Col lg="12" className='_margin_bottom_20'>
                            <Label htmlFor='usersurname' text={lang.surname} />
                            <InputText
                                id='usersurname'
                                key='usersurname'
                                bsSize='small'
                                type='text'
                                value={inputValues.surname.value}
                                onChange={(e) => onChangeHandler('surname', e.target.value)}
                                error={inputValues.surname.error}
                                errorMsg={inputValues.surname.message}
                            />
                        </Col>
                        <Col lg="12">
                            <Label htmlFor='input-password' text={lang.password} />
                            <InputPassword
                                id='input-password'
                                value={inputValues.password.value}
                                onChange={(e) => onChangeHandler('password', e.target.value)}
                                error={inputValues.password.error}
                                errorMsg={inputValues.password.message}
                            />
                            {capsLockState && (
                                <p className='_input_capslock_msg'>{lang.lockUpperCase}</p>
                            )}
                        </Col>
                        <Col lg="12">
                            <Label htmlFor='password-confirm' text={lang.confirmPassword} />
                            <InputPassword
                                id='password-confirm'
                                value={inputValues.confirmPass.value}
                                onChange={(e) => onChangeHandler('confirmPass', e.target.value)}
                                error={inputValues.confirmPass.error}
                                errorMsg={inputValues.confirmPass.message}
                            />
                            {capsLockState && (
                                <p className='_input_capslock_msg'>{lang.lockUpperCase}</p>
                            )}
                        </Col>
                    </Row>
                    <Row className='centerLoginButton'>
                        <Col lg="12">
                            <Button
                                id='SendButton'
                                theme='primary'
                                block
                                type='submit'
                                onClick={validateForm}
                                text={lang.send}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

ChangePersonalInfo.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        surname: PropTypes.string,
    }),
    submit: PropTypes.func,
}

ChangePersonalInfo.defaultProps = {
    submit: (
        /* istanbul ignore next */ data = {
            name: '',
            surname: '',
            password: '',
            verification_password: '',
            id: '',
        }
    ) => {},
    user: {
        id: '',
        name: '',
        surname: '',
    },
}

const mapStateToProps = (state) => ({
    lang: state.langStore.changePersonalInfo,
})

export default connect(mapStateToProps)(ChangePersonalInfo)
