import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { calculatePaginationRange } from '@/helpers/pagination'
import Paginator from '@digitalhouse-tech/react-lib-ui-explorer/lib/Paginator'
import { actions as managerActions } from '@/redux/reducers/managerReducer'

const ManagerPagination = ({ goToPageTop, loading, onPageChange }) => {
    const dispatch = useDispatch()
    const { currentPage, pagesQuantity } = useSelector((state) => state.manager.pagination)

    const handleSetCurrentPage = async (page) => {
        dispatch(managerActions.setPagintationValue('currentPage', page))
        await onPageChange(page)
        goToPageTop()
    }

    useEffect(() => {
        dispatch(managerActions.clearPaginationValues())
    }, [dispatch])

    return pagesQuantity ? (
        <div className='__pagination'>
            <Paginator
                currentPage={currentPage}
                disabled={loading}
                onPageChange={(page) => handleSetCurrentPage(page)}
                range={calculatePaginationRange(pagesQuantity)}
                totalPages={pagesQuantity}
            />
        </div>
    ) : (
        <></>
    )
}

ManagerPagination.defaultProps = {
    loading: false,
}

ManagerPagination.propTypes = {
    goToPageTop: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onPageChange: PropTypes.func.isRequired,
}

export default ManagerPagination
