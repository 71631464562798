import { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
    updateProgressQuizAnswerText,
    updateProgressQuizAnswerSingleChoice,
    setProgressQuizAnswer,
    setProgressQuizAllAnswers,
    updateProgressQuizAnswerMultipleChoice,
} from '@/redux/reducers/student/quizzes'
import {
    QUESTION_TYPES,
    CHOICE_QUESTION,
    TEXT_CONTROL_QUESTION,
    TEXT_FREE_QUESTIONS,
} from '@/constants/quiz'

const useQuizBlock = (quiz, progressReport, blockId, isCourseExpired) => {
    const dispatch = useDispatch()

    let { attempts, id: quizId } = quiz
    const questions = quiz.questions.sort((a, b) => (a.order > b.order ? 1 : -1))

    const blockProgress = useSelector(({ student }) =>
        student.classroom.progress.blockProgress.find((b) => b.block_id == blockId)
    )

    const lang = useSelector(({ langStore }) => langStore.quizzes)

    const isLoading = useSelector(
        ({ loading }) => loading.classroomProgress && loading.classroomProgress[blockId]
    )

    const [activeQuestionIdx, setActiveQuestionIdx] = useState(0)
    const [showCover, setShowCover] = useState(true)
    const [showReDoButton, setShowReDoButton] = useState(false)
    const [showSendQuizButton, setShowSendQuizButton] = useState(false)
    const [hasQuizComplete, setHasQuizComplete] = useState(false)
    const [isDoingQuiz, setIsDoingQuiz] = useState(false)
    const [isQuizSent, setIsQuizSent] = useState(false)
    const [initialTime, setInitialTime] = useState(null)

    const validateOptionValue = (answer) => {
        return (
            (answer.text && answer.text !== '') ||
            (answer.options && answer.options.some((option) => option.selected))
        )
    }

    const answers =
        useSelector(
            ({ student }) => student.quizzes.find((quiz) => quiz.blockId == blockId)?.answers
        ) || []

    const handleStartQuiz = () => {
        setActiveQuestionIdx(0)
        setShowCover(false)
        setIsDoingQuiz(true)
        setInitialTime(moment().utc().format())

        if (
            studentProgressAnswers.attempts > 0 &&
            answers.length === 1 &&
            !validateOptionValue(answers[0])
        )
            setAnswersFromProgress()
    }

    const setAnswersFromProgress = () => {
        const answersFromProgress = blockProgress?.ProgressQuiz?.answers.map((item) => {
            //buscar todas las opciones del quiz y marcar en true las que habia seleccionado
            const options = questions
                .find((opt) => opt.id === item.questionId)
                ?.choiceQuestion?.options.map((option) => {
                    return {
                        optionId: option.id,
                        selected: item.options.some((el) => el.optionId == option.id),
                    }
                })
            return {
                questionId: item.questionId,
                ...(item.text ? { text: item.text } : {}),
                ...(item.options.length > 0 ? { options } : {}),
            }
        })
        dispatch(setProgressQuizAllAnswers(answersFromProgress, blockId))
    }

    const handleSendQuiz = (answers) => {
        const date = moment().utc().format()
        const timeDifference = moment(date).diff(initialTime)
        const totalTime = moment.utc(timeDifference).format('HH:mm:ss')

        const answersToSend = answers.map((answer) => {
            if (answer.options) {
                return {
                    ...answer,
                    options: answer.options.filter((opt) => opt.selected),
                }
            }
            return answer
        })

        const progress = {
            progress: {
                answers: answersToSend,
                initialTime,
                totalTime,
            },
        }

        progressReport(progress)
        setActiveQuestionIdx(0)
        setIsQuizSent(true)
        setIsDoingQuiz(false)
    }

    const studentProgressAnswers = useMemo(() => {
        const lastVersion = blockProgress?.ProgressQuiz
        return {
            attempts: lastVersion?.attempt || 0,
            answers: !isDoingQuiz ? lastVersion?.answers : answers,
            approvedPercentage: Math.floor(lastVersion?.approvedPercentage) || 0,
            isApproved: lastVersion?.isApproved || false,
            questionsResults:
                questions.map(
                    (item) => lastVersion?.answers.find((x) => x.questionId === item.id)?.isCorrect
                ) || [],
        }
    }, [blockProgress, isDoingQuiz, hasQuizComplete, answers])

    useEffect(() => {
        const studentAttempts = studentProgressAnswers.attempts
        const availableAttempts =
            isCourseExpired ? false : !!attempts ? attempts - studentAttempts > 0 : true
        const availableReDoQuiz = blockProgress && availableAttempts && !isDoingQuiz
        setShowReDoButton(availableReDoQuiz)
        setShowSendQuizButton(availableAttempts && !availableReDoQuiz)
        setHasQuizComplete(!isDoingQuiz && studentAttempts > 0)
        setShowCover(blockProgress === undefined && !isDoingQuiz && !isQuizSent)
    }, [blockProgress, isDoingQuiz, isCourseExpired])

    const completenessPercentage = useMemo(() => {
        const completedAnswers = answers.filter((answer) => {
            return validateOptionValue(answer)
        })
        const percent = (completedAnswers.length * 100) / questions.length
        return Math.floor(percent)
    }, [questions, answers])

    const disabled = useMemo(() => {
        return completenessPercentage < 100 || isLoading
    }, [completenessPercentage, isLoading])

    const activeQuestion = useMemo(() => questions[activeQuestionIdx], [
        questions,
        activeQuestionIdx,
    ])

    const questionType = useMemo(
        () =>
            QUESTION_TYPES.find((type) => Object.keys(activeQuestion).find((key) => key === type)),
        [activeQuestion]
    )

    const answerType = useMemo(() => {
        return {
            [CHOICE_QUESTION]: {
                options: activeQuestion.choiceQuestion?.options?.map((option) => ({
                    optionId: option.id,
                    selected: false,
                })),
            },
            [TEXT_CONTROL_QUESTION]: { text: '' },
            [TEXT_FREE_QUESTIONS]: { text: '' },
        }[questionType]
    }, [questionType, activeQuestion])

    useEffect(() => {
        dispatch(
            setProgressQuizAnswer(
                {
                    questionId: activeQuestion.id,
                    ...answerType,
                },
                quizId,
                blockId
            )
        )
    }, [setProgressQuizAnswer, activeQuestion.id, answerType, blockProgress])

    const onUpdateAnswerText = useCallback(
        (text) => dispatch(updateProgressQuizAnswerText(activeQuestion.id, text, quizId, blockId)),
        [updateProgressQuizAnswerText, activeQuestion.id]
    )

    const onUpdateAnswerSingleChoice = useCallback(
        (optionId) =>
            dispatch(
                updateProgressQuizAnswerSingleChoice(activeQuestion.id, optionId, quizId, blockId)
            ),
        [updateProgressQuizAnswerSingleChoice, activeQuestion.id]
    )

    const onUpdateAnswerMultipleChoice = useCallback(
        (optionId, isSelected) =>
            dispatch(
                updateProgressQuizAnswerMultipleChoice(
                    activeQuestion.id,
                    optionId,
                    isSelected,
                    quizId,
                    blockId
                )
            ),
        [updateProgressQuizAnswerMultipleChoice, activeQuestion.id]
    )

    return {
        activeQuestionIdx,
        activeQuestion,
        onUpdateAnswerText,
        onUpdateAnswerSingleChoice,
        onUpdateAnswerMultipleChoice,
        setActiveQuestionIdx,
        completenessPercentage,
        disabled,
        showCover,
        handleStartQuiz,
        showReDoButton,
        showSendQuizButton,
        studentProgressAnswers,
        hasQuizComplete,
        handleSendQuiz,
        isLoading,
        lang,
    }
}

export default useQuizBlock