import * as courseService from '../http/services/courseService'
import { actions as managerActions } from '../reducers/managerReducer'
import { setLoading } from '../reducers/loadingReducer'
import httpHandler, { codes } from '@/helpers/error-handling/httpHandler'
import showNewMessage from '../reducers/_deprecated/globalAlertsReducer/alertsControllers'

export const createCourseTool = (courseId, tool) => async (dispatch, getState) => {
    const alertMessageLang = getState().langStore.courseInfo.alertMessage
    dispatch(setLoading.true('courseTools.createCourseTool'))
    const [error, createdTool] = await courseService.createCourseTool(courseId, tool)
    dispatch(setLoading.false('courseTools.createCourseTool'))

    if (error) {
        return httpHandler(error)({
            [codes.INTERNAL_SERVER_ERROR]: () => {
                showNewMessage({
                    type: 'danger',
                    message: alertMessageLang.dangerCreateTool,
                    isTemporal: true,
                })
            },
        })
    }

    dispatch(managerActions.addNewRow(createdTool))
}

export const updateCourseTool = (courseId, toolId, tool) => async (dispatch, getState) => {
    const alertMessageLang = getState().langStore.courseInfo.alertMessage
    dispatch(setLoading.true('courseTools.updateCourseTool'))
    const [error, updatedTool] = await courseService.updateCourseTool(courseId, toolId, tool)
    dispatch(setLoading.false('courseTools.updateCourseTool'))

    if (error) {
        return httpHandler(error)({
            [codes.INTERNAL_SERVER_ERROR]: () => {
                showNewMessage({
                    type: 'danger',
                    message: alertMessageLang.dangerUpdateTool,
                    isTemporal: true,
                })
            },
        })
    }

    dispatch(managerActions.updateRow(updatedTool))
}

export const deleteCourseTool = (courseId, toolId) => async (dispatch, getState) => {
    const alertMessageLang = getState().langStore.courseInfo.alertMessage
    dispatch(setLoading.true('courseTools.deleteCourseTool'))
    const [error] = await courseService.deleteCourseTool(courseId, toolId)
    dispatch(setLoading.false('courseTools.deleteCourseTool'))

    if (error) {
        return httpHandler(error)({
            [codes.INTERNAL_SERVER_ERROR]: () => {
                showNewMessage({
                    type: 'danger',
                    message: alertMessageLang.dangerDeleteTool,
                    isTemporal: true,
                })
            },
        })
    }

    dispatch(managerActions.deleteRow(toolId))
}
