import React from 'react'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { X } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import './Alerts.scss'

const Alert = ({ id, type, message, isTemporal, onCloseMessage }) => (
    <div className={`alert alert-${type} alert-dismissible`} role="alert">
        <Button
            id="CloseButton"
            onClick={() => onCloseMessage(id)}
            visible={!isTemporal}
            className="close"
            ariaLabel="Close"
            icon={<X />}
            theme='no-theme'
        />
        {message}
    </div>
)

const generateAlertList = (alerts, onCloseMessage) => alerts.map((alert, index) => <Alert onCloseMessage={onCloseMessage} id={alert.id} type={alert.type} message={alert.message} isTemporal={alert.isTemporal} key={`alertNumber${index}`} />)

const Alerts = ({
    alerts = [],
    onCloseMessage
}) => {
    return (
        <div className="GenericAlert">{generateAlertList(alerts, onCloseMessage)}</div>
    )
}

export default Alerts