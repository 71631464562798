import {
    ADD_MESSAGE,
    DELETE_MESSAGE,
    DELETE_FIRST_MESSAGE
} from './alertsConstants'
let count = 0
export default {
    [ADD_MESSAGE]: (state, payload = {
        type: '',
        message: '',
        isTemporal: false,
    }) => {
        const stateCopy = [...state]
        count++
        stateCopy.push({
            type: payload.type,
            message: payload.message,
            isTemporal: payload.isTemporal,
            id: new Date().getTime() + count
        })
        return stateCopy
    },
    [DELETE_MESSAGE]: (state, payload = 0) => {
        const stateCopy = [...state]
        return stateCopy.filter(alert => alert.id !== payload)
    },
    [DELETE_FIRST_MESSAGE]: (state) => {
        const stateCopy = [...state]
        const messageTemporalFinded = stateCopy.find(item => item.isTemporal)
        return stateCopy.filter((item) => item.id !== messageTemporalFinded.id)
    }
}