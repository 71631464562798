import React from 'react'
import PropTypes from 'prop-types'
import './Slider.scss'

const Slider = ({ children: slides, currentSlide }) => {
    return <div className='slider-container'>{slides[currentSlide]}</div>
}

Slider.propTypes = {
    children: PropTypes.array,
    currentSlide: PropTypes.number,
}

Slider.defaultProps = {
    children: [],
    currentSlide: 0,
}

export default Slider
