import React from 'react'
import PropTypes from 'prop-types'
import { File, ChevronsLeft } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Sections from './Sections'
import { atomAsset } from '@/assets/img/config'

const EditorSidebar = ({
    show,
    sections,
    readOnlySections,
    files,
    activeFile,
    activeSidebar,
    openFile,
    setActiveFile,
    setShowSidebar,
    setActiveSidebar,
    setDefaultFile,
    isAdministration,
    setShowPopUp,
    allowFilesNameChange,
}) => {
    return (
        <>
            <ul className='studioNavigation'>
                <li
                    active={`${activeSidebar === 'files'}`}
                    onClick={() => setActiveSidebar('files')}
                >
                    <File />
                </li>
            </ul>
            <div className='sidebar' show={`${show}`}>
                <div className='header'>
                    <img src={atomAsset('rubiks-cube.png')} alt='cubo' />
                    <span className='title'>PlaygroundCode</span>
                    <div className='back' onClick={() => setShowSidebar(false)}>
                        <ChevronsLeft />
                    </div>
                </div>
                <Sections
                    sections={sections}
                    readOnlySections={readOnlySections}
                    show={activeSidebar === 'files'}
                    files={files}
                    activeFile={activeFile}
                    openFile={openFile}
                    setActiveFile={setActiveFile}
                    setDefaultFile={setDefaultFile}
                    isAdministration={isAdministration}
                    setShowPopUp={setShowPopUp}
                    allowFilesNameChange={allowFilesNameChange}
                />
            </div>
        </>
    )
}

EditorSidebar.defaultProps = {
    navigation: [],
    isAdministration: false,
    defaultFile: {}
}

EditorSidebar.propTypes = {
    show: PropTypes.bool.isRequired,
    navigation: PropTypes.arrayOf(PropTypes.string.isRequired),
    sections: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    readOnlySections: PropTypes.arrayOf(PropTypes.string).isRequired,
    files: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            content: PropTypes.string,
            language: PropTypes.string,
            section: PropTypes.string,
            defaultOpen: PropTypes.bool,
        })
    ).isRequired,
    activeFile: PropTypes.shape({
        name: PropTypes.string,
        content: PropTypes.string,
        language: PropTypes.string,
        section: PropTypes.string,
        defaultOpen: PropTypes.bool,
    }).isRequired,
    activeSidebar: PropTypes.oneOf(['files']).isRequired,
    openFile: PropTypes.func.isRequired,
    setActiveFile: PropTypes.func.isRequired,
    setShowSidebar: PropTypes.func.isRequired,
    setActiveSidebar: PropTypes.func.isRequired,
    setDefaultFile: PropTypes.func.isRequired,
    isAdministration: PropTypes.bool,
    setShowPopUp: PropTypes.func.isRequired,
}

export default EditorSidebar
