import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as modalActions } from '@/redux/reducers/modalReducer'
import { deleteOneTopic, changeDeleteTopicAction } from '@/redux/reducers/_deprecated/courseReducer'

export default () => {
    const dispatch = useDispatch()

    const lang = useSelector(({ langStore }) => langStore.deleteTopic.warningModal)
    const mamushka = useSelector(({ mamushka }) => mamushka)
    const { deletingTopic, activeCourse } = useSelector(({ courseReducer }) => courseReducer)

    const onCancel = () => {
        dispatch(modalActions.close(DELETE_TOPIC_WARNING_MODAL))
    }

    const onConfirm = () => {
        dispatch(deleteOneTopic(mamushka, activeCourse))
        dispatch(changeDeleteTopicAction(true))
    }

    useEffect(() => {
        !deletingTopic && dispatch(modalActions.close())
    }, [deletingTopic])

    return {
        onCancel,
        onConfirm,
        deletingTopic,
        lang,
    }
}
