import React from 'react'
import './GoBackBar.scss'
import { ChevronLeft } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'

const GoBackBar = ({ text, onGoBack }) => (
    <div className="GoBackBar" onClick={onGoBack}>
        <span className="arrow">
            <ChevronLeft />
        </span>
        <span className="text">{text}</span>
    </div>
)

export default GoBackBar