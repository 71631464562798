import React from 'react'
import PropTypes from 'prop-types'
import Hints from './Hints'
import { iconAsset } from '@/assets/img/config'
import './Header.scss'

const Header = ({
    hasProgress,
    completed,
    hints,
    hintsText,
    exerciseName,
    exerciseStatement,
    showName,
}) => {
    let iconComponent = null
    if (hasProgress) {
        iconComponent = (
            <div className='headerIconBox'>
                <img
                    src={completed ? iconAsset('check.svg') : iconAsset('exclamation.svg')}
                    alt='exerciseProgressIcon'
                    className={
                        'mR exerciseHeaderIcon ' +
                        (completed ? 'bg-color--success' : 'bg-color--warning')
                    }
                />
            </div>
        )
    }

    return (
        <div className='row exerciseHeader'>
            <div className='col-md-12'>
                {showName && (
                    <div className='playgroundTitle pg-block-title'>
                        {iconComponent}
                        <h1>{exerciseName}</h1>
                    </div>
                )}
                <div
                    className='statementDescription'
                    dangerouslySetInnerHTML={{
                        __html: exerciseStatement,
                    }}
                />
                <Hints data={hints} text={hintsText} />
            </div>
        </div>
    )
}

Header.defaultProps = {
    hasProgress: false,
    completed: false,
    showName: false,
}

Header.propTypes = {
    hasProgress: PropTypes.bool,
    completed: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    hints: PropTypes.arrayOf(PropTypes.string).isRequired,
    hintsText: PropTypes.string.isRequired,
    exerciseName: PropTypes.string.isRequired,
    exerciseStatement: PropTypes.string.isRequired,
    showName: PropTypes.bool,
}

export default Header
