import React from 'react'
import PropTypes from 'prop-types'
import { isNull } from 'lodash/lang'
import cn from 'classnames'
import { CheckCircle, AlertCircle, Disc } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'
import { iconAsset } from '@/assets/img/config'

import './ProgressIcon.scss'

const Icon = ({ isActive, isApproved, hasProgress, ...props }) => {
    if (isActive || !hasProgress) {
        return <Disc />
    }

    if (isApproved) {
        return <CheckCircle />
    }

    return <AlertCircle />
}

const ProgressIcon = ({ completed, isActive, hasExtra }) => {
    const hasProgress = !isNull(completed);
    const isApproved = hasProgress && completed
    return (
        <Tooltip
            id="ProgressIconTooltip"
            content={hasProgress && !isApproved && 'Hay algo pendiente o para revisar'}
        >
            <span
                className={cn('progress-circle', {
                    'progress-circle--green': hasProgress && isApproved,
                    'progress-circle--orange': hasProgress && !isApproved,
                })}
            >
                <Icon
                    isActive={isActive}
                    isApproved={isApproved}
                    hasProgress={hasProgress}
                    className="progress-circle__icon"
                />
                {hasExtra && (
                    <span className="progress-circle__extra">
                        <img src={iconAsset('plus.svg')} className="__svg_icon" alt="" />
                    </span>
                )}
            </span>
        </Tooltip>
    )
}

ProgressIcon.propTypes = {
    completed: PropTypes.bool,
    isActive: PropTypes.bool,
    hasExtra: PropTypes.bool,
}

ProgressIcon.defaultProps = {
    completed: null,
    isActive: null,
    hasExtra: null,
}

export default ProgressIcon
