import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Collapse from '@digitalhouse-tech/react-lib-ui-explorer/lib/Collapse'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { COURSE_ENTITIES_DATA } from '@/constants/courseEntities'

const CourseReportLoginInfo = ({ users, usersDetail, dataLevel, lang }) => {
    return (
        <>
            <div className="_report_login_info">
                {users.map(user => {
                    const showDetail =
                        dataLevel !== COURSE_ENTITIES_DATA.TOPICS && usersDetail.includes(user.id)

                    const lastLoginDate = user.last_login
                        ? moment(user.last_login).local().format('DD/MM/YY')
                        : '-'

                    const lastLoginTime = user.last_login
                        ? `${moment(user.last_login).local().format('HH:mm')} hs.`
                        : '-'

                    const tooltipText = user.last_login ? lang.lastLogin : lang.withoutLogin

                    return (
                        <div
                            className="_report_slide_control_detail"
                            key={`student_login_info_${user.id}`}
                        >
                            <Button
                                id="student_last_login"
                                theme='clear'
                                onClick={() => {}}
                                tooltipId="student_last_login"
                                tooltipContent={tooltipText}
                                text={lastLoginDate}
                                className="_report_general_button _report_slide_control_item"
                            />
                            <Collapse show={showDetail}>
                                <p>{lastLoginTime}</p>
                            </Collapse>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

CourseReportLoginInfo.propTypes = {
    users: PropTypes.array.isRequired,
    usersDetail: PropTypes.array.isRequired,
    dataLevel: PropTypes.string.isRequired,
    lang: PropTypes.object.isRequired,
}

export default CourseReportLoginInfo
