import {
    REVIEW_STATUS,
    MAX_ACTIVITY_CHARS,
    MAX_EXTERNAL_ACTIVITY_CHARS,
    ACTIVITY_TYPE,
} from './constants'

const { IDLE, PENDING_RETRY } = REVIEW_STATUS
const { INTERNAL, EXTERNAL } = ACTIVITY_TYPE

const isActivityDisabled = (reviewStatus) => reviewStatus !== PENDING_RETRY && reviewStatus !== IDLE

const disabledInputs = (reviewStatus, isCourseExpired) =>
    (reviewStatus !== PENDING_RETRY && reviewStatus !== IDLE) || isCourseExpired

const maxActivityCharacters = (isExternal) =>
    isExternal ? MAX_EXTERNAL_ACTIVITY_CHARS : MAX_ACTIVITY_CHARS
const isSubmitDisabled = (isOwner, reviewStatus, isCourseExpired, isExternal, link, text) =>
    isOwner ||
    disabledInputs(reviewStatus, isCourseExpired) ||
    (isExternal && (!link.length || !text.length)) ||
    (!isExternal && !text.length)

const getActivityType = (isExternal) => (isExternal ? EXTERNAL : INTERNAL)

export default {
    isActivityDisabled,
    disabledInputs,
    maxActivityCharacters,
    isSubmitDisabled,
    getActivityType,
}
