import React from 'react'
import PropTypes from 'prop-types'
import { Video } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { atomAsset } from '@/assets/img/config'
import './WelcomeHeader.scss'

const WelcomeHeader = ({
    lang,
    loggedUserName,
    formatedDate,
    onSiteLessonLink,
    thereIsComingLesson,
    isOnSiteLessonStarted,
    nextOnSiteCourseName,
    goToLiveClass,
}) => {
    return (
        <div
            data-testid='welcome-header'
            className={`welcome-header ${!thereIsComingLesson ? 'welcome-header--centered' : ''}`}
        >
            <div className='welcome-header__greeting'>
                <h1 className='welcome-header__greeting--text'>
                    {lang.welcome} {loggedUserName}!
                </h1>
            </div>

            {thereIsComingLesson && (
                <>
                    <p
                        className={`welcome-header__next-class-msg ${
                            !onSiteLessonLink
                                ? 'welcome-header__next-class-msg--no-lesson-link'
                                : ''
                        }`}
                    >
                        <span className='welcome-header__next-class-msg--text'>
                            {isOnSiteLessonStarted() ? lang.nextClassNowStart : lang.nextClassSoon}
                        </span>
                        <span className='welcome-header__next-class-msg--class-name'>
                            {nextOnSiteCourseName}
                        </span>
                        <span className='welcome-header__next-class-msg--text'>
                            {isOnSiteLessonStarted() ? lang.nextClassNowEnd : formatedDate()}
                        </span>
                    </p>

                    {onSiteLessonLink && (
                        <a
                            className={`welcome-header__link-to-class ${
                                !isOnSiteLessonStarted()
                                    ? 'welcome-header__link-to-class--disabled'
                                    : ''
                            }`}
                            onClick={goToLiveClass}
                        >
                            <Video className='welcome-header__link-to-class--icon' />
                            <p className='welcome-header__link-to-class--text'>
                                {lang.linkToClassText}
                            </p>
                        </a>
                    )}
                </>
            )}
        </div>
    )
}

WelcomeHeader.propTypes = {
    lang: PropTypes.object,
    loggedUserName: PropTypes.string.isRequired,
    formatedDate: PropTypes.string,
    onSiteLessonLink: PropTypes.string,
    thereIsComingLesson: PropTypes.bool,
    isOnSiteLessonStarted: PropTypes.bool,
    nextOnSiteCourseName: PropTypes.string,
    goToLiveClass: PropTypes.func.isRequired,
}

export default WelcomeHeader
