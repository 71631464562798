import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { loadAllStudentEnrolments } from '@/redux/thunks/courseThunks'

const StudentLayout = ({ children }) => {
    const courses = useSelector(({ course }) => course)
    const dispatch = useDispatch()
    useEffect(() => {
        if (courses?.ongoingCourses?.courses?.length) return;
        if (courses?.finishedCourses?.courses?.length) return;

        dispatch(loadAllStudentEnrolments())
    }, [])


    return (
        <div className='student-layout__container'>
            {children}
        </div>
    )
}

StudentLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default StudentLayout
