import React, { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import useHistory from '@/hooks/useHistory'

import OwnerEdit from './OwnerEdit'
import { loadCourse } from '@/redux/thunks/courseThunks'
import {
    updateOwnerCourseRole,
    loadUserOwnershipDetail,
    loadOwnershipRoles,
} from '../../../../../redux/thunks/ownerThunks'
import { clearCourse } from '@/redux/reducers/courseReducer'
import {
    clearSingleOwnerAction,
} from '../../../../../redux/reducers/ownerReducer'
import { loadCoursePermissions } from '@/redux/reducers/_deprecated/authReducer'


const OwnerEditContainer = ({ lang, match, owner, ownershipRoles }) => {
    const courseId = match.params.courseId
    const userId = match.params.userId

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        const loadResources = async () => {
            setLoading(true)
            const { course_type_id } = await dispatch(loadCourse(courseId))
            await dispatch(loadOwnershipRoles(course_type_id))
            await dispatch(loadUserOwnershipDetail(userId, courseId))
            await dispatch(loadCoursePermissions(courseId))
            setLoading(false)
        }

        loadResources()

        return () => {
            dispatch(clearCourse())
            dispatch(clearSingleOwnerAction())
        }
    }, [dispatch, courseId, userId])

    const onClickUpdateOwnershipRole = useCallback(
        async ownershipRoleId => {
            await dispatch(updateOwnerCourseRole(userId, courseId, ownershipRoleId))
            history.goBack()
        },
        [dispatch, history, userId, courseId],
    )

    return (
        <OwnerEdit
            lang={lang}
            owner={owner}
            ownershipRoles={ownershipRoles}
            loading={loading}
            onClickUpdateOwnershipRole={onClickUpdateOwnershipRole}
        />
    )
}
const mapStateToProps = state => ({
    lang: state.langStore.ownershipEdit,
    owner: state.owner.single,
    ownershipRoles: state.owner.ownershipRoles,
})

OwnerEditContainer.propTypes = {
    lang: PropTypes.shape({
        ownerEdit: PropTypes.string.isRequired,
        backButton: PropTypes.string.isRequired,
        roleDetail: PropTypes.string.isRequired,
        educationalRole: PropTypes.string.isRequired,
        saveChanges: PropTypes.string.isRequired,
        selectRole: PropTypes.string.isRequired,
        errorGetOwnerCoursePermissions: PropTypes.string.isRequired,
        errorUpdateOwnerCourseRole: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            courseId: PropTypes.string.isRequired,
            userId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    ownershipRoles: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    owner: PropTypes.object.isRequired,
}

export default withRouter(connect(mapStateToProps)(OwnerEditContainer))
