import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { actions as managerActions } from '@/redux/reducers/managerReducer'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

const StudentListSearcherForm = ({ lang }) => {
    const searcherValues = useSelector(state => state.manager.searcher.search)
    const dispatch = useDispatch()

    const handler = (key, value) =>
        dispatch(managerActions.setSearcherValue(key, value))

    return (
        <Row className='_margin_bottom_25'>
            <Col md="4">
                <Label text={lang.searchName} />
                <InputText
                    id='user.name'
                    value={searcherValues['!name']}
                    onChange={e =>
                        handler('!name', e.target.value)
                    }
                />
            </Col>
            <Col md="4">
                <Label text={lang.searchLastName} />
                <InputText
                    id='user.surname'
                    value={searcherValues['!surname']}
                    onChange={e =>
                        handler('!surname', e.target.value)
                    }
                />
            </Col>
            <Col md="4">
                <Label text={lang.searchEmail} />
                <InputText
                    key='user.email'
                    bsSize='small'
                    type='text'
                    value={searcherValues['!email']}
                    onChange={e =>
                        handler('!email', e.target.value)
                    }
                />
            </Col>
        </Row>
    )
}

StudentListSearcherForm.propTypes = {
    lang: PropTypes.shape({
        searchName: PropTypes.string.isRequired,
        searchLastName: PropTypes.string.isRequired,
        searchEmail: PropTypes.string.isRequired,
    }).isRequired,
}

export default StudentListSearcherForm
