/* eslint-disable no-undef */
import { upload, getUrl, config } from '@digitalhouse-tech/ts-sdk-vaulty'
import { v4 as uuidv4 } from 'uuid'
import { getFileExtension } from '@/helpers/files'
import * as env from '@/config/env'

const fileUploaderService = {
    async uploadFile(file, folder, { apiKey, writerUrl, readerUrl }) {
        try {
            config.set({ apiKey, writerUrl, readerUrl })
            const { type, name } = file
            const uuid = uuidv4()
            const extension = getFileExtension(name)
            const fileToUpload = new File([file], `${uuid}.${extension}`, { type })
            const { data } = await upload(`${env.VAULTY_PATH}/${folder}`, fileToUpload)
            const { asset_name } = data[0]
            const fileUrl = getUrl(`${folder}/${asset_name}`)
            return [fileUrl, extension]
        } catch (e) {
            throw { type: 'request' }
        }
    },
}

export default fileUploaderService
