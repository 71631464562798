import React from 'react'

import { Modal } from 'react-bootstrap'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'

class ModalClonesConfirmation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showByProps: this.props.show,
            showByRef: false,
        }
        this.onCancel = this.onCancel.bind(this)
        this.onConfirm = this.onConfirm.bind(this)
        this.handleShow = this.handleShow.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.show !== state.showByProps) {
            return { showByProps: props.show }
        }
        return {}
    }

    handleShow() {
        this.setState({
            ...this.state,
            showByRef: true,
        })
    }

    onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel()
        }
        this.setState({
            ...this.state,
            showByProps: false,
            showByRef: false,
        })
    }

    onConfirm() {
        this.props.onConfirm()
        this.setState({
            ...this.state,
            showByProps: false,
            showByRef: false,
        })
    }

  render() {
    return (
      <>
        <Modal
          className="__modal __modal_confirmation"
          bsSize="large"
          show={this.state.showByProps || this.state.showByRef}
          onHide={this.onCancel}
          keyboard={false}
          backdrop="static">
          <Modal.Header closeButton className="__modal_header __border_bottom">
            <h3 className="__modal_title">Atención</h3>
          </Modal.Header>

                    <Modal.Body className="__modal_body">
                        <strong>{this.props.body}</strong>
                    </Modal.Body>

          <Modal.Footer className="__modal_footer __border_top">
            <ButtonGroup className='pull-right'>
              <Button
                  id='CancelButton'
                  theme='tertiary'
                  onClick={this.onCancel}
                  text={this.props.cancelTitle}
              />
              <Button
                  id='ConfirmButton'
                  theme='primary'
                  onClick={this.onConfirm}
                  text={this.props.confirmTitle}
              />
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default ModalClonesConfirmation
