import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { history } from '@/app/store'
import { actions as modalActions } from '@/redux/reducers/modalReducer'
import { VISIBILITY_MODAL } from '@/constants/modals'
import ModalConfirmation from '@/components/ModalConfirmation'
import {
    clearTabsAction,
    clearActiveCourseTabAction,
    loadCourseTabs,
    saveTabContent,
    setActiveCourseTabAction
} from '../../../../redux/reducers/_deprecated/courseReducer'
import { loadCoursePermissions} from '@/redux/reducers/_deprecated/authReducer'
import TabsListPresentational from './TabsListPresentational'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import { EDIT_TABS, EDIT_TABS_VISIBILITY } from '@/constants/coursePermissions'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

export class TabsListContainer extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {}

        this.handleTabVisibility = this.handleTabVisibility.bind(this)
        this.handleCloseModalVisibility = this.handleCloseModalVisibility.bind(this)
        this.handleGoBack = this.handleGoBack.bind(this)

    }

    componentDidMount() {
        let courseId = this.props.match.params.courseId
        this.props.loadCoursePermissions(courseId)
        this.props.clearTabsAction()
        this.props.closeModal()
        this.props.loadCourseTabs(courseId)
    }

    handleGoBack() {
        history.push('/admin/courses')
    }

    handleTabVisibility() {
        let courseId = this.props.match.params.courseId
        if (this.props.courseTab) {
            let data = this.props.courseTab

            if (data.visibility) {
                data.visibility = false
            }
            else {
                data.visibility = true
            }
            this.props.saveTabContent(data, courseId)
            this.props.clearActiveCourseTabAction()
            this.props.closeModal()
        }
    }

    handleCloseModalVisibility() {
        this.props.clearActiveCourseTabAction()
        this.props.closeModal()
    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="__main_header">
                        <Row>
                            <Col lg="6">
                                <h1 className="__main_title">{this.props.lang.tabsTitle}</h1>
                            </Col>
                        </Row>
                    </div>

                    <TabsListPresentational
                        courseId={this.props.match.params.courseId}
                        modalVisibility={this.props.modalVisibility}
                        showModalVisibility={this.props.openModal}
                        courseTabs={this.props.activeCourseTabs}
                        setActiveTabAction={this.props.setActiveCourseTabAction}
                        clearActiveCourseTab={this.props.clearActiveCourseTabAction}
                        openModal={this.props.openModal}
                        showEditButton={EDIT_TABS()}
                        showVisibilityButton={EDIT_TABS_VISIBILITY()}
                    />

                    <Row>
                        <Col lg="12">
                            <div className="__main_footer">
                                <ButtonGroup className="pull-right">
                                    <Button
                                        id="handleGoBackButton"
                                        onClick={this.handleGoBack}
                                        text={this.props.lang.goBackButton}
                                        theme='secondary'
                                    />
                                </ButtonGroup>
                            </div>
                        </Col>
                    </Row>
                </div>

                <ModalConfirmation
                    show={this.props.modalReducer.id === VISIBILITY_MODAL}
                    confirmTitle={this.props.lang.confirmTitle}
                    cancelTitle={this.props.lang.cancelTitle}
                    onConfirm={() => {
                        this.handleTabVisibility()
                    }}
                    onCancel={() => {
                        this.handleCloseModalVisibility()
                    }}
                    body={this.props.lang.modalBody}
                />
            </React.Fragment>
        )
    }
}

const mapDispatch = (dispatch) => ({
    closeModal: () => dispatch(modalActions.close()),
    openModal: (id, data) => dispatch(modalActions.open(id, data)),
    loadCourseTabs: (courseId) => {
        dispatch(loadCourseTabs(courseId))
    },
    saveTabContent: (data, courseId) => {
        dispatch(saveTabContent(data, courseId))
    },
    setActiveCourseTabAction: (data) => {
        dispatch(setActiveCourseTabAction(data))
    },
    clearTabsAction: () => {
        dispatch(clearTabsAction())
    },
    clearActiveCourseTabAction: () => {
        dispatch(clearActiveCourseTabAction())
    },
    loadCoursePermissions: courseId => {
        dispatch(loadCoursePermissions(courseId))
    },
})

const mapStateToProps = (state) => {
    return {
        modalReducer: state.modal,
        activeCourseTabs: state.courseReducer.activeCourseTabs,
        courseTab: state.courseReducer.courseTab,
        lang: state.langStore.tabsListContainer
    }
}

export default withRouter(connect(mapStateToProps, mapDispatch)(TabsListContainer))