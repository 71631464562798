import { useSelector } from 'react-redux'

export default (progressAnswers = [], quizId, blockId) => {
    const quizAnswers =
        useSelector(
            ({ student }) =>
                student.quizzes.find((quiz) => quiz.quizId === quizId && quiz.blockId === blockId)
                    ?.answers
        ) || []

    const getAnswer = (anwserId, quizComplete) => {
        let answer = quizAnswers.find((answer) => answer.questionId === anwserId)
        if (
            !answer ||
            answer.text === '' ||
            answer.options?.some((option) => !option.selected) ||
            quizComplete
        )
            answer = progressAnswers.find((answer) => answer.questionId === anwserId)
        return answer
    }

    const validateInputValue = (anwserId, quizComplete) => {
        const answer = getAnswer(anwserId, quizComplete)
        return (
            answer &&
            ((answer?.text && answer.text !== '') ||
                (answer?.options && answer.options?.some((option) => option.selected)))
        )
    }

    return {
        getAnswer,
        quizAnswers,
        validateInputValue,
    }
}
