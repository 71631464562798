import React from 'react'
import PropTypes from 'prop-types'
import './TableStudentItem.scss'
import StudentItem from '@digitalhouse-tech/react-lib-ui-explorer/lib/StudentItem'

const TableStudentItem = ({ user }) => {
    return (
        <div className='table-student-item'>
            <StudentItem ellipsis email={user.email} fullName={user.name} />
        </div>
    )
}

TableStudentItem.propTypes = {
    user: PropTypes.object,
}

export default TableStudentItem
