import React from 'react'
import PropTypes from 'prop-types'

const Validations = ({
    hasError,
    validations,
    errorsText,
    reference,
    throws,
}) => {
    return (
        <div
            className='playGroundResults danger top'
            style={{
                display: hasError ? 'block' : 'none',
            }}
            ref={reference}
        >
            <h3>{errorsText}:</h3>
            <ul>
                {validations.map((val, i) => {
                    if (Object.keys(val).length) {
                        return <li key={`validation-${i}`}>{val.msg}</li>
                    }
                })}
                {throws.map((elem, i) => (
                    <li key={`throw-${i}`}>{elem}</li>
                ))}
            </ul>
        </div>
    )
}

Validations.defaultProps = {
    throws: [],
}

Validations.propTypes = {
    hasError: PropTypes.bool.isRequired,
    validations: PropTypes.arrayOf(
        PropTypes.shape({
            error: PropTypes.bool.isRequired,
            msg: PropTypes.string.isRequired,
        })
    ).isRequired,
    errorsText: PropTypes.string.isRequired,
    reference: PropTypes.shape({
        current: PropTypes.shape(),
    }).isRequired,
    throws: PropTypes.arrayOf(PropTypes.shape()),
}

export default Validations
