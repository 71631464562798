import React, { useEffect } from 'react'
import { ChevronDown, ChevronUp } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import getIcon from '../icons'
import File from './File'

const Section = ({
    i,
    section,
    files,
    readOnlySections,
    activeFile,
    openFile,
    setActiveFile,
    setDefaultFile,
    isAdministration,
    setShowPopUp,
    allowFilesNameChange,
    onChangeSectionStatus,
}) => {

    return (
        <li key={`section${i}`} show={`${section.toggle}`}>
            <span className='sectionName'>
                {section.name}
                <span
                    className='arrow'
                    onClick={() => onChangeSectionStatus(section)}
                >
                    {section.toggle ? (
                        <ChevronDown size={14} />
                    ) : (
                        <ChevronUp size={14} />
                    )}
                </span>
            </span>
            <ul>
                {files.map((file, i) => {
                    const icon = getIcon(file.name)
                    return (
                        <File
                            key={`file-${i}`}
                            index={i}
                            file={file}
                            icon={icon}
                            section={section}
                            readOnlySections={readOnlySections}
                            activeFile={activeFile}
                            openFile={openFile}
                            setActiveFile={setActiveFile}
                            setDefaultFile={setDefaultFile}
                            isAdministration={isAdministration}
                            setShowPopUp={setShowPopUp}
                            allowFilesNameChange={allowFilesNameChange}
                        />
                    )
                })}
            </ul>
        </li>
    )
}

export default Section
