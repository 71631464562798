import { NativeAuthStrategy } from './authStrategies'
import {
    getTenantSessionStorage,
    setTenantSessionStorage,
} from '@digitalhouse-tech/ts-lib-store/lib/session-storage'
import Cookies from 'js-cookie'
import { COOKIE_LANGUAGE, LOCALES, PREFS_PASSWORD } from './constants';

export const baseDecrypt = (encryptedText, password) => {
    let plainText = ''
  
    if (!encryptedText) return plainText
  
    const encryptedTextClean = Buffer.from(encryptedText, 'base64').toString('utf-8')
  
    for (let index = 0; index < encryptedTextClean.length; index++) {
      const encryptedCode = encryptedTextClean.charCodeAt(index)
      const passwordCharCode = password.charCodeAt(index % password.length)
  
      // perform reverse XOR operation to obtain the original ASCII code
      const charCode = encryptedCode ^ passwordCharCode
  
      // convert the ASCII code back to a character and add it to the decrypted text
      plainText += String.fromCharCode(charCode)
    }
  
    return plainText;
};

export const tenantHasNativeAuthStrategy = (tenant = getTenantSession()) =>
    !!tenant.authStrategies.find((s) => s.type === NativeAuthStrategy)

export const setTenantSession = (tenant) => setTenantSessionStorage(tenant)

export const getTenantSession = () => getTenantSessionStorage()

export const getLyraLang = () => {
    const cookieValue = Cookies.get(COOKIE_LANGUAGE)
    return baseDecrypt(cookieValue, PREFS_PASSWORD)
}

export const getTenantLang = () => {
    const lang = getLyraLang()
    const isValidLang = Object.keys(LOCALES).includes(lang)
    return isValidLang ? LOCALES[lang] : (getTenantSessionStorage().lang || LOCALES.es)
}

export const getTenantCountry = () => getTenantSessionStorage().country
