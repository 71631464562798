import React from 'react'
import JSFileDownload from 'js-file-download'

const DeleteBlockInfoAlert = ({
    reportUrl,
    onCloseAlert,
    countCanBeRemoved,
    showDownloadReport,
    lang,
}) => {
    const onClickDownloadReport = () => window.open(reportUrl, 'blank')
    return (
        <div className="toastA alert alert-success">
            <a className="close" onClick={onCloseAlert}>
                &times;
            </a>
            <h3>{lang.title}</h3>
            <br />
            {showDownloadReport && countCanBeRemoved ? (
                <h3>
                    <a onClick={onClickDownloadReport}>{lang.clickHere}</a>{' '}
                    {lang.clickHereRightText}
                </h3>
            ) : null}
        </div>
    )
}
export default DeleteBlockInfoAlert
