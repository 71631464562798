import React from 'react'
import PropTypes from 'prop-types'
import Dropdown from '@/components/Dropdown'
import { ChevronDown } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import UserFinder from '@/components/UserFinder'
import useHistory from '@/hooks/useHistory'

const StudentListHeaderToolbar = ({
    lang,
    enableAssignStudentsFromSearch,
    enableAssignStudentsFromCSV,
    showModalAssign,
    courseId,
}) => {
    const history = useHistory()
    const dropdownOptions = []

    const handleUserLoad = () => {
        history.push('/admin/users/csv?courseId=' + courseId)
    }

    if (enableAssignStudentsFromCSV) {
        dropdownOptions.push({
            name: lang.attachCSV,
            onClick: () => handleUserLoad(),
            testId: 'attachCSV',
        })
    }

    if (enableAssignStudentsFromSearch) {
        dropdownOptions.unshift({
            name: lang.studentAssign,
            onClick: () => showModalAssign(),
            testId: 'studentAssign',
        })
    }

    return (
        <>
            <Dropdown
                ddStyle='_btn _new_btn--tertiary'
                items={dropdownOptions}
            >
                <span>
                    {lang.studentAssign}
                    <ChevronDown />
                </span>
            </Dropdown>
            <UserFinder
                type='student'
                title={lang.searchStudents}
                relatedId={courseId}
            />
        </>
    )
}

StudentListHeaderToolbar.propTypes = {
    lang: PropTypes.shape({
        studentAssign: PropTypes.string.isRequired,
        attachCSV: PropTypes.string.isRequired,
        searchStudents: PropTypes.string.isRequired,
    }).isRequired,
    enableAssignStudentsFromSearch: PropTypes.bool.isRequired,
    enableAssignStudentsFromCSV: PropTypes.bool.isRequired,
    showModalAssign: PropTypes.func.isRequired,
    courseId: PropTypes.string.isRequired,
}

export default StudentListHeaderToolbar