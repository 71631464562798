import { validator, type } from '@/helpers/utils/propTypes'
import { loadUserActiveCoursePayloadScheme } from './schemes'
import * as constants from './constants'

const processPayload = (scheme, payload, constant) => {
    try {
        validator(scheme, payload)
        return {
            type: constant, payload
        }
    } catch (e) {
        return {
            type: constant, payload
        }
    }
}

export const loadAllCoursesAction = payload => ({
    type: constants.ALL_COURSES, payload: {
        ...payload,
        limit: 15
    }
})

export const returnButtonHideAction = () => ({
    type: constants.RETURN_BUTTON_HIDE
})

export const changeVisibilityAction = payload => ({
    type: constants.CHANGE_VISIBILITY, payload
})

export const clearCourseAction = () => ({
    type: constants.CLEAR_REDUCER
})

export const loadAllCourseTypesAction = payload => ({
    type: constants.LOAD_ALL_COURSE_TYPES, payload
})

export const loadAllLessonTypesAction = payload => ({
    type: constants.LOAD_ALL_LESSON_TYPES, payload
})

export const loadAllGroupsLessonAction = payload => ({
    type: constants.LOAD_ALL_GROUPS_LESSON, payload
})

export const clearAllGroupsLessonAction = () => ({
    type: constants.CLEAR_ALL_GROUPS_LESSON
})

export const clearAllCourseTypesAction = () => ({
    type: constants.CLEAR_ALL_COURSE_TYPES
})

export const clearCourseAlertAction = () => ({
    type: constants.CLEAR_COURSE_ALERT
})

export const setStudentsQtyAction = (payload) => ({
    type: constants.SET_STUDENTS_QTY, payload
})

export const clearStudentsQtyAction = () => ({
    type: constants.CLEAR_STUDENT_QTY
})

// normal action creators
export const queryError = payload => ({
    type: constants.QUERY_ERROR_COURSE, payload
})

export const successful = payload => ({
    type: constants.SUCCESSFUL_COURSE, payload
})

export const setEmailAlertAction = payload => ({
    type: constants.SET_EMAIL_ALERT, payload
})

export const clearEmailAlertAction = payload => ({
    type: constants.CLEAR_EMAIL_ALERT, payload
})

//COURSE
export const updateCoursesAction = payload => ({
    type: constants.UPDATE_COURSES, 
    payload: {
        ...payload,
        limit: 15 
    }
})

export const updateActiveCourseAction = payload => ({
    type: constants.UPDATE_ACTIVE_COURSE, payload
})

export const loadActiveCourseAction = payload => ({
    type: constants.LOAD_ACTIVE_COURSE, payload
})
export const loadActiveUnitsAction = payload => ({
    type: constants.LOAD_ACTIVE_UNITS, payload
})
export const loadActiveLessonsAction = payload => ({
    type: constants.LOAD_ACTIVE_LESSONS, payload
})
export const loadActiveTopicsAction = payload => ({
    type: constants.LOAD_ACTIVE_TOPICS, payload
})
export const loadActiveBlocksAction = payload => ({
    type: constants.LOAD_ACTIVE_BLOCKS, payload
})
export const clearActiveCourseAction = payload => ({
    type: constants.CLEAR_ACTIVE_COURSE, payload
})
export const loadCourseClonesQtyAction = payload => ({
    type: constants.LOAD_COURSE_CLONES_QTY, payload
})

export const toggleDragAction = () => ({
    type: constants.TOGGLE_DRAG_UNITS
})

export const toggleDragLessonAction = () => ({
    type: constants.TOGGLE_DRAG_LESSONS
})

export const toggleDragTopicAction = () => ({
    type: constants.TOGGLE_DRAG_TOPICS
})

export const toggleDragBlockAction = () => ({
    type: constants.TOGGLE_DRAG_BLOCKS
})


export const loadPaginatedCoursesAction = payload => ({
    type: constants.ALL_PAGINATED_COURSES, payload
})

export const loadCourseProgressAction = payload => ({
    type: constants.LOAD_COURSE_PROGRESS, payload
})

export const clearCourseProgressAction = () => ({
    type: constants.CLEAR_COURSE_PROGRESS,
})

export const httpRequestLoading = payload => processPayload(type.boolean, payload, constants.HTTP_REQUEST_LOADING)

export const clearActiveSearchAction = () => ({
    type: constants.CLEAR_ACTIVE_SEARCH
})

export const setActiveSearchAction = payload => ({
    type: constants.SET_ACTIVE_SEARCH, payload
})

export const getCoursesToModifyAction = payload => ({
    type: constants.MODIFY_COURSES, payload
})

export const clearCoursesCountAction = () => ({
    type: constants.CLEAR_COURSES_COUNT
})

export const setUpdatedClonesAction = payload => ({
    type: constants.SET_UPDATED_CLONES, payload
})

export const clearUpdatedClonesAction = () => ({
    type: constants.CLEAR_UPDATED_CLONES
})

export const unableCloneSurveyAction = payload => ({
    type: constants.UNABLE_CLONE_SURVEY, payload
})

export const clearUpdatedCloneSurveyAction = () => ({
    type: constants.CLEAR_SURVEY_MSG
})

export const changeLoadingAction = payload => ({
    type: constants.CHANGE_IS_LOADING, payload
})

export const isSchoolsCourseAction = payload => processPayload(type.boolean, payload, constants.IS_SCHOOLS_COURSE)

export const getLessonsByCourseAction = payload => ({
    type: constants.GET_LESSONS_BY_COURSE, payload
})

export const getBlocksByLessonAction = payload => ({
    type: constants.GET_BLOCKS_BY_LESSON, payload
})


//TABS ACTIONS

export const updateCourseTabContentAction = payload => ({
    type: constants.UPDATE_TAB_CONTENT, payload
})

export const setTabsInfoAction = payload => ({
    type: constants.SET_COURSE_TABS, payload
})
export const loadCourseTabsAction = payload => ({
    type: constants.LOAD_COURSE_TABS, payload
})
export const setActiveTabAction = payload => ({
    type: constants.SET_ACTIVE_TAB, payload
})
export const clearTabsAction = payload => ({
    type: constants.CLEAR_COURSE_TABS, payload
})
export const clearActiveTabAction = payload => ({
    type: constants.CLEAR_ACTIVE_TAB, payload
})

export const setActiveCourseTabAction = payload => ({
    type: constants.SET_ACTIVE_COURSE_TAB, payload
})
export const clearActiveCourseTabAction = payload => ({
    type: constants.CLEAR_ACTIVE_COURSE_TAB, payload
})

// LOADER ACTIONS
export const changeSavingCourseAction = payload => ({
    type: constants.CHANGE_SAVING_COURSE, payload
})
export const changeSavingUnitAction = payload => ({
    type: constants.CHANGE_SAVING_UNIT, payload
})
export const changeSavingLessonAction = payload => ({
    type: constants.CHANGE_SAVING_LESSON, payload
})
export const changeSavingTopicAction = payload => ({
    type: constants.CHANGE_SAVING_TOPIC, payload
})
export const changeDeleteTopicAction = payload => ({
    type: constants.CHANGE_DELETE_TOPIC, payload
})
export const changeSavingBlockAction = payload => ({
    type: constants.CHANGE_SAVING_BLOCK, payload
})

export const setReportDataAction = payload => ({
    type: constants.SET_REPORT_DATA, payload
})

export const clearReportDataAction = () => ({
    type: constants.CLEAR_REPORT_DATA
})

export const setCourseReportErrorAction = payload => ({
    type: constants.SET_COURSE_REPORT_ERROR, payload
})

export const loadCourseStudentAction = payload => ({
    type: constants.LOAD_COURSE_STUDENT, payload
})

export const clearSearchStudentAction = () => ({
    type: constants.CLEAR_SEARCH_STUDENT
})