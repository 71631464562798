import React from 'react'

export class RadioBtn extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      value: props.item
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.value === null) {
      return {
        value: props.item
      }
    }
    return {}
  }

  render() {
    return (
      <React.Fragment>
        <li onClick={this.props.toggle} className={this.state.value === this.props.selectedValue ? "bg-color--primary active __font_weight_bold" : ''}>
          {this.props.item}
        </li>
      </React.Fragment>
    )
  }
}

export default RadioBtn
