import React, { memo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useParams } from 'react-router-dom'
import { isMobileSelector } from '@/redux/selectors/auth'
import { checkLessonIsReleased } from '@digitalhouse-tech/ts-lib-store/lib/shared/dates'
import Topic from './Topic'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import DatePill from '@/components/DatePill'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'
import { ChevronUp, Video } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { iconAsset } from '@/assets/img/config'
import lessonTypesConstants from '@/helpers/lessons/lessonTypes'
import { getCourseType } from '@/redux/selectors/course'
import { enableBaseCloneVisibility } from '@/helpers/granters/courseTypeRules'

import './Lesson.scss'

const Lesson = memo(
    ({
        lesson: {
            LessonType: { name: lessonType },
            ...lesson
        },
        progresses,
        showDates,
        allowApiCreate,
    }) => {
        const courseType = useSelector(getCourseType)
        const [collapse, setCollapse] = useState(true)

        const isMobile = useSelector(isMobileSelector)

        const { notReleasedLesson, remoteClasses } = useSelector(({ langStore: { progressReducer } }) => progressReducer)

        const handleToggleCollapse = () => setCollapse(!collapse)

        const { topicId: currrentTopicId } = useParams()

        useEffect(() => {
            const haveCurrentTopic = lesson.Topics.some(topic => topic.id == currrentTopicId)

            if (haveCurrentTopic) {
                setCollapse(false)
            }
        }, [lesson.id, lesson.Topics, currrentTopicId])

        const isPresential = (lessonType === lessonTypesConstants.SYNC) && !enableBaseCloneVisibility(courseType)

        const isReleased = checkLessonIsReleased(lesson.release_date)

        return (
            <li
                className={cn('lesson-map-container separator', {
                    'lesson-map-container--collapse': collapse,
                })}
            >
                <Button
                    id="ContentMapLessonToggleCollapseButton"
                    block
                    className="lesson-map-container__title"
                    icon={<ChevronUp className="lesson-map-container__icon-container__icon" />}
                    onClick={handleToggleCollapse}
                    theme="clear"
                    text={
                        <div className="lesson-map-container__title__button-text">
                            <span className='lesson-map-container__title__box'>
                                {allowApiCreate && isPresential && (
                                    <Tooltip
                                        tooltipId={`LessonIconTooltip-${lesson.id}`}
                                        tooltipContent={remoteClasses}
                                        tooltipPlacement='right'
                                    >
                                        <Video className='lesson-map-container__title__button-text__class-icon' />
                                    </Tooltip>
                                )}
                                <span className='lesson-map-container__title__button-text__class-name'>
                                    {lesson.name}
                                </span>
                            </span>
                            <span>
                                {!isReleased && (
                                    <Tooltip
                                        tooltipPlacement='left'
                                        tooltipId={`LessonNotReleasedIconTooltip-${lesson.id}`}
                                        tooltipContent={!isMobile ? notReleasedLesson : null}
                                    >
                                        <img
                                            src={iconAsset('padlock.svg')}
                                            alt='Lesson not released yet'
                                            className='lesson-map-container__title__button-text__not-released'
                                        />
                                    </Tooltip>
                                )}
                            </span>
                        </div>

                    }
                />

                {showDates && lesson.due_date && <DatePill date={lesson.due_date} />}

                <ul className="lesson-map-container__topics-container">
                    {lesson.Topics.map(topic => (
                        <Topic
                            topic={topic}
                            key={topic.id}
                            completed={progresses[topic.id]}
                            lessonId={lesson.id}
                        />
                    ))}
                </ul>
            </li>
        )
    },
)

Lesson.propTypes = {
    lesson: PropTypes.object.isRequired,
    progresses: PropTypes.object.isRequired,
    showDates: PropTypes.bool.isRequired,
    allowApiCreate: PropTypes.bool.isRequired,
}

export default Lesson
