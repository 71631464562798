import React, { createRef } from 'react'
import { connect } from 'react-redux'
import { X } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { calculatePaginationRange } from '@/helpers/pagination'
import Paginator from '@digitalhouse-tech/react-lib-ui-explorer/lib/Paginator'
import LoadingOrContent from '@digitalhouse-tech/react-lib-ui-explorer/lib/LoadingOrContent'
import { offset, scrollTo } from '@/helpers/utils'
import { saveNewOwner } from '@/redux/thunks/ownerThunks'
import { isObjectEmpty } from '@/helpers/utils'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import {
    closeModalSearchAction,
    loadPaginatedUsers,
    addStudents,
    changeLoadingAction
} from '../../redux/reducers/_deprecated/userReducer'
import UserTable from './UserTable'
import './UserFinder.scss'


export class UserFinder extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            addUsers: [],
            choose: '',
            currentPage: 1,
            pagesQuantity: 1,
            userModalId: 'userSelectionModal',
            userName: '',
            userSurname: '',
            userEmail: '',
        }

        this.userListRef = createRef()
        this.searchUser = this.searchUser.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.showPagination = this.showPagination.bind(this)
        this.pageChangeHandler = this.pageChangeHandler.bind(this)
        this.getHeaders = this.getHeaders.bind(this)
        this.showResults = this.showResults.bind(this)
        this.assignStudent = this.assignStudent.bind(this)
        this.assignOwner = this.assignOwner.bind(this)
        this.goToPageTop = this.goToPageTop.bind(this)
    }

    static getDerivedStateFromProps(props) {
        const { pages: pagesQuantity } = props.allUsers
        return {
            ...(pagesQuantity && { pagesQuantity }),
        }
    }

    componentDidMount() {
        this.props.changeLoading(false)
    }

    addUser(user) {
        if (!this.state.addUsers.length) {
            this.setState({ addUsers: [...this.state.addUsers, user] })
        }
        else {
            let newAdd = {}
            for (let i = 0; i < this.state.addUsers.length; i++) {
                if (this.state.addUsers[i].id == user.id) {
                    return null
                }
                else {
                    newAdd = user
                }
            }
            isObjectEmpty(newAdd) ? null : this.setState({ addUsers: [...this.state.addUsers, newAdd] })
        }
    }

    removeUser(user) {
        const result = this.state.addUsers.filter(userArr => {
            return userArr.id !== user.id
        })
        this.setState({ addUsers: result })
    }

    async searchUser() {
        const { relatedId, type, loadPaginatedUsers } = this.props

        let name = this.state.userName
        let surname = this.state.userSurname
        let email = this.state.userEmail

        await loadPaginatedUsers({ order_by: 'desc' }, 1, name, surname, email, relatedId, type)

        this.goToPageTop()
    }

    closeModal() {
        this.setState({ addUsers: [], choose: '', activePage: 1 })
        this.props.close()
    }

    assignStudent() {
        if (!this.state.addUsers.length) {
            this.setState({
                choose: this.props.lang.mustSelectStudent
            })
            return
        }
        let create = this.state.addUsers.map(user => {
            let userAux = {}
            userAux.course_id = this.props.relatedId
            userAux.user_id = user.id
            return userAux
        })
        this.props.addStudents(create, this.props.relatedId)
        this.closeModal()
    }

    assignOwner() {
        if (!this.state.addUsers.length) {
            this.setState({
                choose: this.props.lang.mustSelectOwner
            })
            return
        }
        let create = this.state.addUsers.map(user => {
            let userAux = {}
            userAux.course_id = this.props.relatedId
            userAux.user_id = user.id
            return userAux
        })
        this.props.addOwners(create, this.props.relatedId)
        this.closeModal()
    }

    getHeaders() {
        const headers = [
            this.props.lang.name,
            this.props.lang.lastName,
            this.props.lang.email
        ]
        return headers
    }

    goToPageTop() {
        const { userModalId } = this.state
        const userListTop = offset(this.userListRef.current).top - 25
        const userModalDomEl = document.getElementById(userModalId)
        scrollTo(userModalDomEl, userListTop)
    }

    async pageChangeHandler(page) {
        const { loadPaginatedUsers, relatedId, type } = this.props

        this.setState({
            currentPage: page,
        })
        let name = this.state.userName
        let surname = this.state.userSurname
        let email = this.state.userEmail

        await loadPaginatedUsers({ order_by: 'desc' }, page, name, surname, email, relatedId, type)

        this.goToPageTop()
    }

    showPagination() {
        const { currentPage, pagesQuantity } = this.state
        const {
            isLoading,
            allUsers: { users },
        } = this.props

        return users && users.length ? (
            <Paginator
                currentPage={currentPage}
                disabled={isLoading}
                onPageChange={(page) => this.pageChangeHandler(page)}
                range={calculatePaginationRange(pagesQuantity)}
                totalPages={pagesQuantity}
            />
        ) : (
            <></>
        )
    }

    formatUsers(data) {
        if (!data) { return { name: '' } }
        let formatAux = []
        data.forEach((elem, index) => {
            formatAux.push({ name: elem.name, surname: elem.surname, email: elem.email, id: elem.id, disabled: elem.disabled })
        })
        return formatAux
    }

    showResults() {

        return (
            <UserTable
                className="topMargin"
                key={'searchTable'}
                data={this.formatUsers(this.props.allUsers.users)}
                headers={this.getHeaders()}
                editAction={(user) => this.addUser(user)}
                deleteAction={null}
            />)
    }

    submitEnabled() {
        return this.state.userName !== '' || this.state.userSurname !== '' || this.state.userEmail !== ''
    }


    searchBar() {
        return (
            <div>
                <Modal className="__modal _modal_xl" id={this.state.userModalId}  show={this.props.showModal} onHide={this.closeModal} keyboard={false} backdrop="static">
                    <Modal.Header closeButton className="__modal_header __border_bottom">
                        <h3 className="__modal_title">{this.props.title}</h3>
                    </Modal.Header>

                    <Modal.Body className="__modal_body">
                        {this.state.choose.length ? <div className="__error"><h3><strong>{this.props.lang.warning}</strong> {this.state.choose}</h3></div> : null}

                        <Row className="centerText">
                            <Col lg="4" md="4">
                                <Label text={this.props.lang.name} />
                                <InputText
                                    id="user.name"
                                    onChange={name => this.setState({ ...this.state, userName: name.currentTarget.value })}
                                    value={this.state.userName}
                                />
                            </Col>
                            <Col lg="4" md="4">
                                <Label text={this.props.lang.searchLastName} />
                                <InputText
                                    id="user.surname"
                                    onChange={surname => this.setState({ ...this.state, userSurname: surname.currentTarget.value })}
                                    value={this.state.userSurname}
                                />
                            </Col>
                            <Col lg="4" md="4">
                                <Label text={this.props.lang.searchEmail} />
                                <InputText
                                    id="user.email"
                                    onChange={email => this.setState({ ...this.state, userEmail: email.currentTarget.value }) }
                                    value={this.state.userEmail}
                                />
                            </Col>
                            <Col lg="12" md="4">
                                <ButtonGroup className="pull-right _margin_top_30">
                                    <Button
                                        id="SearchButton"
                                        onClick={this.searchUser}
                                        text={this.props.lang.search}
                                        theme='primary'
                                        loading={this.props.isLoading}
                                        disabled={this.props.isLoading || !this.submitEnabled()}
                                    />
                                </ButtonGroup>
                            </Col>
                        </Row>

                        <Row>

                            <Col lg="12" md="4">
                                <div className="__search_table_result" ref={this.userListRef}>
                                    {!this.props.isLoadedForModal &&
                                        <p className="text-center _start_search"><strong>{this.props.lang.startSearch}</strong></p>
                                    }
                                    {this.props.isLoadedForModal &&
                                        <div>
                                            <LoadingOrContent
                                                loading={this.props.isLoading}
                                                size='medium'
                                            >
                                                {this.showResults()}
                                            </LoadingOrContent>
                                            <div className="__pagination">
                                                {this.showPagination()}
                                            </div>
                                        </div>
                                    }
                                </div>

                            </Col>
                        </Row>

                        <Row>
                            <Col lg="12" md="4">
                                {this.state.addUsers.length > 0 &&
                                    this.state.addUsers.map((user, i) => {
                                        return (
                                            <div key={i} className="__badge_box">
                                                <div className="_badge font-color--white">
                                                    <h4 className="_badge__username">{user.name} {user.surname}</h4><h5 className="_badge__cancel-icon"><X size={16} onClick={this.removeUser.bind(this, user)} /></h5>
                                                </div>
                                            </div>

                                        )
                                    })
                                }
                            </Col>
                        </Row>

                    </Modal.Body>

                    <Modal.Footer className="__modal_footer __border_top">
                        <ButtonGroup className="pull-right">
                            <Button
                                id="CloseModalButton"
                                onClick={this.closeModal}
                                text={this.props.lang.cancel}
                                theme='tertiary'
                            />
                            <Button
                                id="AssignResourceButton"
                                onClick={
                                    this.props.type === "student" ? this.assignStudent
                                    : this.props.type === "owner" ? this.assignOwner
                                    : null
                                }
                                text={this.props.lang.save}
                                theme='primary'
                            />
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>

            </div>


        )
    }

    render() {
        return this.searchBar()
    }
}

const mapDispatch = (dispatch) => ({
    close: () => {
        dispatch(closeModalSearchAction())
    },
    loadPaginatedUsers: (filters, page, name, surname, email, relatedId, type) =>
        dispatch(loadPaginatedUsers(filters, page, name, surname, email, relatedId, type)),
    addStudents: (data, courseId) => {
        dispatch(addStudents(data, courseId))
    },
    changeLoading: (status) => {
        dispatch(changeLoadingAction(status))
    },
    addOwners: (ownersIds, courseId) => {
        dispatch(saveNewOwner(ownersIds, courseId))
    }
})


const mapStateToProps = (state) => {
    return {
        showModal: state.userReducer.showModalSearch,
        users: state.userReducer.listaUser,
        isLoadedForModal: state.userReducer.isLoadedForModal,
        allUsers: state.userReducer.allUsers,
        isLoading: state.userReducer.isLoading,
        lang: state.langStore.userFinder
    }
}

UserFinder.propTypes = {
    addStudents: PropTypes.func.isRequired,
    addOwners: PropTypes.func.isRequired,
    allUsers: PropTypes.shape({
        users: PropTypes.arrayOf(PropTypes.object),
        count: PropTypes.number,
        pages: PropTypes.number,
        isLoaded: PropTypes.bool,
    }).isRequired,
    changeLoading: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    isLoadedForModal: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    lang: PropTypes.objectOf(PropTypes.string).isRequired,
    loadPaginatedUsers: PropTypes.func.isRequired,
    relatedId: PropTypes.string.isRequired,
    showModal: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatch, null, { forwardRef: true })(UserFinder)
