import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
    Plus,
    Lock,
    AlertCircle,
    ArrowLeft,
    ArrowRight,
    RotateCw,
    Terminal,
    Sun,
    Moon
} from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Spinner from '@digitalhouse-tech/react-lib-ui-explorer/lib/Spinner'
import { atomAsset, logoAsset } from '@/assets/img/config'
import BrowserConsole from './BrowserConsole'
import useLogCapturer from '../useLogCapturer'

import './Browser.scss'

const constants = {
    LIGHT: 'light',
    DARK: 'dark',
}

const Browser = ({
    secure,
    host,
    port,
    favicon,
    title,
    content,
    width,
    height,
    logs,
    throws,
    random,
    executing,
    setRandom,
}) => {
    const [theme, setTheme] = useState(constants.LIGHT)
    const [showConsole, setShowConsole] = useState(false)

    const { stateLogs, stateThrows } = useLogCapturer({ random })

    const url = () => {
        const protocolo = 'http'
        const icons = {
            true: <Lock />,
            false: <AlertCircle />,
        }
        return (
            <>
                <span className='protocolo'>{icons[secure]}</span>
                {protocolo}://{host}
                {port}
            </>
        )
    }
    const reDo = () => setRandom(random + 1)
    const toggleTheme = () =>
        theme === constants.LIGHT
            ? setTheme(constants.DARK)
            : setTheme(constants.LIGHT)
    const toggleConsole = () => setShowConsole(!showConsole)
    const logo = () => {
        const themes = {
            [constants.DARK]: <img src={logoAsset('dh-coding-school-light.png')} alt='lightLogo' />,
            [constants.LIGHT]: <img src={logoAsset('dh-coding-school-dark.png')} alt='darkLogo' />,
        }
        return themes[theme]
    }

    const getBrowseTitle = () => {
        const titleDOM = content.match(/<title[^>]*>([^<]+)<\/title>/)

        if (titleDOM) return titleDOM[1]

        return title
    }

    return (
        <div className={`Browser ${theme}`}>
            <div className='header'>
                <ul className='tabs'>
                    <li>
                        {executing ? (
                            <div className='browserLoader'>
                                <Spinner />
                            </div>
                        ) : (
                            <img
                                src={favicon}
                                className='favicon'
                                alt='favicon'
                            />
                        )}{' '}
                        {getBrowseTitle()}
                    </li>
                    <li>
                        <Plus />
                    </li>
                </ul>
            </div>
            <div className='options'>
                <ul className='navigation'>
                    <li onClick={reDo} key='backArrow'>
                        <ArrowLeft />
                    </li>
                    <li key='nextArrow'>
                        <ArrowRight />
                    </li>
                    <li onClick={reDo} key='reDo'>
                        <RotateCw size={20} />
                    </li>
                </ul>
                <div className='url'>{url()}</div>
                <div className='theme' onClick={toggleTheme}>
                    { theme === constants.DARK ? <Sun /> : <Moon /> }
                </div>
                <div className='terminal' onClick={toggleConsole}>
                    <Terminal />
                </div>
                <div className='both' />
            </div>
            {content && !executing ? (
                <div className='renderArea'>
                    {showConsole && (
                        <BrowserConsole
                            width={'350px'}
                            height={'100%'}
                            logs={[...stateLogs, ...logs]}
                            throws={[...stateThrows, ...throws]}
                            close={setShowConsole}
                        />
                    )}
                    <iframe
                        srcDoc={content}
                        className='frame'
                        key={random}
                        title={'pg'}
                        width={width}
                        height={height}
                        sandbox='allow-forms allow-scripts allow-same-origin allow-modals allow-popups allow-presentation'
                    />
                </div>
            ) : (
                <div className='emptyArea' style={{ height, width }}>
                    {logo()}
                    {showConsole && (
                        <BrowserConsole
                            width={'350px'}
                            height={'100%'}
                            logs={[...logs, ...stateLogs]}
                            throws={[...throws, ...stateThrows]}
                            close={setShowConsole}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

Browser.defaultProps = {
    secure: false,
    host: 'playground.digitalhouse.com',
    port: '',
    favicon: atomAsset('rubiks-cube.png'),
    title: 'Playground Digital House',
    content: '',
    width: '100%',
    height: '250px',
    logs: [],
    throws: [],
    executing: false,
}

Browser.propTypes = {
    secure: PropTypes.bool,
    host: PropTypes.string,
    port: PropTypes.string,
    favicon: PropTypes.node,
    title: PropTypes.string,
    content: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    logs: PropTypes.arrayOf(PropTypes.shape()),
    throws: PropTypes.arrayOf(PropTypes.shape()),
    random: PropTypes.number.isRequired,
    executing: PropTypes.bool,
    setRandom: PropTypes.func.isRequired,
}

export default Browser
