import React from 'react'
import GenericModal from '@/components/GenericModal'
import PropTypes from 'prop-types'

const FileEditModal = ({ fileName, fileId, onAccept, onCancel, show, lang }) => {

    const onAcceptHandler = () => {
        onAccept(fileName, fileId)
    }

    const onCancelHandler = () => {
        onCancel()
    }

    return (
        <GenericModal
            show={show}
            titleModal= {lang.deleteFile}
            cancelTitle= {lang.cancel}
            confirmTitle= {lang.confirm}
            onCancel={onCancelHandler}
            onConfirm={onAcceptHandler}
        >
           <h4>{fileName}</h4>
        </GenericModal>
    )
}

FileEditModal.propTypes = {
    fileName: PropTypes.string.isRequired,
    fileId: PropTypes.number.isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
}

export default FileEditModal