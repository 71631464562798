import React from 'react'
import PropTypes from 'prop-types'
import { getBlockProgress } from '@/redux/selectors/students/progress'
import ExerciseBlock from './ExerciseBlock'
import IframeExerciseBlock from './IframeExerciseBlock'

const ExerciseContainer = ({ isMobile, blockType, exerciseType, ...props }) => {
    const IFRAME_TYPE = 'IFRAME'

    return (
        <>
            {exerciseType === IFRAME_TYPE ? (
                <IframeExerciseBlock id={props.exercise._id} exercise={props.exercise} {...props} />
            ) : (
                <ExerciseBlock isMobile={isMobile} blockType={blockType} {...props} />
            )}
        </>
    )
}

ExerciseContainer.propTypes = {}

export default ExerciseContainer
