import {
    EXERCISE_BLOCK_MODAL,
    FILE_BLOCK_MODAL,
    PDF_BLOCK_MODAL,
    QUIZ_BLOCK_MODAL,
    SLIDE_BLOCK_MODAL,
    TEXT_BLOCK_MODAL,
    VIDEO_BLOCK_MODAL,
    TEXT_HTML_BLOCK_MODAL,
    EVALUABLE_BLOCK_MODAL,
    NOTION_BLOCK_MODAL,
} from './modals'

export const ExerciseBlock = "ExerciseBlock"
export const FileBlock = "FileBlock"
export const NotebookBlock = "NotebookBlock"
export const PdfBlock = "PdfBlock"
export const QuizBlock = "QuizBlock"
export const SlideBlock = "SlideBlock"
export const TextBlock = "TextBlock"
export const VideoBlock = "VideoBlock"
export const TextHtmlBlock = "TextHtmlBlock"
export const EvaluableBlock = "EvaluableBlock"
export const NotionBlock = "NotionBlock"

export const BLOCK_TYPES = {
    EXERCISE_BLOCK: {
        name: ExerciseBlock,
        bdField: 'exercise_block_id',
        payloadName: 'exercise_block_id',
        schemaName: 'exercise-block',
        modal: EXERCISE_BLOCK_MODAL
    },
    FILE_BLOCK: {
        name: FileBlock,
        bdField: 'file_block_id',
        payloadName: 'file_block_id',
        schemaName: 'file-block',
        modal: FILE_BLOCK_MODAL
    },
    NOTEBOOK_BLOCK: {
        name: NotebookBlock,
        payloadName: 'NotebookBlock',
        schemaName: 'notebook-block',
    },
    PDF_BLOCK: {
        name: PdfBlock,
        bdField: 'pdf_block_id',
        payloadName: 'PdfBlock',
        schemaName: 'pdf-block',
        modal: PDF_BLOCK_MODAL
    },
    QUIZ_BLOCK: {
        name: QuizBlock,
        bdField: 'quiz_block_id',
        payloadName: 'quiz_block_id',
        schemaName: 'quiz-block',
        modal: QUIZ_BLOCK_MODAL
    },
    SLIDE_BLOCK: {
        name: SlideBlock,
        bdField: 'slide_block_id',
        payloadName: 'SlideBlock',
        schemaName: 'slide-block',
        modal: SLIDE_BLOCK_MODAL
    },
    TEXT_BLOCK: {
        name: TextBlock,
        bdField: 'text_block_id',
        payloadName: 'TextBlock',
        schemaName: 'text-block',
        modal: TEXT_BLOCK_MODAL
    },
    NOTION_BLOCK: {
        name: NotionBlock,
        bdField: 'text_block_id',
        payloadName: 'TextBlock',
        schemaName: 'text-block',
        modal: NOTION_BLOCK_MODAL,
        regex: /\{notion\:(.+)\}/,
        stringKey: '{notion:PAGE_ID}'
    },
    VIDEO_BLOCK: {
        name: VideoBlock,
        bdField: 'video_block_id',
        payloadName: 'VideoBlock',
        schemaName: 'video-block',
        modal: VIDEO_BLOCK_MODAL
    },
    TEXT_HTML_BLOCK: {
        name: TextHtmlBlock,
        bdField: 'text_html_block_id',
        payloadName: 'TextHtmlBlock',
        schemaName: 'text-html-block',
        modal: TEXT_HTML_BLOCK_MODAL
    },
    EVALUABLE_BLOCK: {
        name: EvaluableBlock,
        bdField: 'evaluable_block_id',
        payloadName: 'EvaluableBlock',
        schemaName: 'evaluable-block',
        modal: EVALUABLE_BLOCK_MODAL
    }
}
const blockKeys =  Object.keys(BLOCK_TYPES)

export const BLOCK_SCHEMA_NAMES = blockKeys.map(key => BLOCK_TYPES[key].schemaName)
export const BLOCK_NAMES = blockKeys.map(key => BLOCK_TYPES[key].name)
export const BLOCK_MODAL_NAMES = blockKeys.map(key => BLOCK_TYPES[key].modal).filter(name => !!name)

export const findBlockTypeByName = name => BLOCK_TYPES[blockKeys.find(key => BLOCK_TYPES[key].name === name)]

export const BLOCKS_THAT_MAY_HAVE_PROGRESS = [
    'ExerciseBlock',
    'QuizBlock',
]