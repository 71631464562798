import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Collapse from '@digitalhouse-tech/react-lib-ui-explorer/lib/Collapse'
import CardStatus from '@digitalhouse-tech/react-lib-ui-explorer/lib/CardStatus'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { ChevronsRight, ChevronsLeft } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import CourseReportActions from './CourseReportActions'
import { COURSE_ENTITIES_DATA } from '@/constants/courseEntities'
import { getTenantLang } from '@/helpers/tenants/helpers'
import { reportAsset, emptyStateAsset } from '@/assets/img/config'
import EmptyState from '../../shared/components/EmptyState'

const CourseReportGeneralData = ({
    progressStatus,
    showReferenceTable,
    showRefTableHandler,
    refreshCourse,
    onClickDownloadReport,
    loading,
    totalUsers,
    dataLevel,
    lang,
    loadingDownloadProgressReport,
}) => {
    const [referenceTable, setReferenceTable] = useState(null)

    const i18n = getTenantLang()

    const getReferenceTable = () =>
        dataLevel === COURSE_ENTITIES_DATA.TOPICS
            ? reportAsset(`blocks-reference-table-${i18n}.png`)
            : reportAsset(`reference-table-${i18n}.png`)

    useEffect(() => {
        setReferenceTable(<img src={getReferenceTable()} alt={lang.referenceTable} />)
    }, [dataLevel])

    return (
        <>
            <EmptyState
                hidden={totalUsers > 0}
                imgUrl={emptyStateAsset('default-no-results.svg')}
                text={lang.courseWithoutStudents}
            />
            <div
                className={cn({
                    _no_show_element: totalUsers === 0,
                })}
            >
                <div className='_report_general_info'>
                    <CardStatus
                        title={lang.states.UP_TO_DATE}
                        theme='success'
                        number={progressStatus.UP_TO_DATE}
                        hasUserIcon
                    />
                    <CardStatus
                        title={lang.states.WITH_DELAY}
                        theme='warning'
                        number={progressStatus.WITH_DELAY}
                        hasUserIcon
                    />
                    <CardStatus
                        title={lang.states.WITH_LONG_DELAY}
                        theme='danger'
                        number={progressStatus.WITH_LONG_DELAY}
                        hasUserIcon
                    />
                    <div
                        className={cn(
                            {
                                _reference_button_open: showReferenceTable,
                            },
                            '_report_reference_table'
                        )}
                    >
                        <Button
                            id='show_ref_image'
                            theme='tertiary'
                            onClick={showRefTableHandler}
                            icon={
                                showReferenceTable ? (
                                    <ChevronsLeft />
                                ) : (
                                    <ChevronsRight />
                                )
                            }
                            text={
                                showReferenceTable
                                    ? lang.hideReferenceTable
                                    : lang.showReferenceTable
                            }
                        />
                    </div>
                    <CourseReportActions
                        refreshCourse={refreshCourse}
                        lang={lang}
                        loading={loading}
                        showActionButtons={!showReferenceTable}
                        onClickDownloadReport={onClickDownloadReport}
                        loadingDownloadProgressReport={loadingDownloadProgressReport}
                    />
                </div>
                <Collapse show={showReferenceTable}>
                    <div className='_report_ref_image'>{referenceTable}</div>
                </Collapse>
            </div>
        </>
    )
}

CourseReportGeneralData.propTypes = {
    progressStatus: PropTypes.shape({
        UP_TO_DATE: PropTypes.number.isRequired,
        WITH_DELAY: PropTypes.number.isRequired,
        WITH_LONG_DELAY: PropTypes.number.isRequired,
    }).isRequired,
    showReferenceTable: PropTypes.bool.isRequired,
    showRefTableHandler: PropTypes.func.isRequired,
    refreshCourse: PropTypes.func.isRequired,
    onClickDownloadReport: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    totalUsers: PropTypes.number.isRequired,
    dataLevel: PropTypes.string.isRequired,
    lang: PropTypes.object.isRequired,
}

CourseReportGeneralData.defaultProps = {
    courseAlias: '',
    loading: false,
}

export default CourseReportGeneralData
