import React, { useState } from 'react'

import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import { ChevronDown, ChevronUp } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'


const StudentFinder = ({
    searchStudent,
    lang,
    isLoading,
    title,
    subtitle,
    courseName
}
) => {
    const [searchActive, setSearchActive] = useState(true)
    const [searchName, setSearchName] = useState('')
    const [searchSurname, setSearchSurname] = useState('')
    const [searchEmail, setSearchEmail] = useState('')
    
    const showSearchBar = () => {
        setSearchActive(prevSearchActive => !prevSearchActive)
    }

    const setUserSearchName = (e) => {
        const value = e.target.value
        setSearchName(value)
    }
    const setUserSearchSurname = (e) => {
        const value = e.target.value
        setSearchSurname(value)
    }
    const setUserSearchEmail = (e) => {
        const value = e.target.value
        setSearchEmail(value)
    }
    const onClickSearch = () => {
        searchStudent({name:searchName, surname:searchSurname, email: searchEmail})
    }
    const getSearchBar = () => {
        if (searchActive) {
            return (
                <>
                    <Row>
                        <Col md="12">
                            <h3 className="__search_title">
                                {subtitle}
                            </h3>
                        </Col>
                    </Row>

                    <div>
                        <Row className='_margin_bottom_25'>
                            <Col md="4">
                                <Label text={lang.searchByName} />
                                <InputText
                                    id="user.name"
                                    onChange={setUserSearchName}
                                    value={searchName}
                                />
                            </Col>
                            <Col md="4">
                                <Label text={lang.searchBySurname} />
                                <InputText
                                    id="user.surname"
                                    onChange={setUserSearchSurname}
                                    value={searchSurname}
                                />
                            </Col>
                            <Col md="4">
                                <Label text={lang.searchByEmail} />
                                <InputText
                                    id="user.email"
                                    onChange={setUserSearchEmail}
                                    value={searchEmail}
                                />
                            </Col>
                        </Row>
                    </div>
                    <ButtonGroup className="pull-right">
                        <Button
                            id="StudenSearchButton"
                            text={lang.search}
                            theme='primary'
                            onClick={onClickSearch}
                            loading={isLoading}
                            disabled={isLoading}
                        />
                    </ButtonGroup>
                    <div className="clearfix"></div>

                </>
            )
        }
        return null
    }
    return (
            <div className="__main_header">
                <Row>
                    <Col lg="6">
                        <h1 className="__main_title">{title}<small>{courseName}</small></h1>
                    </Col>
                    <Col lg="6">
                        <div className="_btn_group pull-right">

                            <ButtonRound
                                id="ShowSearchhButton"
                                onClick={showSearchBar}
                                icon={searchActive ? <ChevronUp /> : <ChevronDown />}
                                theme='info'
                                tooltipId="showSearchBar"
                                tooltipContent={searchActive ? lang.closeSearch : lang.openSearch}
                            />

                        </div>
                    </Col>
                </Row>
                {searchActive && getSearchBar()}

            </div>
    )
}
export default StudentFinder