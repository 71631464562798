import axios from '@/app/requests'
import { identify } from '@digitalhouse-tech/react-sdk-analytics'

import { history, getStore } from '@/app/store.js'

import { getConfig } from '@/helpers/utils'
import api from '@/config/endpoints'

import { incomingDeployment } from '../../../thunks/deploymentAlertThunks'
import { hasBackofficeAccessSelector } from '../../../selectors/auth'
import { getGuard, setGuard } from '@/routes/guards'
import { ADMIN_GUARD, STUDENT_GUARD } from '@/constants/guards'
import showNewMessage from '../globalAlertsReducer/alertsControllers'
import { getTenantSession } from '@/helpers/tenants/helpers'
import httpHandler, { codes } from '@/helpers/error-handling/httpHandler'
import { VERSION } from '@/config/env'
import { getUserSession, removeUserSessionCookie, setUserSession } from '@/helpers/users/helpers'
import { setFirstRedirect } from '@/redux/reducers/contextReducer'
import { SESSION_USER_KEY } from '@/helpers/tenants/constants'

const getScreenSize = () => ({ width: window.innerWidth, height: window.innerHeight })
const isItMobile = ({ height, width }) => {
    const screenSize = getScreenSize()
    return screenSize.height > screenSize.width
}
export const resizeController = () => {
    const sizeScreenGetted = getScreenSize()
    const isMobile = getStore().getState().authReducer.isMobile
    const dispatch = getStore().dispatch
    // Este if optimiza mucho el rendimiento para no andar cambiando todo el tiempo el estado del contenedor
    if (isItMobile(sizeScreenGetted) && !isMobile) dispatch(setIsMobile(true))
    else if (!isItMobile(sizeScreenGetted) && isMobile) dispatch(setIsMobile(false))
}

const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
const LOGIN_ERROR = 'LOGIN_ERROR'
const SWITCH_ROLE = 'SWITCH_ROLE'
const CLEAR_ERRORS = 'CLEAR_ERRORS'
const SHOW_GREETING = 'SHOW_GREETING'
const ACCEPT_LEGAL_DISCLAIMER = 'ACCEPT_LEGAL_DISCLAIMER'
const UPDATE_PERSONAL_INFO = 'UPDATE_PERSONAL_INFO'
const SHOW_DROP_DOWN_MAIN = 'SHOW_DROP_DOWN_MAIN'
const SET_IS_MOBILE = 'SET_IS_MOBILE'
const LOADING = 'LOADING'
const UPDATE_ONBOARDING = 'UPDATE_ONBOARDING'
const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS'
const SET_COURSE_PERMISSIONS = 'SET_COURSE_PERMISSIONS'
const CLEAR_COURSE_PERMISSIONS = 'CLEAR_COURSE_PERMISSIONS'
const SET_CURRENT_COURSE_ID = 'SET_CURRENT_COURSE_ID'
const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS'
const SET_PAYMENT_XUOW = 'SET_PAYMENT_XUOW'
const CLEAR_PAYMENT_XUOW = 'CLEAR_PAYMENT_XUOW'

const userSession = getUserSession()
const initialState = {
    user: {
        paymentMethods: [],
        paymentXuow: {
            xuow: ''
        },
        ...(userSession ?? {})
    },
    permissions: userSession?.permissions ?? [],
    coursePermissions: [],
    error: { message: '' },
    isAuthenticated: !!userSession?.id,
    availableRoles: [],
    showGreeting: true,
    first_time: false,
    showDropDown: true,
    studentCourseTypes: { isLoaded: false, coursetypes: [] },
    loading: false,
    isMobile: false,
    currentCourseId: 0
}

//CONSTANTS

const SUCCESSFUL_PASSWORD = 'SUCCESSFUL_PASSWORD'

const LOAD_STUDENT_COURSE_TYPES = 'LOAD_STUDENT_COURSE_TYPES'

//ACTIONS

export const loadUserCourseTypesAction = (data) => ({
    type: LOAD_STUDENT_COURSE_TYPES, data
})

export const successful = text => ({
    type: SUCCESSFUL_PASSWORD, text
})

export const updateUser = async (userId, password, verificationPassword) => {
    let config = getConfig()
    let body = {}
    if (password) body.password = password
    if (verificationPassword) body.verification_password = verificationPassword
    if (body.password) body.password_changed = true

    try {
        await axios.patch(api.users + '/' + userId, body, config)
        getStore().dispatch(successful('El usuario se actualizó correctamente'))
    } catch (e) {
        console.log("fallo el 0password update", e)
    }
}

export const updateOnboarding = (userId, newStatus) => dispatch => {
    const config = getConfig()
    const body = { onboarding: newStatus }
    axios.patch(api.users + '/' + userId, body, config)
        .then((data) => {
            dispatch(updateOnboardingAction(newStatus))
        })
        .catch(e => {
            console.error('Fallo actualizar el onboarding - Razon: ' + e);

        })
}

/*
export const updateUser = (userId, password, verificationPassword) => dispatch => {
    const store = getStore()
    const allStates = store.getState()
    let config = getConfig()

    let body = {}
    if (password) body.password = password
    if (verificationPassword) body.verification_password = verificationPassword

    axios.patch(api.users + '/' + userId, body, config)
        .then(() => {
            // dispatch(successful('El usuario se actualizó correctamente'))
            dispatch(successful(allStates.langStore.authReducer.correctUserUpdate))
        })
        .catch(err => {
            // console.log("fallo el 0password update", err)
            console.log(allStates.langStore.authReducer.failedPasswordUpdate, err)
            //dispatch(queryError(getErrorResponse(err)))
        })
}
*/

export const updatePersonalInfo = (data) => dispatch => {
    const store = getStore()
    const allStates = store.getState()
    let config = getConfig()
    data.password_changed = true
    //acordarse de chequear el id adentro de data
    axios.patch(api.users + '/' + data.id, data, config)
        .then((data) => {
            dispatch(updatePersonalInfoAction(data))
        })
        .catch(err => {
            // console.log("fallo el 0password update", err)
            console.log(allStates.langStore.authReducer.failedPasswordUpdate, err)
            //dispatch(queryError(getErrorResponse(err)))
        })
}


const setIsMobile = bool => ({
    type: SET_IS_MOBILE,
    payload: bool
})

export const loginError = err => ({
    type: LOGIN_ERROR, err
})

export const clearErrors = () => ({
    type: CLEAR_ERRORS
})

// normal action creators
export const login = (user, roles) => ({
    type: LOGIN_USER, user, roles
})

export const logout = () => ({
    type: LOGOUT_USER
})

export const switchRoleAction = (newRole) => ({
    type: SWITCH_ROLE, newRole
})

export const showGreetingAction = (status) => ({
    type: SHOW_GREETING, status
})

export const acceptLegalDisclaimerAction = (status) => ({
    type: ACCEPT_LEGAL_DISCLAIMER, status
})

export const updatePersonalInfoAction = (data) => ({
    type: UPDATE_PERSONAL_INFO, data
})

export const showDropDownMain = (data) => ({
    type: SHOW_DROP_DOWN_MAIN, data
})

export const loadingAction = (data) => ({
    type: LOADING, data
})

export const updateOnboardingAction = (status) => ({
    type: UPDATE_ONBOARDING, status
})

export const switchRole = (newRole) => dispatch => {
    location.href = '/'
    dispatch(switchRoleAction(newRole))
    // dispatch(loadPermissions(newRole.id))
}

export const updatePermissionsAction = data => ({
    type: UPDATE_PERMISSIONS, data
})

export const setCoursePermissionsAction = (permissions) => ({
    type: SET_COURSE_PERMISSIONS,
    payload: { permissions }
})

export const setCurrentCourseIdAction = (courseId) => ({
    type: SET_CURRENT_COURSE_ID,
    courseId
})

export const clearCoursePermissionsAction = () => ({
    type: CLEAR_COURSE_PERMISSIONS
})



export const acceptLegalDisclaimer = () => dispatch => {
    const store = getStore()
    const allStates = store.getState()
    let config = getConfig()

    dispatch(loadingAction(true))

    axios.post(api.legalDisclaimerAccept, true, config)
        .then(res => {
            dispatch(acceptLegalDisclaimerAction(res.legal_disclaimer))
            dispatch(loadingAction(false))
        })
        .catch(err => {
            // console.log("fallo al aceptar el legal disclaimer", err)
            console.log(allStates.langStore.authReducer.failedAcceptLegalDisclaimer, err)
        })
}

export const loadCoursePermissions = (courseId) =>
    async (dispatch, getState) => {
        const store = getStore()
        const allStates = store.getState()
        const currentId = getState().authReducer.currentCourseId

        if (currentId != courseId) {
            dispatch(setCurrentCourseIdAction(courseId))
            try {
                const userId = getState().authReducer.user.id
                const permissions = await axios.get(`${api.users}/${userId}/courses/${courseId}/ownerships/course-permissions`, getConfig())
                dispatch(setCoursePermissionsAction(permissions))
            } catch (e) {
                showNewMessage({
                    type: 'danger',
                    message: allStates.langStore.authReducer.permissionsError,
                    isTemporal: false,
                })
                location.href = '/'
            }
        }
    }

export const userRedirection = () => (dispatch, getState) => {
    
    let guardPath = getGuard();
    
    // Se comenta todo esto porque causa problemas con el reload
    // let guard = null, redirectUrl = null
    // const hasBoAccess = hasBackofficeAccessSelector(getState())
    // if (options.redirectGuard === ADMIN_GUARD && hasBoAccess) {
    //     guard = ADMIN_GUARD
    //     redirectUrl = options.redirect || '/'
    //         } 
    // else if (options.redirectGuard === STUDENT_GUARD) {
    //     guard = STUDENT_GUARD
    //     redirectUrl = options.redirect || '/'
    //         } 
    // else if (hasBoAccess) {
    //     guard = ADMIN_GUARD
    //     redirectUrl = '/admin'
    // }
    // else {
    //     guard = STUDENT_GUARD
    //     redirectUrl = '/'
    // }
    setGuard(guardPath);
    // este redirect causa problemas con el reload
    // history.push(redirectUrl)
    dispatch(setFirstRedirect())
    dispatch(incomingDeployment())
    // location.href = redirectUrl;
}

export const userAuthentication = (user) => async (dispatch) => {
    const { token, id, name, surname, email, username, roles, legal_disclaimer } = user
    dispatch(login(user, roles))
    const permissions = await axios.get(api.users + '/' + id + '/permissions', getConfig())
    setUserSession({ token, id, name, surname, email, username, permissions, legal_disclaimer, tenant: getTenantSession()._id })
    dispatch(updatePermissionsAction(permissions))
    dispatch(userRedirection())
}

export const loginUser = (username = '', password = '', config) =>
    async (dispatch) => {
        try {
            const user = await axios.post(api.login + '/native', { username, password }, {
                headers: {
                    'tenant-id': getTenantSession()._id,
                    'ApiVersion': VERSION
                }
            })
            await dispatch(userAuthentication(user, config))
            identify(user.id, {
                email: user.email,
                first_name: user.name,
                last_name: user.surname
            })
        } catch (e) {
            const store = getStore()
            const allStates = store.getState()
            httpHandler(e)({
                [codes.CONFLICT]: () => {
                    dispatch(loginError(allStates.langStore.authReducer.invalidUser))
                },
                [codes.UNPROCESSABLE_ENTITY]: () => {
                    dispatch(loginError(allStates.langStore.authReducer.inactiveUser))
                }
            })
        }
    }

export const signToken = (accessToken, options) =>
    async (dispatch) => {
        try {
            const user = await axios.post(api.oauth + '/sign-token?tenantId=' + getTenantSession()._id, { accessToken })
            await dispatch(userAuthentication(user, options))
        } catch (e) {
            const store = getStore()
            const allStates = store.getState()
            httpHandler(e)({
                [codes.CONFLICT]: () => {
                    dispatch(loginError(allStates.langStore.authReducer.invalidUser))
                },
                [codes.UNPROCESSABLE_ENTITY]: () => {
                    dispatch(loginError(allStates.langStore.authReducer.inactiveUser))
                }
            })
        }
    }

export const logoutUser = () => dispatch => {};

export const setPaymentMethods = (paymentMethods) => ({
    type: SET_PAYMENT_METHODS,
    paymentMethods
})

export const setPaymentXuow = (xuow) => ({
    type: SET_PAYMENT_XUOW,
    xuow,
})

export const clearPaymentXuow = () => ({
    type: CLEAR_PAYMENT_XUOW,
})

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_COURSE_PERMISSIONS: {
            return {
                ...state,
                coursePermissions: action.payload.permissions
            }
        }
        case CLEAR_COURSE_PERMISSIONS: {
            return {
                ...state,
                coursePermissions: [],
                currentCourseId: null
            }
        }
        case SET_IS_MOBILE:
            return {
                ...state,
                isMobile: action.payload
            }
        case LOGIN_USER:
            return {
                ...state,
                user: {...state.user, ...action.user},
                error: {},
                availableRoles: action.roles,
                isAuthenticated: true,
            }
        case UPDATE_PERMISSIONS:
            return {
                ...state,
                permissions: action.data
            }
        case SUCCESSFUL_PASSWORD:
            return {
                ...state,
                user: {
                    ...state.user,
                    mustChangePassword: false,
                },
                alert: { style: 'success', text: action.text }
            }

        case LOGOUT_USER:
            return { ...initialState, isMobile: isItMobile(getScreenSize()), sessionClosed: true }
        case LOGIN_ERROR:
            return { ...state, user: {}, permissions: [], availableRoles: [], error: { message: action.err }, isAuthenticated: false }
        case CLEAR_ERRORS:
            return { ...state, error: {} }
        case SHOW_GREETING:
            return { ...state, showGreeting: action.status }
        case ACCEPT_LEGAL_DISCLAIMER:
            return {
                ...state, user: {
                    ...state.user,
                    legal_disclaimer: action.status,
                },
                first_time: true
            }
        case UPDATE_PERSONAL_INFO:
            return {
                ...state,
                user: { ...state.user, name: action.data.name, surname: action.data.surname, mustChangePassword: false },
                first_time: false,
            }
        case SHOW_DROP_DOWN_MAIN:
            return {
                ...state,
                showDropDown: action.data
            }
        case LOAD_STUDENT_COURSE_TYPES:
            return {
                ...state,
                studentCourseTypes: {
                    isLoaded: true,
                    coursetypes: action.data
                }
            }
        case LOADING:
            return {
                ...state,
                loading: action.data
            }
        case UPDATE_ONBOARDING:
            return {
                ...state,
                user: { ...state.user, onboarding: action.status }
            }
        case SET_CURRENT_COURSE_ID:
            return {
                ...state,
                currentCourseId: action.courseId
            }
        case SET_PAYMENT_METHODS:
            return {
                ...state,
                user: { ...state.user, paymentMethods: action.paymentMethods },
            }
        case SET_PAYMENT_XUOW:
            return {
                ...state,
                user: { ...state.user, paymentXuow: {
                    xuow: action.xuow
                }}
            }
        case CLEAR_PAYMENT_XUOW:
            return {
                ...state,
                user: { ...state.user, paymentXuow: {
                    xuow: ''
                }}
            }
        default:
            return state
    }
}
