import NotFoundPage from '@/components/NotFoundPage'
import NonSecuredLayout from '@/components/Layout/NonSecuredLayout'

const routes = [
    {
        path: '/*',
        key: 'Common - Not Found',
        exact: true,
        strict: false,
        component: NotFoundPage,
        layout: NonSecuredLayout,
    },
]

export default routes
