import { setLoading } from '../reducers/loadingReducer'
import httpHandler from '@/helpers/error-handling/httpHandler'
import { actions as managerActions } from '../reducers/managerReducer'

export const managerDataLoader = ({
    loadingName,
    serviceHandler,
    errorHandlerObject,
    thunkExtension,
}) => () => async (dispatch, getState) => {
    if (!loadingName || typeof loadingName !== 'string')
        throw new Error('loadingName is required!')
    if (!serviceHandler || typeof serviceHandler !== 'function')
        throw new Error('serviceHandler is required!')
    if (thunkExtension && typeof thunkExtension !== 'function')
        throw new Error('thunkExtension must be a function')

    const managerStore = getState().manager

    dispatch(setLoading.true(loadingName))
    const [error, result] = await serviceHandler({
        page: managerStore.pagination.currentPage,
        ...managerStore.searcher,
    })
    dispatch(setLoading.false(loadingName))

    if (error) return httpHandler(error)(errorHandlerObject)

    if(result.pagesQuantity !== undefined){
        dispatch(managerActions.setPaginationDefaultValues(result))
    } else {
        dispatch(managerActions.setRowsValues(result))
    }


    if (thunkExtension) dispatch(thunkExtension(result))
}

export const hola = () => {}