import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import MonacoEditor from 'react-monaco-editor'

import { monacoOptions } from '@/helpers/utils'
import CustomTabs from '../CustomTabs'
import Playstrap from '../Playstrap'

const HTMLEditor = ({
    html,
    editorWidth,
    editorHeight,
    handleChange
}) => {

    const lang = useSelector(({ langStore }) => langStore.htmlEditor)
    const monacoDidMount = editor => editor.focus()

    const onWrite = useCallback(value => {
        handleChange(value)
    }, [handleChange])


    return (
        <CustomTabs
            activeKey='editor'
            tabs={[
                {
                    key: 'editor',
                    title: lang.editor,
                    component: (
                        <div
                            className='_margin_5'
                        >
                            <MonacoEditor
                                width={editorWidth}
                                height={editorHeight}
                                language='html'
                                theme='vs-dark'
                                value={html}
                                options={{
                                    ...monacoOptions
                                }}
                                onChange={onWrite}
                                editorDidMount={
                                    monacoDidMount
                                }
                            />
                        </div>
                    )
                },
                {
                    key: 'preview',
                    title: lang.preview,
                    component: (
                        <div
                            style={{
                                maxWidth: editorWidth,
                                height: editorHeight,
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                overflow: 'auto',
                                border: '1px solid var(--light-gray)',
                                borderRadius: '10px'
                            }}
                        >
                            <Playstrap
                                data={html}
                            />
                        </div>
                    )
                }
            ]}
        />
    )
}


HTMLEditor.propTypes = {
    html: PropTypes.string.isRequired,
    editorWidth: PropTypes.string,
    editorHeight: PropTypes.string,
    handleChange: PropTypes.func.isRequired
}
HTMLEditor.defaultProps = {
    editorWidth: '100%',
    editorHeight: '350px',
}

export default HTMLEditor