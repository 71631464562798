import React from 'react'
import { useParams } from 'react-router-dom'
import useQuizMf from '../useQuizMf'

export const QuizEdit = () => {
    const MF_ID = 'mfBoQuiz'
    const MF_NAME = 'edit'
    const SELECTOR_ID = 'mfBoQuizEdit'

    const params = useParams()
    const { quizId } = params

    useQuizMf(MF_ID, SELECTOR_ID, MF_NAME, {
        quizId,
    })

    return <div id={SELECTOR_ID} className='quiz-app-general-container' />
}

export default QuizEdit
