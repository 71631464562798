import React from 'react'
import { useSelector } from 'react-redux'
import preloader from '../../../public/imgs/preloader.gif'

const Preloader = () => {

    const showPreloader = useSelector(({ navigationReducer }) => navigationReducer.showPreloader)

    if (!showPreloader) return null

    return (
        <div id="animationWindow">
            <img src={preloader} alt="preloader" />
        </div>
    )

}

export default Preloader