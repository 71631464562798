import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import getHeaders from '../components/Headers'
import { getGradesList, updateGradebook } from '@/redux/thunks/dashboardThunks'
import {
    setGradesListModal,
    setGradesListModalError,
    clearGradesList,
} from '@/redux/reducers/dashboardReducer'
import { getTenantLang } from '@/helpers/tenants/helpers'
import { monthConfig } from '../helpers/dateConfig'
import { formatFullDate, formatUsers, filterUsers } from '../helpers'
import { setGradesListPublishModal } from '@/redux/reducers/dashboardReducer/index'

const useGradeList = (strategyId, courseId) => {
    const dispatch = useDispatch()
    const location = getTenantLang()
    const lang = useSelector(({ langStore }) => langStore.notebook)
    const {
        gradesList: gradesListState,
        gradesListModal: { show, error },
    } = useSelector(({ dashboardReducer }) => dashboardReducer)
    const loading = useSelector(({ loading }) => loading.dashboard)
    const { assessmentsTypes, users, userProgress, lastPublishDate, step } = gradesListState
    const tableHeaders = getHeaders(assessmentsTypes, lang.table)
    const openModal = () => dispatch(setGradesListModal(true))
    const closeModal = () => {
        dispatch(setGradesListModal(false))
        dispatch(setGradesListModalError(null))
        setGradesFiles([])
    }
    const [textToFilter, setTextToFilter] = useState('')
    const [formattedUsers, setFormattedUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])
    const [gradesFiles, setGradesFiles] = useState([])
    const [existData, setExistData] = useState(false)

    const openPublishModal = () => dispatch(setGradesListPublishModal(true))

    const handleGradesFiles = (file) => setGradesFiles(file)

    const sendUpdatedGradebook = async (file) => dispatch(updateGradebook(courseId, file))

    const getMessageBoxText = useCallback(() => {
        let data = { text: '', theme: 'danger' }

        if (error) {
            const errorSplited = error.split('. ')
            const showTitle = errorSplited.length === 2
            data = {
                title: showTitle ? `${errorSplited[0]}.` : error,
                text: showTitle ? errorSplited[1] : '',
                theme: 'danger',
            }
        }

        return data
    }, [error])

    const formatedDate = useCallback(
        () => formatFullDate(lastPublishDate, location),
        [lastPublishDate]
    )

    const handleDownloadGrades = useCallback(() => {}, [])

    useEffect(() => {
        const [hasData, formatedUsers] = formatUsers(users, userProgress)
        setExistData(hasData)
        setFormattedUsers(formatedUsers)
        setGradesFiles([])
    }, [users])

    useEffect(() => {
        if (strategyId && courseId) {
            dispatch(getGradesList(strategyId, courseId))
        }

        return () => {
            dispatch(clearGradesList())
        }
    }, [courseId, strategyId])

    useEffect(() => {
        setFilteredUsers(filterUsers(formattedUsers, textToFilter))
    }, [formattedUsers, textToFilter])

    useEffect(() => {
        moment.updateLocale(...monthConfig(location))
        return () => moment.locale(location)
    }, [location])

    const emptyMessage = useCallback(() => {
        return strategyId ? lang.emptyState.withoutEnrolments : lang.emptyState.withoutStrategy
    }, [strategyId])

    return {
        grades: filteredUsers,
        tableHeaders,
        openModal,
        showModal: show,
        closeModal,
        error,
        textToFilter,
        setTextToFilter,
        count: users.length,
        existData,
        lang,
        loading,
        sendUpdatedGradebook,
        gradesFiles,
        handleGradesFiles,
        getMessageBoxText,
        emptyMessage,
        lastPublishDate: formatedDate(),
        handleDownloadGrades,
        step,
        openPublishModal,
    }
}

export default useGradeList
