import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { isMobileSelector } from '../../../redux/selectors/auth'
import { atomAsset } from '@/assets/img/config'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import './QuizCover.scss'
import CoverDetail from './CoverDetail'

const QuizCover = ({
    quiz: { name, description, attempts, approvalPercentage, isSurvey, affectsProgress },
    questionsNumber,
    lang,
    onClickStartQuiz,
}) => { 
    const isMobile = useSelector(isMobileSelector)
    const {
        textFeedbackInfo,
        surveyInfoMessage,
        startQuiz,
        rightAnswersPercentage,
        questions,
        unlimited
    } = lang

    return (
        <div className='quiz-cover'>
            <div className='quiz-cover__header'>
                <div className='quiz-cover__header__left-container'>
                    <div className='quiz-cover__header__left-container__image-container'>
                        <div className='quiz-cover__header__left-container__image-container__box'>
                            <img src={atomAsset('sheet-holder.svg')} alt='' />
                        </div>
                    </div>

                    <div className='quiz-cover__header__left-container__text'>
                        <div className='quiz-cover__header__left-container__text__title'>
                            {name}
                        </div>
                        <div className='quiz-cover__header__left-container__text__description'>
                            {description}
                        </div>
                    </div>
                </div>
                <Button
                    className='quiz-cover__header__start-button'
                    onClick={onClickStartQuiz}
                    text={startQuiz}
                    theme='secondary'
                    visible={!isMobile}
                />
            </div>
            <div className='quiz-cover__separator' />
            <div className='quiz-cover__details'>
                {!isSurvey && !affectsProgress &&
                    <MessageBox
                        title={textFeedbackInfo}
                        theme={'info'}
                        fullWidth={true}
                    />
                }
                {isSurvey &&
                    <MessageBox 
                        title={surveyInfoMessage} 
                        theme={'info'} 
                        fullWidth={true} 
                    />
                }
                {!isSurvey && 
                    <div className='quiz-cover__details__approval-percentage'>
                        {rightAnswersPercentage}
                        <b>{approvalPercentage}</b>
                    </div>
                }
                <div className='quiz-cover__details__footer'>
                    <CoverDetail attempts={attempts} showAttempts={!isSurvey} questionsNumber={questionsNumber} lang={lang} />
                </div>
            </div>
            <div className='quiz-cover__footer'>
                <Button
                    className='quiz-cover__footer__start-button'
                    onClick={onClickStartQuiz}
                    text={startQuiz}
                    theme='secondary'
                    visible={isMobile}
                />
            </div>
        </div>
    )
}

QuizCover.propTypes = {
    quiz: PropTypes.object,
    questionsNumber: PropTypes.number,
    lang: PropTypes.object,
    onClickStartQuiz: PropTypes.func,
}

QuizCover.defaultProps = {
    title: '',
    description: '',
    attemps: 3,
    approvalPercentage: 0,
}

export default QuizCover
