import React from 'react'
import PropTypes from 'prop-types'

import './TextBlock.scss'
import { BLOCK_TYPES } from '@/constants/blocks'
import NotionBlock from './NotionBlock'

const TextBlock = ({ text, id }) => {
    const iframeId = `TextBlock-${id}`
    const contentId = `TextBlockContent-${id}`

    const enhancedText = `
        <script>
            window.onload = function() {
                const iframe = window.parent.document.getElementById('${iframeId}')
                const container = document.getElementById('${contentId}')
                const height = Math.max(
                    document.documentElement["clientHeight"],
                    document.body["scrollHeight"],
                    document.documentElement["scrollHeight"],
                    document.body["offsetHeight"],
                    document.documentElement["offsetHeight"]
                )
                iframe.style.height = height + 'px'
            }
        </script>
        <style>body { overflow: hidden; font-family: "Source Sans Pro", sans-serif; }</style>
        <div id='${contentId}'>${text}</div>
    `

    const notionPageIdMatch = text.match(BLOCK_TYPES.NOTION_BLOCK.regex);
    const notionPageId = notionPageIdMatch ? notionPageIdMatch[1] : null
    
    if (notionPageId) {
        return <NotionBlock pageId={notionPageId} id={id} />
    }

    return (
        <iframe
            id={iframeId}
            srcDoc={enhancedText}
            className='text-block-iframe'
            sandbox='allow-forms allow-scripts allow-same-origin allow-modals allow-popups allow-presentation'
        />
    )
}

TextBlock.propTypes = {
    text: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
}

export default TextBlock
