'use-strict'

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import controller from '@/redux/reducers/_deprecated/roleReducer/controller'
import routing from '@/redux/reducers/_deprecated/roleReducer/routing'
import roleActions from '@/redux/reducers/_deprecated/roleReducer/actions'
import RoleForm from './RoleForm'


const RoleFormContainer = ({
    lang,
    match,
    activeRole,
    defaultPermissionsRole,
    loading,
    allPermissions,
    clearActiveRole,
    clearRolePermissions
}) => {

    const isCreate = /create/.test(match.url)

    React.useEffect(() => {
        controller.loadAllPermissions()

        if (isCreate) {
            clearActiveRole()
            clearRolePermissions()
        } else {
            controller.loadRole(match.params.id)
        }
    }, [])

    const getForm = () => {
        const formLang = lang.form
        const title = isCreate ? formLang.createNewRole : formLang.editOneRole
        const onSave = isCreate ? controller.saveNewRole : controller.updateOneRole

        return (
            <RoleForm
                title={title}
                lang={formLang}
                role={activeRole}
                loading={loading}
                handlers={{
                    setField: controller.setActiveRole,
                    updateRolePermissions: controller.updateRolePermissions,
                    onSave: onSave
                }}
                routing={routing}
                isCreate={isCreate}
                allPermissions={allPermissions}
                defaultPermissionsRole={defaultPermissionsRole}
            />
        )
    }

    return getForm()

}


const mapDispatch = dispatch => ({
    clearActiveRole: () => {
        dispatch(roleActions.clearActiveRole())
    },
    clearRolePermissions: () => {
        dispatch(roleActions.clearRolePermissions())
    }
})


const mapStateToProps = state => ({
    lang: state.langStore.roles,
    loading: state.roleManagerStore.loading,
    activeRole: state.roleManagerStore.activeRole,
    allPermissions: state.roleManagerStore.allPermissions,
    defaultPermissionsRole: state.roleManagerStore.defaultPermissionsRole,
})

export default withRouter(connect(mapStateToProps, mapDispatch)(RoleFormContainer))