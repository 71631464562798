import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const PrivacyPolicy = ({ lang }) => {
    return (
        <div id="legalDisclaimerConianer" className="container __legaldisclaimer">
            <div className="__card">
                <h1 style={{ textAlign: "center" }}> {lang.privacyPolicyTitle} </h1>
                <br />
                <ol id="privacyPolicy" dangerouslySetInnerHTML={{ __html: lang.privacyPolicyBody }} />
                <br />
            </div>
        </div>
    )
}

PrivacyPolicy.propTypes = {
    lang: PropTypes.any.isRequired
}

const mapStateToProps = state => ({
    lang: state.langStore.legalTerms,
})

export default connect(mapStateToProps)(PrivacyPolicy)
export { PrivacyPolicy, mapStateToProps }