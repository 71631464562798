import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import EditorSidebar from './EditorSidebar'
import EditorMain from './Main'
import './Editor.scss'

const Editor = ({
    navigation,
    sections,
    readOnlySections,
    files,
    handleChange,
    handleChangeFileName,
    setActiveFile,
    activeFile,
    readOnly,
    handleChangeDefaultFile,
    isAdministration,
    allowFilesNameChange,
}) => {
    const editorReference = React.createRef()
    const [showSidebar, setShowSidebar] = useState(true)
    const [openFiles, setOpenFiles] = useState([])
    const [activeSidebar, setActiveSidebar] = useState('files')
    const [defaultIsOpen, setDefaultIsOpen] = useState(false)
    const [showPopUp, setShowPopUp] = useState(false)

    const openFile = useCallback(
        (file) => {
            const exists = openFiles.find((f) => f._id === file._id)
            setActiveFile(file)
            if (!exists) setOpenFiles([...openFiles, file])
        },
        [setOpenFiles, openFiles]
    )

    const closeFile = useCallback(
        (file) => {
            const openFilesCopy = [...openFiles]
            const index = openFilesCopy.findIndex((f) => f._id === file._id)
            openFilesCopy.splice(index, 1)
            const [firstFile] = openFilesCopy
            if (firstFile) {
                setActiveFile(firstFile)
            } else {
                setActiveFile(null)
            }
            setOpenFiles(openFilesCopy)
        },
        [setActiveFile, setOpenFiles, openFiles]
    )

    const onWrite = useCallback((value) => handleChange(activeFile, value), [
        handleChange,
        activeFile,
    ])

    const changeActiveFile = useCallback((newFile) => setActiveFile(newFile), [setActiveFile])

    const onSaveFilename = useCallback(
        (oldName, name) => {
            const matchFiles = files.filter((f) => f.name === oldName)
            handleChangeFileName([
                ...files.filter((f) => !matchFiles.find((mf) => mf._id === f._id)),
                ...matchFiles.map((f) => ({ ...f, name })),
            ])
            setOpenFiles([
                ...openFiles.filter((f) => !matchFiles.find((mf) => mf._id === f._id)),
                ...openFiles.filter((f) => f.name === oldName).map((f) => ({ ...f, name })),
            ])
            setActiveFile({ ...activeFile, name })
        },
        [handleChangeFileName, activeFile]
    )

    useEffect(() => {
        if (files.length && !defaultIsOpen) {
            const defaultOpenFiles = files.filter((file) => file.defaultOpen)
            setActiveFile(defaultOpenFiles[0])
            setOpenFiles(defaultOpenFiles)
            setDefaultIsOpen(true)
        }
    }, [files])

    return (
        <>
            <div className='Editor'>
                <EditorSidebar
                    navigation={navigation}
                    show={showSidebar}
                    sections={sections}
                    readOnlySections={readOnlySections}
                    files={files}
                    activeFile={activeFile}
                    openFile={openFile}
                    setActiveFile={changeActiveFile}
                    setShowSidebar={setShowSidebar}
                    activeSidebar={activeSidebar}
                    setActiveSidebar={setActiveSidebar}
                    setDefaultFile={handleChangeDefaultFile}
                    isAdministration={isAdministration}
                    setShowPopUp={setShowPopUp}
                    allowFilesNameChange={allowFilesNameChange}
                />
                <EditorMain
                    editorReference={editorReference}
                    showPopUp={showPopUp}
                    showSidebar={showSidebar}
                    openFiles={openFiles}
                    files={files}
                    readOnlySections={readOnlySections}
                    activeFile={activeFile}
                    onWrite={onWrite}
                    setActiveFile={changeActiveFile}
                    setShowSidebar={setShowSidebar}
                    closeFile={closeFile}
                    setDefaultFile={handleChangeDefaultFile}
                    onSaveFilename={onSaveFilename}
                    setShowPopUp={setShowPopUp}
                    readOnly={
                        readOnly ||
                        readOnlySections.some(
                            (section) => activeFile && section === activeFile.section
                        )
                    }
                />
            </div>
        </>
    )
}

Editor.defaultProps = {
    readOnly: false,
    readOnlySections: [],
    navigation: [],
    handleChangeDefaultFile: () => {},
    setActiveFile: () => {},
    isAdministration: false,
    activeFile: null,
}

Editor.propTypes = {
    navigation: PropTypes.arrayOf(PropTypes.string.isRequired),
    sections: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    readOnlySections: PropTypes.arrayOf(PropTypes.string),
    files: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            content: PropTypes.string,
            language: PropTypes.string,
            section: PropTypes.string,
            defaultOpen: PropTypes.bool,
        })
    ).isRequired,
    activeFile: PropTypes.shape({
        name: PropTypes.string,
        content: PropTypes.string,
        language: PropTypes.string,
        section: PropTypes.string,
    }),
    handleChange: PropTypes.func.isRequired,
    setActiveFile: PropTypes.func,
    readOnly: PropTypes.bool,
    handleChangeDefaultFile: PropTypes.func,
    isAdministration: PropTypes.bool,
}

export default Editor
