import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import MonacoEditor from 'react-monaco-editor'
import { monacoOptions } from '@/helpers/utils'
import { logoAsset } from '@/assets/img/config/index'
import Header from './Header'
import FilenamePopup from './FilenamePopup'

const Main = ({
    showSidebar,
    readOnly,
    openFiles,
    files,
    activeFile,
    readOnlySections,
    editorReference,
    onWrite,
    setShowSidebar,
    setActiveFile,
    closeFile,
    showPopUp,
    onSaveFilename,
    setShowPopUp,
}) => {
    const monacodidmount = (editor) => editor.focus()
    const getEditorValue = () => {
        if (files.length && activeFile) {
            const file = files.find((file) => file._id === activeFile._id)
            let value = file?.content
            if (readOnly) {
                value = `// this file is read only, you cannot write it\n${value}`
            }
            return value
        }
        return ''
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (editorReference.current) editorReference.current.editor.layout()
        })
    }, [editorReference])

    useEffect(() => {
        setTimeout(() => {
            if (editorReference.current) {
                editorReference.current.editor.layout()
            }
        }, 200)
    }, [showSidebar, editorReference, openFiles, activeFile])

    return (
        <>
            <div className='main'>
                <Header
                    openFiles={openFiles}
                    showSidebar={showSidebar}
                    activeFile={activeFile}
                    setActiveFile={setActiveFile}
                    closeFile={closeFile}
                    readOnlySections={readOnlySections}
                    setShowSidebar={setShowSidebar}
                />
                <div
                    className='monaco'
                    style={{
                        paddingLeft: showSidebar ? '230px' : '40px',
                    }}
                >
                    {showPopUp && activeFile?.section === 'SRC' ? (
                        <FilenamePopup
                            fileName={activeFile?.name}
                            onSave={onSaveFilename}
                            onCancel={() => setShowPopUp(false)}
                        />
                    ) : openFiles.length ? (
                        <MonacoEditor
                            width='100%'
                            height='543'
                            language={activeFile?.language}
                            theme='vs-dark'
                            value={getEditorValue()}
                            options={{
                                ...monacoOptions,
                                readOnly,
                            }}
                            onChange={onWrite}
                            editorDidMount={monacodidmount}
                            ref={editorReference}
                        />
                    ) : (
                        <div className='not-open-files' style={{ height: `550px` }}>
                            <img src={logoAsset('dh-coding-school-full-black.png')} alt='logodh' />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

Main.defaultProps = {
    readOnly: false,
    showPopUp: false,
}

Main.propTypes = {
    showPopUp: PropTypes.bool,
    showSidebar: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool,
    files: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            content: PropTypes.string,
            language: PropTypes.string,
            section: PropTypes.string,
            defaultOpen: PropTypes.bool,
        })
    ).isRequired,
    openFiles: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            content: PropTypes.string,
            language: PropTypes.string,
            section: PropTypes.string,
            defaultOpen: PropTypes.bool,
        })
    ).isRequired,
    activeFile: PropTypes.shape({
        name: PropTypes.string,
        content: PropTypes.string,
        language: PropTypes.string,
        section: PropTypes.string,
        defaultOpen: PropTypes.bool,
    }).isRequired,
    readOnlySections: PropTypes.arrayOf(PropTypes.string).isRequired,
    editorReference: PropTypes.shape({
        current: PropTypes.shape(),
    }).isRequired,
    onWrite: PropTypes.func.isRequired,
    setShowSidebar: PropTypes.func.isRequired,
    setActiveFile: PropTypes.func.isRequired,
    closeFile: PropTypes.func.isRequired,
}

export default Main
