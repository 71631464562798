/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'

import _ from 'lodash'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Block from './Blocks/card/Block'

import {
    updateActiveCourseAction,
    toggleDragBlockAction,
    toggleDisableBlock,
    getCoursesToModify,
    loadActiveBlocks
} from '../../../../../../../redux/reducers/_deprecated/courseReducer'

import Dropdown from '@/components/Dropdown'
import ModalConfirmation from '@/components/ModalConfirmation'
import { actions as mamushkaActions } from '@/redux/reducers/mamushkaReducer'
import { actions as modalActions } from '@/redux/reducers/modalReducer'
import { DELETE_TOPIC_WARNING_MODAL } from '@/constants/modals'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import { Eye, EyeOff, Edit2, Move, ChevronDown, Trash2 } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import * as modalKeys from '@/constants/modals';
import { findBlockTypeByName, BLOCK_TYPES } from '@/constants/blocks'
import {
    EDIT_TOPIC_CONTENT,
    EDIT_TOPIC_VISIBILITY,
    ORDER_BLOCKS,
    CREATE_BLOCKS,
    DELETE_BLOCK,
} from '../../../../../../../constants/coursePermissions'
import { showTopicsAndBlocksActions, hideChangeVisibility } from '@/helpers/granters/hideMamushkaElements'
import BlockIcon from '@/components/BlockIcon'

import CourseSkeleton from '../../../Skeleton'
import '../../styles/Card.scss'
import langStates from '@/redux/reducers/_deprecated/langReducer/langStates'

export class TopicCard extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            items: [],
            renderBlocks: false,
            panelBodyOpen: true
        }

        this.reorder = this.reorder.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)
        this.showBlocks = this.showBlocks.bind(this)
        this.getBlocks = this.getBlocks.bind(this)
        this.handleOrder = this.handleOrder.bind(this)
        this.openModalTopicDelete = this.openModalTopicDelete.bind(this)
        this.openModalTopic = this.openModalTopic.bind(this)
        this.getBlocks = this.getBlocks.bind(this)
        this.onClickNewBlock = this.onClickNewBlock.bind(this)
        this.changeOrder = this.changeOrder.bind(this)
        this.renderPanelTitle = this.renderPanelTitle.bind(this)
        this.toggleRenderBlocks = this.toggleRenderBlocks.bind(this);
        this.onClickRemoveButton = this.onClickRemoveButton.bind(this)
    }

    handleOrder() {
        this.props.toggleDragBlockDisable()
    }

    changeOrder() {
        let data = {}
        data.courseId = this.props.courseId
        data.unitId = this.props.unitId
        data.lessonId = this.props.lessonId
        data.topicId = this.props.id
        data.activeCourse = this.props.activeCourse

        this.props.toggleDisableBlock(data)

        this.props.toggleDragBlockDisable()
    }

    /**
     * OPEN MODALS
     */
    openModalTopicDelete() {
        return this.modalTopicDelete.handleShow()
    }

    openModalTopic() {
        const {
            unitId,
            lessonId,
            name,
            id,
            visibility,
            order,
            courseId,
            getCoursesToModify,
            TopicRoles, disableStudentVisibility,
            openModal
        } = this.props
        const topicData = { unitId, lessonId, name, id, visibility, order, TopicRoles, disableStudentVisibility }        

        if (this.props.activeCourse.base) {
            if (id) {
                getCoursesToModify(id, 'topic', courseId)
            } else if (lessonId) {
                getCoursesToModify(lessonId, 'lesson', courseId)
            }
        }

        openModal(modalKeys.TOPIC_MODAL, topicData)
    }

    openModalVisibility() {
        let visibilityInfo = {
            courseId: this.props.courseId,
            unitId: this.props.unitId,
            lessonId: this.props.lessonId,
            topicId: this.props.id,
            visibilityRoute: 'topics',
            visibility: !this.props.visibility,
            modalText: this.props.lang.modalVisibilityText
        }
        this.props.openModal(modalKeys.VISIBILITY_MODAL, visibilityInfo)
    }

    onClickRemoveButton() {
        this.setMamushkaValues()
        this.props.openModal(DELETE_TOPIC_WARNING_MODAL)
    }

    getBlocks() {
        let course = this.props.activeCourse
        let unit = _.find(course.Units, { 'id': this.props.unitId })
        let lesson = _.find(unit.Lessons, { 'id': this.props.lessonId })
        let topic = _.find(lesson.Topics, { 'id': this.props.id })

        return topic.Blocks || []
    }

    showBlocks() {
        if (!this.state.renderBlocks) return null
        let course = this.props.activeCourse
        let unit = _.find(course.Units, { id: this.props.unitId })
        let lesson = _.find(unit.Lessons, { id: this.props.lessonId })
        let topic = _.find(lesson.Topics, { id: this.props.id })
        let blocks = topic.Blocks

        if (!blocks) {
            this.props.loadActiveBlocks(course.id, unit.id, lesson.id, topic.id)
            return <CourseSkeleton width='100%' height='100px' isBlock />
        }

        return blocks?.map((block) => (
            <Block
                block={block}
                key={block.id}
                courseId={course.id}
                unitId={unit.id}
                lessonId={lesson.id}
                topicId={topic.id}
            />
        ))
    }

    reorder(items, startIndex, endIndex) {
        let result = Array.from(items)
        let [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        let activeCourseCopy = _.cloneDeep(this.props.activeCourse)
        let unitAux = _.find(activeCourseCopy.Units, { 'id': this.props.unitId })
        let lessonAux = _.find(unitAux.Lessons, { 'id': this.props.lessonId })
        let topicAux = _.find(lessonAux.Topics, { 'id': this.props.id })

        let newBlocks = this.reorder(
            topicAux.Blocks,
            result.source.index,
            result.destination.index
        )

        newBlocks.map((item, index) => (
            item.order = index
        ))

        topicAux.Blocks = newBlocks
        this.props.updateElementsOrder(activeCourseCopy)
    }

    toggleRenderBlocks() {
        this.setState({ renderBlocks: !this.state.renderBlocks });
    }

    renderPanelTitle() {
        let title =
            <h3 className="__card_secondary_title"
                onClick={this.toggleRenderBlocks}>
                {this.props.name}
            </h3>
        return this.props.dragDisableTopics ? <Panel.Toggle>{title}</Panel.Toggle> : title
    }

    renderOrderButton() {
        return (
            <Button
                id="SortButton"
                visible={ORDER_BLOCKS()}
                text={this.props.dragDisableBlocks ? this.props.lang.sort : this.props.lang.finish}
                icon={<Move />}
                onClick={this.props.dragDisableBlocks ? this.handleOrder : this.changeOrder}
                disabled={!this.props.dragGeneralDisable && this.props.dragDisableBlocks}
                theme='tertiary'
                leftIcon
            />
        )
    }

    getDropdownItem(type, text) {
        return (
            <>
                <span className="dropdown--item-img">
                    <BlockIcon type={type} />
                </span>
                {text}
            </>
        )
    }

    renderCreateButton() {
        if (CREATE_BLOCKS()) {
            return (
                <Dropdown
                    ddStyle = '_new_btn--tertiary'
                    items={[
                        {
                            name: this.getDropdownItem(BLOCK_TYPES.EXERCISE_BLOCK.name, this.props.lang.exerciseBlock),
                            onClick: e => this.onClickNewBlock(e, BLOCK_TYPES.EXERCISE_BLOCK.name),
                            testId: 'exerciseBlock',
                        },
                        {
                            name: this.getDropdownItem(BLOCK_TYPES.TEXT_HTML_BLOCK.name, this.props.lang.textHtmlBlock),
                            onClick: e => this.onClickNewBlock(e, BLOCK_TYPES.TEXT_HTML_BLOCK.name),
                            testId: 'textHtmlBlock',
                        },
                        {
                            name: this.getDropdownItem(BLOCK_TYPES.TEXT_BLOCK.name, this.props.lang.textBlock),
                            onClick: e => this.onClickNewBlock(e, BLOCK_TYPES.TEXT_BLOCK.name),
                            testId: 'textBlock',
                        },
                        {
                            name: this.getDropdownItem(BLOCK_TYPES.QUIZ_BLOCK.name, this.props.lang.quizBlock),
                            onClick: e => this.onClickNewBlock(e, BLOCK_TYPES.QUIZ_BLOCK.name),
                            testId: 'quizBlock',
                        },
                        {
                            name: this.getDropdownItem(BLOCK_TYPES.VIDEO_BLOCK.name, this.props.lang.videoBlock),
                            onClick: e => this.onClickNewBlock(e, BLOCK_TYPES.VIDEO_BLOCK.name),
                            testId: 'videoBlock',
                        },
                        {
                            name: this.getDropdownItem(BLOCK_TYPES.SLIDE_BLOCK.name, this.props.lang.slideBlock),
                            onClick: e => this.onClickNewBlock(e, BLOCK_TYPES.SLIDE_BLOCK.name),
                            testId: 'slideBlock',
                        },
                        {
                            name: this.getDropdownItem(BLOCK_TYPES.PDF_BLOCK.name, this.props.lang.pdfBlock),
                            onClick: e => this.onClickNewBlock(e, BLOCK_TYPES.PDF_BLOCK.name),
                            testId: 'pdfBlock',
                        },
                        {
                            name: this.getDropdownItem(BLOCK_TYPES.FILE_BLOCK.name, this.props.lang.fileBlock),
                            onClick: e => this.onClickNewBlock(e, BLOCK_TYPES.FILE_BLOCK.name),
                            testId: 'fileBlock',
                        },
                        {
                            name: this.getDropdownItem(BLOCK_TYPES.EVALUABLE_BLOCK.name, this.props.lang.evaluableActivity),
                            onClick: e => this.onClickNewBlock(e, BLOCK_TYPES.EVALUABLE_BLOCK.name),
                            testId: 'evaluableBlock'
                        },
                        {
                            name: this.getDropdownItem(BLOCK_TYPES.NOTION_BLOCK.name, this.props.lang.notionBlock),
                            onClick: e => this.onClickNewBlock(e, BLOCK_TYPES.NOTION_BLOCK.name),
                            testId: 'notionBlock'
                        }
                    ].map(item => ({ ...item, className: 'dropdown--item' }))}
                    disabled={
                        this.props.dragDisableBlocks && this.props.dragGeneralDisable ? false : true
                    }
                >
                    <span>
                        {this.props.lang.createNewBlock}
                        <ChevronDown />
                    </span>
                </Dropdown>
            )
        }

        return null
    }

    setMamushkaValues() {
        const mamushka = {
            courseId: this.props.courseId,
            unitId: this.props.unitId,
            lessonId: this.props.lessonId,
            topicId: this.props.id,
        }
        this.props.setMamushkaValues(mamushka)
    }

    onClickNewBlock(e, value) {
        const { openModal, getCoursesToModify, id, courseId } = this.props
        const blockType = findBlockTypeByName(value)
        this.setMamushkaValues()
        if (this.props.activeCourse.base) {
            getCoursesToModify(id, "topic", courseId)
        }
        return blockType && blockType.modal && openModal(blockType.modal)
    }

    // getInheritsVisibility() {
    //     return (this.props.inheritsVisibilityColor || !this.props.visibility)
    // }

    // getVisibilityClass() {
    //     return this.getInheritsVisibility() ? 'notVisible' : ''
    // }

    render() {
        return (
            <>
                <Panel className={`__card left-border--success-light`}>
                    <Panel.Heading>
                        <Row>
                            <Col lg="8">
                                {this.renderPanelTitle()}
                            </Col>
                            <Col lg="4" className="__card_button_box __center_buttons_course">
                                <ButtonGroup>
                                    { showTopicsAndBlocksActions(this.props.activeCourse) &&
                                        <ButtonRound
                                            id="DeleteTopicButton"
                                            theme='danger'
                                            visible={DELETE_BLOCK()}
                                            icon={<Trash2 />}
                                            tooltipId='deleteTopicButton'
                                            tooltipContent={this.props.lang.deleteTopic}
                                            onClick={this.onClickRemoveButton}
                                        />
                                    }
                                    { !hideChangeVisibility(this.props.activeCourse) &&
                                        <ButtonRound
                                            id="ShowVisibilityButton"
                                            icon={this.props.visibility ? <Eye /> : <EyeOff />}
                                            onClick={this.openModalVisibility.bind(this)}
                                            disabled={!(this.props.dragDisableBlocks && this.props.dragGeneralDisable) || !EDIT_TOPIC_VISIBILITY()}
                                            tooltipId='visibilityTopicButton'
                                            tooltipContent={this.props.lang.changeVisibility}
                                            theme='info'
                                        />
                                    }
                                    { showTopicsAndBlocksActions(this.props.activeCourse) &&
                                        <ButtonRound
                                            id="EditButton"
                                            visible={EDIT_TOPIC_CONTENT()}
                                            icon={<Edit2 />}
                                            theme='primary_dark'
                                            onClick={this.openModalTopic}
                                            disabled={!(this.props.dragDisableTopics && this.props.dragGeneralDisable)}
                                            tooltipId='editTopicButton'
                                            tooltipContent={this.props.lang.editTopic}
                                        />
                                    }
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <Panel className={`__card left-border--success-light`}>
                            <Row className="__card_header">
                                <Col lg="3">
                                    <h3 className="__card_title __title_margin __font_weight_bold _cursor_pointer"
                                        onClick={() => {
                                            this.setState({ panelBodyOpen: !this.state.panelBodyOpen });
                                            this.toggleRenderBlocks();
                                        }}>
                                        {this.props.lang.blocks}
                                    </h3>
                                </Col>
                                { showTopicsAndBlocksActions(this.props.activeCourse) &&
                                    <Col lg="9" className="__card_button_box __center_buttons_course">
                                        <ButtonGroup>
                                            {this.renderOrderButton()}
                                            {this.renderCreateButton()}
                                        </ButtonGroup>
                                    </Col>
                                }
                            </Row>
                            {this.props.dragDisableBlocks ? this.state.panelBodyOpen && this.showBlocks() : <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId={'droppable-' + this.props.courseId + '-' + this.props.unitId + '-' + this.props.lessonId + '-' + this.props.topicId + '-' + this.props.id}>
                                    {(provided) => (
                                        <div ref={provided.innerRef} >
                                            {this.getBlocks().map((block, index) => (
                                                <Draggable key={index} draggableId={block.id} index={index} >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            style={{ backgroundColor: snapshot.isDraggingOver ? 'var(--primary)' : 'var(--disabled)' }}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Block block={block} key={block.id} courseId={this.props.courseId} unitId={this.props.unitId} lessonId={this.props.lessonId} topicId={this.props.id} />
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>}
                        </Panel>
                    </Panel.Body>
                </Panel>
                <ModalConfirmation
                    ref={(modalTopicDelete) => { this.modalTopicDelete = modalTopicDelete }}
                    confirmTitle={this.props.lang.confirmTitle}
                    cancelTitle={this.props.lang.cancelTitle}
                    body={this.props.lang.modalBody + 'asdasd'}
                    onConfirm={() => {/**Aun no se borra ningun tema**/ }}
                />
            </>
        )
    }
}

const mapDispatch = dispatch => ({
    openModal: (id, data) => {
        dispatch(modalActions.open(id, data))
    },
    updateElementsOrder: data => {
        dispatch(updateActiveCourseAction(data))
    },
    toggleDragBlockDisable: () => {
        dispatch(toggleDragBlockAction())
    },
    toggleDisableBlock: (data) => {
        dispatch(toggleDisableBlock(data))
    },
    getCoursesToModify: (id, type, courseId) => {
        dispatch(getCoursesToModify(id, type, courseId))
    },
    setMamushkaValues: values => {
        dispatch(mamushkaActions.setMamushkaValues(values))
    },
    loadActiveBlocks: (courseId, unitId, lessonId, topicId) => {
        dispatch(loadActiveBlocks(courseId, unitId, lessonId, topicId))
    },
})

const mapStateToProps = state => {
    return {
        activeCourse: state.courseReducer.activeCourse,
        dragDisableTopics: state.courseReducer.dragDisableTopics,
        dragDisableBlocks: state.courseReducer.dragDisableBlocks,
        dragGeneralDisable: state.courseReducer.dragGeneralDisable,
        lang: {
            ...langStates.topic,
            ...state.langStore.topic,
            ...state.langStore.courseIndexButtons
        },
        modalReducer: state.modal
    }
}

export default withRouter(connect(mapStateToProps, mapDispatch)(TopicCard))