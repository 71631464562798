import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getStudentsStatus, getStudentProgress } from '@/redux/thunks/dashboardThunks'
import {
    clearEvaluableActivityList,
    clearRejectTags,
    clearStudentProgress,
} from '@/redux/reducers/dashboardReducer'
import useHistory from '@/hooks/useHistory'
import * as helpers from '../utils/reviewHelpers'

const useStudentsStatus = (courseId, blockId, userId, blockType) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const lang = useSelector(({ langStore }) => langStore.dashboard)
    const { studentsList } = useSelector(({ dashboardReducer }) => dashboardReducer.evaluableActivity)

    const mappedStudentList = () => {
        return studentsList
            .filter((student) => userId !== student?.id)
            .map((student) => {
                const statusInfo = helpers.getStatusInfo(student.progressEvaluableLastStatus, lang)
                const mappedStudent = {
                    ...helpers.shapeStudentInfo(student),
                    rightIcon: statusInfo.icon,
                    iconTheme: statusInfo.theme,
                }
                return {
                    ...mappedStudent,
                    onClick: function () {
                        history.push(helpers.studentReviewUrl(courseId, blockId, student.id))
                    },
                }
            })
    }

    const [studentsDropdown, setStudentsDropdown] = useState(mappedStudentList())
    const [search, setSearch] = useState('')
    const [selected, setSelected] = useState({})

    const onChangeSearch = (value) => {
        const cleanSearchvalue = helpers.cleanString(value)

        setStudentsDropdown(
            mappedStudentList().filter(({ fullName, email }) => {
                const cleanFullName = helpers.cleanString(fullName)
                const cleanEmail = helpers.cleanString(email)
                return (
                    cleanFullName.includes(cleanSearchvalue) ||
                    cleanEmail.includes(cleanSearchvalue)
                )
            })
        )
        setSearch(value)
    }

    const cleanUp = () => {
        dispatch(clearEvaluableActivityList())
        dispatch(clearRejectTags())
        dispatch(clearStudentProgress())
    }

    useEffect(() => {
        setStudentsDropdown(mappedStudentList())
        setSearch('')

        const selectedStudent = studentsList.find((student) => student?.id === userId)
        setSelected(helpers.shapeStudentInfo(selectedStudent))
    }, [studentsList])

    useEffect(() => {
        dispatch(getStudentsStatus(courseId, blockId, blockType))
        dispatch(getStudentProgress(courseId, blockId, userId))
    }, [userId])

    useEffect(() => () => cleanUp(), [])

    return {
        studentsDropdown,
        search,
        selected,
        onChangeSearch,
        cleanUp,
    }
}

export default useStudentsStatus
