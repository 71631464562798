import React, { useState } from 'react'
import Searcher from '@digitalhouse-tech/react-lib-ui-explorer/lib/Searcher'
import { getNotionPage, refreshNotionPage } from '@/redux/http/services/notionService'
import langStates from '@/redux/reducers/_deprecated/langReducer/langStates'
import DefaultStaticAlert from '@/components/Alerts/DefaultStaticAlert'
import LoadingOrContent from '@/components/LoadingOrContent/LoadingOrContent'
import BlockIcon from '@/components/BlockIcon/BlockIcon'
import NotionPage from '@/components/NotionPage/NotionPage'
import { BLOCK_TYPES } from '@/constants/blocks'
import NotionForm from './NotionForm'
import './NotionRefresh.scss'

const lang = langStates.notionRefresh

const ALERT_DATA = {
  open: false,
  title: '',
  message: ''
}

const NotionRefresh = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [alertData, setAlertData] = useState(ALERT_DATA)
  const [previewData, setPreviewData] = useState({ loading: false })
  const [enabled, setEnabled] = useState(true)
  const [notionUrl, setNotionUrl] = useState('')

  const loadPreview = async (page) => {
    try {
      setPreviewData({loading: true})
      const { data } = await getNotionPage(page)

      setPreviewData({
        page,
        record: data,
        loading: false
      })
      
    } catch (e) {
      const { title, error } = lang
      setAlertData({ open: true, title, message: error })
      setPreviewData({ loading: false })
    }
  }

  const onCancel = () => setAlertData({ ...alertData, open: false })

  const onChange = (e) => setNotionUrl(e.target.value)

  const onDone = () => {
    const { label, success } = lang

    setNotionUrl('')
    setIsLoading(false)
    setAlertData({ open: true, title: label, message: success })
  }
  
  const onComplete = () => {
    loadPreview(notionUrl)
    setIsLoading(false)
    setEnabled(true)
  }

  const onError = (e) => {
    // const { label, error } = lang

    setEnabled(true)
    // TODO: set alert after fix CORS on refresh service request
    // setAlertData({ open: true, title: label, message: error })
    // TODO: remove set after fix CORS on refresh service request
    setNotionUrl('')
  }

  const refreshPage = async (e) => {
    e.preventDefault()
    setPreviewData({loading: false})
    
    if (notionUrl) {
      setIsLoading(true)
      setEnabled(false)

      await refreshNotionPage(notionUrl).then(onDone).catch(onError).finally(onComplete)
    }
  }

  const resetNotionUrl = () => setNotionUrl('')

  const getFormBody = () => {
    return (
      <NotionForm
        id='notion.url'
        label={lang.label}
        disabled={!enabled}
        value={notionUrl}
        onChange={onChange}
        onCloseIcon={resetNotionUrl}
      />
    )
  }

  return <>
    <DefaultStaticAlert
      lang={{ message: alertData.message, title: alertData.title }}
      show={alertData.open}
      showConfirmButton={false}
      onCancel={onCancel}
    />
    <Searcher
      formBody={getFormBody()}
      loadingStatus={isLoading}
      searchSubmitHandler={refreshPage}
      submitText={lang.refresh}
    />
    <LoadingOrContent loading={previewData?.loading} size='medium'>
      {!!previewData?.record &&
        <div className='container'>
          <div className='notion-refresh__preview-page'>
            <BlockIcon type={BLOCK_TYPES.NOTION_BLOCK.name} /> {previewData?.page}
          </div>
        
          <NotionPage record={previewData.record} />
        </div>
      }
    </LoadingOrContent>
  </>
}

export default NotionRefresh