import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { loadLastUserProgress, setStarredCourse } from '@/redux/thunks/courseThunks'
import useHistory from '@/hooks/useHistory'
import { dontReturnToWelcomeAction } from '@/redux/reducers/_deprecated/surveyReducer'
import moment from 'moment'

export const useActions = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const onClickGoToActivity = useCallback(
        async (courseId) => {
            const userProgress = await dispatch(loadLastUserProgress(courseId))
            if (!userProgress) return onClickGoToUnits(courseId)
            const { unit_id, lesson_id, topic_id } = userProgress
            if (!unit_id || !lesson_id || !topic_id) return onClickGoToUnits(courseId)
            dispatch(dontReturnToWelcomeAction())
            return history.push(
                `/course/${courseId}/unit/${unit_id}/lesson/${lesson_id}/topic/${topic_id}`
            )
        },
        [history, dispatch, onClickGoToUnits]
    )

    const onClickGoToUnits = useCallback(
        (courseId) => {
            dispatch(dontReturnToWelcomeAction())
            return history.push(`/course/${courseId}/units`)
        },
        [history, dispatch]
    )

    const onClickGoToLastSurvey = useCallback(
        (courseId, survey) => {
            return history.push(`/courses/${courseId}/lastSurvey`, { survey })
        },
        [history]
    )

    const onClickStarredCourse = useCallback(
        (courseId, date) => {
            const starredDate = !date ? moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss') : null
            const data = { course_id: courseId, starred_date: starredDate }
            dispatch(setStarredCourse(courseId, data))
        },
        [history, dispatch]
    )

    return {
        onClickGoToActivity,
        onClickGoToUnits,
        onClickGoToLastSurvey,
        onClickStarredCourse,
    }
}
