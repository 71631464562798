import useHistory from '@/hooks/useHistory'

const useRouteHandler = () => {
    const history = useHistory()

    const onCreateQuiz = () => history.push(`/admin/quizzes/create`)

    const onEditQuiz = (quizId) => history.push(`/admin/quizzes/${quizId}/edit`)

    const onCopyQuiz = (quizId) => history.push(`/admin/quizzes/${quizId}/copy`)

    const onGoToSearcher = () => history.push('/admin/quizzes')

    const resetNewQuizCreatedState = () => history.push({ state: { quizCreatedId: '' } })

    const onSaveRedirection = (quizCreatedId) => {
        history.push({ pathname: '/admin/quizzes', state: { quizCreatedId } })
    }

    return {
        onCreateQuiz,
        onEditQuiz,
        onCopyQuiz,
        onGoToSearcher,
        resetNewQuizCreatedState,
        onSaveRedirection,
    }
}

export default useRouteHandler
