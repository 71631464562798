import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { setLocation } from '@/routes/guards'
import { BACKOFFICE_LOCATION } from '@/constants/locations'

const BackofficeLayout = ({ children }) => {
    useEffect(() => {
        setLocation(BACKOFFICE_LOCATION)
    }, [])

    return (
        <>
            {children}
        </>
    )
}

BackofficeLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default BackofficeLayout
