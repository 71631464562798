import { GRAPH_API_URL } from '@/config/env';
import httpClient from '../httpClient'

const api = () => httpClient('', GRAPH_API_URL, {secured: true})

const query = `
  query OrganizationQuery($user_id: String!) {
    getOrganizationByUser(user_id: $user_id) {
      name
    }
  }
`;

export const getUserOrganizations = (userId) => api().post('/', {
  query: query,
  variables: {user_id: userId}
})
