import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { saveBlockProgressByBlock } from '@/redux/thunks/student/progress'
import { getBlockData } from '../utils'
import moment from 'moment'

import {
    ExerciseBlock,
    QuizBlock,
    EvaluableBlock,
} from '@/constants/blocks'

import {
    PROGRESS_EXERCISE,
    PROGRESS_QUIZ,
    PROGRESS_TEXT,
    PROGRESS_EVALUABLE,
} from '../../../../constants/student'

const noop = () => {}

import Blocks from './Blocks'

const SCHEMAS = {
    [QuizBlock]: PROGRESS_QUIZ,
    [ExerciseBlock]: PROGRESS_EXERCISE,
    [EvaluableBlock]: PROGRESS_EVALUABLE,
}

export default ({ block, BlockProgress }) => {
    const dispatch = useDispatch()

    const { finish_date, isOwner } = useSelector(
        ({
            student: {
                classroom: { content_map },
            },
        }) => ({
            finish_date: content_map.Course && content_map.Course.finish_date,
            isOwner: content_map?.User.isOwner,
        })
    )

    const { blockType, blockData } = getBlockData(block)
    const BlockComponent = Blocks[blockType]

    const { courseId, unitId } = useParams()

    const hasProgressCompleted = useMemo(() => BlockProgress && BlockProgress.completed, [
        BlockProgress,
    ])

    const isCourseExpired = useMemo(() => moment().isAfter(moment(finish_date)), [finish_date])

    const isConsumable = useMemo(() => [QuizBlock, ExerciseBlock, EvaluableBlock].includes(blockType), [
        blockType,
    ])

    const handleProgressReport = useCallback(
        ({ progress = {} } = {}) => {
            const shoudReportNonConsumableBlockProgress =
                !isCourseExpired && !hasProgressCompleted && isConsumable

            if (shoudReportNonConsumableBlockProgress) {
                return dispatch(
                    saveBlockProgressByBlock({
                        courseId: courseId,
                        blockId: block.id,
                        progress,
                        schema: SCHEMAS[blockType] || PROGRESS_TEXT,
                        unitId
                    })
                )
            }

            return noop
        },
        [
            blockType,
            dispatch,
            block.id,
            courseId,
            hasProgressCompleted,
            isConsumable,
            isCourseExpired,
            finish_date,
        ]
    )

    let available = true
    let extraProps = {}

    // @todo unify
    if (blockType === ExerciseBlock) {
        available = block.ExerciseBlock.Exercise.AVAILABLE,
        extraProps = {
            block: {
                [ExerciseBlock]: { exercise: blockData.Exercise, files: blockData.Exercise.files },
                ...block,
            },
            exercise: blockData.Exercise,
            moral: blockData.Exercise.moral,
            ...blockData.Exercise,
        }
    } else if (blockType === QuizBlock) {
        available = block.QuizBlock.Quiz.AVAILABLE
    }

    return {
        available,
        BlockComponent,
        handleProgressReport,
        extraProps,
        blockData,
        blockType,
        courseId,
        isCourseExpired,
        isOwner,
    }
}
