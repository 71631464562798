import React from 'react'
import PropTypes from 'prop-types'
import Spinner from '@digitalhouse-tech/react-lib-ui-explorer/lib/Spinner'
import useLogCapturer from '../useLogCapturer'
import './Terminal.scss'

const NodejsTerminal = ({ start, user, content, logs, throws, random }) => {
    const { stateLogs, stateThrows } = useLogCapturer({ random })

    return (
        <div className='CodingTerminal'>
            <div className='header'>
                <ul>
                    <li>
                        {start && (
                            <div className='terminalLoader'>
                                <Spinner />
                            </div>
                        )}
                        Terminal
                    </li>
                </ul>
            </div>
            <div className='logArea'>
                <>
                    <span className='greenLog'>{user}@playground:~$</span>
                    <span className='whiteLog'>npm install</span>
                    <br />
                    <span className='grayLog'>[1/4] </span>
                    <span className='whiteLog'>Resolving packages...</span>
                    <br />
                    <span className='grayLog'>[2/4] </span>
                    <span className='whiteLog'>Resolving packages...</span>
                    <br />
                    <span className='grayLog'>[3/4] </span>
                    <span className='whiteLog'>Resolving packages...</span>
                    <br />
                    <span className='grayLog'>[4/4] </span>
                    <span className='whiteLog'>Resolving packages...</span>
                    <br />
                    <span className='greenLog'>success</span>
                    <span className='whiteLog'>Already up-to-date.</span>
                    <br />
                    <span className='greenLog'>{user}@playground:~$</span>
                    <span className='whiteLog'>
                        nodemon index.js localhost 8080
                    </span>
                    <br />
                    <span className='yellowLog'>[nodemon] 1.18.4</span>
                    <br />
                    <span className='yellowLog'>[nodemon] watching: *.*</span>
                    <br />
                    <span className='greenLog'>
                        [nodemon] starting `index.js http://localhost:8080`
                    </span>
                    <br />
                    {[...logs, ...stateLogs].map((log, i) => (
                        <React.Fragment key={`log${i}`}>
                            <span className='whiteLog'>{log}</span>
                            <br />
                        </React.Fragment>
                    ))}
                    {[...throws, ...stateThrows].map((t, i) => (
                        <React.Fragment key={`throw${i}`}>
                            <span className='throwError'>ThrowError: {t}</span>
                            <br />
                        </React.Fragment>
                    ))}
                    <iframe
                        srcDoc={content}
                        className='frame'
                        key={random}
                        title='pg'
                        width='0px'
                        height='0px'
                        style={{ border: 'none' }}
                        sandbox='allow-forms allow-scripts allow-same-origin allow-modals allow-popups allow-presentation'
                    />
                </>
            </div>
        </div>
    )
}

NodejsTerminal.defaultProps = {
    start: false,
    user: 'dh',
    content: '',
    logs: [],
    throws: [],
}

NodejsTerminal.propTypes = {
    start: PropTypes.bool,
    user: PropTypes.string,
    content: PropTypes.string,
    logs: PropTypes.arrayOf(PropTypes.shape()),
    throws: PropTypes.arrayOf(PropTypes.shape()),
    random: PropTypes.number.isRequired,
}

export default NodejsTerminal
