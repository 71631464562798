import React from 'react'
import PropTypes from 'prop-types'
import { getTenantSession } from '@/helpers/tenants/helpers'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import InputPassword from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputPassword'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import MadeWithLove from '@digitalhouse-tech/react-lib-ui-explorer/lib/MadeWithLove'
import './CredentialsManager.scss'
import { useSelector } from 'react-redux'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import * as forms from '../helpers/constants'
import { useInputValidations } from './hooks/useInputValidations'
import { DH_WEB_URL } from '@/config/env'

const CredentialsManager = ({
    type,
    onSubmit,
    onBack,
    lang,
    notValidatedEmail,
    onChangeInput,
    inputValue,
    onChangeInputConfirm,
    inputConfirmValue,
    emailNotFound,
    hideGoBack,
}) => {
    const { logoUrl, dhLogoUrl, country } = getTenantSession()
    const DH_URL = `${DH_WEB_URL}/${country}`

    const loading = useSelector(({ loading }) => loading.recoverPassword.sendEmail)
    const [errorMessage, errorMessageConfirm, formValidity] = useInputValidations(
        type,
        inputValue,
        inputConfirmValue,
        lang
    )

    const onClickSubmit = () => {
        if (formValidity()) {
            onSubmit()
        }
    }

    const getInput = () => {
        const inputType = {
            [forms.EMAIL_FORM]: (
                <InputText
                    id='userMail'
                    type='text'
                    autoComplete='true'
                    value={inputValue}
                    onChange={(e) => onChangeInput(e)}
                    error={!!errorMessage}
                    errorMsg={errorMessage}
                    className='auth-page__card__input-box--username'
                    autoFocus
                    data-testid='emailInput'
                />
            ),
            [forms.PASSWORD_FORM]: (
                <InputPassword
                    id='userPassword'
                    value={inputValue}
                    onChange={(e) => onChangeInput(e)}
                    error={!!errorMessage}
                    errorMsg={errorMessage}
                    className='auth-page__card__input-box--username'
                    data-testid='passwordInput'
                />
            ),
        }
        return inputType[type]
    }

    const getInputConfirm = () => {
        const inputType = {
            [forms.EMAIL_FORM]: (
                <InputText
                    id='userMailConfirm'
                    type='text'
                    autoComplete='true'
                    value={inputConfirmValue}
                    onChange={(e) => onChangeInputConfirm(e)}
                    error={!!errorMessageConfirm}
                    errorMsg={errorMessageConfirm}
                    className='auth-page__card__input-box--username'
                    autoFocus
                    data-testid='emailInputConfirm'
                />
            ),
            [forms.PASSWORD_FORM]: (
                <InputPassword
                    id='userPasswordConfirm'
                    data-testid='userPasswordConfirm'
                    value={inputConfirmValue}
                    onChange={(e) => onChangeInputConfirm(e)}
                    error={!!errorMessageConfirm}
                    errorMsg={errorMessageConfirm}
                    className='auth-page__card__input-box--username'
                    data-testid='passwordInputConfirm'
                />
            ),
        }
        return inputType[type]
    }

    return (
        <>
            <div className='auth-page'>
                <div className='auth-page__card auth-page__card__reset-password'>
                    <h4 className='auth-page__card__title'>{lang.title}</h4>
                    {notValidatedEmail && (
                        <MessageBox
                            theme='danger'
                            text={lang.notValidatedEmail}
                            className='auth-page__message-box'
                        />
                    )}
                    {emailNotFound && (
                        <MessageBox
                            theme='danger'
                            text={lang.emailNotFound}
                            className='auth-page__message-box'
                        />
                    )}
                    <div className='auth-page__card__input-box'>
                        <Label
                            htmlFor={type === forms.EMAIL_FORM ? 'userMail' : 'userPassword'}
                            text={lang.input}
                            theme={errorMessage ? 'validation' : 'classic'}
                        />
                        {getInput()}
                    </div>
                    <div className='auth-page__card__input-box'>
                        <Label
                            htmlFor={type === forms.EMAIL_FORM ? 'userMailConfirm' : 'userPasswordConfirm'}
                            text={lang.inputConfirm}
                            theme={errorMessageConfirm ? 'validation' : 'classic'}
                        />
                        {getInputConfirm()}
                    </div>
                    <Button
                        id='submitButton'
                        className='auth-page__button'
                        theme='primary'
                        block
                        type='submit'
                        onClick={onClickSubmit}
                        text={lang.send}
                        loading={loading}
                        data-testid='submitButton'
                    />
                    {!hideGoBack && (
                        <Button
                            id='GoBackButton'
                            onClick={onBack}
                            theme='tertiary'
                            block
                            text={lang.returnButton}
                            data-testid='goBackButton'
                        />
                    )}
                    <div className='auth-page__card__reset-password__footer'>
                        <div className='auth-page__card__pg-logo'>
                            <img src={logoUrl} alt='Playground Logo' />
                        </div>
                        <MadeWithLove
                            altImg='Digital House'
                            className='auth-page__signature'
                            imgUrl={dhLogoUrl}
                            logoUrl={DH_URL}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

CredentialsManager.propTypes = {
    onSubmit: PropTypes.func,
    onBack: PropTypes.func,
    lang: PropTypes.object,
    onChangeInput: PropTypes.func,
    onChangeInputConfirm: PropTypes.func,
    inputValue: PropTypes.string,
    inputConfirmValue: PropTypes.string,
    notValidatedEmail: PropTypes.bool,
    type: PropTypes.string,
    emailNotFound: PropTypes.bool,
}

CredentialsManager.defaultProps = {
    onSubmit: () => {},
    onBack: () => {},
    lang: {},
    onChangeInput: () => {},
    onChangeInputConfirm: () => {},
    inputValue: '',
    inputConfirmValue: '',
    notValidatedEmail: false,
    emailNotFound: false,
}

export default CredentialsManager
