import _ from 'lodash'
import { Country, State, City } from 'country-state-city'
import api from '@/config/endpoints'
import axios from '@/app/requests'
import { getConfig, getErrorResponse } from '@/helpers/utils'


// INITIAL STATE
const initialState = {
  allInstitutes: {
    isLoaded: false
  },
  formattedIntitutes: [],
  result: [],
  activeSearch: {},
  alert: {},
  allInstitutesSearch: [],
  isSearchActive: false,
  allLevels: [],
  activeInstitute: {
    isLoaded: false
  },
  allCountries: [],
  allStates: [],
  valueState: null,
  valueCity: null,
  allCities: [],
  selectedCity: null,
  selectedState: null,
  selectedCountry: null,
  isLoadingInstitute: false

}


// CONSTANTS
const SUCCESSFUL_INSTITUTE = 'SUCCESSFUL_INSTITUTE'
const CLEAR_ALERT_INSTITUTE = 'CLEAR_ALERT_INSTITUTE'
const QUERY_ERROR_INSTITUTE = 'QUERY_ERROR_INSTITUTE'

const ALL_PAGINATED_INSTITUTES = 'ALL_PAGINATED_INSTITUTES'
const SEARCH_INSTITUTE = 'SEARCH_INSTITUTE'

const NEW_INSTITUTE = 'NEW_INSTITUTE'
const UPDATE_INSTITUTE = 'UPDATE_INSTITUTE'
const ALL_LEVELS = 'ALL_LEVELS'
const LOAD_ACTIVE_INSTITUTE = 'LOAD_ACTIVE_INSTITUTE'
const CLEAR_INSTITUTE = 'CLEAR_INSTITUTE'
const ALL_COUNTRIES = 'ALL_COUNTRIES'
const ALL_STATES = 'ALL_STATES'
const ALL_CITIES = 'ALL_CITIES'
const CLEAR_CITIES = 'CLEAR_CITIES'
const LOAD_ALL_INSTITUTES = 'LOAD_ALL_INSTITUTES'
const LOAD_CITY_VALUE = 'LOAD_CITY_VALUE'

const UPDATE_SELECTED_COUNTRY = 'UPDATE_SELECTED_COUNTRY'
const UPDATE_SELECTED_STATE = 'UPDATE_SELECTED_STATE'
const UPDATE_SELECTED_ID_TYPE = 'UPDATE_SELECTED_ID_TYPE'
const UPDATE_SELECTED_CITY = 'UPDATE_SELECTED_CITY'
const UPDATE_SELECTED_ORGANIZATION_TYPE = 'UPDATE_SELECTED_ORGANIZATION_TYPE'
const CLEAR_SELECTIONS = 'CLEAR_SELECTIONS'
const CLEAR_ACTIVE_INSTITUTE_SEARCH = 'CLEAR_ACTIVE_INSTITUTE_SEARCH'
const SET_ACTIVE_INSTITUTE_SEARCH = 'SET_ACTIVE_INSTITUTE_SEARCH'
const CLEAR_ALL_INSTITUTES = 'CLEAR_ALL_INSTITUTES'
const CHANGE_IS_LOADING = 'CHANGE_IS_LOADING'



// ACTIONS
export const successful = text => ({
  type: SUCCESSFUL_INSTITUTE, text
})
export const queryError = text => ({
  type: QUERY_ERROR_INSTITUTE, text
})
export const clearUsersAlert = () => ({
  type: CLEAR_ALERT_INSTITUTE
})

export const loadPaginatedInstitutesAction = data => ({
  type: ALL_PAGINATED_INSTITUTES, data
})

export const updateInstitutesAction = (data) => ({
  type: UPDATE_INSTITUTE, data
})

export const loadIntituteLevelsAction = (data) => ({
  type: ALL_LEVELS, data
})

export const loadInstituteAction = (data) => ({
  type: LOAD_ACTIVE_INSTITUTE, data
})

export const clearInstituteAction = () => ({
  type: CLEAR_INSTITUTE
})

export const loadCountriesAction = (data) => ({
  type: ALL_COUNTRIES, data
})

export const loadStatesByCountryAction = (data) => ({
  type: ALL_STATES, data
})

export const loadCitiesByStateAction = (valueState, data) => ({
  type: ALL_CITIES, valueState, data
})

export const clearCityAction = () => ({
  type: CLEAR_CITIES
})

export const loadAllInstitutesAction = (data) => ({
  type: LOAD_ALL_INSTITUTES, data
})

export const loadCityValueAction = (data) => ({
  type: LOAD_CITY_VALUE, data
})

export const changeSelectedCountry = (data) => ({
  type: UPDATE_SELECTED_COUNTRY, data
})

export const changeSelectedState = (data) => ({
  type: UPDATE_SELECTED_STATE, data
})

export const changeSelectedCity = (data) => ({
  type: UPDATE_SELECTED_CITY, data
})

export const changeSelectedOrganizationType = (data) => ({
  type: UPDATE_SELECTED_ORGANIZATION_TYPE, data
})

export const changeSelectedIdType = (data) => ({
  type: UPDATE_SELECTED_ID_TYPE, data
})

export const clearSelectionsAction = () => ({
  type: CLEAR_SELECTIONS,
})

export const clearInstituteActiveSearchAction = () => ({
  type: CLEAR_ACTIVE_INSTITUTE_SEARCH
})

export const setInstituteActiveSearchAction = (data) => ({
  type: SET_ACTIVE_INSTITUTE_SEARCH, data
})

export const clearAllInstitutesAction = () => ({
  type: CLEAR_ALL_INSTITUTES
})
export const changeLoadingInstituteAction = (status) => ({
  type: CHANGE_IS_LOADING, status
})


export const loadInstitute = (instituteId) => dispatch => {
  let config = getConfig()
  axios.get(api.institutes + '/edit/' + instituteId, config)
    .then(data => {
      dispatch(loadInstituteAction(data))
      if (data.Country)
        dispatch(loadStatesByCountry(data.Country.id))

      if (data.State)
        dispatch(loadCitiesByState({ label: data.State.name, value: data.State.id }))
    })
    .catch(err => {
      if (err.response && err.response.status) {
        dispatch(queryError(getErrorResponse(err)))
      } else {
        dispatch(getErrorResponse(err))
      }
    })
}

export const loadAllInstitutes = () => dispatch => {
  let config = getConfig()
  axios.get(api.institutes, config)
    .then(data => {
      dispatch(loadAllInstitutesAction(data))
    })
    .catch(err => {
      if (err.response && err.response.status) {
        dispatch(queryError(getErrorResponse(err)))
      } else {
        dispatch(getErrorResponse(err))
      }
    })
}

export const loadPaginatedInstitutes = (page, name) => async (dispatch) => {
    dispatch(changeLoadingInstituteAction(true))
    let config = getConfig()
    let route = api.institutes + '/' + page
    route += name ? '/' + name : ''

    try {
        const data = await axios.get(route, config)
        dispatch(loadPaginatedInstitutesAction(data))
        dispatch(changeLoadingInstituteAction(false))
    } catch (err) {
        if (err.response && err.response.status) {
            dispatch(changeLoadingInstituteAction(false))
            dispatch(queryError(getErrorResponse(err)))
        } else {
            dispatch(changeLoadingInstituteAction(false))
            dispatch(getErrorResponse(err))
        }
    }
}

export const saveNewInstitute = (data, allInstitutes) => dispatch => {
  const config = getConfig()
  const body = {
    name: data.name,
    contactPhone: data.phone,
    description: data.description,
    address: data.address,
    active: data.active,
    levels: data.levels,
    contactEmail: data.email,
    country: data.country ? data.country : null,
    state: data.state ? data.state : null,
    city: data.city ? data.city : null,
    organizationType: data.organizationType ? data.organizationType : null
  }
  axios.post(api.institutes, body, config)
    .then(savedInstitute => {
      let allInstitutesGeneralCopy = _.cloneDeep(allInstitutes)
      allInstitutesGeneralCopy.push(savedInstitute)
      allInstitutesGeneralCopy = _.orderBy(allInstitutesGeneralCopy, ['created_at'], ['desc'])
      if (allInstitutesGeneralCopy.length > allInstitutes.limit) {
        allInstitutesGeneralCopy.pop()
      }
      dispatch(updateInstitutesAction(allInstitutesGeneralCopy))
    })
    .catch(err => {
      dispatch(queryError(getErrorResponse(err)))
    })
}

export const updateOneInstitute = (data, allInstitutes) => dispatch => {
  let config = getConfig()
  let body = {
    id: data.id,
    name: data.name,
    contactPhone: data.phone,
    description: data.description,
    address: data.address,
    active: data.active,
    levels: data.levels,
    contactEmail: data.email,
    country: data.country ? data.country : null,
    state: data.state ? data.state : null,
    city: data.city ? data.city : null,
    organizationType: data.organizationType ? data.organizationType : null
  }
  axios.put(api.institutes + '/update/' + data.id, body, config)
    .then(updatedInstitute => {

      let allInstitutesCopy = _.cloneDeep(allInstitutes)
      _.remove(allInstitutesCopy, { 'id': updatedInstitute.id })
      allInstitutesCopy.push(updatedInstitute)
      allInstitutesCopy = _.orderBy(allInstitutesCopy, ['created_at'], ['desc'])

      dispatch(updateInstitutesAction(allInstitutesCopy))
    })
    .catch(err => {
      // dispatch(queryError(getErrorResponse(err)))
      dispatch(console.log("error", err))
    })

}

export const loadIntituteLevels = () => dispatch => {
  let config = getConfig()
  axios.get(api.levels, config)
    .then(data => {
      dispatch(loadIntituteLevelsAction(data))
    })
    .catch(err => {
      // dispatch(queryError(getErrorResponse(err)))
      dispatch(console.log("error", err))
    })
}

export const loadCountries = () => dispatch => {
  let config = getConfig()
  axios.get(api.countries, config)
    .then(data => {
      dispatch(loadCountriesAction(data))
    })
    .catch(err => {
      if (err.response && err.response.status) {
        dispatch(queryError(getErrorResponse(err)))
      } else {
        dispatch(getErrorResponse(err))
      }
    })
}

export const loadStatesByCountry = (country_id) => dispatch => {
  let config = getConfig()
  axios.get(api.states + '/country/' + country_id, config)
    .then(data => {
      dispatch(loadStatesByCountryAction(data))
    })
    .catch(err => {
      if (err.response && err.response.status) {
        dispatch(queryError(getErrorResponse(err)))
      } else {
        dispatch(getErrorResponse(err))
      }
    })
}

export const loadCitiesByState = (selected) => dispatch => {
  let config = getConfig()
  let state_id = selected.value
  axios.get(api.cities + '/states/' + state_id, config)
    .then(data => {
      dispatch(loadCitiesByStateAction(selected, data))
    })
    .catch(err => {
      if (err.response && err.response.status) {
        dispatch(queryError(getErrorResponse(err)))
      } else {
        dispatch(getErrorResponse(err))
      }
    })
}

const formatSelectInstitutes = institutes => {
  let institutesAux = []

  institutes.map(institute => {

    institutesAux.push({
      value: institute.id,
      label: institute.name
    })
  })
  return institutesAux
}

const formatSelect = data => {
  if (data == null || data == undefined) {
    return null
  }
  if (data?.length === undefined) {
    return { value: data.id, label: data.name }
  }
  return data.map(el => {

    return {
      value: el.id,
      label: el.name
    }
  })
}

const getCountry = ({ country }) => {
  const data = Country.getCountryByCode(country)

  if (!data) return null

  return {
    label: data.name,
    value: country
  }
}

const getState = ({ country, state }) => {
  const data = State.getStateByCodeAndCountry(state, country)

  if (!data) return null

  return {
    label: data.name,
    value: state
  }
}

const getCity = ({ country, state, city }) => {
  const data = City.getCitiesOfState(country, state).filter(item => item.name === city)[0]

  if (!data) return null

  return {
    label: data.name,
    value: city
  }
}

export default (state = initialState, action) => {

  switch (action.type) {
    case SUCCESSFUL_INSTITUTE:
      return {
        ...state,
        alert: { style: 'success', text: action.text }
      }
    case QUERY_ERROR_INSTITUTE:
      return {
        ...state,
        alert: { style: 'danger', text: action.text }
      }
    case CLEAR_ALERT_INSTITUTE:
      return {
        ...state,
        alert: {}
      }
    case ALL_PAGINATED_INSTITUTES:
      return {
        ...state,
        allInstitutes: {
          ...action.data,
          isLoaded: true
        }
      }
    case SEARCH_INSTITUTE:
      return {
        ...state,
        ...searchInstituteByName(state, action.data),
        isLoaded: true
      }
    case UPDATE_INSTITUTE:
      return {
        ...state,
        allInstitutes: {
          ...state.allInstitutes,
          institutes: action.data,
          isLoaded: true
        }
      }
    case ALL_LEVELS:
      return {
        ...state,
        allLevels: action.data
      }
    case LOAD_ACTIVE_INSTITUTE:
      return {
        ...state,
        activeInstitute: {
          ...action.data,
          isLoaded: true
        },
        selectedCountry: getCountry(action.data),
        selectedState: getState(action.data),
        selectedCity: getCity(action.data),
        selectedOrganizationType: action.data.organizationType,
        // valueState: formatSelect(action.data.State),
        // valueCity: formatSelect(action.data.City)
      }
    case CLEAR_INSTITUTE:
      return {
        ...state,
        activeInstitute: {
          country: null,
          isLoaded: false
        },
        allCountries: [],
        allStates: [],
        allCities: []
      }
    case ALL_COUNTRIES:
      return {
        ...state,
        allCountries: action.data,
        valueState: null,
        valueCity: null,
        allCities: []
      }
    case ALL_STATES:
      return {
        ...state,
        allStates: action.data,
        valueState: null,
        valueCity: null,
        allCities: []
      }
    case ALL_CITIES:
      return {
        ...state,
        allCities: action.data,
        valueState: action.valueState,
        valueCity: null,
      }
    case LOAD_CITY_VALUE:
      return {
        ...state,
        valueCity: action.data
      }
    case CLEAR_CITIES:
      return {
        ...state,
        valueCity: null
      }
    case LOAD_ALL_INSTITUTES:
      return {
        ...state,
        allInstitutes: {
          isLoaded: true,
          institutes: action.data
        },
        formattedInstitutes: formatSelectInstitutes(action.data),
      }
    case UPDATE_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: action.data,
        selectedState: null,
        selectedCity: null
      }
    case UPDATE_SELECTED_STATE:
      return {
        ...state,
        selectedState: action.data,
        selectedCity: null
      }
    case UPDATE_SELECTED_CITY:
      return {
        ...state,
        selectedCity: action.data
      }
    case UPDATE_SELECTED_ORGANIZATION_TYPE:
      return {
        ...state,
        selectedOrganizationType: action.data
      }
    case UPDATE_SELECTED_ID_TYPE:
      return {
        ...state,
        selectedIdType: action.data,
      }
    case CLEAR_SELECTIONS:
      return {
        ...state,
        selectedCountry: null,
        selectedState: null,
        selectedCity: null,
        selectedOrganizationType: null,
      }
    case CLEAR_ACTIVE_INSTITUTE_SEARCH:
      return {
        ...state,
        activeSearch: initialState.activeSearch,
        allInstitutes: initialState.allInstitutes,
      }
    case SET_ACTIVE_INSTITUTE_SEARCH:
      return {
        ...state,
        activeSearch: action.data
      }
    case CLEAR_ALL_INSTITUTES:
      return {
        ...state,
        allInstitutes: {
          isLoaded: false
        },
      }
    case CHANGE_IS_LOADING:
      return {
        ...state,
        isLoadingInstitute: action.status
      }
    default:
      return state
  }
}
