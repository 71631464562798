import httpClient from '../httpClient'
import * as blockApi from '../api/blockApi'
import { getTenantLang } from '@/helpers/tenants/helpers.js'

const api = (courseId, unitId, lessonId, topicId) =>
    httpClient(blockApi.nested(courseId, unitId, lessonId, topicId))

const lang = getTenantLang()

export const hasAnyProgress = (courseId, unitId, lessonId, topicId, blockId) => {
    return api(courseId, unitId, lessonId, topicId).get(`/${blockId}/has-any-progress`)
}

export const create = (courseId, unitId, lessonId, topicId, block, schemaName) => {
    return api(courseId, unitId, lessonId, topicId).post(`/${schemaName}`, block, {
        params: { lang }
    })
}

export const update = (courseId, unitId, lessonId, topicId, blockId, block, schemaName) => {
    return api(courseId, unitId, lessonId, topicId).patch(`/${blockId}/${schemaName}`, block, {
        params: { lang }
    })
}

export const remove = (courseId, unitId, lessonId, topicId, blockId) => {
    return api(courseId, unitId, lessonId, topicId).delete(`/${blockId}`, {
        params: { lang }
    })
}

export const getReport = reportUrl =>
    httpClient('').get(reportUrl, {
        responseType: 'blob',
    })
