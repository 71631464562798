import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import useCourseInfo from '../shared/hooks/useCourseInfo'
import { downloadClassProgressReport } from '@/redux/thunks/dashboardThunks'
import { MAX_NUMBER_DAYS_REPORT } from '../shared/constants/dashboard'

export default (courseId) => {
    const dispatch = useDispatch()

    const dateFormat = 'DD/MM/YYYY'
    const dateFormatToSubmit = 'YYYY-MM-DD'

    const lang = useSelector(({ langStore }) => langStore.dashboard)
    const downloadIsLoading = useSelector(
        ({ loading }) => loading.dashboard.classReportDownloadReport
    )

    const [dateFrom, setDateFrom] = useState(null)
    const [dateTo, setDateTo] = useState(null)
    const [validDateRange, setValidDateRange] = useState(false)
    const [alertTheme, setAlertTheme] = useState('info')
    const [alertMessage, setAlertMessage] = useState(lang.classReportDatesRange)

    const { name, alias, is_massive } = useCourseInfo(courseId)

    useEffect(() => {
        if (moment(dateFrom).isValid() && moment(dateTo).isValid()) verifyDateRange()
        else setValidDateRange(false)
    }, [dateTo, dateFrom])

    const onChangeDates = (date, type) => {
        if (type === 'from') setDateFrom(date)
        else setDateTo(date)
    }

    const verifyDateRange = () => {
        const datesDiff = dateTo.diff(dateFrom, 'day')
        const rangeIsValid = datesDiff <= MAX_NUMBER_DAYS_REPORT
        const alertThemeByRange = rangeIsValid ? 'info' : 'danger'
        const alertMessageByRange = rangeIsValid
            ? lang.classReportDatesRange
            : lang.classReportError

        setValidDateRange(rangeIsValid)
        setAlertTheme(alertThemeByRange)
        setAlertMessage(alertMessageByRange)
    }

    const downloadReport = () => {
        const initialDate = moment(dateFrom).format(dateFormatToSubmit)
        const finishDate = moment(dateTo).format(dateFormatToSubmit)
        dispatch(downloadClassProgressReport(courseId, initialDate, finishDate))
    }

    const fieldsError = dateFrom && dateTo && !validDateRange

    return {
        name,
        alias,
        is_massive,
        lang,
        dateFrom,
        dateTo,
        onChangeDates,
        validDateRange,
        downloadIsLoading,
        downloadReport,
        alertTheme,
        alertMessage,
        fieldsError,
        dateFormat,
    }
}
