import ExerciseBlockCmp from './ExerciseBlock'
import FileBlockComponent from './FileBlock'
import BasicBlock from './BasicBlock'
import QuizBlockCmp from './QuizBlock'
import EvaluableBlockComponent from './EvaluableBlock'

import {
    ExerciseBlock,
    FileBlock,
    PdfBlock,
    QuizBlock,
    SlideBlock,
    TextBlock,
    VideoBlock,
    TextHtmlBlock,
    EvaluableBlock,
} from '../../../../../constants/blocks'

export default {
    [ExerciseBlock]: ExerciseBlockCmp,
    [TextBlock]: BasicBlock,
    [VideoBlock]: BasicBlock,
    [SlideBlock]: BasicBlock,
    [QuizBlock]: QuizBlockCmp,
    [PdfBlock]: BasicBlock,
    [FileBlock]: FileBlockComponent,
    [TextHtmlBlock]: BasicBlock,
    [EvaluableBlock]: EvaluableBlockComponent,
}
