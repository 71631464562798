import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './SkeletonGroup.scss'

const SkeletonGroup = ({ children, containerStyles }) => {
    return (
        <div
            className={cn('skeleton-group-container', {
                [containerStyles]: containerStyles && (containerStyles !== null),
            })}
        >
            {children}
        </div>
    )
}

SkeletonGroup.propTypes = {
    children: PropTypes.node.isRequired,
    containerStyles: PropTypes.string,
}

SkeletonGroup.defaultProps = {
    containerStyles: null,
}

SkeletonGroup.defaultProps = {}

export default SkeletonGroup
