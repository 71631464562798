import React from 'react'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'

export class CustomAlert extends React.Component {
  render() {
    return (
      <Row key={this.props.rowKey}>
        <Col lg="12">
          <MessageBox
            centeredText
            fullWidth
            isTemporal
            onClose={this.props.onDismiss}
            text={this.props.message}
            theme={this.props.bsStyle}
          />
        </Col>
      </Row>
    )
  }
}
