import axios from '@/app/requests'
import * as env from '@/config/env'
import { getTenantLang } from '@/helpers/tenants/helpers'

export default async () => {
    const response = await axios.get(
        `${env.LANG_API_URL}?service=frontend&lang=${getTenantLang()}`,
        {
            headers: {
                'x-api-key': env.LANG_API_TOKEN,
            }
        }
    )
    return response
}
