const initialState = {
    static: {
        message: '',
        type: null,
        show: false,
    },
    closeClicked: false,
    turnOnDeploymentInterval: false,
    stacked: [],
    forceReloadWithoutCache: false,
}

const LOGOUT_USER = 'LOGOUT_USER'
const SHOW_STATIC_ALERT = 'SHOW_STATIC_ALERT'
const HIDE_STATIC_ALERT = 'HIDE_STATIC_ALERT'
const SHOW_STACKED_ALERT = 'SHOW_STACKED_ALERT'
const HIDE_STACKED_ALERT = 'HIDE_STACKED_ALERT'
const FORCE_RELOAD_WITHOUT_CACHE = 'FORCE_RELOAD_WITHOUT_CACHE'
const CLOSE_CLICKED = 'CLOSE_CLICKED'
const TURN_ON_DEPLOYMENT_INTERVAL = 'TURN_ON_DEPLOYMENT_INTERVAL'

export const showStaticAlert = payload => ({ type: SHOW_STATIC_ALERT, payload })
export const hideStaticAlert = payload => ({ type: HIDE_STATIC_ALERT, payload })
export const showStackedAlert = payload => ({ type: SHOW_STACKED_ALERT, payload })
export const hideStackedAlert = payload => ({ type: HIDE_STACKED_ALERT, payload })
export const closeClicked = payload => ({ type: CLOSE_CLICKED, payload })
export const turnOnDeploymentIntervalAction = payload => ({
    type: TURN_ON_DEPLOYMENT_INTERVAL,
    payload,
})

export default (state = initialState, action) => {
    const types = {
        [TURN_ON_DEPLOYMENT_INTERVAL]: {
            ...state,
            turnOnDeploymentInterval: true,
        },
        [CLOSE_CLICKED]: {
            ...state,
            closeClicked: true,
        },
        [SHOW_STATIC_ALERT]: {
            ...state,
            static: action.payload,
        },
        [HIDE_STATIC_ALERT]: {
            ...state,
            static: initialState.static,
        },
        [FORCE_RELOAD_WITHOUT_CACHE]: {
            ...state,
            forceReloadWithoutCache: action.payload,
        },
        [LOGOUT_USER]: {
            ...initialState,
            forceReloadWithoutCache: state.forceReloadWithoutCache,
        },
    }

    return types[action.type] ? types[action.type] : state
}
