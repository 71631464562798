import React from 'react'
import { Modal } from 'react-bootstrap'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import _ from 'lodash'
import { connect } from 'react-redux'
import { isUndefined } from '@/helpers/utils'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import InputTag from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputTag'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import { formatStatus } from './helpers'

import './RuleModals.scss'
class HeadChildModalForm extends React.Component {
    constructor(props) {
        super(props)
        this.defaultState = {
            isEdit: false,
            ruleKey: null,
            show: false,
            rule_id: null,
            inputs: {
                child: {
                    error: null,
                    message: this.props.lang.thisIsAMandatoryField,
                    value: null,
                    type: "string",
                    required: true
                },
                hint: {
                    error: null,
                    message: this.props.lang.thisIsAMandatoryField,
                    value: "",
                    type: "string",
                    required: true
                },
                content: {
                    error: null,
                    message: "",
                    value: "",
                    type: "string",
                    required: false
                },
                target: {
                    error: null,
                    message: this.props.lang.thisIsAMandatoryField,
                    value: [],
                    type: "string",
                    required: false
                },
                statusChange: {
                    error: null,
                    message: "",
                    value: {
                        value: false,
                        label: this.props.lang.no,
                    },
                    type: "boolean",
                    required: false
                }
            }
        }


        this.state = this.defaultState

        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.saveRule = this.saveRule.bind(this)
        this.handleChangeSelect = this.handleChangeSelect.bind(this)
    }

    showModal(rule_id, rule, ruleKey) {
        if (!isUndefined(rule) && !isUndefined(ruleKey)) {
            let editingState = _.cloneDeep(this.defaultState.inputs)
            Object.keys(rule).forEach(r => {
                if (!isUndefined(editingState[r])) {
                    if (r === "target") {
                        editingState[r].value = rule[r].map(elem => elem)
                    } else if (r === "statusChange") {
                        editingState[r].value = formatStatus(rule[r], this.props.lang.yes)
                    } else {
                        editingState[r].value = rule[r]
                    }
                }
            })
            this.setState({
                ...this.defaultState,
                inputs: editingState,
                isEdit: true,
                ruleKey,
                rule_id,
                show: true
            })
        } else {
            this.setState({ ...this.defaultState, show: true })
        }
    }

    hideModal() {
        this.setState(this.defaultState)
    }

    handleChangeInput(e) {
        this.setState({
            ...this.state,
            inputs: {
                ...this.state.inputs,
                [e.target.name]: {
                    ...this.state.inputs[e.target.name],
                    value: e.target.value
                }
            }
        })
    }

    handleChangeSelect(type, e) {
        this.setState({
            ...this.state,
            inputs: {
                ...this.state.inputs,
                [type]: {
                    ...this.state.inputs[type],
                    value: e
                }
            }
        })
    }

    saveRule() {
        const inputs = this.state.inputs
        let childError = null, hintError = null, targetError = null, hasError = false
        if (inputs.child.value === "" || typeof inputs.child.value !== inputs.child.type) {
            childError = "error"
            hasError = true
        }
        if (inputs.hint.value === "" || typeof inputs.hint.value !== inputs.hint.type) {
            hintError = "error"
            hasError = true
        }
        if (!inputs.target.value.length) {
            targetError = "error"
            hasError = true
        }
        this.setState({
            ...this.state,
            inputs: {
                ...this.state.inputs,
                child: {
                    ...this.state.inputs.child,
                    error: childError
                },
                hint: {
                    ...this.state.inputs.hint,
                    error: hintError
                },
                target: {
                    ...this.state.inputs.target,
                    error: targetError
                }
            }
        })
        if (hasError) {
            return
        }
        let rule = {}
        rule.type = "HeadChild"
        rule.child = inputs.child.value
        rule.hint = inputs.hint.value
        rule.target = inputs.target.value.map(elem => elem)
        rule.statusChange = inputs.statusChange.value
        if (inputs.content.value !== "") {
            rule.content = inputs.content.value
        }
        let ruleObj = { ruleType: 'HeadChild', config: rule }
        if (this.state.isEdit) {
            if (this.state.rule_id) {
                ruleObj.id = this.state.rule_id
            }
            const allRules = [...this.props.rules]
            allRules[this.state.ruleKey] = ruleObj
            this.props.handlers.setField('rules', allRules)
        } else {
            const allRules = [...this.props.rules]
            allRules.push(ruleObj)
            this.props.handlers.setField('rules', allRules)
        }
        this.hideModal()
    }

    render() {
        return (
            <Modal className="__modal" bsSize="large" show={this.state.show} onHide={this.hideModal} keyboard={false} backdrop="static">
                <Modal.Header closeButton className="__modal_header __border_bottom">
                    <h3 className="__modal_title">{this.props.lang.createANewRule}</h3>
                </Modal.Header>

                <Modal.Body className="__modal_body">
                    <>
                        <Row className='rule-modal__input-container'>
                            <Col md="12">
                                <Label text={this.props.lang.targetFiles} />
                                <InputTag
                                    name='target'
                                    placeholder={!this.state.inputs.target.value.length && this.props.lang.choose}
                                    id='target'
                                    dropdownItems={this.props.availableFiles}
                                    onChange={elems => this.handleChangeSelect('target', elems)}
                                    selectedTags={this.state.inputs.target.value}
                                    error={this.state.inputs.target.error === 'error'}
                                    errorMsg={this.state.inputs.target.message}
                                    autoFocus
                                />
                            </Col>
                        </Row>
                        <Row className='rule-modal__input-container'>
                            <Col md="6">
                                <Label text={this.props.lang.ruleType} />
                                <InputText
                                    id="ruleTypeName"
                                    key="rule_type_name"
                                    type="text"
                                    disabled
                                    value="HeadChild"
                                />
                            </Col>
                            <Col md="6">
                                <Label text='Child *' />
                                <InputText
                                    id="child"
                                    key="child"
                                    inputName="child"
                                    value={this.state.inputs.child.value}
                                    onChange={this.handleChangeInput}
                                    error={this.state.inputs.child.error === 'error'}
                                    errorMsg={this.state.inputs.child.message}
                                />
                            </Col>
                        </Row>
                        <Row className='rule-modal__input-container'>
                            <Col md="6">
                                <Label text='Hint *' />
                                <InputText
                                    id="hint"
                                    key="hint"
                                    inputName="hint"
                                    onChange={this.handleChangeInput}
                                    value={this.state.inputs.hint.value}
                                    error={this.state.inputs.hint.error === 'error'}
                                    errorMsg={this.state.inputs.hint.message}
                                />
                            </Col>
                            <Col md="6">
                                <Label text='Content' />
                                <InputText
                                    id="content"
                                    key="content"
                                    inputName="content"
                                    onChange={this.handleChangeInput}
                                    value={this.state.inputs.content.value}
                                    error={this.state.inputs.content.error}
                                    errorMsg={this.state.inputs.content.message}
                                />
                            </Col>
                        </Row>
                        <Row className='rule-modal__input-container'>
                            <Col md="6">
                                <Label text='Denied content' />
                                <InputComboBox
                                    name="statusChange"
                                    placeholder={this.props.lang.choose}
                                    id="statusChange"
                                    key="statusChange"
                                    dropdownItems={[this.props.lang.yes, this.props.lang.no]}
                                    onChange={(item) =>
                                        this.handleChangeSelect('statusChange', {
                                            value: item === this.props.lang.yes,
                                            label: item,
                                    })}
                                    value={this.state.inputs.statusChange.value.label}
                                />
                            </Col>
                        </Row>
                    </>
                </Modal.Body>

                <Modal.Footer className="__modal_footer __border_top">
                    <ButtonGroup>
                        <Button
                            id="CancelButton"
                            onClick={this.hideModal}
                            text={this.props.lang.cancel}
                            theme='tertiary'
                        />
                        <Button
                            id="SaveButton"
                            theme='primary'
                            onClick={this.saveRule}
                            text={this.props.lang.save}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    lang: state.langStore.rulesModals
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(HeadChildModalForm)
