import * as ownerService from '../http/services/ownerService'
import * as permissionService from '../http/services/permissionService'
import {
    setSingleOwnerAction,
    setOwnershipRoles,
    loadOwnersAction,
    deleteOwnerAction,
    createOwnerAction,
    loadCoursePermissionsAction,
    setVisibilityAction,
} from '../reducers/ownerReducer'
import { setLoading } from '../reducers/loadingReducer'
import httpHandler, { codes } from '@/helpers/error-handling/httpHandler'
import showNewMessage from '../reducers/_deprecated/globalAlertsReducer/alertsControllers'

const returnHttpHandler = (error, message, code = codes.INTERNAL_SERVER_ERROR) =>
    httpHandler(error)({
        [code]: () => {
            showNewMessage({
                type: 'danger',
                message,
                isTemporal: true,
            })
        },
    })

export const getOwners = courseId => async (dispatch, getState) => {
    const [error, owners] = await ownerService.loadOwners(courseId)
    if (error) return returnHttpHandler(error, getState().langStore.ownerList.errorGetOwnerMessage)

    dispatch(loadOwnersAction(owners))
}

export const loadUserOwnershipDetail = (userId, courseId) => async (dispatch) => {
    const [error, owner] = await ownerService.loadUserOwnershipDetail(userId, courseId)
    if (error) return 
    dispatch(setSingleOwnerAction(owner))
}

export const saveNewOwner = (ownersData, courseId) => async (dispatch, getState) => {
    const [error, savedOwner] = await ownerService.create(courseId, ownersData)
    if (error)
        return returnHttpHandler(error, getState().langStore.ownerList.errorCreateOwnerMessage)

    dispatch(createOwnerAction(savedOwner))
}

export const removeOwner = (courseId, ownerId) => async (dispatch, getState) => {
    const [error] = await ownerService.removeCourseOwnership(ownerId, courseId)
    if (error)
        return returnHttpHandler(
            error,
            getState().langStore.ownerList.errorDeleteCourseOwnerMessage,
        )

    dispatch(deleteOwnerAction(ownerId))
}

export const getCoursePermissions = () => async (dispatch, getState) => {
    const [error, coursePermissions] = await permissionService.loadCoursePermissions()
    if (error)
        return returnHttpHandler(
            error,
            getState().langStore.ownerList.errorGetCoursePermissionsMessage,
        )

    dispatch(loadCoursePermissionsAction(coursePermissions))
}

export const courseOwnershipAssign = (courseId, data) => async (dispatch, getState) => {
    dispatch(setLoading.true('course.ownershipAssign'))

    const [error] = await ownerService.ownershipAssign(courseId, data)

    dispatch(setLoading.false('course.ownershipAssign'))

    if (error)
        return returnHttpHandler(error, getState().langStore.ownershipAssign.errorRoleAssignMessage)
}

export const toggleOwnerVisibility = (userId, courseId) => async (dispatch, getState) => {
    const [error] = await ownerService.updateOwnerCourseVisibility(userId, courseId)
    if (error)
        return returnHttpHandler(error, getState().langStore.ownerList.errorVisibilityToogleMessage)

    dispatch(setVisibilityAction(userId))
}

export const updateOwnerCourseRole = (userId, courseId, ownershipRoleId) => async (dispatch, getState) => {
    const [error] = await ownerService.updateOwnerCourseRole(userId, courseId, ownershipRoleId)
    if (error)
        return returnHttpHandler(error, getState().langStore.ownerEdit.errorUpdateOwnerCourseRole)

}

export const loadOwnershipRoles = (userDataSearch) => async (dispatch, getState) => {

    const errorGetRolesMessage = getState().langStore.ownershipAssign.errorGetRolesMessage
    const [error, roles] = await ownerService.loadOwnershipRoles(userDataSearch)

    if (error) {
        return httpHandler(error)({
            [codes.INTERNAL_SERVER_ERROR]: () => {
                showNewMessage({
                    type: 'danger',
                    message: errorGetRolesMessage,
                    isTemporal: true,
                })
            },
        })
    }

    dispatch(setOwnershipRoles(roles))
}