import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { history } from '@/app/store'
import { offset, scrollTo } from '@/helpers/utils'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import BackButton from '@/components/BackButton'
import OwnerAssignUsersTable from './OwnerAssignUsersTable'
import OwnerAssignedUsersTable from './OwnerAssignedUsersTable'
import OwnerAssignModal from './OwnerAssignModal'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import './OwnerAssign.scss'

const OwnerAssign = ({
    courseId,
    roles,
    users,
    getUsers,
    clearUsers,
    pagination,
    loadingUserSearch,
    courseOwnershipAssign,
    lang,
}) => {

    const isMounted = useRef(true)
    const ownersListRef = useRef()
    
    useEffect(() => {
        return () => {
            blockChangeRoute()
        }
    }, [blockChangeRoute])

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    const getFormatedRoles = () => {
        return roles.map(role => {
            return {
                value: role.id,
                label: role.description,
            }
        })
    }

    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')

    const [usersToAssign, setUsersToAssign] = useState([])
    const [showIncompleteModal, setShowIncompleteModal] = useState(false)

    const [newRoute, setNewRoute] = useState(null)
    const [leaveAssign, setLeaveAssign] = useState(false)

    const [activeSearch, setActiveSearch] = useState(false)

    const blockChangeRoute = history.block(location => {
        if(location.pathname !== `/login` && isMounted.current) {
            setNewRoute(location)
            if (!leaveAssign && usersToAssign.length > 0) {
                setShowIncompleteModal(true)
                return false
            }
            clearUsers()
        }
    })

    const leaveAssignHandler = () => {
        setShowIncompleteModal(false)
        setLeaveAssign(true)
        setTimeout(() => {
            history.push(newRoute)
        }, 200)
    }

    const closeModalIncompleteAssign = () => setShowIncompleteModal(false)

    const isCompleteDataToAssign = () => {
        if (usersToAssign.length > 0) return usersToAssign.every(a => a.role.id)
        return false
    }

    const goToPageTop = () => {
        const ownersListTop = offset(ownersListRef.current).top - 75
        scrollTo(window, ownersListTop)
    }

    const userSearch = async () => {
        await getUsers(name, surname, email)
        setActiveSearch(true)
        goToPageTop()
    }

    const assignUsers = async () => {
        setLeaveAssign(true)
        const data = usersToAssign.map(user => {
            return {
                user_id: user.id,
                course_id: courseId,
                ownership_role_id: user.role.id,
            }
        })
        await courseOwnershipAssign(courseId, data)
        clearUsers()
        history.goBack()
    }

    const changeSearchUserPage = async (page) => {
        await getUsers(name, surname, email, page)
        goToPageTop()
    }

    const preAssignUser = id => {
        const userToAssign = users.find(user => user.id == id)
        userToAssign.role = {}
        setUsersToAssign([...usersToAssign, userToAssign])
    }

    const removePreAssignUser = id => {
        const newUsersToAssign = [...usersToAssign]
        const index = newUsersToAssign.findIndex(user => user.id == id)
        if (index !== -1) {
            newUsersToAssign.splice(index, 1)
            setUsersToAssign([...newUsersToAssign])
        }
    }

    const selectRole = (selectedOption, userId) => {
        const newUsersToAssign = [...usersToAssign]
        const index = newUsersToAssign.findIndex(user => user.id == userId)
        newUsersToAssign[index].role = {
            id: selectedOption.value,
            description: selectedOption.label,
        }
        setUsersToAssign([...newUsersToAssign])
    }

    return (
        <>
            <div className="container">
                <div className="owner_assign __main_header">
                    <Row>
                        <Col lg="12">
                            <BackButton text={lang.backButton} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <h1 className="__main_title">{lang.ownerAssign}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4">
                            <p className="assign_subtitle">{lang.ownerAssignSubtitle}</p>
                        </Col>
                    </Row>
                    <div className="assign_card">
                        <div className="assign_step">{lang.firstStep}</div>
                        <p className="assign_card_title">{lang.usersSearch}</p>
                        <p className="assign_card_subtitle">{lang.usersSearchSubtitle}</p>
                        <div className="user_search_results">
                            <Row>
                                <Col md="3">
                                    <Label text={lang.searchByName} />
                                    <InputText
                                        id="user.name"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        deletable
                                        onCloseIcon={() => setName('')}
                                    />
                                </Col>
                                <Col md="3">
                                    <Label text={lang.searchBySurname} />
                                    <InputText
                                        id="user.surname"
                                        value={surname}
                                        onChange={e => setSurname(e.target.value)}
                                        deletable
                                        onCloseIcon={() => setSurname('')}
                                    />
                                </Col>
                                <Col md="4">
                                    <Label text={lang.searchByEmail} />
                                    <InputText
                                        id="user.email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        deletable
                                        onCloseIcon={() => setEmail('')}
                                    />
                                </Col>
                                <Col md="2">
                                    <Button
                                        id="assign_user_search_button"
                                        text={lang.search}
                                        onClick={() => userSearch()}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div ref={ownersListRef}>
                            <OwnerAssignUsersTable
                                users={users}
                                pagination={pagination}
                                changeSearchUserPage={changeSearchUserPage}
                                usersToAssign={usersToAssign}
                                preAssignUser={preAssignUser}
                                lang={lang}
                                loadingUserSearch={loadingUserSearch}
                                activeSearch={activeSearch}
                            />
                        </div>
                    </div>
                    <OwnerAssignedUsersTable
                        getRoles={getFormatedRoles}
                        removePreAssignUser={removePreAssignUser}
                        selectRole={selectRole}
                        usersToAssign={usersToAssign}
                        isCompleteDataToAssign={isCompleteDataToAssign}
                        assignUsers={assignUsers}
                        lang={lang}
                    />
                    <OwnerAssignModal  
                        showIncompleteModal={showIncompleteModal}
                        closeModalIncompleteAssign={closeModalIncompleteAssign}
                        leaveAssignHandler={leaveAssignHandler}
                        lang={lang}
                    />
                   
                </div>
            </div>
        </>
    )
}

OwnerAssign.propTypes = {
    courseId: PropTypes.string.isRequired,
    roles: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    getUsers: PropTypes.func.isRequired,
    clearUsers: PropTypes.func.isRequired,
    pagination: PropTypes.object.isRequired,
    loadingUserSearch: PropTypes.bool.isRequired,
    courseOwnershipAssign: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
}

export default OwnerAssign
