import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

const PagesIndicator = ({ activePage, pagesCount, handleChangePage }) => {
    const indicatorClasses = (isActive) =>
        cn(
            {
                __active: isActive,
            },
            'slider-wrapper__footer__indicator-item'
        )

        
    return (
        <div className='slider-wrapper__footer__indicator'>
            {new Array(pagesCount).fill(null).map((_, index) => (
                <span className={indicatorClasses(activePage === index + 1)} onClick={() => handleChangePage(index)} />
            ))}
        </div>
    )
}

PagesIndicator.defaultProps = {
    activePage: 0,
    pagesCount: 0,
}

PagesIndicator.propTypes = {
    activePage: PropTypes.number,
    pagesCount: PropTypes.number,
    handleChangePage: PropTypes.func.isRequired,
}

export default PagesIndicator
