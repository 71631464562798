import JSFileDownload from 'js-file-download'
import moment from 'moment'
import httpClient from '../httpClient'
import * as enrolment from '../api/enrolmentApi'
import { getTenantLang } from '@/helpers/tenants/helpers.js'
import URLQueryString from '@/helpers/utils/URLQueryString'

const api = () => httpClient(enrolment.base)
const apiDelete = (studentId) => httpClient(enrolment.deleteStudent(studentId))
const reportApi = (courseId) => httpClient(enrolment.loginReport(courseId))
const lang = getTenantLang()

export const loadStudents = query => {
    const encodedName = encodeURIComponent(query.search['!name'])
    const encodedSurname = encodeURIComponent(query.search['!surname'])
    const encodedEmail = encodeURIComponent(query.search['!email'])

    query.search = {
        'name' : encodedName,
        'surname' : encodedSurname,
        'email': encodedEmail
    }

    const queryString = URLQueryString(query)
    return api().get(`${queryString}`)
}

export const loginReport = async (courseId, courseName) => {
    const report = await reportApi(courseId).get(null, {
        responseType: 'blob',
        params: { lang }
    })
    const filename = `${moment().format('DDMMYYYY_HHmmss')}_${courseName}_last_login_report.csv`
    JSFileDownload(report[1].data, filename)
}

export const deleteStudentCourse = (studentId, courseId) => {
    const body = {
        courseId,
    }
    return apiDelete(studentId).patch(null, body)
}
