import { useState } from 'react'
import { useSelector } from 'react-redux'
import { BAD_URL, EMPTY_FIELD } from '../constants/errorTypes'

export const useModalError = () => {
    const lang = useSelector(({ langStore }) => langStore.modalErrors)
    const [errorList, setErrorList] = useState([])

    const errorTypes = {
        [BAD_URL]: lang.badUrl,
        [EMPTY_FIELD]: lang.emptyField,
    }

    const errorsToString = () => {
        const uniqErrors = errorList.filter((e, i) => !!e && errorList.indexOf(e) === i)
        return uniqErrors.map((error) => errorTypes[error]).join(' ')
    }

    return [setErrorList, errorsToString]
}
