'use-strict'

import configureStore from '@/app/store'
import {
    hideStaticAlert,
    showStaticAlert,
    closeClicked,
} from '../../redux/reducers/deployment'
import { logout } from '@/redux/reducers/_deprecated/authReducer'
import { codes } from '@/helpers/error-handling/httpHandler'
import { ALERT_TYPES } from '@/constants/alerts'

const onConfirm = () => {
    configureStore.store.dispatch(logout())
    setTimeout(() => {
        window.location.reload(true)
    }, 500)
}

const onCancel = () => {
    configureStore.store.dispatch(hideStaticAlert())
}

const notImplementedAlertTypes = response => {
    const { isAuthenticated } = configureStore.store.getState().authReducer

    return showStaticAlert({
        type: response.errors,
        isAuth: isAuthenticated,
        message: response.message,
        showConfirmButton: true,
        showCancelButton: true,
        show: true,
        onConfirm,
        onCancel,
    })
}
const alertTypes = {
    [codes.NOT_IMPLEMENTED]: response => notImplementedAlertTypes(response),
    DEFAULT: () =>
        showStaticAlert({
            type: ALERT_TYPES.DEFAULT_ALERT,
            showConfirmButton: true,
            show: true,
            onCancel,
        }),
}

export default response => {
    const alertGenerator =
        alertTypes[response.code] ||
        function () {
            return null
        }
    return alertGenerator(response)
}
