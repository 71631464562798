import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { timezoneConfig } from '@/helpers/dates/timezoneConfig'
import { EXPIRATION_STATUS } from '../helpers/constants'

export default (hasLimit, { timeleft, expiredActivity }, limitDays, topicId) => {
    const lessons = useSelector(({ student }) => student.classroom.content_map.Lessons)
    const [tz, locale] = timezoneConfig()
    moment.locale(locale)

    const getFormatedDate = (text, date) => {
        return text
            .replace('{DATE}', date.tz(tz).format('DD/MM'))
            .replace('{HOUR}', date.tz(tz).format('hh A'))
    }

    if (hasLimit && !!lessons) {
        const { release_date } =
            lessons.find(({ Topics }) => Topics.some(({ id }) => id === topicId)) || moment()

        const expirationDate = moment(release_date).add(limitDays, 'd')
        const expired = moment().isAfter(expirationDate.tz(tz))

        if (expired) {
            return [
                'danger',
                getFormatedDate(expiredActivity, expirationDate),
                EXPIRATION_STATUS.EXPIRED,
            ]
        }
        return ['info', getFormatedDate(timeleft, expirationDate), EXPIRATION_STATUS.ALIVE]
    }
    return [null, null, EXPIRATION_STATUS.UNKOWN]
}
