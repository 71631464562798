import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getCourseStructureByBlockType } from '@/redux/thunks/dashboardThunks'
import { MODULE, LESSON, TOPIC, BLOCK, DEFAULT_SELECTED } from '../constants/dashboard'
import useQueryParams from '@/hooks/useQueryParams'

const useCourseSelects = (courseId, blockType) => {
    const dispatch = useDispatch()
    const {
        module: moduleQueryString,
        lesson: lessonQueryString,
        topic: topicQueryString,
        block: blockQueryString,
      } = useQueryParams();
    
    const [modules, setModules] = useState([])
    const [selectedModule, setSelectedModule] = useState(DEFAULT_SELECTED)
    const [lessons, setLessons] = useState([])
    const [selectedLesson, setSelectedLesson] = useState(DEFAULT_SELECTED)
    const [topics, setTopics] = useState([])
    const [selectedTopic, setSelectedTopic] = useState(DEFAULT_SELECTED)
    const [blocks, setBlocks] = useState([])
    const [selectedBlock, setSelectedBlock] = useState(DEFAULT_SELECTED)

    const getCourseInfo = async () => {
        const response = await dispatch(getCourseStructureByBlockType(courseId, blockType))
        setModules(response)
    }

    const getModuleFromParam = (modules) => {
        const index = modules.findIndex((m) => m.id === moduleQueryString);
        const selected_module = modules.find((m) => m.id === moduleQueryString);

        onSelectChange(selected_module.id, index, 'MODULE', false);
    };

    const getLessonFromParam = () => {
        const indexLesson = lessons?.findIndex((l) => l.id === lessonQueryString);
        const selected_lesson = lessons?.find((l) => l.id === lessonQueryString);

        onSelectChange(selected_lesson?.id, indexLesson, 'LESSON', false);
    };

    const getTopicFromParam = () => {
        const indexTopic = topics.findIndex((l) => l.id === topicQueryString);
        const selected_topic = topics.find((l) => l.id === topicQueryString);
        
        onSelectChange(selected_topic?.id, indexTopic, 'TOPIC', false);
    };

   const getBlockFromParam = () => {
        const indexBlock = blocks.findIndex((l) => l.id === blockQueryString);
        const selected_block = blocks.find((l) => l.id === blockQueryString);
        
        onSelectChange(selected_block?.id, indexBlock, 'BLOCK', false);
    };

    useEffect(() => {
        getCourseInfo();
    }, [dispatch]);

    useEffect(() => {
        if (!modules?.length || !moduleQueryString) return;
        getModuleFromParam(modules);
    
    }, [modules]);

    useEffect(() => {
        if (!lessons?.length || !lessonQueryString) return;
        getLessonFromParam();
    
    }, [lessons]);

    useEffect(() => {
        if (!topics?.length || !topicQueryString) return;
        getTopicFromParam();
    
    }, [topics]);

    useEffect(() => {
        if (!blocks?.length || !blockQueryString) return;
        getBlockFromParam();
    
    }, [blocks]);

    const onSelectChange = (value, index, key, useQueryParam) =>
        ({
            [MODULE]: () => {
                const _module = modules.find((m, i) => index === i);

                if (index !== selectedModule.index) {
                    setSelectedModule({ ..._module, index })
                    setLessons(_module.lessons)
                    setSelectedLesson([])
                    setSelectedTopic([])
                    setTopics([])
                    setSelectedBlock([])
                    setBlocks([])
                }

                if (!!useQueryParam) {
                    const params = new URLSearchParams(window.location.search);
                    params.set('module', _module?.id);
                    params.set('lesson', '');
                    params.set('topic', '');
                    params.set('block', '');
                
                    window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);
                }
            },
            [LESSON]: () => {
                const _lesson = lessons.find((l, i) => index === i);

                if (index !== selectedLesson.index) {
                    setSelectedLesson({ ..._lesson, index })
                    setTopics(_lesson?.topics)
                    setSelectedTopic([])
                    setSelectedBlock([])
                    setBlocks([])
                }

                if (!!useQueryParam) {
                    const params = new URLSearchParams(window.location.search);
                    params.set('lesson', _lesson?.id);
                    params.set('topic', '');
                    params.set('block', '');
                
                    window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);
                }
            },
            [TOPIC]: () => {
                const _topic = topics.find((t, i) => index === i);

                if (index !== selectedTopic.index) {
                    setSelectedTopic({ ..._topic, index })
                    setBlocks(_topic?.blocks)
                    setSelectedBlock([])
                }

                if (!!useQueryParam) {
                    const params = new URLSearchParams(window.location.search);
                    params.set('topic', _topic?.id);
                    params.set('block', '');
                
                    window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);
                }
            },
            [BLOCK]: () => {
                const _block = blocks.find((b, i) => index === i);

                if (index !== selectedBlock.index) {
                    const _block = blocks.find((b, i) => index === i)
                    setSelectedBlock({ ..._block, index })
                }

                if (!!useQueryParam) {
                    const params = new URLSearchParams(window.location.search);
                    params.set('block', _block?.id);
                
                    window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);
                }
            },
        }[key]())

    return {
        modules,
        selectedModule,
        lessons,
        selectedLesson,
        topics,
        selectedTopic,
        blocks,
        selectedBlock,
        onSelectChange,
    }
}

export default useCourseSelects
