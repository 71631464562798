import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { Maximize2, X } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Checkbox from '@digitalhouse-tech/react-lib-ui-explorer/lib/Checkbox'
import RadioButton from '@digitalhouse-tech/react-lib-ui-explorer/lib/RadioButton'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Feedback from '../../Feedback/Feedback'
import './ChoiceOption.scss'
import { SINGLE_CHOICE, MULTIPLE_CHOICE } from '../../../../constants/quiz'

const INPUT_COMPONENT = {
    [MULTIPLE_CHOICE]: (props) => <Checkbox {...props} theme='choice' />,
    [SINGLE_CHOICE]: (props) => <RadioButton {...props} theme='confirm' />,
}

const ChoiceOption = ({
    optionId,
    questionId,
    statement,
    imageUrl,
    onUpdateAnswerChoice,
    type,
    feedback,
    isCorrect,
    hasQuizComplete,
    checked,
    showOptionFeedback,
    lang
}) => {
    const [showPreview, setShowPreview] = useState(false)
    const hasImage = !!imageUrl
    const InputComponent = INPUT_COMPONENT[type]
    const showChecked = checked && hasQuizComplete
    const isSingleChoice = type === SINGLE_CHOICE

    return (
            <Feedback
                hasFeedback={checked && showOptionFeedback && hasQuizComplete}
                feedback={feedback}
                isCorrectChoice={isCorrect}
                imageChoice={hasImage}
                lang={lang}
            >
                <div
                    className={cn('choice-container', {
                        'choice-container--image': hasImage,
                        'choice-container--image-preview': showPreview,
                        'choice-container__input--selected': showChecked,
                        'choice-container__input--right-feedback':
                            showChecked && isCorrect,
                        'choice-container__input--wrong-feedback':
                            showChecked && !isCorrect,
                    })}
                >
                    <div className='choice-container__input-container'>
                        <InputComponent
                            className={cn({
                                'choice-container__input': !hasImage,
                                'choice-container__input--preview': showPreview,
                                'choice-container__input--image': hasImage,
                            })}
                            id={`option-${optionId}-${questionId}`}
                            key={`option-${optionId}-${questionId}`}
                            inputName={isSingleChoice ? `option-${questionId}` : `option-${optionId}-${questionId}`}
                            value={optionId}
                            onClick={(e) => onUpdateAnswerChoice(optionId, e.target.checked)}
                            defaultChecked={checked}
                            disabled={hasQuizComplete}
                            />
                        <Label
                            className='choice-container__label'
                            htmlFor={`option-${optionId}-${questionId}`}
                            text={statement}
                            theme='clear'
                        />
                    </div>

                    {hasImage && (
                        <div
                            className={cn('choice-container__image-container', {
                                'choice-container__image-container--preview': showPreview,
                            })}
                        >
                            <div>
                                <img
                                    src={imageUrl}
                                    alt=''
                                    className={cn('choice-container__image-container__image', {
                                        'choice-container__image-container__image--preview': showPreview,
                                    })}
                                />
                                <Button
                                    className={cn(
                                        'choice-container__image-container__preview-button',
                                        {
                                            'choice-container__image-container__preview-button--preview': showPreview,
                                        }
                                    )}
                                    icon={ !showPreview ? <Maximize2 size={18} /> : <X size={28} /> }
                                    theme='clear'
                                    line
                                    onClick={() => setShowPreview(!showPreview)}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Feedback>
    )
}

ChoiceOption.propTypes = {
    optionId: PropTypes.string,
    statement: PropTypes.string,
    imageUrl: PropTypes.string,
    onUpdateAnswerChoice: PropTypes.func,
    type: PropTypes.string,
    feedback: PropTypes.string,
    isCorrect: PropTypes.bool,
    hasQuizComplete: PropTypes.bool,
    checked: PropTypes.bool,
    showOptionFeedback: PropTypes.bool,
    lang: PropTypes.object
}

ChoiceOption.defaultProps = {
    optionId: 0,
    statement: '',
    feedback: ''
}

export default ChoiceOption
