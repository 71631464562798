import * as bases from './bases'

const enrolments = bases.enrolments

const courses = bases.courses

export const base = `/${enrolments}`

export const deleteStudent = (studentId) => `${base}/${studentId}` 

export const loginReport = (courseId) => `/${courses}/${courseId}/${enrolments}/login-report`