import React from 'react'
import PropTypes from 'prop-types'
import Presence from '../Presence'
import './MainCard.scss'

const MainCard = ({ courseName }) => {
    return (
        <div className='main-card'>
            <span className='main-card__title'>{courseName}</span>
            {/* <Presence presence={90} /> */}
        </div>
    )
}

MainCard.propTypes = {
    courseName: PropTypes.string,
}

MainCard.defaultProps = {
    courseName: '',
}

export default MainCard
