import React from 'react'
import { withRouter } from 'react-router-dom'
import moment from "moment"
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'
import { Eye, EyeOff, Edit2, FileText } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import './CourseSurvey.scss'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'

export class CourseSurvey extends React.Component {

  constructor() {
    super()
  }

  renderVisibilityButton() {
    if(this.props.visibility){
      return (
        <ButtonRound
          id="handleVisibilityButton"
          theme='info'
          onClick={this.props.visibility.bind(this, this.props.survey)}
          icon={this.props.survey.visibility ? <Eye /> : <EyeOff />}
        />
      )
    }
    else{return null}
  }

  renderEditButton() {
    if(this.props.editSurvey){
      return (
        <ButtonRound
          id="editSurveyButton"
          theme='primary_dark'
          onClick={this.props.editSurvey.bind(this, this.props.survey)}
          icon={<Edit2 />}
        />
      )
    }
    else{return null}
  }

  renderReportButton() {
    return (
      <ButtonRound
        id="getReportButton"
        theme='primary'
        onClick={this.props.getReport.bind(this, this.props.survey.course_id, this.props.survey.id, this.props.location.state.courseName)}
        icon={<FileText />}
      />
    )
  }

  renderMandatoryIcon() {

    return (
      <Tooltip
        key={this.props.survey.Survey.name + 'keyTooltip'}
        tooltipId={`mandatory` + this.props.survey.Survey.name}
        tooltipContent={this.props.lang.mandatorySurvey}
      >
        <span className="mandatory-icon">*</span>
      </Tooltip>
    )

  }

  render() {
    return (
      <Panel className="__card __fixedHeight">
        <Row>
          <Col lg="9">
            <h3 className="__card_secondary_title display_inline">{this.props.survey.mandatory ? this.renderMandatoryIcon() : ''} {this.props.survey.Survey.name}</h3><h5 className="__card_more_information display_inline">{this.props.survey.date ? this.props.lang.date + moment(this.props.survey.date).utc().format("DD/MM/YYYY") : (this.props.lang.date + this.props.lang.notAvailable)}</h5>
          </Col>

          <Col lg="3" className="__card_button_box">
            <ButtonGroup>

              {this.renderVisibilityButton()}
              {this.renderEditButton()}
              {this.props.showReportButton && this.renderReportButton()}

            </ButtonGroup>
          </Col>
        </Row>
      </Panel>
    )
  }
}


export default withRouter(CourseSurvey)