import React, { useState, useEffect } from 'react'
import GenericModal from '@/components/GenericModal'
import PropTypes from 'prop-types'
import { InputGroup } from 'react-bootstrap'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'

const FileUploadModal = ({
    name,
    show,
    onCancel,
    onAccept,
    lang,
    fileList
}) => {
    const [inputValue, setInputValue] = useState('')

    const [validInput, setValidInput] = useState('')

    useEffect(() => {
        setInputValue(extractNameAndExtension(name).name)
    }, [name])

    const extractNameAndExtension = name => {
        const stringList = name.split(".");
        return {
            name: name.split('.' + stringList[stringList.length - 1])[0],
            extension: stringList[stringList.length - 1]
        }
    }

    const onChangeHandler = value => {
        setInputValue(value)
    }

    const validateFile = name => {
        let formOk = true

        fileList.map(f => {
            if (f.name === name) {
                formOk = false
            }
        })

        setValidInput(formOk)
        return formOk
    }

    const confirmHandler = () => {
        let fileNameValidated = validateFile(`${inputValue}.${extractNameAndExtension(name).extension}`)

        if (inputValue.length > 0) {
            if (fileNameValidated) {
                onAccept(`${inputValue}.${extractNameAndExtension(name).extension}`)
            }
        }

        // if(inputValue.length > 0) onAccept(`${inputValue}.${extractNameAndExtension(name).extension}`)
    }

    const cancelHandler = () => {
        onCancel()
    }

    const getFeedback = () => {
        let feedBack = <div></div>

        if (validInput === false) {
            feedBack = <InputGroup>
                <MessageBox
                    theme='danger'
                    text={lang.validationMessage}
                    noFill
                />
            </InputGroup>
        }
        return feedBack
    }

    return (
        <GenericModal
            show={show}
            titleModal={lang.editFile}
            cancelTitle={lang.cancel}
            confirmTitle={lang.save}
            onCancel={cancelHandler}
            onConfirm={confirmHandler}
        >
            <Label text={lang.fileLabel} className="__input_label" />
            <InputGroup>
                <InputText
                    className="FileUploadFileModalInput"
                    type="text"
                    value={inputValue}
                    onChange={e => onChangeHandler(e.target.value)}
                />

                <InputGroup.Addon
                    className="__input_addon">
                    {'.' + extractNameAndExtension(name).extension}
                </InputGroup.Addon>
            </InputGroup>
            {getFeedback()}
        </GenericModal>
    )
}

FileUploadModal.propTypes = {
    name: PropTypes.string,
    show: PropTypes.bool,
    onCancel: PropTypes.func,
    onAccept: PropTypes.func
}

FileUploadModal.defaultProps = {
    name: '',
    show: false,
    onCancel: /* istanbul ignore next */ () => { },
    onAccept: /* istanbul ignore next */ (fileName = '') => { }
}

export default FileUploadModal