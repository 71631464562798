import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Skeleton from '@/components/Skeleton'
import QuizTitle from '@/components/Quiz/QuizTitle'
import './Skeleton.scss'

const QuizSkeleton = memo(({ quizTitle, className }) => (
    <div className={className}>
        <div className='quiz-skeleton__title'>
            <QuizTitle title={quizTitle} />
            <Skeleton containerStyle='quiz-skeleton__title--attemps' height='50px' />
        </div>
        <div className='quiz-skeleton__body'>
            <Skeleton width='100%' height='50px' />
            <Skeleton width='100%' height='400px' />
            <Skeleton width='100%' height='50px' />
            <Skeleton width='50%' height='50px' center/>
        </div>
    </div>
))

QuizSkeleton.propTypes = {
    className: PropTypes.string,
    quizTitle: PropTypes.string.isRequired,
}
QuizSkeleton.defaultProps = {
    className: '',
}

export default QuizSkeleton
