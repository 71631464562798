import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import FilesDownloader from '@digitalhouse-tech/react-lib-ui-explorer/lib/FilesDownloader'
import CallToAction from '@digitalhouse-tech/react-lib-ui-explorer/lib/CallToAction'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { Link, ExternalLink, Clock } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import TextAreaInput from '@digitalhouse-tech/react-lib-ui-explorer/lib/TextAreaInput'
import Feedback from '@digitalhouse-tech/react-lib-ui-explorer/lib/Feedback'
import LimitCounter from '@digitalhouse-tech/react-lib-ui-explorer/lib/LimitCounter'
import { useSelector } from 'react-redux'
import useExpirationDate from './hooks/useExpirationDate'
import useReview from './hooks/useReview'
import { EXPIRATION_STATUS, REVIEW_STATUS } from './helpers/constants'
import situations from './helpers/situations'
import { getBlockProgress } from '@/redux/selectors/students/progress'
import cn from 'classnames'
import { emptyStateAsset } from '@/assets/img/config'
import './EvaluableBlock.scss'
import { getIconType, getFileExtension, openLinkInNewTab } from '@/helpers/files'
import BlockIssueMenu from '../BlockIssueMenu/BlockIssueMenu'
import Playstrap from '@/components/Playstrap'

const EvaluableBlock = ({ block, isCourseExpired, progressReport, isOwner, blockType }) => {
    const { lang, loading } = useSelector(
        ({
            langStore: {
                evaluableBlock,
            },
            loading: { classroomProgress = {} },
        }) => ({
            lang: evaluableBlock,
            loading: classroomProgress && classroomProgress[block.id]
        })
    )
    const { name, EvaluableBlock: evaluableBlock, topic_id, id: block_id, show_name } = block
    const {
        EvaluableBlockFiles,
        has_limit,
        limit_days,
        statement,
        external_platform_url,
    } = evaluableBlock

    const [expireTheme, expiredMessage, expiredStatus] = useExpirationDate(
        has_limit,
        lang,
        limit_days,
        topic_id
    )
    const evaluableProgress = useSelector(getBlockProgress(block_id)).ProgressEvaluable
    const [reviewTheme, reviewMessage, reviewStatus, showReviewMessage] = useReview(
        lang,
        evaluableProgress
    )
    const [activityLink, setActivityLink] = useState('')
    const [activityText, setActivityText] = useState('')
    const [charsCount, setCharsCount] = useState(0)
    const [feedbackReview, setFeedbackReview] = useState({})

    useEffect(() => {
        if (evaluableProgress) {
            const { lastSubmission } = evaluableProgress
            if (lastSubmission) {
                const { handIn, feedback, reporter } = lastSubmission
                if (handIn) {
                    evaluableProgress.type === 'EXTERNAL' && setActivityLink(handIn.link)
                    setActivityText(handIn.message)
                    setCharsCount(handIn.message.length)
                }
                if (feedback) {
                    setFeedbackReview({
                        review: feedback.feedback,
                        files: feedback.files.map((file) => ({
                            name: file.name,
                            icon: getIconType(getFileExtension(file.name)),
                            size: file.size,
                            url: file.url,
                            onClick: () => openLinkInNewTab(file.url),
                        })),
                        reporter: `${reporter.name} ${reporter.surname}`
                    })
                }
            }
        }
    }, [evaluableProgress])

    const charsLimit = situations.maxActivityCharacters(!!external_platform_url)

    const onChangeActivityText = (e) => {
        const value = e.target.value
        if (value.length <= charsLimit) {
            setCharsCount(value.length)
            setActivityText(value)
        }
    }

    const getActivityFiles = () => {
        return EvaluableBlockFiles.map((file) => {
            return {
                ...file,
                icon: getIconType(getFileExtension(file.name)),
                onClick: () => openLinkInNewTab(file.url),
            }
        })
    }

    const onClickSubmit = () => {
        progressReport({
            progress: {
                type: situations.getActivityType(!!external_platform_url),
                Submission: {
                    handIn: {
                        message: activityText,
                        link: activityLink,
                    },
                },
            },
        })
    }

    const activityDisabledStyle = cn('', {
        'evaluable-block__activity--disabled': situations.isActivityDisabled(reviewStatus),
    })

    const activityReviewStyle = cn('evaluable-block__activity__status--text', {
        'evaluable-block__activity__status--text--idle': reviewStatus === REVIEW_STATUS.IDLE,
        'evaluable-block__activity__status--text--pending':
            reviewStatus === REVIEW_STATUS.PENDING_FEEDBACK,
    })

    const handInStyle = cn('evaluable-block__activity__text-area__input', {
        'evaluable-block__activity__text-area__input--external': !!external_platform_url,
    })

    return (
        <div className='basic-block-content'>
            <BlockIssueMenu blockType={blockType} blockId={block_id} blockName={name} />
            {show_name && (
                <div className='basic-block-content__blockHeader pg-block-title'>
                    <h1>{name}</h1>
                </div>
            )}
            {expiredStatus !== EXPIRATION_STATUS.UNKOWN && (
                <div className='evaluable-block__expiration-message'>
                    <MessageBox text={expiredMessage} theme={expireTheme} fullWidth />
                </div>
            )}
            <Playstrap data={statement} id={block_id} />

            {external_platform_url && (
                <div className='evaluable-block__goto-link'>
                    <CallToAction
                        id='ctaActivity'
                        icon={<ExternalLink />}
                        text={lang.goToActivity}
                        externalLink={external_platform_url}
                        strong
                        onClick={() => {}}
                    />
                </div>
            )}

            <div className='evaluable-block__files'>
                <FilesDownloader files={getActivityFiles()} title={lang.attachedFiles} />
            </div>

            <div className='evaluable-block__activity'>
                <div className={activityDisabledStyle}>
                    <h4 className='evaluable-block__activity--subtitle'>
                        {lang.finishCompleteActivity}
                    </h4>

                    {!!external_platform_url && (
                        <div className='evaluable-block__activity__link'>
                            <div className='paste'>
                                <Link className='paste__icon' />
                                <p className='paste__text'>{lang.pasteActivityLink}</p>
                            </div>
                            <InputText
                                onChange={(e) => setActivityLink(e.target.value)}
                                value={activityLink}
                                type='text'
                                key='blockActivityLink'
                                glow={false}
                                disabled={situations.disabledInputs(reviewStatus, isCourseExpired)}
                            />
                        </div>
                    )}

                    <div className='evaluable-block__activity__text-area'>
                        {!!external_platform_url && (
                            <h4 className='evaluable-block__activity--subtitle'>
                                {lang.developYourActivity}
                            </h4>
                        )}
                        <div className='evaluable-block__activity__text-area__chars'>
                            <LimitCounter
                                limit={charsLimit}
                                text={lang.chars}
                                count={charsCount}
                                limitTheme='danger'
                                limitReached={charsCount >= charsLimit}
                            />
                        </div>
                        <TextAreaInput
                            id='evaluableStudentHandInText'
                            className={handInStyle}
                            value={activityText}
                            onChange={(e) => onChangeActivityText(e)}
                            disabled={situations.disabledInputs(reviewStatus, isCourseExpired)}
                            glow={false}
                        />
                    </div>
                </div>
                <div className='evaluable-block__activity__status'>
                    {!showReviewMessage && (
                        <p className={activityReviewStyle}>
                            <Clock className='status-icon' />
                            {reviewMessage}
                        </p>
                    )}
                    <Button
                        id='buttonSend'
                        className='evaluable-block__activity__status--button'
                        text={lang.sendAnswers}
                        disabled={situations.isSubmitDisabled(
                            isOwner,
                            reviewStatus,
                            isCourseExpired,
                            !!external_platform_url,
                            activityLink,
                            activityText
                        )}
                        onClick={onClickSubmit}
                        loading={loading}
                    />
                </div>

                {showReviewMessage && (
                    <div className='evaluable-block__activity__feedback'>
                        <MessageBox theme={reviewTheme} title={reviewMessage} text='' fullWidth />
                        <Feedback
                            writer={feedbackReview.reporter}
                            text={(
                                <div
                                    dangerouslySetInnerHTML={{ __html: feedbackReview.review }}
                                    className='evaluable-block__activity__feedback--inner-html'
                                />
                            )}
                            imgUrl={emptyStateAsset('default-user-avatar.svg')}
                        />
                        {feedbackReview.files && (
                            <div className='evaluable-block__activity__feedback__files'>
                                <FilesDownloader
                                    files={feedbackReview.files}
                                    title={lang.attachedFiles}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

EvaluableBlock.propTypes = {
    block: PropTypes.shape({
        name: PropTypes.string,
        EvaluableBlock: PropTypes.shape({
            EvaluableBlockFiles: PropTypes.array,
        }),
        topic_id: PropTypes.string,
    }).isRequired,
    isCourseExpired: PropTypes.bool,
    isOwner: PropTypes.bool,
    progressReport: PropTypes.func,
}

EvaluableBlock.defaultProps = {
    isCourseExpired: false,
    progressReport: () => {},
}

export default EvaluableBlock
