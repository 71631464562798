import React from 'react'
import PropTypes from 'prop-types'

import NoResults from '@/components/NoResults'

import './styles/ToolsEmptyState.scss'

const ToolsEmptyState = ({ text }) => (
    <div className="emptyStateContainer">
        <NoResults text={text} />
    </div>
)

ToolsEmptyState.propTypes = {
    text: PropTypes.string.isRequired,
}

export default ToolsEmptyState
