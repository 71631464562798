import * as blockIssueOptionsService from '../http/services/blockIssueOptionsService'
import { setBlockIssueOptions } from '../reducers/student/blockIssueOption'

export const loadBlockIssuesOptions = () => async (dispatch) => {
    const [error, data] = await blockIssueOptionsService.loadBlockIssuesOptions()

    if (error) return

    dispatch(setBlockIssueOptions(data))
}
