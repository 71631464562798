import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Collapse from '@digitalhouse-tech/react-lib-ui-explorer/lib/Collapse'
import { ChevronDown } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { COURSE_ENTITIES_DATA } from '@/constants/courseEntities'

const CourseReportStudents = ({ users, usersDetail, showUserDetailHandler, dataLevel, lang }) => {
    const isTopicsLevel = dataLevel === COURSE_ENTITIES_DATA.TOPICS
    return (
        <>
            {users.map(user => {
                const visibleStudentDetail = usersDetail.includes(user.id)
                const showDetail = !isTopicsLevel && visibleStudentDetail
                return (
                    <div  className={cn(
                        {
                            _report_students_list_open: showDetail,
                        },
                        '_report_students_list',
                    )} key={`report_student_list_${user.id}`}>
                        <Button
                            id={`report_student_button_${user.id}`}
                            key={`report_student_button_${user.id}`}
                            className="_report_course_student_button _report_general_button"
                            theme='clear'
                            text={` ${user.surname} ${user.name} `}
                            icon={
                                !isTopicsLevel && (
                                    <ChevronDown
                                        className={cn(
                                            {
                                                _open_arrow: showDetail,
                                            }
                                        )}
                                    />
                                )
                            }
                            onClick={() => showUserDetailHandler(user.id)}
                        />
                        <Collapse show={showDetail}>
                            <div className="_report_student_detail">
                                <p>{` ${user.Course.percentage}% ${lang.correctContentPercent}`}</p>
                                <p>{` ${user.Course.viewedContentPercentage}% ${lang.viewedContentPercent}`}</p>
                            </div>
                        </Collapse>
                    </div>
                )
            })}
        </>
    )
}

CourseReportStudents.propTypes = {
    users: PropTypes.array.isRequired,
    usersDetail: PropTypes.array.isRequired,
    showUserDetailHandler: PropTypes.func.isRequired,
    dataLevel: PropTypes.string.isRequired,
    lang: PropTypes.object.isRequired,
}

export default CourseReportStudents
