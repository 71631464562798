import React from 'react'
import PropTypes from 'prop-types'
import UserPost from '@digitalhouse-tech/react-lib-ui-explorer/lib/UserPost'
import ReplyUserPost from '@digitalhouse-tech/react-lib-ui-explorer/lib/ReplyUserPost'
import FeedbackBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/FeedbackBox'
import { ExternalLink } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { EXTERNAL, PENDING_RETRY, APPROVED, FAILED } from '../../../shared/constants/dashboard'
import { formatDateMonthName } from '../../../shared/utils/dateHelpers'
import * as filesHandler from '@/helpers/files'
import { emptyStateAsset } from '@/assets/img/config'
import './StaticFeedback.scss'

const StaticFeedback = ({
    lang,
    feedback,
    handIn,
    statusTracking,
    studentName,
    studentStatus,
    type,
}) => {
    const [studentTracking, teacherTracking] = statusTracking
    const noAvatarIcon = emptyStateAsset('default-user-avatar.svg')

    const formatFiles = (files) =>
        files.map((file) => {
            const extension = filesHandler.getFileExtension(file.name)
            return {
                name: file.name,
                size: file.size,
                icon: filesHandler.getIconType(extension),
                onClick: () => filesHandler.openLinkInNewTab(file.url),
            }
        })

    const addSeparator = (length, index) => {
        if (length === 1 || index === length - 1) return
        return index === length - 2 ? ` ${lang.andConector} ` : ','
    }

    const formatRejectReasons = (reasons) =>
        reasons.map((reason, i) => (
            <>
                <strong key={reason}> {reason}</strong>
                {addSeparator(reasons.length, i)}
            </>
        ))

    return (
        <div className='static-feedback'>
            <div className='static-feedback__student-hand-in'>
                <UserPost
                    ctaText={lang.goToActivity}
                    date={formatDateMonthName(studentTracking?.date)}
                    hasCTA={type === EXTERNAL}
                    icon={<ExternalLink />}
                    imgUrl={noAvatarIcon}
                    onClick={() => window.open(handIn.link, '_blank')}
                    text={handIn.message}
                    userName={studentName}
                />
            </div>
            {feedback.reporterId && studentStatus === PENDING_RETRY && (
                <div className='static-feedback__teacher-redo-feedback'>
                    <ReplyUserPost
                        date={formatDateMonthName(teacherTracking?.date)}
                        headerText={
                            feedback.rejectReasons.length > 0 && (
                                <>
                                    {lang.requestRedoReason}
                                    {formatRejectReasons(feedback.rejectReasons)}
                                </>
                            )
                        }
                        replyerName={`${feedback.surname} ${feedback.name}`}
                        text={feedback.feedback}
                    />
                </div>
            )}
            {feedback.reporterId && (studentStatus === APPROVED || studentStatus === FAILED) && (
                <div className='static-feedback__teacher-feedback'>
                    <FeedbackBox
                        boxTitle={lang.feedback}
                        files={formatFiles(feedback.files)}
                        filesTitle={lang.attachedFiles}
                        imgUrl={noAvatarIcon}
                        text={
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: feedback.feedback,
                                }}
                            ></div>
                        }
                        writer={`${feedback.surname} ${feedback.name}`}
                        writerAtTop
                    />
                </div>
            )}
        </div>
    )
}

StaticFeedback.propTypes = {
    lang: PropTypes.object,
    feedback: PropTypes.shape({
        files: PropTypes.array,
        rejectReasons: PropTypes.array,
        reporterId: PropTypes.string,
        feedback: PropTypes.string,
        name: PropTypes.string,
        surname: PropTypes.string,
    }),
    handIn: PropTypes.shape({
        link: PropTypes.string,
        message: PropTypes.string,
    }),
    statusTracking: PropTypes.arrayOf(
        PropTypes.shape({
            status: PropTypes.string,
            date: PropTypes.string,
        })
    ),
    studentName: PropTypes.string,
    studentStatus: PropTypes.string,
    type: PropTypes.string,
}

StaticFeedback.defaultProps = {
    lang: {},
    feedback: {
        files: [],
        rejectReasons: [],
        reporterId: '',
        feedback: '',
        name: '',
        surname: '',
    },
    handIn: {
        link: '',
        message: '',
    },
    statusTracking: [
        {
            status: '',
            date: '',
        },
    ],
    studentName: '',
    studentStatus: '',
    type: '',
}

export default StaticFeedback
