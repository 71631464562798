import React from 'react'
import './PaymentsNotFound.scss'
import cn from 'classnames'
import { useSelector } from 'react-redux'


const PaymentsNotFound = (props) => {
    const { isMobile } = useSelector(({ authReducer }) => authReducer)

    return (
        <>
            <div
            className={cn(
                {
                    'paymentsNotFound--mobile': isMobile,
                },
                'paymentsNotFound'
            )}
            >
                <p>No se encuentran tarjetas asociadas</p>
            </div>
        </>
    )
}

export default PaymentsNotFound
