import moment from 'moment'
import { hourFormatLang } from './dateConfig'

export const formatUsers = (users, userProgress = []) => {
    let existData = false

    const mappedUsers = users.map((user) => {
        let newUser = { ...user }
        newUser.grades.forEach((useGrade) => {
            if (!existData && useGrade.grade) {
                existData = true
            }

            newUser[useGrade.assessmentTypeId] = useGrade.grade || '-'
        })
        newUser.user = {
            email: user.email,
            name: `${user.name} ${user.surname}`,
        }
        const progress = userProgress.find((p) => p.user_id === newUser.id)
        newUser.progress = progress?.percent || null
        return newUser
    })

    return [existData, mappedUsers]
}

export const filterUsers = (users, text) => {
    const value = new RegExp(text, 'gi')
    return users.filter((item) => item.user.name.match(value) || item.user.email.match(value))
}

export const formatFullDate = (date, location) => {
    const dateFormat = `DD [de] MMMM [de] YYYY [${hourFormatLang[location]}] hh:mm [h.]`
    return moment(date).format(dateFormat)
}
