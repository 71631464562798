import React from 'react'
import StudentTabContainer from './StudentTabContainer'

const StudentTabView = () => {
    return (
        <div className="StudentTab viewContainer">
            <StudentTabContainer />
        </div>
    )
}

export default StudentTabView