import React from 'react'
import PropTypes from 'prop-types'
import CallToAction from '@digitalhouse-tech/react-lib-ui-explorer/lib/CallToAction'
import { ArrowLeft } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import SectionTitle from '@digitalhouse-tech/react-lib-ui-explorer/lib/SectionTitle'
import './ReviewHeader.scss'

const ReviewHeader = ({ ctaText, onClick, title }) => {
    return (
        <div className='activity-header'>
            <CallToAction
                id='evaluableActivityGoBack'
                icon={<ArrowLeft />}
                text={ctaText}
                onClick={onClick}
                className='activity-header__cta'
            />
            <SectionTitle text={title} />
        </div>
    )
}

ReviewHeader.propTypes = {
    ctaText: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string,
}

ReviewHeader.defaultProps = {
    ctaText: '',
    onClick: () => {},
    title: '',
}

export default ReviewHeader
