import React, { useState } from 'react'
import PaymentMethodCard from '@digitalhouse-tech/react-lib-ui-explorer/lib/PaymentMethodCard'
import SidebarChangeCard from '@digitalhouse-tech/react-lib-ui-explorer/lib/SidebarChangeCard'
import Container from '@digitalhouse-tech/react-lib-ui-explorer/lib/Container'
import * as env from '@/config/env'
import './ChangePayment.scss'   
import { useDispatch, useSelector } from 'react-redux'
import { updatePaymentMethod } from '@/redux/thunks/changePaymentThunks'
import cn from 'classnames'
import { clearPaymentXuow, setPaymentXuow } from '@/redux/reducers/_deprecated/authReducer/index'
import { v4 as uuidv4 } from 'uuid'
import { NETWORK_ERROR } from './constants/requestErrors'

const ChangePayment = ({
    id,
    expirationDate,
    logoUrl,
    last4,
    brand,
    type,
    clusterName,
    country,
    productId,
    onChangePaymentMethod,
}) => {
    const [showSidebar, setShowSidebar] = useState(false)
    const [onSubmitForm, setOnSubmitForm] = useState(() => {})
    const [externalError, setExternalError] = useState('')
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const { isMobile } = useSelector(({ authReducer }) => authReducer)
    const { paymentXuow } = useSelector(({ authReducer }) => authReducer.user)
    const lang = useSelector(({langStore}) => langStore.paymentMethod)
    const dispatch = useDispatch()

    const onClickChangePaymentMethod = () => {
        setShowSidebar(true)
    }

    const onSubmit = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)
            setExternalError('')
            const formValidationResult = await onSubmitForm()
            const newPaymentMethod = {
                id,
                holderName: formValidationResult.cardHolder,
                token: formValidationResult.dlocalObject.token,
                kind: formValidationResult.kind,
                personalIdNumber: formValidationResult.personalIdNumber,
                personalIdType: formValidationResult.personalIdType,
                brand: formValidationResult.dlocalObject.brand,
                countryCode: country,
                productId,
            }

            const xuow = uuidv4()
            
            if(!paymentXuow.xuow) {
                dispatch(setPaymentXuow(xuow))
            }
            const [error, response] = await dispatch(updatePaymentMethod(newPaymentMethod, paymentXuow.xuow || xuow))
            
            setLoading(false)
            
            if (!response) {
                setExternalError(
                    error?.response?.data?.message ??
                        'En este momento no podemos procesar tu solicitud. Vuelve a intentar más tarde. '
                )
                const errorCode = error?.response?.data?.data?.response?.errorCode
                if(errorCode !== NETWORK_ERROR) {
                    dispatch(clearPaymentXuow())
                }
                return
            }
            setSuccess(true)
            dispatch(clearPaymentXuow())
        } catch (er) {
            setLoading(false)
            console.error(er)
        }
    }

    const providerKeys = {
        ar: env.DLOCAL_AR_KEY,
        co: env.DLOCAL_CO_KEY,
        uy: env.DLOCAL_UY_KEY,
        br: env.DLOCAL_BR_KEY,
        xx: env.DLOCAL_XX_KEY,
        sandboxMode: env.DLOCAL_SANDBOX_MODE,
    }

    const onClickDismiss = async () => {
        if (success) {
            setShowSidebar(false)
            await onChangePaymentMethod()
        }
        setShowSidebar(false)
    }

    return (
        <>
            <div
                className={cn(
                    {
                        'payment__container--mobile': isMobile,
                    },
                    'payment__container'
                )}
            >
                <Container>
                    <PaymentMethodCard
                        expirationDate={expirationDate}
                        logoUrl={logoUrl}
                        last4={last4 ?? '1234'}
                        brand={brand}
                        type={type.es}
                        clusterName={clusterName}
                        onClickChangePaymentMethod={onClickChangePaymentMethod}
                        lang={lang}
                    />
                </Container>
                {showSidebar && (
                    <div className='payment__container__sidebar'>
                        <SidebarChangeCard
                            expirationDate={expirationDate}
                            last4={last4}
                            brand={brand}
                            type={type.es}
                            country={country.toUpperCase()}
                            logoUrl={logoUrl}
                            method={type.en}
                            onClickSubmit={onSubmit}
                            onSubmit={onSubmit}
                            setOnSubmit={setOnSubmitForm}
                            isLoading={loading}
                            displaySuccess={success}
                            onClickDismiss={onClickDismiss}
                            providerKeys={providerKeys}
                            externalError={externalError}
                            lang={lang}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export default ChangePayment
