import axios from 'axios'
import JSFileDownload from 'js-file-download'
import * as courseService from '../http/services/courseService'
import httpHandler, { codes } from '@/helpers/error-handling/httpHandler'
import { actions as blockActions } from '../reducers/blockReducer'
import { setLoading } from '../reducers/loadingReducer'
import { getLoggedUser } from '../selectors/user'
import showNewMessage from '../reducers/_deprecated/globalAlertsReducer/alertsControllers'
import {
    setAllCourses,
    getRemoteClassesStart,
    getRemoteClassesError,
    getRemoteClassesSuccess,
    setActiveTab,
    setCourseType,
    setCourse,
    setCourseHasProgress,
    updateAllCourses,
    setNextCourseLesson
} from '../reducers/courseReducer'
import { setFinishedCourses, setOngoingCourses, setStarredCourses, updateFinishedCourses, updateOngoingCourses, updateStarredCourses } from '../reducers/courseReducer/index'

const mamushkaResourcesActions = {
    block: blockActions,
}

// Se puede usar para cualquier subentidad de course -> unit/lesson/topic/block
export const howManyItemsCanBeRemovedFromChilds =
    (resourceName, resourceId) => async (dispatch, getState) => {
        const course = getState().courseReducer.activeCourse // tomado de versiones anteriores

        dispatch(setLoading.true('course.howManyItemsCanBeRemovedFromChilds'))
        const [error, counts] = await courseService.howManyItemsCanBeRemovedFromChilds(
            course.id,
            resourceName,
            resourceId
        )
        dispatch(setLoading.false('course.howManyItemsCanBeRemovedFromChilds'))

        if (error) return

        const [countCanBeRemoved, countCanNotBeRemoved] = counts

        dispatch(mamushkaResourcesActions[resourceName].setCountCanBeRemoved(countCanBeRemoved))
        dispatch(
            mamushkaResourcesActions[resourceName].setCountCanNotBeRemoved(countCanNotBeRemoved)
        )
    }

export const loadCourse = (courseId) => async (dispatch) => {
    dispatch(setLoading.true('course.loadCourse'))
    const [error, course] = await courseService.loadCourse(courseId)
    dispatch(setLoading.false('course.loadCourse'))

    if (error) return

    dispatch(setCourse(course))

    return course
}

export const loadNextCourseLesson = () => async (dispatch, getState) => {
    const { id } = getLoggedUser(getState())

    dispatch(setLoading.true('course.loadNextCourseLesson'))
    const [_error, course] = await courseService.getNextCourseLesson(id)
    dispatch(setLoading.false('course.loadNextCourseLesson'))

    dispatch(setNextCourseLesson(course))
}

export const loadAllStudentEnrolments = () => async (dispatch, getState) => {
    const { id } = getLoggedUser(getState())

    dispatch(setLoading.true('course.loadAllStudentEnrolments'))
    const [errorStarred, starredCourses] = await courseService.loadAllStudentEnrolments(id, 'favourites')
    const [errorOngoing, ongoingCourses] = await courseService.loadAllStudentEnrolments(id, 'actives')
    const [errorFinished, finishedCourses] = await courseService.loadAllStudentEnrolments(id, 'staleds')

    dispatch(setLoading.false('course.loadAllStudentEnrolments'))

    if (errorStarred || errorOngoing || errorFinished) return
    dispatch(setStarredCourses(starredCourses))
    dispatch(setOngoingCourses(ongoingCourses))
    dispatch(setFinishedCourses(finishedCourses))

}

export const loadNextCoursesPage = (filter) => async (dispatch, getState) => {
    const { id } = getLoggedUser(getState())
    
    const courseStatuses = {
        ['starredCourses']: {status: 'favourites', setCourses: (c) => setStarredCourses(c)},
        ['ongoingCourses']: {status: 'actives', setCourses: (c) => setOngoingCourses(c)},
        ['finishedCourses']: {status: 'staleds', setCourses: (c) => setFinishedCourses(c)}
    }

    const page = getState().course[filter].pagination.page + 1 

    const [error, courses] = await courseService.loadAllStudentEnrolments(id, courseStatuses[filter].status, page)
    
    if (error) return
    
    dispatch(courseStatuses[filter].setCourses(courses))

}

export const setStarredCourse = (courseId, data) => async (dispatch, getState) => {
    const { id } = getLoggedUser(getState())

    dispatch(setLoading.true('course.setStarredCourse'))

    const [error] = await courseService.setStarredDateCourse(id, courseId, data)

    if(data.starred_date) {
        const courseToStar = [...getState().course.ongoingCourses.courses, ...getState().course.finishedCourses.courses].find(c => c.id === courseId)
        dispatch(updateStarredCourses(courseToStar))
    } else {
        dispatch(updateStarredCourses(getState().course.starredCourses.courses.filter(c => c.id !== courseId)))
        
    }
    const mappedOngoing = getState().course.ongoingCourses.courses.map(course => {
        if (course.id === data.course_id) {
            course.starred_date = data.starred_date
        }
        return course
    })
    const mappedFinished = getState().course.finishedCourses.courses.map(course => {
        if (course.id === data.course_id) {
            course.starred_date = data.starred_date
        }
        return course
    })
    dispatch(updateOngoingCourses(mappedOngoing))
    dispatch(updateFinishedCourses(mappedFinished))
    dispatch(setLoading.false('course.setStarredCourse'))

    if (error) return

}

export const loadLastUserProgress = (courseId) => async (dispatch, getState) => {
    const { id } = getLoggedUser(getState())

    dispatch(setLoading.true('course.loadLastUserProgress'))
    const [error, lastMovement] = await courseService.loadLastUserProgress(id, courseId)
    dispatch(setLoading.false('course.loadLastUserProgress'))

    if (error) return {}

    await dispatch(setCourseType(lastMovement.CourseType))

    return lastMovement
}

export const getUserProgressPercent = (courseId) => async (dispatch, getState) => {
    const { id } = getLoggedUser(getState())

    dispatch(setLoading.true('course.getUserProgressPercent'))
    const [error, userProgress] = await courseService.getUserProgressPercent(id, courseId)
    dispatch(setLoading.false('course.getUserProgressPercent'))

    if (error) return {}

    return userProgress
}

export const getRemoteClasses = (courseId) => async (dispatch) => {
    dispatch(getRemoteClassesStart())

    const [error, response] = await courseService.getRemoteClassesURL(courseId)

    if (error) {
        dispatch(getRemoteClassesError(error))
        return
    }

    const remoteClass = response.visibility ? response : null

    dispatch(getRemoteClassesSuccess(remoteClass))
}

export const refreshCourseCacheInfo = (courseId) => async (dispatch, getState) => {
    const {
        langStore: {
            course: {
                courseCache: { refreshError, refreshSuccess },
            },
        },
    } = getState()

    dispatch(setLoading.true('course.refreshCache'))

    const [error] = await courseService.refreshCourseCache(courseId)
    const msgType = error ? 'danger' : 'success'
    const msgText = error ? refreshError : refreshSuccess

    dispatch(setLoading.false('course.refreshCache'))

    showNewMessage({
        type: msgType,
        message: msgText,
        isTemporal: true,
    })
}

export const loadActiveTab = (courseId, tabId) => async (dispatch, getState) => {
    const [error, tab] = await courseService.loadTab(courseId, tabId)

    if (error) return
    dispatch(setActiveTab(tab))
}

export const downloadCourseCertificate =
    (userId, courseId, userSurname, courseName) => async (dispatch, getState) => {
        const lang = getState().langStore.courseInfo.backendMessages

        dispatch(setLoading.true('course.certificate'))
        const [error, url] = await courseService.getCourseCertificate(userId, courseId)

        if (error) {
            return httpHandler(error)({
                [codes.NOT_FOUND]: () => history.push('/not-found'),
                [codes.INTERNAL_SERVER_ERROR]: () => {
                    showNewMessage({
                        type: 'danger',
                        message: lang.internalServerError,
                        isTemporal: true,
                    })
                },
            })
        }

        const file = await axios.get(url, { responseType: 'blob' })
        JSFileDownload(file.data, `${courseName}_${userSurname}_DigitalHouse.pdf`)
        dispatch(setLoading.false('course.certificate'))
    }

export const hasAnyProgress = (courseId) => async (dispatch, getState) => {
    const [error, userProgressQty] = await courseService.hasAnyProgress(courseId)
    if (error) return
    const hasProgress = userProgressQty > 0
    dispatch(setCourseHasProgress(hasProgress))
}
