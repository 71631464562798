import React, { useState, useLayoutEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { getLoggedUser } from '@/redux/selectors/user'

import Onboarding from '@/components/Onboarding'
import { ONBOARDING_TYPE } from '@/constants/onboarding'
import { allowApiCreate } from '@/helpers/granters/courseTypeRules'
import LoadingOrContent from '@/components/LoadingOrContent'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'

import { ChevronsLeft, ChevronsRight } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import LessonList from './LessonList'
import UnitNav from './UnitNav'
import Skeleton from './Skeleton'
import './ContentMap.scss'

const ContentMap = ({
    course,
    lessons,
    unitName,
    moduleNumber,
    prevUnit,
    nextUnit,
    isMobile,
    loading,
}) => {
    const { onboarding } = useSelector(getLoggedUser)

    const [isOpen, setIsOpen] = useState(true)
    const handleToggleVisibility = () => {
        refreshIframes()
        setIsOpen(!isOpen)
    }

    const refreshIframes = useCallback(() => {
        const iframes = [...document.querySelectorAll('iframe')]
        if (!iframes.length) return

        iframes.forEach((iframe) => {
            const src = iframe.getAttribute('src')
            const srcdoc = iframe.getAttribute('srcdoc')

            srcdoc && iframe.setAttribute('srcdoc', srcdoc)
            src && iframe.setAttribute('src', src)
        })
    })

    useLayoutEffect(() => {
        let resizeTimer

        window.addEventListener('resize', () => {
            clearTimeout(resizeTimer)
            resizeTimer = setTimeout(refreshIframes, 250)
        })

        return () => {
            window.removeEventListener('resize', refreshIframes)
            clearTimeout(resizeTimer)
        }
    })

    const { hide, module: moduleWord } = useSelector(({ langStore: { progressReducer } }) => progressReducer)

    return (
        <LoadingOrContent
            loading={loading}
            loadingComponent={
                <Skeleton
                    className="content-map-container__skeleton-container"
                    isMobile={isMobile}
                />
            }
        >
            <div
                className={cn({
                    'content-map-column': isOpen,
                })}
            >
                <div
                    className={cn('content-map-container', {
                        'content-map-container--onboarding-active':
                            onboarding === ONBOARDING_TYPE.NAV_POPOVER,
                    })}
                >
                    {!isMobile && (
                        <div
                            className={cn('content-map-container__toggle-container', {
                                'content-map-container__toggle-container--open': isOpen,
                            })}
                        >
                            <Button
                                className={cn('content-map-container__toggle-container__button', {
                                    'content-map-container__toggle-container--open__button': isOpen,
                                })}
                                id="ContentMapHideButton"
                                text={isOpen ? hide : ''}
                                icon={isOpen ? <ChevronsLeft /> : <ChevronsRight />}
                                theme="clear"
                                onClick={handleToggleVisibility}
                            />
                        </div>
                    )}

                    <div
                        className={cn({
                            'hide-panel': !isOpen && !isMobile,
                            'content-map-container__module-container': isOpen || isMobile,
                            'content-map-container__module-container--mobile': isMobile,
                        })}
                    >
                        <div className="content-map-container__header">
                            <h4 className="content-map-container__header__module-name">{`${moduleWord} ${moduleNumber}`}</h4>

                            <UnitNav unitName={unitName} prevUnit={prevUnit} nextUnit={nextUnit} />
                        </div>

                        <LessonList
                            lessons={lessons}
                            showDates={course.show_dates}
                            allowApiCreate={course.CourseType && allowApiCreate(course.CourseType)}
                        />
                    </div>
                </div>
            </div>
            <Onboarding
                step={ONBOARDING_TYPE.SIDE_POPOVER}
                className="content-map-column__onboarding-content"
            />
        </LoadingOrContent>
    )
}

ContentMap.propTypes = {
    course: PropTypes.object,
    lessons: PropTypes.array,
    moduleNumber: PropTypes.number,
    prevUnit: PropTypes.object,
    nextUnit: PropTypes.object,
    unitName: PropTypes.string,
    isMobile: PropTypes.bool,
    loading: PropTypes.bool,
}

ContentMap.defaultProps = {
    course: {},
    lessons: [],
    moduleNumber: 1,
    prevUnit: null,
    nextUnit: null,
    unitName: '',
    isMobile: false,
    loading: false,
}

export default ContentMap