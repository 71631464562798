import React, { useEffect } from 'react'
import routes from '@/routes'

const LoginRedirect = ({ location, path }) => {
    useEffect(() => {
        const route = routes.find((route) => route.path === path)
        const query =
            location.pathname != '/'
                ? `?redirect=${encodeURIComponent(location.pathname)}&redirectGuard=${route?.guard}`
                : ''
        window.location.replace(`/login${query}`)
    }, [])

    return null;
}

export default LoginRedirect
