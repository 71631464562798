'use-strict'

const initialState = {
    all: [],
    single: {
        id: '',
        name: '',
        surname: '',
        email: '',
        coursePermissions: '',
        OwnershipRoles: [],
        UserHasOwnershipRoles: []
    },
    coursePermissions: [],
    ownershipRoles: [],
}

const CLEAR_ALL_OWNERS = '@@owner/CLEAR_ALL_OWNERS'
const SET_SINGLE_OWNER = '@@owner/SET_SINGLE_OWNER'
const CLEAR_SINGLE_OWNER = '@owner/CLEAR_SINGLE_OWNER'
const SET_OWNERSHIP_ROLES = '@@owner/SET_OWNERSHIP_ROLES'
const CLEAR_OWNERSHIP_ROLES = '@@owner/CLEAR_OWNERSHIP_ROLES'
const LOAD_OWNERS_COURSE = '@owner/LOAD_OWNERS_COURSE'
const DELETE_OWNER_COURSE = '@owner/DELETE_OWNER_COURSE'
const CREATE_OWNERS_COURSE = '@owner/CREATE_OWNERS_COURSE'
const LOAD_COURSE_PERMISSIONS = '@owner/LOAD_COURSE_PERMISSIONS'
const SET_VISIBILITY = '@owner/SET_VISIBILITY'

export const clearAllOwnersAction = () => ({
    type: CLEAR_ALL_OWNERS,
})

export const setSingleOwnerAction = owner => ({
    type: SET_SINGLE_OWNER,
    payload: { owner },
})

export const clearSingleOwnerAction = () => ({
    type: CLEAR_SINGLE_OWNER,
})

export const setOwnershipRoles = ownershipRoles => ({
    type: SET_OWNERSHIP_ROLES,
    payload: { ownershipRoles },
})

export const clearOwnershipRoles = () => ({
    type: CLEAR_OWNERSHIP_ROLES,
})

export const loadOwnersAction = owners => ({
    type: LOAD_OWNERS_COURSE,
    payload: { owners },
})

export const deleteOwnerAction = data => ({
    type: DELETE_OWNER_COURSE,
    payload: { data },
})

export const createOwnerAction = data => ({
    type: CREATE_OWNERS_COURSE,
    payload: { data },
})

export const loadCoursePermissionsAction = data => ({
    type: LOAD_COURSE_PERMISSIONS,
    payload: { data },
})

export const setVisibilityAction = userId => ({
    type: SET_VISIBILITY,
    payload: { userId },
})

const ownerReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_OWNERS_COURSE:
            return {
                ...state,
                all: action.payload.owners,
            }
        case CLEAR_ALL_OWNERS:
            return {
                ...state,
                all: initialState.all,
            }
        case SET_OWNERSHIP_ROLES:
            return {
                ...state,
                ownershipRoles: action.payload.ownershipRoles,
            }
        case CLEAR_OWNERSHIP_ROLES:
            return {
                ...state,
                ownershipRoles: initialState.ownershipRoles,
            }
        case DELETE_OWNER_COURSE:
            return {
                ...state,
                all: state.all.filter(owner => owner.id !== action.payload.data),
            }
        case CREATE_OWNERS_COURSE:
            return {
                ...state,
                all: [...state.all, ...action.payload.data],
            }
        case LOAD_COURSE_PERMISSIONS:
            return {
                ...state,
                coursePermissions: action.payload.data,
            }
        case SET_SINGLE_OWNER:
            return {
                ...state,
                single: action.payload.owner,
            }
        case SET_VISIBILITY: {
            const owners = [...state.all]
            const index = owners.findIndex(({ id }) => id === action.payload.userId)
            owners[index].OwnershipRole.UserHasOwnershipRole.visibility = !owners[index].OwnershipRole.UserHasOwnershipRole.visibility
            return {
                ...state,
                all: owners,
            }
        }
        case CLEAR_SINGLE_OWNER:
            return {
                ...state,
                single: initialState.single,
            }
        case 'LOGOUT_USER': {
            return initialState
        }
        default: {
            return state
        }
    }
}

export default ownerReducer
