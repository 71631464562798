import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Search, X } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { actions as managerActions } from '@/redux/reducers/managerReducer'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import LoadingOrContent from '@digitalhouse-tech/react-lib-ui-explorer/lib/LoadingOrContent'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

export const ManagerHeader = ({
    goToPageTop,
    screenTitle,
    screenSmallTitle,
    rightToolbar,
    searchEnabled,
    searcherTitle,
    searcherFormBody,
    searcherCleanButtonText,
    searcherSubmitButtonText,
    searcherSubmitHandler,
    searcherFields,
    searchOnMount,
    openSearcherOnMount,
    loading,
}) => {
    const dispatch = useDispatch()
    const searcherValues = useSelector((state) => state.manager.searcher.search)
    const [showSearchbar, setShowSearchBar] = useState(openSearcherOnMount)

    useEffect(() => {
        if (searchOnMount)
            dispatch(managerActions.setSearcherDefaultValues(searcherFields))
        searcherSubmitHandler()
    }, [])

    const getSearcherHandlerButton = () => {
        if (!searchEnabled) return null
        return (
            <ButtonRound
                id='searchStudents'
                onClick={() => setShowSearchBar(!showSearchbar)}
                icon={showSearchbar ? <X /> : <Search />}
                theme='info'
            />
        )
    }

    const onSearchSubmit = async (event) => {
        event.preventDefault()
        await searcherSubmitHandler()
        goToPageTop()
    }

    return (
        <LoadingOrContent loading={loading}>
            <div className='__main_header'>
                <Row>
                    <Col lg="8">
                        <h1 className='__main_title'>
                            {screenTitle}
                            <small>{screenSmallTitle}</small>
                        </h1>
                    </Col>
                    <Col lg="4">
                        <ButtonGroup className='pull-right'>
                            {getSearcherHandlerButton()}
                            {rightToolbar}
                        </ButtonGroup>
                    </Col>
                </Row>
                {searchEnabled && showSearchbar ? (
                    <>
                        <h3 className='__search_title'>{searcherTitle}</h3>
                        <form>
                            {searcherFormBody}
                            <ButtonGroup className='pull-right'>
                                <Button
                                    id='cleanSearch'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        dispatch(
                                            managerActions.setSearcherDefaultValues(searcherFields)
                                        )
                                        searcherSubmitHandler()
                                    }}
                                    text={searcherCleanButtonText}
                                    theme='secondary'
                                />
                                <Button
                                    id='submitSearch'
                                    onClick={(e) => onSearchSubmit(e)}
                                    text={searcherSubmitButtonText}
                                    theme='primary'
                                    type='submit'
                                />
                            </ButtonGroup>
                        </form>
                    </>
                ) : null}
                <div className='clearfix' />
            </div>
        </LoadingOrContent>
    )
}

ManagerHeader.defaultProps = {
    screenTitle: '',
    screenSmallTitle: '',
    rightToolbar: null,
    searchEnabled: false,
    searcherTitle: '',
    searcherFormBody: null,
    searcherCleanButtonText: '',
    searcherSubmitButtonText: '',
    searcherSubmitHandler: () => {},
    searcherFields: {},
    searchOnMount: false,
    openSearcherOnMount: false,
    loading: false,
}

ManagerHeader.propTypes = {
    goToPageTop: PropTypes.func.isRequired,
    screenTitle: PropTypes.string,
    screenSmallTitle: PropTypes.string,
    rightToolbar: PropTypes.node,
    searchEnabled: PropTypes.bool,
    searcherTitle: PropTypes.string,
    searcherFormBody: PropTypes.node,
    searcherCleanButtonText: PropTypes.string,
    searcherSubmitButtonText: PropTypes.string,
    searcherSubmitHandler: PropTypes.func,
    searcherFields: PropTypes.shape({
        search: PropTypes.shape().isRequired,
    }),
    searchOnMount: PropTypes.bool,
    openSearcherOnMount: PropTypes.bool,
    loading: PropTypes.bool,
}

export default ManagerHeader
