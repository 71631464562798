import api from '@/config/endpoints'
import axios from '@/app/requests'
import { getConfig, getErrorResponse, getFileConfig } from '@/helpers/utils'
import FileDownload from 'js-file-download'
import { history } from '@/app/store.js'
import moment from "moment"
import { getTenantLang } from '@/helpers/tenants/helpers'
moment.locale(getTenantLang())

import configureStore from '@/app/store'
import { loadLastUserProgress } from '../../../thunks/courseThunks'
const getStore = () => configureStore.store

//INITIAL STATE

const initialState = {
    allCourseSurveys: { data: [], isLoaded: false },
    courseChildren: 0,
    coursesModified: [],
    coursesNotModified: [],
    allSurveys: false,
    editSurvey: {},
    courseSurvey: defaultCourseSurvey(),
    show: false,
    modalTitle: '',
    surveyByUser: { data: [], isLoaded: false },
    surveyAnswers: [],
    showErrors: false,
    surveysWelcome: [],
    activeSurvey: {},
    success: false,
    returnToWelcome: true,
    allreadyAnswered: []
}

function defaultCourseSurvey() {
    return { nameSurvey: '', surveyId: null, courseId: null, id: null, date: null, visibility: true, mandatory: false, base: false }
}

//CONSANTS
const LOAD_COURSES_SURVEYS = 'LOAD_COURSES_SURVEYS'
const LOAD_COURSE_CHILDREN = 'LOAD_COURSE_CHILDREN'
const LOAD_COURSES_MODIFIED = 'LOAD_COURSES_MODIFIED'
const CLEAR_COURSES_MODIFIED = 'CLEAR_COURSES_MODIFIED'
const UPDATE_COURSES_SURVEYS = 'UPDATE_COURSES_SURVEYS'
// const UPDATE_ALL_COURSES_SURVEYS = 'UPDATE_ALL_COURSES_SURVEYS'
const LOAD_ALL_SURVEYS = 'LOAD_ALL_SURVEYS'
const LOAD_LAST_SURVEY = 'LOAD_LAST_SURVEY'
const LOAD_SURVEY_COURSE_USER = 'LOAD_SURVEYS_COURSE_USER'
const LOAD_ACTIVE_SURVEY = 'LOAD_ACTIVE_SURVEY'


const SHOW_MODAL_SURVEY = 'SHOW_MODAL_SURVEY'
const EDIT_SURVEY = 'EDIT_SURVEY'
const CLOSE_MODAL_SURVEY = 'CLOSE_MODAL_SURVEY'
const CLEAR_LOADED = 'CLEAR_LOADED'

const SAVE_SURVEY_ANSWER = 'SAVE_SURVEY_ANSWER'
const SUBMIT_SURVEY_ANSWERS = 'SUBMIT_SURVEY_ANSWERS'
const SHOW_SURVEY_ERRORS = 'SHOW_SURVEY_ERRORS'
const SET_SUBMIT_SUCCESS = 'SET_SUBMIT_SUCCESS'
const RETURN_TO_WELCOME = 'RETURN_TO_WELCOME'
const DONT_RETURN_TO_WELCOME = 'DONT_RETURN_TO_WELCOME'

//ACTIONS
export const loadCoursesSurveysAction = (data) => ({
    type: LOAD_COURSES_SURVEYS, data
})

export const loadCourseChildrenAction = (data) => ({
    type: LOAD_COURSE_CHILDREN, data
})

export const loadCoursesModifiedAction = (modified, notModified) => ({
    type: LOAD_COURSES_MODIFIED, modified, notModified
})

export const clearCoursesModifiedAction = () => ({
    type: CLEAR_COURSES_MODIFIED
})

export const updateCoursesSurveysAction = (data) => ({
    type: UPDATE_COURSES_SURVEYS, data
})

// export const updateAllCoursesSurveysAction = data => ({
//   type: UPDATE_ALL_COURSES_SURVEYS, data
// })

export const editSurveyAction = (data) => ({
    type: EDIT_SURVEY, data
})

export const showModalSurveyAction = () => ({
    type: SHOW_MODAL_SURVEY
})

export const closeModalSurveyAction = () => ({
    type: CLOSE_MODAL_SURVEY
})

export const loadAllSurveysAction = (data) => ({
    type: LOAD_ALL_SURVEYS, data
})

export const clearLoadedAction = () => ({
    type: CLEAR_LOADED
})

export const loadLastSurveyAction = (data, id) => ({
    type: LOAD_LAST_SURVEY, data
})

export const saveSurveyAnswerAction = (data) => ({
    type: SAVE_SURVEY_ANSWER, data
})

export const submitSurveyAnswersAction = (data) => ({
    type: SUBMIT_SURVEY_ANSWERS, data
})

export const showSurveyAnswerError = (data) => ({
    type: SHOW_SURVEY_ERRORS, data
})

export const loadSurveyCourseUserAction = (data) => ({
    type: LOAD_SURVEY_COURSE_USER, data
})

export const loadActiveSurveyAction = (data) => ({
    type: LOAD_ACTIVE_SURVEY, data
})

export const setSubmitSuccessAction = (data) => ({
    type: SET_SUBMIT_SUCCESS, data
})

export const returnToWelcomeAction = () => ({
    type: RETURN_TO_WELCOME
})

export const dontReturnToWelcomeAction = () => ({
    type: DONT_RETURN_TO_WELCOME
})


//FUNCTIONS

const formatSurveys = (data) => {
    let allSurveys = []
    data.map(survey => {

        allSurveys.push({
            value: survey.id,
            label: survey.name
        })
    })
    return allSurveys
}

export const loadCoursesSurveys = (courseId) => dispatch => {
    let config = getConfig()
    axios.get(api.courses + '/' + courseId + '/surveys', config)
        .then(data => {
            dispatch(loadCourseChildrenAction(data.count))
            dispatch(loadCoursesSurveysAction(data.survey))
        })
        .catch(err => {
            dispatch(getErrorResponse(err))
        })
}

export const loadLastSurvey = (courseId, surveyId) => dispatch => {
    let config = getConfig()
    axios.get(api.courses + "/" + courseId + '/lastSurvey/' + surveyId, config)
        .then(data => {
            dispatch(loadLastSurveyAction(data))
        })
        .catch(err => {
            dispatch(getErrorResponse(err))
        })
}

export const createCourseSurvey = (data, allCourseSurveys, students) => dispatch => {
    let body = {
        course_id: data.courseId,
        survey_id: data.surveyId,
        date: data.date,
        mandatory: data.mandatory,
        visibility: data.visibility,
        base: data.base,
        students: students
    }

    let config = getConfig()
    axios.post(api.courses + '/' + data.courseId + '/surveys', body, config)
        .then(data => {
            let aux = allCourseSurveys.data.slice(0)
            aux.push(data.courseSurvey)
            dispatch(loadCoursesModifiedAction(data.coursesModified, data.coursesNotModified))
            dispatch(loadCoursesSurveysAction(aux))
        })
        .catch(err => {
            dispatch(getErrorResponse(err))
        })
}

export const updateCourseSurvey = (data, allCourseSurveys) => dispatch => {

    let body = {
        id: data.id,
        date: data.date,
        mandatory: data.mandatory,
        visibility: data.visibility,
        base: data.base
    }
    let config = getConfig()
    axios.patch(api.courses + '/' + data.courseId + '/surveys', body, config)
        .then(data => {
            let aux = allCourseSurveys.data.slice(0)
            let ind = aux.findIndex(survey => survey.id == body.id)
            aux[ind] = data.courseSurvey
            dispatch(loadCoursesModifiedAction(data.coursesModified, data.coursesNotModified))
            dispatch(loadCoursesSurveysAction(aux))
        })
        .catch(err => {
            dispatch(getErrorResponse(err))
        })
}

export const loadAllSurveys = () => dispatch => {
    let config = getConfig()
    axios.get(api.courses + '/surveys', config)
        .then(data => {
            dispatch(loadAllSurveysAction(data))
        })
        .catch(err => {
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

export const getReport = (courseId, courseSurveyId, courseName) => dispatch => {
    let config = getFileConfig()
    axios.get(api.courses + '/' + courseId + '/surveys/' + courseSurveyId, config)
        .then((response) => {
            let filename = moment().format("HH:mm:ss-DD-MM-YYYY") + "_" + courseName + '.xlsx'
            FileDownload(response, filename)
        })
}

export const saveSurveyAnswer = (data, surveyAnswers) => dispatch => {
    let copySurveyAnswer = _.cloneDeep(surveyAnswers)
    let ind

    ind = copySurveyAnswer.findIndex(answer => answer.survey_question_id == data.survey_question_id)
    if (ind !== -1) {
        copySurveyAnswer[ind] = data
    } else {
        copySurveyAnswer.push(data)
    }

    dispatch(saveSurveyAnswerAction(copySurveyAnswer))

}

export const submitSurveyAnswers = (data, courseId, userId, returnToWelcome, courseSurveuyUserId) => (dispatch, getState) => {
    let config = getConfig()
    axios.post(api.courses + '/' + courseId + '/lastSurvey/' + userId, data, config)
        .then(async data => {
            dispatch(setSubmitSuccessAction(courseSurveuyUserId))
            if (!returnToWelcome) {
                const up = await dispatch(loadLastUserProgress(courseId))
                history.push(`/course/${courseId}/unit/${up.unit_id}/lesson/${up.lesson_id}/topic/${up.topic_id}`) // history.push('/student_course/' + courseId)
                // todo: Esta logica, la de encontrar el progreso de un curso, lo podria reutilizar en varias partes de la app
            } else {
                history.push("/")
            }

        })
        .catch(err => {
            dispatch(getErrorResponse(err))
        })
}

export const loadSurveyCourseUser = () => dispatch => {
    let config = getConfig()
    axios.get(api.courses + '/surveys/all', config)
        .then(data => {
            dispatch(loadSurveyCourseUserAction(data))
        })
        .catch(err => {
            dispatch(getErrorResponse(err))
        })
}

export default (state = initialState, action) => {

    switch (action.type) {
        case LOAD_COURSES_SURVEYS:
            return {
                ...state,
                allCourseSurveys: { data: action.data, isLoaded: true },
                show: false,
            }
        case UPDATE_COURSES_SURVEYS:
            return {
                ...state,
                courseSurvey: action.data
            }
        case EDIT_SURVEY:
            return {
                ...state,
                courseSurvey: action.data,
                // modalTitle: "Editar Encuesta",
                show: true,

            }
        case SHOW_MODAL_SURVEY:
            return {
                ...state,
                show: true,
                // modalTitle: "Crear Encuesta",
                courseSurvey: defaultCourseSurvey(),
                editSurvey: {}
            }
        case CLOSE_MODAL_SURVEY:
            return {
                ...state,
                show: false,
                modalTitle: "",
                courseSurvey: defaultCourseSurvey(),
                editSurvey: {}
            }
        case LOAD_ALL_SURVEYS:
            return {
                ...state,
                allSurveys: formatSurveys(action.data)
            }
        case CLEAR_LOADED:
            return {
                ...state,
                allCourseSurveys: { data: [], isLoaded: false },
                surveyByUser: { data: [], isLoaded: false },
                surveyAnswers: [],
                showErrors: false,
                success: false
            }
        case LOAD_LAST_SURVEY:
            return {
                ...state,
                surveyByUser: { data: action.data, isLoaded: true },
            }
        case SAVE_SURVEY_ANSWER:
            return {
                ...state,
                surveyAnswers: action.data
            }
        case SHOW_SURVEY_ERRORS:
            return {
                ...state,
                showErrors: action.data
            }
        case LOAD_SURVEY_COURSE_USER:
            return {
                ...state,
                surveysWelcome: action.data,
                allreadyAnswered: []
            }
        case LOAD_ACTIVE_SURVEY:
            return {
                ...state,
                activeSurvey: action.data
            }
        case SET_SUBMIT_SUCCESS:
            return {
                ...state,
                success: true,
                allreadyAnswered: [...state.allreadyAnswered, { id: action.data, answered: true }]
            }
        case DONT_RETURN_TO_WELCOME:
            return {
                ...state,
                returnToWelcome: false
            }
        case RETURN_TO_WELCOME:
            return {
                ...state,
                returnToWelcome: true
            }
        case LOAD_COURSE_CHILDREN:
            return {
                ...state,
                courseChildren: action.data
            }
        case LOAD_COURSES_MODIFIED:
            return {
                ...state,
                coursesModified: action.modified,
                coursesNotModified: action.notModified,
            }
        case CLEAR_COURSES_MODIFIED:
            return {
                ...state,
                coursesModified: [],
                coursesNotModified: [],
            }
        case 'LOGOUT_USER':
            return initialState
        default:
            return state
    }
}