import React from 'react'
import PropTypes from 'prop-types'
import DashboardHeader from '../shared/components/DashboardHeader/DashboardHeader'
import DatePicker from 'react-datepicker'
import { CustomFormField } from '@/components/CustomFormField'
import 'react-datepicker/dist/react-datepicker.css'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import { CLASS_PROGRESS_SECTION } from '../shared/constants/dashboard'
import useClassProgress from './useClassProgress'

import './ClassProgress.scss'

const ClassProgress = ({ match }) => {
    const courseId = match.params.courseId
    const {
        name,
        alias,
        is_massive,
        lang,
        validDateRange,
        dateFrom,
        dateTo,
        downloadIsLoading,
        alertTheme,
        alertMessage,
        fieldsError,
        dateFormat,
        onChangeDates,
        downloadReport
    } = useClassProgress(courseId)

    return (
        <div className='container'>
            <DashboardHeader
                section={CLASS_PROGRESS_SECTION}
                courseName={name}
                courseAlias={alias}
                courseMassive={is_massive}
            />
            <div className='class-progress'>
                <MessageBox
                    theme={alertTheme}
                    className='class-progress__alert'
                    title={alertMessage}
                    fullWidth
                    centeredText
                />
                <div className='class-progress__box'>
                    <div className='class-progress__box--input'>
                        <CustomFormField
                            validationState={fieldsError && 'error'}
                            bsSize='small'
                            controlId='date'
                            label={lang.classReportFrom}
                            showMessageBox={false}
                            inputComponent={
                                <DatePicker
                                    className='form-control input-sm datePickerWidth'
                                    dateFormat={dateFormat}
                                    selected={dateFrom}
                                    name='from_date'
                                    onChange={(date) => onChangeDates(date, 'from')}
                                    placeholderText={'DD/MM/AAAA'}
                                />
                            }
                        />
                    </div>
                    <div className='class-progress__box--input'>
                        <CustomFormField
                            validationState={fieldsError && 'error'}
                            bsSize='small'
                            controlId='date'
                            label={lang.classReportTo}
                            showMessageBox={false}
                            inputComponent={
                                <DatePicker
                                    className='form-control input-sm datePickerWidth'
                                    dateFormat={dateFormat}
                                    selected={dateTo}
                                    name='to_date'
                                    onChange={(date) => onChangeDates(date, 'to')}
                                    placeholderText={'DD/MM/AAAA'}
                                />
                            }
                        />
                    </div>
                    <div className='class-progress__box--button'>
                        <Button
                            disabled={!validDateRange || downloadIsLoading}
                            id='downloadButton'
                            theme='primary'
                            onClick={downloadReport}
                            text={lang.classReportDownload}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

ClassProgress.propTypes = {
    match: PropTypes.object,
}

ClassProgress.defaultProps = {
    match: {},
}

export default ClassProgress
