import React from 'react'
import { Modal } from 'react-bootstrap'
import ReduxModal from '@/components/ReduxModal'
import './styles/DeleteBlockWarningModal.scss'
import { AlertCircle } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'

const DeleteBlockWarningModal = ({
    onCancel,
    countCanBeRemoved,
    countCanNotBeRemoved,
    showCounters,
    id,
    onContinue,
    lang
}) => {

    const getWarningIcon = () => <AlertCircle />

    const getCanBeRemovedText = () => {
        if (countCanBeRemoved) {
            const word = countCanBeRemoved > 1 ? lang.clons : lang.clon
            return `- ${countCanBeRemoved} ${word}`
        }
        return null
    }

    const getCanNotBeRemovedText = () => {
        if (countCanNotBeRemoved) {
            const word = countCanNotBeRemoved > 1 ? lang.clons : lang.clon
            return lang.cannotBeRemoved
                .replace('{{countCanNotBeRemoved}}', countCanNotBeRemoved)
                .replace('{{word}}', word)
        }
        return null
    }

    return (
        <ReduxModal
            id={id}
            className="__modal __modal_confirmation"
            keyboard={false}
            backdrop="static">
            <Modal.Header closeButton className="__modal_header __border_bottom">
                <h3 className="__modal_title danger">{lang.title}</h3>
            </Modal.Header>

            <Modal.Body className="__modal_body">
                <div className="delete-block-warning-modal-body">
                    {lang.bodyAsk}
                    <h2 className="warning-title">
                        {lang.warnings}
                    </h2>
                    <ul className="warnings-list">
                        <li>{getWarningIcon()}{lang.actionCannotBeUndone}</li>
                        {showCounters && (
                            <>
                                <li>
                                    {getWarningIcon()}{lang.canBeRemoved}
                                    <br />- {lang.baseCourse}
                                    <br />{getCanBeRemovedText()}
                                </li>
                                {countCanNotBeRemoved ? (
                                    <li>
                                        {getWarningIcon()}
                                        {getCanNotBeRemovedText()}
                                    </li>
                                ) : null}
                            </>
                        )}
                    </ul>
                </div>
            </Modal.Body>

            <Modal.Footer className="__modal_footer __border_top">
                <ButtonGroup>
                    <Button
                        id="ContitnueButton"
                        theme='tertiary'
                        text={lang.permanentlyDelete}
                        onClick={onContinue}
                    />
                    <Button
                        id="CancelButton"
                        theme='primary'
                        text={lang.cancel}
                        onClick={() => onCancel(id)}
                    />
                </ButtonGroup>
            </Modal.Footer>
        </ReduxModal>
    )
}

export default DeleteBlockWarningModal