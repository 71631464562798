import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { ArrowLeft, ArrowRight } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'

const NavigationArrows = ({ activeLeft, activeRight, onClickNext, onClickPrev }) => {
    const arrowClasses = (isActive) =>
        cn({ __active: isActive }, 'slider-wrapper__footer__navigation-arrow')

    return (
        <div className='slider-wrapper__footer__navigation'>
            <ArrowLeft onClick={onClickPrev} className={arrowClasses(activeLeft)} />
            <ArrowRight onClick={onClickNext} className={arrowClasses(activeRight)} />
        </div>
    )
}

NavigationArrows.defaultProps = {
    activeLeft: false,
    activeRight: false,
}

NavigationArrows.propTypes = {
    activeLeft: PropTypes.bool,
    activeRight: PropTypes.bool,
    onClickNext: PropTypes.func.isRequired,
    onClickPrev: PropTypes.func.isRequired,
}

export default NavigationArrows
