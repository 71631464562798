import React from 'react'
import PropTypes from 'prop-types'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'

const ExecutionTools = ({
    onExecute,
    onCopy,
    onReset,
    lang,
    readOnly,
    executing
}) => {
    const noop = () => {}
    return (
        <>
            <div className='row' style={{ padding: '30px 0' }}>
                <div className='col-md-12'>
                    <ButtonGroup className='pull-left _margin_bottom_25'>
                        <Button
                            id='ResetButton'
                            visible={typeof onReset === 'function'}
                            theme='secondary'
                            text={lang.resetCode}
                            onClick={!readOnly ? onReset : noop}
                            disabled={readOnly}
                            tooltipId={readOnly ? 'TooltipTop' : ''}
                            tooltipContent={readOnly && lang.disableActionMessage}
                        />

                        <Button
                            id='CopyButton'
                            visible={typeof onCopy === 'function'}
                            text={lang.copyContent}
                            onClick={!readOnly ? onCopy : noop}
                            disabled={readOnly}
                            tooltipId='CopyContent'
                            theme='secondary'
                            tooltipContent={
                                readOnly
                                    ? lang.disableActionMessage
                                    : lang.copyContentFromTheCurrentTab
                            }
                        />
                    </ButtonGroup>
                    <div className='_btn_group pull-right _margin_bottom_25'>
                        <Button
                            id='ExecuteButton'
                            text={lang.excecuteResponse}
                            onClick={!readOnly ? onExecute : noop}
                            disabled={readOnly || executing}
                            tooltipId={readOnly ? 'TooltipTop' : ''}
                            loading={executing}
                            tooltipContent={readOnly && lang.disableActionMessage}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

ExecutionTools.defaultProps = {
    onCopy: () => {},
    onReset: () => {},
    readOnly: false,
}

ExecutionTools.propTypes = {
    onExecute: PropTypes.func.isRequired,
    onCopy: PropTypes.func,
    onReset: PropTypes.func,
    lang: PropTypes.shape({
        resetCode: PropTypes.string.isRequired,
        copyContentFromTheCurrentTab: PropTypes.string.isRequired,
        copyContent: PropTypes.string.isRequired,
        excecuteResponse: PropTypes.string.isRequired,
        disableActionMessage: PropTypes.string,
    }).isRequired,
    readOnly: PropTypes.bool,
    executing: PropTypes.bool.isRequired,
}

export default ExecutionTools
