import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { usePostMessage } from './hooks/usePostMessage'
import Validations from '@/components/Playground/Validations'
import Header from '@/components/Playground/Header'
import ToastAlert from '@/components/Playground/ToastAlert'
import { getBlockProgress } from '@/redux/selectors/students/progress'
import { useSelector } from 'react-redux'
import './ExerciseBlock.scss'

const IframeExerciseBlock = ({ exercise, id, blockType, ...props }) => {
    const progress = useSelector(getBlockProgress(props.block.id))
    const [message, hasErrors, showTopAlert, setShowTopAlert, iframeRef] = usePostMessage(props.block.id, progress)    
    const iframeUrl = `${exercise.urlReference}?id=${props.block.id}`
    const { lang } = useSelector(
        ({
            langStore: {
                playground,
                generalStudentWarnings,
                topicBlock: { exerciseBlock },
            }
        }) => ({
            lang: { ...playground, ...generalStudentWarnings, exerciseBlock },
        })
    )
    return (
        <div className='exercise-block-container'>
            <ToastAlert
                show={showTopAlert}
                hasError={hasErrors}
                errorsText={lang.processedCodeButErrorThereAreErrors}
                moral={exercise.moral}
                noMoralText={lang.thereIsNoMoral}
                seeErrorsText={lang.seeErrors}
                onClickClose={setShowTopAlert}
                showErrorButton={false}
            />
            <div className='blockContent'>
                <Header
                    hasProgress={!!progress}
                    completed={!!progress?.completed}
                    hints={exercise.hints}
                    hintsText={lang.hints.giveMeAHint}
                    exerciseName={exercise.name}
                    exerciseStatement={exercise.statement}
                    showName={props.block.show_name}
                />
                <Validations
                    hasError={hasErrors}
                    validations={[{msg: message}]}
                    errorsText={lang.errors}
                />
                <div>
                    <iframe
                        id='dhs-lab'
                        src={iframeUrl}
                        ref={iframeRef}
                        style={{
                            margin: 0,
                            padding: 0,
                            border: '1px solid #d9d9d9',
                            outline: 'none',
                            boxSizing: 'border-box',
                            width: '100%',
                            height: '560px',
                            display: 'block',
                            margin: 'auto',
                            textAlign: 'center',
                        }}
                    ></iframe>
                </div>
            </div>
        </div>
    )
}

IframeExerciseBlock.propTypes = {
    exercise: PropTypes.object,
    id: PropTypes.string,
    blockType: PropTypes.string
}

export default IframeExerciseBlock
