'use-strict'

import { LOGOUT_USER } from '../_deprecated/authReducer'
import enrolment from './subentities/enrolmentLoading'
import course from './subentities/courseLoading'
import block from './subentities/blockLoading'
import owner from './subentities/ownerLoading'

const initialState = {
    course,
    block,
    enrolment,
    courseTools: {
        loadCourseTools: false,
        createCourseTool: false,
        updateCourseTool: false,
        deleteCourseTool: false,
    },
    UnitLessonTopic: {
        isLoading: false
    },
    roadmap: {
        loadRoadmap: false
    },
    courseInfo: {
        loadCourseInfo: false
    },
    classroomProgress: null,
    dashboard: {
        dropdowns: false,
        general: false,
        generalRefresh: false,
        quizReport: false,
        downloadProgressReport: false,
    },
    owner,
    recoverPassword: {
        sendEmail: false,
    },
    changePayment: {
        getPaymentMethods: false,
        updatePaymentMethod: false,
    }
}

export const constants = {
    SET_LOADING: 'SET_LOADING'
}

export const actions = {
    [constants.SET_LOADING]: (target, boolean) => ({
        type: constants.SET_LOADING,
        target,
        boolean
    })
}

export const setLoading = {
    true: target => actions[constants.SET_LOADING](target, true),
    false: target => actions[constants.SET_LOADING](target, false)
}

const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_LOADING: {
            const [reducer, loadingType] = action.target.split('.')
            return {
                ...state,
                [reducer]: {
                    ...state[reducer],
                    [loadingType]: action.boolean
                }
            }
        }
        case LOGOUT_USER: {
            return initialState
        }
        default:
            return state
    }
}

export default loadingReducer