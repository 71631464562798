import React from 'react'
import { useSelector } from 'react-redux'
import { Info } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import * as env from '@/config/env'
import './UserManualLink.scss'

const UserManualLink = () => {
    const { userManual, userManualStrongText } = useSelector(
        ({ langStore }) => langStore.notebook.table
    )

    return (
        <div
            className='grades-user-manual-link'
            onClick={() => window.open(env.USER_MANUAL_URL, '_blank')}
        >
            <Info />
            <p className='grades-user-manual-link__text'>
                {userManual} <strong>{userManualStrongText}</strong>
            </p>
        </div>
    )
}

export default UserManualLink
