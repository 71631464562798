import React from "react"
import PropTypes from "prop-types"
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { history } from '@/app/store'
import { ArrowLeft } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import './BackButton.scss'

const BackButton = ({text}) => {

    const handleGoBack = ()=> {
        history.goBack()
    }

    return (
        <>
            <Button
                id='back_button'
                className='back_button'
                theme='clear'
                onClick={() => handleGoBack()}
                icon={<ArrowLeft />}
                text={text}
                leftIcon
            />
        </>
    )
}

BackButton.propTypes = {
    text: PropTypes.string.isRequired
}

export default BackButton
