import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '@digitalhouse-tech/react-lib-ui-explorer/lib/Modal'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { ONBOARDING_TYPE } from '@/constants/onboarding'
import { backgroundAsset } from '@/assets/img/config'
import './onboarding.scss'

const OnboardingWelcomeModal = ({ lang, update }) => {
    const [showModal, setShowModal] = useState(true)
    const confirm = () => {
        update(ONBOARDING_TYPE.NAV_POPOVER)
        setShowModal(false)
    }

    return (
        <Modal showModal={showModal} customModal id='onboardingModal'>
            <div className='onboarding-modal'>
                <img
                    className='onboarding-modal__background'
                    src={backgroundAsset('onboarding-modal.png')}
                    alt='modal background'
                />
                <h3 className='onboarding-modal__title'>{lang.onboardingModalTitle}</h3>
                <p className='onboarding-modal__text'>{lang.onboardingModalGreeting}</p>
                <p className='onboarding-modal__text'>{lang.onboardingModalSubtitle}</p>
                <p className='onboarding-modal__text'>{lang.onboardingModalMessage}</p>
                <Button
                    id='StartOnBoardingBtn'
                    className='onboarding-modal__button'
                    text={lang.startMessage}
                    theme='secondary'
                    onClick={() => confirm()}
                />
            </div>
        </Modal>
    )
}

OnboardingWelcomeModal.propTypes = {
    lang: PropTypes.any.isRequired,
    update: PropTypes.func.isRequired,
}

export default OnboardingWelcomeModal
