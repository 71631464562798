'use-strict'

export const initialState = {
    generalCourseInfo: {},
    gradesList: {
        assessmentsTypes: [],
        users: [],
        userProgress: [],
        lastPublishDate: null,
        assessmentsAllowToPublish: [],
        step: '',
    },
    gradesListModal: {
        show: false,
        error: null,
    },
    gradesListPublishModal: {
        show: false,
    },
    evaluableActivity: {
        studentsList: [],
        rejectTags: [],
        actualProgress: {
            type: '',
            blockInfo: {
                name: '',
                hasLimit: false,
                limitDays: 0,
                releaseDate: '',
            },
            lastStatus: {
                status: '',
                date: '',
            },
            lastSubmission: {
                statusTracking: [
                    {
                        status: '',
                        date: '',
                    },
                ],
                handIn: {
                    link: '',
                    message: '',
                    name: '',
                    surname: '',
                    email: '',
                },
                feedback: {
                    files: [],
                    rejectReasons: [],
                    reporterId: '',
                    feedback: '',
                    name: '',
                    surname: '',
                },
            },
        },
    },
}

export const SET_GENERAL_COURSE_INFO = '@@dashboard/SET_GENERAL_COURSE_INFO'
export const CLEAR_GENERAL_COURSE_INFO = '@@dashboard/CLEAR_GENERAL_COURSE_INFO'
export const SET_EVALUABLE_ACTIVITY_LIST = '@@dashboard/SET_EVALUABLE_ACTIVITY_LIST'
export const CLEAR_EVALUABLE_ACTIVITY_LIST = '@@dashboard/CLEAR_EVALUABLE_ACTIVITY_LIST'
export const SET_STUDENT_PROGRESS = '@@dashboard/SET_STUDENT_PROGRESS'
export const CLEAR_STUDENT_PROGRESS = '@@dashboard/CLEAR_STUDENT_PROGRESS'
export const UPDATE_LAST_STATUS = '@@dashboard/UPDATE_LAST_STATUS'
export const SET_REJECT_TAGS = '@@dashboard/SET_REJECT_TAGS'
export const CLEAR_REJECT_TAGS = '@@dashboard/CLEAR_REJECT_TAGS'
export const SET_GRADES_LIST = '@@dashboard/SET_GRADES_LIST'
export const CLEAR_GRADES_LIST = '@@dashboard/CLEAR_GRADES_LIST'
export const SET_GRADE_LIST_MODAL = '@@dashboard/SET_GRADE_LIST_MODAL'
export const SET_GRADE_LIST_PUBLISH_MODAL = '@@dashboard/SET_GRADE_LIST_PUBLISH_MODAL'
export const SET_GRADE_LIST_MODAL_ERROR = '@@dashboard/SET_GRADE_LIST_MODAL_ERROR'
export const SET_GRADE_STEP = '@@dashboard/SET_GRADE_STEP'

export const setGeneralCourseInfo = (courseDashboard) => ({
    type: SET_GENERAL_COURSE_INFO,
    payload: courseDashboard,
})

export const clearGeneralCourseInfo = () => ({
    type: CLEAR_GENERAL_COURSE_INFO,
})

export const setEvaluableActivityList = (list) => ({
    type: SET_EVALUABLE_ACTIVITY_LIST,
    payload: list,
})

export const setGradesList = (list) => ({
    type: SET_GRADES_LIST,
    payload: list,
})

export const clearGradesList = () => ({
    type: CLEAR_GRADES_LIST,
})

export const clearEvaluableActivityList = () => ({
    type: CLEAR_EVALUABLE_ACTIVITY_LIST,
})

export const setStudentProgress = (studentReview) => ({
    type: SET_STUDENT_PROGRESS,
    payload: studentReview,
})

export const clearStudentProgress = () => ({
    type: CLEAR_STUDENT_PROGRESS,
})

export const updateLastStatus = (updatedStatus) => ({
    type: UPDATE_LAST_STATUS,
    payload: updatedStatus,
})

export const setRejectTags = (tags) => ({
    type: SET_REJECT_TAGS,
    payload: tags,
})

export const clearRejectTags = () => ({
    type: CLEAR_REJECT_TAGS,
})

export const setGradesListModal = (value) => ({
    type: SET_GRADE_LIST_MODAL,
    payload: value,
})

export const setGradesListPublishModal = (value) => ({
    type: SET_GRADE_LIST_PUBLISH_MODAL,
    payload: value,
})


export const setGradesListModalError = (error) => ({
    type: SET_GRADE_LIST_MODAL_ERROR,
    payload: error,
})

export const setGradeStep = (step) => ({
    type: SET_GRADE_STEP,
    payload: step,
})

const dashboardReducer = (state = initialState, { type, payload }) => {
    const reducers = {
        [SET_GRADES_LIST]: () => ({
            ...state,
            gradesList: payload,
        }),
        [CLEAR_GRADES_LIST]: () => ({
            ...state,
            gradesList: initialState.gradesList,
            gradesListModal: initialState.gradesListModal,
            gradesListPublishModal: initialState.gradesListPublishModal
        }),
        [SET_GENERAL_COURSE_INFO]: () => ({
            ...state,
            generalCourseInfo: payload,
        }),
        [CLEAR_GENERAL_COURSE_INFO]: () => ({
            ...state,
            generalCourseInfo: {},
        }),
        [SET_EVALUABLE_ACTIVITY_LIST]: () => ({
            ...state,
            evaluableActivity: {
                ...state.evaluableActivity,
                studentsList: payload,
            },
        }),
        [CLEAR_EVALUABLE_ACTIVITY_LIST]: () => ({
            ...state,
            evaluableActivity: {
                ...state.evaluableActivity,
                studentsList: [],
            },
        }),
        [SET_STUDENT_PROGRESS]: () => ({
            ...state,
            evaluableActivity: {
                ...state.evaluableActivity,
                actualProgress: payload,
            },
        }),
        [CLEAR_STUDENT_PROGRESS]: () => ({
            ...state,
            evaluableActivity: {
                ...state.evaluableActivity,
                actualProgress: { ...initialState.evaluableActivity.actualProgress },
            },
        }),
        [UPDATE_LAST_STATUS]: () => ({
            ...state,
            evaluableActivity: {
                ...state.evaluableActivity,
                actualProgress: {
                    ...state.evaluableActivity.actualProgress,
                    lastStatus: payload.lastStatus,
                    lastSubmission: {
                        ...state.evaluableActivity.actualProgress.lastSubmission,
                        statusTracking: payload.statusTracking,
                        feedback: payload.feedback,
                    },
                },
            },
        }),
        [SET_REJECT_TAGS]: () => ({
            ...state,
            evaluableActivity: {
                ...state.evaluableActivity,
                rejectTags: payload,
            },
        }),
        [SET_GRADE_LIST_MODAL]: () => ({
            ...state,
            gradesListModal: {
                ...state.gradesListModal,
                show: payload,
            },
        }),
        [SET_GRADE_LIST_PUBLISH_MODAL]: () => ({
            ...state,
            gradesListPublishModal: {
                show: payload,
            },
        }),
        [SET_GRADE_LIST_MODAL_ERROR]: () => ({
            ...state,
            gradesListModal: {
                ...state.gradesListModal,
                error: payload,
            },
        }),
        [SET_GRADE_STEP]: () => ({
            ...state,
            gradesList: {
                ...state.gradesList,
                step: payload,
            },
        }),
    }
    return reducers[type] ? reducers[type]() : state
}

export default dashboardReducer
