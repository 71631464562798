import React from 'react'
import { Javascript, Html, Css, Java } from './svg'

export default name => {
    const splited = name.split('.')
    const ext = splited[splited.length - 1]
    const icons = {
        js: <Javascript />,
        html: <Html />,
        css: <Css />,
        java: <Java />
    }
    return icons[ext]
}
