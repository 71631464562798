import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import ReduxModal from '@/components/ReduxModal'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import './styles/DeleteBlockConfirmationModal.scss'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'

const DeleteBlockConfirmationModal = ({
    onCancel,
    name,
    id,
    onConfirm,
    loadingRemoveBlock,
    lang
}) => {

    const [inputBlockName, setInputBlockName] = useState('')

    useEffect(() => {
        setInputBlockName('')
    }, [name])

    return (
        <ReduxModal
            id={id}
            className="__modal __modal_confirmation delete-block-confirmation-modal"
            keyboard={false}
            backdrop="static">
            <Modal.Header closeButton className="__modal_header __border_bottom">
                <h3 className="__modal_title danger">{lang.header}</h3>
            </Modal.Header>

            <Modal.Body className="__modal_body">
                {lang.body}
                <h3 className="block-name">{name}</h3>
                <InputText
                    id="name"
                    value={inputBlockName}
                    onChange={e => setInputBlockName(e.target.value)}
                />
            </Modal.Body>

            <Modal.Footer className="__modal_footer __border_top">
                <ButtonGroup className="pull-right">
                    <Button
                        id="ConfirmButton"
                        theme='secondary'
                        text='Confirmar'
                        onClick={onConfirm}
                        disabled={inputBlockName !== name}
                        loading={loadingRemoveBlock}
                    />
                    <Button
                        id="CancelButton"
                        theme='primary'
                        text="Cancelar"
                        onClick={() => onCancel(id)}
                    />
                </ButtonGroup>
            </Modal.Footer>
        </ReduxModal>
    )
}

export default DeleteBlockConfirmationModal