import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import TopicNav from './Navigators/TopicNav'
import LoadingOrContent from '@/components/LoadingOrContent'

import TopicContentMobileLayout from './TopicContentMobileLayout'
import Block from './Block'
import Skeleton from './Skeleton'

import useTopicContent from './useTopicContent'
import './TopicContent.scss'

const TopicContent = memo(
    ({ content, courseId, topicId, isMobile, loading, showLastBlock, isClassroomLoaded }) => {
        const { connectionFailure } = useSelector(({ langStore: { topic } }) => topic)
        const {
            Blocks,
            currentBlock,
            setCurrentBlock,
            showBottomNav,
            sliderRef,
            stepBlocks,
            hasActiveQuiz,
            isCourseExpired,
        } = useTopicContent({
            content,
            courseId,
            topicId,
            isMobile,
            showLastBlock,
            isClassroomLoaded,
        })

        /**
         * Prepare Blocks array.
         * @returns {Array<Block>}
         */
        const BlocksComponents = useMemo(
            () =>
                Blocks.map((block) => (
                    <Block
                        block={block}
                        key={block.id}
                        isMobile={isMobile}
                        isCourseExpired={isCourseExpired}
                        lang={connectionFailure}
                    />
                )),
            [Blocks, isMobile, isCourseExpired]
        )

        return (
            <>
                <div className='topic-container'>
                    <TopicNav
                        isMobile={isMobile}
                        loading={loading}
                        hasActiveQuiz={hasActiveQuiz}
                        showTopicName
                    />

                    <LoadingOrContent
                        loading={loading}
                        loadingComponent={
                            <Skeleton isMobile={isMobile} className='topic-container__skeleton' />
                        }
                    >
                        {isMobile ? (
                            <TopicContentMobileLayout
                                blocksCount={Blocks.length}
                                blocksComponents={BlocksComponents}
                                currentBlock={currentBlock}
                                setCurrentBlock={setCurrentBlock}
                                showBottomNav={showBottomNav}
                                sliderRef={sliderRef}
                                stepBlocks={stepBlocks}
                                hasActiveQuiz={hasActiveQuiz}
                            />
                        ) : (
                            <div className='topic-container__content-container'>
                                {BlocksComponents}
                            </div>
                        )}
                    </LoadingOrContent>

                    {!isMobile && <TopicNav loading={loading} />}
                </div>
            </>
        )
    }
)

TopicContent.propTypes = {
    content: PropTypes.object,
    courseId: PropTypes.string.isRequired,
    topicId: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    isMobile: PropTypes.bool,
    showLastBlock: PropTypes.bool,
    isClassroomLoaded: PropTypes.bool,
}

TopicContent.defaultProps = {
    content: null,
    loading: true,
    isMobile: false,
    showLastBlock: false,
    isClassroomLoaded: false,
}

export default TopicContent
