import React from 'react'
import PropTypes from 'prop-types'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import './HeaderTitle.scss'

const HeaderFeedback = ({ 
    hasFeedback,
    feedback,
    approvedMessage,
    redoMessage,
    isSurvey,
    affectsProgress,
    failedMessage,
    rightAnswer,
    approvedPercentage,
    availableReDoQuiz,
    lang
}) => {

    return (
        <div className='header-feedback'>
            {!isSurvey && <MessageBox
                title={
                    rightAnswer
                        ? approvedMessage
                        : availableReDoQuiz
                            ? redoMessage
                            : failedMessage
                }
                text={lang.correctAnswers + approvedPercentage + '%'}
                theme={rightAnswer ? 'success' : availableReDoQuiz ? 'warning' : 'danger'}
                fullWidth={true}
            />
            }
            <div className='header-feedback__title'>{lang.showAnswers}</div>
        </div>
    )
}

HeaderFeedback.propTypes = {
    hasFeedback: PropTypes.bool,
    feedback: PropTypes.string,
    approvedMessage: PropTypes.string,
    redoMessage: PropTypes.string,
    affectsProgress: PropTypes.bool,
    failedMessage: PropTypes.string,
    rightAnswer: PropTypes.bool,
    approvedPercentage: PropTypes.number,
    availableReDoQuiz: PropTypes.bool,
    isSurvey: PropTypes.bool,
    lang: PropTypes.object
}

HeaderFeedback.defaultProps = {
    hasFeedback: false,
    feedback: '',
}

export default HeaderFeedback
