import React from 'react'
import { connect } from 'react-redux'
import RadioBtn from './RadioBtn'
import './RadioGrp.scss'
import { saveSurveyAnswer } from '@/redux/reducers/_deprecated/surveyReducer'

export class RadioGrp extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedIndex: null,
            selectedValue: null,
            options: this.props.options
        }

    }

    toggle(option) {

        this.setState({
            ...this.state,
            selectedValue: option
        })

        //Problemas de asincronismo, estamos mandando el valor anterior al que realment eestamos eligiendo. Pasar a Redux:
        //en answer pasamos option directamente y no el state de react -> comentario de ale
        let data = {
            survey_question_id: this.props.surveyQuestionId,
            answer: option,
            course_survey_user_id: this.props.surveyData.course_survey_user_id,
            survey_id: this.props.surveyData.survey_id
        }

        let surveyAnswers = this.props.surveyAnswers

        this.props.saveSurveyAnswer(data, surveyAnswers)
    }

    render() {
        const { options } = this.state

        const allOptions = options.map((option, i) => {
            return (
                <RadioBtn
                    key={"radiogroup" + i}
                    surveyQuestionId={this.props.surveyQuestionId}
                    selectedValue={this.state.selectedValue}
                    item={i}
                    toggle={this.toggle.bind(this, (i))} />
            )
        })

        return (
            <div className="__radioButtonBox">
                <ul className="__radioButtonsGroup">{allOptions}</ul>
                <div className="_radioButton_infoBox">
                    <p className="pull-left">{this.props.options[0].option}</p>
                    <p className="pull-right">{this.props.options[this.props.options.length - 1].option}</p>
                </div>
            </div>
        )
    }
}


const mapDispatch = (dispatch) => ({
    saveSurveyAnswer: (data, surveyAnswers) => {
        dispatch(saveSurveyAnswer(data, surveyAnswers))
    },
})

const mapStateToProps = (state, ownProps) => {
    return {
        surveyAnswers: state.surveyReducer.surveyAnswers,
        surveyByUser: state.surveyReducer.surveyByUser
    }
}

export default connect(mapStateToProps, mapDispatch, null, { forwardRef: true })(RadioGrp)
