import React, { useCallback, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'

import "react-datepicker/dist/react-datepicker.css"

import BlockModal from './BlockModal'

import { BLOCK_TYPES } from '@/constants/blocks'
import { getSelectOptions } from '@/helpers/arrays'
import { hasAnyProgressSelector, createModalDataSelector } from '@/redux/selectors'
import { BAD_URL } from './constants/errorTypes'
import ModalPanel from '@/components/ModalPanel'
import ToggleButton from '@digitalhouse-tech/react-lib-ui-explorer/lib/ToggleButton'
import { Info } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import InputNumber from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputNumber'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

const URL_REGEX = /^(http(s?):\/\/(docs|drive)\.google\.com\/)/

const DEFAULT_SLIDE_BLOCK = {
    SlideBlock: {
        url: '',
        width: '100',
        height: '100',
        allow_download: true,
        extra: false,
    }
}

const slideBlockSelector = createModalDataSelector(DEFAULT_SLIDE_BLOCK)

const langSelector = ({ langStore: { modalSlideBlock, modalSlideForm, modalExerciseBlock } }) => ({
    ...modalSlideBlock,
    ...modalSlideForm,
    ...modalExerciseBlock,
    blockNameLabel: modalSlideForm.blockName,
    isVisibleLabel: modalSlideForm.isVisible,
    showBlockNameLabel: modalSlideForm.showBlockName,
})

export const ModalSlideBlock = () => {
    const data = useSelector(slideBlockSelector)
    const lang = useSelector(langSelector)
    const hasAnyProgress = useSelector(hasAnyProgressSelector)

    const [url, setUrl] = useState(data.SlideBlock.url)
    const [width, setWidth] = useState(data.SlideBlock.width)
    const [height, setHeight] = useState(data.SlideBlock.height)
    const [extra, setExtra] = useState(data.extra)
    const [allowDownload, setAllowDownload] = useState(data.SlideBlock.allow_download)

    const isUrlValid = useMemo(() => url && URL_REGEX.test(url), [url])
    const [urlError, setUrlError] = useState(null)
    const slideUrl = useMemo(() => isUrlValid ? url : '', [isUrlValid, url])
    const values = useMemo(
        () => ({
            url,
            width,
            height,
            allow_download: allowDownload,
        }),
        [url,width,height,allowDownload],
    )

    const urlFormatIframe = urlValue => {
        const docUrl = urlValue.replace('/pub?','/embed?')
        setUrl(docUrl)
    }

    const getHeight = useCallback(() => isUrlValid ? (height * 538) / 100 : 0, [
        height,
        isUrlValid,
    ])

    const validateInputValues = useCallback(() => {
        const errorMessage = lang.requiredUrlMessage
        const error = isUrlValid ? null : errorMessage
        setUrlError(error)
        return error ? [BAD_URL] : []
    }, [lang, isUrlValid])

    const blockTitle = data.id
        ? lang.editSlideTitle
        : lang.createSlideTitle;
    const selectOptions = getSelectOptions(lang.yes, lang.no)
    const getValue = (option) => (option ? selectOptions[0] : selectOptions[1])
    const id = BLOCK_TYPES.SLIDE_BLOCK.modal

    return (
        <BlockModal
            blockId={data.id}
            visibility={data.visibility}
            show_name={data.show_name}
            name={data.name}
            values={values}
            validateInputValues={validateInputValues}
            lang={lang}
            blockType={BLOCK_TYPES.SLIDE_BLOCK}
            blockTitle={blockTitle}
            id={id}
            extra={extra}
        >
            <Row>
                <Col lg="12" md="12" style={{ marginBottom: '20px' }}>
                    <Label text={lang.urlPresentation} />
                    <InputText
                        id='blockURL'
                        value={url}
                        error={urlError}
                        placeholder="e.g. https://docs.google.com/..."
                        onChange={e => urlFormatIframe(e.target.value)}
                    />
                </Col>
                <Col lg="4" md="4" className='modal-panel__number-input'>
                    <Label text={lang.width} />
                    <InputNumber
                        id='blockWidth'
                        value={width}
                        min={0}
                        max={100}
                        placeholder="100%"
                        onChange={number => setWidth(number)}
                    />
                </Col>
                <Col lg="4" md="4" className='modal-panel__number-input'>
                    <Label text={lang.height} />
                    <InputNumber
                        id='blockHeight'
                        value={height}
                        min={0}
                        max={100}
                        placeholder="100%"
                        onChange={number => setHeight(number)}
                    />
                </Col>

                <Col lg="4" md="4">
                    <ModalPanel theme='no-theme'>
                        <div className='modal-panel__toggle-input'>
                            <ToggleButton
                                onToggle={() => setAllowDownload(!allowDownload)}
                                toggled={allowDownload}
                                id='DownloadableSlideBlock'
                                inputName='downloadable'
                                key='downloadable'
                                disabled={url.includes('embed') || url.includes('2PACX-')}
                            />
                            <Label text={lang.downloadable} indent />
                            <Tooltip tooltipContent={lang.warningText} tooltipId='DownloadableBlockTooltip'>
                                <Info className='tooltip-icon' size={20} />
                            </Tooltip>
                        </div>
                    </ModalPanel>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="12">
                    <Label text={lang.isExtraLabel} />
                    <InputComboBox
                        disabled={hasAnyProgress}
                        inputName='extra'
                        placeholder={lang.select}
                        id='extra'
                        dropdownItems={selectOptions.map(({ label }) => label)}
                        onChange={label => setExtra(label === selectOptions[0].label)}
                        value={getValue(extra).label}
                    />
                </Col>
            </Row>
            <Row style={{marginTop: '20px'}}>
                <Col lg="12" md="12">
                    <iframe
                        src={slideUrl}
                        width={width + '%'}
                        height={getHeight()+'vh'}
                        frameBorder="0"
                        title={BLOCK_TYPES.SLIDE_BLOCK.modal}
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                    />
                </Col>
            </Row>
        </BlockModal>
    )
}

export default ModalSlideBlock