'use-strict'

import React from 'react'

export default ({
    title = ''
}) => {
    return (
        <>
            <div className="__main_header">
                <div className="row">
                    <div className="col-md-6">
                        <h1 className="__main_title">{title}</h1>
                    </div>
                </div>
            </div>
        </>
    )
}