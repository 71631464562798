import { getCourseInfo } from '../http/services/courseService'
import { history } from '@/app/store'
import httpHandler, {codes} from '@/helpers/error-handling/httpHandler'
import { getCourseInfoAction } from '../reducers/courseInfo/index'
import showNewMessage from '../reducers/_deprecated/globalAlertsReducer/alertsControllers'
import { setLoading } from '../reducers/loadingReducer'

export const loadCourseInfo = (courseId) => async (dispatch, getState) => {
    const userId = getState().authReducer.user.id
    const lang = getState().langStore.courseInfo.backendMessages
    
    dispatch(setLoading.true('courseInfo.loadCourseInfo'))
    const [error, response] = await getCourseInfo(userId, courseId)   
    if(error) {
        dispatch(setLoading.false('courseInfo.loadCourseInfo'))
        return httpHandler(error)({
            [codes.NOT_FOUND]: () => history.push('/not-found'),
            [codes.INTERNAL_SERVER_ERROR]: (data) => {
                showNewMessage({
                    type: 'danger',
                    message: lang.internalServerError,
                    isTemporal: true
                })
            } 
        })
    }
    dispatch(setLoading.false('courseInfo.loadCourseInfo'))
    dispatch(getCourseInfoAction(response))    
}