import { useState, useEffect } from 'react'
import controller from '@/redux/reducers/_deprecated/exerciseManagerReducer/controller'
import { history } from '@/app/store'

export const useExerciseSearcher = () => {
    const [searchName, setSearchName] = useState('')
    const [searchTags, setSearchTags] = useState([])
    const [searchType, setSearchType] = useState('')
    const [showCloneModal, setShowCloneModal] = useState(false)
    const [currentCloneId, setCurrentCloneId] = useState(null)
    const [currentCloneName, setCurrentCloneName] = useState(null)

    const goToPage = (page) => {
        controller.setCurrentPage(page)
        controller.loadPaginatedExercises()
    }

    useEffect(() => {
        controller.loadAllTags()
        controller.loadAllExerciseTypes()
        controller.clearFinderFields()
        goToPage(1)
    }, [])

    const onClickSearchButton = () => {
        goToPage(1)
    }

    const onChangeName = (value) => {
        setSearchName(value)
        controller.setFinderField({ key: '!name', value })
    }

    const onChangeType = (value) => {
        setSearchType(value)
        controller.setFinderField({ key: 'exerciseType', value })
    }

    const onChangeTags = (value) => {
        setSearchTags(value)
        controller.setFinderField({ key: 'tags', value })
    }

    const onCopyExercise = (id, name) => {
        setCurrentCloneId(id)
        setCurrentCloneName(name)
        setShowCloneModal(true)
    }

    const onEditExercise = (id, type) => {
        history.push(`/admin/exercises/${id}/edit?exerciseType=${type}`)
    }

    const handleCloseModal = () => {
        setShowCloneModal(false)
    }

    const handleCloneExercise = async (exerciseId, exerciseName) => {
        await controller.cloneExercise(exerciseId, exerciseName)
        handleCloseModal()
    }

    const checkUsedName = (name) => {
        return controller.checkUsedName(name)
    }

    return {
        handleCloneExercise,
        handleCloseModal,
        onEditExercise,
        onCopyExercise,
        onChangeTags,
        onChangeType,
        onChangeName,
        onClickSearchButton,
        searchName,
        searchTags,
        searchType,
        showCloneModal,
        currentCloneId,
        currentCloneName,
        goToPage,
        checkUsedName,
    }
}
