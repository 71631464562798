import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import "react-datepicker/dist/react-datepicker.css"

import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import { getSelectOptions } from '@/helpers/arrays'
import exerciseManagerController from '@/redux/reducers/_deprecated/exerciseManagerReducer/controller'
import BlockModal from './BlockModal'
import { BLOCK_TYPES } from '@/constants/blocks'
import api from '@/config/endpoints'
import {
    hasAnyProgressSelector,
    allExercisesSelector,
    createModalDataSelector,
} from '../../../../../../../../redux/selectors'
import { EMPTY_FIELD } from './constants/errorTypes'
import { courseIdSelector } from '@/redux/selectors/course'
import { getContentConfig } from '@/helpers/utils/index'

let timeout;

const DEFAULT_EXERCISE_BLOCK = {
    exercise_block_id: 0,
    extra: false,
}

const exerciseBlockSelector = createModalDataSelector(DEFAULT_EXERCISE_BLOCK)
const exerciseLangSelector = ({ langStore }) => langStore.modalExerciseBlock

const ModalExerciseBlock = () => {
    const heritageExerciseRef = useRef();
    const data = useSelector(exerciseBlockSelector)
    const lang = useSelector(exerciseLangSelector)
    const exerciseBlocks = useSelector(allExercisesSelector)
    const hasAnyProgress = useSelector(hasAnyProgressSelector)
    const courseId = useSelector(courseIdSelector);

    const [heritageExercisesUpdatedAt, setHeritageExercisesUpdatedAt] = useState((new Date()).getTime())
    const [selectedExerciseBlockId, setSelectedExerciseBlockId] = useState(null)
    const [extra, setExtra] = useState(data.extra)
    const [exerciseError, setExerciseError] = useState(null)
    const [selectedExercise, setSelectedExercise] = useState(null)
    const [searchValue, setSearchValue] = useState('')

    const [heritageExercises, setHeritageExercises] = useState([])
    const [selectedHeritageExercise, setSelectedHeritageExercise] = useState(null)
    const [selectedHeritageExerciseId, setSelectedHeritageExerciseId] = useState(data.previous_step_block_id)
    const defaulValue = useMemo(() =>
        heritageExercises?.find((ex) => data?.previous_step_block_id === ex.id),
    [data, heritageExercises])
    

    const heritageExerciseOptions = useMemo(
        () =>
            heritageExercises?.map((heritageExercise) => ({
                value: heritageExercise.id,
                label: heritageExercise.blockable.name,
            })),
        [heritageExercises]
    )
    
    const exerciseBlockOptions = useMemo(
        () =>
            exerciseBlocks.map((exerciseBlock) => ({
                value: exerciseBlock.id,
                label: exerciseBlock.name,
            })),
        [exerciseBlocks]
    )

    const searchableExcerciseBlockOptions = exerciseBlockOptions.filter(({ label }) =>
        label?.trim().toLowerCase().includes(searchValue?.trim().toLowerCase())
    )

    const values = useMemo(() => selectedExerciseBlockId, [selectedExerciseBlockId])

    const onChangeExcercise = (value, index) => {
        setSearchValue(value)
        if (index === -1) {
            value === '' ? setSelectedExercise(value) : setSelectedExercise(null)
            setSelectedExerciseBlockId(null)
            return
        }
        const selected = searchableExcerciseBlockOptions.find(
            (ex, i) => value === ex.label && index === i
        )
        setSelectedExerciseBlockId(selected.value)
        setSelectedExercise(selected.label)
    }

    const validateInputValues = useCallback(() => {
        const error = !selectedExerciseBlockId
        setExerciseError(error ? lang.requiredExerciseMessage : null)

        return error ? [EMPTY_FIELD] : []
    }, [lang, setExerciseError, selectedExerciseBlockId])

    const getHeritageExercises = useCallback(async ({exerciseName, exerciseId}) => {
        if (!courseId) return;
        const searcher = exerciseName ?? selectedHeritageExercise ?? '';

        const params = [
            `course_id=${courseId}`,
            `block_type=exercise`,
            `&limit=10`
        ]

        if (exerciseId) params.push(`id=${exerciseId}`)
        if (searcher) params.push(`exercise_name=${searcher}`)

        const endpoint = `${api.content}/blocks?${params.join('&')}`;
        await axios.get(endpoint, getContentConfig()).then(response => {
            setHeritageExercises(response.data.data);
            
        });
    }, [selectedHeritageExercise, courseId, timeout])

    const onChangeHeritage = (event) => {
        const exerciseName = event;
        setSelectedHeritageExercise(exerciseName);

        setHeritageId(exerciseName);

        if (timeout) clearTimeout(timeout); 
        timeout = setTimeout(() => {
            getHeritageExercises({name: exerciseName});
        }, [500])
    };

    const setHeritageId = (exerciseName) => {
        const selected = heritageExercises.find((e) => e.blockable.name === exerciseName);
        setSelectedHeritageExerciseId(selected?.id)
    }

    useEffect(() => {
        if (data.exercise_block_id) {
            setSelectedExerciseBlockId(data.exercise_block_id)
            const selectedExerciseLabel = exerciseBlockOptions.find(block => block.value === data.exercise_block_id)?.label
            setSelectedExercise(selectedExerciseLabel)
            setSearchValue(selectedExerciseLabel)
        }
    }, [exerciseBlockOptions])
    useEffect(() => {
        exerciseManagerController.loadAllExercises({
            attributes: ['name'],
        })
    }, [])
    useEffect(() => {
        if (!courseId) return;
        if (!data.previous_step_block_id) return;

        getHeritageExercises({exerciseId: data.previous_step_block_id}); 
    }, [data]);
    useEffect(() => {
        if (!heritageExerciseRef.current && heritageExerciseOptions.length) {
            heritageExerciseRef.current = true;
            return;
        }
        setHeritageExercisesUpdatedAt((new Date()).getTime());
        setTimeout(() => {
            if (heritageExerciseRef.current && !document.getElementsByClassName('dropdown-container')?.[0]) {
                document.getElementById('previous_step_block_id').click();
                document.getElementById('previous_step_block_id').focus();
            }
        });
    }, [heritageExerciseOptions]);

    const blockTitle = !!data.id
        ? lang.modalExerciseTitleEdit
        : lang.modalExerciseTitleCreate
    const selectOptions = getSelectOptions(lang.yes, lang.no)
    const getValue = (option) => (option ? selectOptions[0] : selectOptions[1])
    const id = BLOCK_TYPES.EXERCISE_BLOCK.modal

    return (
        <BlockModal
            validateInputValues={validateInputValues}
            blockId={data.id}
            visibility={data.visibility}
            previousStep={selectedHeritageExerciseId}
            show_name={data.show_name}
            name={data.name}
            lang={lang}
            blockTitle={blockTitle}
            blockType={BLOCK_TYPES.EXERCISE_BLOCK}
            id={id}
            extra={extra}
            values={values}
        >
            <Row className='_margin_bottom_25'>
                <Col md="6">
                    <Label text={lang.exercises} />
                    <InputComboBox
                        inputName='Ejercicios'
                        id='exercises'
                        dropdownItems={searchableExcerciseBlockOptions.map(({ label }) => label)}
                        onChange={onChangeExcercise}
                        value={selectedExercise}
                        searchable
                        noResultsText={lang.noResults}
                        error={exerciseError}
                    />
                </Col>
                <Col md="6">
                    <Label text={lang.heritageExercise} />
                    <InputComboBox
                        key={`heritageExercise-${heritageExercisesUpdatedAt}`}
                        inputName="previous_step_block_id"
                        id="previous_step_block_id"
                        dropdownItems={heritageExerciseOptions?.map(({ label }) => label)}
                        onChange={onChangeHeritage}
                        value={selectedHeritageExercise ?? defaulValue?.blockable.name}
                        searchable
                        noResultsText={lang.noResults}
                        error={exerciseError}
                        aria-hidden={false}
                    />
                </Col>
                <Col md="6">
                    <Label text={lang.isExtraLabel} />
                    <InputComboBox
                        disabled={hasAnyProgress}
                        inputName='extra'
                        placeholder={lang.select}
                        id='extra'
                        dropdownItems={selectOptions.map(({ label }) => label)}
                        onChange={label => setExtra(label === selectOptions[0].label)}
                        value={getValue(extra).label}
                    />
                </Col>
            </Row>
        </BlockModal>
    )
}

export default ModalExerciseBlock
