import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { history } from '@/app/store'
import { Edit2 } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import DropdownRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/DropdownRound'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'
import { showModalPasswordAction } from '@/redux/reducers/_deprecated/modalsReducer'
import {
    CHANGE_PASSWORD_USER,
    VIEW_USER,
    EDIT_USER,
} from '@digitalhouse-tech/ts-lib-store/lib/session-storage/permission'
import { tenantHasNativeAuthStrategy } from '@/helpers/tenants/helpers'

export class UserCard extends React.Component {

    constructor() {
        super()
        this.state = {
            redirect: []
        }

        this.openPasswordModal = this.openPasswordModal.bind(this)
    }

    handleEdit() {
        let userId = this.props.id
        history.push('/admin/users/' + userId + '/edit')
    }

    openPasswordModal() {
        this.props.showModalPassword(this.props.id)
    }

    goToPreworkReport(userId) {
        this.props.onClickUser(userId)
    }

    goToUser(userId) {
        let userLink = "/admin/users/" + userId + "/detail"
        history.push(userLink)
    }

    showButtons() {
        if (this.props.prework) {
            return (
                <ButtonGroup>
                    <Button
                        id="goToPreworkReportButton"
                        onClick={this.goToPreworkReport.bind(this, this.props.id)}
                        text={this.props.lang.seeMoreButton}
                    />
                </ButtonGroup>
            )
        }
        return (
            <ButtonGroup>
                {VIEW_USER() &&
                    <Button
                        id="goToUserButton"
                        onClick={this.goToUser.bind(this, this.props.id)}
                        text={this.props.lang.seeMoreButton}
                        theme='tertiary'
                    />
                }
                <div className="_btn_group">
                    {EDIT_USER() &&
                        <ButtonRound
                            id="handleEditButton"
                            theme='primary_dark'
                            onClick={this.handleEdit.bind(this)}
                            icon={<Edit2 />}
                        />
                    }
                    {CHANGE_PASSWORD_USER() && tenantHasNativeAuthStrategy() && (
                        <DropdownRound
                            id={'more_options_' + this.props.id}
                            items={[
                                {
                                    name: this.props.lang.changePassword,
                                    onClick: this.openPasswordModal,
                                    testId: `more_options_${this.props.id}_0`,
                                },
                            ]}
                        />
                    )}
                </div>
            </ButtonGroup>
        )

    }
    showLastConection() {
        if (this.props.last_login) {
            return <h5 className="__last_login display_inline">
                {this.props.lang.lastConection}: {moment(this.props.last_login).utc().format("DD/MM/YYYY")}
            </h5>
        }
        return <h5 className="__last_login display_inline">{this.props.lang.noConection}</h5>
    }
    render() {
        return (
            <Panel className='__card_flex_container'>
                <div>
                    <h3 className="__card_secondary_title">{this.props.name} {this.props.surname}</h3>
                    <h5 className="__card_more_information">{this.props.email}</h5>
                    {this.props.prework ? this.showLastConection() : null}
                </div>
                {this.showButtons()}
            </Panel>
        )
    }
}

const mapDispatch = (dispatch) => ({
    showModalPassword: (userId) => {
        dispatch(showModalPasswordAction(userId))
    }
})


const mapStateToProps = (state) => {
    return {
        allUsers: state.userReducer.allUsers,
        lang: state.langStore.user,

    }
}

export default withRouter(connect(mapStateToProps, mapDispatch)(UserCard))