import React from 'react';
import GoBackBar from '../GoBackBar'
import './ConfirmMessageMobile.scss'

const LogOutComponent = ({
    onGoBack,
    onFisrt,
    onSecond,
    onThird,
    customIcon,
    lang: {
        goBack: goBackLang,
        title: titleLang,
        message: messageLang,
        firstOption: firstOptionLang,
        secondOption: secondOptionLang,
        thirdOption: thirdOptionLang
    },
}) => {

    const getFisrtButton = () => onFisrt && firstOptionLang ? <div className="greenButton" onClick={onFisrt}>{firstOptionLang}</div> : null
    const getSecondButton = () => onSecond && secondOptionLang ? <div className="normalButton" onClick={onSecond}>{secondOptionLang}</div> : null
    const getThirdButton = () => onThird && thirdOptionLang ? <div className="normalButton" onClick={onThird}>{thirdOptionLang}</div> : null

    const getButtons = () => {
        return (
            <div className="verticalButtonGroup">
                {getFisrtButton()}
                {getSecondButton()}
                {getThirdButton()}
            </div>
        )
    }

    const getTitle = () => titleLang ? <div className="title">{titleLang}</div> : null
    const getMessage = () => messageLang ? <div className="message">{messageLang}</div> : null

    const getContent = () => {
        return (
            <div className="content">
                <div className="icon">
                    {customIcon}
                </div>
    
                {getTitle()}
                {getMessage()}
            </div>
        )
    }

    return (
        <div>
            <div className="confirmMessageMobile">
                <div className="goBackBarWrapper">
                    <GoBackBar text={goBackLang} onGoBack={onGoBack} />
                </div>
                <div className="contentWrapper">
                    {getContent()}
                    {getButtons()}
                </div>
            </div>
        </div>
    )
}

export default LogOutComponent