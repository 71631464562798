import React, { Component } from 'react'
import Wysiwyg from '@/components/Wysiwyg'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'

export default class SendEmailPresentational extends Component {

  constructor(props, context) {
    super(props, context)

    this.state = {
      subject: { error: false, message: '', value: '' },
      emailmessage: { error: false, message: '', value: '' },
      receiver: { error: '', message: '', selectedReceiver: [] },
      receiverOptions: [
        { value: 1, label: props.lang.toMyself },
        { value: 2, label: props.lang.teachersAndStudents },
        { value: 3, label: props.lang.allStudents }
      ],
    }

    this.submitSendEmail = this.submitSendEmail.bind(this)
    this.handleReceiver = this.handleReceiver.bind(this)
    this.handleChangeValue = this.handleChangeValue.bind(this)
    this.handleSubject = this.handleSubject.bind(this)
  }

  handleReceiver(selectedReceiver) {
    this.setState({
      ...this.state,
      receiver: {
        ...this.state.receiver,
        selectedReceiver
      }
    })
  }

  handleSubject(value) {
    this.setState({
      ...this.state,
      subject: {
        ...this.state.subject,
        value,
      }
    })
  }

  validateInfo(subject, receiver, emailmessage) {
    let formOk = true

    let subjectValue = { error: false, message: '', value: subject }
    let emailmessageValue = { error: false, message: '', value: emailmessage }
    let receiverValue = { error: false, message: '', selectedReceiver: receiver }
    if (subject == null || subject == '') {
        subjectValue.error = true
        subjectValue.message = this.props.lang.requiredInputMessage
        formOk = false
    } else {
      subjectValue.error = false
      subjectValue.message = ''
    }
    
    if (emailmessage == null || emailmessage == '') {
        emailmessageValue.error = true
      emailmessageValue.message = this.props.lang.requiredInputMessage
      formOk = false
    } else {
        emailmessageValue.error = false
        emailmessageValue.message = ''
    }

    if (receiver == null || !receiver.length) {
      receiverValue.error = true
      receiverValue.message = this.props.lang.requiredReceiverMessage
      formOk = false
    } else {
      receiverValue.error = false
      receiverValue.message = ''
    }

    this.setState({
      ...this.state,
      subject: subjectValue,
      emailmessage: emailmessageValue,
      receiver: receiverValue,
    })

    return formOk
  }

  handleChangeValue(content) {
    let messageValue = { error: false, message: '', value: content }

    this.setState({
      ...this.state,
      emailmessage: messageValue,
    })
  }

  submitSendEmail() {
    let courseId = this.props.courseId
    let teacherEmail = this.props.teacherEmail
    let subject = this.state.subject.value
    let message = this.state.emailmessage.value
    let receiver = this.state.receiver.selectedReceiver

    let formValidation = this.validateInfo(subject, receiver, message)

    if (formValidation) {

      let emailInfo = { courseId, teacherEmail, subject, receiver, message }

      this.props.sendEmail(emailInfo)

    }
  }


  render() {
    return (
      <>
        <div className="container">
          <div className="__main_header">
            <Row>
              <Col lg="12">
                <h1 className="__main_title">{this.props.lang.sendEmailTitle}</h1>
              </Col>
            </Row>
          </div>
          <Panel className="__card __fixedHeight">
            <Row>

              <Col lg="6" className='_margin_bottom_25'>
                  <Label text={this.props.lang.subjectLabel} />
                  <InputText
                    id="instituteName"
                    error={this.state.subject.error}
                    errorMsg={this.state.subject.message}
                    value={this.state.subject.value}
                    onChange={(e) => this.handleSubject(e.target.value)}
                  />
              </Col>
              <Col lg="6" className='_margin_bottom_25'>
                  <Label text={this.props.lang.receiverLabel} />
                  <InputComboBox
                    inputName={this.props.lang.receiverLabel}
                    placeholder={this.props.lang.selectOption}
                    id="receiver"
                    key={this.props.lang.selectOption}
                    dropdownItems={this.state.receiverOptions.map(({ label }) => label)}
                    onChange={(select, index) => {
                        const selected = this.state.receiverOptions.find((re, i) => re.label === select && index === i)
                        this.handleReceiver([selected])
                    }}
                    value={this.state.receiver?.selectedReceiver[0]?.label}
                    error={this.state.receiver.error}
                    errorMsg={this.state.receiver.message}
                  />
              </Col>

              <Col md="12">
                  <Label text={this.props.lang.messageLabel} />
                  <div className={this.state.emailmessage.error ? '__wysiwyg--error' : null}>
                    <Wysiwyg
                        onChange={(content) => this.handleChangeValue(content)}
                    />
                  </div>
                  {this.state.emailmessage.error && (
                        <MessageBox
                            theme='danger'
                            text={this.state.emailmessage.message}
                            noFill
                        />
                    )}
              </Col>
              <Col md="12">
                <p>{this.props.lang.recomendationMessage}</p>
              </Col>
              <Col md="12">
                <p><strong className="font-color--danger">{this.props.lang.attention} </strong> {this.props.lang.attentionMessage}</p>
              </Col>

            </Row>
            <Row>
              <Col lg="12">
                <div className="__main_footer">
                  <ButtonGroup className='pull-right'>
                    <Button
                      id="GoBackButton"
                      theme="secondary"
                      onClick={this.props.handleGoBack}
                      text={this.props.lang.goBackButton}
                    />
                    <Button
                      id="SendEmailButton"
                      onClick={this.submitSendEmail}
                      text={this.props.lang.sendButton}
                    />
                  </ButtonGroup>
                </div>
              </Col>
            </Row>
          </Panel>
        </div>
      </>
    )
  }

}
