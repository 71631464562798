'use-strict'

import React, { useState } from 'react'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'

import '../Roles.scss'

const InputName = ({
    state,
    message,
    label,
    onChange,
    value
}) => (
        <>
            <Label text={label} />
            <InputText
                id="name"
                value={value}
                onChange={onChange}
                error={state}
                errorMsg={message}
            />
        </>
    )

const InputDescription = ({
    state,
    message,
    label,
    onChange,
    value
}) => (
       <>
            <Label text={label} />
            <InputText
                id="description"
                value={value}
                onChange={onChange}
                error={state}
                errorMsg={message}
            />
       </>
    )

const SinglePermission = ({
    permission,
    search,
    isCreate,
    onChange,
    activeRole,
    defaultPermissionsRole
}) => {

    if (search !== "" && permission.description.toLowerCase().indexOf(search.toLowerCase()) === -1) {
        return null
    }

    let isChecked = false
    if (!isCreate) {
        isChecked = activeRole.Permissions.find(activePermission => activePermission.id === permission.id)
    }

    isChecked = defaultPermissionsRole.includes(permission.id)


    return (
        <div className="col-md-6" key={`${permission.id}-col`}>
            <div className="__checkbox_container" key={`${permission.id}-container`}>
                <label className="container _checkbox_label" key={`${permission.id}-label`}>
                    <input
                        key={`${permission.id}-input`}
                        type="checkbox"
                        onClick={e => onChange(e.target.checked, permission.id)}
                        defaultChecked={isChecked}
                    />&nbsp; &nbsp;
                {permission.description}
                </label>
            </div>
        </div>
    )
}

const InputPermissions = ({
    search,
    isCreate,
    activeRole,
    allPermissions,
    defaultPermissionsRole,
    onChange
}) => {

    return (
        <div className="col-md-12">
            <div className="_roles_checkbox">
                {allPermissions.map(permission => {
                    return (
                        <SinglePermission
                            key={`${permission.id}-single-permission`}
                            permission={permission}
                            search={search}
                            isCreate={isCreate}
                            onChange={onChange}
                            activeRole={activeRole}
                            defaultPermissionsRole={defaultPermissionsRole}
                        />
                    )
                })}
            </div>
        </div>
    )

}

const PermissionsFinder = ({
    state,
    message,
    setSearch,
    lang,
    search
}) => {
    return (
        <InputText
            id="searchByName"
            placeholder={lang.searchByName}
            autoComplete="off"
            onChange={e => setSearch(e.target.value)}
            value={search}
            error={state}
            errorMsg={message}
        />
    )
}


export default ({
    lang,
    handlers = {
        setField: () => { },
        updateRolePermissions: () => { },
    },
    validations = {
        name: {},
        description: {},
        permissions: {}
    },
    data = {
        name: '',
        description: '',
        Permissions: []
    },
    isCreate,
    allPermissions,
    defaultPermissionsRole
}) => {

    const [search, setSearch] = useState('')

    return (
        <>
            <div className="row _margin_bottom_25">
                <br />
                <div className="col-md-12">
                    <h3 className="__card_secondary_title">{lang.information}
                    </h3>
                </div>
                <div className="col-md-6">
                    <InputName
                        state={validations.name.state === 'error'}
                        message={validations.name.message}
                        label={`${lang.labels.name} *`}
                        value={data.name}
                        onChange={e => handlers.setField('name', e.target.value)}
                    />
                </div>
                <div className="col-md-6">
                    <InputDescription
                        state={validations.description.state === 'error'}
                        message={validations.description.message}
                        label={`${lang.labels.description} *`}
                        value={data.description}
                        onChange={e => handlers.setField('description', e.target.value)}
                    />
                </div>
            </div>
            <div className="row _margin_bottom_25">
                <div className="col-md-12">
                    <h3 className="__card_secondary_title">{lang.assignPermissions}</h3>
                </div>
                <div className="col-md-12">
                    <PermissionsFinder
                        state={validations.permissions.state === 'error'}
                        message={validations.permissions.message}
                        setSearch={setSearch}
                        lang={lang}
                        search={search}
                    />
                </div>
            </div>
            <div className="row">
                <InputPermissions
                    search={search}
                    isCreate={isCreate}
                    activeRole={data}
                    allPermissions={allPermissions}
                    defaultPermissionsRole={defaultPermissionsRole}
                    onChange={handlers.updateRolePermissions}
                />
            </div>
        </>
    )
}