export const EMPTY_STRING = ''

export const STRING_MIN_LENGHT = 0

export const TITLE_MAX_LENGHT = 90
export const DESCRIPTION_MAX_LENGHT = 140

export const ERROR = 'error'
export const SUCCESS = 'success'

//COURSE_TOOLS
export const TOOL_NAME_MAX_LENGTH = 150
export const TOOL_DESCRIPTION_MAX_LENGTH = 2000
