import { saveBlockProgressByBlock } from '@/redux/thunks/student/progress'
import { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from 'react-router-dom'

export const usePostMessage = (blockId, progress) => {
    const [message, setMessage] = useState('')
    const [hasErrors, setHasErrors] = useState(false)
    const [showTopAlert, setShowTopAlert] = useState(false)
    const { courseId, unitId } = useParams()
    const dispatch = useDispatch();
    const SCHEMA = 'progress-iframe'
    const INIT_BLOCKLY = '{"blocks":{"languageVersion":0,"blocks":[{"type":"on_execute","id":"rwW]g?!-iwJNk))r*~^C","x":61,"y":69}]}}'
    const iframeRef = useRef(null)
    const { content_map: { User } = {}} = useSelector(({ student: { classroom } = {} }) => classroom)
    const isOwner = User?.isOwner

    const handleEventTypes = {
        ['SUCCESS']: () => onSuccess(),
        ['FAILURE']: () => onFailure()
    }

    const onFailure = () => {
        setHasErrors(true)
        setShowTopAlert(true)
    }

    const onSuccess = () => {
        setHasErrors(false)
        setShowTopAlert(true)
    }

    const handleUpdateProgress = async (eventType, lastSending) => {
        await dispatch(
            saveBlockProgressByBlock({
                courseId,
                blockId,
                progress: { exerciseStatus: eventType, lastSending },
                schema: SCHEMA,
                unitId,
                isOwner
            })
        )
    }

    const checkValidReceivedEvent = (event) => {
        return event?.data?.type === 'blockly-type' 
            && event?.data?.event !== "STATE"
            && event?.data?.id === blockId
    }
    useEffect(() => {
        const handleMessage = async (event) => {
            if(checkValidReceivedEvent(event) && !progress.completed) {
                const { event: eventType, message: eventMessage, state: lastSending } = event.data
                handleEventTypes[eventType] && handleEventTypes[eventType]()
                setMessage(eventMessage)
                await handleUpdateProgress(eventType, lastSending)
            }
        }
        window.addEventListener('message', handleMessage)
        return () => {
            window.removeEventListener('message', handleMessage)
        }

    }, [progress])

    useEffect(() => {
        if(iframeRef) {
            const contentWindowPostMessage = () => {
                const savedProgress = progress?.ProgressExercise || progress?.ProgressIframe
                const postMessageEvent = {
                        data: savedProgress ? JSON.parse(savedProgress?.last_sending) : INIT_BLOCKLY,
                        type: 'init'
                }
                iframeRef.current.contentWindow.postMessage(postMessageEvent, '*')
            };
            iframeRef.current.addEventListener('load', contentWindowPostMessage);
            return () => {
                iframeRef.current.removeEventListener('load', contentWindowPostMessage)
            }
        }
    }, [progress])

    return [
        message,
        hasErrors,
        showTopAlert,
        setShowTopAlert,
        iframeRef
    ]
}
