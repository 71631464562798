import httpHandler, { codes } from '@/helpers/error-handling/httpHandler'
import * as strategyService from '../http/services/strategyService'
import { setAllStrategies, editStrategy } from '../reducers/strategyReducer'
import showNewMessage from '../reducers/_deprecated/globalAlertsReducer/alertsControllers'


export const loadStrategies = () => async (dispatch) => {
    const [error, response] = await strategyService.getGradesList()

    if (error) {
        return httpHandler(error)({
            [codes.NOT_FOUND]: () => {
                showNewMessage({
                    type: 'danger',
                    message: error.message,
                    isTemporal: true,
                })
            },
        })
    }

    dispatch(setAllStrategies(response))
}

export const canEditStrategy = (courseId) => async (dispatch) => {
    const [error, notes] = await strategyService.canEdit(courseId)

    if (error) {
        return httpHandler(error)({
            [codes.NOT_FOUND]: () => {
                showNewMessage({
                    type: 'danger',
                    message: error.message,
                    isTemporal: true,
                })
            },
        })
    }

    dispatch(editStrategy(!+notes))
}