import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './UsersSearch.scss'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'

const UsersSearch = ({ containerClassName, placeholder, value, onChange }) => {
    return (
        <div className={cn('users-search', containerClassName)}>
            <InputText
                leftIcon='Search'
                onChange={(e) => onChange(e.target.value)}
                {...{ placeholder, value }}
                className='users-search__input'
            />
        </div>
    )
}

UsersSearch.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    containerClassName: PropTypes.string,
}

UsersSearch.defaultProps = {
    onChange: () => {},
    value: '',
    placeholder: '',
    containerClassName: '',
}

export default UsersSearch
