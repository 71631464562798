import React, { useState, useEffect } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { ChevronsRight, ChevronsLeft } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { COURSE_ENTITIES_DATA } from '@/constants/courseEntities'
import { checkLessonIsReleased } from '@digitalhouse-tech/ts-lib-store/lib/shared/dates'

const CourseReportControl = ({ dataLevel, totalItems, filteredCourseData, lang }) => {
    const [enableScrollToLeft, setEnableScrollToLeft] = useState(true)
    const [enableScrollToRight, setEnableScrollToRight] = useState(true)
    const [currentScroll, setCurrentScroll] = useState(0)
    const [totalScrollMovements, setTotalScrollMovements] = useState(0)
    const headerContentDiv = document.getElementById('_report_detail_cols')
    const bodyContentDiv = document.getElementById('_report_content_cols')

    const isTopicsLevel = dataLevel === COURSE_ENTITIES_DATA.TOPICS
    const widthCols = isTopicsLevel ? 50 : 135 //px de ancho de cada columna del detalle del reporte
    const displacement = widthCols * 2

    useEffect(() => {
        setCurrentScroll(0)
        if (headerContentDiv && headerContentDiv.offsetWidth > 0) calculateScrollMovements()
    }, [headerContentDiv, dataLevel])

    useEffect(() => {
        if (!isTopicsLevel && totalScrollMovements > 0) moveToLastReleased()
    }, [totalScrollMovements])

    const scrollHandler = (side, movementsToRight = 0) => {
        const movements = movementsToRight > 0 ? movementsToRight : 1
        let move = movements * displacement
        move = side === 'right' ? move * -1 : move
        const newScrollPosition = side === 'right' ? currentScroll + movements : currentScroll - 1
        headerContentDiv.scrollLeft = headerContentDiv.scrollLeft - move
        bodyContentDiv.scrollLeft = bodyContentDiv.scrollLeft - move
        setCurrentScroll(newScrollPosition)
        scrollControl(newScrollPosition)
    }

    const calculateScrollMovements = () => {
        const visibleArea = headerContentDiv.offsetWidth
        const necessaryWidth = totalItems.widthItems * widthCols
        if (visibleArea >= necessaryWidth) enableScroll(false, false)
        else {
            enableScroll(true, false)
            const widthDifference = necessaryWidth - visibleArea
            const scrollMovements = Math.ceil(widthDifference / displacement)
            setTotalScrollMovements(scrollMovements)
        }
    }

    const scrollControl = (newScrollPosition) => {
        if (newScrollPosition === 0) enableScroll(true, false)
        else if (newScrollPosition === totalScrollMovements) enableScroll(false)
        else enableScroll()
    }

    const enableScroll = (right = true, left = true) => {
        setEnableScrollToRight(right)
        setEnableScrollToLeft(left)
    }

    const moveToLastReleased = () => {
        let lastEntityReleasedIndex = [...filteredCourseData]
            .reverse()
            .findIndex((item) => checkLessonIsReleased(item.releaseDate))
        const itemReleased =
            lastEntityReleasedIndex +
            (filteredCourseData.length - lastEntityReleasedIndex - lastEntityReleasedIndex)
        const movementsToRight = Math.ceil(
            (itemReleased * widthCols - headerContentDiv.offsetWidth) / displacement
        )
        setTimeout(() => scrollHandler('right', movementsToRight), 500)
    }

    return (
        <>
            <div className='_report_slide_control'>
                <div className='_report_slide_control_buttons'>
                    <Button
                        id='scroll_to_left_button'
                        theme='clear'
                        onClick={() => scrollHandler('left')}
                        tooltipId='scrollLeftTooltip'
                        tooltipContent={lang.scrollToLeft}
                        icon={<ChevronsLeft />}
                        className={cn({
                            _report_disable_button: !enableScrollToLeft,
                        })}
                    />
                    <Button
                        id='scroll_to_right_button'
                        theme='clear'
                        onClick={() => scrollHandler('right')}
                        tooltipId='scrollRightTooltip'
                        tooltipContent={lang.scrollToRight}
                        icon={<ChevronsRight />}
                        className={cn({
                            _report_disable_button: !enableScrollToRight,
                        })}
                    />
                    <p>{`Total  ${totalItems.items}`}</p>
                </div>
                {isTopicsLevel && <div className='_report_filling_content'></div>}
            </div>
        </>
    )
}

CourseReportControl.propTypes = {
    dataLevel: PropTypes.string.isRequired,
    totalItems: PropTypes.object.isRequired,
    filteredCourseData: PropTypes.array.isRequired,
    lang: PropTypes.object.isRequired,
}

export default CourseReportControl