import React, { useCallback, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import 'react-datepicker/dist/react-datepicker.css'

import { getSelectOptions } from '@/helpers/arrays'
import BlockModal from './BlockModal'
import { BLOCK_TYPES } from '@/constants/blocks'
import { hasAnyProgressSelector, createModalDataSelector } from '@/redux/selectors'
import { BAD_URL } from './constants/errorTypes'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import InputNumber from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputNumber'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

const VALID_PDF_URL = /^(http(s?):\/\/)/
const DEFAULT_PDF_BLOCK = {
    PdfBlock: {
        width: '100',
        height: '100',
        url: '',
        allow_download: true,
        extra: false,
    },
}

const pdfLangSelector = ({ langStore: { modalPDFBlock, genericPDFform, modalExerciseBlock } }) => ({
    ...modalPDFBlock,
    ...genericPDFform,
    ...modalExerciseBlock,
})
const pdfBlockSelector = createModalDataSelector(DEFAULT_PDF_BLOCK)

const ModalPDFBlock = () => {
    const lang = useSelector(pdfLangSelector)
    const hasAnyProgress = useSelector(hasAnyProgressSelector)
    const data = useSelector(pdfBlockSelector)

    const [urlPdf, setUrlPdf] = useState(data.PdfBlock.url)
    const [width, setWidth] = useState(data.PdfBlock.width)
    const [height, setHeight] = useState(data.PdfBlock.height)
    const [allowDownload, setAllowDownload] = useState(data.PdfBlock.allow_download)
    const [pdfError, setPdfError] = useState(null)
    const [extra, setExtra] = useState(data.extra)

    const values = {
        url: urlPdf,
        allow_download: allowDownload,
        width,
        height,
    }

    const completeLang = useMemo(() => {
        const { blockName, isVisible, showBlockName } = lang
        return {
            ...lang,
            blockNameLabel: blockName,
            isVisibleLabel: isVisible,
            showBlockNameLabel: showBlockName,
        }
    }, [lang])

    const validateInputValues = useCallback(() => {
        const errorMesssage = lang.requiredUrlMessage
        const isUrlValid = VALID_PDF_URL.test(urlPdf)
        const error = isUrlValid ? null : errorMesssage
        setPdfError(error)
        return error ? [BAD_URL] : []
    }, [lang, setPdfError, urlPdf])

    const urlFormatPdf= urlValue => {
        const docUrl = urlValue.replace('/view','/preview')
        setUrlPdf(docUrl)
    }

    const selectOptions = getSelectOptions(lang.yes, lang.no)
    const getValue = option => (option ? selectOptions[0] : selectOptions[1])
    const blockTitle = data.id ? lang.modalPDFTitleEdit : lang.modalPDFTitleCreate
    const id = BLOCK_TYPES.PDF_BLOCK.modal

    return (
        <BlockModal
            validateInputValues={validateInputValues}
            blockId={data.id}
            visibility={data.visibility}
            show_name={data.show_name}
            name={data.name}
            values={values}
            lang={completeLang}
            blockTitle={blockTitle}
            blockType={BLOCK_TYPES.PDF_BLOCK}
            id={id}
            extra={extra}
        >
            <Row>
                <Col lg="12" className='_margin_bottom_25'>
                    <Label text={lang.pdfURL} />
                    <InputText
                        id='blockURL'
                        key="blocURL"
                        bsSize="small"
                        type="text"
                        placeholder="e.g. https://drive.google.com/..."
                        value={urlPdf}
                        onChange={e => urlFormatPdf(e.target.value)}
                        error={pdfError}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg="3" className='modal-panel__number-input'>
                    <Label text={lang.width} />
                    <InputNumber
                        id='blockWidth'
                        min={0}
                        max={100}
                        placeholder="100%"
                        onChange={number => setWidth(number)}
                        value={width}
                    />
                </Col>

                <Col lg="3" className='modal-panel__number-input'>
                    <Label text={lang.height} />
                    <InputNumber
                        id='blockHeight'
                        min={0}
                        max={100}
                        placeholder="100%"
                        onChange={number => setHeight(number)}
                        value={height}
                    />
                </Col>

                <Col lg="6">
                    <Label text={lang.downloadable} />
                    <InputComboBox
                        inputName="descargable"
                        placeholder={lang.select}
                        id="descargable"
                        key="descargable"
                        dropdownItems={selectOptions.map(({ label }) => label)}
                        onChange={(el, index) => {
                            setAllowDownload(selectOptions[index].value)
                        }}
                        value={getValue(allowDownload).label}
                    />
                </Col>
                <Col md="12">
                    <Label text={lang.isExtraLabel} />
                    <InputComboBox
                        disabled={hasAnyProgress}
                        inputName='extra'
                        placeholder={lang.select}
                        id='extra'
                        dropdownItems={selectOptions.map(({ label }) => label)}
                        onChange={label => setExtra(label === selectOptions[0].label)}
                        value={getValue(extra).label}
                    />
                </Col>
            </Row>

            <Row style={{marginTop: '20px'}}>
                <Col lg="12" md="12">
                    {urlPdf && (
                        <div className="embed-container">
                            <iframe
                                src={urlPdf}
                                width={(width ? width : '100') + '%' + 'vw'}
                                height={(450 * (height ? height : '100')) / 100 + 'vh'}
                                webkitallowfullscreen="true"
                                mozallowfullscreen="true"
                                allowFullScreen
                                title="Preview"
                            />
                        </div>
                    )}
                </Col>
            </Row>
        </BlockModal>
    )
}

export default ModalPDFBlock
