import React from 'react'
import PropTypes from 'prop-types'
import ManagerTable from './table/ManagerTable'

const ManagerTableBody = ({ headers, rows, loading, notFoundMessage, useSearchMessage }) => {
    return (
        <ManagerTable
            headers={headers}
            rows={rows}
            loading={loading}
            notFoundMessage={notFoundMessage}
            useSearchMessage={useSearchMessage}
        />
    )
}

ManagerTableBody.propTypes = {
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            ref: PropTypes.string.isRequired,
            className: PropTypes.string,
        })
    ).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    loading: PropTypes.bool.isRequired,
    notFoundMessage: PropTypes.string.isRequired,
    useSearchMessage: PropTypes.string.isRequired
}

export default ManagerTableBody
