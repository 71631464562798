import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { actions as modalActions } from '@/redux/reducers/modalReducer'
import { avatarDimensions, 
    avatarFileSize,
    validateExtension,
    validateFileSize,
    validateDimensions,
    avatarFileFormat
} from './avatarSetupFormats'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { X, Upload, AlertCircle } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Spinner from '@digitalhouse-tech/react-lib-ui-explorer/lib/Spinner'
import './styles.scss'
import { emptyStateAsset } from '@/assets/img/config'

const AvatarUploadModal = ({
    show,
    handleDismiss,
    handleAvatarUpload,
    currentAvatar,
    userAvatar
}) => {
    const noAvatarIcon = emptyStateAsset('default-user-avatar.svg')

    const dispatch = useDispatch()
    const lang = useSelector(({ langStore }) => langStore.avatarUploadModal)
    const [loadedAvatar, setLoadedAvatar] = useState(false)
    const [uploadedAvatar, setUploadedAvatar] = useState(null)
    const [preview, setPreview] = useState(noAvatarIcon)
    const [loadedTimeout, setLoadedTimeout] = useState(false)
    const [validated, setValidated] = useState(false)
    const [loadingError, setLoadingError] = useState(false)
    const [currentError, setCurrentError] = useState('')
    const [savingAvatar, setSavingAvatar] = useState(false)
    const fileRef = useRef(null)
    const previewRef = useRef(null)
    
    const {
        aspect,
        format, 
        square, 
        maxDimension, 
        maxSize,
        extensionErrorMessage,
        sizeErrorMessage,
        dimensionErrorMessage,
    } = lang.avatar
    const { WIDTH, HEIGHT } = avatarDimensions
    const { UNITS, AMOUNT } = avatarFileSize
    const { ALLOWED } = avatarFileFormat
    const fileSize = `${AMOUNT} ${UNITS.KB}`
    const avatarDimension = `${WIDTH}px x ${HEIGHT}px`

    useEffect(() => {
        if(show && previewRef.current){
            previewRef.current.src = noAvatarIcon
            previewRef.current.src = `${currentAvatar}?v=${Date.now()}`
        }
    }, [show, previewRef.current])

    const handleSave = async () => {
        const data = new FormData()
        data.append('file', uploadedAvatar)
        data.append('fileName', 'avatar')
        setSavingAvatar(true)
        await handleAvatarUpload(preview, data)
        setSavingAvatar(false)
        handleDismiss(false)
        setLoadedAvatar(false)
        setUploadedAvatar(null)
        setValidated(false)
        previewRef.current.src = `${currentAvatar}?v=${Date.now()}`
        dispatch(modalActions.close())

    }

    const handleCancel = () => {
        handleDismiss(false)
        setPreview(noAvatarIcon)
        setLoadingError(false)
        setLoadedAvatar(false)
        dispatch(modalActions.close())
    }

    const handleUploadWindow = () => {
        fileRef && fileRef.current.click()
    }

    const validateImage = ({ width, height }) =>     
        (validateDimensions(width, height))
    
    const validateTypeAndSize = ({ type, size }) => {
        const isValidExtension = validateExtension(type)
        const isValidSize = validateFileSize(size)
        if(!isValidExtension) setCurrentError(extensionErrorMessage)
        if(!isValidSize) setCurrentError(sizeErrorMessage)

        return (isValidExtension && isValidSize)
    }

    const handleUploadedFile = (event) => {
        const [file] = event.target.files
        const reader = new FileReader()

        const validation = validateTypeAndSize(file)

        reader.onloadend = () => {
            var image = new Image()
            image.src = reader.result
            image.onload = () => {
                if(validateImage(image)){
                    setUploadedAvatar(file)
                    setPreview(reader.result)
                    previewRef.current.src = reader.result
                    setValidated(true)
                    setLoadingError(false)
                } else {
                    setCurrentError(dimensionErrorMessage)
                    setLoadingError(true)
                }
            }
        }
        !validation ? setLoadingError(true) : reader.readAsDataURL(file)
    }

    const handleOnloadAvatar = () => {
        setLoadedAvatar(true)
    }

    const handleOnErrorAvatar = () => {
        previewRef.current.src = noAvatarIcon
    }
    return (
        <Modal
            show={show}
            onHide={handleCancel}
            className="__modal __modal_avatar"
        >
            <Modal.Header
                className="__modal_header avatar-modal-header __border_bottom"   
            >
                <h3>{lang.modalTitle.toUpperCase()}</h3>
                <Button
                    id="closeIconModal" 
                    className="close_icon" 
                    theme="no-theme"
                    icon={<X />}
                    onClick={handleCancel}
                />
            
            </Modal.Header>
            <Modal.Body className="__modal_body avatar-modal-container">
                <div className="avatar-modal-container-row">
                    <div className="avatar-modal-container-column">
                        {!loadedTimeout && !loadedAvatar && userAvatar && 
                        <div className="loadingSpinner"><Spinner /></div>}
                        <img
                            style={{display: !loadedAvatar ? 'inline-block' : 'none'}}
                            className="modal-avatar" 
                            src={noAvatarIcon} 
                            alt="no-avatar" 
                        />

                        <img
                            style={{display: loadedAvatar ? 'inline-block' : 'none'}}
                            ref={previewRef}
                            className="modal-avatar" 
                            src={currentAvatar} 
                            alt="avatar" 
                            onLoad={handleOnloadAvatar}
                            onError={handleOnErrorAvatar}
                        />
                        
                    </div>
                    <div className="avatar-modal-container-column">
                        <h4>{lang.selectPhoto}</h4>
                        <p className="description">{lang.selectDescription}</p>
                        <div className="uploadBox">
                            <Upload className="uploadIcon" />
                            <input
                                type="file"
                                name="file"
                                onChange={handleUploadedFile}
                                style={{display:'none'}}
                                ref={fileRef}
                                accept=".jpg,.jpeg,.png"
                            />
                            <button 
                                type="button" 
                                className="uploadText" 
                                onClick={handleUploadWindow}
                            >
                                {lang.buttons.uploadAvatar}
                            </button>
                            
                        </div>

                    </div>
                </div>

                {loadingError &&
                    <div className="avatar-modal-container-row">
                        <div className="invalidAvatar-box">
                            <AlertCircle className="alert-icon" />
                            <span className="message">{currentError}</span>
                        </div>
                    </div>
                }

                <p className="conditionLegend">
                    <div>
                        {aspect}
                        {' '}
                        <span className="highlight">{square}</span>
                    </div>
                    <div>
                        {maxDimension} 
                        {' '}
                        <span className="highlight">{avatarDimension}</span>
                    </div>
                    <div>
                        {maxSize} 
                        {' '}
                        <span className="highlight">{fileSize}</span>
                    </div>
                    <div>
                        {format}
                        {' '}
                        <span className="highlight">{ALLOWED}</span>
                    </div>
                    <div className='separator' />
                </p>
                                    
            </Modal.Body>
            <Modal.Footer className="__modal_footer avatar-modal-footer __border_top">
                <div className="_btn_group pull-right" style={{ minHeight: 'unset' }}>
                    <Button
                        id='avatar-button-cancel'
                        onClick={handleCancel}
                        theme="secondary"
                        text={lang.buttons.cancel}
                    />
                    <Button
                        id='avatar-button-save'
                        className='avatar-button--save'
                        onClick={handleSave}
                        theme="primary"
                        loading={savingAvatar}
                        disabled={!validated || savingAvatar}
                        text={lang.buttons.save}
                    />
                </div>
            </Modal.Footer>
        </Modal>
        
    )
}

AvatarUploadModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleDismiss: PropTypes.func.isRequired,
    handleAvatarUpload: PropTypes.func.isRequired,
    currentAvatar: PropTypes.string.isRequired,
    userAvatar: PropTypes.string.isRequired
}

export default AvatarUploadModal