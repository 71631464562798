import * as bases from './bases'

const courses = bases.courses
const users = bases.users

export const owners = courseId => `/${courses}/${courseId}`

export const ownerAssign = courseId => `/${users}/${courses}/${courseId}`

export const usersOwnership = (userId, courseId) => `/${users}/${userId}/${courses}/${courseId}`
