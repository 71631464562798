export const VERSION = '1.51.0'
export const COURSE_API_URL = `https://playground-course-api.digitalhouse.com/v1/api/`
export const API_PG_URL = "https://pg-api.digitalhouse.com"
export const API_PG_TOKEN="0iFJ3HaM3T5Xh3anRzFNb22RtLaxRi3ydnFo4tZf"
export const GTM_ID = 'GTM-NLRZG33'
export const BLIP_KEY = 'ZGhhbHVtbm9zMTE6ZDg4N2YwZGItOGNhZi00YTExLThiNGQtZjY2OTFhNjY3NTRi'
export const BLIP_ENABLED = false
export const LANG_API_TOKEN = '2yViTeDmHk8pCqO4AkJ2P6AZx0mZY5dNarZTyUJz'
export const LANG_API_URL = 'https://pg-api.digitalhouse.com/lang'
export const INCOMING_DEPLOY_INTERVAL = 10000 * 60

export const IDLE_TIME_OUT = 1000 * 60 * 90
export const KEEP_SESSION_TIME = 1000 * 60 * 60 * 24 * 30
export const EXPIRE_SESSION_TIMEOUT = 1000 * 60 * 2
export const TTL_COURSE_CACHE = 15
export const FILES_SIZE_LIMIT_MB = 50
export const MICROFRONTENDS_URL = 'https://d2vuu7uc9wa87k.cloudfront.net'
export const ASSETS_URL='https://assets.digitalhouse.com/pg/prod'
export const IGNORE_REDUX_LOGGER = false
export const TENANT_API_URL = 'https://pg-api.digitalhouse.com/tenant'

export const VAULTY_WRITER_URL = 'https://vaulty.digitalhouse.com'
export const VAULTY_READER_URL = 'https://assets.digitalhouse.com'
export const VAULTY_API_KEY = 'VTLQ2S3uqSGQ9W1tBJh70GVVratqUt5EAFrbmbZy'
export const VAULTY_PATH = 'pg/prod'
export const DASHBOARD_API_URL = 'https://pg-api.digitalhouse.com/dashboard'
export const DASHBOARD_API_TOKEN = 'qKU6Dpj1BA6pma4Ot5ijk74GsSC6N9ra1473ZI2E'
export const SENTRY_DSN = ''
export const STAGE = 'gbl-prod'
export const PROGRESS_API_URL = 'https://pg-api.digitalhouse.com/progress'
export const PROGRESS_API_TOKEN = 'N35jhPHyjt33uLUeQHcQ349bVg1hKUaq20QU5izy'

export const EGG_SCRIPT_URL = 'https://egg-widget-script.s3.amazonaws.com/prod/dh-script-prod.js'
export const HEAP_ID = ''
export const DH_WEB_URL = 'https://www.digitalhouse.com'
export const SEGMENT_WRITE_KEY = 'ph2OXkn5sAGtpekPQD8VilXGwEJqfaYg'
export const SEGMENT_URL = 'https://cdnsegio.digitalhouse.com'
export const ASSESSMENT_API_URL = 'https://prod-gbl-pg-api.digitalhouse.com/assessment/v1'
export const ASSESSMENT_API_TOKEN = 'OOpRoj8kvd1ijkWGdBK8nahLWgPVXfvS3nbKC0xX'


export const CHANGE_PAYMENT_URL = 'https://growth-api.digitalhouse.com/payment-methods'
export const CHANGE_PAYMENT_TOKEN = ''
export const DLOCAL_AR_KEY="5e0fea5d-2a81-4e13-bb2c-c00b4f9462f4"
export const DLOCAL_CO_KEY="bb8f8ffc-b28f-459b-8a68-41124b577a1f"
export const DLOCAL_XX_KEY="9d7761c9-f702-4dcb-acbd-c41dd57716f4"
export const DLOCAL_SANDBOX_MODE=false
export const DLOCAL_UY_KEY="c2892b6e-8fd4-476b-b13a-bdbc4432a6c9"
export const DLOCAL_BR_KEY="d2986c73-c330-4caf-8e99-305c0eac77ed"

export const NOTION_API_URL = 'https://pg-api.digitalhouse.com'
export const NOTION_API_KEY = ''
export const COOKIE_SESSION_KEY = 'dh-pg-session'
export const PROFILE_URL = 'https://playground.digitalhouse.com/profile'
export const INSIGHTS_URL = 'https://playground.digitalhouse.com/insights'
export const COMMUNITY_URL = 'https://community.digitalhouse.com/discord-redirect'
export const GRAPH_API_URL = 'https://72sfw27qcncgpf2enbboq7hsly.appsync-api.us-east-1.amazonaws.com/graphql'
