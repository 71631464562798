import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import nextLessonDates from '../helpers/nextLessonDates'
import {
    enableShowCompleteDate,
    allowApiLessons,
    enableCertificate,
} from '@/helpers/granters/courseTypeRules'

export const useCourseCard = (course) => {
    const {
        id: courseId,
        content,
        CourseType,
        finished,
        lesson,
        show_dates,
        started,
        isTeacher,
    } = course

    const lang = useSelector(({ langStore }) => ({
        ...langStore.welcomeScreen,
        ...langStore.courseCard,
        schedule: langStore.schedule
    }))

    const surveys = useSelector(({ surveyReducer }) => surveyReducer.surveysWelcome)

    const { onGoingCourse, approvedCourse, courseFinished, notActive } = lang

    const survey = useMemo(() => {
        const courseSurveyUser = surveys.find(
            ({ CourseSurvey }) => CourseSurvey.course_id === courseId
        )
        if (!courseSurveyUser) return null
        const { survey_id, Survey, mandatory } = courseSurveyUser.CourseSurvey
        return {
            course_survey_user_id: courseSurveyUser.id,
            survey_id,
            name: Survey.name,
            description: Survey.description,
            completed: courseSurveyUser.completed,
            mandatory,
            courseId,
        }
    }, [surveys, course])

    const allowShowCompleteDateRule = CourseType  && enableShowCompleteDate(CourseType)
    const allowApiLessonsRule = CourseType && allowApiLessons(CourseType)
    const shouldNeverBeNaN = (num) => Number(num ? num : 0)

    const calculatePercentage = (num1, num2) =>
        num1 > 0 && num2 > 0 ? Math.ceil((num1 * 100) / num2) : 0

    const totalContent = shouldNeverBeNaN(content.total)
    const releasedContent = shouldNeverBeNaN(content.released)
    const completedContent = shouldNeverBeNaN(content.completed)

    const completedPercentageOfTotal = useMemo(
        () => calculatePercentage(completedContent, totalContent),
        [totalContent, completedContent]
    )

    const nextLessonDate = useMemo(
        () =>
            nextLessonDates(
                allowShowCompleteDateRule,
                allowApiLessonsRule,
                show_dates,
                lesson.nextOnSite,
                lang
            ),
        [allowShowCompleteDateRule, allowApiLessonsRule, show_dates, lesson, lang]
    )

    const courseState = useMemo(() => {
        let state = null
        // let state = courseToStart
        const showNoActive = !releasedContent && isTeacher
        if (started) state = onGoingCourse
        if (finished) state = courseFinished
        if (showNoActive) state = notActive
        if (completedPercentageOfTotal === 100 && enableCertificate(CourseType))
            state = approvedCourse
        return [
            state,
            cn(
                {
                    __on_going: started && !finished,
                    __finished: finished,
                    __not_active: showNoActive,
                },
                'course-card__flag'
            ),
        ]
    }, [
        started,
        finished,
        onGoingCourse,
        courseFinished,
        releasedContent,
        notActive,
        approvedCourse,
        completedPercentageOfTotal,
    ])

    return {
        courseState,
        nextLessonDate,
        survey,
        scheduleLang: lang.schedule
    }
}
