import React, { useEffect, useState } from 'react'

import Editor from '@/components/Playground/Editor/Editor'
import { RefreshCw } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import { v4 as uuidv4 } from 'uuid'
import './ExerciseIndividualReport.scss'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

const ExerciseIndividualReport = ({
    formattedStudents,
    handleSelectedStudent,
    studentResponse,
    lang,
    prework,
    handleStudentResponsePrework,
    studentsData,
}) => {
    const [activeFile, setActiveFile] = useState(false)
    const [files, setFiles] = useState([])
    const [selectedStudent, setSelectedStudent] = useState(null)

    useEffect(() => {
        if (prework) {
            handleStudentResponsePrework()
        }
    }, [studentsData])

    useEffect(() => {
        if (studentResponse?.files) {
            setFiles(studentResponse?.files?.filter((f) => f.section === 'SRC').map(f => ({ ...f, defaultOpen: true, _id: uuidv4() })))
        }
    }, [studentResponse])

    const showSelect = () => {
        return (
            <Row className="individual-report-select">
                <Col lg="6">
                    <Label text={lang.selectStudent} />
                    <InputComboBox
                        inputName='students'
                        placeholder={lang.selectOption}
                        id='students'
                        dropdownItems={formattedStudents.map(({ label }) => label)}
                        value={selectedStudent}
                        onChange={(student, index) => {
                            setFiles([])
                            const selected = formattedStudents.find((s, i) => s.label === student && index === i)
                            handleSelectedStudent(selected)
                            setSelectedStudent(selected.label)
                            setActiveFile(null)
                        }}
                    />
                </Col>
            </Row>
        )
    }
    return formattedStudents.length > 0 || prework ? (
        <>
            {prework ? null : showSelect()}
            {
                !studentResponse && prework ? (
                    <div style={{ height: '426px' }}>
                        <div className='__reload_progress_spinner'>
                            <RefreshCw className='__progress_spinner' />
                        </div>{' '}
                    </div>
                ) : files?.length ? (
                    <Editor
                        sections={['SRC']}
                        files={files}
                        readOnlySections={['SRC']}
                        setActiveFile={setActiveFile}
                        activeFile={activeFile}
                    />
                ) : null
            }
        </>
    ) : null
}

export default ExerciseIndividualReport
