import React from 'react'
import PropTypes from 'prop-types'
import Skeleton, { SkeletonGroup } from '@/components/Skeleton'

const ContentMapSkeleton = ({ className, isMobile }) => (
    <div className={className}>
        {!isMobile && (
            <>
                <SkeletonGroup containerStyles={`${className}__toggle-button`}>
                    <Skeleton width="40%" right />
                </SkeletonGroup>
                <hr style={{marginTop: 0}} />
            </>
        )}

        <SkeletonGroup>
            <Skeleton width="75%" />
        </SkeletonGroup>
        <SkeletonGroup>
            <Skeleton circle />
            <Skeleton />
            <Skeleton circle />
        </SkeletonGroup>
        <hr />
        <Skeleton />
        <hr />
        <Skeleton />
        <hr />
        <Skeleton />
    </div>
)

ContentMapSkeleton.propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
}

ContentMapSkeleton.defaultProps = {
    className: null,
    isMobile: false,
}

export default ContentMapSkeleton
