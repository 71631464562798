import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import RoleDetailList from './RoleDetailList'
import './RoleDetailTable.scss'

const RoleDetailTable = ({ categoriesPerRow, lang, permissions }) => {
    const groupPermissionByCategory = useMemo(() => {
        const groups = {}
        permissions.forEach(permission => {
            const { name } = permission.CoursePermissionCategory
            if (!groups[name]) {
                groups[name] = []
            }
            groups[name].push(permission.description)
        })
        return groups
    }, [permissions])

    const table = useMemo(() => {
        const keys = Object.keys(groupPermissionByCategory)
        const rowsCount = Math.ceil(keys.length / categoriesPerRow)
        const rows = []
        let acum = 0
        for (let i = 0; i < rowsCount; i++) {
            const columns = []
            for (let x = 0; x < categoriesPerRow; x++) {
                if (keys[acum]) {
                    columns.push(
                        <Col
                            className="permissions-table_col"
                            key={`category-table-${keys[x]}`}
                            md={3}
                        >
                            <RoleDetailList
                                category={keys[acum]}
                                categoryId={i}
                                permissions={groupPermissionByCategory[keys[acum]]}
                            />
                        </Col>,
                    )
                }
                acum++
            }
            rows.push(
                <div className="row permissions-table_row" key={`category-row-${i}`}>
                    {columns}
                </div>,
            )
            if (i < rowsCount - 1) {
                rows.push(<hr key={`separator-${acum}`} className="permission-table_separator" />)
            }
        }
        return rows
    }, [groupPermissionByCategory, categoriesPerRow])

    return (
        <>
            <div className="owner-edit-card">
                <p className="permissions-card_title">{lang.roleDetail}</p>
                <div className="permissions-card_table">{table}</div>
            </div>
        </>
    )
}

RoleDetailTable.propTypes = {
    categoriesPerRow: PropTypes.number,
    lang: PropTypes.shape({
        ownerEdit: PropTypes.string.isRequired,
        backButton: PropTypes.string.isRequired,
        roleDetail: PropTypes.string.isRequired,
        educationalRole: PropTypes.string.isRequired,
        saveChanges: PropTypes.string.isRequired,
        selectRole: PropTypes.string.isRequired,
        errorGetOwnerCoursePermissions: PropTypes.string.isRequired,
        errorUpdateOwnerCourseRole: PropTypes.string.isRequired,
    }).isRequired,
    permissions: PropTypes.arrayOf(
        PropTypes.shape({
            CoursePermissionCategory: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
            description: PropTypes.string.isRequired,
        }).isRequired,
    ),
}

RoleDetailTable.defaultProps = {
    categoriesPerRow: 3,
    permissions: [],
}

export default RoleDetailTable
