'use-strict'

const initialState = {
    paginated: {
        totalRows: 0,
        pagesQuantity: 0,
        rows: [],
        nextPage: null,
    }
}

const LOAD_PAGINATED_USERS = '@@users/LOAD_PAGINATED_USERS'
const CLEAR_ALL_USERS = '@@users/CLEAR_ALL_USERS'
const UPLOAD_AVATAR = '@@users/UPLOAD_AVATAR'

export const loadPaginatedUsers = data => ({
    type: LOAD_PAGINATED_USERS,
    payload: data
})

export const clearAllUsers = () => ({
    type: CLEAR_ALL_USERS,
})

export const uploadAvatarAction = () => ({
    type: UPLOAD_AVATAR
})

const userReducer = (state = initialState, { type, payload }) => {
    const types = {
        [LOAD_PAGINATED_USERS]: () => ({
            ...state,
            paginated: payload,
        }),
        [CLEAR_ALL_USERS]: () => ({
            ...initialState
        }),
        [UPLOAD_AVATAR]: () => ({ ...state }),
    }
    return types[type] ? types[type]() : state
}

export default userReducer
