
const USER_IDLE = '@@idle/USER_IDLE'
const SESSION_EXPIRED = '@@idle/SESSION_EXPIRED'
const KEEP_SESSION_LOGGED = '@@idle/KEEP_SESSION_LOGGED'
const CLEAR_ALL = '@@idle/CLEAR_ALL'
const CLEAR_EXPIRED= '@@idle/CLEAR_EXPIRED'

const initialState = {
    isIdle: false,
    isExpired: false,
    keepLogged: false,
}

export const onIdle = () => ({
    type: USER_IDLE
})

export const onExpire = () => ({
    type: SESSION_EXPIRED
})

export const clearExpired = () => ({
    type: CLEAR_EXPIRED
})

export const clearAll = () => ({
    type: CLEAR_ALL
})

export const keepSessionLogged = () => ({
    type: KEEP_SESSION_LOGGED
})

const idleTimeReducer = (state = initialState, { type }) => {
    switch (type) {
        case USER_IDLE:
            return { ...state, isIdle: true }
        case SESSION_EXPIRED:
            return { ...state, isExpired: true, isIdle: false }
        case KEEP_SESSION_LOGGED: 
            return { ...state, keepLogged: true }
        case CLEAR_EXPIRED: 
            return { ...state, isExpired: false }
        case CLEAR_ALL:
            return initialState
        default:
            return state
    }
}

export default idleTimeReducer