import React from 'react'
import PropTypes from 'prop-types'
import { offset, scrollTo } from '@/helpers/utils'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'

const ToastAlert = ({
    show,
    hasError,
    errorsText,
    reference,
    moral,
    noMoralText,
    seeErrorsText,
    onClickClose,
    showErrorButton,
}) => {
    if (!show) return null

    return (
        <div
            className={`toastA alert ${
                hasError ? 'alert-danger' : 'alert-success'
            } toastAlertFadeIn`}
        >
            <a className='close' onClick={() => onClickClose(false)}>
                &times;
            </a>
            {hasError ? (
                <>
                    <h3>{errorsText}</h3>
                    {
                        showErrorButton && (
                            <Button
                                id='ShowErrorsButton'
                                className='btn btn-default'
                                style={{ outline: 0 }}
                                onClick={() => {
                                    if (reference) {
                                        const top = offset(reference.current).top - 100
                                        scrollTo(window, top)
                                    }
                                }}
                                text={seeErrorsText}
                                theme='no-theme'
                            />
                        )
                    }
                </>
            ) : (
                <h3
                    dangerouslySetInnerHTML={{
                        __html: moral || noMoralText,
                    }}
                />
            )}
        </div>
    )
}

ToastAlert.propTypes = {
    show: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
    errorsText: PropTypes.string.isRequired,
    reference: PropTypes.shape({
        current: PropTypes.shape(),
    }).isRequired,
    moral: PropTypes.string.isRequired,
    noMoralText: PropTypes.string.isRequired,
    seeErrorsText: PropTypes.string.isRequired,
    onClickClose: PropTypes.func.isRequired,
    showErrorButton: PropTypes.bool
}

ToastAlert.defaultProps = {
    showErrorButton: true
}

export default ToastAlert
