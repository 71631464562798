/* eslint-disable no-undef */
import { useCallback, useEffect } from 'react'
import { MAX_REVIEW_CHARS, SUMMER_NOTE_CLASSNAME, ALLOWED_KEY_CODES } from '../constants/dashboard'

const useCharRestriction = (reviewTextLength) => {
    const handleKeyPress = useCallback(
        (event) => {
            const { keyCode, srcElement } = event
            const { className } = srcElement
            if (
                reviewTextLength > MAX_REVIEW_CHARS &&
                className.includes(SUMMER_NOTE_CLASSNAME) &&
                !ALLOWED_KEY_CODES.includes(keyCode)
            ) {
                event.preventDefault()
            }
        },
        [reviewTextLength]
    )

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress)
        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [handleKeyPress, reviewTextLength])
}

export default useCharRestriction
