import {
    getUserSessionStorage,
    setUserSessionStorage,
} from '@digitalhouse-tech/ts-lib-store/lib/session-storage'
import {
    SESSION_USER_KEY
} from '@digitalhouse-tech/ts-lib-store/lib/session-storage/constants'
import Cookies from 'js-cookie';
import { DH_WEB_URL, COOKIE_SESSION_KEY } from '@/config/env';

export const sessionUserCookieName = COOKIE_SESSION_KEY ?? 'dh-pg-session';
export const sessionUserCookieDomain = (DH_WEB_URL ?? 'https://www.digitalhouse.com').replace('http://www', '');

export const setUserSession = (user) => {
    Cookies.set(sessionUserCookieName,  JSON.stringify({token: user.token, tenant: user.tenant}, { domain: sessionUserCookieDomain }));
    setUserSessionStorage(user);
}

export const getUserCookies = () => {
  return JSON.parse(Cookies.get(SESSION_USER_KEY, { domain: sessionUserCookieDomain }) ?? '{}');
};

export const getUserSession = () => {
    const userSession = getUserSessionStorage();
          
    return userSession?.token ?  userSession : getUserCookies();
};

export const removeUserSessionCookie = () => {
    Cookies.remove(sessionUserCookieName);
    Cookies.remove(SESSION_USER_KEY);
    localStorage.removeItem(SESSION_USER_KEY);
    sessionStorage.removeItem(SESSION_USER_KEY);
}

export const isCurrentPlatformVersion = () => {
    return Cookies.get('ab-test-cookie') !== 'new'
}
