import axios from '@/app/requests'
import fileDownload from 'js-file-download'
import { getConfig } from '@/helpers/utils'
import api from '@/config/endpoints'

export default {
    httpModule: axios,
    async searchStudent(courseId, name='', surname='', email='') {
        const route = api.filterStudents(courseId, name, surname, email)
        const response = await this.httpModule.get(route, getConfig())
        return response
    },
    async exerciseProgress(userId, courseId, blockId) {
        const route = api.preworkReportExercise(userId, courseId, blockId)
        const response = await this.httpModule.get(route, getConfig())
        return response
    }

}