import React from 'react'
import PropTypes from 'prop-types'
import { ChevronLeft, ChevronRight } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import './CustomArrow.scss'
import cn from 'classnames'

const CustomArrow = ({
    right, 
    className, 
    onClick
}) => {

    const arrowLocation = cn(
        'arrowContainer',
        {'right': right,
            'left' : !right,
        })

    return (
        <div className={className} onClick={onClick}>
            <div>
                {right 
                    ? <ChevronRight />
                    : <ChevronLeft /> }
            </div> 
        </div>
    )
}

CustomArrow.defaultProps = {
    className: null,
    onClick: null,
}

CustomArrow.propTypes = {
    right: PropTypes.bool.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
}

export default CustomArrow
