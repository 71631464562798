import React from 'react'
import PropTypes from 'prop-types'
import { multilineEllipsisCrop } from '@/helpers/strings'

const CarouselItem = ({separator, name, title}) => {
    
    const defaultWidth = {width: 106}
    const separatorWidth = {width: 18}
    
    const cropNameIfExceeds = (paragraph) => {
        return paragraph.length>=50
            ? multilineEllipsisCrop(paragraph,50)
            : paragraph
    }

    return (
        !separator ? 
            <div className="unitBox" style={defaultWidth}>
                <h3 className="unitBoxTitle">{title}</h3>
                <p>{cropNameIfExceeds(name)}</p>
            </div>
            :
            <div className="unitSeparator" style={separatorWidth}>
                <div className="separatorLine" />
            </div>      
    
    )
}

CarouselItem.defaultProps = {
    name: "",
    title: "",
}

CarouselItem.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    separator: PropTypes.bool.isRequired,
}

export default CarouselItem