import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadCourse } from '@/redux/thunks/courseThunks'

const useCourseInfo = (courseId) => {
    const dispatch = useDispatch()
    const { name, alias, id, is_massive, assessment_strategy_id, CourseType } = useSelector((state) => state.course.course)

    useEffect(() => {
        dispatch(loadCourse(courseId))
    }, [dispatch, courseId])

    return { name , alias, id, is_massive, assessment_strategy_id, CourseType }
}

export default useCourseInfo
