import React from 'react'
import PropTypes from 'prop-types'
import './ModalPanel.scss'
import cn from 'classnames'

const ModalPanel = ({ children, theme }) => {
    const panelStyle = cn('modal-panel', {
        'modal-panel--no-theme': theme === 'no-theme',
    })
    return <div className={panelStyle}>{children}</div>
}

ModalPanel.defaultProps = {
    theme: '',
}

ModalPanel.propTypes = {
    children: PropTypes.node.isRequired,
    theme: PropTypes.string,
}

export default ModalPanel
