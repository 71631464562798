import React from 'react'
import PropTypes from 'prop-types'
import { Repeat, MoreVertical, Download } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonDropdown from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonDropdown'
import BlockIssueModal from './BlockIssueModal'
import useBlockIssueMenu from './useBlockIssueMenu'
import './BlockIssueMenu.scss'
import { PdfBlock, SlideBlock } from '@/constants/blocks'

const BlockIssueMenu = ({ blockType, blockId, blockName, url }) => {
    const {
        enableBlockIssueReport,
        openBlockIssueModal,
        closeBlockIssueModal,
        modalStep,
        changeModalStep,
        issueExplanation,
        changeIssueExplanation,
        selectedIssueIndex,
        selectIssue,
        issuesByBlockType,
        sendTrackingData,
        showModal,
        lang,
        nextStep,
        issueImages,
        handleIssueImagesUpload,
        handleDownload,
    } = useBlockIssueMenu()

    if (!enableBlockIssueReport) return <></>

    const dropdownOptions = [
        {
            text: lang.blockIssuesMenu.suggestRevision,
            isVisible: () => true,
            handle: () => openBlockIssueModal(blockId, blockName, blockType),
            icon: Repeat
        },
        {
            text: lang.blockIssuesMenu.donwload,
            isVisible: () => url && [SlideBlock, PdfBlock].includes(blockType),
            handle: () => handleDownload(url, blockType),
            icon: Download
        }
    ]

    const customDropdown = () => {
        return (
            <div className='block-issue-menu__dropdown'>
                {dropdownOptions.map(
                    ({ isVisible, text, handle, icon: IconComponent }, i) =>
                        isVisible() && (
                            <Button
                                key={i}
                                className='block-issue-menu__dropdown--item'
                                theme='no-theme'
                                leftIcon
                                icon={
                                    <IconComponent
                                        className='block-issue-menu__dropdown--icon'
                                        size={18}
                                    />
                                }
                                text={text}
                                onClick={handle}
                            ></Button>
                        )
                )}
            </div>
        )
    }

    return (
        <>
            <div className='block-issue-menu'>
                <ButtonDropdown
                    className='block-issue-menu__list'
                    theme='clear'
                    customDropdown={customDropdown()}
                    expandIcon={<MoreVertical />}
                />
            </div>
            <BlockIssueModal
                issueExplanation={issueExplanation}
                changeIssueExplanation={changeIssueExplanation}
                selectedIssueIndex={selectedIssueIndex}
                selectIssue={selectIssue}
                modalStep={modalStep}
                changeModalStep={changeModalStep}
                issuesByBlockType={issuesByBlockType}
                showModal={showModal}
                closeModal={closeBlockIssueModal}
                sendTrackingData={sendTrackingData}
                lang={lang}
                nextStep={nextStep}
                issueImages={issueImages}
                handleIssueImagesUpload={handleIssueImagesUpload}
            />
        </>
    )
}

BlockIssueMenu.propTypes = {
    blockType: PropTypes.string.isRequired,
    blockId: PropTypes.string.isRequired,
    blockName: PropTypes.string.isRequired,
    url: PropTypes.string,
}

export default BlockIssueMenu
