import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useHistory from '@/hooks/useHistory'
import PropTypes from 'prop-types'
import { getStudentsStatus } from '@/redux/thunks/dashboardThunks'
import { getStatusInfo, studentProgressUrl } from '../../shared/utils/reviewHelpers'
import { clearEvaluableActivityList } from '@/redux/reducers/dashboardReducer'
import useCourseInfo from '../../shared/hooks/useCourseInfo'
import useCourseSelects from '../../shared/hooks/useCourseSelects'
import { formatDate } from '../../shared/utils/dateHelpers'
import DashboardDropdownsBar from '../../shared/components/DashboardDropdownsBar'
import ListItem from './ListItem'
import LoadingOrContent from '@digitalhouse-tech/react-lib-ui-explorer/lib/LoadingOrContent'
import DashboardHeader from '../../shared/components/DashboardHeader'
import EmptyState from '../../shared/components/EmptyState/EmptyState'
import disabledSectionMassiveCourse from '../../shared/utils/disabledSectionMassiveCourse'
import {
    CLOCK_ICON,
    EVALUABLE_BLOCK,
    FORMAT_TIME,
    HOUR_SUFIX,
    EVALUABLE_ACTIVITY_SECTION,
} from '../../shared/constants/dashboard'
import { atomAsset } from '@/assets/img/config'
import './ListContainer.scss'

const ListContainer = ({ match }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const courseId = match.params.courseId

    const lang = useSelector(({ langStore }) => langStore.dashboard)

    const loading = useSelector(({ loading }) => loading.dashboard)
    const { studentsList } = useSelector(
        ({ dashboardReducer }) => dashboardReducer.evaluableActivity
    )

    const { name, alias, is_massive } = useCourseInfo(courseId)

    useEffect(() => {
        if (is_massive) disabledSectionMassiveCourse(is_massive)
    }, [is_massive])

    const {
        modules,
        selectedModule,
        lessons,
        selectedLesson,
        topics,
        selectedTopic,
        blocks,
        selectedBlock,
        onSelectChange,
    } = useCourseSelects(courseId, EVALUABLE_BLOCK)

    const hasNoProgress = selectedBlock.id && !studentsList.length

    const emptyStateProps = {
        imgUrl: hasNoProgress ? atomAsset('file-with-clock.svg') : atomAsset('grayscale-chart.svg'),
        text: hasNoProgress ? lang.emptyProgress : lang.emptySelection,
        isHidden: loading?.studentsStatus || (selectedBlock.id && studentsList.length > 0),
        className: hasNoProgress ? 'empty-progress-dashboard' : 'empty-selection-dashboard',
    }

    const fetchBlockActivities = useCallback(() => {
        dispatch(clearEvaluableActivityList())
        dispatch(getStudentsStatus(courseId, selectedBlock.id, EVALUABLE_BLOCK))
    }, [selectedBlock])

    useEffect(() => {
        if (selectedBlock.id) fetchBlockActivities()
        return () => dispatch(clearEvaluableActivityList())
    }, [fetchBlockActivities])

    useEffect(() => {
        dispatch(clearEvaluableActivityList())
        return () => dispatch(clearEvaluableActivityList())
    }, [])

    return (
        <div className='container evaluable-activity-list'>
            <DashboardHeader
                courseName={name}
                courseAlias={alias}
                section={EVALUABLE_ACTIVITY_SECTION}
            />
            <DashboardDropdownsBar
                modules={modules.map(({ name }) => name)}
                selectedModule={selectedModule.name}
                lessons={lessons.map(({ name }) => name)}
                selectedLesson={selectedLesson.name}
                topics={topics.map(({ name }) => name)}
                selectedTopic={selectedTopic.name}
                blocks={blocks.map(({ name }) => name)}
                selectedBlock={selectedBlock.name}
                onChange={(selection, index, key) => onSelectChange(selection, index, key)}
            />
            <EmptyState
                id='evaluableActivityEmptyState'
                imgUrl={emptyStateProps.imgUrl}
                text={emptyStateProps.text}
                hidden={emptyStateProps.isHidden}
                className={emptyStateProps.className}
            />
            <LoadingOrContent loading={loading?.studentsStatus}>
                <div className='evaluable-activity-list__items-container'>
                    {studentsList.map((student) => {
                        const listProps = getStatusInfo(student?.progressEvaluableLastStatus, lang)

                        const date = formatDate(student.lastDate, FORMAT_TIME)

                        return (
                            <ListItem
                                avatar={student.avatar}
                                cta={() =>
                                    history.push(studentProgressUrl(selectedBlock.id, student.id))
                                }
                                ctaId={`evaluableActivityStudent${student.id}`}
                                ctaLineAppearence={listProps?.ctaLineAppearence}
                                ctaText={listProps?.ctaText}
                                date={`${date} ${HOUR_SUFIX}`}
                                email={student.email}
                                fullName={`${student.surname} ${student.name} `}
                                status={lang.completed}
                                statusDescription={listProps?.text}
                                statusIcon={CLOCK_ICON}
                                statusTheme={listProps?.theme}
                                key={student.email}
                            />
                        )
                    })}
                </div>
            </LoadingOrContent>
        </div>
    )
}

ListContainer.propTypes = {
    match: PropTypes.object,
}

ListContainer.defualtProps = {
    match: {},
}

export default ListContainer
