import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { page } from '@digitalhouse-tech/react-sdk-analytics'
/* @KEEPLOGGED keepSessionLogged unused until new implementacion is ready */
import { keepSessionLogged, clearExpired } from '@/redux/reducers/idleTimeReducer'
import { clearErrors } from '@/redux/reducers/_deprecated/authReducer'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import useHistory from '@/hooks/useHistory'
import { getTenantSession } from '@/helpers/tenants/helpers'
import { ASSETS_URL, DH_WEB_URL } from '@/config/env'
import { jsUcfirst } from '@/helpers/utils'
import { isCurrentPlatformVersion } from '@/helpers/users/helpers'
import useVideoHero from '../shared/hooks/useVideoHero'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import InputPassword from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputPassword'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import VideoHero from '@digitalhouse-tech/react-lib-ui-explorer/lib/VideoHero'
/* @KEEPLOGGED Checkbox unused until new implementacion is ready */
import Checkbox from '@digitalhouse-tech/react-lib-ui-explorer/lib/Checkbox'
import { loginPage } from '@/constants/pageViewEvents'
import './NativeLogin.scss'

const PASSWORD = 'password'
const TEXT = 'text'

const INPUT_STATE = (type = TEXT) => ({
    error: null,
    message: '',
    value: '',
})

const Login = ({
    lang,
    error,
    logoUrl,
    onClickSubmit,
    oAuthStrategies,
    showTermsAndConditions,
}) => {
    const [email, setEmail] = useState(INPUT_STATE())
    const [password, setPassword] = useState(INPUT_STATE(PASSWORD))
    const [loading, setLoading] = useState(false)
    const [showCapsLock, setShowCapsLock] = useState(false)
    const [passwordSpaceBar, setPasswordSpaceBar] = useState(false)

    /* 
    ** @KEEPLOGGED functionality commented until new implementation is ready 
    ** const [keepLogged, setKeepLogged] = useState(false)
    */

    const history = useHistory()
    const dispatch = useDispatch()

    const { dhLogoUrl, country } = getTenantSession()
    const DH_URL = `${DH_WEB_URL}/${country}`

    const { videoSrc, posterSrc, autoPlay, notSupportedMsg } = useVideoHero()
    useEffect(() => {
        document.onkeypress = function (e) {
            e = e || window.event
            setShowCapsLock(e.getModifierState('CapsLock'))
        }
        if(isCurrentPlatformVersion()) page(loginPage, {
            platformVersion: '1.x',
        })
        dispatch(clearErrors())
    }, [])

    const onSubmitHandler = async (e) => {
        e.preventDefault()
        dispatch(clearExpired())

        let hasError = false
        if (!email.value) {
            hasError = true
            setEmail({ ...email, error: 'error', message: lang.requiredInputMessage })
        } else if (email.value.indexOf('@') === -1 || email.value.indexOf('.') === -1) {
            hasError = true
            setEmail({ ...email, error: 'error', message: lang.emailFormatMessage })
        } else {
            setEmail({ ...email, error: null })
        }

        if (!password.value) {
            hasError = true
            setPassword({ ...password, error: 'error', message: lang.requiredInputMessage })
        } else {
            setPassword({ ...password, error: null })
        }

        if (hasError) return

        setLoading(true)

        /* 
        ** @KEEPLOGGED functionality commented until new implementation is ready 
        ** if (keepLogged) dispatch(keepSessionLogged(true))
        */

        await onClickSubmit(email.value, password.value)
        setLoading(false)
    }

    /* 
    ** @KEEPLOGGED functionality commented until new implementation is ready 
    ** const handleKeepSessionLogged = () => {
    **     setKeepLogged(!keepLogged)
    ** }
    */

    const handlePassword = useCallback(
        ({ target: { value } }) =>
            setPassword({ ...password, value }),
        [])

    const handleKeyDown = useCallback((e) => setPasswordSpaceBar(e.which === 32), [])

    const getSocialButton = (provider) => ({
        image: `${ASSETS_URL}/logos/${provider}-small.png`,
        text: `${lang.socialLogin}`,
    })

    return (
        <>
            <VideoHero
                videoSrc={videoSrc}
                posterSrc={posterSrc}
                autoPlay={autoPlay}
                notSupportedMsg={notSupportedMsg}
            />

                <div className='auth-page'>
                    <h1 className='auth-page__welcome-msg'>{lang.welcomeMsg}</h1>
                    <div className='auth-page__card'>
                        <div className='auth-page__card__pg-logo'>
                            <img src={logoUrl} alt='Playground Logo' />
                        </div>
                        {error && (
                            <MessageBox theme='danger' text={error} className='auth-page__message-box' />
                        )}
                        <form onSubmit={onSubmitHandler}>
                            <div className='auth-page__card__input-box'>
                                <Label
                                    data-testid='emailErrorMessage'
                                    htmlFor='username'
                                    text={lang.email}
                                    theme={email.error ? 'validation' : 'classic'}
                                />
                                <InputText
                                    id='username'
                                    type={email.type}
                                    value={email.value}
                                    onChange={({ target: { value } }) =>
                                        setEmail({ ...email, value: value.trim() })
                                    }
                                    error={email.error}
                                    errorMsg={email.message}
                                    className='auth-page__card__input-box--username'
                                    autoComplete
                                    autoFocus
                                    data-testid='emailInput'
                                />
                            </div>
                            <div className='auth-page__card__input-box'>
                                <Label
                                    data-testid='pwErrorMessage'
                                    htmlFor='input-password'
                                    text={lang.password}
                                    theme={password.error ? 'validation' : 'classic'}
                                />
                                <InputPassword
                                    id='input-password'
                                    value={password.value}
                                    onChange={!passwordSpaceBar ? handlePassword : () => { }}
                                    onKeyDown={handleKeyDown}
                                    error={password.error}
                                    errorMsg={password.message}
                                    data-testid='pwInput'
                                />
                                {showCapsLock && (
                                    <p data-testid='capslockWarnText' className='_input_capslock_msg'>
                                        {lang.bloqMayus}
                                    </p>
                                )}
                            </div>
                            <div className='auth-page__confirm-section'>
                                {/* 
                                @KEEPLOGGED functionality commented until new implementation is ready
                                <Checkbox
                                    id='KeepSessionLoggedCheckbox'
                                    className='auth-page__confirm-section--checkbox'
                                    theme='confirm'
                                    value={keepLogged}
                                    onClick={handleKeepSessionLogged}
                                    data-testid='keepLoggedInCheckbox'
                                />
                                <Label
                                    className='auth-page__confirm-section--label'
                                    htmlFor='KeepSessionLoggedCheckbox'
                                    text={lang.keepLoggedIn}
                                    theme='clear'
                                />
                            */}
                                <Button
                                    id='ResetPassword'
                                    className='auth-page__button auth-page__button--link'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        history.push('/forgot-password')
                                    }}
                                    theme='clear'
                                    block
                                    text={lang.forgotPassword}
                                    data-testid='resetPasswordButton'
                                />
                            </div>
                            <Button
                                id='SubmitLoginButton'
                                className='auth-page__button'
                                theme='primary'
                                block
                                type='submit'
                                text={lang.submit}
                                loading={loading}
                                data-testid='submitButton'
                            />

                            {oAuthStrategies.map((strategy) => {
                                return (
                                    <Button
                                        id={strategy.config.provider}
                                        key={strategy.config.provider}
                                        className='auth-page__button auth-page__button--strategy'
                                        theme='tertiary'
                                        block
                                        onClick={() =>
                                            (window.location.href = strategy.config.providerUrl)
                                        }
                                        image={
                                            <img
                                                src={getSocialButton(strategy.config.provider).image}
                                                className='auth-page__button--strategy--logo'
                                            />
                                        }
                                        text={getSocialButton(strategy.config.provider).text}
                                        data-testid='oAuthStrategy'
                                    />
                                )
                            })}
                        </form>
                        <div className='auth-page__card__dh-logo'>
                            <img src={dhLogoUrl} alt='Digital House Logo' />
                        </div>
                        <div className='auth-page__card__links'>
                            {showTermsAndConditions && (
                                <>
                                    <a
                                        href={`${window.location.origin}/terms-and-conditions`}
                                        target='_blank'
                                        rel='noreferrer'
                                        id='TermsAndConditionsLink'
                                    >
                                        {lang.termsAndConditions}
                                    </a>
                                </>
                            )}
                            <a
                                href={`${window.location.origin}/privacy-policy`}
                                target='_blank'
                                rel='noreferrer'
                                id='PrivacyPolicyLink'
                            >
                                {lang.privacyPolicy}
                            </a>
                        </div>
                    </div>
                </div>
        </>
    )
}

Login.propTypes = {
    lang: PropTypes.object,
    error: PropTypes.string,
    logoUrl: PropTypes.string.isRequired,
    onClickSubmit: PropTypes.func.isRequired,
    oAuthStrategies: PropTypes.array,
    showTermsAndConditions: PropTypes.bool,
}

export default Login
