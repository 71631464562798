import moment from 'moment-timezone'
import { timezoneConfig } from '@/helpers/dates/timezoneConfig'
import { capitalize } from '@/helpers/strings'

const nextLessonDates = 
(
    allowShowCompleteDateRule,
    allowApiLessonsRule,
    showDates,
    nextOnSite,
    { classAttendance, today, tomorrow }
) => {
    const [tz, locale] = timezoneConfig()
    const defaultFormat = 'DD/MM/YYYY'
    const hourFormat = allowShowCompleteDateRule ? ' [-] kk:mm [hs]' : ''
    const completeFormat = `ddd ${defaultFormat}${hourFormat}`
    moment.locale(locale)
    const calendarFormat = {
        sameDay: `[${today}] ${defaultFormat}${hourFormat}`,
        nextDay: `[${tomorrow}] ${defaultFormat}${hourFormat}`,
        nextWeek: completeFormat,
        lastDay: completeFormat,
        lastWeek: completeFormat,
        sameElse: completeFormat,
    }

    const formatTimezoneDate = (date) => moment(date).tz(tz).calendar(calendarFormat)
    const formatDate = (date) => formatTimezoneDate(date)

    const showNextOnSiteClass = !!nextOnSite && allowApiLessonsRule

    if (!showDates || !showNextOnSiteClass) return []

    let subtitle = ''
    let info = ''

    if (showNextOnSiteClass) {
        subtitle = classAttendance
        info = capitalize(formatDate(nextOnSite))
    }

    return [subtitle, info]
}

export default nextLessonDates
