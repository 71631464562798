import React, { createRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { calculatePaginationRange } from '@/helpers/pagination'
import { isObjectEmpty, offset, scrollTo } from '@/helpers/utils'
import { CREATE_COURSE } from '@digitalhouse-tech/ts-lib-store/lib/session-storage/permission'
import Paginator from '@digitalhouse-tech/react-lib-ui-explorer/lib/Paginator'
import { loadAllInstitutes } from '@/redux/reducers/_deprecated/instituteReducer'
import LoadingOrContent from '@digitalhouse-tech/react-lib-ui-explorer/lib/LoadingOrContent'
import { loadStrategies } from '@/redux/thunks/strategyThunks'

import {
    changeLoadingAction,
    clearActiveCourseAction,
    clearActiveSearchAction,
    clearCourseAction,
    clearCourseProgressAction,
    clearEmailAlertAction,
    clearUpdatedCloneSurveyAction,
    loadAllCourses,
    loadAllCourseTypes,
    returnButtonHideAction,
    setActiveSearchAction,
} from '../../../../redux/reducers/_deprecated/courseReducer'

import CourseFinder from './CourseFinder'
import CourseCard from './CourseCard'
import CourseModal from '../Modal'

const searchParams = (params, page = 1) => {
    let searchData = {}
    if (page) {
        searchData.page = page
    }
    if (params.name && params.name !== "") {
        searchData.name = params.name
    }
    if (params.alias && params.alias !== "") {
        searchData.alias = params.alias
    }
    if (params.isBase !== null && params.isBase !== undefined) {
        searchData.base = params.isBase
    }
    if (params.initial_date) {
        searchData.initial_date = params.initial_date
    }
    if (params.finish_date) {
        searchData.finish_date = params.finish_date
    }
    if (params.institute_id) {
        searchData.institute_id = params.institute_id
    }
    if (params.course_type_id) {
        searchData.course_type_id = params.course_type_id
    }
    return searchData
}

const queryOptions = { order_by: { item: 'created_at', value: 'desc' } }

export class CourseList extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            currentPage: 1,
            pagesQuantity: 1,
            searchActive: true,
        }

        this.courseCardRef = createRef()
        this.goToPageTop = this.goToPageTop.bind(this)
        this.pageChangeHandler = this.pageChangeHandler.bind(this)
        this.openModalCourse = this.openModalCourse.bind(this)
        this.showCourses = this.showCourses.bind(this)
        this.showPagination = this.showPagination.bind(this)
        this.renderAlert = this.renderAlert.bind(this)
        this.renderEmailAlert = this.renderEmailAlert.bind(this)
        this.modalCourse = null
        this.setCurrentPage = this.setCurrentPage.bind(this)
    }

    static getDerivedStateFromProps(props) {
        const { currentPage, pagesQuantity } = props.allCourses
        return {
            pagesQuantity,
            ...(currentPage && { currentPage }),
        }
    }

    componentDidMount() {
        this.props.clearCourse()
        this.props.loadAllCourses(searchParams(this.props.activeSearch), queryOptions)
        this.props.clearActiveCourseAction()
        this.props.loadAllInstitutes()
        this.props.loadAllCourseTypes()
        this.props.returnButtonHideAction()
        this.props.clearCourseProgressAction()
        this.props.clearUpdatedCloneSurveyAction()
        this.props.loadStrategies()
    }

    showCourses() {
        let resultCourses = Array()
        let allCourses = this.props.allCourses.rows ? this.props.allCourses.rows : this.props.allCourses
        if (!allCourses.length) {
            return <div className="__search_msg_result"><p>{this.props.lang.youCanUseTheSearchEngine}</p></div>
        }
        allCourses.map(function (course) {
            resultCourses.push(<CourseCard {...course} key={'course' + course.id} />)
        })
        return resultCourses
    }

    openModalCourse() {
        return this.modalCourse.handleShow()
    }

    setCurrentPage(page) {
        this.setState({
            currentPage: page,
        })
    }

    goToPageTop() {
        const courseCardTop = offset(this.courseCardRef.current).top - 100
        scrollTo(window, courseCardTop)
    }

    async pageChangeHandler(page) {
        const { activeSearch, loadAllCourses } = this.props
        this.setCurrentPage(page)

        if (isObjectEmpty(activeSearch)) {
            await loadAllCourses(searchParams({}, page), queryOptions)
        } else {
            await loadAllCourses(searchParams(activeSearch, page), queryOptions)
        }

        this.goToPageTop()
    }

    showPagination() {
        const { currentPage, pagesQuantity } = this.state
        const {
            isLoading,
            allCourses: { rows },
        } = this.props

        return rows && rows.length ? (
            <Paginator
                currentPage={currentPage}
                disabled={isLoading}
                onPageChange={(page) => this.pageChangeHandler(page)}
                range={calculatePaginationRange(pagesQuantity)}
                totalPages={pagesQuantity}
            />
        ) : (
            <></>
        )
    }

    removeAlert(e) {
        e.preventDefault()
        this.props.clearUpdatedCloneSurveyAction()
    }

    renderAlert() {
        if (this.props.cloneSurveyMsg) {
            return (
                <div className="toastA alert alert-success" ref="alert">
                    <a href="#" className="close" onClick={this.removeAlert.bind(this)}>&times;</a>
                    <h3>{this.props.lang.theCourseWasSuccessfullyClonedButTheSurveysCouldNotBeCloned}</h3><br />
                </div>
            )
        }
        else { return null }
    }

    removeEmailAlert(e) {
        e.preventDefault()
        this.props.clearEmailAlert(false)
    }

    renderEmailAlert() {
        if (this.props.emailAlert) {
            return (
                <div className={"toastA alert alert-success"} ref="emailAlert">
                    <a href="#" className="close" onClick={this.removeEmailAlert.bind(this)}>&times;</a>
                    <h3 className="alert-text">{this.props.lang.emailsWereSentSuccessfully}</h3>
                </div>
            )
        }
        else {
            return null
        }
    }

    render() {
        const { courseFinder: courseSearcherFormLang } = this.props.lang
        const { generalSearcherLang } = this.props

        return (
            <>
                <CourseFinder
                    activeSearch={this.props.activeSearch}
                    allInstitutes={this.props.allInstitutes}
                    clearActiveSearch={this.props.clearActiveSearch}
                    courseTypes={this.props.courseTypes}
                    lang={{ courseSearcherFormLang, generalSearcherLang }}
                    loading={this.props.isLoading}
                    onSubmit={[
                        this.props.loadAllCourses,
                        this.props.setActiveSearch,
                        this.props.changeLoading,
                        this.goToPageTop,
                        this.setCurrentPage,
                    ]}
                    ref='courseFinderRef'
                    searcherActions={[
                        {
                            handler: this.openModalCourse,
                            id: 'OpenModalCourse',
                            name: generalSearcherLang?.create,
                        },
                    ]}
                    searcherActionsPermission={CREATE_COURSE()}
                />
                <div className='container'>
                    {this.renderAlert()}
                    {this.renderEmailAlert()}
                    <div ref={this.courseCardRef}>
                        <LoadingOrContent loading={this.props.isLoading} size='medium'>
                            {this.showCourses()}
                        </LoadingOrContent>
                    </div>
                    <div className='__pagination'>{this.showPagination()}</div>

                    <CourseModal
                        ref={(modalCourse) => {
                            this.modalCourse = modalCourse
                        }}
                    />
                </div>
            </>
        )
    }
}

const mapDispatch = (dispatch) => ({
    changeLoading: (status) => {
        dispatch(changeLoadingAction(status))
    },
    clearActiveCourseAction: () => {
        dispatch(clearActiveCourseAction())
    },
    clearActiveSearch: () => {
        dispatch(clearActiveSearchAction())
    },
    clearCourse: () => {
        dispatch(clearCourseAction())
    },
    clearCourseProgressAction: () => dispatch(clearCourseProgressAction()),
    clearEmailAlert: (data) => dispatch(clearEmailAlertAction(data)),
    clearUpdatedCloneSurveyAction: () => {
        dispatch(clearUpdatedCloneSurveyAction())
    },
    loadAllCourses: (filters, options) => dispatch(loadAllCourses(filters, options)),
    loadAllCourseTypes: () => {
        dispatch(loadAllCourseTypes())
    },
    loadAllInstitutes: () => dispatch(loadAllInstitutes()),
    returnButtonHideAction: () => dispatch(returnButtonHideAction()),
    setActiveSearch: (data) => {
        dispatch(setActiveSearchAction(data))
    },
    loadStrategies: () => dispatch(loadStrategies())
})

const mapStateToProps = (state) => {
    return {
        activeSearch: state.courseReducer.activeSearch,
        allCourses: state.courseReducer.allPaginatedCourses,
        allInstitutes: state.instituteReducer.allInstitutes,
        cloneSurveyMsg: state.courseReducer.cloneSurveyMsg,
        courseTypes: state.courseReducer.courseTypes,
        emailAlert: state.courseReducer.emailAlert,
        generalSearcherLang: state.langStore.generalSearcher,
        isLoading: state.courseReducer.isLoading,
        lang: state.langStore.courseList,
    }
}

CourseList.propTypes = {
    activeSearch: PropTypes.object.isRequired,
    allCourses: PropTypes.shape({
        currentPage: PropTypes.number,
        isLoaded: PropTypes.bool,
        nextPage: PropTypes.number,
        pagesQuantity: PropTypes.number,
        rows: PropTypes.arrayOf(PropTypes.object),
        totalRows: PropTypes.number,
    }).isRequired,
    allInstitutes: PropTypes.object.isRequired,
    changeLoading: PropTypes.func.isRequired,
    clearActiveCourseAction: PropTypes.func.isRequired,
    clearActiveSearchAction: PropTypes.func.isRequired,
    clearCourse: PropTypes.func.isRequired,
    clearCourseProgressAction: PropTypes.func.isRequired,
    clearEmailAlert: PropTypes.func.isRequired,
    clearUpdatedCloneSurveyAction: PropTypes.func.isRequired,
    courseTypes: PropTypes.object.isRequired,
    emailAlert: PropTypes.bool.isRequired,
    generalSearcherLang: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    lang: PropTypes.object.isRequired,
    loadAllCourses: PropTypes.func.isRequired,
    loadAllCourseTypes: PropTypes.func.isRequired,
    loadAllInstitutes: PropTypes.func.isRequired,
    returnButtonHideAction: PropTypes.func.isRequired,
    setActiveSearch: PropTypes.func.isRequired,
}

export default withRouter(connect(mapStateToProps, mapDispatch)(CourseList))
