import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import RoleDetailTable from './RoleDetailTable'
import CustomCargando from '@/components/CustomCargando'
import BackButton from '@/components/BackButton'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

import './OwnerEdit.scss'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'

const OwnerEdit = ({ loading, ownershipRoles, lang, owner, onClickUpdateOwnershipRole }) => {
    const [defaultRole, setDefaultRole] = useState(null)
    const [selected, setSelected] = useState([])
    const [hasChanges, setHasChanges] = useState(false)
    const [selectedPermissions, setSelectedPermissions] = useState([])

    useEffect(() => {
        const [userHasOwnershipRole] = owner.UserHasOwnershipRoles
        if (userHasOwnershipRole) {
            const { OwnershipRole } = userHasOwnershipRole
            setSelected([OwnershipRole])
            setDefaultRole(OwnershipRole)

            const { CoursePermissions } = ownershipRoles.find(
                ({ id }) => id === OwnershipRole.id,
            ) || { CoursePermissions: [] }
            setSelectedPermissions(CoursePermissions)
        }
    }, [owner.UserHasOwnershipRoles, ownershipRoles])

    const selectOwnershipRoles = useMemo(
        () => ownershipRoles.map(({ id, description }) => ({ value: id, label: description })),
        [ownershipRoles],
    )

    const selectedOwnershipRoles = useMemo(
        () =>
            selected.map(({ id, description }) => ({
                value: id,
                label: description,
            })),
        [selected],
    )

    const onChangeSelection = (option, index) => {
        if (Array.isArray(option) || index < 0 || ownershipRoles.indexOf(ownershipRoles[index]) === -1) {
            return setSelected([{ value: '', label: '' }])
        }
        const ownershipRole = ownershipRoles[index]
        setSelected([ownershipRole])
        setHasChanges(defaultRole && defaultRole.id !== ownershipRole.id)
        setSelectedPermissions(ownershipRole.CoursePermissions)
    }

    if (loading) {
        return <CustomCargando />
    }

    return (
        <>
            <div className="container">
                <div className="__main_header">
                    <Row>
                        <Col lg="12">
                            <BackButton text={lang.backButton} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <h1 className="__main_title">{lang.ownerEdit}</h1>
                        </Col>
                    </Row>
                    <div className="owner-edit-card">
                        <p className="owner-edit-card_title">{`${owner.name} ${owner.surname}`}</p>
                        <p className="owner-edit-card_subtitle">{lang.educationalRole}</p>
                        <div>
                            <Row>
                                <Col md="4">
                                    <InputComboBox
                                        name="OwnershipRoleSelect"
                                        placeholder={lang.selectRole}
                                        id='OwnershipRoleSelect'
                                        dropdownItems={selectOwnershipRoles.map(({ label }) => label)}
                                        onChange={(item, index) => onChangeSelection(item, index)}
                                        value={selectedOwnershipRoles[0]?.label}
                                    />
                                </Col>
                                <Col md="4">
                                    <Button
                                        id="save_edition_button"
                                        text={lang.saveChanges}
                                        disabled={!hasChanges}
                                        onClick={() => onClickUpdateOwnershipRole(selected[0].id)}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <RoleDetailTable lang={lang} permissions={selectedPermissions} />
                </div>
            </div>
        </>
    )
}

OwnerEdit.propTypes = {
    loading: PropTypes.bool.isRequired,
    ownershipRoles: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
        }).isRequired,
    ).isRequired,
    lang: PropTypes.shape({
        ownerEdit: PropTypes.string.isRequired,
        backButton: PropTypes.string.isRequired,
        roleDetail: PropTypes.string.isRequired,
        educationalRole: PropTypes.string.isRequired,
        saveChanges: PropTypes.string.isRequired,
        selectRole: PropTypes.string.isRequired,
        errorGetOwnerCoursePermissions: PropTypes.string.isRequired,
        errorUpdateOwnerCourseRole: PropTypes.string.isRequired,
    }).isRequired,
    owner: PropTypes.shape({
        id: PropTypes.string.isRequired,
        UserHasOwnershipRoles: PropTypes.array.isRequired,
        name: PropTypes.string,
        surname: PropTypes.string,
    }).isRequired,
    onClickUpdateOwnershipRole: PropTypes.func.isRequired,
}

export default OwnerEdit
