import { 
    enableApiManagement, 
    allowApiCreate, 
    allowBaseCourseModifyUnitLesson, 
    allowBaseCourseUpdateUnitLesson,
    allowBaseCourseModifyTopicBlock,
    allowChildCourseModifyUnitLesson,
    allowChildCourseUpdateUnitLesson,
    allowChildCourseModifyTopicBlock,
    disableLessonDates,
    disableUnitDates,
} from '@/helpers/granters/courseTypeRules'

const courseIsBase = (course) => course.base

const courseHasParent = (course) => course.parent_id

export const courseHasApiManagementRule = (course) => enableApiManagement(course.CourseType)

export const hideUnitsAndLessonsActions = (course) =>
    courseHasApiManagementRule(course) || (!courseIsBase(course) && courseHasParent(course))

export const showUnitsAndLessonsActions = (course) =>
    (courseIsBase(course) && allowBaseCourseModifyUnitLesson(course.CourseType)) ||
    (!courseIsBase(course) && allowChildCourseModifyUnitLesson(course.CourseType))

export const showUnitsAndLessonsEditActions = (course) =>
    (courseIsBase(course) && allowBaseCourseUpdateUnitLesson(course.CourseType)) ||
    (!courseIsBase(course) && allowChildCourseUpdateUnitLesson(course.CourseType))

export const enableLessonDateInputs = (course) =>
    !courseIsBase(course) &&
    !disableLessonDates(course.CourseType)

export const enableUnitDateInputs = (course) =>
    !courseIsBase(course) &&
    !disableUnitDates(course.CourseType)

export const showTopicsAndBlocksActions = (course) => 
    (courseIsBase(course) && allowBaseCourseModifyTopicBlock(course.CourseType)) ||
    (!courseIsBase(course) && allowChildCourseModifyTopicBlock(course.CourseType))

export const hideChangeVisibility = (course) =>
    courseHasApiManagementRule(course) && courseIsBase(course)
