import * as service from '../../http/services/studentService'
import { getLoggedUserId } from '../../selectors/auth'
import {
    getContentMapStart,
    getContentMapSuccess,
    getContentMapError,
    getTopicContentStart,
    getTopicContentSuccess,
    getTopicContentError,
} from '../../reducers/student/classroom'

export const getContentMap = ({ courseId, unitId }) => async (dispatch, getState) => {
    dispatch(getContentMapStart())
    const userId = getLoggedUserId(getState())

    const [error, data] = await service.getContentMap({ userId, courseId, unitId })

    if (error) {
        dispatch(getContentMapError(error))
    } else {
        dispatch(getContentMapSuccess({
            ...data,
            userRoles: getState().authReducer.user.roles
        }))
    }
}

export const getTopicContent = ({ courseId, unitId, lessonId, topicId }) => async (
    dispatch,
    getState,
) => {
    dispatch(getTopicContentStart())
    const userId = getLoggedUserId(getState())

    const [error, data] = await service.getTopicContent({
        userId,
        courseId,
        unitId,
        lessonId,
        topicId,
    })

    if (error) {
        dispatch(getTopicContentError(error))
    } else {
        dispatch(getTopicContentSuccess(data))
    }
}

export const loadEggWidgetToken = (userId, courseId) => async () => {
    const [error, response] = await service.getEggWidgetToken(userId, courseId)

    if (error) return

    return response?.token
}