import httpClient from '@/redux/http/httpClient'
import { setTenantSession } from './helpers'
import { TENANT_API_URL } from '@/config/env'

export default () => {
    return new Promise(async (resolve) => {
        const api = httpClient('', TENANT_API_URL, { secured: false, useVersion: false })

        const [_, tenant] = await api.get(`/host/${encodeURIComponent(window.location.origin)}`)

        setTenantSession(tenant)

        resolve()
    })
}
