export const MAX_ACTIVITY_CHARS = 1500
export const MAX_EXTERNAL_ACTIVITY_CHARS = 300
export const EXPIRATION_STATUS = {
    ALIVE: 'ALIVE',
    EXPIRED: 'EXPIRED',
    UNKOWN: 'UNKOWN',
}
export const REVIEW_STATUS = {
    IDLE: 'IDLE',
    PENDING_FEEDBACK: 'PENDING_FEEDBACK',
    APPROVED: 'APPROVED',
    PENDING_RETRY: 'PENDING_RETRY',
    FAILED: 'FAILED',
}

export const ACTIVITY_TYPE = {
    INTERNAL: 'INTERNAL',
    EXTERNAL: 'EXTERNAL',
}
