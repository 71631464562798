const initialState = []

export const SET_PROGRESS_QUIZ_ANSWER = '@@classroom.quizzes/SET_PROGRESS_QUIZ_ANSWER'
export const UPDATE_PROGRESS_QUIZ_ANSWER_TEXT =
    '@@classroom.quizzes/UPDATE_PROGRESS_QUIZ_ANSWER_TEXT'
export const UPDATE_PROGRESS_QUIZ_ANSWER_SINGLE_CHOICE =
    '@@classroom.quizzes/UPDATE_PROGRESS_QUIZ_ANSWER_SINGLE_CHOICE'
export const UPDATE_PROGRESS_QUIZ_ANSWER_MULTIPLE_CHOICE =
    '@@classroom.quizzes/UPDATE_PROGRESS_QUIZ_ANSWER_MULTIPLE_CHOICE'
export const CLEAR_PROGRESS_QUIZ_ANSWERS = '@@classroom.quizzes/CLEAR_PROGRESS_QUIZ_ANSWERS'
export const SET_PROGRESS_QUIZ_ALL_ANSWERS =
    '@@classroom.quizzes/SET_PROGRESS_QUIZ_ALL_ANSWERS'

export const setProgressQuizAnswer = (answer, quizId, blockId) => ({
    type: SET_PROGRESS_QUIZ_ANSWER,
    payload: { answer, quizId, blockId },
})

export const setProgressQuizAllAnswers = (answers, blockId) => ({
    type: SET_PROGRESS_QUIZ_ALL_ANSWERS,
    payload: { answers, blockId },
})

export const updateProgressQuizAnswerText = (questionId, text, quizId, blockId) => ({
    type: UPDATE_PROGRESS_QUIZ_ANSWER_TEXT,
    payload: { questionId, text, quizId, blockId },
})

export const updateProgressQuizAnswerSingleChoice = (questionId, optionId, quizId, blockId) => ({
    type: UPDATE_PROGRESS_QUIZ_ANSWER_SINGLE_CHOICE,
    payload: { questionId, optionId, quizId, blockId },
})

export const updateProgressQuizAnswerMultipleChoice = (
    questionId,
    optionId,
    isSelected,
    quizId,
    blockId
) => ({
    type: UPDATE_PROGRESS_QUIZ_ANSWER_MULTIPLE_CHOICE,
    payload: { questionId, optionId, isSelected, quizId, blockId },
})

const getQuiz = (state, blockId) => state.find((quiz) => quiz.blockId == blockId)

const getAnswer = (answers, questionId) => answers.find((answer) => answer.questionId == questionId)

const filterQuizzes = (state, blockId) => state.filter((quiz) => quiz.blockId != blockId)

const newState = (quiz, questionId, quizId, answer, payload) => {
    return {
        ...quiz,
        quizId,
        answers: [
            ...quiz.answers.filter((answer) => answer.questionId !== questionId),
            { ...answer, ...payload },
        ],
    }
}

const quizzesReducer = (state = initialState, { type, payload }) => {
    const reducers = {
        [SET_PROGRESS_QUIZ_ANSWER]: () => {
            const quiz = getQuiz(state, payload.blockId)
            const exists = quiz?.answers.find(
                (answer) => answer.questionId === payload.answer.questionId
            )
            if (exists) {
                return [...state]
            }
            const answers =
                quiz?.answers.filter((answer) => answer.questionId !== payload.questionId) || []

            return [
                ...filterQuizzes([...state], payload.blockId),
                {
                    ...quiz,
                    quizId: payload.quizId,
                    blockId: payload.blockId,
                    answers: [...answers, payload.answer],
                },
            ]
        },
        [UPDATE_PROGRESS_QUIZ_ANSWER_TEXT]: () => {
            const quiz = getQuiz(state, payload.blockId)
            const answer = getAnswer(quiz.answers, payload.questionId)

            return [
                ...filterQuizzes([...state], payload.blockId),
                newState(quiz, payload.questionId, payload.quizId, answer, { text: payload.text }),
            ]
        },
        [UPDATE_PROGRESS_QUIZ_ANSWER_SINGLE_CHOICE]: () => {
            const quiz = getQuiz(state, payload.blockId)
            const answer = getAnswer(quiz.answers, payload.questionId)
            const options = [...answer.options].map((option) => {
                const selected = option.optionId === payload.optionId
                const notSelected = option.optionId !== payload.optionId
                return {
                    ...option,
                    selected: notSelected ? false : selected || option.selected,
                }
            })

            return [
                ...filterQuizzes([...state], payload.blockId),
                newState(quiz, payload.questionId, payload.quizId, answer, { options }),
            ]
        },
        [UPDATE_PROGRESS_QUIZ_ANSWER_MULTIPLE_CHOICE]: () => {
            const quiz = getQuiz(state, payload.blockId)
            const answer = getAnswer(quiz.answers, payload.questionId)
            const options = [...answer.options].map((option) => {
                return {
                    ...option,
                    selected:
                        option.optionId === payload.optionId ? payload.isSelected : option.selected,
                }
            })

            return [
                ...filterQuizzes([...state], payload.blockId),
                newState(quiz, payload.questionId, payload.quizId, answer, { options }),
            ]
        },
        [SET_PROGRESS_QUIZ_ALL_ANSWERS]: () => {
            const quiz = getQuiz(state, payload.blockId)
            return [
                ...filterQuizzes([...state], payload.blockId),
                {...quiz, answers: payload.answers}
            ]
        }
    }

    return reducers[type] ? reducers[type]() : state
}

export default quizzesReducer
