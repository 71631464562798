import {
    FORMAT_PDF,
    FORMAT_PPT,
    FORMAT_XLS,
    FORMAT_IMAGE,
    FORMAT_ZIP,
    FORMAT_BASIC,
} from '@digitalhouse-tech/react-lib-ui-explorer/lib/FilesUploader'

export const getIconType = (extension) => {
    const formats = {
        ['xls']: FORMAT_XLS,
        ['xlsx']: FORMAT_XLS,
        ['xcl']: FORMAT_XLS,
        ['jpg']: FORMAT_IMAGE,
        ['jpeg']: FORMAT_IMAGE,
        ['png']: FORMAT_IMAGE,
        ['gif']: FORMAT_IMAGE,
        ['pdf']: FORMAT_PDF,
        ['ppt']: FORMAT_PPT,
        ['pptx']: FORMAT_PPT,
        ['zip']: FORMAT_ZIP,
        ['rar']: FORMAT_ZIP,
        ['tar']: FORMAT_ZIP,
    }
    return formats[extension] || FORMAT_BASIC
}