import React from 'react'
import PropTypes from 'prop-types'
import { NotionRenderer } from 'react-notion-x'
import { Code } from 'react-notion-x/build/third-party/code'
import { Collection } from 'react-notion-x/build/third-party/collection'
import { Equation } from 'react-notion-x/build/third-party/equation'
import { Modal } from 'react-notion-x/build/third-party/modal'
import { Pdf } from 'react-notion-x/build/third-party/pdf'
import 'prismjs/themes/prism.css'
import './NotionPage.scss'

const NotionPage = ({ record, ...props }) => record ? <NotionRenderer
  recordMap={record}
  darkMode={false}
  components={{
    Code,
    Collection,
    Equation,
    Modal,
    Pdf
  }}
  {...props}
/> : null;

NotionPage.propTypes = {
  record: PropTypes.object.isRequired,
}

export default NotionPage