import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Topic from './Topics/TopicCard'
import ModalConfirmation from '@/components/ModalConfirmation'

import { showModalLessonEditAction } from '@/redux/reducers/_deprecated/modalsReducer'

import { actions as modalActions } from '@/redux/reducers/modalReducer';
import { TOPIC_MODAL, VISIBILITY_MODAL } from '@/constants/modals';

import {
    updateActiveCourseAction,
    toggleDragTopicAction,
    toggleDisableTopic,
    getCoursesToModify,
    loadAllGroupsByCourseId,
    loadActiveTopics
} from '../../../../../../redux/reducers/_deprecated/courseReducer'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import { Eye, EyeOff, Edit2, Move } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'
import {
    ORDER_TOPICS,
    CREATE_TOPICS,
    EDIT_LESSON_VISIBILITY,
    EDIT_LESSON_NAME,
    EDIT_LESSON_DATES,
} from '../../../../../../constants/coursePermissions'

import { checkPermissionsGroup } from '@digitalhouse-tech/ts-lib-store/lib/session-storage/permission'
import { formatConfig } from '@/helpers/dates/timezoneConfig'
import lessonTypes from '@/helpers/lessons/lessonTypes'
import { disableLessonCompleteDate } from '@/helpers/granters/courseTypeRules'
import {
    showUnitsAndLessonsEditActions,
    showTopicsAndBlocksActions,
    hideChangeVisibility,
} from '@/helpers/granters/hideMamushkaElements'

import CourseSkeleton from '../../Skeleton'
import '../styles/Card.scss'

import { Video, VideoOff } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'

export class LessonCard extends React.Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            items: [],
            renderTopics: false,
            panelBodyOpen: true,
        }

        this.openModalLessonDelete = this.openModalLessonDelete.bind(this)
        this.openModalLesson = this.openModalLesson.bind(this)
        this.openModalTopic = this.openModalTopic.bind(this)
        this.reorder = this.reorder.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)
        this.getTopics = this.getTopics.bind(this)
        this.showTopics = this.showTopics.bind(this)
        this.handleOrder = this.handleOrder.bind(this)
        this.renderPanelTitle = this.renderPanelTitle.bind(this)
        this.changeOrder = this.changeOrder.bind(this)
        this.toggleRenderTopics = this.toggleRenderTopics.bind(this)
        this.openModalVisibility = this.openModalVisibility.bind(this)
        this.formatDate = this.formatDate.bind(this)
        this.getIcon = this.getIcon.bind(this)
        this.getLessonTypeName = this.getLessonTypeName.bind(this)
    }

    handleOrder() {
        this.props.toggleDragTopicDisable()
    }

    changeOrder() {
        let data = {}
        data.courseId = this.props.courseId
        data.unitId = this.props.unitId
        data.lessonId = this.props.id
        data.activeCourse = this.props.activeCourse

        this.props.toggleDisableTopic(data)
        this.props.toggleDragTopicDisable()
    }

    openModalLessonDelete() {
        return this.modalLessonDelete.handleShow()
    }

    openModalTopic() {
        let topicData = { unitId: this.props.unitId, lessonId: this.props.id, name: '', id: null, visibility: true, order: null }
        let courseId = this.props.activeCourse.id
        if (this.props.activeCourse.base) {
            if (topicData.id) {
                this.props.getCoursesToModify(topicData.id, 'topic', courseId)
            } else if (topicData.lessonId) {
                this.props.getCoursesToModify(topicData.lessonId, 'lesson', courseId)
            }
        }
        this.props.openModal(TOPIC_MODAL, topicData)
    }

    openModalLesson() {
        this.props.loadAllGroupsByCourseId(this.props.activeCourse.id)
        let lessonData = { unitId: this.props.unitId, name: this.props.name, id: this.props.id, release_date: this.props.release_date, due_date: this.props.due_date, visibility: this.props.visibility, order: this.props.order, lesson_type_id: this.props.lesson_type_id, group_lesson: { id: this.props.group_lesson_id, ...this.props.GroupLesson } }
        this.props.showModalLesson(lessonData)
    }

    openModalVisibility() {
        let visibilityInfo = {
            courseId: this.props.courseId,
            unitId: this.props.unitId,
            lessonId: this.props.id,
            visibilityRoute: 'lessons',
            visibility: !this.props.visibility,
            modalText: this.props.lang.modalText
        }
        this.props.openModal(VISIBILITY_MODAL, visibilityInfo)
    }

    getTopics() {
        let course = this.props.activeCourse
        let unit = _.find(course.Units, { 'id': this.props.unitId })
        let lesson = _.find(unit.Lessons, { 'id': this.props.id })

        return lesson.Topics || []
    }

    showTopics() {
        if (!this.state.renderTopics) return null
        let course = this.props.activeCourse
        let unit = _.find(course.Units, { 'id': this.props.unitId })
        let lesson = _.find(unit.Lessons, { 'id': this.props.id })
        let topics = lesson.Topics

        if (!topics) {
            this.props.loadActiveTopics(course.id, unit.id, lesson.id)
            return <CourseSkeleton width='100%' height='100px' />
        }

        let resultTopics = Array()
        topics?.map((topic) => {
            if (topic.TopicRoles != undefined && topic.TopicRoles.length > 0) {
                topic.disableStudentVisibility = true
            }
            resultTopics.push(<Topic {...topic} key={topic.id} courseId={course.id} unitId={unit.id} lessonId={lesson.id} />)
        })

        return resultTopics
    }

    reorder(items, startIndex, endIndex) {
        let result = Array.from(items)
        let [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        let activeCourseCopy = _.cloneDeep(this.props.activeCourse)
        let unitAux = _.find(activeCourseCopy.Units, { 'id': this.props.unitId })
        let lessonAux = _.find(unitAux.Lessons, { 'id': this.props.id })

        let newTopics = this.reorder(
            lessonAux.Topics,
            result.source.index,
            result.destination.index
        )

        newTopics.map((item, index) => (
            item.order = index
        ))

        lessonAux.Topics = newTopics
        this.props.updateElementsOrder(activeCourseCopy)
    }

    toggleRenderTopics() {
        this.setState({ renderTopics: !this.state.renderTopics });
    }

    renderPanelTitle() {
        let title =
            <h3 className="__card_secondary_title"
                onClick={this.toggleRenderTopics}>
                {this.props.name}
            </h3>
        return this.props.dragDisableLessons ? <Panel.Toggle>{title}</Panel.Toggle> : title
    }

    formatDate(date) {
        const defaultFormat = '[:] DD/MM/YYYY'
        const hourFormat = ' HH:mm [hs]'
        const { CourseType } = this.props.activeCourse
        return formatConfig(
            date,
            defaultFormat,
            hourFormat,
            !disableLessonCompleteDate(CourseType)
        )
    }

    getLessonTypeName () {
        const type = this.props.lessonTypes.find((type) => type.id === this.props.lesson_type_id)
        return type?.name || null
    }

    getIcon() {
        const typeName = this.getLessonTypeName()
        if (!typeName) return null
        return (<Col md="1" className="_margin_top_10">
            {typeName === lessonTypes.SYNC ? <Video/> : <VideoOff/>}
        </Col>)
    }

    render() {
        return (
            <>
                <Panel className={`__card left-border--success`}>
                    <Panel.Heading>
                        <Row>
                            <Col md="9">
                                <Row>
                                    {this.getIcon()}
                                    <Col md="11">
                                        {this.renderPanelTitle()}
                                        <ul className="__card_detail">
                                            <li>
                                                {this.props.release_date ? 
                                                    <span>{this.props.lang.initialDate} {this.formatDate(this.props.release_date)}</span> : 
                                                    (this.props.lang.initialDate + ': ' + this.props.lang.notAvailable)}
                                            </li>|
                                            <li>
                                                {this.props.due_date ? 
                                                    <span>{this.props.lang.finishDate} {this.formatDate(this.props.due_date)}</span> : 
                                                    (this.props.lang.finishDate + ': ' + this.props.lang.notAvailable)}
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="3" className="__card_button_box __center_buttons_course">
                                <ButtonGroup>
                                { !hideChangeVisibility(this.props.activeCourse) &&
                                    <ButtonRound
                                        id="ShowVisibilityButton"
                                        icon={this.props.visibility ? <Eye /> : <EyeOff />}
                                        onClick={this.openModalVisibility}
                                        disabled={!(this.props.dragDisableTopics && this.props.dragGeneralDisable) || !EDIT_LESSON_VISIBILITY()}
                                        theme='info'
                                        tooltipId='visibilityLessonButton'
                                        tooltipContent={this.props.lang.changeVisibility}
                                    />
                                }
                                {
                                    showUnitsAndLessonsEditActions(
                                        this.props.activeCourse
                                    ) &&
                                    <ButtonRound
                                        id="OpenLessonModal"
                                        theme='primary_dark'
                                        icon={<Edit2 />}
                                        onClick={this.openModalLesson}
                                        disabled={!(this.props.dragDisableLessons && this.props.dragGeneralDisable)}
                                        tooltipId='editLessonButton'
                                        tooltipContent={this.props.lang.editLesson}
                                        visible={
                                            checkPermissionsGroup.oneOf([
                                                EDIT_LESSON_VISIBILITY,
                                                EDIT_LESSON_NAME,
                                                EDIT_LESSON_DATES,
                                            ])
                                        }
                                    />
                                }
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <Panel className={`__card __borderGray`}>
                            <Row className="__card_header">
                                <Col lg="2">
                                    <h3 className="__card_title __title_margin __font_weight_bold _cursor_pointer"
                                        onClick={() => {
                                            this.setState({ panelBodyOpen: !this.state.panelBodyOpen });
                                            this.toggleRenderTopics();
                                        }}>
                                        {this.props.lang.topics}
                                    </h3>
                                </Col>
                                { showTopicsAndBlocksActions(this.props.activeCourse) &&
                                    <Col lg="10" className="__card_button_box __center_buttons_course">
                                        <ButtonGroup>
                                            <Button
                                                id="SortButton"
                                                visible={ORDER_TOPICS()}
                                                text={this.props.dragDisableTopics ? this.props.lang.order : this.props.lang.finalize}
                                                icon={<Move />}
                                                leftIcon
                                                onClick={this.props.dragDisableTopics ? this.handleOrder : this.changeOrder}
                                                disabled={!this.props.dragGeneralDisable && this.props.dragDisableTopics}
                                                theme='tertiary'
                                            />
                                            <Button
                                                id="ModalTopicButton"
                                                visible={CREATE_TOPICS()}
                                                text={this.props.lang.createNewTopic}
                                                theme='tertiary'
                                                onClick={this.openModalTopic}
                                                disabled={!(this.props.dragDisableTopics && this.props.dragGeneralDisable)}
                                            />
                                        </ButtonGroup>
                                    </Col>
                                }
                            </Row>
                            {this.props.dragDisableTopics ? this.state.panelBodyOpen && this.showTopics() : <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId={'droppable-' + this.props.courseId + '-' + this.props.unitId + '-' + this.props.id}>
                                    {(provided) => (
                                        <div ref={provided.innerRef} >
                                            {this.getTopics().map((topic, index) => (
                                                <Draggable key={index} draggableId={topic.id} index={index} >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            style={{ backgroundColor: snapshot.isDraggingOver ? 'var(--primary)' : 'var(--disabled)' }}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Topic {...topic} key={topic.id} courseId={this.props.courseId} unitId={this.props.unitId} lessonId={this.props.id} />
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>}
                        </Panel>
                    </Panel.Body>
                </Panel>
                <ModalConfirmation
                    ref={(modalLessonDelete) => { this.modalLessonDelete = modalLessonDelete }}
                    confirmTitle={this.props.lang.delete}
                    cancelTitle={this.props.lang.cancel}
                    body={this.props.lang.confirm}
                    onConfirm={() => {/**Aun no se borra ninguna lesson**/ }}
                />
            </>
        )
    }
}

const mapDispatch = (dispatch) => ({
    updateElementsOrder: (data) => {
        dispatch(updateActiveCourseAction(data))
    },
    toggleDragTopicDisable: () => {
        dispatch(toggleDragTopicAction())
    },
    toggleDisableTopic: (data) => {
        dispatch(toggleDisableTopic(data))
    },
    showModalLesson: (lessonData) => {
        dispatch(showModalLessonEditAction(lessonData))
    },
    // showModalTopic: (topicData) => {
    //     dispatch(showModalTopicAction(topicData))
    // },
    // showModalVisibility: (data) => {
    //     dispatch(showModalVisibility(data))
    // },
    openModal: (id, data) => dispatch(modalActions.open(id, data)),
    getCoursesToModify: (id, type, courseId) => {
        dispatch(getCoursesToModify(id, type, courseId))
    },
    loadAllGroupsByCourseId: (courseId) => {
        dispatch(loadAllGroupsByCourseId(courseId))
    },
    loadActiveTopics: (courseId, unitId, lessonId) => {
        dispatch(loadActiveTopics(courseId, unitId, lessonId))
    },
})

const mapStateToProps = (state) => {
    return {
        // allCourses: state.courseReducer.allCourses,
        activeCourse: state.courseReducer.activeCourse,
        dragDisableLessons: state.courseReducer.dragDisableLessons,
        dragDisableTopics: state.courseReducer.dragDisableTopics,
        dragGeneralDisable: state.courseReducer.dragGeneralDisable,
        lessonTypes: state.courseReducer.lessonTypes.items,
        lang: {
            ...state.langStore.lesson,
            ...state.langStore.courseIndexButtons
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatch)(LessonCard))