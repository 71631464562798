import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from "moment"
import { CSVLink } from 'react-csv'
import {
    loadCoursesSurveys,
    showModalSurveyAction,
    editSurveyAction,
    loadAllSurveys,
    getReport,
    clearLoadedAction,
    clearCoursesModifiedAction,
    updateCourseSurvey
} from '../../../../redux/reducers/_deprecated/surveyReducer'
import { loadStudentsByCourse } from '@/redux/reducers/_deprecated/userReducer'
import { loadCoursePermissions } from '@/redux/reducers/_deprecated/authReducer'
import ModalSurvey from './ModalSurvey'
import { history } from '@/app/store'
import CourseSurvey from './CourseSurvey'
import CustomCargando from '@/components/CustomCargando'
import ModalSurveyConfirmation from './ModalSurveyConfirmation'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { GET_SURVEY_REPORT, EDIT_SURVEY, EDIT_SURVEY_VISIBILITY, CREATE_SURVEY } from '@/constants/coursePermissions'
import { getTenantLang } from '@/helpers/tenants/helpers'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'

moment.locale(getTenantLang())

export class SurveyIndex extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            activeCourseSurvey: null,
            showError: { error: false, message: '' }
        }

        this.getSurveys = this.getSurveys.bind(this)
        this.showModalSurvey = this.showModalSurvey.bind(this)
        this.handleGoBack = this.handleGoBack.bind(this)
        this.editSurvey = this.editSurvey.bind(this)
        this.openModalVisibility = this.openModalVisibility.bind(this)
        this.handleVisibility = this.handleVisibility.bind(this)
        this.getReportFunction = this.getReportFunction.bind(this)
        this.renderAlert = this.renderAlert.bind(this)
        this.removeAlert = this.removeAlert.bind(this)
    }

    componentDidMount() {
        let courseId = this.props.match.params.id
        
        this.props.loadCoursePermissions(courseId)
        this.props.clearLoaded()
        this.removeAlert()
        this.props.loadStudentsByCourse(courseId)
        this.props.loadSurveyData(courseId)
        this.props.loadAllSurveys()
    }

    handleGoBack() {
        history.goBack()
    }

    getSurveys() {
        if (!this.props.allCourseSurveys.isLoaded) {
            return
        }
        let allUnitsAux = this.props.allCourseSurveys.data
        let aux = Array()
        allUnitsAux.map((survey, index) => {
            aux.push(
                <CourseSurvey
                    lang={this.props.lang}
                    key={index}
                    survey={survey}
                    editSurvey={EDIT_SURVEY() ? this.editSurvey : null}
                    visibility={EDIT_SURVEY_VISIBILITY() ? this.openModalVisibility : null}
                    getReport={this.getReportFunction}
                    showReportButton={GET_SURVEY_REPORT()}
                />
            )
        })

        return aux
    }

    editSurvey(data) {
        let courseSurveyData = { nameSurvey: data.Survey.name, surveyId: data.Survey.id, courseId: this.props.match.params.id, id: data.id, date: data.date, visibility: data.visibility, mandatory: data.mandatory }
        this.props.editSurveyAction(courseSurveyData)
    }

    getReportFunction(courseId, courseSurveyId, courseName) {
        this.props.getReport(courseId, courseSurveyId, courseName)
    }

    showModalSurvey() {
        if (this.props.allSurveys) {
            this.props.showModal()
        }
        else {
            this.props.showModal()
        }
    }

    renderCreateButton() {
        return (
            <Button
                id="showModalSurveyButton"
                visible={CREATE_SURVEY()}
                onClick={this.showModalSurvey}
                text={this.props.lang.createSurvey}
                theme='tertiary'
            />
        )
    }

    openModalVisibility(data) {
        this.setState({
            ...this.state,
            activeCourseSurvey: data,
            showError: { error: false, message: '' }
        })

        return this.ModalSurveyConfirmation.handleShow()
    }

    handleVisibility() {
        let changeVisibility = true

        let courseId = this.props.match.params.id
        let date = this.state.activeCourseSurvey.date

        let courseSurveyData = {
            courseId,
            id: this.state.activeCourseSurvey.id,
            date: moment(this.state.activeCourseSurvey.date).utc().format("YYYY-MM-DD"),
            mandatory: this.state.activeCourseSurvey.mandatory,
            visibility: !this.state.activeCourseSurvey.visibility,
            base: this.props.location.state.courseBase
        }

        let allCourseSurveys = this.props.allCourseSurveys

        if (courseSurveyData.visibility) {
            if (date !== null && date !== '') {
                let dateAux = moment(date).utc().format("DD/MM/YYYY")

                allCourseSurveys.data.forEach(survey => {
                    if (moment(survey.date).utc().format("DD/MM/YYYY") == dateAux && courseSurveyData.visibility == survey.visibility) {
                        changeVisibility = false
                        this.setState({
                            ...this.state,
                            showError: { error: true, message: this.props.lang.cantChangeVisibilityWarning }
                        })
                    }
                })
            }
        }

        if (changeVisibility) {
            this.props.updateCourseSurvey(courseSurveyData, allCourseSurveys)
        }
    }

    removeAlert(e) {
        if (e) e.preventDefault()
        this.props.clearCoursesModifiedAction()
        this.setState({
            ...this.state,
            showError: { error: false, message: '' }
        })
    }

    renderAlert() {
        const { coursesModified, coursesNotModified } = { ...this.props }
        if ((coursesModified && coursesModified.length > 0) || (coursesNotModified && coursesNotModified.length > 0)) {
            let headers = [
                { label: this.props.lang.course, key: 'course' },
                { label: this.props.lang.modified, key: 'createOrEdit' },
            ]
            let yes = coursesModified.map(element => {
                return (
                    {
                        course: element.name,
                        createOrEdit: this.props.lang.yes,
                    }
                )
            })
            let no = coursesNotModified && coursesNotModified.map(element => {
                return {
                    course: element.name,
                    createOrEdit: this.props.lang.no,
                }
            })
            let reportData = [...yes, ...no]
            let date = moment(new Date()).format('YYYYMMDD_HHMMSS')
            let filename = date + ".csv"

            let alertText = `${this.props.lang.modifiedCourses}  ${coursesModified.length}. ${this.props.lang.notModifiedCourses} ${coursesNotModified.length}.`

            let alertTextDownload = this.props.lang.toDownloadDetailedReport

            return (
                <div className="toastA alert alert-success" ref="alert">
                    <a href="#" className="close" onClick={this.removeAlert.bind(this)}>&times;</a>
                    <h3>{this.props.lang.successfullyEdited}</h3><br />
                    <h3 className="alert-text">{alertText}</h3>
                    <CSVLink className="csvDownload"
                        headers={headers}
                        data={reportData}
                        filename={filename}
                        separator={";"}>
                        <strong>{`${this.props.lang.clickHere} `}</strong>
                    </CSVLink>
                    <h3>{alertTextDownload}</h3>
                </div>
            )
        }
        else {
            return null
        }
    }

    render() {

        if (!this.props.allCourseSurveys.isLoaded && this.props.allCourseSurveys.data.length <= 0) {
            return (<React.Fragment>
                <CustomCargando />
            </React.Fragment>)
        }
        else if (this.props.allCourseSurveys.isLoaded && this.props.allCourseSurveys.data.length <= 0) {
            return (
                <div className="container">
                    <div className="__main_header">
                        <Row>
                            <Col lg="8">
                                <h1 className="__main_title">{this.props.lang.surveyTitle}</h1>
                            </Col>
                            <Col lg="4">
                                <div className="_btn_group pull-right">
                                    {CREATE_SURVEY() && this.renderCreateButton()}
                                    <ModalSurvey base={this.props.location.state.courseBase} count={this.props.courseChildren} courseId={this.props.match.params.id} />
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Panel className="__card __fixedHeight">
                        <Row>
                            <Col lg="12">
                                <p className="text-center _margin_top_20"><strong>{this.props.lang.noContentAvailable}</strong></p>
                            </Col>
                        </Row>
                    </Panel>

                    <Row>
                        <Col lg="12">
                            <div className="_btn_group pull-right">
                                <Button
                                    id="handleGoBackButton"
                                    theme="secondary"
                                    onClick={this.handleGoBack}
                                    text={this.props.lang.goBackButton}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
        else {
            return (
                <div className="container">
                    <div className="__main_header">
                        {this.renderAlert()}
                        <Row>
                            <Col lg="8">
                                <h1 className="__main_title">{this.props.lang.surveyTitle}</h1>
                            </Col>

                            {this.state.showError.error ? <div className="toastA alert alert-danger" ref="alert">
                                <a href="#" className="close" onClick={this.removeAlert.bind(this)}>&times;</a>
                                <h3>{this.state.showError.message}</h3>
                            </div> : ''}

                            <Col lg="4">
                                <div className="_btn_group pull-right">
                                    {CREATE_SURVEY() && this.renderCreateButton()}
                                    <ModalSurvey
                                        base={this.props.location.state.courseBase}
                                        count={this.props.courseChildren}
                                        courseId={this.props.match.params.id}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        <Col lg="12">
                            {this.getSurveys()}
                            <ModalSurveyConfirmation
                                ref={(ModalSurveyConfirmation) => { this.ModalSurveyConfirmation = ModalSurveyConfirmation }}
                                confirmTitle={this.props.lang.confirmTitle}
                                cancelTitle={this.props.lang.cancelTitle}
                                body={this.props.lang.modalBody}
                                base={this.props.location.state.courseBase}
                                count={this.props.courseChildren}
                                showError={this.state.showError}
                                onConfirm={() => this.handleVisibility()}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <div className="_btn_group pull-right">
                                <Button
                                    id="handleGoBackButton"
                                    theme="secondary"
                                    onClick={this.handleGoBack}
                                    text={this.props.lang.goBackButton}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }

    }
}

const mapDispatch = (dispatch) => ({
    loadSurveyData: (courseId) => {
        dispatch(loadCoursesSurveys(courseId))
    },
    showModal: () => {
        dispatch(showModalSurveyAction())
    },
    editSurveyAction: (data) => {
        dispatch(editSurveyAction(data))
    },
    loadAllSurveys: () => {
        dispatch(loadAllSurveys())
    },
    getReport: (courseId, courseSurveyId, courseName) => {
        dispatch(getReport(courseId, courseSurveyId, courseName))
    },
    clearLoaded: () => {
        dispatch(clearLoadedAction())
    },
    loadStudentsByCourse: (courseId) => {
        dispatch(loadStudentsByCourse(courseId))
    },
    updateCourseSurvey: (courseSurveyData, allCourseSurveys) => {
        dispatch(updateCourseSurvey(courseSurveyData, allCourseSurveys))
    },
    clearCoursesModifiedAction: () => dispatch(clearCoursesModifiedAction()),
    loadCoursePermissions: courseId => {
        dispatch(loadCoursePermissions(courseId))
    }

})

const mapStateToProps = (state, ownProps) => {
    return {
        allCourseSurveys: state.surveyReducer.allCourseSurveys,
        allSurveys: state.surveyReducer.allSurveys,
        allCourseStudents: state.userReducer.allCourseStudents.students,
        courseSurvey: state.surveyReducer.courseSurvey,
        courseChildren: state.surveyReducer.courseChildren,
        coursesModified: state.surveyReducer.coursesModified,
        coursesNotModified: state.surveyReducer.coursesNotModified,
        lang: state.langStore.surveyIndex,
    }
}


export default withRouter(connect(mapStateToProps, mapDispatch)(SurveyIndex))