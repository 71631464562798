import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { updateUser } from '@/redux/reducers/_deprecated/authReducer'
import { closeModalPasswordAction } from '@/redux/reducers/_deprecated/modalsReducer'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import InputPassword from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputPassword'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

export class ModalStudentPassword extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            password: { error: false, message: '', value: '' },
            passwordConf: { error: false, message: '', value: '' },

            capsLockState: false,
        }

        this.handleClose = this.handleClose.bind(this)
        this.savePassword = this.savePassword.bind(this)

        this.showCapsLockMsg = this.showCapsLockMsg.bind(this)
        this.setPassword = this.setPassword.bind(this)
    }

    componentDidMount() {
        document.onkeypress = (e) => this.showCapsLockMsg(e)
    }

    handleClose() {
        this.props.closeModal()
    }

    validateInfo(password, passwordConf) {
        let passFormOk = true
        let newPassword = { error: false, message: '', value: password }
        let newPasswordConf = { error: false, message: '', value: passwordConf }

        if (password == null || password == '') {
            newPassword.error = true
            newPassword.message = this.props.lang.defineNewPassword
            passFormOk = false
        }
        if (passwordConf == null || passwordConf == '') {
            newPasswordConf.error = true
            newPasswordConf.message = this.props.lang.confirmNewPassword
            passFormOk = false
        }
        if (passFormOk && password != passwordConf) {
            newPasswordConf.error = true
            newPasswordConf.message = this.props.lang.incorrectConfirm
            passFormOk = false
        } else if (passFormOk) {
            newPassword.error = false
            newPassword.message = ''
        }

        this.setState({
            ...this.state,
            password: newPassword,
            passwordConf: newPasswordConf,
        })

        return passFormOk
    }

    async savePassword() {
        let password = this.state.password.value
        let passwordConf = this.state.passwordConf.value
        if (this.validateInfo(password, passwordConf)) {
            await updateUser(this.props.userId, password, passwordConf)
            this.handleClose()
        }
    }

    showCapsLockMsg(e) {
        e = e ? e : window.event
        let charCode = e.keyCode || e.which

        let isShiftOn = false
        if (e.shiftKey) {
            isShiftOn = e.shiftKey
        } else if (e.modifiers) {
            isShiftOn = !!(e.modifiers & 4)
        }

        if (charCode >= 65 && charCode <= 90 && !isShiftOn) {
            this.setState({
                ...this.state,
                capsLockState: true,
            })
        } else {
            this.setState({
                ...this.state,
                capsLockState: false,
            })
        }
    }

    setPassword(value, type) {
        this.setState((state) => ({
            ...state,
            [type]: { ...state[type], value },
        }))
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    className='__modal'
                    show={this.props.show}
                    onHide={this.handleClose}
                    keyboard={false}
                    backdrop='static'
                >
                    <Modal.Header closeButton className='__modal_header __border_bottom'>
                        <h3 className='__modal_title'>{this.props.lang.changePassword}</h3>
                    </Modal.Header>

                    <Modal.Body className='__modal_body'>
                        <Row className='centerText'>
                            <Col lg="12" md="4">
                                <InputPassword
                                    id='input-password'
                                    label={this.props.lang.newPassword}
                                    value={this.state.password.value}
                                    onChange={({ target: { value } }) => {
                                        this.setPassword(value, 'password')
                                    }}
                                    error={this.state.password.error}
                                    errorMsg={this.state.password.message}
                                    autoFocus
                                />

                                {this.state.capsLockState && (
                                    <p className='_input_capslock_msg'>
                                        {this.props.lang.blockMayusActive}
                                    </p>
                                )}
                            </Col>

                            <Col lg="12" md="4">
                                <InputPassword
                                    id='password-confirm'
                                    label={this.props.lang.confirmNewPassword}
                                    value={this.state.passwordConf.value}
                                    onChange={({ target: { value } }) => {
                                        this.setPassword(value, 'passwordConf')
                                    }}
                                    error={this.state.passwordConf.error}
                                    errorMsg={this.state.passwordConf.message}
                                    autoFocus
                                />

                                {this.state.capsLockState && (
                                    <p className='_input_capslock_msg'>
                                        {this.props.lang.blockMayusActive}
                                    </p>
                                )}
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer className='__modal_footer __border_top'>
                        <ButtonGroup className='pull-right'>
                            <Button
                                id='handleCloseButton'
                                onClick={this.handleClose}
                                text={this.props.lang.cancel}
                                theme='tertiary'
                            />
                            <Button
                                id='savePasswordButton'
                                theme='primary'
                                onClick={this.savePassword}
                                text={this.props.lang.save}
                            />
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatch = (dispatch) => ({
    closeModal: () => {
        dispatch(closeModalPasswordAction())
    },
})

const mapStateToProps = (state, ownProps) => {
    return {
        show: state.modalsReducer.showModalPassword.show,
        userId: state.modalsReducer.showModalPassword.userId,
        lang: state.langStore.modalStudentPassword,
    }
}

export default connect(mapStateToProps, mapDispatch, null, { forwardRef: true })(
    ModalStudentPassword
)
