import React from 'react'
import { connect } from 'react-redux'

import { Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import { isEmpty } from 'lodash/lang'

import { saveNewUnit, updateOneUnit, changeSavingUnitAction } from '@/redux/reducers/_deprecated/courseReducer'
import { isObjectEmpty } from '@/helpers/utils'
import { CustomFormField } from '@/components/CustomFormField'
import { allowApiCreate } from '@/helpers/granters/courseTypeRules'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

import { actions as modalActions } from '@/redux/reducers/modalReducer'
import { UNIT_MODAL } from '@/constants/modals'
import { EDIT_UNIT_NAME, EDIT_UNIT_DATES, EDIT_UNIT_DESCRIPTION, EDIT_UNIT_VISIBILITY, CREATE_UNITS } from '@/constants/coursePermissions'
import { timezoneConfig } from '@/helpers/dates/timezoneConfig'
import { dateFormat } from '@/helpers/dates/dateFormat'
import { disableUnitCompleteDate } from '@/helpers/granters/courseTypeRules'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import { enableUnitDateInputs } from '@/helpers/granters/hideMamushkaElements'

const [tz, locale] = timezoneConfig()
moment.locale(locale)

const EMPTY_UNIT = {
  name: '',
  description: '',
  release_date: null,
  visibility: true
}

const NO_ERROR = { error: null, message: '' }

const getModalData = ({ release_date, ...data }) => {
  if (isEmpty(data)) {
    return EMPTY_UNIT
  }

  return {
    ...data,
    release_date
  }
}

export class ModalUnit extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      isChecked: false,
      courseId: null,
      unit: getModalData(props.modalReducer.data),
      errorName: NO_ERROR
    }

    this.handleClose = this.handleClose.bind(this)
    this.datePick = this.datePick.bind(this)
    this.submitUnit = this.submitUnit.bind(this)
    this.checkPermissions = this.checkPermissions.bind(this)
  }

  componentDidMount() {
    this.props.changeSavingUnit(false)
  }

  handleClose() {
    this.props.closeModal()
  }

  datePick() {
    if (isObjectEmpty(this.state.unit) || !this.state.unit.release_date || this.state.unit.release_date === '') {
      return null
    } else {
      return moment(this.state.unit.release_date).tz(tz)
    }
  }

  validateInfo(name) {
    let unitFormOk = true
    let nameValue = { ...NO_ERROR }
    //Hay que agregar que valide un espacio en un string: " "
    if (typeof (name) === 'string') {
      name = name.trim()
    }
    if (name == null || name == '') {
      nameValue.error = 'error'
      nameValue.message = this.props.lang.requiredInputMessage
      unitFormOk = false
    } else {
      nameValue.error = null
      nameValue.message = ''
    }
    this.setState({
      ...this.state,
      errorName: nameValue
    })
    return unitFormOk
  }

  submitUnit() {
    const { unit: {
      id: unitId,
      name,
      order,
      visibility,
      release_date,
      description
    } } = this.state

    const { courseId, changeSavingUnit, saveNewUnit, activeCourse, updateOneUnit } = this.props

    let formValidation = this.validateInfo(name)
    const formattedReleaseDate = release_date
      ? moment(release_date).utc().format('YYYY-MM-DD HH:mm:ss')
      : null

    if (formValidation) {
      changeSavingUnit(true)
      if (unitId == null) {
        let saveInfo = { name, visibility, release_date: formattedReleaseDate, description, courseId }
        saveNewUnit(saveInfo, activeCourse)
      } else {
        let updateInfo = {
          unitId,
          name,
          order,
          visibility,
          release_date: formattedReleaseDate,
          description,
          courseId,
        }
        updateOneUnit(updateInfo, activeCourse)
      }
      this.handleClose()
    }
  }

  allowApiCreateRule(CourseType) {
    return (CourseType && allowApiCreate(CourseType))
  }

  handleInputChange(value, valueName) {

    if (valueName === 'release_date' && !isEmpty(value)) {
      value = moment(value)
    }

    this.setState({
      ...this.state,
      unit: {
        ...this.state.unit,
        [valueName]: value
      }
    })
  }

  checkPermissions(permissionType) {
    const { unit } = this.state

    const types = {
      'name': EDIT_UNIT_NAME,
      'description': EDIT_UNIT_DESCRIPTION,
      'dates': EDIT_UNIT_DATES,
      'visibility': EDIT_UNIT_VISIBILITY
    }

    switch (permissionType) {
      case 'dates':
        if (!unit.id)
          return CREATE_UNITS() && enableUnitDateInputs(this.props.activeCourse)
        return types[permissionType]() && enableUnitDateInputs(this.props.activeCourse)
      default:
        if (!unit.id) return CREATE_UNITS()
        return types[permissionType]()
    }
  }

  render() {
    if (!this.props.courseTypes.isLoaded) {
      return null
    }
    return (
      <>
        <Modal className="__modal" bsSize="large" show={this.props.modalReducer.id === UNIT_MODAL} onHide={this.handleClose} keyboard={false} backdrop="static">
          <Modal.Header closeButton className="__modal_header __border_bottom">
            <h3 className="__modal_title">{this.state.unit && this.state.unit.id ? this.props.lang.editUnitTitle : this.props.lang.createUnitTitle}</h3>
          </Modal.Header>

          <Modal.Body className="__modal_body">
            {this.checkPermissions('name') &&
              <Row>
                <Col lg="12">
                  <Label text={this.props.lang.nameLabel} />
                  <InputText
                    id="unitName"
                    value={this.state.unit.name}
                    onChange={evt => this.handleInputChange(evt.target.value, 'name')}
                    error={this.state.errorName.error === 'error'}
                    errorMsg={this.state.errorName.message}
                  />
                </Col>
              </Row>
            }
            {this.checkPermissions('description') &&
              <Row style={{ marginTop: '20px' }}>
                <Col md="12">
                  <Label text={this.props.lang.descriptionLabel} />
                  <InputText
                    id="unitDescription"
                    value={this.state.unit.description}
                    onChange={evt => this.handleInputChange(evt.target.value, 'description')}
                  />
                </Col>
              </Row>
            }
            <Row style={{ marginTop: '20px' }}>
              {this.checkPermissions('dates') &&
                <Col md="6">
                  <CustomFormField
                    bsSize="small"
                    controlId="release_date"
                    label={this.props.lang.initialDateLabel}
                    inputComponent={
                      <DatePicker
                        className="form-control input-sm datePickerWidth"
                        dateFormat={dateFormat(!disableUnitCompleteDate(this.props.activeCourse.CourseType))}
                        disabled={!enableUnitDateInputs(this.props.activeCourse)}
                        selected={this.datePick()}
                        onChange={date => this.handleInputChange(date, 'release_date')}
                        placeholderText={this.props.lang.selectDate}
                        showTimeSelect={!disableUnitCompleteDate(this.props.activeCourse.CourseType)}
                      />
                    }
                  />
                </Col>
              }
              {this.checkPermissions('visibility') &&
                <Col md="6">
                  <Label text={this.props.lang.isVisibleLabel} />
                  <InputComboBox
                    name="visibility"
                    placeholder={this.props.lang.select}
                    id="visibility"
                    key="visibility"
                    dropdownItems={[this.props.lang.yes, this.props.lang.no]}
                    onChange={(item) => this.handleInputChange(!!(item === this.props.lang.yes), 'visibility')}
                    value={this.state.unit.id ? (this.state.unit.visibility ? this.props.lang.yes : this.props.lang.no) : (this.state.unit.visibility ? this.props.lang.yes : this.props.lang.no)}
                  />
                </Col>
              }
            </Row>

          </Modal.Body>

          <Modal.Footer className="__modal_footer __border_top">
            <ButtonGroup className="pull-right">
              <Button
                id="CloseButton"
                onClick={this.handleClose}
                text={this.props.lang.cancelButton}
                theme='tertiary'
                disabled={this.props.savingUnit}
              />
              <Button
                id="SaveButton"
                text={this.props.lang.saveButton}
                theme='primary'
                onClick={this.submitUnit}
                loading={this.props.savingUnit}
                disabled={this.props.savingUnit}
              />
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

const mapDispatch = dispatch => ({
  saveNewUnit: (data, activeCourse) => {
    dispatch(saveNewUnit(data, activeCourse))
  },
  updateOneUnit: (data, activeCourse) => {
    dispatch(updateOneUnit(data, activeCourse))
  },
  openModal: (id, data) => dispatch(modalActions.open(id, data)),
  closeModal: () => dispatch(modalActions.close()),
  changeSavingUnit: (status) => {
    dispatch(changeSavingUnitAction(status))
  },
})

const mapStateToProps = state => ({
  activeCourse: state.courseReducer.activeCourse,
  modalReducer: state.modal,
  courseTypes: state.courseReducer.courseTypes,
  savingUnit: state.courseReducer.savingUnit,
  lang: state.langStore.modalUnit
})

export default connect(mapStateToProps, mapDispatch, null, { forwardRef: true })(ModalUnit)