import { BLOCK_NAMES } from '@/constants/blocks'

export const buildURL = ({ unit_id, lesson_id, topic_id, course_id }) =>
    `/course/${course_id}/unit/${unit_id}/lesson/${lesson_id}/topic/${topic_id}`

export const getBlockData = block =>
    BLOCK_NAMES.map(type => {
        if (!block[type]) {
            return false
        }

        return {
            blockType: type,
            blockData: block[type],
        }
    }).filter(Boolean)[0]
