import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import GuardRedirect from '../Layout/GuardRedirect'
import { getIsAuthenticated } from '@/redux/selectors/auth'
import Route from './Route'

const PublicRoute = ({
    exact,
    path,
    strict,
    component,
    layout,
}) => {
    const isAuthenticated = useSelector(getIsAuthenticated)
    const { firstRedirected } = useSelector(state => state.context)

    const itsStrictlyPublic = () => strict && isAuthenticated

    if (itsStrictlyPublic() && firstRedirected) {
        return <GuardRedirect />
    }

    return (
        <Route
            path={path}
            exact={exact}
            component={component}
            layout={layout}
        />
    )
}

PublicRoute.defaultProps = {
    exact: false,
    component: null,
    strict: true,
}

PublicRoute.propTypes = {
    exact: PropTypes.bool,
    path: PropTypes.string.isRequired,
    component: PropTypes.node,
    computedMatch: PropTypes.shape({
        params: PropTypes.shape({
            lang: PropTypes.string,
        }),
    }),
    layout: PropTypes.node.isRequired,
    strict: PropTypes.bool,
}

export default PublicRoute
