import React from 'react'
import PropTypes from 'prop-types'
import { Eye } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'

const ReadOnly = ({ readOnlySections, sectionName }) => {
    const show = readOnlySections.some(section => section === sectionName)
    if (!show) return null
    return (
        <span className='isRightIcon'>
            <Tooltip tooltipId={`${sectionName}-isReadonly`} tooltipContent='readOnly'>
                <Eye size={12} />
            </Tooltip>
        </span>
    )
}

ReadOnly.defaultProps = {
    readOnlySections: [],
}

ReadOnly.propTypes = {
    readOnlySections: PropTypes.arrayOf(PropTypes.string),
    sectionName: PropTypes.string.isRequired,
}

export default ReadOnly
