import React from 'react'
import PropTypes from 'prop-types'
import { atomAsset } from '@/assets/img/config'
import './EmptyState.scss'

const EmptyState = ({ title, subtitle, show }) => {

    if (!show) return null

    return (
        <div className='welcome-empty-state'>
            <img
                className='welcome-empty-state__image'
                src={atomAsset('character-waving.png')}
                alt='no-courses-empty-state'
            />
            <p className='welcome-empty-state__title'>{title}</p>
            <p className='welcome-empty-state__subtitle'>{subtitle}</p>
        </div>
    )
}

EmptyState.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    show: PropTypes.bool,
}

EmptyState.defaultProp = {
    title: '',
    subtitle: '',
    show: false,
}

export default EmptyState
