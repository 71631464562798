import React from 'react'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { Download } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import useTemplateButton from './useTemplateButton'
import { getTenantLang } from '@/helpers/tenants/helpers'

import './TemplateButton.scss'

const TemplateButton = () => {
    const { text, downloadTemplateLink, loading } = useTemplateButton()

    return (
        <Button
            id='templateButton'
            className='tempĺate-button'
            theme='tertiary'
            langCountry={getTenantLang()}
            text={text}
            onClick={downloadTemplateLink}
            leftIcon
            icon={<Download />}
            loading={loading}
            disabled={loading}
        />
    )
}

export default TemplateButton
