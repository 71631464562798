import { REVIEW_STATUS } from '../helpers/constants'

export default (
    { congrats, teacherDeniedActivity, notApproved, notCompleted, pendingReview },
    evaluableProgress
) => {
    const { APPROVED, FAILED, PENDING_RETRY, PENDING_FEEDBACK, IDLE } = REVIEW_STATUS
    const currentReviewStatus = evaluableProgress?.lastStatus.status || IDLE

    const showReviewMessage = () =>
        currentReviewStatus !== PENDING_FEEDBACK && currentReviewStatus !== IDLE

    const reviewTypes = {
        [APPROVED]: ['success', congrats, APPROVED],
        [FAILED]: ['warning', notApproved, FAILED],
        [PENDING_RETRY]: ['warning', teacherDeniedActivity, PENDING_RETRY],
        [PENDING_FEEDBACK]: ['', pendingReview, PENDING_FEEDBACK],
        [IDLE]: ['', notCompleted, IDLE],
    }

    return [...reviewTypes[currentReviewStatus], showReviewMessage()]
}
