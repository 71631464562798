import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import { updateUser } from '@/redux/reducers/_deprecated/authReducer'
import InputPassword from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputPassword'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'

export class PasswordModal extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            show: false,
            password: { error: false, message: '', value: '' },
            passwordConf: { error: false, message: '', value: '' },

            capsLockState: false,
        }

        this.nowHandleShow = this.nowHandleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.savePassword = this.savePassword.bind(this)

        this.showCapsLockMsg = this.showCapsLockMsg.bind(this)
        this.setPassword = this.setPassword.bind(this)
    }

    handleClose() {
        this.setState({ ...this.state, show: false })
    }

    nowHandleShow() {
        document.onkeypress = (e) => this.showCapsLockMsg(e)
        this.setState({ ...this.state, show: true })
    }

    validateInfo(password, passwordConf) {
        let passFormOk = true
        let newPassword = { error: false, message: '', value: password }
        let newPasswordConf = { error: false, message: '', value: passwordConf }

        if (password == null || password == '') {
            newPassword.error = true
            newPassword.message = this.props.lang.defineNewPassword
            passFormOk = false
        }
        if (passwordConf == null || passwordConf == '') {
            newPasswordConf.error = true
            newPasswordConf.message = this.props.lang.confirmNewPassword
            passFormOk = false
        }
        if (passFormOk && password != passwordConf) {
            newPasswordConf.error = true
            newPasswordConf.message = this.props.lang.passwordsNotMatch
            passFormOk = false
        } else if (passFormOk) {
            newPassword.error = false
            newPassword.message = ''
        }

        this.setState({
            ...this.state,
            password: newPassword,
            passwordConf: newPasswordConf,
        })

        return passFormOk
    }

    savePassword() {
        let password = this.state.password.value
        let passwordConf = this.state.passwordConf.value
        if (this.validateInfo(password, passwordConf)) {
            updateUser(this.props.userId, password, passwordConf, null)
            this.handleClose()
        }
    }

    showCapsLockMsg(e) {
        e = e ? e : window.event
        let charCode = e.keyCode || e.which

        let isShiftOn = false
        if (e.shiftKey) {
            isShiftOn = e.shiftKey
        } else if (e.modifiers) {
            isShiftOn = !!(e.modifiers & 4)
        }

        if (charCode >= 65 && charCode <= 90 && !isShiftOn) {
            this.setState({
                ...this.state,
                capsLockState: true,
            })
        } else {
            this.setState({
                ...this.state,
                capsLockState: false,
            })
        }
    }

    setPassword(value, type) {
        this.setState((state) => ({
            ...state,
            [type]: { ...state[type], value },
        }))
    }

    render() {
        return (
            <>
                <Modal
                    className='__modal'
                    show={this.state.show}
                    onHide={this.handleClose}
                    keyboard={false}
                    backdrop='static'
                >
                    <Modal.Header closeButton className='__modal_header __border_bottom'>
                        <h3 className='__modal_title'>{this.props.lang.changePassword}</h3>
                    </Modal.Header>

                    <Modal.Body className='__modal_body'>
                        <Row className='centerText'>
                            <Col lg="12">
                                <Label
                                    htmlFor='input-password'
                                    text={this.props.lang.newPassword}
                                />
                                <InputPassword
                                    id='input-password'
                                    value={this.state.password.value}
                                    onChange={({ target: { value } }) => {
                                        this.setPassword(value, 'password')
                                    }}
                                    error={this.state.password.error}
                                    errorMsg={this.state.password.message}
                                />

                                {this.state.capsLockState && (
                                    <p className='_input_capslock_msg'>
                                        {this.props.lang.lockUpperCase}
                                    </p>
                                )}
                            </Col>
                            <Col lg="12">
                                <Label
                                    htmlFor='password-confirm'
                                    text={this.props.lang.confirmPassword}
                                />
                                <InputPassword
                                    id='password-confirm'
                                    value={this.state.passwordConf.value}
                                    onChange={({ target: { value } }) => {
                                        this.setPassword(value, 'passwordConf')
                                    }}
                                    error={this.state.passwordConf.error}
                                    errorMsg={this.state.passwordConf.message}
                                />

                                {this.state.capsLockState && (
                                    <p className='_input_capslock_msg'>
                                        {this.props.lang.lockUpperCase}
                                    </p>
                                )}
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer className='__modal_footer __border_top'>
                        <ButtonGroup className='pull-right'>
                            <Button
                                id='CloseButton'
                                onClick={this.handleClose}
                                text={this.props.lang.cancel}
                                theme='tertiary'
                            />
                            <Button
                                id='SaveButton'
                                theme='primary'
                                onClick={this.savePassword}
                                text={this.props.lang.save}
                            />
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    lang: state.langStore.passwordModal,
})

PasswordModal.defaultProps = {
    lang: {
        passwordModal: '',
        defineNewPassword: '',
        confirmNewPassword: '',
        passwordsNotMatch: '',
    }
}

PasswordModal.propTypes = {
    lang: PropTypes.shape({
        passwordModal: PropTypes.string,
        defineNewPassword: PropTypes.string,
        confirmNewPassword: PropTypes.string,
        passwordsNotMatch: PropTypes.string,
    }),
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(PasswordModal)