export const BASE_PRESENTEEISM = 90
export const BASE_CONTENT_VIEWED = 80
export const BASE_APPROVED = 7

export const APPROVED = 'APPROVED'
export const DISAPPROVED = 'DISAPPROVED'

export const REDO = 'redo'
export const CHECKED = 'checked'
export const ALERT = 'alert'

export const WARNING = 'warning'
export const SUCCESS = 'success'


export const STATUS = {
    [APPROVED]: {
        theme: SUCCESS,
        status: CHECKED,
    },
    [DISAPPROVED]: {
        theme: WARNING,
        status: ALERT,
    },
}

export const LEVEL_PLACEHOLDER = '{{LEVEL}}'
export const PERCENTAGE_PLACEHOLDER = '{{PERCENTAGE}}'

export const IS_BEGGINER_LIMIT = 7
export const IS_AVANCED_LIMIT = 9
export const IS_CHALLENGER_LIMIT = 10
