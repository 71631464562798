'use-strict'
import moment from 'moment'
import { timezoneConfig } from '@/helpers/dates/timezoneConfig'
import getUtc from '@/helpers/dates/getUtc'
import { FORMAT_TIME_DAY_MONTH, FORMAT_TIME_MONTH_NAME, HOUR_SUFIX } from '../constants/dashboard'

const [tz, locale] = timezoneConfig()
moment.locale(locale)

export const formatDate = (date, format) =>  getUtc(date).tz(tz).format(format)

export const checkHandInIsExpired = (releaseDate, limitDays, handInDate) => {
    const expireDate = getUtc(releaseDate).tz(tz).add(Number(limitDays), 'days')
    const handIn = getUtc(handInDate).tz(tz)
    const isExpired = handIn.diff(expireDate) >= 0

    return [expireDate, isExpired]
}

export const formatedExpiredMessage = (date = '', msg = '') => {
    const [day, hour] = moment(new Date(date)).format(FORMAT_TIME_DAY_MONTH).split(' ')
    return msg.replace('{{DAY}}', day).replace('{{HOURS}}', hour)
}

export const formatDateMonthName = (date) => {
    const [day, monthName, hour] = getUtc(date).tz(tz).format(FORMAT_TIME_MONTH_NAME).split(' ')
    const monthNameCapitalized = monthName.charAt(0).toUpperCase() + monthName.slice(1)
    return `${day} ${monthNameCapitalized} ${hour} ${HOUR_SUFIX}`
}
