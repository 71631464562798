import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch } from 'react-router-dom'
import StaticAlertContainer from '@/components/Alerts/StaticAlertContainer'
import GlobalAlerts from '@/components/GlobalAlerts'
import IdleTimer from '@/components/IdleTimer'
import IdleTimeModal from '@/components/IdleTimeModal'

import DeployingLandingPage from '@/components/Alerts/DeployingLandingPage'
import { ALERT_TYPES } from '@/constants/alerts'
import { incomingDeployment } from '@/redux/thunks/deploymentAlertThunks'
import Preloader from '@/components/Preloader'

import routes from '../routes'
import { PrivateRoute, PublicRoute } from '@/components/Route'
import { userAuthentication } from '@/redux/reducers/_deprecated/authReducer'
import useQuery from '@/hooks/useQuery'
import { getUserSession } from '@/helpers/users/helpers'
import { loadActiveUser } from '@/redux/reducers/_deprecated/userReducer'
import { getUser } from '@/redux/selectors/auth'

const App = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState()
    const query = useQuery()
    const { legal_disclaimer } = useSelector(getUser)
    const alertType = useSelector(({ deployment }) => deployment.static.type)
    const deployingLang = useSelector(
        ({ langStore }) => langStore.deployNotifications.deploying,
    )

    useEffect(() => {
        dispatch(incomingDeployment())
    }, [dispatch])

    useEffect(() => {
        const sessionUser = getUserSession();
        if (!sessionUser?.id) return
        setLoading(true)
        const redirect = query.get('redirect')
        const redirectGuard = query.get('redirectGuard')
        dispatch(userAuthentication({
            ...sessionUser,
            legal_disclaimer: sessionUser.legal_disclaimer || legal_disclaimer
        }, {
            redirect,
            redirectGuard
        }))
        dispatch(loadActiveUser(sessionUser.id))
        setLoading(false)
    }, [])

    if (loading) return <Preloader />
    if (alertType === ALERT_TYPES.DEPLOYING) return <DeployingLandingPage lang={deployingLang} />

    return (
        <>
            <Preloader />
            <IdleTimer />
            <IdleTimeModal />
            <GlobalAlerts />
            <StaticAlertContainer />
            <Switch>
                {routes.map(route => {
                    return route.secured ? <PrivateRoute {...route} /> : <PublicRoute {...route} />
                })}
            </Switch>
        </>
    )
}

export default App
