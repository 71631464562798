import React, { createRef, useState } from 'react'
import PropTypes from 'prop-types'
import Editor from './Editor/Editor'
import ExecutionTools from './ExecutionTools'
import showNewMessage from '@/redux/reducers/_deprecated/globalAlertsReducer/alertsControllers'
import ToastAlert from './ToastAlert'
import { offset, scrollTo } from '@/helpers/utils'
import Mode from './Mode'
import Header from './Header'
import Validations from './Validations'
import './Playground.scss'

const Playground = ({
    navigation,
    sections,
    readOnlySections,
    files,
    handleChange,
    handleChangeFileName,
    onValidate,
    onReset,
    show,
    logs,
    throws,
    id,
    lang,
    exercise,
    executing,
    solvedValidations,
    progress,
    canShowTopAlert,
    readOnly,
    handleChangeDefaultFile,
    isAdministration,
    allowFilesNameChange,
}) => {
    const [random, setRandom] = useState(0)
    const [showTopAlert, setShowTopAlert] = useState(false)
    const [activeFile, setActiveFile] = useState(null)

    const refs = {
        [`${id}RenderArea`]: createRef(),
        [`${id}Validations`]: createRef(),
    }

    const onExecute = () => {
        if (refs[`${id}RenderArea`]) {
            const top = offset(refs[`${id}RenderArea`].current).top - 100
            scrollTo(window, top)
        }
        setShowTopAlert(true)
        onValidate()
    }

    return (
        <>
            <ToastAlert
                show={canShowTopAlert && showTopAlert && !executing}
                hasError={solvedValidations.hasError}
                errorsText={lang.processedCodeButErrorThereAreErrors}
                reference={refs[`${id}Validations`]}
                moral={exercise.moral}
                noMoralText={lang.thereIsNoMoral}
                seeErrorsText={lang.seeErrors}
                onClickClose={setShowTopAlert}
            />
            <div className='blockContent'>
                <Header
                    hasProgress={!!progress}
                    completed={progress ? progress.completed : false}
                    hints={exercise.hints}
                    hintsText={lang.hints.giveMeAHint}
                    exerciseName={exercise.name}
                    exerciseStatement={exercise.statement}
                    showName={exercise.show_name}
                />
                <Validations
                    hasError={solvedValidations.hasError}
                    validations={solvedValidations.validations}
                    errorsText={lang.errors}
                    reference={refs[`${id}Validations`]}
                    throws={throws}
                />
                <Editor
                    navigation={navigation}
                    sections={sections}
                    readOnlySections={readOnlySections}
                    files={files}
                    handleChange={handleChange}
                    readOnly={readOnly}
                    activeFile={activeFile}
                    setActiveFile={setActiveFile}
                    handleChangeDefaultFile={handleChangeDefaultFile}
                    handleChangeFileName={handleChangeFileName}
                    isAdministration={isAdministration}
                    allowFilesNameChange={allowFilesNameChange}
                />
                <ExecutionTools
                    onExecute={() => {
                        setRandom(random + 1)
                        onExecute()
                    }}
                    executing={executing}
                    lang={lang}
                    onCopy={() => {
                        if (!window.isSecureContext) {
                            return showNewMessage({
                                type: 'info',
                                message: lang.cannotCopy,
                                isTemporal: true,
                            })
                        }
                        const file = files.find(
                            (file) =>
                                file.section === activeFile.section &&
                                file.name === activeFile.name
                        )
                        if (file) navigator.clipboard.writeText(file.content)
                    }}
                    onReset={onReset}
                    readOnly={readOnly}
                />
                <Mode
                    mode={show}
                    iframeScript={solvedValidations.joined}
                    executing={executing}
                    logs={logs}
                    throws={throws}
                    random={random}
                    setRandom={setRandom}
                    reference={refs[`${id}RenderArea`]}
                />
            </div>
        </>
    )
}

Playground.defaultProps = {
    id: 0,
    progress: null,
    canShowTopAlert: true,
    readOnly: false,
    navigation: [],
    readOnlySections: [],
    throws: [],
    logs: [],
    executing: false,
    handleChangeDefaultFile: () => {},
    onReset: () => {},
    isAdministration: false,
}

Playground.propTypes = {
    navigation: PropTypes.arrayOf(PropTypes.string.isRequired),
    sections: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    readOnlySections: PropTypes.arrayOf(PropTypes.string),
    files: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            content: PropTypes.string,
            language: PropTypes.string,
            section: PropTypes.string,
            defaultOpen: PropTypes.bool,
        })
    ).isRequired,
    handleChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    onReset: PropTypes.func,
    show: PropTypes.string.isRequired,
    logs: PropTypes.arrayOf(PropTypes.shape()),
    throws: PropTypes.arrayOf(PropTypes.shape()),
    id: PropTypes.number,
    lang: PropTypes.shape({
        processedCodeButErrorThereAreErrors: PropTypes.string.isRequired,
        thereIsNoMoral: PropTypes.string.isRequired,
        seeErrors: PropTypes.string.isRequired,
        hints: PropTypes.shape({
            giveMeAHint: PropTypes.string.isRequired,
        }).isRequired,
        errors: PropTypes.string.isRequired,
        cannotCopy: PropTypes.string.isRequired,
    }).isRequired,
    exercise: PropTypes.shape({
        moral: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        statement: PropTypes.string.isRequired,
        hints: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    executing: PropTypes.bool,
    solvedValidations: PropTypes.shape({
        hasError: PropTypes.bool.isRequired,
        validations: PropTypes.arrayOf(
            PropTypes.shape({
                error: PropTypes.bool,
                msg: PropTypes.string,
            })
        ).isRequired,
        output: PropTypes.arrayOf(PropTypes.shape()),
        joined: PropTypes.string.isRequired,
    }).isRequired,
    progress: PropTypes.shape({
        completed: PropTypes.number,
    }),
    canShowTopAlert: PropTypes.bool,
    readOnly: PropTypes.bool,
    handleChangeDefaultFile: PropTypes.func,
    isAdministration: PropTypes.bool,
}

export default Playground
