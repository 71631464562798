'use-strict'

import constants from './constants'

export default ({
    [constants.SET_ALL_EXERCISES]: payload => ({
        type: constants.SET_ALL_EXERCISES,
        payload
    }),
    [constants.SET_CURRENT_PAGE]: payload => ({
        type: constants.SET_CURRENT_PAGE,
        payload
    }),
    [constants.SET_FINDER_FIELD]: payload => ({ 
        type: constants.SET_FINDER_FIELD, 
        payload 
    }),
    [constants.SET_ALL_TAGS]: payload => ({ 
        type: constants.SET_ALL_TAGS, 
        payload 
    }),
    [constants.SET_ALL_EXERCISE_TYPES]: payload => ({ 
        type: constants.SET_ALL_EXERCISE_TYPES, 
        payload 
    }),
    [constants.SET_ALL_RULE_TYPES]: payload => ({ 
        type: constants.SET_ALL_RULE_TYPES, 
        payload 
    }),
    [constants.SET_REQUEST_LOADING]: (bool, loader) => ({ 
        type: constants.SET_REQUEST_LOADING, 
        payload: { bool, loader } 
    }),
    [constants.SET_PAGINATED_EXERCISES]: payload => ({ 
        type: constants.SET_PAGINATED_EXERCISES, 
        payload 
    }),
    [constants.SET_WORKING_EXERCISE]: payload => ({ 
        type: constants.SET_WORKING_EXERCISE, 
        payload 
    }),
    [constants.SET_SOLVED_VALIDATIONS]: payload => ({ 
        type: constants.SET_SOLVED_VALIDATIONS, 
        payload 
    }),
    [constants.SET_IF_EXERCISE_HAS_PROGRESS]: payload => ({ 
        type: constants.SET_IF_EXERCISE_HAS_PROGRESS, 
        payload 
    }),
    [constants.CLEAR_FINDER_FIELDS]: () => ({ 
        type: constants.CLEAR_FINDER_FIELDS 
    }),
    [constants.CLEAR_WORKING_EXERCISE]: () => ({ 
        type: constants.CLEAR_WORKING_EXERCISE 
    }),
    [constants.CLEAR_SOLVED_VALIDATIONS]: () => ({ 
        type: constants.CLEAR_SOLVED_VALIDATIONS 
    }),
    [constants.CLEAR_IF_EXERCISE_HAS_PROGRESS]: () => ({ 
        type: constants.CLEAR_IF_EXERCISE_HAS_PROGRESS 
    })
})