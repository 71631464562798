import React from 'react'
import PropTypes from 'prop-types'

import DropdownRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/DropdownRound'
import useCourseOptions from './useCourseOptions'

const CourseOptions = ({ cloneCourseHandler, courseInfo, disabled, dropdownId, textLang }) => {
    const options = useCourseOptions(cloneCourseHandler, courseInfo, textLang)
    return <DropdownRound disabled={disabled} id={dropdownId} items={options} />
}

CourseOptions.propTypes = {
    cloneCourseHandler: PropTypes.func.isRequired,
    courseInfo: PropTypes.object.isRequired,
    dropdownId: PropTypes.string,
    textLang: PropTypes.object.isRequired,
}

CourseOptions.defaultProps = {
    dropdownId: 'round-dropdown-options',
}

export default CourseOptions
