import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import './OwnerAssign.scss'

const OwnerAssignModal = ({
    showIncompleteModal,
    closeModalIncompleteAssign,
    leaveAssignHandler,
    lang
}) => {
    return (
        <>
            <Modal show={showIncompleteModal} onHide={closeModalIncompleteAssign}>
                <Modal.Header closeButton />
                <Modal.Body className="assign_modal_body">
                    <p>{lang.incompleteAssign}</p>
                    <p>{lang.incompleteAssignMsg}</p>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup className="pull-right">
                        <Button
                            id="cancel_assign_button"
                            theme="tertiary"
                            text={lang.cancel}
                            onClick={() => closeModalIncompleteAssign()}
                        />
                        <Button
                            id="leave_assign_button"
                            theme="primary"
                            text={lang.leaveProcess}
                            onClick={() => leaveAssignHandler()}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        </>
    )
}

OwnerAssignModal.propTypes = {
    showIncompleteModal: PropTypes.bool.isRequired,
    closeModalIncompleteAssign: PropTypes.func.isRequired,
    leaveAssignHandler: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
}

export default OwnerAssignModal
