import httpClient from '../httpClient'
import * as courseApi from '../api/courseApi'
import * as studentApi from '../api/studentApi'
import * as externalPlatformsApi from '../api/externalPlatformsApi'
import { getTenantLang } from '@/helpers/tenants/helpers'

import { tabs, remoteClasses } from '../api/bases'

const httpCourses = () => httpClient(courseApi.base)
const httpEnrolments = (userId, page = 1, limit = 4, filter = 'actives') => httpClient(`${studentApi.enrolments(userId)}?page=${page}&limit=${limit}${filter ? '&status='+filter : ''}`, undefined, { timezone: true, rawData: true })
const httpStudents = (userId) => httpClient(studentApi.students(userId))
const httpExternalPlatforms = () => httpClient(externalPlatformsApi.base)

export const howManyItemsCanBeRemovedFromChilds = (courseId, resourceName, resourceId) =>
    httpCourses().get(`/${courseId}/inheritance-diffs/${resourceName}/${resourceId}`)

export const loadAllStudentEnrolments = (userId, filter, page = 1, limit = 4) => httpEnrolments(userId, page, limit, filter).get('')

export const loadCourse = (courseId) => httpCourses().get(`/${courseId}`)

export const loadLastUserProgress = (userId, courseId) =>
    httpStudents(userId).get(`/courses/${courseId}/last-movement`)

export const getUserProgressPercent = (userId, courseId) =>
    httpStudents(userId).get(`/courses/${courseId}/user-progress/course-percent`)

export const getNextCourseLesson = (userId) =>
    httpStudents(userId).get(`/next-course-lesson`)

export const getRemoteClassesURL = (courseId) =>
    httpCourses().get(`/${courseId}/${tabs}/${remoteClasses}`)

export const getCourseInfo = (userId, courseId) =>
    httpStudents(userId).get(`/courses/${courseId}/info`)

export const refreshCourseCache = (courseId) => httpCourses().delete(`${courseId}/cache`)

export const getCourseTabs = (userId, courseId) =>
    httpStudents(userId).get(`/courses/${courseId}/navigation`)

export const loadCourseTools = ({ course_id }) => httpCourses().get(`/${course_id}/tools`)
export const createCourseTool = (courseId, tool) => httpCourses().post(`/${courseId}/tools`, tool)
export const updateCourseTool = (courseId, toolId, tool) =>
    httpCourses().patch(`/${courseId}/tools/${toolId}`, tool)
export const deleteCourseTool = (courseId, toolId) =>
    httpCourses().delete(`/${courseId}/tools/${toolId}`)

export const loadTab = (courseId, tabId) => httpCourses().get(`/${courseId}/tabs/${tabId}`)

export const loadExternalPlatforms = () => httpExternalPlatforms().get()

export const getCourseCertificate = (userId, courseId) =>
    httpStudents(userId).post(`/courses/${courseId}/enrolments/certificate?lang=${getTenantLang()}`)

export const hasAnyProgress = (courseId) => httpCourses().get(`/${courseId}/user-progress/count`)

export const setStarredDateCourse = (userId, courseId, data) =>
    httpStudents(userId).patch(`/courses/${courseId}/enrolments/starred-date`, data)
