import React from 'react'
import PropTypes from 'prop-types'

const NonSecuredLayout = ({ children, match, location, history }) => {
    return <>{children}</>
}

NonSecuredLayout.defaultProps = {
    match: {},
    location: {},
    history: {},
}

NonSecuredLayout.propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    children: PropTypes.node.isRequired,
}

export default NonSecuredLayout
