import service from './service'
import actions from './actions'
import filesSchemas from '@/helpers/exercises/filesSchemas'
import configureStore, { history } from '@/app/store'
import { htmlentities } from '@/helpers/utils'
import constants from './constants'

const controller = {
    loadAllExercises: async (attributes = {}) => {
        const { dispatch } = configureStore.store
        try {
            const exercises = await service.loadAllExercises(attributes)
            dispatch(actions[constants.SET_ALL_EXERCISES](exercises))
        } catch (e) { }
    },
    loadAllTags: async () => {
        const { dispatch } = configureStore.store
        try {
            const tags = await service.loadAllTags()
            dispatch(actions[constants.SET_ALL_TAGS](tags))
        } catch (e) { }
    },
    loadAllExerciseTypes: async () => {
        const { dispatch } = configureStore.store
        dispatch(actions[constants.SET_REQUEST_LOADING](true, 'loadAllExerciseTypes'))
        try {
            const types = await service.loadAllExerciseTypes()
            dispatch(actions[constants.SET_ALL_EXERCISE_TYPES](types))
        } catch (e) { }
        dispatch(actions[constants.SET_REQUEST_LOADING](false, 'loadAllExerciseTypes'))
    },
    loadPaginatedExercises: async () => {
        const { dispatch, getState } = configureStore.store
        const { finder, paginationConfig } = getState().exerciseManagerStore
        dispatch(actions[constants.SET_REQUEST_LOADING](true, 'loadPaginatedExercises'))
        try {
            const paginatedExercises = await service.loadPaginatedExercises(finder, paginationConfig)
            dispatch(actions[constants.SET_PAGINATED_EXERCISES](paginatedExercises))
        } catch (e) { }
        dispatch(actions[constants.SET_REQUEST_LOADING](false, 'loadPaginatedExercises'))
    },
    loadSingleExercise: async id => {
        const { dispatch } = configureStore.store
        dispatch(actions[constants.SET_REQUEST_LOADING](true, 'loadSingleExercise'))
        try {
            const exercise = await service.loadSingleExercise(id)
            const workingExercise = {
                ...exercise,
                tags: exercise.tags.map(tag => tag.name),
            }
            dispatch(actions[constants.SET_WORKING_EXERCISE](workingExercise))
        } catch (e) { }
        dispatch(actions[constants.SET_REQUEST_LOADING](false, 'loadSingleExercise'))
    },
    executePreview: async type => {
        const { dispatch, getState } = configureStore.store
        const state = getState()
        const files = state.exerciseManagerStore.workingExercise.files
        const rules = state.exerciseManagerStore.workingExercise.rules
        const userEmail = state.authReducer.user.username
        dispatch(actions[constants.SET_REQUEST_LOADING](true, 'executePreview'))
        try {
            const options = { type, userEmail }
            const data = { files: controller.encodeWorkingFiles(files), rules }
            const solvedValidations = await service.executePreview(data, options)
            dispatch(actions[constants.SET_SOLVED_VALIDATIONS](solvedValidations))
        } catch (e) { }
        dispatch(actions[constants.SET_REQUEST_LOADING](false, 'executePreview'))
    },
    createExercise: async () => {
        const { dispatch, getState } = configureStore.store
        const state = getState()
        const workingExercise = state.exerciseManagerStore.workingExercise
        dispatch(actions[constants.SET_REQUEST_LOADING](true, 'saveExercise'))
        try {
            workingExercise.files.map(file => {
                delete file._id
            })
            await service.createExercise(workingExercise)
            history.push('/admin/exercises')

        } catch (e) { }
        dispatch(actions[constants.SET_REQUEST_LOADING](false, 'saveExercise'))
    },
    updateExercise: async () => {
        const { dispatch, getState } = configureStore.store
        const state = getState()
        const workingExercise = state.exerciseManagerStore.workingExercise
        dispatch(actions[constants.SET_REQUEST_LOADING](true, 'saveExercise'))
        try {
            const updatedExercise = await service.updateExercise(workingExercise._id, workingExercise)
            const paginated = { ...state.exerciseManagerStore.paginated }
            const findedExercise = paginated.rows.find(exercise => exercise._id === updatedExercise._id)
            if (findedExercise) {
                for (const i in updatedExercise) {
                    findedExercise[i] = updatedExercise[i]
                }
            }
            dispatch(actions[constants.SET_PAGINATED_EXERCISES](paginated))
            history.push('/admin/exercises')

        } catch (e) { }
        dispatch(actions[constants.SET_REQUEST_LOADING](false, 'saveExercise'))
    },
    exerciseHasProgress: async id => {
        const { dispatch } = configureStore.store
        try {
            const hasProgress = await service.exerciseHasProgress(id)
            dispatch(actions[constants.SET_IF_EXERCISE_HAS_PROGRESS](hasProgress))
        } catch (e) { }
    },
    setWorkingExercise: (key, value) => {
        const { workingExercise } = configureStore.store.getState().exerciseManagerStore
        const modified = { ...workingExercise, [key]: value }
        configureStore.store.dispatch(actions[constants.SET_WORKING_EXERCISE](modified))
    },
    setFilesInWorkingExercise: (exerciseType, files = []) => {
        const { workingExercise } = configureStore.store.getState().exerciseManagerStore
        const workingFiles = controller.workingFiles(filesSchemas[exerciseType], files)
        const modified = { ...workingExercise, files: workingFiles }
        configureStore.store.dispatch(actions[constants.SET_WORKING_EXERCISE](modified))
    },
    workingFiles: (schemas, files = []) => {
        const workingFiles = schemas?.map(schema => {
            const receivedFile = files.find(file => file.name === schema.name && file.section === schema.section)
            return receivedFile ? receivedFile : schema
        }) ?? []
        return workingFiles
    },
    encodeWorkingFiles: (files = []) => {
        return files.map(file => ({ ...file, content: htmlentities.encode(file.content) }))
    },
    setFinderField: payload => {
        const { dispatch } = configureStore.store
        dispatch(actions[constants.SET_FINDER_FIELD](payload))
    },
    setCurrentPage: payload => {
        const { dispatch } = configureStore.store
        dispatch(actions[constants.SET_CURRENT_PAGE](payload))
    },
    clearFinderFields: () => {
        const { dispatch } = configureStore.store
        dispatch(actions[constants.CLEAR_FINDER_FIELDS]())
    },
    clearWorkingExercise: () => {
        const { dispatch } = configureStore.store
        dispatch(actions[constants.CLEAR_WORKING_EXERCISE]())
    },
    clearSolvedValidations: () => {
        const { dispatch } = configureStore.store
        dispatch(actions[constants.CLEAR_SOLVED_VALIDATIONS]())
    },
    clearIfExerciseHasProgress: () => {
        const { dispatch } = configureStore.store
        dispatch(actions[constants.CLEAR_IF_EXERCISE_HAS_PROGRESS]())
    },
    checkUsedName: async name => {
        const { dispatch } = configureStore.store
        dispatch(actions[constants.SET_REQUEST_LOADING](true, 'saveExercise'))
        try {
            const isNameUsed = await service.checkUsedName(name)
            if (isNameUsed) {
                dispatch(actions[constants.SET_REQUEST_LOADING](false, 'saveExercise'))
            }
            return isNameUsed
        } catch (e) {
            dispatch(actions[constants.SET_REQUEST_LOADING](false, 'saveExercise'))
        }

    },
    cloneExercise: async (id, name) => {
        const { dispatch, getState } = configureStore.store
        const state = getState()
        try {
            const clonedExercise = await service.cloneExercise(id, { name })
            const paginated = { ...state.exerciseManagerStore.paginated }
            paginated.rows.push(clonedExercise)
            dispatch(actions[constants.SET_PAGINATED_EXERCISES](paginated))
        } catch (e) {
            dispatch(actions[constants.SET_REQUEST_LOADING](false, 'saveExercise'))
        }
        dispatch(actions[constants.SET_REQUEST_LOADING](false, 'saveExercise'))
    },
}

export default controller