import React, { memo } from 'react'
import PropTypes from 'prop-types'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import LoadingOrContent from '@/components/LoadingOrContent'
import Breadcrumb from '@/components/Breadcrumb'
import ContentMap from './ContentMap'
import TopicContent from './TopicContent'
import Skeleton from '@/components/Skeleton'
import useClassroom from './useClassroom'
import useEgg from './useEgg'
import './Classroom.scss'

const Classroom = memo(({ location: { state: { showLastBlock } = {} } = {} }) => {
    const {
        Course,
        Lessons,
        contentMapLoading,
        courseId,
        isMobile,
        moduleNumber,
        nextUnit,
        prevUnit,
        showContenMap,
        showTopicContent,
        topicContentLoading,
        topicId,
        topic_content,
        unitName,
        isClassroomLoaded,
        isOwner,
    } = useClassroom()

    const { eggWidgetLoaded } = useEgg(courseId, Course?.has_egg)
    const courseAlias = Course?.alias || Course?.name

    const { ownerMessage, owner, withoutProgress, breadcrumb } = useSelector(
        ({ langStore: { progressReducer, studentUnits } }) => ({
            ...progressReducer,
            ...studentUnits,
        })
    )

    if (!breadcrumb) return null;

    return (
        <>
            {showContenMap && (
                <LoadingOrContent
                    loading={!isClassroomLoaded}
                    loadingComponent={
                        <div className='container classroom-skeleton'>
                            <Skeleton width='35%' left />
                        </div>
                    }
                >
                    <Breadcrumb
                        className='container classroom-breadcrumb'
                        crumbs={[
                            { name: breadcrumb.progress, url: `/course/${courseId}/units` },
                            { name: unitName },
                        ]}
                        courseAlias={courseAlias}
                    />
                </LoadingOrContent>
            )}
            {!!isOwner && (
                <div
                    className={cn(
                        { 'ownership-message--top': !showContenMap },
                        'ownership-message container'
                    )}
                >
                    <MessageBox
                        theme='info'
                        text={<strong>{ownerMessage}</strong>}
                        fullWidth
                    />
                </div>
            )}
            <div className='container'>
                <div className='classroom-container'>
                    {showContenMap && (
                        <ContentMap
                            moduleNumber={moduleNumber}
                            unitName={unitName}
                            course={Course}
                            lessons={Lessons}
                            prevUnit={prevUnit}
                            nextUnit={nextUnit}
                            isMobile={isMobile}
                            loading={contentMapLoading}
                        />
                    )}

                    {showTopicContent && (
                        <TopicContent
                            content={topic_content}
                            courseId={courseId}
                            topicId={topicId}
                            isMobile={isMobile}
                            loading={topicContentLoading}
                            showLastBlock={showLastBlock}
                            isClassroomLoaded={isClassroomLoaded}
                        />
                    )}
                </div>
            </div>
            {eggWidgetLoaded && <div id='eggContainer' />}
        </>
    )
})

Classroom.propTypes = {
    location: PropTypes.objectOf({
        state: PropTypes.objectOf({
            showLastBlock: PropTypes.bool,
        }),
    }),
}
Classroom.defaultProps = {
    location: { state: { showLastBlock: false } },
}

export default Classroom
