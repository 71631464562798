import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { COURSE_ENTITIES_DATA } from '@/constants/courseEntities'
import CourseReportUnitContent from './CourseReportUnitContent'
import CourseReportBlockContent from './CourseReportBlockContent'

const CourseReportContent = ({
    filteredCourseData,
    dataLevel,
    users,
    usersDetail,
    activeUnit,
    activeLesson,
    lang
}) => {

    const isTopicsLevel = dataLevel === COURSE_ENTITIES_DATA.TOPICS
    return (
        <>
            <div className="_report_content_cols" id="_report_content_cols">
                <div
                    className={cn({
                        _report_background_blocks: isTopicsLevel,
                        _report_background: users.length > 0
                    })}
                ></div>
                {filteredCourseData.map(courseItem => {
                    return (
                        <div
                            className={cn(
                                {
                                    _report_col_block: isTopicsLevel,
                                },
                                '_report_col',
                            )}
                            key={`detail_students_${courseItem.id}`}
                        >
                            {users.map(userItem => {
                                if (isTopicsLevel)
                                    return (
                                        <CourseReportBlockContent
                                            userItem={userItem}
                                            activeUnit={activeUnit}
                                            activeLesson={activeLesson}
                                            courseItem={courseItem}
                                            key={`detail_${userItem.id}`}
                                        />
                                    )

                                return (
                                    <CourseReportUnitContent
                                        userItem={userItem}
                                        usersDetail={usersDetail}
                                        dataLevel={dataLevel}
                                        courseItem={courseItem}
                                        activeUnit={activeUnit}
                                        key={userItem.id}
                                        lang={lang}
                                    />
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </>
    )
}

CourseReportContent.propTypes = {
    filteredCourseData: PropTypes.array.isRequired,
    dataLevel: PropTypes.string.isRequired,
    users: PropTypes.array.isRequired,
    usersDetail: PropTypes.array.isRequired,
    activeUnit: PropTypes.number,
    activeLesson: PropTypes.number,
    lang: PropTypes.object.isRequired,
}

export default CourseReportContent
