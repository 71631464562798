import React from 'react'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

const NotionForm = ({ id, disabled, onChange, onCloseIcon, label, value }) => {

  return <Row className='_margin_bottom_25'>
    <Col md="12">
      <Label text={label} />
      <InputText
        autoFocus
        deletable
        id={id}
        onChange={onChange}
        disabled={disabled}
        onCloseIcon={onCloseIcon}
        value={value}
      />
    </Col>
  </Row>
}

export default NotionForm