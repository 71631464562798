import httpClient from '../httpClient'
import * as userApi from '../api/usersApi'

const api = () => httpClient(userApi.users)

export const loadUsers = userDataSearch => {
    return api().get('/list'+userDataSearch)
}

export const uploadAvatar = (formData, userId) =>
    api().post(`/${userId}/avatar`, formData)