import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN_GUARD } from '@/constants/guards'
import { setGuard, setLocation } from '@/routes/guards'
import { ADMIN_LOCATION } from '@/constants/locations'
import { getUser } from '@/redux/selectors/auth'
import './BackofficeLandingPage.scss'

const BackofficeLandingPage = () => {
    useEffect(() => {
        setGuard(ADMIN_GUARD)
        setLocation(ADMIN_LOCATION)
    }, [])
    const { welcome, subText } = useSelector(({ langStore }) => langStore.landingPage)
    const { name } = useSelector(getUser)
    return (
        <div className='administration-landing'>
            <div>
                <h1 className='administration-landing__title'>
                    {welcome + name + '!'}
                    <br />
                </h1>
                <p className='administration-landing__subtitle'>{subText}</p>
            </div>
        </div>
    )
}

export default BackofficeLandingPage
