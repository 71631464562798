export const DISPLAY_BLOCK = 'block'
export const DISPLAY_NONE = 'none'

export const EXERCISE_BLOCK_MODAL = 'EXERCISE_BLOCK_MODAL'
export const FILE_BLOCK_MODAL = 'FILE_BLOCK_MODAL'
export const PDF_BLOCK_MODAL = 'PDF_BLOCK_MODAL'
export const QUIZ_BLOCK_MODAL = 'QUIZ_BLOCK_MODAL'
export const SLIDE_BLOCK_MODAL = 'SLIDE_BLOCK_MODAL'
export const TEXT_BLOCK_MODAL = 'TEXT_BLOCK_MODAL'
export const VIDEO_BLOCK_MODAL = 'VIDEO_BLOCK_MODAL'
export const TEXT_HTML_BLOCK_MODAL = 'TEXT_HTML_BLOCK_MODAL'
export const EVALUABLE_BLOCK_MODAL = 'EVALUABLE_BLOCK_MODAL'
export const NOTION_BLOCK_MODAL = 'NOTION_BLOCK_MODAL'

export const UNIT_MODAL = 'UNIT_MODAL'
export const LESSON_MODAL = 'LESSON_MODAL'
export const TOPIC_MODAL = 'TOPIC_MODAL'
export const VISIBILITY_MODAL = 'VISIBILITY_MODAL'

export const DELETE_TOPIC_WARNING_MODAL = 'DELETE_TOPIC_WARNING_MODAL'

export const DELETE_BLOCK_WARNING_MODAL = 'DELETE_BLOCK_WARNING_MODAL'
export const DELETE_BLOCK_CONFIRMATION_MODAL = 'DELETE_BLOCK_CONFIRMATION_MODAL'

export const IDLE_TIMER_MODAL = 'IDLE_TIMER_MODAL'

export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL'

export const BLOCK_ISSUES_MODAL = 'BLOCK_ISSUES_MODAL'

