'use-strict'

import React, { useRef, useState } from 'react'
import _ from 'lodash'
import { offset, scrollTo } from '@/helpers/utils'
import RoleManagerListHeader from './RoleManagerListHeader'
import RoleManagerListBody from './RoleManagerListBody'

export default ({
    lang,
    handlers,
    routing,
    generalSearcherLang,
    paginated,
    currentPage,
    loading,
    showCreateRole,
    showEditRole,
    showDeleteRole,
}) => {
    const roleListCardRef = useRef()

    const [searchValues, setSearchValues] = useState({ description: '' })

    const goToPageTop = () => {
        const roleListCardTop = offset(roleListCardRef.current).top + 125
        scrollTo(window, roleListCardTop)
    }

    const onChangeHandler = (key, value) => {
        let searchValuesCopy = _.cloneDeep(searchValues)
        searchValuesCopy[key] = value
        setSearchValues(searchValuesCopy)
    }

    const searchSubmit = async (e) => {
        e.preventDefault()
        let searchValuesAux = _.cloneDeep(searchValues)
        handlers.clearPaginated()
        handlers.setCurrentPage(1)
        await handlers.loadPaginated(searchValuesAux)
        goToPageTop()
    }

    const handleCreate = () => {
        handlers.clearRolePermissions()
        routing.goToCreateRole()
    }

    const pageChangeHandler = async (page) => {
        handlers.setCurrentPage(page)
        await handlers.loadPaginated()
        goToPageTop()
    }

    return (
        <>
            <RoleManagerListHeader
                generalSearcherLang={generalSearcherLang}
                handleCreate={handleCreate}
                lang={lang}
                loading={loading}
                onChangeHandler={onChangeHandler}
                searchSubmit={searchSubmit}
                searchValues={searchValues}
                showCreateRole={showCreateRole}
            />
            <div className="container" ref={roleListCardRef}>
                <RoleManagerListBody
                    lang={lang}
                    loading={loading}
                    currentPage={currentPage}
                    pageChangeHandler={pageChangeHandler}
                    paginated={paginated}
                    handlers={handlers}
                    routing={routing}
                    showEditRole={showEditRole}
                    showDeleteRole={showDeleteRole}
                />
            </div>
        </>
    )
}