import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import DeleteBlockWarningModal from './DeleteBlockWarningModal'
import DeleteBlockConfirmationModal from './DeleteBlockConfirmationModal'
import DeleteBlockInfoAlert from './DeleteBlockInfoAlert'
import { actions as modalActions } from '@/redux/reducers/modalReducer'
import { actions as mamushkaActions } from '@/redux/reducers/mamushkaReducer'
import { DELETE_BLOCK_CONFIRMATION_MODAL, DELETE_BLOCK_WARNING_MODAL } from '../../../../../../../../../constants'
import * as blockThunks from '@/redux/thunks/blockThunks'

const DeleteBlockSuiteContainer = ({
    mamushkaValues,
    course,
    countCanBeRemoved,
    countCanNotBeRemoved,
    closeReduxModal,
    clearMamushkaValues,
    modalKey,
    openReduxModal,
    removeBlock,
    loading,
    lang
}) => {
    const [reportUrl, setReportUrl] = useState(null)
    const [showAlert, setShowAlert] = useState(false)

    useEffect(() => {
        if (modalKey === DELETE_BLOCK_WARNING_MODAL || modalKey === DELETE_BLOCK_CONFIRMATION_MODAL)
            setReportUrl(null)
    }, [modalKey])

    const getBlockName = () => {
        const unit = course.Units.find(unit => unit.id === mamushkaValues.unitId)
        if (!unit) return null
        const lesson = unit.Lessons.find(lesson => lesson.id === mamushkaValues.lessonId)
        if (!lesson) return null
        const topic = lesson.Topics.find(topic => topic.id === mamushkaValues.topicId)
        if (!topic) return null
        const block = topic.Blocks.find(block => block.id === mamushkaValues.blockId)
        if (!block) return null
        return block.name
    }

    const onCloseHandler = () => {
        clearMamushkaValues()
        closeReduxModal()
    }

    const onCloseAlert = () => {
        setShowAlert(false)
        setReportUrl(null)
    }

    const onContinueHandler = () => {
        openReduxModal(DELETE_BLOCK_CONFIRMATION_MODAL)
    }

    const onConfirmHandler = async () => {
        const reportUrl = await removeBlock(
            mamushkaValues.courseId,
            mamushkaValues.unitId,
            mamushkaValues.lessonId,
            mamushkaValues.topicId,
            mamushkaValues.blockId
        )
        setShowAlert(true)
        setReportUrl(reportUrl)
        onCloseHandler()
    }

    const getModalWarning = () =>
        <DeleteBlockWarningModal
            show={true}
            onCancel={onCloseHandler}
            showCounters={course.base}
            countCanBeRemoved={countCanBeRemoved}
            countCanNotBeRemoved={countCanNotBeRemoved}
            id={DELETE_BLOCK_WARNING_MODAL}
            onContinue={onContinueHandler}
            lang={lang.warningModal}
        />

    const getModalConfirmation = () =>
        <DeleteBlockConfirmationModal
            onCancel={onCloseHandler}
            onConfirm={onConfirmHandler}
            name={getBlockName().trim()}
            id={DELETE_BLOCK_CONFIRMATION_MODAL}
            loadingRemoveBlock={loading.removeBlock}
            lang={lang.confirmModal}
        />

    const getInfoAlertWithReport = () =>
        <DeleteBlockInfoAlert
            reportUrl={reportUrl}
            countCanBeRemoved={countCanBeRemoved}
            onCloseAlert={onCloseAlert}
            showDownloadReport={course.base}
            lang={lang.infoAlert}
        />

    if (showAlert && !modalKey) return getInfoAlertWithReport()
    if (!mamushkaValues.courseId) return null
    if (modalKey === DELETE_BLOCK_WARNING_MODAL) return getModalWarning()
    if (modalKey === DELETE_BLOCK_CONFIRMATION_MODAL) return getModalConfirmation()
    return null
}

const mapState = state => ({
    mamushkaValues: state.mamushka,
    loading: state.loading.block,
    countCanBeRemoved: state.block.countCanBeRemoved,
    countCanNotBeRemoved: state.block.countCanNotBeRemoved,
    modalKey: state.modal.id,
    course: state.courseReducer.activeCourse, // tomado de versiones anteriores
    lang: state.langStore.deleteBlockSuite
})

const mapDispatch = dispatch => ({
    closeReduxModal: (id) => dispatch(modalActions.close(id)),
    clearMamushkaValues: () => dispatch(mamushkaActions.clearMamushkaValues()),
    openReduxModal: (id) => dispatch(modalActions.open(id)),
    removeBlock: (courseId, unitId, lessonId, topicId, blockId) => dispatch(blockThunks.removeBlock(courseId, unitId, lessonId, topicId, blockId))
})

export default connect(mapState, mapDispatch)(DeleteBlockSuiteContainer)