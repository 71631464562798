import axios from 'axios'
import * as env from '@/config/env'
import { history } from '@/app/store'
import httpHandler, { codes } from '@/helpers/error-handling/httpHandler'
import * as dashboardService from '../http/services/dashboardService'
import { setLoading } from '../reducers/loadingReducer'
import showNewMessage from '../reducers/_deprecated/globalAlertsReducer/alertsControllers'
import {
    setGeneralCourseInfo,
    setEvaluableActivityList,
    setStudentProgress,
    setRejectTags,
    updateLastStatus,
    setGradesList,
    setGradeStep,
} from '../reducers/dashboardReducer'
import { setGradesListModal } from '../reducers/dashboardReducer'
import { setGradesListModalError } from '../reducers/dashboardReducer'
import { GRADES_STEPS } from '@/constants/grades'
import { setGradesListPublishModal } from '../reducers/dashboardReducer/index'

export const getGeneralCourseInfo = (courseId) => async (dispatch, getState) => {
    const lang = getState().langStore.courseInfo.backendMessages

    dispatch(setLoading.true('dashboard.general'))
    const [error, url] = await dashboardService.generalCourseInfoUrl(courseId)
    dispatch(setLoading.false('dashboard.general'))

    if (error) {
        return httpHandler(error)({
            [codes.NOT_FOUND]: () => history.push('/not-found'),
            [codes.INTERNAL_SERVER_ERROR]: () => {
                showNewMessage({
                    type: 'danger',
                    message: lang.internalServerError,
                    isTemporal: true,
                })
            },
        })
    }

    const courseGeneralInfo = await dashboardService.generalCourseInfo(url)

    dispatch(setGeneralCourseInfo(courseGeneralInfo))
}

export const refreshGeneralCourseInfo = (courseId) => async (dispatch, getState) => {
    const {
        langStore: {
            course: {
                courseCache: { refreshError, refreshSuccess },
            },
        },
    } = getState()

    dispatch(setLoading.true('dashboard.generalRefresh'))

    const [error, response] = await dashboardService.refreshGeneralCourseInfo(courseId)
    const msgType = error ? 'danger' : 'success'
    const msgText = error ? refreshError : refreshSuccess

    dispatch(setLoading.false('dashboard.generalRefresh'))

    showNewMessage({
        type: msgType,
        message: msgText,
        isTemporal: true,
    })

    if (error) return {}

    return response
}

export const getCourseStructureByBlockType =
    (courseId, blockType) => async (dispatch, getState) => {
        const lang = getState().langStore.courseInfo.backendMessages

        dispatch(setLoading.true('dashboard.dropdowns'))
        const [error, response] = await dashboardService.courseStructureByBlockType(
            courseId,
            blockType
        )
        if (error) {
            dispatch(setLoading.false('dashboard.dropdowns'))
            return httpHandler(error)({
                [codes.NOT_FOUND]: () => history.push('/not-found'),
                [codes.INTERNAL_SERVER_ERROR]: () => {
                    showNewMessage({
                        type: 'danger',
                        message: lang.internalServerError,
                        isTemporal: true,
                    })
                },
            })
        }
        dispatch(setLoading.false('dashboard.dropdowns'))

        return response
    }

export const getGradesList = (strategyId, courseId) => async (dispatch) => {
    dispatch(setLoading.true('dashboard.getGradesList'))

    const [error, response] = await dashboardService.getGradesList(strategyId, courseId)

    const { assessmentsTypes, users, assessmentsAllowToPublish, lastPublishDate } = response
    const allowPublishGrades = !!assessmentsAllowToPublish?.length

    if (error) {
        dispatch(setLoading.false('dashboard.getGradesList'))
        return httpHandler(error)({
            [codes.NOT_FOUND]: () => {
                showNewMessage({
                    type: 'danger',
                    message: error.message,
                    isTemporal: true,
                })
            },
        })
    }

    const [progressError, progressResponse] = await dashboardService.getUserProgresses(courseId)

    if (progressError) {
        dispatch(setLoading.false('dashboard.getGradesList'))
        return httpHandler(error)({
            [codes.NOT_FOUND]: () => {
                showNewMessage({
                    type: 'danger',
                    message: error.message,
                    isTemporal: true,
                })
            },
        })
    }

    dispatch(setGradesList({ assessmentsTypes, users, userProgress: progressResponse, assessmentsAllowToPublish, lastPublishDate }))

    const step = allowPublishGrades
        ? GRADES_STEPS.CREATED
            : lastPublishDate
            ? GRADES_STEPS.PUBLISHED
        : ''
        
    dispatch(setGradeStep(step))
    dispatch(setLoading.false('dashboard.getGradesList'))
}

export const getGradebookTemplate = (courseId) => async (dispatch) => {
    dispatch(setLoading.true('dashboard.templateDownload'))

    const [error, url] = await dashboardService.getTemplate(courseId)

    if (error) {
        dispatch(setLoading.false('dashboard.templateDownload'))
        return httpHandler(error)({
            [codes.NOT_FOUND]: () => {
                showNewMessage({
                    type: 'danger',
                    message: error.message,
                    isTemporal: true,
                })
            },
        })
    }

    window.open(url, '_blank')
    dispatch(setLoading.false('dashboard.templateDownload'))
}

export const updateGradebook = (courseId, file) => async (dispatch, getState) => {
    const { assessment_strategy_id: strategyId } = getState().course.course
    dispatch(setLoading.true('dashboard.updateGradebook'))
    dispatch(setGradesListModalError(null))

    const [error] = await dashboardService.updateGradebook(courseId, file)

    if (error) {
        dispatch(setLoading.false('dashboard.updateGradebook'))

        if (error.status === codes.INTERNAL_SERVER_ERROR)
            return httpHandler(error)({
                [codes.INTERNAL_SERVER_ERROR]: () => {
                    showNewMessage({
                        type: 'danger',
                        message: error.message,
                        isTemporal: true,
                    })
                },
            })

        const errorMessage = error.response.data.message
        return dispatch(setGradesListModalError(errorMessage))
    }

    dispatch(getGradesList(strategyId, courseId))
    dispatch(setGradesListModal(false))
    dispatch(setLoading.false('dashboard.updateGradebook'))
}

export const publishGradebook = (courseId) => async (dispatch, getState) => {
    const { assessment_strategy_id: strategyId } = getState().course.course
    dispatch(setLoading.true('dashboard.publishGradebook'))

    const [error] = await dashboardService.publishGradebook(courseId)

    if (error) {
        dispatch(setLoading.false('dashboard.publishGradebook'))
        if (error.status === codes.INTERNAL_SERVER_ERROR)
            return httpHandler(error)({
                [codes.INTERNAL_SERVER_ERROR]: () => {
                    showNewMessage({
                        type: 'danger',
                        message: error.message,
                        isTemporal: true,
                    })
                },
            })
    }

    dispatch(getGradesList(strategyId, courseId))
    dispatch(setGradesListPublishModal(false))
    dispatch(setLoading.false('dashboard.publishGradebook'))
}

export const getQuizReportData = (courseId, quizId) => async (dispatch, getState) => {
    const lang = getState().langStore.courseInfo.backendMessages
    const dataApiConfig = {
        headers: {
            Authorization: env.DATA_REPORT_AUTHORIZATION,
        },
        params: { courseId, quizId },
    }

    dispatch(setLoading.true('dashboard.quizReport'))

    try {
        const response = await axios.get(`${env.DATA_REPORT_API_URL}/api/quiz`, dataApiConfig)

        dispatch(setLoading.false('dashboard.quizReport'))

        if (response.data.answers.length === 0) return { emptyProgress: true }
        return response.data
    } catch (error) {
        dispatch(setLoading.false('dashboard.quizReport'))

        return httpHandler(error)({
            [codes.NOT_FOUND]: () => history.push('/not-found'),
            [codes.INTERNAL_SERVER_ERROR]: () => {
                showNewMessage({
                    type: 'danger',
                    message: lang.internalServerError,
                    isTemporal: true,
                })
            },
        })
    }
}

export const downloadQuizReportData = (courseId, blockId) => async (dispatch, getState) => {
    const lang = getState().langStore.courseInfo.backendMessages

    dispatch(setLoading.true('dashboard.quizDownloadReport'))

    const [error, url] = await dashboardService.quizReportUrl(courseId, blockId)

    if (error) {
        return httpHandler(error)({
            [codes.NOT_FOUND]: () => history.push('/not-found'),
            [codes.INTERNAL_SERVER_ERROR]: () => {
                showNewMessage({
                    type: 'danger',
                    message: lang.internalServerError,
                    isTemporal: true,
                })
            },
        })
    }

    window.open(url, '_blank')
    dispatch(setLoading.false('dashboard.quizDownloadReport'))
}

export const getStudentsStatus = (courseId, blockId, blockType) => async (dispatch, getState) => {
    const lang = getState().langStore.courseInfo.backendMessages

    dispatch(setLoading.true('dashboard.studentsStatus'))

    const [error, response] = await dashboardService.studentsStatus(courseId, blockId, blockType)

    dispatch(setLoading.false('dashboard.studentsStatus'))

    if (error) {
        return httpHandler(error)({
            [codes.NOT_FOUND]: () => history.push('/not-found'),
            [codes.INTERNAL_SERVER_ERROR]: () => {
                showNewMessage({
                    type: 'danger',
                    message: lang.internalServerError,
                    isTemporal: true,
                })
            },
        })
    }

    dispatch(setEvaluableActivityList(response))
}

export const getStudentProgress = (courseId, blockId, userId) => async (dispatch, getState) => {
    const lang = getState().langStore.courseInfo.backendMessages
    dispatch(setLoading.true('dashboard.studentProgress'))

    const [error, response] = await dashboardService.studentProgress(userId, courseId, blockId)

    dispatch(setLoading.false('dashboard.studentProgress'))

    if (error) {
        return httpHandler(error)({
            [codes.NOT_FOUND]: () => history.push('/not-found'),
            [codes.INTERNAL_SERVER_ERROR]: () => {
                showNewMessage({
                    type: 'danger',
                    message: lang.internalServerError,
                    isTemporal: true,
                })
            },
        })
    }

    const blockInfo = {
        name: response.blockInfo.name,
        hasLimit: response.blockInfo.EvaluableBlock.has_limit,
        limitDays: response.blockInfo.EvaluableBlock.limit_days,
        releaseDate: response.blockInfo.Topic.Lesson.release_date,
    }

    dispatch(setStudentProgress({ ...response, blockInfo }))
}

export const sendStudentReview =
    (userId, courseId, blockId, review, reporter) => async (dispatch, getState) => {
        const lang = getState().langStore.courseInfo.backendMessages
        dispatch(setLoading.true('dashboard.studentReview'))

        const [error, response] = await dashboardService.teacherReview(
            userId,
            courseId,
            blockId,
            review
        )

        dispatch(setLoading.false('dashboard.studentReview'))

        if (error) {
            return httpHandler(error)({
                [codes.NOT_FOUND]: () => history.push('/not-found'),
                [codes.INTERNAL_SERVER_ERROR]: () => {
                    showNewMessage({
                        type: 'danger',
                        message: lang.internalServerError,
                        isTemporal: true,
                    })
                },
            })
        }

        const {
            lastStatus,
            lastSubmission: { statusTracking },
        } = response.ProgressEvaluable
        const { name, surname } = reporter

        dispatch(
            updateLastStatus({
                lastStatus,
                statusTracking,
                feedback: { ...review.Submission.feedback, name, surname },
            })
        )

        return response.ProgressEvaluable
    }

export const getRejectReasons = () => async (dispatch, getState) => {
    const lang = getState().langStore.courseInfo.backendMessages
    dispatch(setLoading.true('dashboard.rejectReasons'))

    const [error, response] = await dashboardService.rejectReasons()

    dispatch(setLoading.false('dashboard.rejectReasons'))

    if (error) {
        return httpHandler(error)({
            [codes.NOT_FOUND]: () => history.push('/not-found'),
            [codes.INTERNAL_SERVER_ERROR]: () => {
                showNewMessage({
                    type: 'danger',
                    message: lang.internalServerError,
                    isTemporal: true,
                })
            },
        })
    }

    const tags = response.map((tag) => tag.reason)

    dispatch(setRejectTags(tags))
}

export const downloadProgressReport = (courseId) => async (dispatch, getState) => {
    const lang = getState().langStore.courseInfo.backendMessages
    dispatch(setLoading.true('dashboard.downloadProgressReport'))

    const [error] = await dashboardService.downloadProgressReport(courseId)

    dispatch(setLoading.false('dashboard.downloadProgressReport'))

    if (error) {
        return httpHandler(error)({
            [codes.NOT_FOUND]: () => history.push('/not-found'),
            [codes.INTERNAL_SERVER_ERROR]: () => {
                showNewMessage({
                    type: 'danger',
                    message: lang.internalServerError,
                    isTemporal: true,
                })
            },
        })
    }
}

export const downloadClassProgressReport =
    (courseId, dateFrom, dateTo) => async (dispatch, getState) => {
        const lang = getState().langStore.courseInfo.backendMessages

        dispatch(setLoading.true('dashboard.classReportDownloadReport'))

        const [error, url] = await dashboardService.getClassProgressReportUrl(
            courseId,
            dateFrom,
            dateTo
        )

        if (error) {
            return httpHandler(error)({
                [codes.NOT_FOUND]: () => history.push('/not-found'),
                [codes.INTERNAL_SERVER_ERROR]: () => {
                    showNewMessage({
                        type: 'danger',
                        message: lang.internalServerError,
                        isTemporal: true,
                    })
                },
            })
        }

        window.open(url, '_blank')
        dispatch(setLoading.false('dashboard.classReportDownloadReport'))
    }
