const processUnits = (unitsToCheck) => {
    let lessonsMock = []
    const unitsMock = unitsToCheck.map( unit => {
        unit.Lessons.forEach(lesson => {
            lessonsMock.push({
                id: lesson.id,
                percent: 0,
                unitId: unit.id
            })
        });
        return {
            id: unit.id,
            lessonsQty: unit.Lessons.length,
            percent: 0,
            completedLessons: 0,
        }
    })
    return {unitsMock, lessonsMock}
} 
export const generateProgress = (unitsToCheck) => {
    const { unitsMock, lessonsMock } = processUnits(unitsToCheck)
    return { unitsMock, lessonsMock }
}