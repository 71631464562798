import { history } from '@/app/store'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { track, page } from '@digitalhouse-tech/react-sdk-analytics'
import { getLoggedUser } from '@/redux/selectors/user'
import { loadCourseInfo } from '@/redux/thunks/courseInfoThunks'
import {
    getUserProgressPercent,
    loadLastUserProgress,
    downloadCourseCertificate,
} from '@/redux/thunks/courseThunks'
import { CLASSROOM_LOCATION } from '@/constants/locations'
import { setLocation } from '@/routes/guards'
import { keepLearning } from '@/constants/trackEvents'
import { finishCoursePage } from '@/constants/pageViewEvents'
import { isCurrentPlatformVersion } from '@/helpers/users/helpers'

export default () => {
    const dispatch = useDispatch()

    const { courseId } = useParams()
    const { id, surname } = useSelector(getLoggedUser)
    const certificateLang = useSelector(({ langStore }) => langStore.certificateScreen)
    const downloadIsLoading = useSelector(({ loading }) => loading.course.certificate)

    const goToCourseHandler = async () => {
        const lastMovement = await dispatch(loadLastUserProgress(courseId))

        if (lastMovement) {
            const { unit_id, lesson_id, topic_id } = lastMovement
            history.push(
                `/course/${courseId}/unit/${unit_id}/lesson/${lesson_id}/topic/${topic_id}`
            )
        } else history.goBack()
    }

    useEffect(() => {
        setLocation(CLASSROOM_LOCATION)
    }, [])

    useEffect(() => {
        const loadUserProgress = async () => {
            const {
                userProgress: { percent },
            } = await dispatch(getUserProgressPercent(courseId))
            if (percent < 100) return location.href = '/'
        }

        dispatch(loadCourseInfo(courseId))
        loadUserProgress()
        if(isCurrentPlatformVersion()) page(finishCoursePage, {
            platformVersion: '1.x',
        })
    }, [dispatch, courseId])

    const course = useSelector(({ courseInfo }) => courseInfo)
    const courseName = course?.alias || course?.name
    const url = course?.completed_form_url
    const showKeepLearningButton = !!url

    const redirectHandler = () => {
        track(keepLearning.EVENT_NAME, {
            context: keepLearning.EVENT_CONTEXT,
            label: keepLearning.EVENT_LABEL,
            url: course?.completed_form_url,
        })
        window.open(url, '_blank')
    }

    const {
        course: { getUserProgress: userProgressLoading },
        courseInfo: { loadCourseInfo: courseInfoLoading },
    } = useSelector(({ loading }) => loading)
    const showCertificate = !courseInfoLoading && !userProgressLoading

    const downloadHandler = () => {
        dispatch(downloadCourseCertificate(id, courseId, surname, courseName))
    }

    return {
        courseName,
        redirectHandler,
        goToCourseHandler,
        downloadHandler,
        showKeepLearningButton,
        certificateLang,
        showCertificate,
        downloadIsLoading,
    }
}
