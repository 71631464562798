import React, { useMemo, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { allowApiCreate } from '@/helpers/granters/courseTypeRules'
import { dontReturnToWelcomeAction } from '@/redux/reducers/_deprecated/surveyReducer'
import {
    CLASSROOM_ACCESS_BUTTON,
    STARRED_COURSE_BUTTON,
} from '../constants'
import { Star, X } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { useActions } from './useActions'

export const useButtons = (course, setConfirmModal, survey) => {
    const [loadingAction, setLoadingAction] = useState(false)

    const lang = useSelector(({ langStore }) => langStore.welcomeScreen)
    const isMobile = useSelector(({ authReducer }) => authReducer.isMobile)

    const dispatch = useDispatch()

    const {
        id,
        started,
        finished,
        content,
        isTeacher,
        CourseType,
        base,
        starred_date,
    } = course

    const {
        continueActivity,
        reviewTheCourse,
        soon,
        confirmSurvey,
        continueExercise,
        thereSurvey,
        starredCourse,
        undoStarredCourse,
        enter
    } = lang

    const { onClickGoToActivity, onClickGoToUnits, onClickGoToLastSurvey, onClickStarredCourse } =
        useActions()

    const actionSurveyInterceptor = useCallback(
        (callback, confimModalState) =>
            (...data) => {
                if (survey && !isTeacher && !survey.completed) {
                    dispatch(dontReturnToWelcomeAction())
                    if (survey.mandatory) return onClickGoToLastSurvey(data[0], survey)
                    else setConfirmModal(confimModalState)
                    if (!isMobile) return
                }
                return callback(...data)
            },
        [survey, setConfirmModal, onClickGoToLastSurvey, isMobile, isTeacher, dispatch]
    )

    const allowApiCreateRule = allowApiCreate(CourseType)

    const classroomAccessButton = useMemo(() => {
        let text = ''
        let disabled = false
        const callToAction = actionSurveyInterceptor(onClickGoToUnits, {
            show: true,
            confirmTitle: confirmSurvey,
            cancelTitle: continueExercise,
            body: thereSurvey,
            onConfirm: () => onClickGoToLastSurvey(id, survey),
            onCancel: () => onClickGoToUnits(id),
        })

        if (!Number(content.released)) {
            disabled = true
        }
        if (isTeacher && allowApiCreateRule && base) {
            disabled = false
        }
        return {
            id: CLASSROOM_ACCESS_BUTTON,
            testId: CLASSROOM_ACCESS_BUTTON,
            onClick: () => {
                setLoadingAction(true)
                callToAction(id)
            },
            text: enter,
            isDisabled: disabled,
            isVisible: true,
            theme: 'tertiary',
            loading: loadingAction,
            ariaLabel: text,
        }
    }, [
        id,
        continueActivity,
        soon,
        reviewTheCourse,
        started,
        finished,
        content,
        confirmSurvey,
        continueExercise,
        thereSurvey,
        onClickGoToActivity,
        actionSurveyInterceptor,
        onClickGoToLastSurvey,
        isTeacher,
        base,
        allowApiCreateRule,
        survey,
        starred_date,
        loadingAction,
    ])

    const starredCourseButton = useMemo(() => {
        const text = starred_date ? undoStarredCourse : starredCourse
        const icon = starred_date ? <X size={18} /> : <Star size={18} />
        return {
            id: STARRED_COURSE_BUTTON,
            testId: STARRED_COURSE_BUTTON,
            onClick: () => onClickStarredCourse(id, starred_date),
            text: text,
            isDisabled: false,
            isVisible: true,
            loading: false,
            icon: icon,
            ariaLabel: text,
        }
    }, [starred_date])

    return [
        [classroomAccessButton],
        [starredCourseButton],
        lang.accesibility.courseOptions,
    ]
}
