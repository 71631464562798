import { BlipChat } from 'blip-chat-widget'
import * as env from '@/config/env'
import { getLoggedUser } from '../../selectors/user'
import { getAllCourses } from '../../selectors'

export const ENABLE_CHATBOT = '@@chatbot/ENABLE_CHATBOT'
export const DISABLE_CHATBOT = '@@chatbot/DISABLE_CHATBOT'

const initialState = {
    isEnabled: false,
    chatbot: null,
}

export const enableChatbot = (chatbot) => ({
    type: ENABLE_CHATBOT,
    payload: { chatbot },
})
export const disableChatbot = () => ({
    type: DISABLE_CHATBOT,
})

export const createChatbot = () => (dispatch, getState) => {
    const state = getState()
    const courses = getAllCourses(state)
    const { activeCourse } = state.courseReducer

    const inAllCourses = courses.filter(
        ({ CourseType }) => !!CourseType.chatbot
    ).length

    const inCurrentCourse =
        activeCourse &&
        activeCourse.CourseType &&
        activeCourse.CourseType.chatbot

    if (!env.BLIP_ENABLED || (!inAllCourses && !inCurrentCourse)) {
        return null
    }

    const user = getLoggedUser(state)
    const chatbotClient = new BlipChat()
        .withAppKey(env.BLIP_KEY)
        .withAuth({
            authType: BlipChat.DEV_AUTH,
            userIdentity: user.username,
            userPassword: user.username,
        })
        .withAccount({
            fullName: user.name,
            email: user.username,
        })

    chatbotClient.build()

    dispatch(enableChatbot(chatbotClient))
}

export const destroyChatbot = () => (dispatch, getState) => {
    const { chatbot } = getState().chatbot
    if (chatbot) chatbot.destroy()
    dispatch(disableChatbot())
}

const chatbotReducer = (state = initialState, { type, payload }) => {
    const reducers = {
        [ENABLE_CHATBOT]: () => {
            return {
                ...state,
                isEnabled: true,
                chatbot: payload.chatbot,
            }
        },
        [DISABLE_CHATBOT]: () => initialState,
        LOGOUT_USER: () => {
            if (state.chatbot) state.chatbot.destroy()
            return initialState
        },
    }
    return reducers[type] ? reducers[type]() : state
}

export default chatbotReducer
