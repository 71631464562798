import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import LoadingOrContent from '@digitalhouse-tech/react-lib-ui-explorer/lib/LoadingOrContent'
import NoResults from '../../../NoResults'
import './ManagerTable.scss'

const ManagerTable = ({
    headers,
    rows,
    loading,
    notFoundMessage,
    useSearchMessage,
}) => {

    const isLoaded = useSelector(state => state.manager.pagination.isLoaded)

    const valueByRef = (ref, row) => {
        const splitted = ref.split('.')
        let value = row
        for (const key of splitted) value = value[key]
        return value
    }

    const getTableEmptyState = () => {
        if (rows.length) return null
        let msg = null
        if (isLoaded) {
            if (!rows.length) msg = notFoundMessage
        } else {
            msg = useSearchMessage
        }
        return (
            <tr>
                <td className="text-center" colSpan={headers.length}>
                    <NoResults
                        text={msg}
                        bgColor="transparent"
                    />
                </td>
            </tr>
        )
    }

    return (
        <>
            <div className="table-responsive">
                <table className="table __table">
                    <thead>
                        <tr>
                            {headers.map(header => (
                                <th className={header.className} key={'table-header' + header.ref}>
                                    {header.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <LoadingOrContent loading={loading} size='medium'>
                            <>
                                {getTableEmptyState()}
                                {rows.map((row) => {
                                    const columns = headers.map((header) => {
                                        const value = valueByRef(header.ref, row)
                                        return (
                                            <td
                                                className={`${header.className} truncate`}
                                                key={header.ref + 'table-column' + value}
                                            >
                                                {value}
                                            </td>
                                        )
                                    })
                                    return <tr key={'table-row' + row.id}>{columns}</tr>
                                })}
                            </>
                        </LoadingOrContent>
                    </tbody>
                </table>
            </div>
        </>
    )
}

ManagerTable.propTypes = {
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            ref: PropTypes.string.isRequired,
            className: PropTypes.string,
        }),
    ).isRequired,
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        }),
    ).isRequired,
    loading: PropTypes.bool.isRequired,
    notFoundMessage: PropTypes.string.isRequired,
    useSearchMessage: PropTypes.string.isRequired,
}

export default ManagerTable
