import React, { useState, useEffect } from 'react'
import StudentFinder from './StudentFinder'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { loadCourseStudent, clearSearchStudent } from '@/redux/reducers/_deprecated/courseReducer/controllers'
import User from '@/components/UserCard'
import { history } from '@/app/store.js'
import { loadCourse } from '@/redux/thunks/courseThunks'
import { clearCourse } from '@/redux/reducers/courseReducer'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

const PreworkReportContainer = ({
    match,
    courseStudentSearch,
    lang,
    langPrework,
    course,
    loadCourse,
    clearCourse
}) => {

    useEffect(() => {
        clearSearchStudent()
        loadCourse(match.params.courseId)
    }, [])

    useEffect(() => {
        return () => clearCourse()
    }, [])

    const [isLoading, setIsLoading] = useState(false)
    const [isSearchReady, setIsSearchReady]= useState(false)

    const searchStudent = async (searchParams) => {
        setIsLoading(true)
        await loadCourseStudent(match.params.courseId, searchParams.name, searchParams.surname, searchParams.email)
        setIsLoading(false)
        setIsSearchReady(true)
    }
    const goToUser = (userId) => {
        history.push(`/admin/courses/${match.params.courseId}/preworks/users/${userId}`)
      }

    const loadUsers = () => {
        const resultUsers = []
        courseStudentSearch.map((user, key) => {
            resultUsers.push(<User {...user} key={key} prework={true} courseId={match.params.courseId} onClickUser={goToUser}/>)
        })
        return resultUsers
    }
    const showMessage = () => {
        if(isSearchReady) {
            return <div className="__search_msg_result"><p>{lang.noResultsFoundMessage}</p></div>
        }
        return <div className="__search_msg_result"><p>{lang.useSearchMessage}</p></div>
    }
    const handleGoBack = () => {
        history.goBack()
    }
    return (
        <div className="container">
            <StudentFinder searchStudent={searchStudent} lang={lang} isLoading={isLoading} title={langPrework.report} subtitle={langPrework.students} courseName={course.name}/>
            {courseStudentSearch.length > 0 ? loadUsers() : showMessage()}
            <Row>
                <Col lg="12">
                    <ButtonGroup className="pull-right">
                        <Button
                            id="ReturnButton"
                            onClick={handleGoBack}
                            text={langPrework.return}
                            theme='secondary'
                        />
                    </ButtonGroup>
                </Col>
            </Row>
        </div>

    )
}

const mapDispatch = (dispatch, functions = {
    loadCourse,
    clearCourse
}) => ({
    loadCourse: (courseId) => dispatch(functions.loadCourse(courseId)),
    clearCourse: () => dispatch(functions.clearCourse()),
})

const mapStateToProps = state => ({
    courseStudentSearch: state.courseReducer.courseStudentSearch,
    lang: state.langStore.addUser,
    langPrework : state.langStore.preworkReportContainer,
    course: state.course.course
})

export default withRouter(connect(mapStateToProps, mapDispatch)(PreworkReportContainer))