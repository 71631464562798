import React from 'react'

import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import "react-datepicker/dist/react-datepicker.css"

import { saveNewTopic, updateOneTopic, clearCoursesCountAction, changeSavingTopicAction } from '@/redux/reducers/_deprecated/courseReducer'
// import { closeModalTopicAction, updateTopicAction, showModalVisibility, hideModalTopicAction, displayModalTopicAction } from '../course/modalsReducer'
import { actions as modalActions } from '@/redux/reducers/modalReducer'
import { TOPIC_MODAL } from '@/constants/modals'
import ModalClonesConfirmation from '@/components/ModalClonesConfirmation'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import { CREATE_TOPICS, EDIT_TOPIC_CONTENT, EDIT_TOPIC_VISIBILITY } from '@/constants/coursePermissions'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Checkbox from '@digitalhouse-tech/react-lib-ui-explorer/lib/Checkbox'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import './ModalTopic.scss'

const EMPTY_TOPIC = {
  name: '',
  disableStudentVisibility: false,
}

const NO_ERROR = { error: null, message: '' }
export class ModalTopic extends React.Component {

  constructor(props, context) {
    super(props, context)

    this.state = {
      show: false,
      courseId: null,
      isChecked: false,
      name: { default: '', error: false, message: '', value: '' },
      showConfirmation: false,
      topic: props.modalReducer.data || EMPTY_TOPIC,
      display: 'block'
    }
    
    this.handleClose = this.handleClose.bind(this)
    this.submitTopic = this.submitTopic.bind(this)
    this.handleVisibility = this.handleVisibility.bind(this)
    this.openConfirmationModal = this.openConfirmationModal.bind(this)
    this.handleAcceptConfirmation = this.handleAcceptConfirmation.bind(this)
    this.handleCancelConfirmation = this.handleCancelConfirmation.bind(this)
    this.checkPermissions = this.checkPermissions.bind(this)

  }

  componentDidMount() {
    this.props.changeSavingTopic(false)
  }

  // CONFIRMATION MODAL METHODS
  openConfirmationModal() {
    let name = this.state.topic.name
    let formValidation = this.validateInfo(name)

    if (formValidation) {
      this.setState(state => ({
        ...state,
        showConfirmation: true,
        name: {
          ...state.name,
          value: name
        },
        display: 'none'
      }))
    }
  }

  handleAcceptConfirmation() {
    this.setState(state => ({
      ...state,
      showConfirmation: false,
    }), () => {
      this.confirm()
      this.handleClose()
      this.props.clearCoursesCountAction()
    })
  }

  handleCancelConfirmation() {
    this.setState({
      showConfirmation: false,
      display: 'block'
    })
  }

  // TOPIC MODAL METHODS
  handleClose() {
    this.setState(state => ({
      ...state,
      name: { default: '', error: false, message: '', value: '' }
    }))
    this.props.closeModal()
  }

  updateTopic(topic) {
    this.setState({
        ...this.state,
        topic
    })
  }

  handleVisibility(selectedValue) {
    let newCheck = selectedValue
    let topicData = { unitId: this.state.topic.unitId, lessonId: this.state.topic.lessonId, name: this.state.topic.name, id: this.state.topic.id, visibility: newCheck, order: this.state.topic.order, disableStudentVisibility: this.state.topic.disableStudentVisibility }
    this.updateTopic(topicData)

  }

  validateInfo(name) {
    let topicFormOk = true
    let nameValue = NO_ERROR

    if (typeof (name) === 'string') {
      name = name.trim()
    }

    if (name == null || name == '') {
      nameValue.error = true
      nameValue.message = this.props.lang.requiredInputMessage
      topicFormOk = false
    } else {
      nameValue.error = false
      nameValue.message = ''
    }

    this.setState({
      ...this.state,
      name: nameValue,
    })

    return topicFormOk
  }

  confirm() {
    let courseId = this.props.courseId
    let unitId = this.state.topic.unitId
    let lessonId = this.state.topic.lessonId
    let topicId = this.state.topic.id
    let name = this.state.topic.name
    let disableStudentVisibility = this.state.topic.disableStudentVisibility
    let order = this.state.topic.order
    let visibility = this.state.topic.visibility

    this.props.changeSavingTopic(true)
    if (topicId == null) {
      let saveInfo = { name, visibility, courseId, unitId, lessonId, disableStudentVisibility }
      this.props.saveNewTopic(saveInfo, this.props.activeCourse)
    } else {
      let updateInfo = { id: topicId, name, order, visibility, courseId, unitId, lessonId, disableStudentVisibility }
      this.props.updateOneTopic(updateInfo, this.props.activeCourse)
    }
  }

  submitTopic() {
    let courseId = this.props.courseId
    let unitId = this.state.topic.unitId
    let lessonId = this.state.topic.lessonId
    let topicId = this.state.topic.id
    let name = this.state.topic.name
    let disableStudentVisibility = this.state.topic.disableStudentVisibility
    let order = this.state.topic.order
    let visibility = this.state.topic.visibility
    let formValidation = this.validateInfo(name)


    if (formValidation) {
      this.props.changeSavingTopic(true)
      if (topicId == null) {
        let saveInfo = { name, visibility, courseId, unitId, lessonId, disableStudentVisibility }
        this.props.saveNewTopic(saveInfo, this.props.activeCourse)
      } else {
        let updateInfo = { id: topicId, name, order, visibility, courseId, unitId, lessonId, disableStudentVisibility }
        this.props.updateOneTopic(updateInfo, this.props.activeCourse)
      }
      this.handleClose()
    }
  }

  handleInputChange(value, valueName) {
    this.setState({
        ...this.state,
        topic: {
            ...this.state.topic,
            [valueName]: value
        }
    })
}

    handleCheckboxChange(valueName) {
        this.setState({
          ...this.state,
          topic: {
              ...this.state.topic,
              [valueName]: !this.state.topic[valueName]
          }
      })
    }
    
    checkPermissions(permissionsType) {
        const {topic} = this.state
        if (!topic.id)
            return CREATE_TOPICS()

        const type = {
            'name': EDIT_TOPIC_CONTENT,
            'visibility': EDIT_TOPIC_VISIBILITY
        }

        return type[permissionsType]()
    }

  render() {
    return (
      <>
        <Modal
          className="__modal"
          bsSize="large"
          show={this.props.modalReducer.id === TOPIC_MODAL}
          onHide={this.handleClose}
          keyboard={false}
          backdrop="static"
          style={ {display: this.state.display} }
        >
          <Modal.Header closeButton className="__modal_header __border_bottom">
          </Modal.Header>

          <Modal.Body className="__modal_body">
            {this.props.errorCourse && (
                <MessageBox className='_margin_bottom_20' fullWidth text={this.props.errorMessage} theme='danger' />
            )}
            <Row>
              {this.checkPermissions('name') &&
                <Col md="6">
                    <Label text={this.props.lang.nameLabel} />
                    <InputText key="unitName" bsSize="small"
                        id='topicName'
                        value={this.state.topic.name}
                        onChange={(e) => this.handleInputChange(e.target.value, 'name')}
                        error={this.state.name.error}
                        errorMsg={this.state.name.message}
                    />
                </Col>
              }

              {this.checkPermissions('visibility') &&
                <Col md="6">
                    <Label text={this.props.lang.isVisibleLabel} />
                    <InputComboBox
                        inputName="visibility"
                        placeholder={this.props.lang.select}
                        id="visibility"
                        dropdownItems={[this.props.lang.yes, this.props.lang.no]}
                        onChange={(item) => this.handleVisibility(!!(item === this.props.lang.yes))}
                        value={this.state.topic.id ? (this.state.topic.visibility ? this.props.lang.yes : this.props.lang.no) : (this.state.topic.visibility ? this.props.lang.yes : this.props.lang.no)}
                    />
                </Col>
              }
            </Row>
            <Row>
              <div className='studentVisibility'>
                    <Checkbox 
                    id={`disableStudentVisibility`}
                    inputName={'disableStudentVisibility'}
                    onChange={() => this.handleCheckboxChange('disableStudentVisibility')}
                    checked={this.state.topic.disableStudentVisibility}
                    />
                    <Label text={this.props.lang.disabledRoleVisibility} htmlFor={'disableStudentVisibility'} />
              </div>
            </Row>
          </Modal.Body>

          <Modal.Footer className="__modal_footer __border_top">
            <ButtonGroup>
              <Button
                id="handleCloseButton"
                onClick={this.handleClose}
                text={this.props.lang.cancelButton}
                theme='tertiary'
                disabled={this.props.savingTopic}
              />
              <Button
                id="SaveButton"
                text={this.props.lang.saveButton}
                theme='primary'
                onClick={(this.props.activeCourse.base && this.props.coursesToModify.length > 0) ? this.openConfirmationModal : this.submitTopic}
                loading={this.props.savingTopic}
                disabled={this.props.savingTopic}
              />
            </ButtonGroup>
          </Modal.Footer>
        </Modal>

        <ModalClonesConfirmation
          show={this.state.showConfirmation}
          confirmTitle={this.props.lang.confirmTitle}
          cancelTitle={this.props.lang.cancelTitle}
          onConfirm={() => {
            this.handleAcceptConfirmation()
          }}
          onCancel={this.handleCancelConfirmation}
          body={this.props.coursesToModify}
        />
      </>
    )
  }
}

const mapDispatch = (dispatch) => ({
  saveNewTopic: (data, activeCourse) => {
    dispatch(saveNewTopic(data, activeCourse))
  },
  updateOneTopic: (data, activeCourse) => {
    dispatch(updateOneTopic(data, activeCourse))
  },
  closeModal: () => dispatch(modalActions.close()),
  // closeModalTopic: () => {
  //   dispatch(closeModalTopicAction())
  // },
  // updateTopic: (data) => {
  //   dispatch(updateTopicAction(data))
  // },
  // showModalVisibility: (data) => {
  //   dispatch(showModalVisibility(data))
  // },
  // displayModalTopicAction: () => {
  //   dispatch(displayModalTopicAction())
  // },
  // hideModalTopicAction: () => {
  //   dispatch(hideModalTopicAction())
  // },
  clearCoursesCountAction: () => {
    dispatch(clearCoursesCountAction())
  },
  changeSavingTopic: (status) => {
    dispatch(changeSavingTopicAction(status))
  },
})

const mapStateToProps = (state) => {
  return {
    // COURSE REDUCER
    activeCourse: state.courseReducer.activeCourse,
    errorMessage: state.courseReducer.errorMessage,
    errorCourse: state.courseReducer.errorCourse,
    coursesToModify: state.courseReducer.coursesToModify,
    savingTopic: state.courseReducer.savingTopic,
    // MODALS REDUCER
    modalReducer: state.modal,
    lang: state.langStore.modalTopic
  }
}

export default connect(mapStateToProps, mapDispatch, null, { forwardRef: true })(ModalTopic)