import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import { Download } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import fileServices from '../../../../Backoffice/Courses/Files/fileManagerServices'
import BlockIssueMenu from './BlockIssueMenu/BlockIssueMenu'
import './FileBlock.scss'

const FileBlock = ({ block, isMobile, lang, courseId, blockType }) => {

    const [isDownloading, setIsDownloading] = useState(false)

    const handleDownload = async () => {

        if (!courseId || !block.FileBlock) {
            return
        }

        setIsDownloading(true)

        try {
            const { File: { path, name } } = block.FileBlock
            await fileServices.downloadFile(
                path,
                name
            )
            setIsDownloading(false)
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
        }
    }

    return (
        <div className="blockContent fileBlock">
            <BlockIssueMenu blockType={blockType} blockId={block.id} blockName={block.name} />
            {block.show_name && (
                <div className='basic-block-content__blockHeader pg-block-title'>
                    <h1>{block.name}</h1>
                </div>
            )}
            <div className="__medium_card">
                <ButtonRound
                    id="DownloadFileButton"
                    theme='primary'
                    icon={!isDownloading && <Download />}
                    loading={isDownloading}
                    disabled={isDownloading}
                    tooltipId="showSearchBar"
                    tooltipContent={!isMobile && lang.downloadFile}
                    onClick={handleDownload}
                />
                <h3 className="__medium_card__block-name">{block.FileBlock.name}</h3>
            </div>
        </div>
    )
}

FileBlock.propTypes = {
    block: PropTypes.object.isRequired,
    isMobile: PropTypes.bool,
    lang: PropTypes.object,
    courseId: PropTypes.string,
}

FileBlock.defaultProps = {
    lang: {
        downloadFile: 'Download'
    },
    isMobile: false,
    courseId: null,
}

export default FileBlock
