import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import cn from 'classnames'
import PanelHeader from './PanelHeader'
import StaticFeedback from './StaticFeedback'
import FeedbackForm from './FeedbackForm'
import PanelFooter from './PanelFooter'
import * as dashboard from '../../../shared/constants/dashboard'
import './Panel.scss'

const Panel = ({
    blockName,
    lang,
    studentsList,
    selected,
    onRequestRedo,
    onChangeSearch,
    lastStatus,
    type,
    search,
    handIn,
    statusTracking,
    teacherReview,
    htmlReviewProps,
    onClickFooterActions,
    onHtmlReviewChange,
    onChangeReviewFiles,
    canEdit
}) => {
    const uploadingFile = useSelector(({ loading }) => loading.filesManager?.uploading)
    const { uploadFileError } = useSelector(({ langStore }) => langStore.evaluableBlock)

    const isSubmitAllowed =
        canEdit &&
        lastStatus.status === dashboard.PENDING_FEEDBACK &&
        htmlReviewProps.chars <= dashboard.MAX_REVIEW_CHARS_FEEDBACK &&
        !uploadingFile

    const showFeedbackForm =
        lastStatus.status === dashboard.PENDING_RETRY ||
        lastStatus.status === dashboard.PENDING_FEEDBACK

    const buttonsAction =
        !isSubmitAllowed || lastStatus.status === dashboard.PENDING_RETRY
            ? () => {}
            : (reviewType) => onClickFooterActions(reviewType)

    return (
        <div className='review-activity__panel'>
            <PanelHeader
                blockName={blockName}
                dropdownList={studentsList}
                lang={lang}
                onRequestRedo={onRequestRedo}
                selected={selected}
                onChangeSearch={onChangeSearch}
                studentStatus={lastStatus.status}
                type={type}
                searchValue={search}
                canEdit={canEdit}
            />
            <StaticFeedback
                lang={lang}
                handIn={handIn}
                statusTracking={statusTracking}
                studentName={selected.fullName}
                studentStatus={lastStatus.status}
                feedback={teacherReview}
                type={type}
            />
            {showFeedbackForm && (
                <div
                    className={cn({
                        'review-activity__panel__form--pending':
                            lastStatus.status === dashboard.PENDING_RETRY || !canEdit,
                    })}
                >
                    <FeedbackForm
                        reviewFiles={teacherReview.files}
                        onReviewChange={(text) => onHtmlReviewChange(text)}
                        onChangeReviewFiles={onChangeReviewFiles}
                        htmlReviewProps={htmlReviewProps}
                        lang={lang}
                        status={lastStatus.status}
                        filesUploaderErrorMsg={uploadFileError}
                        canEdit={canEdit}
                    />
                    <PanelFooter lang={lang} onClick={buttonsAction} disabled={!isSubmitAllowed} />
                </div>
            )}
        </div>
    )
}

Panel.propTypes = {
    blockName: PropTypes.string,
    lang: PropTypes.object,
    studentsList: PropTypes.array,
    selected: PropTypes.shape({
        fullName: PropTypes.string,
    }),
    onRequestRedo: PropTypes.func,
    onChangeSearch: PropTypes.func,
    lastStatus: PropTypes.shape({
        status: PropTypes.string,
    }),
    type: PropTypes.string,
    search: PropTypes.string,
    handIn: PropTypes.object,
    statusTracking: PropTypes.array,
    teacherReview: PropTypes.object,
    htmlReviewProps: PropTypes.object,
    onClickFooterActions: PropTypes.func,
    onHtmlReviewChange: PropTypes.func,
    onChangeReviewFiles: PropTypes.func,
    canEdit: PropTypes.bool,
}

Panel.defaultProps = {
    blockName: '',
    lang: {},
    studentsList: [],
    selected: {
        fullName: '',
    },
    onRequestRedo: () => {},
    onChangeSearch: () => {},
    lastStatus: {
        status: '',
    },
    type: '',
    search: '',
    handIn: {},
    statusTracking: [],
    teacherReview: {},
    htmlReviewProps: {},
    onClickFooterActions: () => {},
    onHtmlReviewChange: () => {},
    onChangeReviewFiles: () => {},
    canEdit: false,
}

export default Panel
