import { createSelector } from 'reselect'

export const getContentMap = (state) => state.student.classroom.content_map

export const getCurrentTopic = (topic_id) =>
    createSelector(
        [getContentMap],
        ({ Lessons = [] } = {}) =>
            Lessons.map(({ Topics }) => Topics.filter(({ id }) => id == topic_id)[0]).filter(
                Boolean
            )[0] || {}
    )

export const getCourseFinishDate = () =>
    createSelector(getContentMap, ({ Course }) => Course?.finish_date)
