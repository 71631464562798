import React from 'react'
import PropTypes from 'prop-types'
import usePublishModal from '../../hooks/usePublishModal'
import Modal from '@digitalhouse-tech/react-lib-ui-explorer/lib/Modal'
import { Award } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Tag from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tag'
import './PublishModal.scss'

const PublishModal = ({ courseId }) => {
    const { lang, assessmentsAllowToPublish, showModal, closeModal, handlePublishGrades, loading } =
        usePublishModal(courseId)
    return (
        <Modal
            showModal={showModal}
            loadingMain={loading}
            modalTitle={
                <span className='publish-modal__title'>
                    <Award className='publish-modal__icon' />
                    <span>{lang.publishModal.modalTitle}</span>
                </span>
            }
            secondaryTextAction={lang.publishModal.cancel}
            mainTextAction={lang.publishModal.publish}
            onMainAction={handlePublishGrades}
            onSecondaryAction={closeModal}
            onCloseAction={closeModal}
        >
            <p>{lang.publishModal.infoText}</p>
            <div className='publish-modal__tags'>
                {assessmentsAllowToPublish.map((assessment, i) => (
                    <Tag key={i} text={assessment} deletable={false} />
                ))}
            </div>
            <p className='publish-modal__end-description'>{lang.publishModal.disclaimer}</p>
        </Modal>
    )
}

PublishModal.propTypes = {
    courseId: PropTypes.string.isRequired,
}

PublishModal.defaultProps = {
    assessmentsAllowToPublish: [],
}

export default PublishModal
