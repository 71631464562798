import React from 'react'
import PropTypes from 'prop-types'

import QuizHeader from './QuizHeader'
import QuizFooter from './QuizFooter'
import QuizNav from './QuizNav'
import QuizCover from '@/components/Quiz/Cover'
import Question from './Question'
import useQuiz from './useQuiz'
import LoadingOrContent from '@/components/LoadingOrContent'
import Skeleton from './Skeleton'
import './Quiz.scss'

const Quiz = ({
    activeQuestion,
    activeQuestionIdx,
    onUpdateAnswerText,
    onClickQuestion,
    onUpdateAnswerSingleChoice,
    onUpdateAnswerMultipleChoice,
    completenessPercentage,
    disabled,
    onClickStartQuiz,
    onClickSendQuiz,
    showSendQuizButton,
    showReDoButton,
    hasQuizComplete,
    answers,
    showCover,
    blockId,
    quiz,
    isLoading,
    lang,
}) => {
    const { name, approvedMessage, redoMessage, failedMessage, questions, id } = quiz
    const {
        attempts,
        answers: progressAnswers,
        isApproved,
        approvedPercentage,
        questionsResults,
    } = answers
    const { getAnswer, quizAnswers, validateInputValue } = useQuiz(progressAnswers, id, blockId)
    const quizComplete = hasQuizComplete && !isLoading

    return (
        <>
            {showCover ? (
                <>
                    <QuizCover
                        questionsNumber={quiz.questions.length}
                        quiz={quiz}
                        onClickStartQuiz={onClickStartQuiz}
                        lang={lang}
                    />
                </>
            ) : (
                <div className='quiz-student'>
                    <LoadingOrContent
                        loading={isLoading}
                        loadingComponent={<Skeleton quizTitle={quiz?.name} />}
                    >
                        <div className='quiz-student__body'>
                            <QuizHeader
                                title={name}
                                maxAttempts={quiz.attempts}
                                quiz={quiz}
                                attempts={attempts}
                                hasQuizComplete={quizComplete}
                                approvedMessage={approvedMessage}
                                redoMessage={redoMessage}
                                failedMessage={failedMessage}
                                rightAnswer={isApproved}
                                approvedPercentage={approvedPercentage}
                                availableReDoQuiz={showReDoButton}
                                lang={lang}
                            />
                            <hr />
                            <Question
                                question={activeQuestion}
                                onUpdateAnswerText={onUpdateAnswerText}
                                onUpdateAnswerSingleChoice={onUpdateAnswerSingleChoice}
                                onUpdateAnswerMultipleChoice={onUpdateAnswerMultipleChoice}
                                studentAnswer={getAnswer(activeQuestion.id, quizComplete)}
                                hasQuizComplete={quizComplete}
                                isSurvey={quiz.isSurvey}
                                affectsProgress={quiz.affectsProgress}
                                lang={lang}
                            />
                            <QuizNav
                                active={activeQuestionIdx}
                                questions={questions}
                                onClickItem={onClickQuestion}
                                hasQuizComplete={quizComplete}
                                questionsResults={questionsResults}
                                validateInputValue={validateInputValue}
                                lang={lang}
                                isSurvey={quiz.isSurvey}
                                attempts={attempts}
                            />
                        </div>
                    </LoadingOrContent>

                    <QuizFooter
                        completenessPercentage={completenessPercentage}
                        disabled={disabled}
                        onClickFinishQuiz={() => onClickSendQuiz(quizAnswers)}
                        onClickReDoQuiz={() => onClickStartQuiz()}
                        showSendQuizButton={showSendQuizButton}
                        showRedoButton={showReDoButton}
                        isLoading={isLoading}
                        lang={lang}
                    />
                </div>
            )}
        </>
    )
}

Quiz.propTypes = {
    activeQuestion: PropTypes.object,
    activeQuestionIdx: PropTypes.number,
    onUpdateAnswerText: PropTypes.func,
    onClickQuestion: PropTypes.func,
    onUpdateAnswerSingleChoice: PropTypes.func,
    onUpdateAnswerMultipleChoice: PropTypes.func,
    completenessPercentage: PropTypes.number,
    disabled: PropTypes.bool,
    onClickSendQuiz: PropTypes.func,
    onClickStartQuiz: PropTypes.func,
    showSendQuizButton: PropTypes.bool,
    showReDoButton: PropTypes.bool,
    hasQuizComplete: PropTypes.bool,
    answers: PropTypes.object,
    blockId: PropTypes.string,
    quiz: PropTypes.object,
    showCover: PropTypes.bool,
    isLoading: PropTypes.bool,
    lang: PropTypes.object,
}

Quiz.defaultProps = {
    onUpdateAnswerText: () => {},
    activeQuestionIdx: 0,
    onClickQuestion: () => {},
    onUpdateAnswerSingleChoice: () => {},
}

export default Quiz
