'use-strict'

const initialState = {
    guard: null,
    location: null,
    firstRedirected: false,
}

const SET_GUARD = '@@context/SET_GUARD'
const SET_LOCATION = '@@context/SET_LOCATION'
const SET_FIRST_REDIRECT = '@@context/SET_FIRST_REDIRECT'

export const setGuard = (guard) => ({
    type: SET_GUARD,
    payload: { guard },
})

export const setLocation = (location) => ({
    type: SET_LOCATION,
    payload: { location },
})

export const setFirstRedirect = () => ({
    type: SET_FIRST_REDIRECT,
})

const contextReducer = (state = initialState, { type, payload }) => {
    const types = {
        [SET_GUARD]: () => ({
            ...state,
            guard: payload.guard,
        }),
        [SET_LOCATION]: () => ({
            ...state,
            location: payload.location,
        }),
        [SET_FIRST_REDIRECT]: () => ({
            ...state,
            firstRedirected: true,
        }),
        LOGOUT_USER: () => initialState
    }
    return types[type] ? types[type]() : state
}

export default contextReducer
