import api from '@/config/endpoints'
import axios from '@/app/requests'
import _ from 'lodash'
import moment from 'moment'

import { getStore, history } from '@/app/store'

import { getConfig, getErrorResponse, getItemOrderValues, getFileConfig } from '@/helpers/utils'

import services from './services'
import {
    changeSavingBlockAction,
    updateActiveCourseAction,
    setUpdatedClonesAction,
    queryError,
    changeLoadingAction,
    getCoursesToModifyAction,
    successful,
    changeSavingUnitAction,
    changeSavingLessonAction,
    changeSavingTopicAction,
    changeDeleteTopicAction,
    loadPaginatedCoursesAction,
    loadAllCoursesAction,
    loadAllCourseTypesAction,
    loadAllLessonTypesAction,
    loadAllGroupsLessonAction,
    clearAllGroupsLessonAction,
    setStudentsQtyAction,
    loadActiveCourseAction,
    loadActiveUnitsAction,
    loadActiveLessonsAction,
    loadActiveTopicsAction,
    loadActiveBlocksAction,
    loadCourseClonesQtyAction,
    loadCourseProgressAction,
    changeVisibilityAction,
    changeSavingCourseAction,
    updateCoursesAction,
    unableCloneSurveyAction,
    loadCourseTabsAction,
    setEmailAlertAction,
    setReportDataAction,
    setActiveCourseTabAction,
    clearCourseAlertAction,
    setCourseReportErrorAction,
    loadCourseStudentAction,
    clearSearchStudentAction,
} from './actions'

import { LESSON_MODAL } from '@/constants/modals'
import { actions as modalActions } from '../../modalReducer'
import { getTenantLang } from '@/helpers/tenants/helpers'

moment.locale(getTenantLang())

////////////////COURSE////////////////
// THUNK DEPRECADO, NO SEGUIR USANDOLO
export const loadActiveCourse = (courseId) => dispatch => {
    let config = getConfig()
    axios.get(api.courses + '/' + courseId , config)
        .then(data => {
            dispatch(loadActiveCourseAction(data))
        })
        .catch(err => {
            if (err.response && err.response.status) {
                if (err.response.status === 401) {
                    history.push('/admin/courses')
                } else {
                    dispatch(queryError(getErrorResponse(err)))
                }
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

export const loadActiveUnits = (courseId) => dispatch => {
    let config = getConfig()
    axios.get(`${api.courses}/${courseId}/units` , config)
        .then(data => {
            dispatch(loadActiveUnitsAction(data))
        })
        .catch(err => {
            if (err.response && err.response.status) {
                if (err.response.status === 401) {
                    history.push('/admin/courses')
                }else if (err.response.status === 404) {
                    history.push('/not-found')
                }else {
                    dispatch(queryError(getErrorResponse(err)))
                }
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

export const loadActiveLessons = (courseId, unitId) => dispatch => {
    let config = getConfig()
    axios.get(`${api.courses}/${courseId}/units/${unitId}/lessons` , config)
        .then(lessons => {
            dispatch(loadActiveLessonsAction({unitId, lessons}))
        })
        .catch(err => {
            if (err.response && err.response.status) {
                if (err.response.status === 401) {
                    history.push('/admin/courses')
                } else if (err.response.status === 404) {
                    history.push('/not-found')
                }else {
                    dispatch(queryError(getErrorResponse(err)))
                }
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

export const loadActiveTopics = (courseId, unitId, lessonId) => (dispatch) => {
    let config = getConfig()
    axios
        .get(`${api.courses}/${courseId}/units/${unitId}/lessons/${lessonId}/topics`, config)
        .then((topics) => {
            dispatch(loadActiveTopicsAction({ unitId, lessonId, topics }))
        })
        .catch((err) => {
            if (err.response && err.response.status) {
                if (err.response.status === 401) {
                    history.push('/admin/courses')
                }else if (err.response.status === 404) {
                    history.push('/not-found')
                }else {
                    dispatch(queryError(getErrorResponse(err)))
                }
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

export const loadActiveBlocks = (courseId, unitId, lessonId, topicId) => (dispatch) => {
    let config = getConfig()
    axios
        .get(
            `${api.courses}/${courseId}/units/${unitId}/lessons/${lessonId}/topics/${topicId}/blocks`,
            config
        )
        .then((blocks) => {
            dispatch(loadActiveBlocksAction({ unitId, lessonId, topicId, blocks }))
        })
        .catch((err) => {
            if (err.response && err.response.status) {
                if (err.response.status === 401) {
                    history.push('/admin/courses')
                }else if (err.response.status === 404) {
                    history.push('/not-found')
                }else {
                    dispatch(queryError(getErrorResponse(err)))
                }
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

export const loadStudentQuantityByCourse = (courseId) => dispatch => {
    axios.get(api.enrolments + "/studentQuantity?course_id=" + courseId, getConfig())
        .then(studentQuantity => {
            dispatch(setStudentsQtyAction(studentQuantity))
        })
        .catch((err) => {
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

export const loadAllGroupsByCourseId = (courseId) => dispatch => {
    axios.get(api.groupsLesson + '?course_id=' + courseId, getConfig())
        .then(groupsLesson => {
            dispatch(loadAllGroupsLessonAction(groupsLesson))
        })
        .catch((err) => {
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

export const loadAllCourseTypes = () => dispatch => {
    axios.get(api.courseTypes, getConfig())
        .then(courseTypes => {
            dispatch(loadAllCourseTypesAction(courseTypes))
        })
        .catch((err) => {
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

export const getCoursesToModify = (id, type, courseId) => (dispatch, getState) => {
    const allStates = getState()
    let config = getConfig()

    let apiRoute
    if (type == 'block') {
        apiRoute = api.coursesToModifyByBlock(id)
        apiRoute += '?course_id=' + courseId
    } else if (type == 'topic') {
        apiRoute = api.coursesToModifyByTopic(id)
        apiRoute += '?course_id=' + courseId
    } else if (type == 'lesson') {
        apiRoute = api.coursesToModifyByLesson(id)
        apiRoute += '?course_id=' + courseId
    }

    return axios.get(apiRoute, config)
        .then(coursesQuantity => {
            let modalText
            if (coursesQuantity > 0) {
                // modalText = `La siguiente acción también va a modificar ${coursesQuantity} cursos. ¿Está seguro que desea continuar?`
                modalText = allStates.langStore.courseReducer.modalTextPart1 + coursesQuantity + allStates.langStore.courseReducer.modalTextPart2
            } else {
                modalText = ''
            }

            dispatch(getCoursesToModifyAction(modalText))
        })
        .catch(err => {
            // if (err.response && err.response.status) {
            //   dispatch(queryError(getErrorResponse(err)))
            // } else {
            //   dispatch(getErrorResponse(err))
            // }
            console.log(err)
        })
}

export const loadAllCourses = (filters, options) => async dispatch => {
    dispatch(changeLoadingAction(true))
    let config = getConfig()
    try {
        const data = await axios.get(api.searchCourseURLGenerator({ ...options, ...filters }), config)
        dispatch(changeLoadingAction(false))
        if (data.rows !== undefined && data.pagesQuantity !== undefined && data.totalRows !== undefined) {
            dispatch(loadPaginatedCoursesAction(data))
        } else {
            dispatch(loadAllCoursesAction(data))
        }
    } catch (err) {
        if (err.response && err.response.status) {
            dispatch(changeLoadingAction(false))
            dispatch(queryError(getErrorResponse(err)))
        } else {
            dispatch(changeLoadingAction(false))
            dispatch(getErrorResponse(err))
        }
    }
}

export const saveNewCourse = async (data, allCourses, currentPage) => {
    const store = getStore()
    let config = getConfig()

    try {
        const savedCourse = await axios.post(api.courses, data, config)

        let allCoursesCopy = _.cloneDeep(allCourses)
        savedCourse.Units = Array()
        if (!allCoursesCopy.courses) {
            allCoursesCopy.courses = []
        }
        allCoursesCopy.courses.push(savedCourse)
        allCoursesCopy.courses = _.orderBy(allCoursesCopy.courses, ['created_at'], ['desc'])
        if (allCoursesCopy.courses.length > allCoursesCopy.limit) {
            allCoursesCopy.courses.pop()
        }
        store.dispatch(changeSavingCourseAction(false))
        store.dispatch(updateCoursesAction(allCoursesCopy))
        store.dispatch(clearCourseAlertAction())
    } catch (err) {
        store.dispatch(changeSavingCourseAction(false))
        store.dispatch(queryError(getErrorResponse(err)))

        throw err
    }
}

export const updateOneCourse = async (data, allCourses) => {
    const store = getStore()
    let config = getConfig()

    try {
        const updatedCourse = await axios.patch(api.courses + '/' + data.id, data, config)

        let allCoursesCopy = _.cloneDeep(allCourses)
        let courseAux = _.find(allCoursesCopy.rows, { 'id': updatedCourse.id })
        courseAux = Object.assign(courseAux, updatedCourse)

        store.dispatch(updateCoursesAction(allCoursesCopy))
        store.dispatch(changeSavingCourseAction(false))
        store.dispatch(clearCourseAlertAction())
    } catch (err) {
        store.dispatch(changeSavingCourseAction(false))
        store.dispatch(queryError(getErrorResponse(err)))

        throw err
    }
}

export const cloneCourse = async (data, allCourses) => {
    const store = getStore()
    const allStates = store.getState()
    let config = getConfig()

    try {
        const clonedCourse = await axios.post(api.cloneCourse, data, config)

        if (clonedCourse.code === 206) {
            let allCoursesCopy = _.cloneDeep(allCourses)
            allCoursesCopy.rows.push(clonedCourse)
            allCoursesCopy.rows = _.orderBy(allCoursesCopy.courses, ['created_at'], ['desc'])
            if (allCoursesCopy.rows.length > allCoursesCopy.limit) {
                allCoursesCopy.rows.pop()
            }
            allCoursesCopy.rows.pop()

            store.dispatch(changeSavingCourseAction(false))
            store.dispatch(updateCoursesAction(allCoursesCopy))
            // store.dispatch(unableCloneSurveyAction("No se pudieron clonar las encuestas"))
            store.dispatch(unableCloneSurveyAction(allStates.langStore.courseReducer.unableCloneSurvey))

            store.dispatch(clearCourseAlertAction())
        }
        else {
            let allCoursesCopy = _.cloneDeep(allCourses)
            allCoursesCopy.rows.push(clonedCourse)
            store.dispatch(changeSavingCourseAction(false))
            store.dispatch(updateCoursesAction(allCoursesCopy))

            store.dispatch(clearCourseAlertAction())
        }
    } catch (err) {
        store.dispatch(changeSavingCourseAction(false))
        store.dispatch(queryError(getErrorResponse(err)))

        throw err
    }
}

export const loadCourseProgress = (courseId) => dispatch => {
    const config = getConfig()

    axios.get(api.reports(courseId), config)
        .then(data => {
            dispatch(setCourseReportErrorAction(false))
            dispatch(loadCourseProgressAction(data))
        })
        .catch(err => {
            dispatch(setCourseReportErrorAction(true))
            dispatch(getErrorResponse(err))
        })
}

export const loadpreworkProgresss = (courseId, userId) => dispatch => {
    const config = getConfig()
    axios.get(api.preworkReport(courseId, userId), config)
        .then(data => {
            dispatch(setCourseReportErrorAction(false))
            dispatch(loadCourseProgressAction(data))
        })
        .catch(err => {
            dispatch(setCourseReportErrorAction(true))
            dispatch(getErrorResponse(err))
        })
}

export const loadCourseClonesQty = (courseId) => dispatch => {
    let config = getConfig()
    axios.get(api.courses + '/' + courseId + '/clonesQty', config)
        .then(data => {
            dispatch(loadCourseClonesQtyAction(data.qty))
        })
        .catch(err => {
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}



////////////////UNITS////////////////

//paso current role
export const updateOneUnit = (data, activeCourse) => dispatch => {

    let config = getConfig()
    let body = {
        id: data.unitId,
        course_id: data.courseId,
        name: data.name,
        order: data.order,
        visibility: data.visibility,
        release_date: data.release_date,
        description: data.description
    }
    axios.patch(api.units(data.courseId) + '/' + data.unitId, body, config)//VER RUTA
        .then(updatedUnit => {
            let activeCourseCopy = _.cloneDeep(activeCourse)

            let unit = _.find(activeCourseCopy.Units, { 'id': updatedUnit.id })
            unit.name = updatedUnit.name
            unit.order = updatedUnit.order
            unit.visibility = updatedUnit.visibility
            unit.release_date = updatedUnit.release_date
            unit.description = updatedUnit.description

            dispatch(changeSavingUnitAction(false))
            dispatch(updateActiveCourseAction(activeCourseCopy))
        })
        .catch(err => {
            if (err.response && err.response.status) {
                dispatch(changeSavingUnitAction(false))
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(changeSavingUnitAction(false))
                dispatch(getErrorResponse(err))
            }
        })
}

//paso current role
export const saveNewUnit = (data, activeCourse) => dispatch => {

    let config = getConfig() //PERMISOS :)
    let body = {
        course_id: data.courseId,
        name: data.name,
        visibility: data.visibility,
        release_date: data.release_date,
        description: data.description
    }

    axios.post(api.units(data.courseId), body, config)
        .then(savedUnit => {
            let activeCourseCopy = _.cloneDeep(activeCourse)

            // let course = activeCourseCopy.id
            savedUnit.Lessons = Array()
            activeCourseCopy.Units.push(savedUnit)

            dispatch(changeSavingUnitAction(false))
            dispatch(updateActiveCourseAction(activeCourseCopy))
        })
        .catch(err => {
            if (err.response && err.response.status) {
                dispatch(changeSavingUnitAction(false))
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(changeSavingUnitAction(false))
                dispatch(getErrorResponse(err))
            }
        })
}

//paso current role
export const toggleDisableUnit = (data) => (dispatch, getState) => {
    const allStates = getState()
    const config = getConfig()

    axios.patch(api.units(data.courseId) + '/order', getItemOrderValues(data.activeCourse.Units), config)
        .then((data) => {
            // dispatch(successful('Editado el orden de las lessons'))
            dispatch(successful(allStates.langStore.courseReducer.successfulLessonMessage))
        })
        .catch(err => {
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}


////////////////LESSON////////////////

//paso current role
export const saveNewLesson = (data, activeCourse) => dispatch => {
    let config = getConfig()
    let body = {
        unit_id: data.unitId, //?????
        name: data.name,
        visibility: data.visibility,
        release_date: data.release_date,
        due_date: data.due_date,
        lesson_type_id: data.lesson_type_id,
        isBaseCourse: data.isBaseCourse
    }

    axios.post(api.lessons(data.courseId, data.unitId), body, config)
        .then(savedLesson => {
            let activeCourseCopy = _.cloneDeep(activeCourse)
            let unit = _.find(activeCourseCopy.Units, { 'id': data.unitId }) // CON LO Q VIENE DEL BACK? UNITID O UNIT_ID??

            savedLesson.Topics = Array()
            unit.release_date = savedLesson.Unit.release_date
            if (!unit.Lessons) unit.Lessons = Array()

            unit.Lessons.push(savedLesson)

            dispatch(changeSavingLessonAction(false))
            dispatch(updateActiveCourseAction(activeCourseCopy))
            dispatch(clearAllGroupsLessonAction())
        })
        .catch(err => {
            if (err.response && err.response.status) {
                dispatch(changeSavingLessonAction(false))
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(changeSavingLessonAction(false))
                dispatch(getErrorResponse(err))
            }
        })
}

//paso current role
export const updateOneLesson = (data, activeCourse) => dispatch => {

    let config = getConfig()
    let body = {
        id: data.id,
        course_id: data.courseId,
        unit_id: data.unitId,
        name: data.name,
        order: data.order,
        visibility: data.visibility,
        release_date: data.release_date,
        due_date: data.due_date,
        lesson_type_id: data.lesson_type_id,
        isBaseCourse: data.isBaseCourse
    }
    axios.patch(api.lessons(data.courseId, data.unitId) + '/' + data.id, body, config)//VER RUTA
        .then(updatedLesson => {
            let activeCourseCopy = _.cloneDeep(activeCourse)
            let unit = _.find(activeCourseCopy.Units, { 'id': updatedLesson.unit_id })
            let lesson = _.find(unit.Lessons, { 'id': updatedLesson.id })

            // si la visibilidad es true, todos sus hijos heredan esa visibilidad
            if (updatedLesson.visibility) {
                let updatedTopics = lesson.Topics?.map(topic => {
                    topic.Blocks.map(block => {
                        block.visibility = true
                        return block
                    })
                    topic.visibility = true
                    return topic
                })
            }
            lesson.name = updatedLesson.name
            lesson.order = updatedLesson.order
            lesson.visibility = updatedLesson.visibility
            lesson.release_date = updatedLesson.release_date
            lesson.due_date = updatedLesson.due_date
            lesson.lesson_type_id = updatedLesson.lesson_type_id
            lesson.GroupLesson = updatedLesson.GroupLesson

            unit.release_date = updatedLesson.Unit.release_date

            dispatch(changeSavingLessonAction(false))
            dispatch(updateActiveCourseAction(activeCourseCopy))
            dispatch(clearAllGroupsLessonAction())
        })
        .catch(err => {
            if (err.response && err.response.status) {
                dispatch(changeSavingLessonAction(false))
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(changeSavingLessonAction(false))
                dispatch(getErrorResponse(err))
            }
        })

}

//paso current role
export const toggleDisableLesson = (data) => (dispatch, getState) => {
    const allStates = getState()
    let config = getConfig()

    let course = data.activeCourse
    let unit = _.find(course.Units, { 'id': data.unitId })
    axios.patch(api.lessons(data.courseId, data.unitId) + '/order', getItemOrderValues(unit.Lessons), config)
        .then((data) => {
            // dispatch(successful('Editado el orden de las lessons'))
            dispatch(successful(allStates.langStore.courseReducer.successfulLessonMessage))
        })
        .catch(err => {
            dispatch(getErrorResponse(err))
        })
}

export const closeLessonModalAndClearGroupLesson = () => dispatch => {
    dispatch(clearAllGroupsLessonAction())
    dispatch(modalActions.close(LESSON_MODAL))
}


////////////////TOPIC////////////////

//paso current role y courseid
export const saveNewTopic = (data, activeCourse) => dispatch => {
    let config = getConfig() //PERMISOS :)
    let body = {
        lesson_id: data.lessonId,
        name: data.name,
        disable_student_visibility: data.disableStudentVisibility,
        visibility: data.visibility,
        base: activeCourse.base,
    }

    axios.post(api.topics(data.courseId, data.unitId, data.lessonId), body, config)
        .then(savedTopicResult => {
            let savedTopic
            let updatedClones = {}
            if (Array.isArray(savedTopicResult)) {
                savedTopic = savedTopicResult[0]
                updatedClones = savedTopicResult[1]
            } else {
                savedTopic = savedTopicResult
            }
            let activeCourseCopy = _.cloneDeep(activeCourse)
            let unit = _.find(activeCourseCopy.Units, { 'id': data.unitId })

            let lesson = _.find(unit.Lessons, { 'id': data.lessonId })
            if (!lesson.Topics) lesson.Topics = Array()

            savedTopic.disableStudentVisibility = savedTopic.disable_student_visibility
            lesson.Topics.push(savedTopic)
            savedTopic.Blocks = Array()
            dispatch(changeSavingTopicAction(false))
            dispatch(updateActiveCourseAction(activeCourseCopy))
            dispatch(setUpdatedClonesAction(updatedClones))
        })
        .catch(err => {
            if (err.response && err.response.status) {
                dispatch(changeSavingTopicAction(false))
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(changeSavingTopicAction(false))
                dispatch(getErrorResponse(err))
            }
        })
}

//paso current role y courseid
export const updateOneTopic = (data, activeCourse) => dispatch => {

    let config = getConfig()
    let body = {
        id: data.id,
        lesson_id: data.lessonId,
        name: data.name,
        order: data.order,
        visibility: data.visibility,
        disable_student_visibility: data.disableStudentVisibility,
        base: activeCourse.base,
    }
    axios.put(api.topics(data.courseId, data.unitId, data.lessonId) + '/' + data.id, body, config)
        .then(updatedTopicResult => {
            let updatedTopic
            let updatedClones = {}
            if (Array.isArray(updatedTopicResult)) {
                updatedTopic = updatedTopicResult[0]
                updatedClones = updatedTopicResult[1]
            } else {
                updatedTopic = updatedTopicResult
            }

            let activeCourseCopy = _.cloneDeep(activeCourse)
            let unit = _.find(activeCourseCopy.Units, { 'id': data.unitId })
            let lesson = _.find(unit.Lessons, { 'id': updatedTopic.lesson_id })

            let topic = _.find(lesson.Topics, { 'id': updatedTopic.id })
            topic.name = updatedTopic.name
            topic.order = updatedTopic.order
            topic.visibility = updatedTopic.visibility
            topic.disableStudentVisibility = updatedTopic.disable_student_visibility
            topic.TopicRoles = updatedTopic.TopicRoles
            dispatch(changeSavingTopicAction(false))
            dispatch(updateActiveCourseAction(activeCourseCopy))
            dispatch(setUpdatedClonesAction(updatedClones))
        })
        .catch(err => {
            if (err.response && err.response.status) {
                dispatch(changeSavingTopicAction(false))
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(changeSavingTopicAction(false))
                dispatch(getErrorResponse(err))
            }
        })
}

export const deleteOneTopic = (data, activeCourse) => dispatch => {

    let config = getConfig()
    axios.delete(api.topics(data.courseId, data.unitId, data.lessonId) + '/' + data.topicId, config)
        .then(() => {
            const activeCourseCopy = _.cloneDeep(activeCourse)
            const unit = _.find(activeCourseCopy.Units, { 'id': data.unitId })
            const lesson = _.find(unit.Lessons, { 'id': data.lessonId })
            const topic = _.findIndex(lesson.Topics, { 'id': data.topicId })
            lesson.Topics.splice(topic, 1)

            dispatch(updateActiveCourseAction(activeCourseCopy))
            dispatch(changeDeleteTopicAction(false))
        })
        .catch(err => {
            dispatch(changeDeleteTopicAction(false))
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

//paso current role y courseid
export const toggleDisableTopic = (data) => (dispatch, getState) => {
    const allStates = getState()
    const config = getConfig()

    let course = data.activeCourse
    let unit = _.find(course.Units, { 'id': data.unitId })
    let lesson = _.find(unit.Lessons, { 'id': data.lessonId })
    axios.patch(api.topics(data.courseId, data.unitId, data.lessonId) + '/order', getItemOrderValues(lesson.Topics), config)
        .then(() => {
            dispatch(successful(allStates.langStore.courseReducer.successfulTopicMessage))
        })
        .catch(err => {
            dispatch(getErrorResponse(err))
        })
}


////////////////BLOCK////////////////

//paso current role
export const saveNewBlock = (data, activeCourse) => dispatch => {

    let config = getConfig() //PERMISOS :)
    let body = {
        topic_id: data.topicId,
        name: data.name,
        show_name: data.show_name,
        order: data.order,
        visibility: data.visibility,
        exercise_block_id: data.exerciseId || null,
        text_block_content: data.textContent || null,
        video_block_content: data.videoContent || null,
        slide_block_content: data.slideContent || null,
        pdf_block_content: data.pdfContent || null,
        file_block_id: data.fileBlockId || null,
        quiz_block_id: data.quizId || null,
        description: data.description || null,
        video_block_id: null,
        slide_block_id: null,
        pdf_block_id: null,
        base: activeCourse.base,

        replicationData: (activeCourse.base ? {
            courseId: data.courseId,
            unitId: data.unitId,
            lessonId: data.lessonId,
            topicId: data.topicId,
        } : null)
    }
    if (data.extra) {
        body.extra = data.extra
    }

    axios.post(api.blocks(data.courseId, data.unitId, data.lessonId, data.topicId), body, config)
        .then(savedBlockResult => {
            let savedBlock
            let updatedClones = {}

            if (Array.isArray(savedBlockResult)) {
                savedBlock = savedBlockResult[0]
                updatedClones = savedBlockResult[1]
            } else {
                savedBlock = savedBlockResult
            }

            let activeCourseCopy = _.cloneDeep(activeCourse)
            let unit = _.find(activeCourseCopy.Units, { 'id': data.unitId })
            let lesson = _.find(unit.Lessons, { 'id': data.lessonId })
            let topic = _.find(lesson.Topics, { id: data.topicId })
            if (!topic.Blocks) topic.Blocks = Array()

            topic.Blocks.push(savedBlock)
            dispatch(changeSavingBlockAction(false))

            dispatch(updateActiveCourseAction(activeCourseCopy))
            dispatch(setUpdatedClonesAction(updatedClones))

        })
        .catch(err => {
            if (err.response && err.response.status) {
                dispatch(changeSavingBlockAction(false))
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(changeSavingBlockAction(false))
                dispatch(getErrorResponse(err))
            }
        })
}

//paso current role
export const updateOneBlock = (data, activeCourse) => dispatch => {
    let config = getConfig()
    let body = {
        id: data.id,
        topic_id: data.topicId,
        name: data.name,
        show_name: data.show_name,
        visibility: data.visibility,
        exercise_block_id: data.exerciseId || null,
        text_block: { content: data.textContent || null, id: data.textBlockId },
        file_block_id: data.fileBlockId || null,
        quiz_block_id: data.quizId || null,
        description: data.description || null,
        video_block_content: data.videoContent || null,
        slide_block_content: data.slideContent || null,
        pdf_block_content: data.pdfContent || null,
        video_block_id: data.videoBlockId || null,
        slide_block_id: data.slideBlockId || null,
        pdf_block_id: data.pdfBlockId || null,
        extra: data.extra,
        base: activeCourse.base,
        replicationData: (activeCourse.base ? {
            courseId: data.courseId,
            unitId: data.unitId,
            lessonId: data.lessonId,
            topicId: data.topicId,
        } : null)
    }

    axios.put(api.blocks(data.courseId, data.unitId, data.lessonId, data.topicId) + '/' + data.id, body, config)
        .then(updatedBlockResult => {
            let updatedBlock
            let updatedClones = {}
            if (Array.isArray(updatedBlockResult)) {
                updatedBlock = updatedBlockResult[0]
                updatedClones = updatedBlockResult[1]
            } else {
                updatedBlock = updatedBlockResult
            }

            let activeCourseCopy = _.cloneDeep(activeCourse)
            let unit = _.find(activeCourseCopy.Units, { 'id': data.unitId })
            let lesson = _.find(unit.Lessons, { 'id': data.lessonId })
            let topic = _.find(lesson.Topics, { id: data.topicId })
            let block = _.find(topic.Blocks, { id: updatedBlock.id })

            block.name = updatedBlock.name
            block.show_name = updatedBlock.show_name
            block.order = updatedBlock.order
            block.visibility = updatedBlock.visibility
            block.description = updatedBlock.description
            block.exercise_block_id = updatedBlock.exercise_block_id
            block.exercise_quiz_id = updatedBlock.exercise_quiz_id
            block.ExerciseBlock = updatedBlock.ExerciseBlock
            block.TextBlock = updatedBlock.TextBlock
            block.QuizBlock = updatedBlock.QuizBlock
            block.VideoBlock = updatedBlock.VideoBlock
            block.SlideBlock = updatedBlock.SlideBlock
            block.PdfBlock = updatedBlock.PdfBlock
            block.FileBlock = updatedBlock.FileBlock
            block.TextHtmlBlock = updatedBlock.TextHtmlBlock
            block.extra = updatedBlock.extra

            dispatch(changeSavingBlockAction(false))
            dispatch(updateActiveCourseAction(activeCourseCopy))
            dispatch(setUpdatedClonesAction(updatedClones))
        })
        .catch(err => {
            dispatch(changeSavingBlockAction(false))
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

//paso current role
export const toggleDisableBlock = (data) => (dispatch, getState) => {
    const allStates = getState()
    const config = getConfig()

    let course = data.activeCourse
    let unit = _.find(course.Units, { 'id': data.unitId })
    let lesson = _.find(unit.Lessons, { 'id': data.lessonId })
    let topic = _.find(lesson.Topics, { 'id': data.topicId })
    axios.patch(api.blocks(data.courseId, data.unitId, data.lessonId, data.topicId) + '/order', getItemOrderValues(topic.Blocks), config)
        .then(() => {
            // dispatch(successful('Editado el orden de los temas'))
            dispatch(successful(allStates.langStore.courseReducer.successfulTopicMessage))
        })
        .catch(err => {
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}


// FUNCTIONS

export const loadAllLessonTypes = () => dispatch => {
    axios.get(api.lessonTypes, getConfig())
        .then(lessonTypes => {
            dispatch(loadAllLessonTypesAction(lessonTypes))
        })
        .catch((err) => {
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}


////////////////VISIBILITY GENERAL////////////////

//paso el current role
export const changeVisibility = (data) => dispatch => {
    let config = getConfig()
    let apiRoute = api[data.visibilityRoute]

    switch (data.visibilityRoute) {
        case "units":
            apiRoute = apiRoute(data.courseId)
            apiRoute += "/" + data.unitId
            break
        case "lessons":
            apiRoute = apiRoute(data.courseId, data.unitId)
            apiRoute += "/" + data.lessonId
            break
        case "topics":
            apiRoute = apiRoute(data.courseId, data.unitId, data.lessonId)
            apiRoute += "/" + data.topicId
            break
        case "blocks":
            apiRoute = apiRoute(data.courseId, data.unitId, data.lessonId, data.topicId)
            apiRoute += "/" + data.blockId
            break
    }
    apiRoute += "/visibility"

    axios.patch(apiRoute, { visibility: data.visibility }, config)
        .then(() => {
            dispatch(changeVisibilityAction(data))
        })
        .catch(err => {
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

////////////////TABS////////////////

export const loadCourseTabs = (courseId) => dispatch => {
    let config = getConfig()
    axios.get(api.getTabs(courseId), config)
        .then(info => {
            dispatch(loadCourseTabsAction(info))
        })
        .catch(err => {
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

export const handleTabContentChange = (data) => dispatch => {
    let tab = data.tab

    tab[data.key] = data.content

    dispatch(setActiveCourseTabAction(tab))
}

export const getOneTab = (tab_id, courseId) => dispatch => {
    const config = getConfig()
    axios.get(api.getTab(tab_id) + `?course_id=${courseId}`, config)
        .then(info => {
            dispatch(setActiveCourseTabAction(info))
        })
        .catch(err => {
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

export const saveTabContent = (data, courseId) => dispatch => {
    let body = {
        name: data.name,
        tab_info: data.tab_info,
        is_editable: data.is_editable,
        visibility: data.visibility
    }
    let config = getConfig()
    axios.put(api.editTabs(data.id) + `?course_id=${courseId}`, body, config)
        .then(res => res)
        .catch(err => {
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

export const sendEmail = (data) => dispatch => {
    let config = getConfig()
    axios.post(api.emails(data.courseId), data, config)
        .then(info => {
            dispatch(setEmailAlertAction(true))
            history.push('/admin/courses')
        })
        .catch(err => {
            dispatch(setEmailAlertAction(true))
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

export const getReportData = (courseId, blockId) => dispatch => {
    let config = getConfig()
    axios.get(api.blockReport(courseId, blockId), config)
        .then(data => {
            dispatch(setReportDataAction(data))
        })
        .catch(err => {
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

export const loadCourseStudent = async (courseId, name, surname, email) => {
    const store = getStore()
    const studentResult = await services.searchStudent(courseId, name, surname, email)
    store.dispatch(loadCourseStudentAction(studentResult))
}

export const clearSearchStudent = async () => {
    const store = getStore()
    store.dispatch(clearSearchStudentAction())
}

export const getExerciseData = async (userId, courseId, blockId) => {
    const store = getStore()
    const studentExerciseResult = await services.exerciseProgress(userId, courseId, blockId)
    await store.dispatch(setReportDataAction(studentExerciseResult))
}
