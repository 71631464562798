export const initialState = {
    id: null,
    name: "",
    description: "",
    initial_date: "",
    finish_date: "",
    alias: "",
    Units: [],
    CourseTools: [],
    UserHasOwnershipRoles: [],
}

export const GET_COURSE_INFO = "@@courseInfo/GET_COURSE_INFO"
export const CLEAR_COURSE_INFO = "@@courseInfo/CLEAR_COURSE_INFO"

export const getCourseInfoAction = (course) => ({
    type: GET_COURSE_INFO,
    payload: course,
})
export const clearCourseInfoAction = () => ({
    type: CLEAR_COURSE_INFO
})

const courseInfoReducer = (state = initialState, { type, payload }) => {
    const types = {
        [GET_COURSE_INFO]: () => ({
            ...state,
            ...payload
        }),
        [CLEAR_COURSE_INFO]: () => ({
            ...state,
            courseInfo: initialState
        })
    }
    return types[type] ? types[type]() : state
}

export default courseInfoReducer 