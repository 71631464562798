import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash/lang'
import { PdfBlock, SlideBlock, VideoBlock } from '@/constants/blocks'
import { YOUTUBE, VIMEO, VIMEO_PLAYER_URL, YOUTUBE_PLAYER_URL } from '@/constants/videoBlock'

export default ({ unformattedUrl, allow_download, blockType, block, isMobile }) => {

    const { lang } = useSelector(({ langStore: { pdfBlock, topicBlock } }) => ({
        lang: { ...pdfBlock, ...topicBlock },
    }))

    const allowDownload = useMemo(() => {
        return (
            allow_download &&
            (blockType === PdfBlock ||
                (blockType === SlideBlock &&
                    (!unformattedUrl.includes('embed') || !unformattedUrl.includes('2PACX-'))))
        )
    }, [unformattedUrl, allow_download, blockType])

    const url = useMemo(() => {
        if (blockType === VideoBlock) {
            if (block.VideoBlock.type == YOUTUBE) {
                return `${YOUTUBE_PLAYER_URL}${unformattedUrl}`
            }
            if (block.VideoBlock.type == VIMEO) {
                // TO-DO: Validar por qué se reinicia el video al ir a pantalla completa 
                //agregar "#t=0s" a la url no está especificado en la documentación para este caso, pero soluciona el bug
                return `${VIMEO_PLAYER_URL}${unformattedUrl}#t=0s`
            }
        }

        if (blockType === SlideBlock) {
            return unformattedUrl.replace('edit', 'preview')
        }

        return unformattedUrl
    }, [blockType, unformattedUrl])

    const rotateMessage = useMemo(
        () => ({
            [SlideBlock]: lang.slideRotatePhoneMessage,
            [VideoBlock]: lang.videoRotatePhoneMessage,
        }),
        [lang]
    )

    const height = useMemo(() => {
        if ([SlideBlock, PdfBlock, VideoBlock].includes(blockType)) {
            return isMobile ? 180 : 538
        }

        const { height } = block[blockType]

        if (!isEmpty(height)) {
            return height
        }

        return 100
    }, [block, blockType, isMobile])

    const width = useMemo(() => {
        const { width } = block[blockType]

        if (!isEmpty(width)) {
            return width
        }

        return 100
    }, [block, blockType])

    return {
        id: block.id,
        url,
        lang,
        rotateMessage,
        height,
        width,
        allowDownload
    }
}
