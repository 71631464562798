import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ToolsEmptyState from './ToolsEmptyState'
import ToolListActions from './ToolListActions'
import ToolsModal from './ToolsModal'
import ToolPanel from './ToolPanel'
import useHistory from '@/hooks/useHistory'
import GenericModal from '@/components/GenericModal'
import { Manager } from '@/components/Manager'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'

const ToolList = ({
    courseName,
    deleteCourseTool,
    handleSubmit,
    lang,
    managerDataLoader,
    searcherFields,
    tools,
    showCreateButton,
    showEditButton,
    showDeleteButton,
}) => {
    const EMPTY_TOOL = { name: '', description: '', tool_url: '', tutorial_url: '' }

    const history = useHistory()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showFormModal, setShowFormModal] = useState(false)
    const [selectedTool, setSelectedTool] = useState(EMPTY_TOOL)

    const content = tools.length > 0
    const { course_id: courseId } = searcherFields

    const addActions = tools =>
        tools.map(tool => ({
            ...tool,
            actions: (
                <ToolListActions
                    showEditButton={showEditButton}
                    showDeleteButton={showDeleteButton}
                    openFormModal={() => openFormModal(tool)}
                    openDeleteModal={() => openDeleteModal(tool)}
                />
            ),
        }))

    const getDeletionMessageComponent = () => (
        <p className="confirmDeleteMessage">
            {`${lang.confirmToolDeletionStart} ${selectedTool ? selectedTool.name : ''} ${
                lang.confirmToolDeletionEnd
                }`}
        </p>
    )

    const openDeleteModal = tool => {
        setSelectedTool(tool)
        setShowDeleteModal(true)
    }

    const closeDeleteModal = () => {
        setSelectedTool(EMPTY_TOOL)
        setShowDeleteModal(false)
    }

    const confirmDelete = () => {
        deleteCourseTool(courseId, selectedTool.id)
        closeDeleteModal()
    }

    const openFormModal = tool => {
        setSelectedTool(EMPTY_TOOL)
        setSelectedTool(tool)
        setShowFormModal(true)
    }

    const closeFormModal = () => {
        setSelectedTool(EMPTY_TOOL)
        setShowFormModal(false)
    }

    const handleListChanges = submittedTool => {
        handleSubmit(submittedTool)
        closeFormModal()
    }

    return (
        <>
            <Manager
                emptyStateComponent={<ToolsEmptyState text={lang.noToolsYet} />}
                goBackText={lang.returnButton}
                handleGoBack={history.goBack}
                headerEnabled
                headerRightToolbar={
                    showCreateButton &&
                    <Button
                        className="pull-right"
                        id="addNewToolButton"
                        onClick={() => openFormModal(selectedTool)}
                        text={lang.addTool}
                        theme='tertiary'
                    />
                }
                managerDataLoader={managerDataLoader}
                screenSmallTitle={courseName}
                screenTitle={lang.digitalTools}
                searcherFields={searcherFields}
                noContent={!content}
                searchOnMount
            >
                {addActions(tools).map(tool => (
                    <ToolPanel key={tool.id} tool={tool} />
                ))}
            </Manager>

            <ToolsModal
                cancelTitle={lang.cancel}
                confirmTitle={lang.save}
                lang={lang}
                modalTitle={selectedTool.name !== '' ? lang.editTool : lang.createTool}
                onCancel={closeFormModal}
                onSubmit={submittedTool => handleListChanges(submittedTool)}
                show={showFormModal}
                tool={selectedTool}
            />

            <GenericModal
                cancelTitle={lang.cancel}
                className="courseToolDeleteModal"
                confirmTitle={lang.delete}
                onCancel={closeDeleteModal}
                onConfirm={confirmDelete}
                show={showDeleteModal}
            >
                {getDeletionMessageComponent()}
            </GenericModal>
        </>
    )
}

ToolList.propTypes = {
    courseName: PropTypes.string.isRequired,
    deleteCourseTool: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    lang: PropTypes.shape({
        confirmToolDeletionStart: PropTypes.string.isRequired,
        confirmToolDeletionEnd: PropTypes.string.isRequired,
        digitalTools: PropTypes.string.isRequired,
        noToolsYet: PropTypes.string.isRequired,
        addTool: PropTypes.string.isRequired,
        returnButton: PropTypes.string.isRequired,
        editTool: PropTypes.string.isRequired,
        createTool: PropTypes.string.isRequired,
        save: PropTypes.string.isRequired,
        cancel: PropTypes.string.isRequired,
        delete: PropTypes.string.isRequired,
    }).isRequired,
    managerDataLoader: PropTypes.func.isRequired,
    searcherFields: PropTypes.shape({
        search: PropTypes.object,
        course_id: PropTypes.string.isRequired,
    }).isRequired,
    tools: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            tool_url: PropTypes.string.isRequired,
            tutorial_url: PropTypes.string,
        }).isRequired,
    ).isRequired,
    showCreateButton: PropTypes.bool.isRequired,
    showEditButton: PropTypes.bool.isRequired,
    showDeleteButton: PropTypes.bool.isRequired,
}

export default ToolList
