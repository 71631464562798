import React from 'react'
import PropTypes from 'prop-types'
import { ChevronRight, X } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'

const BrowserConsole = ({ logs, throws, height, width, close }) => {
    return (
        <div className='BrowserConsole' style={{ width, height }}>
            <div className='header'>
                <div className='closeBtnConsole' onClick={() => close(false)}>
                    <ChevronRight />
                </div>
                <div className='consoleTitle'>Console</div>
            </div>
            <div className='logArea'>
                {logs.map((log, i) => (
                    <span className='grayLog' key={`log${i}`}>
                        <ChevronRight /> {log}
                    </span>
                ))}
                {throws.map((t, i) => (
                    <span className='throwError' key={`throw${i}`}>
                        <X /> ThrowError: {t}
                    </span>
                ))}
            </div>
        </div>
    )
}

BrowserConsole.defaultProps = {
    logs: [],
    throws: [],
    height: '100%',
    width: '350px',
}

BrowserConsole.propTypes = {
    logs: PropTypes.arrayOf(PropTypes.shape()),
    throws: PropTypes.arrayOf(PropTypes.shape()),
    height: PropTypes.string,
    width: PropTypes.string,
    close: PropTypes.func.isRequired,
}

export default BrowserConsole
