import React from 'react'
import PropTypes from 'prop-types'
import { getTenantLang } from '@/helpers/tenants/helpers'
import InputTag from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputTag'
import TextAreaInput from '@digitalhouse-tech/react-lib-ui-explorer/lib/TextAreaInput'
import LimitCounter from '@digitalhouse-tech/react-lib-ui-explorer/lib/LimitCounter'
import {
    EXCLAMATION_ICON,
    DANGER_STATUS,
    MAX_REVIEW_CHARS,
    MAX_REJECT_REASONS_TAGS,
} from '../../../shared/constants/dashboard'
import './RequestRedo.scss'

const RequestRedo = ({
    reasonsHasError,
    rejectTags,
    reviewCounterText,
    review,
    reviewHasError,
    requiredInputMsg,
    reviewTitle,
    noResultsText,
    onReasonsChange,
    onReviewChange,
    reasonsCounterText,
    selectedReasons,
    title,
    textCharsCount,
}) => {
    return (
        <div className='request-redo-modal'>
            <p className='request-redo-modal__title'>{title}</p>
            <div className='request-redo-modal__reasons'>
                <LimitCounter
                    limitTheme={DANGER_STATUS}
                    className='request-redo-modal__limit-counter'
                    limit={MAX_REJECT_REASONS_TAGS}
                    icon={EXCLAMATION_ICON}
                    limitReached={selectedReasons.length === MAX_REJECT_REASONS_TAGS}
                    text={reasonsCounterText}
                    count={Number(selectedReasons.length)}
                />
            </div>
            <InputTag
                dropdownItems={rejectTags}
                maxTags={MAX_REJECT_REASONS_TAGS}
                noResultsText={noResultsText}
                selectedTags={selectedReasons}
                id='requestRedoReasonsTags'
                onChange={onReasonsChange}
                error={reasonsHasError}
                errorMsg={requiredInputMsg}
                createNewTag
                langCountry={getTenantLang()}
            />
            <div className='request-redo-modal__explanation'>
                <p className='request-redo-modal__explanation-title'>{reviewTitle}</p>
                <LimitCounter
                    limitTheme={DANGER_STATUS}
                    className='request-redo-modal__limit-counter'
                    limit={MAX_REVIEW_CHARS}
                    icon={EXCLAMATION_ICON}
                    limitReached={textCharsCount >= MAX_REVIEW_CHARS}
                    text={reviewCounterText}
                    count={textCharsCount}
                />
            </div>
            <TextAreaInput
                glow={false}
                onChange={onReviewChange}
                value={review}
                inputName='request-redo-explanation-text'
                id='requestRedoExplanationText'
                error={reviewHasError}
                errorMsg={requiredInputMsg}
            />
        </div>
    )
}

RequestRedo.propTypes = {
    rejectTags: PropTypes.array,
    reviewCounterText: PropTypes.string,
    review: PropTypes.string,
    reviewHasError: PropTypes.bool,
    requiredInputMsg: PropTypes.string,
    reviewTitle: PropTypes.string,
    noResultsText: PropTypes.string,
    onReasonsChange: PropTypes.func,
    onReviewChange: PropTypes.func,
    reasonsCounterText: PropTypes.string,
    selectedReasons: PropTypes.array,
    title: PropTypes.string,
    textCharsCount: PropTypes.number,
}

RequestRedo.defaultProps = {
    rejectTags: [],
    reviewCounterText: '',
    review: '',
    reviewHasError: false,
    requiredInputMsg: '',
    reviewTitle: '',
    noResultsText: '',
    onReasonsChange: () => {},
    onReviewChange: () => {},
    reasonsCounterText: '',
    selectedReasons: '',
    title: '',
    textCharsCount: 0,
}

export default RequestRedo
