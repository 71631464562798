import { useSelector } from 'react-redux'
import { animationAsset, backgroundAsset } from '@/assets/img/config'

const useVideoHero = () => {
    const { notSupportedVideoTag } = useSelector((state) => state.langStore.login)
    const { isMobile } = useSelector((state) => state.authReducer)

    const videoSrc = !isMobile ? animationAsset('login-video.mp4') : ''
    const posterSrc = !isMobile
        ? backgroundAsset('login-bg-desktop.jpg')
        : backgroundAsset('login-bg-mobile.jpg')

    return {
        videoSrc,
        posterSrc,
        autoPlay: !isMobile,
        notSupportedMsg: notSupportedVideoTag,
    }
}

export default useVideoHero
