'use-strict'

import { removeDuplicates } from "@/helpers/arrays/removeDuplicates"

export const initialState = {
    all: [],
    starredCourses: {
        courses: [],
        pagination: {}
    },
    ongoingCourses: {
        courses: [],
        pagination: {}
    },
    finishedCourses: {
        courses: [],
        pagination: {}
    },
    CourseType: {
        Rules: [],
    },
    remoteClass: null,
    activeTab: {},
    course: {
        id: '',
        name: '',
        course_type_id: '',
        base: false,
        CourseType: {
            Rules: [],
        },
    },
    quizBlocks: [],
    externalPlatforms: [],
    currentCourseHasProgress: false,
    nextCourseLesson: null
}

export const SET_ALL_COURSES = '@@course/SET_ALL_COURSES'
export const SET_STARRED_COURSES = '@@course/SET_STARRED_COURSES'
export const SET_ONGOING_COURSES = '@@course/SET_ONGOING_COURSES'
export const SET_FINISHED_COURSES = '@@course/SET_FINISHED_COURSES'
export const SET_COURSE_TYPE = '@@course/SET_COURSE_TYPE'
export const CLEAR_ALL_COURSES = '@@course/CLEAR_ALL_COURSES'

export const GET_REMOTE_CLASSES_START = '@@course/GET_REMOTE_CLASSES_START'
export const GET_REMOTE_CLASSES_SUCCESS = '@@course/GET_REMOTE_CLASSES_SUCCESS'
export const GET_REMOTE_CLASSES_ERROR = '@@course/GET_REMOTE_CLASSES_ERROR'
export const CLEAR_REMOTE_CLASS = '@@course/CLEAR_REMOTE_CLASS'

export const SET_ACTIVE_TAB = "@@course/SET_ACTIVE_TAB"
export const SET_COURSE = '@@course/SET_COURSE'
export const CLEAR_COURSE = '@@course/CLEAR_COURSE'

export const SET_QUIZ_BLOCKS = '@@course/SET_QUIZ_BLOCKS'

export const SET_EXTERNAL_PLATFORMS = '@@course/SET_EXTERNAL_PLATFORMS'

export const SET_COURSE_HAS_PROGRESS = '@@course/SET_COURSE_HAS_PROGRESS'
export const CLEAR_COURSE_HAS_PROGRESS = '@@course/CLEAR_COURSE_HAS_PROGRESS'

export const UPDATE_ALL_COURSES = '@@course/UPDATE_ALL_COURSES'
export const SET_NEXT_COURSE_LESSON = '@@course/SET_NEXT_COURSE_LESSON'

export const UPDATE_STARRED_COURSES = '@@course/UPDATE_STARRED_COURSES'
export const UPDATE_ONGOING_COURSES = '@@course/UPDATE_ONGOING_COURSES'
export const UPDATE_FINISHED_COURSES = '@@course/UPDATE_FINISHED_COURSES'

export const updateStarredCourses = (courses) => ({
    type: UPDATE_STARRED_COURSES,
    payload: courses
})

export const updateOngoingCourses = (courses) => ({
    type: UPDATE_ONGOING_COURSES,
    payload: courses
})

export const updateFinishedCourses = (courses) => ({
    type: UPDATE_FINISHED_COURSES,
    payload: courses
})

export const setAllCourses = (courses) => ({
    type: SET_ALL_COURSES,
    payload: { courses },
})

export const setStarredCourses = (courses) => ({
    type: SET_STARRED_COURSES,
    payload: { courses },
})

export const setOngoingCourses = (courses) => ({
    type: SET_ONGOING_COURSES,
    payload: { courses },
})

export const setFinishedCourses = (courses) => ({
    type: SET_FINISHED_COURSES,
    payload: { courses },
})


export const setCourseType = courseType => ({
    type: SET_COURSE_TYPE,
    payload: courseType,
})
export const clearAllCourses = () => ({
    type: CLEAR_ALL_COURSES,
})

export const getRemoteClassesStart = () => ({ type: GET_REMOTE_CLASSES_START })
export const getRemoteClassesSuccess = data => ({
    type: GET_REMOTE_CLASSES_SUCCESS,
    payload: data,
})
export const getRemoteClassesError = error => ({
    type: GET_REMOTE_CLASSES_ERROR,
    payload: error,
})

export const clearRemoteClass = () => ({
    type: CLEAR_REMOTE_CLASS,
})

export const setActiveTab = (activeTab) => ({
    type: SET_ACTIVE_TAB,
    payload: { activeTab },
})

export const setCourse = (course) => ({
    type: SET_COURSE,
    payload: { course }
})

export const clearCourse = () => ({
    type: CLEAR_COURSE,
})

export const setQuizBlocks = (quizBlocks) => ({
    type: SET_QUIZ_BLOCKS,
    payload: { quizBlocks },
})

export const setExternalPlatforms = (externalPlatforms) => ({
    type: SET_EXTERNAL_PLATFORMS,
    payload: externalPlatforms,
})

export const setCourseHasProgress = (hasProgress) => ({
    type: SET_COURSE_HAS_PROGRESS,
    payload: hasProgress
})

export const clearCourseHasProgress = () => ({
    type: CLEAR_COURSE_HAS_PROGRESS,
})

export const updateAllCourses = (courses) => ({
    type: UPDATE_ALL_COURSES,
    payload: { courses }
})

export const setNextCourseLesson = (nextOnSite) => ({
    type: SET_NEXT_COURSE_LESSON,
    payload: nextOnSite
})

const courseReducer = (state = initialState, { type, payload }) => {
    const reducers = {
        [SET_ALL_COURSES]: () => ({
            ...state,
            all: payload.courses,
        }),
        [UPDATE_STARRED_COURSES]: () => {
            const starred = Array.isArray(payload) ? payload : [...state.starredCourses.courses, payload]
            return ({
                ...state,
                starredCourses: {
                    pagination: state.starredCourses.pagination,
                    courses: (starred || [])
                }
            })
        },
        [UPDATE_ONGOING_COURSES]: () => {
            const ongoing = Array.isArray(payload) ? payload : [...state.ongoingCourses.courses, payload]
            return ({
                ...state,
                ongoingCourses: {
                    pagination: state.ongoingCourses.pagination,
                    courses: (ongoing || [])
                }
            })
        },
        [UPDATE_FINISHED_COURSES]: () => {
            const finished = Array.isArray(payload) ? payload : [...state.finishedCourses.courses, payload]
            return ({
                ...state,
                finishedCourses: {
                    pagination: state.finishedCourses.pagination,
                    courses: (finished || [])
                }
            })
        },
        [SET_STARRED_COURSES]: () => {
            return ({
                ...state,
                starredCourses: {
                    courses: removeDuplicates([...state.starredCourses.courses, ...payload.courses.data], 'id'),
                    pagination: payload.courses.meta
                }
        })},
        [SET_ONGOING_COURSES]: () => ({
            ...state,
            ongoingCourses: {
                courses: removeDuplicates([...state.ongoingCourses.courses, ...payload.courses.data], 'id'),
                pagination: payload.courses.meta
            }
        }),
        [SET_FINISHED_COURSES]: () => ({
            ...state,
            finishedCourses: {
                courses: removeDuplicates([...state.finishedCourses.courses, ...payload.courses.data], 'id'),
                pagination: payload.courses.meta
            }
        }),
        [SET_COURSE]: () => ({
            ...state,
            course: payload.course,
        }),
        [CLEAR_COURSE]: () => ({
            ...state,
            course: initialState.course,
        }),
        [SET_COURSE_TYPE]: () => ({
            ...state,
            CourseType: payload,
        }),
        [CLEAR_ALL_COURSES]: () => ({
            ...state,
            all: initialState.all,
        }),
        LOGOUT_USER: () => initialState,
        [GET_REMOTE_CLASSES_START]: () => ({
            ...state,
            loading: true,
        }),
        [GET_REMOTE_CLASSES_SUCCESS]: () => ({
            ...state,
            loading: false,
            remoteClass: payload,
        }),
        [GET_REMOTE_CLASSES_ERROR]: () => ({
            ...state,
            loading: false,
            error: payload,
        }),
        [CLEAR_REMOTE_CLASS]: () => ({
            ...state,
            remoteClass: null,
        }),
        [SET_ACTIVE_TAB]: () => ({
            ...state,
            activeTab: payload.activeTab,
        }),
        [SET_QUIZ_BLOCKS]: () => ({
            ...state,
            quizBlocks: payload.quizBlocks,
        }),
        [SET_EXTERNAL_PLATFORMS]: () => ({
            ...state,
            externalPlatforms: payload,
        }),
        [SET_COURSE_HAS_PROGRESS]: () => ({
            ...state,
            currentCourseHasProgress: payload,
        }),
        [CLEAR_COURSE_HAS_PROGRESS]: () => ({
            ...state,
            currentCourseHasProgress: initialState.currentCourseHasProgress,
        }),
        [UPDATE_ALL_COURSES]: () => ({
            ...state,
            all: payload.courses,
        }),
        [SET_NEXT_COURSE_LESSON]: () => ({
            ...state,
            nextCourseLesson: payload,
        })
    }
    return reducers[type] ? reducers[type]() : state
}

export default courseReducer
