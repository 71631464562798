import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { track } from '@digitalhouse-tech/react-sdk-analytics'
import { enableCertificate } from '@/helpers/granters/courseTypeRules'
import useClassroomRedirect from '../../useClassroomRedirect'
import useCertificateRedirect from '../../../Classroom/Certificate/useCertificateRedirect'
import useContentNavigator from './useContentNavigator'
import { handleToggleMobileMenu } from '@/redux/reducers/student/classroom'
import { getCurrentTopic } from '@/redux/selectors/students/classroom'
import { getUserProgressPercent } from '@/redux/selectors/students/progress'
import { getCourseType } from '@/redux/selectors/course'
import { finishCourse } from '@/constants/trackEvents'

import { NO_MORE_CONTENT, UNIT, LESSON, MODULE, TOPIC } from '@/constants/student'

export default ({ isMobile, blockId = null }) => {
    const dispatch = useDispatch()

    const { courseId } = useParams()
    const courseType = useSelector(getCourseType)

    const { classroomRedirect, topic_id } = useClassroomRedirect()
    const { certificateRedirect } = useCertificateRedirect()

    const { name: topicName } = useSelector(getCurrentTopic(topic_id))

    const nextContent = useContentNavigator(topic_id, 'next')
    const prevContent = useContentNavigator(topic_id, 'prev')

    const {
        next,
        nextClass,
        nextModule,
        nextUnit,
        previous,
        previousClass,
        previousModule,
        previousUnit,
        mandatoryContent: mandatoryContentLang,
        finishCourse: finishCourseLang,
        accessibility,
    } = useSelector(({ langStore: { progressNavigation } }) => progressNavigation)

    const { started: activeQuizStarted, blockId: activeQuizBlockId } = useSelector(
        ({ courseReducer: { activeQuiz } }) => activeQuiz,
    )

    const hasActiveQuiz = useMemo(() => {
        return activeQuizStarted && activeQuizBlockId == blockId
    }, [activeQuizStarted, activeQuizBlockId, blockId])

    const nextLangs = {
        [TOPIC]: {
            buttonText: next,
            ariaLabel: accessibility?.nextTopic,
        },
        [LESSON]: {
            buttonText: nextClass,
            ariaLabel: accessibility?.nextClass,
        },
        [MODULE]: {
            buttonText: nextModule,
            ariaLabel: accessibility?.nextModule,
        },
        [UNIT]: {
            buttonText: nextUnit,
            ariaLabel: accessibility?.nextUnit,
        },
    }

    const previousLangs = {
        [TOPIC]: {
            buttonText: previous,
            ariaLabel: accessibility?.previousTopic,
        },
        [LESSON]: {
            buttonText: previousClass,
            ariaLabel: accessibility?.previousClass,
        },
        [MODULE]: {
            buttonText: previousModule,
            ariaLabel: accessibility?.previousModule,
        },
        [UNIT]: {
            buttonText: previousUnit,
            ariaLabel: accessibility?.previousUnit,
        },
    }

    const hasPrev = prevContent.type !== NO_MORE_CONTENT
    const hasPrevUnit = prevContent.type === UNIT

    const hasNext = nextContent.type !== NO_MORE_CONTENT
    const hasNextUnit = nextContent.type === UNIT

    const handleGoBack = ({ showLastBlock }) => {
        if ((!hasPrev || hasPrevUnit) && isMobile) {
            dispatch(handleToggleMobileMenu())
        }

        if (hasPrev && !(isMobile && hasPrevUnit)) {
            classroomRedirect(prevContent, { showLastBlock })
        }
    }

    const handleForward = () => {
        if (hasNextUnit) {
            dispatch(handleToggleMobileMenu())
        }

        if (hasNext) {
            classroomRedirect(nextContent)
        }
    }

    const userProgressPercent = useSelector(getUserProgressPercent())

    const enableFinishCourseButton = userProgressPercent === 100

    const allowFinishCourse = enableCertificate(courseType)

    const trackFinishCourseEvent = (pathname) => {
        track(finishCourse.EVENT_NAME, {
            context: finishCourse.EVENT_CONTEXT,
            label: finishCourse.EVENT_LABEL,
            url: pathname
        })
    }

    const handleGotToCertificate = () => {
        if (allowFinishCourse && enableFinishCourseButton) {
            certificateRedirect(courseId, trackFinishCourseEvent)
        }
    }

    return {
        handleForward,
        handleGoBack,
        hasNext,
        hasPrev,
        topicName,
        previousLang: previousLangs[prevContent.type],
        nextLang: nextLangs[nextContent.type],
        hasActiveQuiz,
        enableFinishCourseButton,
        allowFinishCourse,
        handleGotToCertificate,
        mandatoryContentLang,
        finishCourseLang: {
            buttonText: finishCourseLang,
            ariaLabel: accessibility?.finishCourse
        },
    }
}
