const initialState = {
    courseId: 0,
    fileList: [],
    loading: false,
}

const constants = {
    setCourseId: 'setCourseId',
    setFileList: 'getFileList',
    updateFile: 'updateFile',
    deleteFile: 'deleteFile',
    loading: 'loading',
}

export const actions = {
    setCourseId: (courseId = 0) => ({
        type: constants.setCourseId,
        payload: courseId
    }),
    setFileList: (fileList = [{ id: 0, name: '' }]) => ({
        type: constants.setFileList,
        payload: fileList
    }),
    updateFile: (file = { id: 0, name: '' }) => ({
        type: constants.updateFile,
        payload: file
    }),
    delete: (fileId = 0) => ({
        type: constants.deleteFile,
        payload: fileId
    }),
    setLoading: (bool) => ({
        type: constants.loading,
        payload: bool
    })
}

const reducers = {
    [constants.setCourseId]: (state, courseId = 0) => ({
        ...state,
        courseId: courseId
    }),
    [constants.setFileList]: (state, fileList = [{ id: 0, name: '', date: '', isEditable: false }]) => ({
        ...state,
        fileList: fileList
    }),
    [constants.updateFile]: (state, fileUpdated = { id: 0, name: '' }) => {
        const fileListCopy = [...state.fileList]
        const fileIndexFinded = fileListCopy.findIndex(file => file.id === fileUpdated.id)
        fileListCopy[fileIndexFinded].name = fileUpdated.name

        return {
            ...state,
            fileList: fileListCopy
        }
    },
    [constants.deleteFile]: (state, id = 0) => {
        const filesCopy = [...state.fileList]
        const filesFiltered = filesCopy.filter(file => {
            return file.id !== id
        })
        return {
            ...state,
            fileList: filesFiltered
        }
    },
    [constants.loading]: (state, bool) => {
        return {
            ...state,
            loading: bool
        }
    }
}

const dispatcher = (state = initialState, action) => reducers[action.type] ? reducers[action.type](state, action.payload) : state
export default dispatcher