import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import  StudentUnitLessonsList from './StudentUnitLessonsList'
import { ChevronUp, ChevronDown } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import ProgressBar from './ProgressBar'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'

const StudentUnitCard = ({
    lang,
    unit,
    moduleNumber,
    isReleased,
    isActiveUnit,
    isLive,
    getMonthAndDay,
    handleLessonUrl,
    handleExpandUnit,
    expanded,
    goToUnit,
    allowApiCreateRule,
    isMobile,
    lessonsReleased,
    unitProgress,
    lessonsProgress,
    nextLessonId,
}) => {
    
    const {
        module,
        aboutClass,
        contentStatus,
        continueUnit,
        seeTheContent,
        lessons: lessonsText,
        liveClass,
        nextLiveLesson,
        accesibility,
    } = lang

    const {
        name: unitTitle,
        description,
        release_date,
        Lessons: lessons,
        id: unitId
    } = unit

    const Lessons = lessons.filter(lesson => lesson.visibility)
    const isContentEnabled = useMemo(() => 
        Lessons.filter(lesson =>
            lesson.visibility &&
                lesson.Topics.some(topic => 
                    topic.visibility && 
                    topic.Blocks.length > 0 &&
                    topic.Blocks.some(block => 
                        block.visibility
                    ),
                ),
        ).length, [Lessons])
    

    const releasedUnit = isReleased(release_date)
    const releasedContent = lessonsReleased(Lessons)

    const setModuleInfoSpan = () => {
        
        if(isActiveUnit && isContentEnabled) return <span>{contentStatus.lastVisited}</span>
        if(!releasedUnit || !releasedContent || !isContentEnabled)  
            return <span className="notReleased">{contentStatus.notAvailable}</span>
    }

    const handleUnitClick = () => {
        if(!releasedUnit || !releasedContent) return () => {}
        goToUnit(isActiveUnit, unit)
    }

    const manageToggle = () => {
        if(releasedUnit && 
            releasedContent && 
            !isMobile && 
            (allowApiCreateRule || (!allowApiCreateRule && description)) &&
            isContentEnabled
        ) {
            return (
                <div 
                    className="icon" 
                    onClick={() => handleExpandUnit(expanded, unitId)}
                    data-message={expanded ? accesibility.angleUpIcon : accesibility.angleDownIcon}
                 >
                    {expanded ? <ChevronUp /> : <ChevronDown />}
                </div>
            )
        }
        return null
    }

    const cardContentClasses = cn({
        'activeUnit': isActiveUnit,
        'disabled': !releasedUnit || isMobile || !releasedContent,
    }, 'unitCardContent')

    const linkClasses = cn({
        'disabled': !releasedUnit || !releasedContent || !isContentEnabled,
    },'link')

    const progressBoxClasses = cn({
        'toLeft': !releasedUnit || !releasedContent,
    }, 'progressBox')

    const contentBodyClasses = cn({         
        'colapsedContent': expanded && releasedUnit && isContentEnabled,     
    }, 'contentBody')

    
    const getBarProgress = () => {
        const { completedLessons, lessonsQty } = unitProgress
        return !lessonsQty ? 0 : 100*completedLessons/Lessons.length
    }
    
    const isBarAllowed = () => {
        return !!allowApiCreateRule &&
        !!releasedUnit && 
        !!releasedContent && 
        !!isContentEnabled
    }
    
    const barAndExpandStyle = cn({
        'onlyExpand' : !isBarAllowed(),
    }, 'barAndExpand')

    return (
        <div className="StudentUnitsGridCell">
            <div className={cardContentClasses}>
                <div className="contentHeader">
                    <div className="moduleInfo">
                        <p>{`${module} ${moduleNumber}`}</p>
                        {setModuleInfoSpan()}
                    </div>
                    <div className="unitTitle">
                        <p>{unitTitle}</p>
                        <div className={barAndExpandStyle}>
                            {isBarAllowed() &&
                            <div className={progressBoxClasses}>
                                <ProgressBar level={getBarProgress()} />
                                <span className="progressText">
                                    {`${unitProgress.completedLessons || 0} de 
                                    ${Lessons.length} 
                                    ${lang.completedLessons.toLowerCase()}`}
                                </span>
                            </div>}
                            {manageToggle()}
                        </div>
                    </div>
                    <Button 
                        id='modulesContentButton'
                        onClick={handleUnitClick}
                        data-message={isActiveUnit ? accesibility.continueUnit : accesibility.seeTheContent}
                        text={isActiveUnit ? continueUnit : seeTheContent}
                        theme='tertiary'
                        disabled={!releasedUnit || !releasedContent || !isContentEnabled}
                    />
                </div>
                {!isMobile && 
                <div className={contentBodyClasses}>
                    {description && 
                    <div className="aboutClass topLine">
                        <p>{aboutClass}</p>
                        <p>{description}</p>
                    </div>}
                    {allowApiCreateRule &&
                    <div className="unitCardClasses topLine">
                        <p>{lessonsText}</p>
                        <StudentUnitLessonsList
                            lessons={Lessons}
                            isLive={isLive}
                            liveClass={liveClass}
                            getMonthAndDay={getMonthAndDay}
                            unitId={unitId}
                            handleLessonUrl={handleLessonUrl}
                            isReleased={isReleased}
                            lessonsProgress={lessonsProgress}
                            nextLiveLesson={nextLiveLesson}
                            nextLessonId={nextLessonId}
                        />
                    </div>}
                </div>}
            </div>
        </div>
    )
}

StudentUnitCard.propTypes  = {
    lang: PropTypes.any.isRequired,
    unit: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        release_date: PropTypes.string,
        Lessons: PropTypes.array
    }).isRequired,
    moduleNumber: PropTypes.number.isRequired,
    isReleased: PropTypes.func.isRequired,
    isActiveUnit: PropTypes.bool.isRequired,
    isLive: PropTypes.func.isRequired,
    getMonthAndDay: PropTypes.func.isRequired,
    handleLessonUrl: PropTypes.func.isRequired,
    handleExpandUnit: PropTypes.func.isRequired,
    expanded: PropTypes.bool,
    goToUnit: PropTypes.func.isRequired,
    allowApiCreateRule: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    lessonsReleased: PropTypes.func.isRequired,
    unitProgress: PropTypes.shape({
        id: PropTypes.number,
        percent: PropTypes.number,
        completedLessons: PropTypes.number,
        lessonsQty: PropTypes.number,
    }),
    lessonsProgress: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            percent: PropTypes.number,
            completedTopics: PropTypes.number,
            topicsQty: PropTypes.number,
        }),
    ),
    nextLessonId: PropTypes.number.isRequired,
}

StudentUnitCard.defaultProps = {
    expanded: false,
    unitProgress: {},
    lessonsProgress: null,
}

export default StudentUnitCard