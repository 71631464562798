import httpClient from '../httpClient'
import { base } from '../api/changePaymentApi'
import * as env from '@/config/env'

const httpPaymentMethod = (xuow = '') => httpClient('', base, { useVersion: false, apiToken: env.CHANGE_PAYMENT_TOKEN, secured: true, xuow })

export const getAll = () => {
    return httpPaymentMethod().get('')
}

export const update = (walletId, payment, xuow = '') => {
    return httpPaymentMethod(xuow).patch(`/${walletId}`, payment, { useVersion: false, apiToken: env.CHANGE_PAYMENT_TOKEN, secured: true })
}
