import {
    checkIsLoggedWithPermission,
    FULL_COURSE_ACCESS,
    checkPermissionsGroup,
} from '@digitalhouse-tech/ts-lib-store/lib/session-storage/permission'
import { getStore } from '@/app/store'

const { oneOf } = checkIsLoggedWithPermission

const ownership =
    (askedPermissions, options = { ignoreHighLevelPermission: false }) =>
    (defPermissions = []) => {
        const { coursePermissions } = getStore().getState().authReducer
        let middlewares = [oneOf(askedPermissions, [...defPermissions, ...coursePermissions])]
        if (!options.ignoreHighLevelPermission) {
            middlewares.push(FULL_COURSE_ACCESS)
        }
        return checkPermissionsGroup.oneOf(middlewares)
    }

/**
 * COURSES
 */
export const VIEW_COURSE = ownership(['VIEW_COURSE'])
export const EDIT_COURSE_DESCRIPTION = ownership(['EDIT_COURSE_DESCRIPTION'])
export const EDIT_COURSE_DATES = ownership(['EDIT_COURSE_DATES'])
export const EDIT_COURSE_CONFIG = ownership(['EDIT_COURSE_CONFIG'])

/**
 * UNITS
 */
export const ORDER_UNITS = ownership(['ORDER_UNITS'])
export const CREATE_UNITS = ownership(['CREATE_UNITS'])
export const EDIT_UNIT_VISIBILITY = ownership(['EDIT_UNIT_VISIBILITY'])
export const EDIT_UNIT_NAME = ownership(['EDIT_UNIT_NAME'])
export const EDIT_UNIT_DESCRIPTION = ownership(['EDIT_UNIT_DESCRIPTION'])
export const EDIT_UNIT_DATES = ownership(['EDIT_UNIT_DATES'])

/**
 * LESSONS
 */
export const CREATE_LESSONS = ownership(['CREATE_LESSONS'])
export const ORDER_LESSONS = ownership(['ORDER_LESSONS'])
export const EDIT_LESSON_VISIBILITY = ownership(['EDIT_LESSON_VISIBILITY'])
export const EDIT_LESSON_NAME = ownership(['EDIT_LESSON_NAME'])
export const EDIT_LESSON_DATES = ownership(['EDIT_LESSON_DATES'])

/**
 * TOPICS
 */
export const CREATE_TOPICS = ownership(['CREATE_TOPICS'])
export const ORDER_TOPICS = ownership(['ORDER_TOPICS'])
export const EDIT_TOPIC_VISIBILITY = ownership(['EDIT_TOPIC_VISIBILITY'])
export const EDIT_TOPIC_CONTENT = ownership(['EDIT_TOPIC_CONTENT'])

/**
 * BLOCKS
 */
export const CREATE_BLOCKS = ownership(['CREATE_BLOCKS'])
export const ORDER_BLOCKS = ownership(['ORDER_BLOCKS'])
export const EDIT_BLOCK_VISIBILITY = ownership(['EDIT_BLOCK_VISIBILITY'])
export const EDIT_BLOCK_CONTENT = ownership(['EDIT_BLOCK_CONTENT'])
export const DELETE_BLOCK = ownership(['DELETE_BLOCK'])

/**
 * REPORTS
 */
export const VIEW_COURSE_PROGRESS_REPORT = ownership(['VIEW_COURSE_PROGRESS_REPORT'])
export const VIEW_STUDENT_PROGRESS_REPORT = ownership(['VIEW_STUDENT_PROGRESS_REPORT'])

/**
 * OWNERS
 */
export const VIEW_OWNERS = ownership(['VIEW_OWNERS'])
export const EDIT_OWNER_PERMISSIONS = ownership(['EDIT_OWNER_PERMISSIONS'])
export const DELETE_OWNER = ownership(['DELETE_OWNER'])
export const OWNER_ENROLMENT_SEARCH = ownership(['OWNER_ENROLMENT_SEARCH'])

/**
 * SURVEYS
 */
export const NAVIGATE_SURVEY = ownership(['NAVIGATE_SURVEY'])
export const EDIT_SURVEY = ownership(['EDIT_SURVEY'])
export const CREATE_SURVEY = ownership(['CREATE_SURVEY'])
export const EDIT_SURVEY_VISIBILITY = ownership(['EDIT_SURVEY_VISIBILITY'])
export const GET_SURVEY_REPORT = ownership(['GET_SURVEY_REPORT'])

/**
 * FILES
 */
export const VIEW_FILE = ownership(['VIEW_FILE'])
export const EDIT_FILE = ownership(['EDIT_FILE'])
export const UPLOAD_FILE = ownership(['UPLOAD_FILE'])
export const DOWNLOAD_FILE = ownership(['DOWNLOAD_FILE'])
export const DELETE_FILE = ownership(['DELETE_FILE'])

/**
 * STUDENTS
 */
export const VIEW_STUDENT = ownership(['VIEW_STUDENT'])
export const CHANGE_PASSWORD_STUDENT = ownership(['CHANGE_PASSWORD_STUDENT'])
export const DELETE_STUDENT = ownership(['DELETE_STUDENT'])
export const STUDENT_ENROLMENT_SEARCH = ownership(['STUDENT_ENROLMENT_SEARCH'])
export const STUDENT_ENROLMENT_CSV = ownership(['STUDENT_ENROLMENT_CSV'])
export const DOWNLOAD_STUDENT_LAST_LOGIN = ownership(['DOWNLOAD_STUDENT_LAST_LOGIN'])

/**
 * SEND EMAIL
 */
export const SEND_EMAIL = ownership(['SEND_EMAIL'])

/**
 * TABS
 */
export const VIEW_TABS = ownership(['VIEW_TABS'])
export const EDIT_TABS = ownership(['EDIT_TABS'])
export const EDIT_TABS_VISIBILITY = ownership(['EDIT_TABS_VISIBILITY'])

/**
 * TOOLS
 */

export const VIEW_TOOLS = ownership(['VIEW_TOOLS'])
export const CREATE_TOOLS = ownership(['CREATE_TOOLS'])
export const EDIT_TOOLS = ownership(['EDIT_TOOLS'])
export const DELETE_TOOLS = ownership(['DELETE_TOOLS'])

/**
 * EVALUABLE ACTIVITY FEEDBACK
 */

export const VIEW_FEEDBACK = ownership(['VIEW_FEEDBACK'])
export const EDIT_FEEDBACK = ownership(['EDIT_FEEDBACK'], { ignoreHighLevelPermission: true })
