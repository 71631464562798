'use-strict'

import { LOGOUT_USER } from '../_deprecated/authReducer'

const initialState = {
    courseId: '',
    unitId: '',
    lessonId: '',
    topicId: '',
    blockId: ''
}

export const constants = {
    SET_MAMUSHKA_VALUES: '@@mamushka/SET_MAMUSHKA_VALUES',
    CLEAR_MAMUSHKA_VALUES: '@@mamushka/CLEAR_MAMUSHKA_VALUES'
}

export const actions = {
    setMamushkaValues: (value) => ({
        type: constants.SET_MAMUSHKA_VALUES,
        value
    }),
    clearMamushkaValues: () => ({
        type: constants.CLEAR_MAMUSHKA_VALUES
    })
}

const mamushkaReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_MAMUSHKA_VALUES: {
            return {
                ...state,
                ...action.value
            }
        }
        case constants.CLEAR_MAMUSHKA_VALUES: {
            return initialState
        }
        case LOGOUT_USER: {
            return initialState
        }
        default: {
            return state
        }
    }
}

export default mamushkaReducer
