import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './CircularProgressBar.scss'
import { CheckCircle } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'

class CircularProgressBar extends PureComponent {

    componentDidMount() {
        this.animateCircularProgress()
    }

    componentDidUpdate() {
        this.animateCircularProgress()
    }

    animateCircularProgress() {
        const { percent } = this.props
        if (this.circularProgress) {
            window.randomize = () => {
                if (this.circularProgress)
                    this.circularProgress.setAttribute('data-progress', parseInt(percent))
            }
            setTimeout(window.randomize, 200)
        }
    }

    checkCompleted() {
        const { percent, fillCompleted } = this.props
        return percent === 100 && fillCompleted
    }

    render() {
        const { children, small, pointed } = this.props
        const isSmall = small && 'small'
        
        return (
            <div className={`ko-progress-circle ${isSmall}`} data-progress="0" ref={circularProgress => this.circularProgress = circularProgress}>
                { !this.checkCompleted() ?
                    <>
                        <div className="ko-circle">
                            <div className={`full ko-progress-circle__slice ${isSmall}`}>
                                <div className={`ko-progress-circle__fill ${isSmall}`} />
                            </div>
                            <div className={`ko-progress-circle__slice ${isSmall}`}>
                                <div className={`ko-progress-circle__fill ${isSmall}`} />
                                <div className={`ko-progress-circle__fill ko-progress-circle__bar ${isSmall}`} />
                            </div>
                        </div>
                        
                        <div className={`ko-progress-circle__overlay ${isSmall}`}>
                            {!!pointed && <div className="ko-progress-circle__pointedOverlay" /> }
                            { children || children }
                        </div>
                    </>
                    : <CheckCircle alt="checked" className={`iconSuccess ${isSmall}`} /> }
            </div>
        )
    }
}

CircularProgressBar.defaultProps = {
    fillCompleted: false,
    pointed: false,
    children: null,
    small: false,
}

CircularProgressBar.propTypes = {
    percent: PropTypes.number.isRequired,
    fillCompleted: PropTypes.bool,
    pointed: PropTypes.bool,
    children: PropTypes.element,
    small: PropTypes.bool,

}
export default CircularProgressBar