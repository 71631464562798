import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isEmpty, cloneDeep } from 'lodash/lang'
import moment from 'moment'
import { CSVLink } from 'react-csv'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import UnitCard from './Units/UnitCard'
import { history } from '@/app/store'

import ModalUnit from './Units/ModalUnit'
import ModalEvaluableBlock from './Units/Lessons/Topics/Blocks/ModalEvaluableBlock'
import ModalExerciseBlock from './Units/Lessons/Topics/Blocks/ModalExerciseBlock'
import ModalFileBlock from './Units/Lessons/Topics/Blocks/ModalFileBlock'
import ModalLesson from './Units/Lessons/ModalLesson'
import ModalPDFBlock from './Units/Lessons/Topics/Blocks/ModalPDFBlock'
import ModalQuizBlock from './Units/Lessons/Topics/Blocks/ModalQuizBlock'
import ModalSlideBlock from './Units/Lessons/Topics/Blocks/ModalSlideBlock'
import ModalTextBlock from './Units/Lessons/Topics/Blocks/ModalTextBlock'
import ModalTextHtmlBlock from './Units/Lessons/Topics/Blocks/ModalTextHtmlBlock'
import ModalTopic from './Units/Lessons/Topics/ModalTopic'
import ModalVideoBlock from './Units/Lessons/Topics/Blocks/ModalVideoBlock'
import ModalNotionBlock from './Units/Lessons/Topics/Blocks/ModalNotionBlock'
import ModalConfirmation from '@/components/ModalConfirmation'
import {
    hasAnyProgress
} from '../../../../redux/thunks/courseThunks'

import { clearCourseHasProgress } from '@/redux/reducers/courseReducer'
import {
    clearActiveCourseAction,
    updateActiveCourseAction,
    toggleDragAction,
    loadActiveCourse,
    loadActiveUnits,
    toggleDisableUnit,
    changeVisibility,
    clearUpdatedClonesAction,
    loadCourseClonesQty,
    loadAllCourseTypes,
    changeSavingUnitAction,
} from '../../../../redux/reducers/_deprecated/courseReducer'

import { loadCoursePermissions } from '@/redux/reducers/_deprecated/authReducer'

import {
    EXERCISE_BLOCK_MODAL,
    FILE_BLOCK_MODAL,
    PDF_BLOCK_MODAL,
    QUIZ_BLOCK_MODAL,
    SLIDE_BLOCK_MODAL,
    TEXT_BLOCK_MODAL,
    NOTION_BLOCK_MODAL,
    VIDEO_BLOCK_MODAL,
    UNIT_MODAL,
    TOPIC_MODAL,
    VISIBILITY_MODAL,
    TEXT_HTML_BLOCK_MODAL,
    EVALUABLE_BLOCK_MODAL,
} from '../../../../constants/modals'

import { actions as modalActions } from '@/redux/reducers/modalReducer'

import CustomCargando from '@/components/CustomCargando'
import { isObjectEmpty } from '@/helpers/utils'

import DeleteBlockSuiteContainer from './Units/Lessons/Topics/Blocks/card/DeleteBlockSuiteContainer'
import DeleteTopicModal from './Units/Lessons/Topics/DeleteTopicModal'
import DownloadReportAlert from './Units/Lessons/Topics/Blocks/DownloadReportAlert'

import CourseHeader from './Header'
import CourseSkeleton from './Skeleton'

export class CourseIndex extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            items: [],
            courseId: this.props.match.params.courseId,
            courseName: '',
        }

        this.alertRef = null

        this.openModalUnit = this.openModalUnit.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)
        this.getUnits = this.getUnits.bind(this)
        this.showUnits = this.showUnits.bind(this)
        this.handleOrder = this.handleOrder.bind(this)
        this.changeOrder = this.changeOrder.bind(this)
        this.handleGoBack = this.handleGoBack.bind(this)
        this.renderAlert = this.renderAlert.bind(this)
        this.getText = this.getText.bind(this)
        this.getVisibilityModal = this.getVisibilityModal.bind(this)
    }

    componentDidMount() {
        let courseId = this.props.match.params.courseId
        this.props.loadActiveCourse(courseId)
        this.props.loadActiveUnits(courseId)
        this.props.loadCourseClonesQty(courseId)
        this.props.loadAllCourseTypes()
        this.props.loadCoursePermissions(courseId)
        this.props.hasAnyProgress(this.props.match.params.courseId)

    }

    componentWillUnmount(){
        this.props.clearCourseHasProgress()
    }

    handleGoBack() {
        this.props.clearActiveCourseAction()
        history.push('/admin/courses')
    }

    handleOrder() {
        this.props.toggleDragDisable()
    }

    changeOrder() {
        let data = {}
        data.courseId = this.props.match.params.courseId
        data.activeCourse = this.props.course

        this.props.toggleDisableUnit(data)

        this.props.toggleDragDisable()
    }

    openModalUnit() {
        this.props.changeSavingUnit(false)
        this.props.openModal(UNIT_MODAL)
    }

    getUnits() {
        return this.props.course.Units
    }

    showUnits() {
        const courseId = this.props.match.params.courseId
        const units = this.props.course.Units

        if (!units) return <CourseSkeleton width='100%' height='100px' />

        return units.map(unit => <UnitCard {...unit} key={unit.id} courseId={courseId} />)
    }

    reorder(items, startIndex, endIndex) {
        let result = Array.from(items)
        let [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        let activeCourseCopy = cloneDeep(this.props.course)

        let newUnits = this.reorder(
            activeCourseCopy.Units,
            result.source.index,
            result.destination.index
        )

        newUnits.map((item, index) => (
            item.order = index
        ))

        activeCourseCopy.Units = newUnits
        this.props.updateElementsOrder(activeCourseCopy)
    }

    goToStudentsList() {
        let studentsLink = "/admin/enrolment/" + this.props.courseId
        history.push(studentsLink)
    }

    removeAlert(e) {
        e.preventDefault()
        this.props.clearUpdatedClonesAction()
    }

    renderAlert() {
        if (!isObjectEmpty(this.props.updatedClones)) {
            let modifiedCount = 0
            let notModifiedCount = 0

            let headers = [
                { label: this.props.lang.modifiedCourse, key: 'course' },
                { label: this.props.lang.operation, key: 'createOrEdit' },
                { label: this.props.lang.modifiedBlockName, key: 'added' },
                { label: this.props.lang.errors, key: 'withErrors' },
            ]
            let reportData = this.props.updatedClones.map(element => {
                if (element.withErrors == '-') {
                    modifiedCount++
                } else {
                    notModifiedCount++
                }

                return (
                    {
                        course: element.course,
                        createOrEdit: element.createOrEdit,
                        added: element.added,
                        withErrors: element.withErrors,
                    }
                )
            })

            let date = moment(new Date()).format('YYYYMMDD_HHMMSS')
            let filename = date + "_" + this.props.course.name + ".csv"


            let alertText = (notModifiedCount == 0) ? (this.props.lang.other + this.props.updatedClones.length + this.props.lang.coursesModifiedAnd) : (this.getText(modifiedCount) + notModifiedCount + this.props.lang.notAble + (notModifiedCount > 1 ? this.props.lang.ablePlural : this.props.lang.ableSingular) + this.props.lang.modify)
            let alertTextDownload = this.props.lang.toDownloadDetailReport

            return (
                <div className="toastA alert alert-info" ref={this.alertRef}>
                    <a href="#" className="close" onClick={this.removeAlert.bind(this)}>&times;</a>
                    <h3 >{this.props.lang.successfullEditMessage}</h3><br />
                    <h3 className="alert-text">{alertText}</h3>
                    <CSVLink className="csvDownload" headers={headers} data={reportData} filename={filename} separator={";"}><strong>{this.props.lang.clickHere}</strong> </CSVLink>
                    <h3>{alertTextDownload}</h3>
                </div>
            )
        }
        else {
            return null
        }
    }

    getText(modified) {
        let text = ''
        if (modified > 1) {
            text = this.props.lang.other + modified + this.props.lang.coursesModifiedAnd
        } else if (modified == 0) {
            text = ''
        }
        else {
            text = this.props.lang.otherCourseWasModified
        }

        return text
    }

    getVisibilityModal() {
        const { modalReducer, lang, closeModal, changeVisibility } = this.props
        return modalReducer.id === VISIBILITY_MODAL && (
            <ModalConfirmation
                confirmTitle={lang.confirmTitle}
                cancelTitle={lang.cancelTitle}
                onConfirm={() => {
                    changeVisibility(modalReducer.data)
                    closeModal()
                }}
                onCancel={closeModal}
                body={modalReducer.data && modalReducer.data.modalText}
                show
            />
        )
    }

    renderModal() {
        const { course, lang, modalReducer } = this.props
        const visibilityModal = this.getVisibilityModal()

        const modalComponents = {
            [UNIT_MODAL]: <ModalUnit modalTitle={lang.createUnitTitle} courseId={course.id} />,
            [TOPIC_MODAL]: <ModalTopic courseId={course.id} />,
            [EXERCISE_BLOCK_MODAL]: <ModalExerciseBlock />,
            [FILE_BLOCK_MODAL]: <ModalFileBlock />,
            [PDF_BLOCK_MODAL]: <ModalPDFBlock />,
            [QUIZ_BLOCK_MODAL]: <ModalQuizBlock />,
            [SLIDE_BLOCK_MODAL]: <ModalSlideBlock />,
            [TEXT_BLOCK_MODAL]: <ModalTextBlock />,
            [NOTION_BLOCK_MODAL]: <ModalNotionBlock />,
            [VIDEO_BLOCK_MODAL]: <ModalVideoBlock />,
            [TEXT_HTML_BLOCK_MODAL]: <ModalTextHtmlBlock />,
            [EVALUABLE_BLOCK_MODAL]: <ModalEvaluableBlock />,
            [VISIBILITY_MODAL]: visibilityModal
        }
        return modalComponents[modalReducer.id]
    }

    render() {
        if (!isEmpty(this.props.course.CourseType) && !this.props.course.errorMessage) {
            return (
                <div className="container">
                    <CourseHeader
                        changeOrder={this.changeOrder}
                        course={this.props.course}
                        courseHasProgress={this.props.courseHasProgress}
                        dragDisable={this.props.dragDisable}
                        dragGeneralDisable={this.props.dragGeneralDisable}
                        handleGoBack={this.handleGoBack}
                        handleOrder={this.handleOrder}
                        lang={{
                            ...this.props.lang,
                            ...this.props.langCourse,
                            ...this.props.langDashboard,
                        }}
                        openModalUnit={this.openModalUnit}
                        renderAlert={this.renderAlert}
                    />
                    {this.props.dragDisable ? (
                        this.showUnits()
                    ) : (
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId={'droppable-' + this.props.course.id}>
                                    {provided => (
                                        <div ref={provided.innerRef}>
                                            {this.getUnits().map((unit, index) => (
                                                <Draggable
                                                    key={index}
                                                    draggableId={unit.id}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            style={{
                                                                backgroundColor: snapshot.isDraggingOver
                                                                    ? 'var(--primary)'
                                                                    : 'var(--disabled)',
                                                            }}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <UnitCard
                                                                {...unit}
                                                                key={unit.id}
                                                                courseId={this.props.course.id}
                                                            />
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}
                    {this.renderModal()}
                    <ModalLesson courseId={this.props.course.id} />
                    <DeleteBlockSuiteContainer />
                    <DeleteTopicModal />
                    <DownloadReportAlert />
                </div>
            )
        }
        return <CustomCargando />
    }
}

const mapDispatch = {
    loadActiveCourse,
    loadActiveUnits,
    updateElementsOrder: updateActiveCourseAction,
    toggleDragDisable: toggleDragAction,
    toggleDisableUnit,
    changeVisibility,
    clearUpdatedClonesAction,
    loadCourseClonesQty,
    loadAllCourseTypes,
    changeSavingUnit: changeSavingUnitAction,
    clearActiveCourseAction,
    openModal: modalActions.open,
    closeModal: modalActions.close,
    hasAnyProgress,
    clearCourseHasProgress,
    loadCoursePermissions
}

const mapStateToProps = (state) => {
    return {
        // COURSE REDUCER
        course: state.courseReducer.activeCourse,
        isLoaded: state.courseReducer.isLoaded,
        dragDisable: state.courseReducer.dragDisable,
        dragGeneralDisable: state.courseReducer.dragGeneralDisable,
        updatedClones: state.courseReducer.updatedClones,
        // MODALS REDUCER
        modalReducer: state.modal,
        //  LANG STORE
        lang: state.langStore.courseIndex,
        langCourse: state.langStore.course,
        langDashboard: state.langStore.dashboard,
        // AUTH REDUCER
        coursePermissions: state.authReducer.coursePermissions,
        //NEW COURSE REDUCER
        courseHasProgress: state.course.currentCourseHasProgress
    }
}

export default withRouter(connect(mapStateToProps, mapDispatch)(CourseIndex))