import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import CourseProgress from '../ProgressReport/CourseProgress'
import CourseReportsContainer from '../CourseReportsContainer'
import CustomCargando from '@/components/CustomCargando'
import { loadpreworkProgresss, clearCourseProgressAction } from '@/redux/reducers/_deprecated/courseReducer'

const PreworkUserReportContainer = ({
    match,
    lang,
    formattedProgressData = [],
    loadpreworkProgresss,
    clearCourseProgressAction
}) => {
    useEffect(() => {
        clearCourseProgressAction()
        loadpreworkProgresss(match.params.courseId, match.params.userId)
    }, [])

    return (
        formattedProgressData.length > 0 ?
            <div className="container">
                <div className="__mainHeaderPrework">
                    <h1 className="__main_title" >{formattedProgressData[0].name} {formattedProgressData[0].surname}</h1>
                </div>
                <CourseProgress prework={true} userId={match.params.userId} title={lang.consolidated} />
                <CourseReportsContainer title={lang.individual} prework={true} userId={match.params.userId} />
            </div>
            : <CustomCargando />
    )
}

const mapStateToProps = state => ({
    lang: state.langStore.preworkUserReportContainer,
    formattedProgressData: state.courseReducer.courseProgressData.formattedData,
})

const mapDispatch = (dispatch, functions = { loadpreworkProgresss, clearCourseProgressAction }) => ({
    clearCourseProgressAction: () => dispatch(functions.clearCourseProgressAction()),
    loadpreworkProgresss: (courseId, userId) => dispatch(functions.loadpreworkProgresss(courseId, userId))
})


export default withRouter(connect(mapStateToProps, mapDispatch)(PreworkUserReportContainer))

