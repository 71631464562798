import axios from '@/app/requests'
import { getConfig } from '@/helpers/utils'
import api from '@/config/endpoints'
import { COURSE_FILES } from '@/constants/vaultyPaths'
import * as env from '@/config/env'
import { v4 as uuidv4 } from 'uuid';
import { upload, config } from '@digitalhouse-tech/ts-sdk-vaulty'

const fileManagerServices = {
    httpModule: axios,
    async fileList(courseId) {
        const response = await this.httpModule.get(`${api.files}?course_id=${courseId}`, getConfig())
        return response
    },
    async downloadFile(path, filename) {
        fetch(path, {
            method: 'GET',
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', filename)

                document.body.appendChild(link)
                link.click()
                link.parentNode.removeChild(link)
            });
    },
    async uploadFile(courseId, file, fileName) {
        try{
            config.set({
                apiKey: env.VAULTY_API_KEY,
                writerUrl: `${env.VAULTY_WRITER_URL}`,
                readerUrl: `${env.VAULTY_READER_URL}/${env.VAULTY_PATH}`,
            })

            const extension = fileName.split('.').slice(-1)[0]
            const uuid = uuidv4()
            file = new File([file], `${uuid}.${extension}`, {type: file.type});
            const { data: { 0: { asset_name, path } } } = await upload(`${env.VAULTY_PATH}/${COURSE_FILES}`, file)

            const { id } = await this.httpModule.post(`${api.files}?course_id=${courseId}`, {
                path: `${env.VAULTY_READER_URL}/${path}/${asset_name}`,
                fileName,
                courseId,
                uuid,
            }, getConfig())
            return id
        } catch (e) {
            console.error(e)
            throw {type: 'request'}
        }
    },
    async editFile(courseId, fileId, fileName) {
        const response = await this.httpModule.patch(`${api.files}/${fileId}?course_id=${courseId}`, {
            fileId, fileName
        }, getConfig())
        return response
    },
    async deleteFile(courseId, fileId) {
        return await this.httpModule.delete(`${api.files}/${fileId}?course_id=${courseId}`, getConfig())
    }
}

export default fileManagerServices
