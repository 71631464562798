import React from 'react'
import PropTypes from 'prop-types'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { CheckCircle, ArrowLeft, Award } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import './Certificate.scss'
import { atomAsset, backgroundAsset, logoAsset } from '@/assets/img/config'

const Certificate = ({
    courseName,
    redirectHandler,
    goToCourseHandler,
    downloadHandler,
    showKeepLearningButton,
    downloadIsLoading,
    lang,
}) => {
    const mainImage = backgroundAsset('end-of-course.png')
    const secondaryImage = atomAsset('meteor.png')
    const logo = logoAsset('dh-logo.svg')

    return (
        <div className='certificate-page'>
            <div className='certificate-page__images'>
                <img
                    src={mainImage}
                    alt='Logo Digital House'
                    className='certificate-page__images--main'
                />
            </div>
            <div className='certificate-page__message'>
                <CheckCircle className='certificate-page__message--icon' />
                <h1 className='certificate-page__message--title'>{lang.ready}</h1>
                <p>{lang.finishMsg}</p>
                <p className='certificate-page__message--alias'>{courseName}</p>
                <Button
                    id='downloadCertificate'
                    className='certificate-page__message--download-certificate'
                    theme='primary'
                    onClick={downloadHandler}
                    text={lang.download}
                    loading={downloadIsLoading}
                />
                <img
                    src={logo}
                    alt='Logo Digital House'
                    className='certificate-page__message--logo'
                />
                <div className='certificate-page__message--buttons'>
                    <div>
                        <Button
                            id='backToCourse'
                            className='certificate-page__message--back-button'
                            icon={<ArrowLeft size={28} />}
                            theme='clear'
                            onClick={goToCourseHandler}
                            text={lang.backToCourse}
                            leftIcon
                        />
                    </div>
                    <div className='certificate-page__message--filler'></div>
                    <div>
                        <Button
                            visible={showKeepLearningButton}
                            id='completedFormUrl'
                            className='certificate-page__message--url'
                            icon={<Award size={28} />}
                            theme='clear'
                            onClick={redirectHandler}
                            text={lang.keepLearning}
                            leftIcon
                        />
                    </div>
                </div>
            </div>
            <div className='certificate-page__images certificate-page__images--secondary'>
                <img src={secondaryImage} alt='Logo Digital House' />
            </div>
        </div>
    )
}

Certificate.defaultProps = {
    courseName: '',
    showKeepLearningButton: false,
}

Certificate.propTypes = {
    redirectHandler: PropTypes.func.isRequired,
    goToCourseHandler: PropTypes.func.isRequired,
    downloadHandler: PropTypes.func.isRequired,
    showKeepLearningButton: PropTypes.bool,
    downloadIsLoading: PropTypes.bool,
    lang: PropTypes.shape({
        ready: PropTypes.string.isRequired,
        finishMsg: PropTypes.string.isRequired,
        download: PropTypes.string.isRequired,
        backToCourse: PropTypes.string.isRequired,
        keepLearning: PropTypes.string.isRequired,
    }).isRequired,
}

export default Certificate
