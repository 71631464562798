import React, { useMemo, memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './Skeleton.scss'
import { isEmpty } from 'lodash/lang'

const Skeleton = memo(({ containerStyle, count, circle, square, height, width, left, right }) => {
    let lines = useMemo(() => {
        const line = <span className="skeleton-container__line" style={{height, width}} />
        if (circle || square) {
            return null
        }

        if (isEmpty(lines)) {
            return line
        }

        const lines = []
        for (let index = 0; index < count; index++) {
            lines.push(line)
        }
    }, [count, circle, square, height, width])

    return (
        <div
            className={cn('skeleton-container', {
                [containerStyle]: !isEmpty(containerStyle),
                'skeleton-container--single-line': !(square || circle),
                'skeleton-container--left': left,
                'skeleton-container--right': right,
            })}
        >
            <span
                className={cn({
                    'skeleton-container__circle': circle,
                    'skeleton-container__square': square,
                    'skeleton-container__element': true,
                })}
            />

            {lines}
        </div>
    )
})

Skeleton.propTypes = {
    containerStyle: PropTypes.string,
    count: PropTypes.number,
    circle: PropTypes.bool,
    square: PropTypes.bool,
    height: PropTypes.string,
    width: PropTypes.string,
}

Skeleton.defaultProps = {
    containerStyle: null,
    count: 1,
    circle: false,
    square: false,
    height: undefined,
    width: undefined,
}

export default Skeleton
