const GET_UNITS_START = '@@unitLessonTopic/GET_UNITS_START'
const GET_UNITS_SUCCESS = '@@unitLessonTopic/GET_UNITS_SUCCESS'
const GET_UNITS_ERROR = '@@unitLessonTopic/GET_UNITS_ERROR'

const GET_LESSONS_START = '@@unitLessonTopic/GET_LESSONS_START'
const GET_LESSONS_SUCCESS = '@@unitLessonTopic/GET_LESSONS_SUCCESS'
const GET_LESSONS_ERROR = '@@unitLessonTopic/GET_LESSONS_ERROR'

const GET_TOPICS_START = '@@unitLessonTopic/GET_TOPICS_START'
const GET_TOPICS_SUCCESS = '@@unitLessonTopic/GET_TOPICS_SUCCESS'
const GET_TOPICS_ERROR = '@@unitLessonTopic/GET_TOPICS_ERROR'

const CLEAR_ALL = 'CLEAR_ALL'

const initialState = {
    units: null,
    lessons: null,
    topics: null,
}

export const getUnitsStart = () => ({type: GET_UNITS_START})
export const getUnitsSuccess = (data) => ({type: GET_UNITS_SUCCESS, payload: data})
export const getUnitsError = (error) => ({type: GET_UNITS_ERROR, payload: error})

export const getLessonsStart = () => ({type: GET_LESSONS_START})
export const getLessonsSuccess = (data) => ({type: GET_LESSONS_SUCCESS, payload: data})
export const getLessonsError = (error) => ({type: GET_LESSONS_ERROR, payload: error})

export const getTopicsStart = () => ({type: GET_TOPICS_START})
export const getTopicsSuccess = (data) => ({type: GET_TOPICS_SUCCESS, payload: data})
export const getTopicsError = (error) => ({type: GET_TOPICS_ERROR, payload: error})

export const clearAll = () => ({type: CLEAR_ALL})

const unitLessonTopicReducer = (state = initialState, { type, payload }) => {
    switch(type) {
        case GET_UNITS_START: {
            return { ...state }
        }
        case GET_UNITS_SUCCESS: {
            return { ...state, units: payload, lessons: null, topics: null }
        }
        case GET_UNITS_ERROR: {
            return { ...state }
        }

        case GET_LESSONS_START: {
            return { ...state }
        }
        case GET_LESSONS_SUCCESS: {
            return { ...state, lessons: payload, topics: null }
        }
        case GET_LESSONS_ERROR: {
            return { ...state }
        }

        case GET_TOPICS_START: {
            return { ...state }
        }
        case GET_TOPICS_SUCCESS: {
            return { ...state, topics: payload }
        }
        case GET_TOPICS_ERROR: {
            return { ...state }
        }

        case CLEAR_ALL: {
            return initialState
        }

        default: {
            return state
        }
    }
}

export default unitLessonTopicReducer