import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import { history } from '@/app/store.js'
import CustomCargando from '@/components/CustomCargando'
import Wysiwyg from '@/components/Wysiwyg'
import _ from 'lodash'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'
import { EDIT_TABS_VISIBILITY } from '@/constants/coursePermissions'
import { stripHtml } from '@/helpers/utils'

class TabEdit extends React.Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            name: { default: '', error: false, message: '', value: '' },
            tab_info: { default: '', error: false, message: '' },
            visibilityOptions: [
                { value: true, label: this.props.lang.yes },
                { value: false, label: this.props.lang.no }
            ],
            visibility: false,
        }

        this.handleGoBack = this.handleGoBack.bind(this)
        this.tabHandleChange = this.tabHandleChange.bind(this)
        this.submitTabInfo = this.submitTabInfo.bind(this)
        this.handleNameChange = this.handleNameChange.bind(this)
        this.visibilitySelectDefaultValue = this.visibilitySelectDefaultValue.bind(this)
    }

    componentWillReceiveProps(props) {
        this.setState({
            ...this.state,
            name: {
                ...this.state.name,
                value: props?.tabData.name || '',
            },
            visibility: props?.tabData.visibility || false
        })
    }

    handleGoBack() {
        history.goBack()
    }

    tabHandleChange(content, key) {
        let dataContent = {
            content: content,
            course_id: this.props.courseId,
            id: this.props.tabId,
            tab: this.props.tabData,
            key: key
        }

        this.props.contentChange(dataContent)
    }

    handleNameChange(value) {
        let nameValue = { error: false, message: '', value }
        this.setState({
            ...this.state,
            name: nameValue,
        })
    }

    visibilitySelectDefaultValue() {
        return this.state.visibility ? this.props.lang.yes : this.props.lang.no
    }

    validateInfo(name, tab) {
        //VER XQ NO VALIDA BIEN EL CAMPO DE VACIO.. AVECES PARECE VACIO PERO VIENE UN "<p></p>" y me lo toma como lleno..
        let formOk = true
        let nameValue = { error: false, message: '', value: name }
        let contentValue = { error: false, message: '' }

        if (name == null || name == '') {
            nameValue.error = true
            nameValue.message = this.props.lang.requiredInputMessage
            formOk = false
        } else {
            nameValue.error = false
            nameValue.message = ''
        }

        if (!stripHtml(tab.tab_info) || tab.tab_info == null || tab.tab_info == '') {
            contentValue.error = true
            contentValue.message = this.props.lang.noEmptyInputMessage
            formOk = false
        } else {
            contentValue.error = false
            contentValue.message = ''
        }

        this.setState({
            ...this.state,
            name: nameValue,
            tab_info: contentValue
        })

        return formOk
    }

    submitTabInfo() {
        let name = this.state.name.value
        let tab = this.props.tabData
        tab.name = name
        let formValidation = this.validateInfo(name, tab)

        if (formValidation) {
            this.props.submitTabContent(tab, this.props.courseId)
            this.props.goToTabsList()
        }
    }

    render() {
        if (this.props.tabData) {
            return (
                <>
                    <div className="container">
                        <div className="__main_header">
                            <Row>
                                <Col lg="6">
                                    <h1 className="__main_title">{this.props.lang.editTabTitle}</h1>
                                </Col>
                            </Row>
                        </div>

                        <Panel className="__card __fixedHeight">
                            <Row>

                                <Col md="6" className='_margin_bottom_25'>
                                    <Label text={`${this.props.lang.nameLabel} *`} />
                                    <InputText
                                        id="unitName"
                                        value={this.state.name.value}
                                        disabled={this.props.tabData && (this.props.tabData.name === this.props.lang.remoteClass)}
                                        onChange={(e) => this.handleNameChange(e.target.value)}
                                        error={this.state.name.error}
                                        errorMsg={this.state.name.message}
                                    />
                                </Col>

                                {EDIT_TABS_VISIBILITY() &&
                                    <Col md="6" className='_margin_bottom_25'>
                                        <Label text={this.props.lang.isVisibleLabel} />
                                        <InputComboBox
                                            inputName="visibility"
                                            id={"visibility" + this.props.tabData.id}
                                            onChange={(content) => {
                                                const visibility = content === this.props.lang.yes
                                                this.setState({...this.state, visibility })
                                                this.tabHandleChange(visibility, 'visibility')
                                                this.visibilitySelectDefaultValue()
                                            }}
                                            dropdownItems={this.state.visibilityOptions.map(({ label }) => label)}
                                            value={this.visibilitySelectDefaultValue()}
                                        />
                                    </Col>}

                                <Col md="12">
                                    <Label text={this.props.lang.content} />
                                    <div className={this.state.tab_info.error ? '__wysiwyg--error' : null}>
                                        <Wysiwyg
                                            onChange={(content) => this.tabHandleChange(content, 'tab_info')}
                                            value={this.props.tabData ? this.props.tabData.tab_info : ''}
                                        />
                                    </div>
                                    {this.state.tab_info.error &&
                                        <MessageBox
                                            theme='danger'
                                            text={this.state.tab_info.message}
                                            noFill
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <div className="__main_footer">
                                        <ButtonGroup className="pull-right">
                                            <Button
                                                id="handleGoBackButton"
                                                onClick={this.handleGoBack}
                                                text={this.props.lang.goBackButton}
                                                theme='secondary'
                                            />
                                            <Button
                                                id="submitTabInfoButton"
                                                onClick={this.submitTabInfo}
                                                text={this.props.lang.saveButton}
                                                theme='primary'
                                            />
                                        </ButtonGroup>
                                    </div>
                                </Col>
                            </Row>
                        </Panel>
                    </div>
                </>
            )
        }
        else {
            return <CustomCargando />
        }
    }
}

const mapStateToProps = (state) => ({
    lang: state.langStore.tabEdit
})

export default withRouter(connect(mapStateToProps)(TabEdit))