import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import "react-datepicker/dist/react-datepicker.css"

import BlockModal from './BlockModal'
import { BLOCK_TYPES } from '@/constants/blocks'
import {
    createModalDataSelector,
    getAllQuizBlocksSelector,
} from '@/redux/selectors'
import { loadQuizBlocks } from '@/redux/thunks/quizBlockThunks'
import { EMPTY_FIELD } from './constants/errorTypes'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

const DEFAULT_QUIZ_BLOCK = {
    quiz_block_id: 0,
}

const quizLangSelector = ({ langStore }) => langStore.modalQuizBlock
const quizDataSelector = createModalDataSelector(DEFAULT_QUIZ_BLOCK)

const ModalQuizBlock = () => {
    const dispatch = useDispatch()
    const data = useSelector(quizDataSelector)
    const lang = useSelector(quizLangSelector)
    const allQuizBlocks = useSelector(getAllQuizBlocksSelector)
    const quizBlockOptions = useMemo(
        () => allQuizBlocks.map((quiz) => ({ value: quiz.id, label: quiz.name })),
        [allQuizBlocks]
    )

    const [description, setDescription] = useState(data.description)
    const [selectedQuizBlockId, setSelectedQuizBlockId] = useState(null)
    const [quizError, setQuizError] = useState(null)
    const [selectedQuiz, setSelectedQuiz] = useState(null)
    const [searchValue, setSearchValue] = useState('')

    const values = useMemo(() => selectedQuizBlockId, [selectedQuizBlockId])

    const searchableQuizBlockOptions = quizBlockOptions.filter(({ label }) =>
        label?.trim().toLowerCase().includes(searchValue?.trim().toLowerCase())
    )

    const onChangeQuiz = (value, index) => {
        setSearchValue(value)
        if (index === -1) {
            value === '' ? setSelectedQuiz(value) : setSelectedQuiz(null)
            setSelectedQuizBlockId(null)
            return
        }
        const selected = searchableQuizBlockOptions.find(
            (quiz, i) => value === quiz.label && index === i
        )
        setSelectedQuizBlockId(selected.value)
        setSelectedQuiz(selected.label)
    }

    const validateInputValues = useCallback(() => {
        const error = !selectedQuizBlockId
        setQuizError(error ? lang.requiredQuizMessage : null)
        return error ? [EMPTY_FIELD] : []
    }, [lang, setQuizError, selectedQuizBlockId])

    useEffect(() => {
        dispatch(loadQuizBlocks())
    }, [dispatch])

    useEffect(() => {
        if (data.quiz_block_id) {
            setSelectedQuizBlockId(data.quiz_block_id)
            const selectedBlockLabel = quizBlockOptions.find((quiz) => quiz.value === data.quiz_block_id)?.label
            setSelectedQuiz(selectedBlockLabel)
            setSearchValue(selectedBlockLabel)
        }
    }, [quizBlockOptions])
    useEffect(() => {
        dispatch(loadQuizBlocks())
    }, [dispatch])

    const blockTitle = !!data.id
        ? lang.modalQuizTitleEdit
        : lang.modalQuizTitleCreate
    const id = BLOCK_TYPES.QUIZ_BLOCK.modal

    return (
        <BlockModal
            validateInputValues={validateInputValues}
            blockId={data.id}
            visibility={data.visibility}
            show_name={data.show_name}
            name={data.name}
            description={description}
            values={values}
            lang={lang}
            blockTitle={blockTitle}
            blockType={BLOCK_TYPES.QUIZ_BLOCK}
            id={id}
        >
            <Row>
                <Col md="6" className='_margin_bottom_25'>
                    <Label text={lang.quizLabel} />
                    <InputComboBox
                        error={quizError}
                        id='quizzes'
                        inputName='quizzes'
                        dropdownItems={searchableQuizBlockOptions.map(({ label }) => label)}
                        onChange={onChangeQuiz}
                        value={selectedQuiz}
                        noResultsText={lang.noResults}
                        searchable
                    />
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <Label text={lang.descriptionLabel} />
                    <InputText
                        id='blockDescription'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Col>
            </Row>
        </BlockModal>
    )
}

export default ModalQuizBlock
