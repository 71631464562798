'use-strict'

import React from 'react'
import { Modal } from 'react-bootstrap'
import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import './ExerciseCloneModal.scss'

const ExerciseCloneModal = ({
    loading,
    show,
    lang,
    exerciseId,
    exerciseName,
    closeModal,
    checkUsedName,
    cloneExercise
}) => {
    const [inputName, setInputName] = React.useState('')
    const [validationError, setValidationError] = React.useState(null)
    const [validationMessage, setValidationMessage] = React.useState('')

    React.useEffect(() => {
        setValidationError(null)
        setValidationMessage('')

        if (show) {
            setInputName(`Copia de ${exerciseName}`)
        } else {
            setInputName('')
        }
    }, [show])

    const validateForm = async () => {
        if (inputName === '' || inputName === null || inputName === undefined) {
            setValidationError('error')
            setValidationMessage(lang.requiredField)
            return
        }
        if (inputName === exerciseName) {
            setValidationError('error')
            setValidationMessage(lang.changeExerciseName)
            return
        }

        const isUsed = await checkUsedName(inputName)
        if (isUsed) {
            setValidationError('error')
            setValidationMessage(lang.nameAlreadyInUse)
            return
        }

        cloneExercise(exerciseId, inputName)
        setInputName('')
    }

    return (
        <>
            <Modal
                className="__modal"
                bsSize="large"
                show={show}
                onHide={closeModal}
                keyboard={false}
                backdrop="static"
            >
                <Modal.Header closeButton className="__modal_header __border_bottom"
                >
                    <h3 className="__modal_title">
                        {lang.cloneExercise}
                    </h3>
                </Modal.Header>

                <Modal.Body className="__modal_body">
                    <Row>
                        <Col className='col-md-12 _margin_bottom_25'>
                            <Label text={lang.exerciseNameLabel} />
                            <InputText
                                key="newName"
                                value={inputName}
                                onChange={e => setInputName(e.target.value)}
                                error={validationError}
                                errorMsg={validationMessage}
                            />
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer className="__modal_footer __border_top">
                    <div className="_btn_group pull-right">
                        <Button
                            id="CloseModalButton"
                            theme="tertiary"
                            onClick={closeModal}
                            text={lang.cancel}
                        />
                        <div className='clone-button--accept'>
                            <Button
                                id="CloneExcerciseButton"
                                theme="primary"
                                loading={loading.saveExercise}
                                onClick={validateForm}
                                text={lang.cloneExercise}
                            />
                        </div>
                    </div>
                </Modal.Footer>

            </Modal>
        </>
    )
}

export default ExerciseCloneModal