import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { history } from '@/app/store'
import CourseModal from '../Modal'
import { toggleDragAction } from '@/redux/reducers/_deprecated/courseReducer'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'
import { Edit2 } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import { loadCoursePermissions } from '@/redux/reducers/_deprecated/authReducer'
import {
    VIEW_COURSE,
    EDIT_COURSE_DESCRIPTION,
    EDIT_COURSE_DATES,
    EDIT_COURSE_CONFIG,
} from '../../../../constants/coursePermissions'
import { formatConfig } from '@/helpers/dates/timezoneConfig'
import { enableShowCompleteDate } from '@/helpers/granters/courseTypeRules'
import CourseOptions from '../CourseOptions'

import './CourseCard.scss'

export class CourseCard extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            items: [],
            coursePermissions: [],
        }

        this.getCourseInfo = this.getCourseInfo.bind(this)
        this.getCoursePermissions = this.getCoursePermissions.bind(this)
        this.getCourseState = this.getCourseState.bind(this)
        this.getParentCourse = this.getParentCourse.bind(this)
        this.goToCourse = this.goToCourse.bind(this)
        this.openModalCourse = this.openModalCourse.bind(this)
    }

    componentDidMount() {
        const { Units: units } = this.props
        this.setState(state => ({
            ...state,
            items: units,
        }))
    }

    async getCoursePermissions() {
        await this.props.loadCoursePermissions(this.props.id)
    }

    getCourseState() {
        let courseState = (
            <div className="__card_active __card_active--green-bg">
                <p className="__card_active__text">{this.props.lang.activeCourse}</p>
            </div>
        )
        if (this.props.base) {
            courseState = (
                <div className="__card_active __card_active--grey-bg">
                    <p className="__card_active__text">{this.props.lang.baseCourse}</p>
                </div>
            )
            courseState = (
                <Tooltip tooltipId="courseBase" tooltipContent={this.props.lang.canCopyCourse}>
                    <div className="__card_active __card_active--grey-bg">
                        <p className="__card_active__text">{this.props.lang.baseCourse}</p>
                    </div>
                </Tooltip>
            )
        }

        if (!this.props.visibility) {
            courseState = (
                <Tooltip tooltipId="courseNotVisible" tooltipContent={this.props.lang.courseNoVisible}>
                    <div className="__card_active __card_active--orange-bg">
                        <p className="__card_active__text">{this.props.lang.noVisible}</p>
                    </div>
                </Tooltip>
            )
        }

        if (this.props.base && !this.props.visibility) {
            courseState = (
                <Tooltip tooltipId="BaseNotVisible" tooltipContent={this.props.lang.baseCourseNoVisible}>
                    <div className="__card_active __card_active--orange-bg">
                        <p className="__card_active__text">{this.props.lang.baseNoVisible}</p>
                    </div>
                </Tooltip>
            )
        }

        return courseState
    }

    getParentCourse() {
        let courseParent = <div></div>

        if (this.props.Parent) {
            courseParent = (
                <div className="__course_parent">
                    <p className="__course_parent__text">
                        <strong>{this.props.lang.courseCopyOf}: </strong>
                    </p>
                    <p className="__course_parent__text">{this.props.Parent.name}</p>
                </div>
            )
        }
        return courseParent
    }

    async goToCourse() {
        await this.props.loadCoursePermissions(this.props.id)
        let courseLink = '/admin/courses/' + this.props.id
        history.push(courseLink)
    }

    async openModalCourse(clone, course) {
        await this.getCoursePermissions()
        return this.modalCourse.handleShow(clone, course)
    }

    grantCoursePermission(resolvers = []) {
        return resolvers.some(resolver => resolver(this.props.coursePermissions))
    }

    handleOrder() {
        this.props.toggleDragDisable()
    }

    getCourseInfo() {
        return {
            base: this.props.base,
            CourseType: this.props.CourseType,
            id: this.props.id,
            isMassive: this.props.is_massive,
            name: this.props.name,
            permissions: this.props.coursePermissions,
        }
    }

    formatDate(date) {
        const defaultFormat = ' DD/MM/YYYY'
        const hourFormat = ' HH:mm [hs]'
        return formatConfig(
            date,
            defaultFormat,
            hourFormat,
            enableShowCompleteDate(this.props.CourseType)
        )
    }

    render() {
        return (
            <div className={'__course_card __course_card__padding'}>
                <div className="__container-flex__row">
                    <div className="__container-flex__first-col">
                        {this.getCourseState()}
                        <div className="__card_course_type">
                            {this.props.CourseType && this.props.CourseType.name}
                        </div>
                    </div>

                    <div className="__container-flex__second-col">
                        <h3 className="__admin_card_title">{this.props.name}</h3>
                        <h5 className="__admin_card_alias">
                            <strong>{this.props.lang.alias}:</strong>{' '}
                            {this.props.alias ? this.props.alias : '-'}
                        </h5>
                    </div>

                    <div className="__container-flex__third-col">
                        <div className="_btn_group">
                            {this.grantCoursePermission([VIEW_COURSE]) && (
                                <Button
                                    id="GoToCourseButton"
                                    className="_view_course_button"
                                    onClick={this.goToCourse}
                                    text={this.props.lang.seeCourse}
                                    theme='tertiary'
                                />
                            )}
                            {this.grantCoursePermission([
                                EDIT_COURSE_DESCRIPTION,
                                EDIT_COURSE_DATES,
                                EDIT_COURSE_CONFIG,
                            ]) && (
                                <ButtonRound
                                    className='_open_modal_course_button'
                                    id="OpenModalCourseButton"
                                    theme='primary_dark'
                                    onClick={() =>
                                        this.openModalCourse(
                                            null,
                                            this.props.allCourses.rows.find(
                                                course => course.id === this.props.id,
                                            ),
                                        )
                                    }
                                    icon={<Edit2 size={18} />}
                                />
                            )}
                            <CourseOptions
                                cloneCourseHandler={() =>
                                    this.openModalCourse(
                                        'clone',
                                        this.props.allCourses.rows.find(
                                            (courseItem) => courseItem.id === this.getCourseInfo().id
                                        )
                                    )
                                }
                                courseInfo={this.getCourseInfo()}
                                dropdownId={'more_options_' + this.props.id}
                                textLang={{
                                    ...this.props.lang,
                                    ...this.props.langDashboard,
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="__course_card__footer">
                    {this.getParentCourse()}
                    <ul className="__card_detail">
                        <li>
                            {this.props.initial_date ? (
                                <span>
                                    <strong>{this.props.lang.startDate}:</strong>{' '}
                                    {this.formatDate(this.props.initial_date)}
                                </span>
                            ) : (
                                <span>
                                    <strong>{this.props.lang.startDate}:</strong>{' '}
                                    {this.props.lang.notAvailable}
                                </span>
                            )}
                        </li>
                        {'|'}
                        <li>
                            {this.props.finish_date ? (
                                <span>
                                    <strong>{this.props.lang.endDate}:</strong>{' '}
                                    {this.formatDate(this.props.finish_date)}
                                </span>
                            ) : (
                                <span>
                                    <strong>{this.props.lang.endDate}:</strong>{' '}
                                    {this.props.lang.notAvailable}
                                </span>
                            )}
                        </li>
                    </ul>
                </div>

                <CourseModal
                    modalTitle={this.props.lang.editCourseTitle}
                    ref={modalCourse => {
                        this.modalCourse = modalCourse
                    }}
                    {...this.props}
                />
            </div>
        )
    }
}

const mapDispatch = dispatch => ({
    toggleDragDisable: () => {
        dispatch(toggleDragAction())
    },
    loadCoursePermissions: courseId => {
        return dispatch(loadCoursePermissions(courseId))
    },
})

const mapStateToProps = (state) => {
    return {
        allCourses: state.courseReducer.allCourses,
        dragDisable: state.courseReducer.dragDisable,
        lang: { ...state.langStore.course },
        langDashboard: state.langStore.dashboard,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatch)(CourseCard))
