import { REACHED_LEFT_LIMIT, REACHED_RIGHT_LIMIT } from "../constants"

export const mappedPages = (elem) => {
    const fullWidth = elem.scrollWidth
    const visibleArea = elem.offsetWidth
    const pagesCount = Math.ceil(fullWidth / visibleArea)

    if (pagesCount === 0 || isNaN(pagesCount)) return []

    const pagesArr = new Array(pagesCount + 1).fill(null)
    return pagesArr.map((_, index) => visibleArea * index)
}

export const childFullWidth = (elem) => {
    if (!elem.childNodes.length) return

    const sliderChild = elem.childNodes[0]
    const { marginLeft, marginRight } = window.getComputedStyle(elem?.childNodes[0])
    const childFullWidth =
        sliderChild.offsetWidth + parseFloat(marginLeft) + parseFloat(marginRight)

    return childFullWidth
}

export const findActivePage = (
    pages,
    currentScroll,
    visibleWidth,
    scrollWidth,
    side,
    scrollLeftMax = 0
) => {
    const rightScroll = currentScroll + visibleWidth
    const reachedRightLimit = currentScroll === scrollLeftMax
    const position = pages.reduce((prev, curr) =>
        Math.abs(curr - rightScroll) < Math.abs(prev - rightScroll) ? curr : prev
    )
    const newActivePage = pages.indexOf(position)

    if ((side === 'left' || side === 'grab') && currentScroll <= 0) return [1, false, REACHED_LEFT_LIMIT]
    if ((side === 'right' && rightScroll >= scrollWidth) || (side === 'grab' && reachedRightLimit))
        return [pages.length - 1, false, REACHED_RIGHT_LIMIT]

    return [newActivePage, true]
}

export const pagesRanges = (elem) => {
    const totalWidth = elem.scrollWidth
    const visibleWidth = elem.offsetWidth
    const numPages = Math.ceil(totalWidth / visibleWidth)
    const pagesArr = new Array(numPages + 1).fill(null)
    return pagesArr.map((_, index) => visibleWidth * index)
}

export const visibleArea = (elem) => elem.offsetWidth

export const getMaxScrollLeft = (elem) => elem.scrollLeftMax || elem.scrollWidth - elem.clientWidth

export const getChildsLimitFit = (elem) => {
    const maxScrollLeft = getMaxScrollLeft(elem)
    const childWidth = childFullWidth(elem)
    const leftLimits = Math.ceil(maxScrollLeft / childWidth) + 1
    return Array.from({ length: leftLimits }, (_,i) =>
        i === leftLimits - 1 ? maxScrollLeft : childWidth * i
    )
}
