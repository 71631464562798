/**
 * @param {string} input
 * The string to be checked
 * @param {string} errorMessage
 * The string to be used as an error message if validation fails
 * @returns {string}
 * returns error message if validation fails
 */
 export const validateSlug = (input = '', errorMessage = '') => {
  if (!/^[\w\d]+(?:-[\w\d]+)*$/.test(input)) {
      return errorMessage
  }
}