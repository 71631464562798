import React from 'react'
import PropTypes from 'prop-types'
import CourseReportEntityButton from './CourseReportEntityButton'
import { COURSE_ENTITIES_DATA } from '@/constants/courseEntities'

const CourseReportUnitList = ({
    currentUnit,
    currentLesson,
    dataLevel,
    dataList,
    activeUnit,
    activeLesson,
    showList,
    showListHandler,
    changeDataLevel,
    lang,
}) => {
    const isUnitsLevel = dataLevel === COURSE_ENTITIES_DATA.UNITS
    const isLessonsLevel = dataLevel === COURSE_ENTITIES_DATA.LESSONS
    const isTopicsLevel = dataLevel === COURSE_ENTITIES_DATA.TOPICS

    const currentEntity = isTopicsLevel
        ? currentLesson
        : isLessonsLevel
            ? currentUnit
            : { id: null }

    const entityPrefix = isTopicsLevel ? lang.lessonPrefix : lang.unitPrefix

    return (
        <>
            <div>
                <div className="_report_select_course">
                    <CourseReportEntityButton
                        dataLevel={dataLevel}
                        showList={showList}
                        showRegularButton={!isUnitsLevel}
                        data={dataList}
                        currentEntity={currentEntity}
                        entityPrefix={entityPrefix}
                        changeDataLevel={changeDataLevel}
                        defaultLevel
                        id="_report_select_default"
                        buttonText={lang.course}
                        onClick={() =>
                            isUnitsLevel
                                ? showListHandler(showList)
                                : changeDataLevel(null, COURSE_ENTITIES_DATA.UNITS)
                        }
                        items={dataList}
                    />
                    {!isUnitsLevel && (
                        <CourseReportEntityButton
                            dataLevel={dataLevel}
                            showList={showList}
                            showRegularButton={!isLessonsLevel}
                            data={dataList}
                            currentEntity={currentEntity}
                            entityPrefix={entityPrefix}
                            changeDataLevel={changeDataLevel}
                            id="_report_select_unit"
                            buttonText={`${lang.unitPrefix}${activeUnit + 1} ${currentUnit.name}`}
                            onClick={() =>
                                isLessonsLevel
                                    ? showListHandler(showList)
                                    : changeDataLevel(currentUnit.id, COURSE_ENTITIES_DATA.LESSONS)
                            }
                            items={dataList}
                        />
                    )}
                    {isTopicsLevel && (
                        <CourseReportEntityButton
                            dataLevel={dataLevel}
                            showList={showList}
                            showRegularButton={!isTopicsLevel}
                            data={dataList}
                            currentEntity={currentEntity}
                            entityPrefix={entityPrefix}
                            changeDataLevel={changeDataLevel}
                            id="_report_select_lesson"
                            buttonText={`${lang.lessonPrefix}${activeLesson + 1} ${
                                currentLesson.name
                            }`}
                            onClick={() => (isTopicsLevel ? showListHandler(showList) : () => {})}
                            items={dataList}
                        />
                    )}
                </div>
            </div>
        </>
    )
}

CourseReportUnitList.defaultProps = {
    showList: false,
    dataList: [],
}

CourseReportUnitList.propTypes = {
    currentUnit: PropTypes.object,
    currentLesson: PropTypes.object,
    dataLevel: PropTypes.string.isRequired,
    dataList: PropTypes.array,
    activeUnit: PropTypes.number,
    activeLesson: PropTypes.number,
    showList: PropTypes.bool,
    showListHandler: PropTypes.func.isRequired,
    changeDataLevel: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
}

export default CourseReportUnitList
