import { createSelector } from 'reselect'

export const courseInfoSelector = state => state.courseInfo
export const courseInfoLang = state => state.langStore

export const courseDataSelector = createSelector(courseInfoSelector, course => {
    const { name, description, initial_date, finish_date, alias } = course
    return { name, description, initial_date, finish_date, alias }
})

export const courseToolsSelector = createSelector(courseInfoSelector, course => course.CourseTools)

export const courseUnitsSelector = createSelector(courseInfoSelector, course => course.Units)

export const courseTeachersSelector = createSelector(courseInfoSelector, course => 
    course.UserHasOwnershipRoles.map(teacher=> ({ ...teacher.User, OwnershipRole: teacher.OwnershipRole })))

export const courseInfoLangSelector = createSelector(courseInfoLang, lang => lang.courseInfo)