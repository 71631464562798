import React, { useState, useEffect, useCallback } from 'react'

import moment from 'moment'

import './CourseReports.scss'
import ReportTabs from './ReportTabs'
import ExerciseReport from './ExerciseReport'
import ExerciseIndividualReport from './ExerciseIndividualReport'
import CircularProgressBar from '@/components/CircularProgressBar'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import InputComboBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputComboBox'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'
import useQueryParams from '@/hooks/useQueryParams'

const CourseReports = ({
    goBack,
    activeCourse,
    formattedUnits,
    formattedTopics,
    handleSelectedLesson,
    handleReportSelection,
    studentsData,
    formattedStudents,
    handleSelectedStudent,
    studentResponse,
    blockType,
    selectedTopic,
    selectedLesson,
    lang,
    prework = false,
    handleReportSelectionPrework,
    handleStudentResponsePrework,
    title,
    quantity
}) => {
    const {
        lesson: lessonQueryString,
        topic: topicQueryString,
    } = useQueryParams();

    useEffect(() => {
        handleReportSelection('')
        handleReportSelectionPrework('')
    }, [formattedTopics])

    const [activeKey, setActiveKey] = useState('CONSOLIDATED')

    const handleSelectTopic = (e) => {
        if (prework) {
            handleReportSelectionPrework(e)
            setActiveKey('INDIVIDUAL')
        } else {
            handleReportSelection(e)
            setActiveKey('CONSOLIDATED')
        }
    }
    
    const handleChangeLesson = useCallback((lesson, index, queryParam) => {
        const selectedLesson = formattedUnits.find((ls, i) => lesson === ls.label && index === i)

        if (!selectedLesson) return;

        const { value, label } = selectedLesson
        handleSelectedLesson({value, label})
        
        if (!!queryParam) {
            const params = new URLSearchParams(window.location.search);
            params.set('lesson', value);
            window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);
        }
    }, [formattedUnits, handleSelectedLesson]);

    const handleChangeTopic = useCallback((topic, index, queryParam) => {
        const selectedTopic = formattedTopics.find((t, i) => topic === t.label && index === i)

        if (!selectedTopic) return;

        const { value, label } = selectedTopic
        handleSelectTopic({ value, label })
    
        if (!!queryParam) {
            const params = new URLSearchParams(window.location.search);
            params.set('topic', value);
            window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);
        }
    }, [formattedTopics, handleSelectTopic]);

    useEffect(() => {
        if (!formattedUnits?.length) return;
        
        const queryLesson = formattedUnits?.find((ls) => ls.value === lessonQueryString);
        const lessonIndex = formattedUnits?.findIndex((ls) => ls.value === lessonQueryString);
        
        handleChangeLesson(queryLesson?.label, lessonIndex, false);
    }, [formattedUnits]);


    useEffect(() => {
        if (!formattedTopics?.length) return;

        const queryTopic = formattedTopics?.find((t) => t.value === topicQueryString);
        const topicIndex = formattedTopics?.findIndex((t) => t.value === topicQueryString);
        
        handleChangeTopic(queryTopic?.label, topicIndex, false);
    }, [formattedUnits, formattedTopics]);

    const getInfo = () => {
        let totalStudents = studentsData.length
        let totalResponses = 0
        let totalApproved = 0

        let releaseDate = blockType.release_date ? new Date(blockType.release_date) : ''
        let dueDate = blockType.due_date ? new Date(blockType.due_date) : ''

        studentsData?.map(student => {
            if (
                student.User.BlockProgresses.length > 0 &&
                (student.User.BlockProgresses[0].ProgressExercise ||
                    (student.User.BlockProgresses[0].ProgressQuiz &&
                        student.User.BlockProgresses[0].ProgressQuiz.sendings_quantity))
            ) {
                totalResponses++

                if (student.User.BlockProgresses[0].completed) {
                    totalApproved++
                }
            }
        })

        return (
            <div className="__report_info_card">

                <div className="__report_title">
                    <h1>{blockType.name}</h1>
                </div>

                <div className="__report_data">

                    <div className="__report_dates_cards _release_date">
                        <span className="_date_text"> {lang.releaseDate}</span>
                        <br />
                        <span>
                            {releaseDate ? moment(releaseDate).utc().format("DD/MM/YYYY") : 'No disponible'}
                        </span>
                    </div>

                    <div className="__report_dates_cards _due_date">
                        <span className="_date_text">
                            {lang.dueDate}
                        </span>
                        <br />
                        <span>
                            {dueDate ? moment(dueDate).format("DD/MM/YYYY") : 'No disponible'}
                        </span>
                    </div>


                    <div className="pull-right">

                        <div className="__report_total_responses">
                            <CircularProgressBar percent={(totalResponses * 100) / totalStudents} style={{ display: 'inline-block' }} />
                            <div className="__report_responses">
                                {`${totalResponses}/${totalStudents}`}
                            </div>
                            <div className="__report_responses_text">
                                {lang.answersObtained}
                            </div>
                        </div>

                        <div className="__report_total_approved">
                            <CircularProgressBar percent={(totalApproved * 100) / totalStudents} />
                            <div className="__report_approved">
                                {`${Math.ceil(Math.round((totalApproved * 100) / totalStudents))}%`}
                            </div>
                            <div className="__report_approved_text">
                                {lang.studentsApproved}
                            </div>

                        </div>
                    </div>
                </div>

                <div className="meter">
                    <span className="meter_span" style={{ width: '25%' }}></span>
                </div>

                <div className="__report_commission">
                    {lang.commissionResponses}{activeCourse.name}
                </div>
            </div>
        )
    }

    const getTabs = () => {

        let baseTab = [{
            key: 'CONSOLIDATED',
            title: lang.consolidatedReport,
            component: selectComponent('consolidated')
        }]

        if (blockType.type == 'exercise') {
            baseTab.push(
                {
                    key: 'INDIVIDUAL',
                    title: lang.individualReportTabTitle,
                    component: selectComponent('individual')
                }
            )
        }

        if (prework == true) {
            let preworkTab = [{
                key: 'INDIVIDUAL',
                title: lang.individualReportTabTitle,
                component: selectComponent('individual')
            }]
            return (
                <Panel className="__card __fixedHeight">
                    <div className="__report_quantity_card _quantity">
                        <span className="_quantity_text"> {lang.exerciseReport.attempts}: {quantity}</span>
                    </div>
                    <ReportTabs
                        selectedKey={activeKey}
                        setActiveKey={setActiveKey}
                        tabs={preworkTab}
                    />
                </Panel>
            )
        }

        return (
            <Panel className="__card __fixedHeight">
                <ReportTabs
                    selectedKey={activeKey}
                    setActiveKey={setActiveKey}
                    tabs={baseTab}
                />
            </Panel>
        )
    }


    const selectComponent = (reportType) => {
        if (reportType == 'consolidated') {
            if (blockType.type == 'exercise') {
                return (
                    <ExerciseReport
                        handleSelectedStudent={handleSelectedStudent}
                        studentsData={studentsData}
                        lang={lang.exerciseReport}
                        prework={prework}
                    />
                )
            }
        }
        if (reportType == 'individual') {
            if (blockType.type == 'exercise') {
                return (
                    <ExerciseIndividualReport
                        formattedStudents={formattedStudents}
                        studentsData={studentsData}
                        handleSelectedStudent={handleSelectedStudent}
                        studentResponse={studentResponse}
                        lang={lang.individualReport}
                        prework={prework}
                        handleStudentResponsePrework={handleStudentResponsePrework}
                    />
                )
            }
            if (blockType.type == 'quiz') {
                return null
            }
        }
    }

    const toRender = () => {
        if (studentsData.length > 0 && blockType) {
            return getTabs()
        }

        if (prework && blockType) {
            if (studentsData.length > 0) {
                return getTabs()
            }
            return getTabs()
        }

        return null
    }
    return (
        <div className="container">
            {prework ?
                <div className="__prework_title_report">
                    <Row>
                        <Col lg="12">
                            <h4>{title}</h4>
                        </Col>
                    </Row>
                </div>
                :
                <div className="__main_header">
                    <Row>
                        <Col lg="12">
                            <h1 className="__main_title">
                                {lang.reports}
                                <small>{activeCourse.name}</small>
                            </h1>
                        </Col>
                    </Row>
                </div>}

            <Row>
                <Col lg="6" className='_margin_bottom_25'>
                    <Label text={lang.selectUnitAndClass} />
                    <InputComboBox
                        inputName="units"
                        placeholder={lang.selectAnOption}
                        id="units"
                        dropdownItems={formattedUnits?.map(({ label }) => label)}
                        onChange={(lesson, index) => handleChangeLesson(lesson, index, true)}
                        value={selectedLesson}
                        autoFocus
                    />
                </Col>
                <Col lg="6">
                    <Label text={lang.selectTheme} />
                    <InputComboBox
                        inputName="topics"
                        placeholder={lang.selectAnOption}
                        id="topics"
                        dropdownItems={formattedTopics?.map(({ label }) => label)}
                        onChange={(topic, index) => handleChangeTopic(topic, index, true)}
                        value={selectedTopic}
                    />
                </Col>
            </Row>

            {studentsData.length > 0 && blockType && !prework ? getInfo() : null}
            {toRender()}
            <Row>
                <Col lg="12">
                    <ButtonGroup className='pull-right'>
                        <Button
                            id="ReturnButton"
                            theme='secondary'
                            onClick={goBack}
                            text={lang.return}
                        />
                    </ButtonGroup>
                </Col>
            </Row>
        </div>
    )
}

export default CourseReports