import React, { createRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { calculatePaginationRange } from '@/helpers/pagination'
import { offset, scrollTo } from '@/helpers/utils'
import { history } from '@/app/store'
import Institute from './Institute'

import {
    changeLoadingInstituteAction,
    clearAllInstitutesAction,
    clearInstituteAction,
    clearInstituteActiveSearchAction,
    loadPaginatedInstitutes,
    setInstituteActiveSearchAction,
} from '@/redux/reducers/_deprecated/instituteReducer'
import { returnButtonHideAction } from '@/redux/reducers/_deprecated/courseReducer'
import { CREATE_INSTITUTE } from '@digitalhouse-tech/ts-lib-store/lib/session-storage/permission'

import InputText from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputText'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'
import LoadingOrContent from '@digitalhouse-tech/react-lib-ui-explorer/lib/LoadingOrContent'
import Paginator from '@digitalhouse-tech/react-lib-ui-explorer/lib/Paginator'
import Searcher from '@digitalhouse-tech/react-lib-ui-explorer/lib/Searcher'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'

export class InstituteList extends React.Component {

    constructor(props) {
        super(props)
        const { activeSearch } = props

        this.state = {
            currentPage: 1,
            pagesQuantity: 1,
            searchValue: '',

            search: {
                instituteNameSearch: activeSearch.name ? activeSearch.name : '',
            },
        }

        this.getFormBody = this.getFormBody.bind(this)
        this.handleCreate = this.handleCreate.bind(this)
        this.instituteListCardRef = createRef()
        this.pageChangeHandler = this.pageChangeHandler.bind(this)
        this.resetInstituteSearch = this.resetInstituteSearch.bind(this)
        this.searchInstitution = this.searchInstitution.bind(this)
        this.setInstituteSearch = this.setInstituteSearch.bind(this)
        this.showInstitutes = this.showInstitutes.bind(this)
    }

    static getDerivedStateFromProps(props) {
        const { pages: pagesQuantity } = props.allInstitutes
        return {
            ...(pagesQuantity && { pagesQuantity }),
        }
    }

    componentDidMount() {
        this.props.changeLoading(false)
        this.props.clearInstituteData()
        this.props.returnButtonHideAction()

        if (!_.isEmpty(this.props.activeSearch)) {
            let searchData = ''
            if (this.props.activeSearch.name && this.props.activeSearch.name !== "") {
                searchData = this.props.activeSearch.name
            }

            this.props.loadPaginatedInstitutes(this.state.currentPage, searchData)
        } else {
            this.props.clearAllInstitutesAction()
        }
    }

    componentWillUnmount() {
        this.props.clearInstituteActiveSearch()
    }

    handleCreate() {
        history.push('/admin/institutes/create')
    }

    setInstituteSearch(e) {
        let value = e.target.value
        this.setState(state => ({
            ...state,
            search: {
                ...state.search,
                instituteNameSearch: value
            }
        }))
    }

    resetInstituteSearch() {
        this.setState(state => ({
            ...state,
            search: {
                ...state.search,
                instituteNameSearch: ''
            }
        }))
    }

    async searchInstitution(e) {
        e.preventDefault()
        let searchData = ''
        const activeSearch = {}

        if (this.state.search.instituteNameSearch !== "") {
            searchData = this.state.search.instituteNameSearch
            activeSearch.name = this.state.search.instituteNameSearch
        }

        this.props.setInstituteActiveSearch(activeSearch)
        await this.props.loadPaginatedInstitutes(this.state.currentPage, searchData)

        this.setState({
            ...this.state,
            activePage: 1
        })
        const instituteListCardTop = offset(this.instituteListCardRef.current).top - 125
        scrollTo(window, instituteListCardTop)
    }

    searchBar() {
        const { getFormBody, searchInstitution } = this
        const { generalSearcherLang, isLoading } = this.props
        return (
            <Searcher
                actions={[
                    {
                        handler: this.handleCreate,
                        id: 'CreateInstituteListButton',
                        name: generalSearcherLang?.create,
                        permission: CREATE_INSTITUTE(),
                    },
                ]}
                formBody={getFormBody()}
                loadingStatus={isLoading}
                searchSubmitHandler={searchInstitution}
                submitText={generalSearcherLang?.submitSearch}
            />
        )
    }

    getFormBody() {
        const { setInstituteSearch, resetInstituteSearch } = this
        const { lang } = this.props
        const { instituteNameSearch } = this.state.search
        return (
            <Row className='_margin_bottom_25'>
                <Col md="12">
                    <Label text={lang.institutionTitle} />
                    <InputText
                        autoFocus
                        deletable
                        id='institute.name'
                        onChange={setInstituteSearch}
                        onCloseIcon={resetInstituteSearch}
                        value={instituteNameSearch}
                    />
                </Col>
            </Row>
        )
    }

    showInstitutes() {
        let resultInstitutes = Array()
        let allInstitutes = this.props.allInstitutes.institutes
        if (allInstitutes) {
            if (!allInstitutes.length) {
                return <div className="__search_msg_result"><p>{this.props.lang.noResultsFoundMessage}</p></div>
            }
            allInstitutes.map((institute, index) => {
                resultInstitutes.push(<Institute {...institute} key={index} />)
            })
        } else {
            return <div className="__search_msg_result"><p>{this.props.lang.useSearchMessage}</p></div>
        }
        return resultInstitutes
    }

    async pageChangeHandler(page) {
        let searchData = ''
        const {
            search: { instituteNameSearch },
        } = this.state
        const { loadPaginatedInstitutes } = this.props

        if (instituteNameSearch) {
            searchData = instituteNameSearch
        }

        this.setState({
            currentPage: page,
        })

        await loadPaginatedInstitutes(page, searchData)
        const instituteListCardTop = offset(this.instituteListCardRef.current).top - 100
        scrollTo(window, instituteListCardTop)
    }

    showPagination() {
        const { currentPage, pagesQuantity } = this.state
        const {
            allInstitutes: { institutes },
            isLoading,
        } = this.props
        return institutes && institutes.length ? (
            <Paginator
                currentPage={currentPage}
                disabled={isLoading}
                onPageChange={(page) => this.pageChangeHandler(page)}
                range={calculatePaginationRange(pagesQuantity)}
                totalPages={pagesQuantity}
            />
        ) : (
            <></>
        )
    }

    render() {
        return (
            <>
                {this.searchBar()}
                <div className='container'>
                    <div ref={this.instituteListCardRef}>
                        <LoadingOrContent loading={this.props.isLoading} size='medium'>
                            {this.showInstitutes()}
                        </LoadingOrContent>
                    </div>
                    <div className='__pagination'>{this.showPagination()}</div>
                </div>
            </>
        )
    }
}

const mapDispatch = (dispatch) => ({
    changeLoading: (status) => {
        dispatch(changeLoadingInstituteAction(status))
    },
    clearAllInstitutesAction: () => {
        dispatch(clearAllInstitutesAction())
    },
    clearInstituteData: () => {
        dispatch(clearInstituteAction())
    },
    loadPaginatedInstitutes: (page, name) => dispatch(loadPaginatedInstitutes(page, name)),
    returnButtonHideAction: () => {
        dispatch(returnButtonHideAction())
    },
    clearInstituteActiveSearch: () => {
        dispatch(clearInstituteActiveSearchAction())
    },
    setInstituteActiveSearch: (data) => {
        dispatch(setInstituteActiveSearchAction(data))
    },
})


const mapStateToProps = (state) => {
    return {
        activeSearch: state.instituteReducer.activeSearch,
        allInstitutes: state.instituteReducer.allInstitutes,
        generalSearcherLang: state.langStore.generalSearcher,
        isLoading: state.instituteReducer.isLoadingInstitute,
        lang: state.langStore.addInstitution,
    }
}

InstituteList.propTypes = {
    activeSearch: PropTypes.object.isRequired,
    allInstitutes: PropTypes.shape({
        count: PropTypes.number,
        institutes: PropTypes.arrayOf(PropTypes.object),
        isLoaded: PropTypes.bool,
        limit: PropTypes.number,
        pages: PropTypes.number,
    }).isRequired,
    changeLoading: PropTypes.func.isRequired,
    clearAllInstitutesAction: PropTypes.func.isRequired,
    clearInstituteActiveSearch: PropTypes.func.isRequired,
    clearInstituteData: PropTypes.func.isRequired,
    generalSearcherLang: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    lang: PropTypes.object.isRequired,
    loadPaginatedInstitutes: PropTypes.func.isRequired,
    returnButtonHideAction: PropTypes.func.isRequired,
    setInstituteActiveSearch: PropTypes.func.isRequired,
}

export default withRouter(connect(mapStateToProps, mapDispatch)(InstituteList))
