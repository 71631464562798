import React from 'react'
import { connect } from 'react-redux'
import './Modal.scss'
import { Modal } from 'react-bootstrap'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'

class ModalConfirmation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showByProps: this.props.show,
            showByRef: false,
        }
        this.onCancel = this.onCancel.bind(this)
        this.onConfirm = this.onConfirm.bind(this)
        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.show !== state.showByProps) {
            return { showByProps: props.show }
        }
        return {}
    }

    handleShow() {
        this.setState({
            ...this.state,
            showByRef: true,
        })
    }

    onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel()
        }
        this.setState({
            ...this.state,
            showByProps: false,
            showByRef: false
        })
    }

    onConfirm() {
        this.props.onConfirm()
        this.setState({
            ...this.state,
            showByProps: false,
            showByRef: false
        })
    }

    handleClose() {
        if (this.props.onCancel) this.props.onCancel()

        this.setState(state => ({
            ...state,
            showByProps: false,
            showByRef: false
        }))
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    className="__modal __modal_confirmation _modal_responsive"
                    show={this.state.showByProps || this.state.showByRef}
                    onHide={this.handleClose}
                    keyboard={false}
                    backdrop="static">
                    <Modal.Header closeButton className="__modal_header __border_bottom">
                        <h3 className="__modal_title">{this.props.lang.attention}</h3>
                    </Modal.Header>

                    <Modal.Body className="__modal_body">
                        {this.props.body}
                    </Modal.Body>

                    {!this.props.noFooter ? <Modal.Footer className="__modal_footer __border_top">
                        <ButtonGroup className='pull-right'>
                            <Button
                                id="CancelButton"
                                theme='tertiary'
                                onClick={this.onCancel}
                                text={this.props.cancelTitle}
                            />
                            <Button
                                id="ConfirmButton"
                                theme='primary'
                                onClick={this.onConfirm}
                                text={this.props.confirmTitle}
                            />
                        </ButtonGroup>
                    </Modal.Footer>
                        : <Modal.Footer className="__modal_footer __border_top">
                            <ButtonGroup className="pull-right">
                                <Button
                                    id="CloseButton"
                                    theme='tertiary'
                                    onClick={this.onCancel}
                                    text={this.props.lang.close}
                                />
                            </ButtonGroup>
                        </Modal.Footer>
                    }
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    lang: state.langStore.modalConfirmation
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(ModalConfirmation)