import httpClient from '../httpClient'
import * as env from '@/config/env'

const client = (userId) => httpClient(`/students/${userId}/`, undefined, { timezone: true })
const httpAssessment = () => httpClient('/assessment/', env.ASSESSMENT_API_URL, { useVersion: false, apiToken: env.ASSESSMENT_API_TOKEN })

export const getContentMap = ({ userId, courseId, unitId }) =>
    client(userId).get(`courses/${courseId}/units/${unitId}/content-map`)

export const getTopicContent = ({ userId, courseId, unitId, lessonId, topicId }) =>
    client(userId).get(
        `courses/${courseId}/units/${unitId}/lessons/${lessonId}/topics/${topicId}/content`
    )

export const getStudentRoadmap = (userId, courseId) =>
    client(userId).get(`courses/${courseId}/roadmap`)

export const getEggWidgetToken = (userId, courseId) =>
    client(userId).post(`/courses/${courseId}/egg-ping`)

export const studentEggAttendance = (userId, courseId) =>
    client(userId).post(`/courses/${courseId}/attendance`)

export const getStudentGradebook = (strategyId, courseId, userId) => 
        httpAssessment().get(`/strategies/${strategyId}/courses/${courseId}/users/${userId}/gradebook`)
