import React from 'react'
import PropTypes from 'prop-types'
import Browser from './Browser'
import NodejsTerminal from './Terminal/NodejsTerminal'
import JavaTerminal from './Terminal/JavaTerminal'

const constants = {
    BROWSER: 'Browser',
    NODEJS_TERMINAL: 'NodejsTerminal',
    JAVA_TERMINAL: 'JavaTerminal',
}

const Mode = ({
    mode,
    iframeScript,
    executing,
    logs,
    throws,
    random,
    setRandom,
    reference
}) => {
    const modes = {
        [constants.BROWSER]: (
            <Browser
                host='playground.digitalhouse.com'
                content={iframeScript}
                width='100%'
                height='500px'
                logs={logs}
                setRandom={setRandom}
                throws={throws}
                random={random}
                executing={executing}
            />
        ),
        [constants.NODEJS_TERMINAL]: (
            <NodejsTerminal
                start={executing}
                user='root'
                logs={logs}
                throws={throws}
                content={iframeScript}
                random={random}
            />
        ),
        [constants.JAVA_TERMINAL]: (
            <JavaTerminal
                start={executing}
                user='root'
                logs={logs}
                throws={throws}
                content={iframeScript}
                random={random}
            />
        ),
    }

    return <div ref={reference}>{modes[mode]}</div>
}

Mode.defaultProps = {
    logs: [],
    throws: [],
}

Mode.propTypes = {
    mode: PropTypes.oneOf([constants.BROWSER, constants.TERMINAL]).isRequired,
    iframeScript: PropTypes.string.isRequired,
    executing: PropTypes.bool.isRequired,
    logs: PropTypes.arrayOf(PropTypes.shape()),
    throws: PropTypes.arrayOf(PropTypes.shape()),
    random: PropTypes.number.isRequired,
    setRandom: PropTypes.func.isRequired,
    reference: PropTypes.shape({
        current: PropTypes.shape()
    }).isRequired
}

export default Mode
