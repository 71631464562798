import React from 'react'
import PropTypes from 'prop-types'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'

const StudentUnitHeader = ({
    modulesCount,
    title,
    linkTitle,
    handleClick,
    isMobile,
    allowApiCreateRule,
    accesilibityMessage,
    buttonLink,
}) => {
    const renderTitle = () => {
        if(modulesCount === 1) {
            return `${modulesCount} ${title.slice(0,-1)}`
        }
        return `${modulesCount} ${title}`
    }
    if(!allowApiCreateRule) return null
    return (
        <div className="StudentUnitsHeader">
            {allowApiCreateRule && <p>{renderTitle()}</p>}
            {(!isMobile && !(modulesCount === 1)) && (
                <Button
                    id='toggleCardContentButton'
                    onClick={handleClick}
                    data-message={accesilibityMessage}
                    text={linkTitle}
                    theme='tertiary'
                    icon={buttonLink}
                />
            )}
        </div>
    )
}
StudentUnitHeader.propTypes = {
    modulesCount: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    linkTitle: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    allowApiCreateRule: PropTypes.bool.isRequired,
    accesilibityMessage: PropTypes.string.isRequired,
    buttonLink: PropTypes.number.isRequired,
}
export default StudentUnitHeader