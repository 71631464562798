import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { acceptLegalDisclaimer } from '@/redux/reducers/_deprecated/authReducer'
import { history } from '@/app/store'
import CustomCargando from '@/components/CustomCargando'
import { downloadPrivacyPolicy } from '@/redux/reducers/_deprecated/userReducer'
import GoBackBar from '@/components/GoBackBar/GoBackBar'
import AnimateComponent from '@/components/AnimateComponent'
import { CheckCircle } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import ConfirmMessageMobile from '@/components/ConfirmMessageMobile/ConfirmMessageMobile'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { setLocation } from '@/routes/guards'
import { DISCLAIMER_LOCATION } from '@/constants/locations'

export class LegalDisclaimer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            buttonDisabled: true,
            goToTerms: false,
            isLoaded: false,
            goToConfirm: false,
        }

        this.onAccept = this.onAccept.bind(this)
        this.downloadTerms = this.downloadTerms.bind(this)
        this.getLegalDisclamer = this.getLegalDisclamer.bind(this)
        this.onGoBackHandler = this.onGoBackHandler.bind(this)
        this.getGoBackBar = this.getGoBackBar.bind(this)

        this.getCurrentComponent = this.getCurrentComponent.bind(this)
        this.getConfirmMessage = this.getConfirmMessage.bind(this)

        this.onFirstButtonHandler= this.onFirstButtonHandler.bind(this)
    }

    componentDidMount() {
        setLocation(DISCLAIMER_LOCATION)
        //let body = document.body, html = document.documentElement;
        //let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        if (this.props.location.state && this.props.location.state.goToTerms) {
            this.setState(state => ({
                ...state,
                goToTerms: true,
                isLoaded: true,
                goToConfirm: false,
            }))
        } else {
            this.setState(state => ({
                ...state,
                goToTerms: false,
                isLoaded: true
            }))
        }

        /*
        window.onscroll = () => {
            if ((window.scrollY + window.innerHeight) >= height && this.state.buttonDisabled) {
                this.setState({
                    ...this.state,
                    buttonDisabled: false,
                })
            }
        }
        */
    }

    onAccept() {
        if (this.props.isMobile) {
            //this.props.acceptLegalDisclaimer()
            this.setState(state => ({
                ...state,
                goToTerms: true,
                goToConfirm: true
            }))
        } else {
            this.props.acceptLegalDisclaimer()
            if (this.props.legal_disclaimer) {
                this.redirectToRootRoute()
            }
        }
    }

    redirectToRootRoute(){
        location.replace('/', { goToTerms: false })
    }

    onFirstButtonHandler(){
        this.props.acceptLegalDisclaimer()
        this.redirectToRootRoute()
    }

    downloadTerms() {
        this.props.downloadPrivacyPolicy()
    }

    onGoBackHandler() {

        if(this.props.legal_disclaimer ) history.goBack()
        else this.setState(state=>({...state, goToConfirm: false}))
    }

    getGoBackBar() {
        return this.props.isMobile && this.props.legal_disclaimer ? <GoBackBar text={this.props.legalDisclaimerLang.termsAndConditions} onGoBack={this.onGoBackHandler} /> : null
    }

    getLegalDisclamer() {
        return (
            <div className="container __legaldisclaimer">
                {this.getGoBackBar()}
                <div className="__card">
                    <h1 style={{ textAlign: "center" }} > {this.props.legalDisclaimerLang.termsAndConditions} </h1>
                    <h3 style={{ textAlign: "center" }} > {this.props.legalDisclaimerLang.privacyPolicy} </h3>
                    <br />
                    <ol id="lalala" dangerouslySetInnerHTML={{ __html: this.props.legalDisclaimerLang.textBody }}></ol>

                    {!this.props.legal_disclaimer &&
                        <div className="_btn_group pull-right _margin_bottom_15">
                            <Button
                                id="ConfirmButton"
                                onClick={this.onAccept}
                                text={this.props.legalDisclaimerLang.confirm}
                                theme='primary'
                            />
                        </div>
                    }

                    <div className="clearfix"></div>
                </div>
            </div>
        )
    }

    getConfirmMessage() {
        return (
            <ConfirmMessageMobile
                onGoBack={this.onGoBackHandler}
                onFisrt={this.onFirstButtonHandler}
                onThird={() => { }}
                customIcon={<CheckCircle />}
                lang={{
                    goBack: this.props.legalDisclaimerMessageLang.goBack,
                    title: this.props.legalDisclaimerMessageLang.title,
                    message: this.props.legalDisclaimerMessageLang.message,
                    firstOption: this.props.legalDisclaimerMessageLang.exit,
                    secondOption: '',
                    thirdOption: ''
                }}
            />
        )
    }

    getCurrentComponent() {
        if (this.state.isLoaded) {
            if (this.state.goToConfirm) return this.getConfirmMessage()

            if (this.props.legal_disclaimer && !this.state.goToTerms) {
                return <Redirect to={{ pathname: '/' }} />
            }
            if (this.props.loading) {
                return <div className="container __legaldisclaimer">
                    <CustomCargando />
                </div>
            }
            else {
                return this.getLegalDisclamer()
            }
        } else {
            return (
                <div className="container __legaldisclaimer">
                    <CustomCargando />
                </div>
            )
        }
    }

    render() {
        return (
            <div className="">
                <AnimateComponent componentToShow={this.getCurrentComponent()} />
            </div>
        )
    }
}

const mapDispatch = (dispatch) => ({
    acceptLegalDisclaimer: () => dispatch(acceptLegalDisclaimer()),
    downloadPrivacyPolicy: () => dispatch(downloadPrivacyPolicy()),
})

const mapStateToProps = (state) => {
    return {
        legal_disclaimer: state.authReducer.user.legal_disclaimer,
        legalDisclaimerLang: state.langStore.legalDisclaimer,
        legalDisclaimerMessageLang: state.langStore.legalDisclaimerMessage,
        loading: state.authReducer.loading,
        isMobile: state.authReducer.isMobile
    }
}

export default withRouter(connect(mapStateToProps, mapDispatch)(LegalDisclaimer))
