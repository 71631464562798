'use-strict'

import api from '@/config/endpoints'
import requests from '@/app/requests'
import { getConfig } from '@/helpers/utils'

export default {
    loadAllRoles: () => {
        const endpoint = api.roles
        return requests.get(endpoint, getConfig())
    },
    loadRole: id => {
        const endpoint = api.singleRole(id)
        return requests.get(endpoint, getConfig())
    },
    loadPaginatedRoles: (finder, paginationConfig) => {
        const endpoint = api.searchPaginatedRoles({ ...finder, ...paginationConfig })
        return requests.get(endpoint, getConfig())
    },
    loadAllPermissions: name => {
        const endpoint = api.permissions(name)
        return requests.get(endpoint, getConfig())
    },
    saveNewrole: data => {
        const endpoint = api.roles
        return requests.post(endpoint, data, getConfig())
    },
    updateOneRole: (id, data) => {
        const endpoint = api.updateRole(id)
        return requests.patch(endpoint, data, getConfig())
    },
    deleteRole: (id) => {
        const endpoint = api.deleteRole(id)
        return requests.delete(endpoint, getConfig())
    }
}