import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import getUtc from '@/helpers/dates/getUtc'
import { timezoneConfig } from '@/helpers/dates/timezoneConfig'
import './DatePill.scss'

const [tz] = timezoneConfig()
const DATE_FORMAT = 'DD \\d\\e MMMM'

const DatePill = ({ date = '' }) => {
    const formatedTime = useMemo(() => getUtc(date).tz(tz).format(DATE_FORMAT).toString(), [date])

    if (!date) {
        return null
    }

    return (
        <p className="date-pill-container">
            <span>{formatedTime}</span>
        </p>
    )
}

DatePill.propTypes = {
    date: PropTypes.string.isRequired,
}

export default DatePill
