'use-strict'

const initialState = {
    // for search engines
    searcher: {
        search: {},
    },
    // for paginations
    pagination: {
        currentPage: 1,
        totalRows: 0,
        pagesQuantity: 0,
        nextPage: null,
        rows: [],
        isLoaded: false,
    },
    // for listings
    rows: []
}

export const constants = {
    SET_SEARCHER_DEFAULT_VALUES: '@@manager/SET_SEARCHER_DEFAULT_VALUES',
    SET_SEARCHER_VALUE: '@@manager/SET_SEARCHER_VALUE',
    SET_PAGINATION_DEFAULT_VALUES: '@@manager/SET_PAGINATION_DEFAULT_VALUES',
    SET_PAGINATION_VALUE: '@@manager/SET_PAGINATION_VALUE',
    DELETE_PAGINATION_ROW: '@@manager/DELETE_PAGINATION_ROW',
    SET_ROWS_VALUES: '@@manager/SET_ROWS_VALUES',
    CLEAR_PAGINATION_VALUES: '@@manager/CLEAR_PAGINATION_VALUES',
    CLEAR_ROWS_VALUES: '@@manager/CLEAR_ROWS_VALUES',
    ADD_PAGINATION_ROWS: '@@manager/ADD_PAGINATION_ROWS',
    ADD_ROWS: '@@manager/ADD_ROWS',
    ADD_NEW_ROW: '@@manager/ADD_NEW_ROW',
    UPDATE_ROW: '@@manager/UPDATE_ROW',
    DELETE_ROW: '@@manager/DELETE_ROW',
}

export const actions = {
    setSearcherDefaultValues: searcher => ({
        type: constants.SET_SEARCHER_DEFAULT_VALUES,
        payload: { searcher },
    }),
    setSearcherValue: (key, value) => ({
        type: constants.SET_SEARCHER_VALUE,
        payload: { key, value },
    }),
    setPaginationDefaultValues: pagination => ({
        type: constants.SET_PAGINATION_DEFAULT_VALUES,
        payload: { pagination },
    }),
    setPagintationValue: (key, value) => ({
        type: constants.SET_PAGINATION_VALUE,
        payload: { key, value },
    }),
    deletePaginationRow: (userId) => ({
        type: constants.DELETE_PAGINATION_ROW,
        payload: userId,
    }),
    setRowsValues: rows => ({
        type: constants.SET_ROWS_VALUES,
        payload: { rows },
    }),
    clearPaginationValues: () => ({
        type: constants.CLEAR_PAGINATION_VALUES,
    }),
    clearRowsValues: () => ({
        type: constants.CLEAR_ROWS_VALUES
    }),
    addPaginationRows: rows => ({
        type: constants.ADD_PAGINATION_ROWS,
        payload: rows,
    }),
    addRows: rows => ({
        type: constants.ADD_ROWS,
        payload: rows,
    }),
    addNewRow: row => ({
        type: constants.ADD_NEW_ROW,
        payload: row,
    }),
    updateRow: row => ({
        type: constants.UPDATE_ROW,
        payload: row,
    }),
    deleteRow: row => ({
        type: constants.DELETE_ROW,
        payload: row,
    })
}

const managerReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case constants.SET_SEARCHER_DEFAULT_VALUES: {
            return {
                ...state,
                searcher: payload.searcher,
            }
        }
        case constants.SET_SEARCHER_VALUE: {
            const { key, value } = payload
            return {
                ...state,
                searcher: {
                    ...state.searcher,
                    search: {
                        ...state.searcher.search,
                        [key]: value
                    }
                }
            }
        }
        case constants.SET_PAGINATION_DEFAULT_VALUES: {
            const { pagination } = payload
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...pagination,
                    isLoaded: true,
                },
            }
        }
        case constants.SET_PAGINATION_VALUE: {
            const { key, value } = payload
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    [key]: value,
                },
            }
        }
        case constants.DELETE_PAGINATION_ROW: {
            const paginationRowsCopy = [...state.pagination.rows]
            const index = paginationRowsCopy.findIndex(({ id }) => id === payload)
            paginationRowsCopy.splice(index,1)
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    rows: paginationRowsCopy
                },
            }
        }
        case constants.SET_ROWS_VALUES: {
            const { rows } = payload
            return {
                ...state,
                rows,
            }
        }
        case constants.ADD_PAGINATION_ROWS: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    rows: [...state.pagination.rows, ...payload],
                    totalRows: state.pagination.totalRows + payload.length
                },
            }
        }
        case constants.ADD_ROWS: {
            return {
                ...state,
                rows: [...state.rows, ...payload]
            }
        }
        case constants.CLEAR_ROWS_VALUES: {
            return {
                ...state,
                rows: initialState.rows
            }
        }
        case constants.CLEAR_PAGINATION_VALUES: {
            return {
                ...state,
                pagination: initialState.pagination,
            }
        }
        case constants.ADD_NEW_ROW: {
            return {
                ...state,
                rows: [payload, ...state.rows]
            }
        }
        case constants.UPDATE_ROW: {
            const rowsCopy = [...state.rows]
            const index = rowsCopy.findIndex(({ id }) => id === payload.id)
            rowsCopy[index] = payload
            return {
                ...state,
                rows: rowsCopy
            }
        }
        case constants.DELETE_ROW: {
            const rowsCopy = [...state.rows]
            const index = rowsCopy.findIndex(({ id }) => id === payload)
            rowsCopy.splice(index,1)
            return {
                ...state,
                rows: rowsCopy
            }
        }
        case 'LOGOUT_USER': {
            return initialState
        }
        default: {
            return state
        }
    }
}

export default managerReducer
