import React from 'react'
import PropTypes from 'prop-types'

import { Check } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'

import './RoleDetailList.scss'

const RoleDetailList = ({ category, categoryId, permissions }) => {

    return (
        <div key={`categoty-list-${categoryId}`}>
            <p className="permission-category_title" key={`categoty-title-${categoryId}`}>
                {`${category}:`}
            </p>
            <ul className="fa-ul role-detail-list permission-category_list" key={`categoty-list-${categoryId}`}>
                {permissions.map(permission => (
                    <li key={`list-item-${permission}`}>
                        <Check className='permission-category_icon' />
                        <p className="permission-category_description">{permission}</p>
                    </li>
                ))}
            </ul>
        </div>
    )
}

RoleDetailList.propTypes = {
    category: PropTypes.string.isRequired,
    categoryId: PropTypes.number.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default RoleDetailList