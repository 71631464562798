'use-strict'

const initialState = {
    navigation: {
        tabs: [],
    },
    showPreloader: false,
}

export const constants = {
    SET_NAVIGATION: '@navigation/SET_NAVIGATION',
    SET_SHOW_PRELOADER: '@navigation/SET_SHOW_PRELOADER',
}

export const actions = {
    setNavigation: navigation => ({
        type: constants.SET_NAVIGATION,
        payload: { navigation },
    }),
    setShowPreloader: boolean => ({
        type: constants.SET_SHOW_PRELOADER,
        payload: { boolean },
    }),
}

const navigationReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_NAVIGATION:
            return {
                ...state,
                navigation: action.payload.navigation,
            }
        case constants.SET_SHOW_PRELOADER:
            return {
                ...state,
                showPreloader: action.payload.boolean,
            }
        case 'LOGOUT_USER': {
            return initialState
        }
        default: {
            return state
        }
    }
}

export default navigationReducer
