import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { history } from '@/app/store'
import PropTypes from 'prop-types'

import {
    loadActiveCourse,
    getReportData,
    clearReportDataAction,
    loadActiveUnits,
    loadActiveLessons,
    loadActiveTopics,
    loadActiveBlocks,
    clearActiveCourseAction,
} from '@/redux/reducers/_deprecated/courseReducer'
import { loadCoursePermissions } from '@/redux/reducers/_deprecated/authReducer'
import { getExerciseData } from '@/redux/reducers/_deprecated/courseReducer/controllers'
import CourseReports from './CourseReports'
import CustomCargando from '@/components/CustomCargando'

const CourseReportsContainer = ({
    match,
    activeCourse,
    loadActiveCourse,
    loadActiveUnits,
    loadActiveLessons,
    loadActiveTopics,
    loadActiveBlocks,
    clearActiveCourse,
    getReportData,
    reportData,
    clearReportDataAction,
    lang,
    prework = false,
    userId,
    userDataPrework,
    title,
    loadCoursePermissions,
}) => {
    useEffect(() => {
        return () => clearActiveCourse()
    }, [])

    useEffect(() => {
        loadCoursePermissions(match.params.courseId)
        clearReportDataAction()
        if (!activeCourse.id || match.params.courseId !== activeCourse.id) {
            loadActiveCourse(match.params.courseId)
            loadActiveUnits(match.params.courseId)
        }
    }, [])

    useEffect(() => {
        formatUnitsWithLessons()
    }, [activeCourse.Units])

    const [formattedTopics, setFormattedTopics] = useState([])
    const [studentResponse, setStudentResponse] = useState('')
    const [blockType, setBlockType] = useState('')
    const [selectedTopic, setSelectedTopic] = useState('')
    const [selectedLesson, setSelectedLesson] = useState('')
    const [quantity, setQuantity] = useState(0)
    const [units, setUnits] = useState([])
    const [lessonsRequested, setLessonsRequested] = useState([])
    const [lessonsRequestData, setLessonsRequestData] = useState([])
    const [topicsRequested, setTopicsRequested] = useState([])
    const [topicsRequestData, setTopicsRequestData] = useState([])
    const [blocksRequested, setBlocksRequested] = useState([])
    const [blocksRequestData, setBlocksRequestData] = useState([])

    const [loadedBlocks, setLoadedBlocks] = useState(0)

    const handleGoBack = () => {
        history.goBack()
    }

    useEffect(() => {
        if (lessonsRequestData.length)
            loadActiveLessons(match.params.courseId, ...lessonsRequestData)
    }, [lessonsRequestData, lessonsRequested])

    useEffect(() => {
        if (topicsRequestData.length)
            loadActiveTopics(match.params.courseId, ...topicsRequestData)
    }, [topicsRequestData, topicsRequested])

    useEffect(() => {
        if (blocksRequestData.length)
            loadActiveBlocks(match.params.courseId, ...blocksRequestData)
    }, [blocksRequestData, blocksRequested])

    const formatUnitsWithLessons = () => {
        let formattedUnits = []
        activeCourse.Units?.map((unit) => {
            if (!unit.Lessons && !lessonsRequested.includes(unit.id)) {
                setLessonsRequestData([unit.id])
                setLessonsRequested([...lessonsRequested, unit.id])
            }
            unit?.Lessons?.map((lesson) => {
                if (!lesson.Topics && !topicsRequested.includes(lesson.id)) {
                    setTopicsRequestData([unit.id, lesson.id])
                    setTopicsRequested([...topicsRequested, lesson.id])
                }
                const hasExcerciseBlock = lesson.Topics?.filter((topic) => {
                    if (!topic.Blocks && !blocksRequested.includes(topic.id)) {
                        setBlocksRequestData([unit.id, lesson.id, topic.id])
                        setBlocksRequested([...blocksRequested, topic.id])
                    } else setLoadedBlocks(loadedBlocks + 1)
                    return topic.Blocks?.filter(
                        (block) => block.exercise_block_id && block.visibility
                    ).length
                }).length
                if (hasExcerciseBlock) {
                    let unitAndLesson = {
                        value: lesson.id,
                        label: `${unit.name} - ${lesson.name}`,
                    }
                    formattedUnits.push(unitAndLesson)
                }
            })
        })

        setUnits(formattedUnits)
    }

    const formatTopics = (lessonId) => {
        let topicsArr = []
        activeCourse.Units?.map((unit) => {
            unit.Lessons?.map((lesson) => {
                if (lesson.id == lessonId) {
                    lesson.Topics?.map((topic) => {
                        topic.Blocks?.map((block) => {
                            if (block.visibility && block.exercise_block_id) {
                                let topicAndBlock = {
                                    value: block.id,
                                    label: `${topic.name} - ${block.name}`,
                                }
                                topicsArr.push(topicAndBlock)
                            }
                        })
                    })
                }
            })
        })

        setFormattedTopics(topicsArr)
    }

    const formatStudents = () => {
        const studentsArr = []
        if (reportData.length > 0) {
            if (prework) {
                const studentObj = {
                    value: reportData[0].id,
                    label: `Prework`,
                }
                studentsArr.push(studentObj)
            } else {
                reportData.map((student) => {
                    const studentObj = {
                        value: student.user_id,
                        label: `${student.User.name} ${student.User.surname}`,
                    }
                    studentsArr.push(studentObj)
                })
            }
            return studentsArr
        }

        return studentsArr
    }

    const handleSelectedLesson = (lesson) => {
        formatTopics(lesson.value)
        setSelectedTopic('')
        setSelectedLesson(lesson.label)
    }

    const handleReportSelection = (block) => {
        if (block) {
            getReportData(activeCourse.id, block.value)

            activeCourse.Units?.map((unit) => {
                unit.Lessons?.map((lesson) => {
                    lesson.Topics?.map((topic) => {
                        topic.Blocks?.map((singleBlock) => {
                            if (singleBlock.id == block.value) {
                                let blockType = {
                                    type: singleBlock.exercise_block_id ? 'exercise' : 'quiz',
                                    name: singleBlock.name,
                                    due_date: lesson.due_date,
                                    release_date: lesson.release_date,
                                    courseName: activeCourse.name,
                                    blockId: block.value,
                                }
                                setBlockType(blockType)
                                setSelectedTopic(block.label)
                                setStudentResponse('')
                            }
                        })
                    })
                })
            })
        } else {
            setBlockType('')
        }
    }

    const handleReportSelectionPrework = (block) => {
        if (block) {
            getExerciseData(userId, match.params.courseId, block.value)

            activeCourse.Units?.map((unit) => {
                unit.Lessons?.map((lesson) => {
                    lesson.Topics?.map((topic) => {
                        topic.Blocks?.map((singleBlock) => {
                            if (singleBlock.id == block.value) {
                                let blockType = {
                                    type: singleBlock.exercise_block_id ? 'exercise' : 'quiz',
                                    name: singleBlock.name,
                                    due_date: lesson.due_date,
                                    release_date: lesson.release_date,
                                    courseName: activeCourse.name,
                                    blockId: block.value,
                                }
                                setBlockType(blockType)
                                setSelectedTopic(block.label)
                                setStudentResponse('')
                            }
                        })
                    })
                })
            })
        } else {
            setBlockType('')
        }
    }

    const formatStudentReponse = (data) => {
        if (typeof data == 'string') {
            return JSON.parse(data)
        }
        return data
    }
    const handleSelectedStudent = (student) => {
        let studentResponseAux = reportData.find((elem) => {
            return elem.user_id == student.value
        })
        let response =
            studentResponseAux.User.BlockProgresses.length > 0
                ? formatStudentReponse(
                      studentResponseAux.User.BlockProgresses[0].ProgressExercise.last_sending
                  )
                : {}
        return setStudentResponse(response)
    }

    //QUE EL BACK DEVUELVA SOLO EL LAST_SENDING PARA PREWORK.
    const handleStudentResponsePrework = () => {
        setQuantity(0)
        let response = {}
        if (reportData.length > 0) {
            setQuantity(reportData[0].ProgressExercise.sendings_quantity)
            response = formatStudentReponse(reportData[0].ProgressExercise.last_sending)
        }
        return setStudentResponse(response)
    }

    return loadedBlocks === 0 || loadedBlocks < blocksRequested.length ? (
        <CustomCargando />
    ) : (
        <>
            <CourseReports
                goBack={handleGoBack}
                activeCourse={activeCourse}
                formattedUnits={units}
                formattedTopics={formattedTopics}
                formattedStudents={formatStudents()}
                handleSelectedLesson={handleSelectedLesson}
                handleReportSelection={handleReportSelection}
                studentsData={reportData}
                handleSelectedStudent={handleSelectedStudent}
                studentResponse={studentResponse}
                blockType={blockType}
                selectedTopic={selectedTopic}
                selectedLesson={selectedLesson}
                lang={lang}
                prework={prework}
                handleReportSelectionPrework={handleReportSelectionPrework}
                handleStudentResponsePrework={handleStudentResponsePrework}
                userDataPrework={userDataPrework}
                title={title}
                quantity={quantity}
            />
        </>
    )
}

CourseReportsContainer.propTypes = {
    activeCourse: PropTypes.shape({
        CourseType: PropTypes.shape({
            managed_from_sge: PropTypes.bool,
        }),
        id: PropTypes.string,
        Units: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
            })
        ),
        match: PropTypes.shape({
            params: PropTypes.shape({
                courseId: PropTypes.string,
            }),
        }),
    }),
}

const mapStateToProps = (state) => {
    return {
        activeCourse: state.courseReducer.activeCourse,
        reportData: state.courseReducer.reportData,
        lang: state.langStore.courseReports,
    }
}

const mapDispatch = (
    dispatch,
    functions = {
        loadActiveCourse,
        loadActiveUnits,
        loadActiveLessons,
        loadActiveTopics,
        loadActiveBlocks,
        getReportData,
        clearReportDataAction,
        loadCoursePermissions,
        clearActiveCourseAction,
    }
) => ({
    loadActiveCourse: (courseId) => dispatch(functions.loadActiveCourse(courseId)),
    loadActiveUnits: (courseId) => dispatch(functions.loadActiveUnits(courseId)),
    loadActiveLessons: (courseId, unitId) =>
        dispatch(functions.loadActiveLessons(courseId, unitId)),
    loadActiveTopics: (courseId, unitId, lessonId) =>
        dispatch(functions.loadActiveTopics(courseId, unitId, lessonId)),
    loadActiveBlocks: (courseId, unitId, lessonId, topicId) =>
        dispatch(functions.loadActiveBlocks(courseId, unitId, lessonId, topicId)),
    getReportData: (courseId, blockId) => dispatch(functions.getReportData(courseId, blockId)),
    clearReportDataAction: () => dispatch(functions.clearReportDataAction()),
    loadCoursePermissions: (courseId) => dispatch(functions.loadCoursePermissions(courseId)),
    clearActiveCourse: () => {
        dispatch(functions.clearActiveCourseAction())
    },
})

export default withRouter(connect(mapStateToProps, mapDispatch)(CourseReportsContainer))
