import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FormGroup, FormControl } from 'react-bootstrap'
import MessageBox from '@digitalhouse-tech/react-lib-ui-explorer/lib/MessageBox'
import { history } from '@/app/store'
import { clearUsersAlert, uploadUsers, downloadUserCSVModel, loadStudentsByCourse } from '@/redux/reducers/_deprecated/userReducer'
import { loadCourse } from '@/redux/thunks/courseThunks'
import { clearCourse } from '@/redux/reducers/courseReducer'
import { getCoursesSelectOptions, isObjectEmpty } from '@/helpers/utils'
import { CustomAlert } from '@/components/CustomAlert'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import ButtonGroup from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonGroup'
import ButtonRound from '@digitalhouse-tech/react-lib-ui-explorer/lib/ButtonRound'
import { Download } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import CardLoader from '@/components/CardLoader'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'
import Label from '@digitalhouse-tech/react-lib-ui-explorer/lib/Label'


export class UploadCsv extends React.Component {

    constructor() {
        super()
        this.state = {
            file: null,
            userForm: {
                course: {
                    error: false, 
                    message: '' },
                file: { error: false, message: '' }
            },
            courseId: null,
            withCourse: true,
        }

        this.focusAlert = React.createRef();

        this.updateFileUpload = this.updateFileUpload.bind(this)
        this.validateUserForm = this.validateUserForm.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.handleGoBack = this.handleGoBack.bind(this)

        this.getCourseOptions = this.getCourseOptions.bind(this)
    }

    updateFileUpload(e) {
        this.setState({
            ...this.state,
            file: e.target.files[0]
        })
    }

    componentDidMount() {

        this.props.clearUsersAlert()

        let urlParams = new URLSearchParams(this.props.location.search);
        let paramsCourseId = urlParams.get('courseId')

        if(paramsCourseId) {
            this.props.loadStudentsByCourse(paramsCourseId)
            if(!this.props.course || this.props.course.id != paramsCourseId) {
                this.props.loadCourse(paramsCourseId)
            }
        }

    }

    componentWillUnmount(){
        this.props.clearCourse()
    }

    validateUserForm(fileToLoad) {
        let urlParams = new URLSearchParams(this.props.location.search);
        let paramsCourseId = urlParams.get('courseId')
        let formOk = true
        let course = paramsCourseId
        let { withCourse } = this.state

        let userForm = {
            course: { selected: course, error: false, message: '' },
            file: { error: false, message: '' }
        }

        if (withCourse) {
            if (course == -1 || course == '') {
                userForm.course.error = true
                userForm.course.message = this.props.lang.requiredInputMessage
                formOk = false
            } else {
                userForm.course.error = false
                userForm.course.message = ''
            }
        }

        if ((fileToLoad == null || fileToLoad == '')) {
            userForm.file.error = true
            userForm.file.message = this.props.lang.requiredInputMessage
            formOk = false
        } else {
            userForm.file.error = false
            userForm.file.message = ''
        }

        if (!formOk) this.setState({ ...this.state, userForm: userForm })

        return formOk
    }

    handleGoBack() {
        history.goBack()
    }

    onSubmit() {
        let urlParams = new URLSearchParams(this.props.location.search);
        let paramsCourseId = urlParams.get('courseId')
        let fileToLoad = this.state.file

        if (this.validateUserForm(fileToLoad)) {
            this.props.uploadUsers(fileToLoad, paramsCourseId)
            ReactDOM.findDOMNode(this.fileInput).value = null

            this.setState(state => ({
                ...state,
                file: null,
                userForm: {
                    course: {
                        error: false,
                        message: ''
                    },
                    file: { error: false, message: '' }
                }
            }))
        }
        else {
            this.setState((state, props) => ({
                ...state,
                courseId: paramsCourseId,
                userForm: {
                    ...state.userForm,
                    course: {
                        ...state.userForm.course,
                    }
                }
            }))
        }
    }

    getCourseOptions() {
        return getCoursesSelectOptions(this.props.allCourses)
    }

    render() {
        return (
            <div className="container">
                <div className="__main_header">
                    <Row>
                        <Col lg="12">
                            <h1 className="__main_title">
                            {this.props.lang.usersTitle}
                            {this.state.withCourse && <small>{this.props.course.name}</small>}
                            </h1>
                        </Col>
                    </Row>
                </div>
                <Panel className="__card __fixedHeight">
                    <CardLoader show={this.props.isLoading} />
                    {(!isObjectEmpty(this.props.alert.text)) &&
                        <CustomAlert onDismiss={this.props.clearUsersAlert} rowKey="errorMessageRow" bsStyle={this.props.alert.style} message={(typeof (this.props.alert.text) == 'object' ? this.props.alert.text.message : this.props.alert.text)} />}
                    <Row>
                        <Col lg="4">
                            <FormGroup
                                validationState={this.state.userForm.file.error ? 'error' : null}
                                key={'fileGroup'}
                                bsSize="small"
                                controlId="fileInput"
                            >
                                <Label text={this.props.lang.uploadCsvFile} />
                                <FormControl
                                    key={'fileControl'}
                                    ref={fileInput => { this.fileInput = fileInput }}
                                    type="file"
                                    onChange={this.updateFileUpload}>
                                </FormControl>
                                {this.state.userForm.file.error &&
                                    <MessageBox
                                        theme='danger'
                                        text={this.state.userForm.file.message}
                                        noFill
                                    />}
                            </FormGroup>
                        </Col>
                        <Col lg="3">
                            <ButtonGroup className={this.state.withCourse ? "pull-right _margin_top_20" : "pull-right _margin_top_10"}>
                                <ButtonRound
                                    id="downloadUsersButton"
                                    onClick={this.props.downloadUserCSVModel}
                                    icon={<Download />}
                                    theme='primary'
                                />
                                <Button
                                    id="onSubmitButton"
                                    theme="primary"
                                    onClick={this.onSubmit}
                                    text={this.props.lang.uploadButton}
                                />
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Panel>
                <Row>
                    <Col lg="12">
                        <div className="_btn_group pull-right">
                            <Button
                                id="handleGoBackButton"
                                theme='secondary'
                                onClick={this.handleGoBack}
                                text={this.props.lang.goBackButton}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }


}

const mapDispatch = (dispatch) => ({
    clearUsersAlert: () => {
        dispatch(clearUsersAlert())
    },
    uploadUsers: (file, courseId) => {
        dispatch(uploadUsers(file, courseId))
    },
    loadStudentsByCourse: (courseId, name, surname, email) => {
        dispatch(loadStudentsByCourse(courseId, name, surname, email))
    },
    loadCourse: (courseId) => {
        dispatch(loadCourse(courseId))
    },
    downloadUserCSVModel: () => (
        dispatch(downloadUserCSVModel())
    ),
    clearCourse: () => (
        dispatch(clearCourse())
    )
})

const mapStateToProps = (state, ownProps) => {
    return {
        alert: state.userReducer.alert,
        uploadState: state.userReducer.uploadState,
        isLoading: state.userReducer.isLoading,
        lang: state.langStore.addUserCsv,
        course: state.course.course,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatch)(UploadCsv))