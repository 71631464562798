import React, { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { finalGradeRanges, isContentApproved, shapeStatus } from '../utils'
import {
    WARNING,
    SUCCESS,
    REDO,
    PERCENTAGE_PLACEHOLDER,
    BASE_CONTENT_VIEWED,
} from '../constants'
import { atomAsset } from '@/assets/img/config'
import { MINIMUM_TO_APPROVE } from '@/constants/grades'
import { COURSE_TYPE_PDP_ID, COURSE_TYPE_PREWORK_ID, COURSE_TYPE_ON_DEMAND_ID } from '@/constants/courseTypes'

const ALLOWED_COURSES = [
    COURSE_TYPE_PREWORK_ID,
    COURSE_TYPE_PDP_ID,
    COURSE_TYPE_ON_DEMAND_ID,
]

const useGrades = () => {
    const { mainGrade, accessibility, tooltips, ...studentGrades } = useSelector(
        ({ langStore }) => langStore.studentGrades
    )
    const { grades, subjectGrade } = useSelector(({ roadmap }) => roadmap.gradebook)
    const course = useSelector(({ roadmap }) => roadmap.course)
    const isApprovedProgress = ALLOWED_COURSES.includes(course?.CourseType?.id);
    const percentProgress = (isApprovedProgress
        ? course?.userProgress?.course?.percent : course?.userProgress?.course?.percentViewed) || null

    const BEGGINER = useMemo(() => ({
        title: mainGrade.status.anotherChance,
        level: mainGrade.levels.beginner,
        tooltipInfo: mainGrade.disapprovedTooltip,
        theme: WARNING,
    }))

    const APPRENTICE = useMemo(() => ({
        title: mainGrade.status.goForMore,
        level: mainGrade.levels.apprentice,
        theme: SUCCESS,
    }))

    const ADVANCED = useMemo(() => ({
        title: mainGrade.status.excellent,
        level: mainGrade.levels.advanced,
        theme: SUCCESS,
    }))

    const CHALLENGER = useMemo(() => ({
        theme: null,
        title: mainGrade.status.incredible,
        level: mainGrade.levels.challenger,
        hasTip: true,
        tipImgSrc: atomAsset('confetti.svg'),
        tipImgAlt: accessibility.extraTipAltImg,
    }))

    const finalGradeBaseProps = useCallback((grade) => ({
        description: mainGrade.approved,
        tooltipInfo: mainGrade.approvedTooltip,
        level: null,
        hasTip: false,
        grade: grade,
    }))

    const finalGradeShape = useCallback((grade) => ({
        ...finalGradeBaseProps(grade),
        ...(finalGradeRanges.isBegginer(grade) && { ...BEGGINER }),
        ...(finalGradeRanges.isApprentice(grade) && { ...APPRENTICE }),
        ...(finalGradeRanges.isAdvanced(grade) && { ...ADVANCED }),
        ...(finalGradeRanges.isChallenger(grade) && { ...CHALLENGER }),
    }))

    const finalGrade = useMemo(() => finalGradeShape(subjectGrade), [subjectGrade])

    const assessmentBaseProps = useCallback((grade) => ({
        info: grade && grade,
        infoStatus: shapeStatus(grade).status,
        theme: shapeStatus(grade).theme,
    }))

    const recoveryPrevStatus = useCallback((grade) => ({
        prevInfo: grade && grade,
        prevInfoStatus: REDO,
    }))

    const dynamicAssessments = useMemo(
        () => grades.filter((assess) => !assess.replaceAssessmentId),
        [grades]
    )

    const shappedAssessments = useMemo(
        () =>
            dynamicAssessments.map((assessment) => {
                const recoveryAssessment = grades.find(
                    (assess) => assess.replaceAssessmentId === assessment.assessmentTypeId
                )

                const { grade, assessmentTypeName } = assessment

                const isRecovery = recoveryAssessment && grade < MINIMUM_TO_APPROVE
                const showRecovery = recoveryAssessment && recoveryAssessment.grade > 0

                const baseAssessment = {
                    ...assessmentBaseProps(!!grade && grade),
                    ...(isRecovery && { infoStatus: REDO }),
                }

                return {
                    text: assessmentTypeName,
                    ...baseAssessment,
                    ...(showRecovery && {
                        ...assessmentBaseProps(
                            !!recoveryAssessment.grade && recoveryAssessment.grade
                        ),
                        ...recoveryPrevStatus(!!assessment.grade && assessment.grade),
                    }),
                }
            }),
        [grades]
    )

    const contentAssessment = useMemo(() => {
        const contentApproved = isContentApproved(percentProgress)

        return {
            theme: SUCCESS,
            type: 'percentage',
            text: studentGrades.content,
            info: percentProgress,
            tooltip: contentApproved
                ? tooltips.contentSuccess.replace(PERCENTAGE_PLACEHOLDER, percentProgress)
                : tooltips.contentWarning.replace(PERCENTAGE_PLACEHOLDER, BASE_CONTENT_VIEWED),
        }
    }, [percentProgress])

    const shapedFixedAssessments = useMemo(() => [contentAssessment])

    const hideFinalGrade = useCallback(() => {
        return  dynamicAssessments.some((grade) => !grade.grade)
    }, [dynamicAssessments])

    return {
        finalGrade,
        assessments: shappedAssessments || [],
        fixedAssessments: shapedFixedAssessments || [],
        showFinalGrade: dynamicAssessments.length ? !hideFinalGrade() : false,
    }
}

export default useGrades
