import { useDispatch } from 'react-redux'
import { history } from '@/app/store'
import { enableClone } from '@/helpers/granters/courseTypeRules'
import { loadCoursePermissions } from '@/redux/reducers/_deprecated/authReducer'
import {
    NAVIGATE_SURVEY,
    SEND_EMAIL,
    VIEW_COURSE_PROGRESS_REPORT,
    VIEW_FILE,
    VIEW_OWNERS,
    VIEW_STUDENT_PROGRESS_REPORT,
    VIEW_STUDENT,
    VIEW_TABS,
    VIEW_TOOLS,
} from '@/constants/coursePermissions'
import {
    ENABLE_ENHANCED_REPORTS,
    ENABLE_FILES,
    ENABLE_OWNERS,
    ENABLE_PREWORK_REPORT,
    ENABLE_REPORTS,
    ENABLE_SEND_EMAILS,
    ENABLE_SURVEYS,
    ENABLE_TABS,
    ENABLE_TOOLS,
} from '@/constants/courseRules'

import {
    FULL_COURSE_ACCESS,
    CLONE_COURSE,
} from '@digitalhouse-tech/ts-lib-store/lib/session-storage/permission'

const grantCoursePermission = (resolvers = [], coursePermissions) => {
    return resolvers.some((resolver) => resolver(coursePermissions))
}

const onClickAction = async (courseId, getCoursePermissions, optionUrl, params) => {
    await getCoursePermissions(courseId)
    history.push(optionUrl, params)
}

const getCourseOptions = (courseInfo, getCoursePermissions, lang) => {
    const courseId = courseInfo.id
    const courseOptions = [
        {
            requiredPermission: grantCoursePermission([VIEW_FILE], courseInfo.permissions),
            courseTypeRule: ENABLE_FILES,
            name: lang.files,
            onClick: () =>
                onClickAction(courseId, getCoursePermissions, `/admin/courses/${courseId}/files`),
            testId: 'files',
        },
        {
            requiredPermission: grantCoursePermission([NAVIGATE_SURVEY], courseInfo.permissions),
            courseTypeRule: ENABLE_SURVEYS,
            name: lang.surveys,
            onClick: () =>
                onClickAction(
                    courseId,
                    getCoursePermissions,
                    `/admin/courses/${courseId}/surveys`,
                    {
                        courseName: courseInfo.name,
                        courseBase: courseInfo.base,
                    }
                ),
            testId: 'surveys',
        },
        {
            requiredPermission: grantCoursePermission([VIEW_OWNERS], courseInfo.permissions),
            courseTypeRule: ENABLE_OWNERS,
            name: lang.owners,
            onClick: () =>
                onClickAction(courseId, getCoursePermissions, `/admin/courses/${courseId}/owners`, {
                    name: courseInfo.name,
                }),
            testId: 'owners',
        },
        {
            requiredPermission: grantCoursePermission([VIEW_STUDENT], courseInfo.permissions),
            name: lang.students,
            onClick: () =>
                onClickAction(
                    courseId,
                    getCoursePermissions,
                    `/admin/courses/${courseId}/enrolments?course=${courseInfo.name}`
                ),
            testId: 'students',
        },
        {
            requiredPermission: grantCoursePermission([VIEW_TABS], courseInfo.permissions),
            courseTypeRule: ENABLE_TABS,
            name: lang.tabs,
            onClick: () =>
                onClickAction(courseId, getCoursePermissions, `/admin/courses/${courseId}/tabs`),
            testId: 'tabs',
        },
        {
            requiredPermission: grantCoursePermission(
                [VIEW_STUDENT_PROGRESS_REPORT],
                courseInfo.permissions
            ),
            courseTypeRule: ENABLE_REPORTS,
            name: lang.reports,
            onClick: () =>
                onClickAction(courseId, getCoursePermissions, `/admin/course/${courseId}/reports`),
            testId: 'reports',
            forceHide: courseInfo.isMassive,
        },
        {
            requiredPermission: grantCoursePermission(
                [VIEW_STUDENT_PROGRESS_REPORT],
                courseInfo.permissions
            ),
            courseTypeRule: ENABLE_PREWORK_REPORT,
            name: lang.reports,
            onClick: () =>
                onClickAction(
                    courseId,
                    getCoursePermissions,
                    `/admin/courses/${courseId}/preworks`
                ),
            testId: 'preworkReport',
        },
        {
            requiredPermission: grantCoursePermission([VIEW_TOOLS], courseInfo.permissions),
            courseTypeRule: ENABLE_TOOLS,
            name: lang.courseTools,
            onClick: () =>
                onClickAction(courseId, getCoursePermissions, `/admin/course/${courseId}/tools`, {
                    courseName: courseInfo.name,
                }),
            testId: 'courseTools',
        },
        {
            requiredPermission: grantCoursePermission(
                [VIEW_COURSE_PROGRESS_REPORT],
                courseInfo.permissions
            ),
            courseTypeRule: ENABLE_ENHANCED_REPORTS,
            name: lang.informes,
            onClick: () =>
                onClickAction(
                    courseId,
                    getCoursePermissions,
                    `/admin/courses/${courseId}/dashboard`
                ),
            testId: 'enhancedReports',
        },
        {
            requiredPermission: grantCoursePermission([SEND_EMAIL], courseInfo.permissions),
            courseTypeRule: ENABLE_SEND_EMAILS,
            name: lang.sendEmail,
            onClick: () =>
                onClickAction(courseId, getCoursePermissions, `/admin/courses/${courseId}/emails`, {
                    courseId: courseId,
                }),
            testId: 'sendEmail',
        },
    ]

    return courseOptions.filter((item) => {
        if (!item.courseTypeRule) return true
        if (
            courseInfo?.CourseType?.Rules.includes(item.courseTypeRule) &&
            item.requiredPermission &&
            !item.forceHide
        )
            return item
    })
}

const cloneCourse = (cloneCourseHandler, courseInfo, textLang) => {
    if (
        courseInfo.base &&
        enableClone(courseInfo.CourseType) &&
        (FULL_COURSE_ACCESS() || CLONE_COURSE())
    )
        return {
            name: textLang.copy,
            onClick: cloneCourseHandler,
            testId: 'clone',
        }
    return null
}

export default (cloneCourseHandler, courseInfo, textLang) => {
    const dispatch = useDispatch()
    const getCoursePermissions = (courseId) => dispatch(loadCoursePermissions(courseId))
    const options = getCourseOptions(courseInfo, getCoursePermissions, textLang)
    const enableCloneCourse = cloneCourse(cloneCourseHandler, courseInfo, textLang)
    if (enableCloneCourse) options.push(enableCloneCourse)

    return options
}
