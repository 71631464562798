import React from 'react'
import { atomAsset } from '@/assets/img/config'
import { getTenantSession } from '@/helpers/tenants/helpers'
import './DeployingLandingPage.scss'

const DeployingLandingPage = ({ lang }) => {
    const imageUrl = atomAsset('astronaut.png')
    const { logoUrl, dhLogoUrl } = getTenantSession()

    return (
        <div className='deploying-page'>
            <div className='deploying-page__text'>
                <img src={logoUrl} alt='Logo Playground' className='deploying-page__text--pg-logo' />
                <div className='deploying-page__text--title'>{lang.title}</div>
                <div className='deploying-page__text--message'>{lang.message}</div>
                <img src={dhLogoUrl} alt='Logo Digital House' className='deploying-page__text--dh-logo' />
            </div>
            <div className='deploying-page__image'>
                <img src={imageUrl} alt='Deploying' />
            </div>
        </div>
    )
}

export default DeployingLandingPage
