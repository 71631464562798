import { useEffect, useState } from 'react'
import * as env from '@/config/env'

const useMicrofrontend = (id, url) => {
    const scriptId = `${id}Bundle`
    const [isLoaded, setLoaded] = useState(window[id])

    const handleLoad = () => setLoaded(true)

    useEffect(() => {
        const existingScript = document.getElementById(scriptId)
        if (existingScript) {
            existingScript.addEventListener('load', handleLoad)

            return () => existingScript.removeEventListener('load', handleLoad)
        }

        const script = document.createElement('script')

        script.id = scriptId
        script.src = `${url}?v=${env.VERSION}`

        document.body.appendChild(script)
        script.onload = handleLoad
    }, []) // eslint-disable-line

    if (!url) {
        return { isLoaded: false, [id]: null }
    }

    return { isLoaded, [id]: window[id] }
}

export default useMicrofrontend
