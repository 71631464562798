import React from 'react'
import useHistory from '@/hooks/useHistory'
import useQuizMf from '../useQuizMf'
import '../Quiz.scss'

export const QuizList = () => {
    const MF_ID = 'mfBoQuiz'
    const MF_NAME = 'list'
    const SELECTOR_ID = 'mfBoQuizList'
    const history = useHistory()

    const quizCreatedId = history.location?.state?.quizCreatedId || ''

    useQuizMf(MF_ID, SELECTOR_ID, MF_NAME, {
        quizCreatedId,
    })

    return <div id={SELECTOR_ID} className='quiz-list-app-container' />
}

export default QuizList
