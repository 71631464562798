import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import cn from 'classnames'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { COURSE_ENTITIES_DATA } from '@/constants/courseEntities'
import { checkLessonIsReleased } from '@digitalhouse-tech/ts-lib-store/lib/shared/dates'

const CourseReportEntityList = ({
    data,
    dataLevel,
    currentEntity,
    entityPrefix,
    changeDataLevel,
    showList,
}) => {
    const isUnitsLevel = dataLevel === COURSE_ENTITIES_DATA.UNITS
    const isLessonsLevel = dataLevel === COURSE_ENTITIES_DATA.LESSONS
    const isTopicsLevel = dataLevel === COURSE_ENTITIES_DATA.TOPICS

    const nextDataLevel =
        isUnitsLevel || isLessonsLevel ? COURSE_ENTITIES_DATA.LESSONS : COURSE_ENTITIES_DATA.TOPICS
    return (
        showList && (
            <div
                className={cn(
                    {
                        _report_list_lessons: isLessonsLevel,
                        _report_list_topics: isTopicsLevel,
                    },
                    '_report_filters_course',
                )}
            >
                {data.map((entity, index) => {
                    const disableUnit =
                        !entity.releaseDate || !checkLessonIsReleased(entity.releaseDate)
                    return (
                        entity.id !== currentEntity.id && (
                            <Button
                                key={`report_unit_list_${entity.id}`}
                                id={`report_unit_list_${entity.id}`}
                                className={cn(
                                    {
                                        _disable_col: disableUnit,
                                    },
                                    '_report_general_button',
                                )}
                                theme='clear'
                                text={
                                    <span>
                                        {entityPrefix + (index + 1)}
                                        {' '}
                                        {entity.name}
                                    </span>
                                }
                                onClick={() => changeDataLevel(entity.id, nextDataLevel)}
                            />
                        )
                    )
                })}
            </div>
        )
    )
}

CourseReportEntityList.propTypes = {
    showList: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    dataLevel: PropTypes.string.isRequired,
    currentEntity: PropTypes.object.isRequired,
    entityPrefix: PropTypes.string.isRequired,
    changeDataLevel: PropTypes.func.isRequired,
}

export default CourseReportEntityList
