import React from 'react'
import PropTypes from 'prop-types'
import FinalGradeCard from './components/FinalGradeCard'
import MainCard from './components/MainCard'
import CardInfo from '@digitalhouse-tech/react-lib-ui-explorer/lib/CardInfo'
import CardStatus from '@digitalhouse-tech/react-lib-ui-explorer/lib/CardStatus'
import Tooltip from '@digitalhouse-tech/react-lib-ui-explorer/lib/Tooltip'
import './Grades.scss'
import Slider from '../../Welcome/Slider/Slider'
import CourseSchedule from './components/CourseSchedule/CourseSchedule'
import { useSelector } from 'react-redux'
import { useSchedules } from '@/hooks/useSchedule'
import moment from 'moment'

const Grades = ({
    finalGrade,
    fixedAssessments,
    assessments,
    showFinalGrade,
    courseName,
    schedules,
    initial_date,
    nextOnSiteLesson,
}) => {
    const { schedule: langSchedule } = useSelector(state => state.langStore)
    const filteredSchedules = schedules?.filter(s => s.periodicity === 1)
    const getSchedules = schedules && useSchedules(langSchedule, filteredSchedules, true)

    return (
        <div className='student-grades'>
            <MainCard {...{ courseName }} />
            {
                !!filteredSchedules?.length && (
                    <CourseSchedule 
                        lang={langSchedule} 
                        schedules={getSchedules} 
                        startingDate={initial_date && moment(initial_date).format('DD/MM/yyyy')}
                        nextLesson={nextOnSiteLesson?.release_date && moment(nextOnSiteLesson.release_date).format('dddd DD/MM')}
                    />
                )
            }
            <div className='student-grades__grades'>
                {showFinalGrade && (
                <FinalGradeCard
                    grade={finalGrade.grade}
                    theme={finalGrade.theme}
                    tooltipContent={finalGrade.tooltipInfo}
                    description={finalGrade.level}
                />)}
                <div className='student-grades__slider'>
                    <div className='student-grades__slider-box'>
                        <Slider
                            id='student-grades-slider'
                            showFooter={false}
                            items={[
                                ...fixedAssessments.map((assessment, i) => (
                                    <div
                                        key={i}
                                        className='student-grades__card-assessment-container'
                                    >
                                        <Tooltip
                                            tooltipContent={assessment.tooltip}
                                            tooltipPlacement='bottom'
                                        >
                                            <CardStatus
                                                className='student-grades__card-assessment'
                                                number={assessment.info}
                                                title={assessment.text}
                                                icon={assessment.icon}
                                                type={assessment.type}
                                                theme={assessment.theme}
                                            />
                                        </Tooltip>
                                    </div>
                                )),
                                ...assessments.map((assessment, j) => (
                                    <div
                                        key={j + 100}
                                        hourFormalocationtLang
                                        className='student-grades__card-assessment-container'
                                    >
                                        <CardInfo
                                            className='student-grades__card-assessment'
                                            text={assessment.text}
                                            theme={assessment.theme}
                                            prevInfo={assessment.prevInfo}
                                            prevInfoStatus={assessment.prevInfoStatus}
                                            info={assessment.info}
                                            infoStatus={assessment.infoStatus}
                                        />
                                    </div>
                                )),
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

Grades.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    finalGrade: PropTypes.obj,
    fixedAssessments: PropTypes.array,
    assessments: PropTypes.array,
    showFinalGrade: PropTypes.bool,
    courseName: PropTypes.string,
}

Grades.defaultProps = {
    finalGrade: null,
    fixedAssessments: null,
    assessments: null,
    showFinalGrade: false,
    courseName: '',
}

export default Grades
