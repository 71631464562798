import * as quizBlockService from '../http/services/quizBlockService'
import { setQuizBlocks } from '../reducers/courseReducer'

export const loadQuizBlocks = () => async (dispatch) => {
    const [error, data] = await quizBlockService.loadQuizBlocks()

    if (error) return

    dispatch(setQuizBlocks(data))
}
