import React, { createRef } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { history } from '@/app/store.js'
import PropTypes from 'prop-types'
import { calculatePaginationRange } from '@/helpers/pagination'
import { offset, scrollTo } from '@/helpers/utils'
import Paginator from '@digitalhouse-tech/react-lib-ui-explorer/lib/Paginator'
import LoadingOrContent from '@digitalhouse-tech/react-lib-ui-explorer/lib/LoadingOrContent'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'
import CustomCargando from '@/components/CustomCargando'
import { loadInstitute, clearInstituteAction, } from '@/redux/reducers/_deprecated/instituteReducer'
import { loadAllCourses } from '@/redux/reducers/_deprecated/courseReducer'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import { Country, State } from 'country-state-city'
const queryOptions = { order_by: { item: 'created_at', value: 'desc' } }
export class InstituteDetail extends React.Component {

    constructor() {
        super()
        this.state = {
            currentPage: 1,
            pagesQuantity: 1,
            redirect: [],
        }

        this.coursesListRef = createRef()
        this.handleGoBack = this.handleGoBack.bind(this)
        this.showLevels = this.showLevels.bind(this)
        this.showCourses = this.showCourses.bind(this)
        this.goToCourse = this.goToCourse.bind(this)
        this.pageChangeHandler = this.pageChangeHandler.bind(this)
        this.showPagination = this.showPagination.bind(this)
    }

    static getDerivedStateFromProps(props) {
        const { pagesQuantity } = props.allCourses
        return {
            ...(pagesQuantity && { pagesQuantity }),
        }
    }

    componentDidMount() {
        this.props.clearInstituteData()
        if (this.props.match.params.instituteId) {
            this.props.loadInstitute(this.props.match.params.instituteId)
        }

        this.props.loadAllCourses({ page: 1, institute_id: this.props.match.params.instituteId }, queryOptions)
        scrollTo(window, 0)
    }

    handleGoBack() {
        this.props.clearInstituteData()
        history.goBack()
    }

    showLevels() {
        const levels = [
            { value: 'PRIMARY', label: this.props.lang.primary },
            { value: 'SECONDARY', label: this.props.lang.secondary },
            { value: 'SUPERIOR', label: this.props.lang.higher }
        ]

        if (!this.props.activeInstitute.levels?.length)
            return this.props.lang.informationNotAvailable

        let allLevels = []
        if (this.props.activeInstitute.levels)
            allLevels = this.props.activeInstitute.levels

        let result = []
        allLevels.map(level => {
            const levelName = levels.find(l => l.value === level).label

            result += result.length > 1 ? ', ' + levelName : levelName
        })

        return result

    }

    async pageChangeHandler(page) {
        const {
            loadAllCourses,
            match: { params: { instituteId } },
        } = this.props

        this.setState({
            currentPage: page,
        })

        await loadAllCourses({ page, institute_id: instituteId }, queryOptions)

        const coursesListTop = offset(this.coursesListRef.current).top - 125
        scrollTo(window, coursesListTop)
    }

    showPagination() {
        const { currentPage, pagesQuantity } = this.state
        const {
            isLoading,
            allCourses: { rows },
        } = this.props

        return rows && rows.length ? (
            <Paginator
                currentPage={currentPage}
                disabled={isLoading}
                onPageChange={(page) => this.pageChangeHandler(page)}
                range={calculatePaginationRange(pagesQuantity)}
                totalPages={pagesQuantity}
            />
        ) : (
            <></>
        )
    }

    showCourses() {
        let courses = []
        if (this.props.allCourses.rows && this.props.allCourses.rows.length > 0) {

            this.props.allCourses.rows.map(course => {
                courses.push(
                    <Panel className="__card_flex_container" key={"institute_course" + course.id}>
                        <div>
                            <h3 className="__card_secondary_title">{course.name}</h3>
                        </div>

                        <div className="_btn_group">
                            <Button
                                id="SeeMoreButton"
                                onClick={this.goToCourse.bind(this, course.id)}
                                text={this.props.lang.seeMoreButton}
                            />
                        </div>
                    </Panel>
                )
            })
        }
        else {
            courses = <div className="__search_msg_result"><p>{this.props.lang.noResultsFoundMessage}</p></div>
        }
        return courses

    }

    goToCourse(courseId) {
        let courseLink = "/admin/courses/" + courseId
        history.push(courseLink)
    }

    render() {
        if (!this.props.match.params.id || this.props.activeInstitute.isLoaded) {
            const countryName = this.props.activeInstitute.country ? Country.getCountryByCode(this.props.activeInstitute.country).name : this.props.lang.informationNotAvailable
            const stateName = this.props.activeInstitute.state ? ', ' + State.getStateByCodeAndCountry(this.props.activeInstitute.state, this.props.activeInstitute.country).name : ''
            const cityName = this.props.activeInstitute.city ? this.props.activeInstitute.city : ''

            return (
                <div className="container">
                    <div className="__main_header">
                        <Row>
                            <Col lg="12">
                                <h1 className="__main_title">{this.props.lang.institutionDetailTitle}<small>{this.props.activeInstitute.name}</small></h1>
                            </Col>
                        </Row>
                    </div>
                    <Panel className="__card __fixedHeight">
                        <Row>
                            <Col lg="3">
                                <h3 className="__card_secondary_title _card_margin_bottom">{this.props.lang.institutionInformation}</h3>
                                <div className="__information_box">
                                    <div className="__label_box">
                                        <h4 className="__label"><strong>{this.props.lang.nameInformation}</strong></h4>
                                        <h4 className="__label">{this.props.activeInstitute.name}</h4>
                                    </div>

                                    <div className="__label_box">
                                        <h4 className="__label"><strong>{this.props.lang.stateInformation}</strong></h4>
                                        <h4 className="__label">{this.props.activeInstitute.active ? this.props.lang.activeState : this.props.lang.inactiveState}</h4>
                                    </div>

                                    <div className="__label_box">
                                        <h4 className="__label"><strong>{this.props.lang.phoneInformation}</strong></h4>
                                        <h4 className="__label">{this.props.activeInstitute.contactPhone ? this.props.activeInstitute.contactPhone : this.props.lang.informationNotAvailable}</h4>
                                    </div>

                                    <div className="__label_box">
                                        <h4 className="__label"><strong>{this.props.lang.emailInformation}</strong></h4>
                                        <h4 className="__label">{this.props.activeInstitute.contactEmail ? this.props.activeInstitute.contactEmail : this.props.lang.informationNotAvailable}</h4>
                                    </div>

                                    <div className="__label_box">
                                        <h4 className="__label"><strong>{this.props.lang.descriptionInformation}</strong></h4>
                                        <h4 className="__label">{this.props.activeInstitute.description ? this.props.activeInstitute.description : this.props.lang.informationNotAvailable}</h4>
                                    </div>

                                    <div className="__label_box">
                                        <h4 className="__label"><strong>{this.props.lang.levelInformation}</strong></h4>
                                        <h4 className="__label">{this.showLevels()}</h4>
                                    </div>

                                    <div className="__label_box">
                                        <h4 className="__label"><strong>{this.props.lang.countryInformation}</strong></h4>
                                        <h4 className="__label">{countryName} {stateName} {cityName}</h4>
                                    </div>
                                </div>
                            </Col>

                            <Col lg="9">
                                <h3 className="__card_secondary_title _card_margin_bottom">{this.props.lang.courses}</h3>
                                <div ref={this.coursesListRef}>
                                    <LoadingOrContent loading={this.props.isLoading} size='medium'>
                                        {this.showCourses()}
                                    </LoadingOrContent>
                                </div>
                                <div className="__pagination">
                                    {this.showPagination()}
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="12">
                                <div className="__main_footer">
                                    <div className="_btn_group pull-right _margin_bottom_28">
                                        <Button 
                                            id="GoBackButton"
                                            theme="secondary"
                                            onClick={this.handleGoBack}
                                            text={this.props.lang.goBackButton}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Panel >
                </div>
            )
        } else {
            return (
                <React.Fragment>
                    <CustomCargando />
                </React.Fragment>
            )
        }
    }
}

const mapDispatch = (dispatch) => ({
    loadInstitute: (instituteId) => {
        dispatch(loadInstitute(instituteId))
    },
    clearInstituteData: () => {
        dispatch(clearInstituteAction())
    },
    loadAllCourses: (filters, options) => dispatch(loadAllCourses(filters, options)),
})

const mapStateToProps = (state) => {
    return {
        activeInstitute: state.instituteReducer.activeInstitute,
        allCourses: state.courseReducer.allPaginatedCourses,
        isLoading: state.courseReducer.isLoading,
        lang: {
            ...state.langStore.createNewInstitution,
            ...state.langStore.institutionDetail
        },
    }
}

InstituteDetail.propTypes = {
    activeInstitute: PropTypes.object.isRequired,
    allCourses: PropTypes.shape({
        isLoaded: PropTypes.bool,
        nextPage: PropTypes.number,
        pagesQuantity: PropTypes.number,
        rows: PropTypes.array,
        totalRows: PropTypes.number,
    }).isRequired,
    clearInstituteData: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    lang: PropTypes.object.isRequired,
    loadInstitute: PropTypes.func.isRequired,
    loadAllCourses: PropTypes.func.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string,
        url: PropTypes.string,
        isExact: PropTypes.bool,
        params: PropTypes.object,
    }).isRequired,
}

export default withRouter(connect(mapStateToProps, mapDispatch)(InstituteDetail))
