import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import RotatePhoneMessage from './RotatePhoneMessage'
import { Maximize } from '@digitalhouse-tech/react-lib-ui-explorer/lib/Icons'
import Playstrap from '@/components/Playstrap'
import { PdfBlock, SlideBlock, VideoBlock, TextHtmlBlock } from '@/constants/blocks'
import BlockIssueMenu from '../BlockIssueMenu/BlockIssueMenu'
import TextBlock from './TextBlock'
import useBasicBlock from './useBasicBlock'
import './BasicBlock.scss'

const BasicBlock = ({
    block: { show_name, name, id, ...block },
    text,
    blockType,
    url: unformattedUrl,
    allow_download,
    isMobile,
}) => {

    const {
        allowDownload,
        url,
        rotateMessage,
        height,
        width,
    } = useBasicBlock({
        allow_download,
        block,
        blockType,
        unformattedUrl,
        isMobile,
    })

    const content = useMemo(() => {
        if ([PdfBlock, SlideBlock, VideoBlock].includes(blockType)) {
            return (
                <iframe
                    src={url}
                    id={`BasiBlockIframe${id}`}
                    className="basic-block-content__content"
                    width={`${width}%`}
                    height={`${height}vh`}
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                    title="BlockIframe"
                />
            )
        }

        if (blockType === TextHtmlBlock) {
            const {
                TextHtmlBlock: { html },
            } = block
            return <Playstrap data={html} id={id} />
        }

        return (
            <TextBlock text={text} id={id} />
        )
    }, [blockType, url, block, text, height, width])
    return (
        <div
            className={cn('basic-block-content', {
                'embed-container': isMobile && blockType === SlideBlock,
            })}
        >
            <BlockIssueMenu 
                blockType={blockType} 
                blockId={id} 
                blockName={name} 
                url={allowDownload && unformattedUrl} 
            />

            {show_name && (
                <div className='basic-block-content__blockHeader pg-block-title'>
                    <h1>{name}</h1>
                </div>
            )}

            {content}

            {[SlideBlock, VideoBlock].includes(blockType) && isMobile && (
                <RotatePhoneMessage
                    message={rotateMessage[blockType]}
                    messageIcon={<Maximize className="messageIcon" />}
                    className="basic-block-content__rotate-phone"
                />
            )}
        </div>
    )
}

BasicBlock.propTypes = {
    text: PropTypes.string,
    blockType: PropTypes.string,
    url: PropTypes.string,
    block: PropTypes.object.isRequired,
    allow_download: PropTypes.bool,
    isMobile: PropTypes.bool,
}

BasicBlock.defaultProps = {
    text: '',
    blockType: '',
    url: '',
    allow_download: false,
    isMobile: false,
}

export default BasicBlock
