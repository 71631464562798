import React from 'react'
import PropTypes from 'prop-types'
import './CoverDetail.scss'
import cn from 'classnames'

const CoverDetail = ({ attempts, showAttempts, questionsNumber, lang }) => {
  return <div className='cover-detail__container'>
    {showAttempts && 
      <div className='cover-detail__attempts'>
          {lang.attempts} <b>{!!attempts ? attempts : lang.unlimited}</b>
      </div>
    }
    <div className={cn('cover-detail__questions', { 'cover-detail__questions_only': !showAttempts})}>
        {lang.questions}
        <b>{questionsNumber}</b>
    </div>
  </div>
}

CoverDetail.propTypes = {
  attempts: PropTypes.number,
  showAttempts: PropTypes.bool,
  questionsNumber: PropTypes.number,
  lang: PropTypes.shape({
    attempts: PropTypes.string,
    unlimited: PropTypes.string,
    questions: PropTypes.string
  })
}

export default CoverDetail
