import React from 'react'
import PropTypes from 'prop-types'
import './PositionPopover.scss'
import cn from 'classnames'
import { ARROW_DOWN, ARROW_UP } from './roadMapConstants'

const PositionPopover = ({
    arrowPosition, 
    text, 
    active
}) => {

    const arrowPositionClass = cn({
        'up': arrowPosition === ARROW_UP,
        'down': arrowPosition === ARROW_DOWN,
        'active': active,
    }, 'arrow')
    
    const borderActive = cn({
        'top': arrowPosition === ARROW_DOWN,
        'bottom': arrowPosition === ARROW_UP,
        'active': active,
    }, 'popContainer')

    return (
        <div className={borderActive}>
            <div className={arrowPositionClass} />
            <p className="popText">{text}</p>
        </div>
    )
}

PositionPopover.propTypes = {
    arrowPosition: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
}

export default PositionPopover
